import { HistoryOutlined } from '@material-ui/icons';
import React from 'react';

const HistoryTableIcon = <HistoryOutlined className="flex" style={{ height: 25, width: 25 }} />;

const TableIcons = {
  HistoryTableIcon
};

export default TableIcons;
