import React, { useContext } from 'react';
import { Button } from 'primereact/button';
import { NumberField, TextField } from 'components/Forms';
import DropdownField from 'components/Forms/DropdownField';
import { ResponseFormContext } from './ResponseFormContext';
import { useAppSelector } from 'hooks/store';
import { intentsSelector, projectsSelector } from 'features/projects/projectsSlice';
import { useFieldArray } from 'react-hook-form';
import { getDefaultResponseContent } from 'constants/responses';
import ResponseComponentContainer from './ResponseComponentContainer';
import RemovableFormField from './RemovableFormField';
import { DragSort, SortableFormComponent } from 'features/drag-sort';
import { reorderResponseFields } from 'util/response';
import { ResponseTypeOptions } from 'models/response-table-data-model';
import { filterRetired } from 'util/status';
import { GenericItemTemplate } from 'components/Table';
import { isAdmin } from 'util/permissions';
import Info from 'components/Forms/Info';

/**
 * Component that displays dynamic fields to define a "options list response"
 * @component
 */
const ResponseOptionsList = function ({ componentIndex }: { componentIndex: number }) {
  const contentField = `content.${componentIndex}.content`;
  const { control, moveField, getValues } = useContext(ResponseFormContext);
  const allIntents = useAppSelector(intentsSelector);
  const { selectedProject } = useAppSelector(projectsSelector);
  const { append, remove, fields, replace } = useFieldArray({
    control,
    name: `${contentField}.elements` as const
  });

  const onReorder = (components: SortableFormComponent[]) =>
    replace(
      reorderResponseFields(
        components,
        fields,
        getValues()?.content?.[componentIndex]?.content.elements
      )
    );

  return (
    <ResponseComponentContainer
      componentIndex={componentIndex}
      title="Options List"
      key={componentIndex}
      move={moveField}
    >
      <div className="w-full">
        <div className="grid">
          <div className="col-12">
            <NumberField
              className="w-3"
              label="Items per page"
              fieldName={`${contentField}.pagination`}
              required={true}
              control={control}
              minValue={1}
              maxValue={25}
              info="The number of options to display per page"
            />
          </div>
          <div className="flex col pb-0 col-offset-1">
            <h6 className="mb-0 ml-1">Label</h6>
            <Info text="The text that will appear on the option" />
          </div>
          <div className="flex col pb-0">
            <h6 className="mb-0 ml-2">Intent</h6>
            <Info text="The Intent that will be triggered when the option is selected" />
          </div>
          <div className="col-1 sm:col-2 flex justify-content-center pb-0" />
        </div>
        <DragSort onReorder={onReorder}>
          {fields.map((item, index) => (
            <RemovableFormField
              roundDeleteButton
              key={item.id}
              onRemove={() => remove(index)}
              disabled={fields.length < 2 || !isAdmin(selectedProject.project_user.role)}
            >
              <TextField
                /**
                 * Name needs to be "[useFormArrayName].[index].[fieldname]" to properly map field
                 * data to the final useFormArray data object
                 */
                className="col flex flex-wrap align-items-center mb-0"
                fieldName={`${contentField}.elements.${index}.label`}
                noPadding
                control={control}
                placeholder="Enter text to appear on the row."
                ariaLabel={`row ${index} label`}
                disabled={!isAdmin(selectedProject.project_user.role)}
              />
              <DropdownField
                /**
                 * Name needs to be "[useFormArrayName].[index].[fieldname]" to properly map field
                 * data to the final useFormArray data object
                 */
                className="col flex flex-wrap align-items-center overflow-hidden"
                fieldName={`${contentField}.elements.${index}.payload`}
                placeholder="Select an Intent"
                filter
                control={control}
                options={filterRetired(
                  allIntents,
                  getValues(`${contentField}.elements.${index}.payload`)
                )}
                itemTemplate={(item) => (
                  <GenericItemTemplate
                    id={item?.id}
                    itemName="intent"
                    allOptions={allIntents}
                    showRetiredIcon
                  />
                )}
                valueTemplate={(item) => (
                  <GenericItemTemplate
                    id={item?.id}
                    itemName="intent"
                    allOptions={allIntents}
                    showRetiredIcon
                  />
                )}
                optionLabel="name"
                optionValue="id"
                appendTo={null}
                noPadding
                disabled={!isAdmin(selectedProject.project_user.role)}
              />
            </RemovableFormField>
          ))}
        </DragSort>
        <div className="flex justify-content-end ml-5">
          <Button
            className="font-semibold specto-text-medium p-button-text underline text-sm py-2 w-auto"
            label="ADD OPTION"
            type="button"
            onClick={() =>
              append(getDefaultResponseContent(ResponseTypeOptions.DROPDOWN).elements[0])
            }
            data-cy="add-option"
            disabled={!isAdmin(selectedProject.project_user.role)}
          />
          <div className="col-2"></div>
        </div>
      </div>
    </ResponseComponentContainer>
  );
};

export default ResponseOptionsList;
