import React, { useEffect, useMemo, useState } from 'react';
// prime react
import { Button } from 'primereact/button';
// components
import SearchBar from 'components/SearchBar';
import EntityDataTable from './components/EntityDataTable';
import EntityPopup from './components/EntityPopup';
import EntityFilterMenu from './components/EntityFilterMenu';
// constants
import { FetchStatusOptions } from 'constants/fetchStatus';
// models
import { EntityTableAPIModel, EntityTableDataModel } from 'models/entity-model';
// hooks
import { useAppDispatch, useAppSelector } from 'hooks/store';
import useFetch from 'hooks/useFetch';
// services
import EntitiesService from 'services/entitiesService';
// selectors
import { setLayout } from 'features/layout/layoutSlice';
import {
  addEntity as addEntityState,
  deleteEntity as deleteEntityState,
  editEntity as editEntityState,
  entitiesSelector,
  projectMembersSelector,
  projectsSelector,
  topicsSelector,
  userGroupsSelector,
  usersSelector
} from 'features/projects/projectsSlice';
import { SlugDataIdOptionsModel, SlugDataOptionsModel, SlugIdOptionsModel } from 'models/api-model';
import { versionsSelector } from 'features/versions/versionsSlice';
import { createToast } from 'features/toast/toastSlice';
// utils
import { FilterChips } from 'components/FilterChips/FilterChips';
import { FilterChip } from 'models/filter-chips';
import { copyNameFormatter } from 'util/uniqueIdGenerator';
import { ThreadStateChangeEvent } from 'pages/Comments/CommentPopup';
import { FormMode } from 'models/form-model';
import { FieldValues, useForm } from 'react-hook-form';
import useQuery from 'hooks/useQuery';
import { humanReadableDateTimeFormatted } from 'util/dates';
import { PublishState, Status } from 'models/status-model';
import { permissionBoolean } from 'util/permissions';
import CommentsService from 'services/commentsService';
import { getMainLayout } from 'util/layout';
import { statusDisplayNames } from 'constants/status';
import { getDefaultTableState, mapTableStateToPagination } from 'util/table';
import { DataTableStateEvent } from 'primereact/datatable';
import { authSelector } from 'features/auth/authSlice';
import {
  filterAuthors,
  findProjectMember,
  mapSuperUsersToAuthors,
  mapUsersToProjectMembers
} from 'util/users';
import { ProjectMemberUserModel } from 'models/project-model';
import { buildMultipleChips } from 'util/filters';
import { mapIdToName } from 'util/mapIdAndName';
import { isArrayAndIncludes } from 'util/arrays';
import { useSearchParams } from 'react-router-dom';
import { parseJson } from 'util/query';

const Entity = () => {
  const dispatch = useAppDispatch();
  const { selectedProject, projects } = useAppSelector(projectsSelector);
  const { isVersionSelected } = useAppSelector(versionsSelector);
  const allEntities = useAppSelector(entitiesSelector);
  const allTopics = useAppSelector(topicsSelector);
  const allProjectMembers = useAppSelector(projectMembersSelector);
  const allUsers = useAppSelector(usersSelector);
  const allUserGroups = useAppSelector(userGroupsSelector);
  const { user } = useAppSelector(authSelector);
  const [contextEntity, setContextEntity] = useState<EntityTableDataModel | null>(null);
  const [popupMode, setPopupMode] = useState<FormMode>(FormMode.CREATE);
  const [displayNewEntityPopup, setDisplayNewEntityPopup] = useState(false);
  const [tablestate, setTableState] = useState<DataTableStateEvent>(getDefaultTableState());
  const validQueryParams = ['status', 'topics', 'authors'];
  const [searchParams] = useSearchParams();
  const filterInitialValues = useMemo(() => {
    const params: { [key: string]: any } = {};
    searchParams.forEach((value, key) => {
      if ([...validQueryParams, 'search'].includes(key)) {
        params[key] = parseJson(value);
      }
    });
    // Clear query params after consuming
    window.history.replaceState(null, '', window.location.pathname);
    return params;
  }, [searchParams]);

  // Find admins from users and convert them to the same object as author
  const adminAuthors: ProjectMemberUserModel[] = mapSuperUsersToAuthors(
    allUsers.filter((u) => u.is_superuser),
    allUserGroups.map((ug) => ug.id)
  );

  // Map users data to fit authors data structure
  const authors: ProjectMemberUserModel[] = mapUsersToProjectMembers(
    allUsers,
    allProjectMembers
  ).concat(adminAuthors);

  // Get currently logged in project user as a project user
  const ownProjectUser = findProjectMember(authors, user) as ProjectMemberUserModel;

  /* - - - - - - - - - - Init - - - - - - - - - - */

  useEffect(() => {
    dispatch(setLayout(getMainLayout(selectedProject.project_user.role, projects.length)));
    searchParams.forEach((value, key) => {
      if (validQueryParams.includes(key)) {
        setFilterValue(key, parseJson(value));
      }
    });
  }, []);

  /* - - - - - - - - - - Get All Entities - - - - - - - - - - */
  const [entities, setEntities] = useState<EntityTableDataModel[]>([]);

  const {
    fetch: getEntities,
    data: getEntitiesData,
    fetchStatus: getEntitiesStatus
  } = useFetch(EntitiesService.getPaginatedEntities, EntitiesService.roles.retrieve);

  function getFilteredEntities() {
    getEntities({ slug: selectedProject.slug, query: filterQueryString });
  }

  useEffect(() => {
    if (getEntitiesStatus === FetchStatusOptions.SUCCESS && getEntitiesData) {
      setEntities(getEntitiesData.results);
    }
  }, [getEntitiesStatus]);

  /* - - - - - - - - - - Get Entity - - - - - - - - - - */

  const {
    fetch: getEntity,
    data: getEntityData,
    fetchStatus: getEntityStatus
  } = useFetch(EntitiesService.getEntity, EntitiesService.roles.list);

  // update state with modified state after onThreadStateChange event
  useEffect(() => {
    if (getEntityStatus === FetchStatusOptions.SUCCESS && getEntityData) {
      dispatch(editEntityState(getEntityData));
      getFilteredEntities();
    }
  }, [getEntityStatus]);

  /* - - - - - - - - - - Create Entity - - - - - - - - - - */
  const {
    fetch: postEntity,
    data: newEntity,
    fetchStatus: entityPostStatus,
    permission: canCreateEntities
  } = useFetch<SlugDataOptionsModel<EntityTableAPIModel>, EntityTableDataModel>(
    EntitiesService.createEntity,
    EntitiesService.roles.create
  );

  useEffect(() => {
    if (entityPostStatus === FetchStatusOptions.SUCCESS && newEntity) {
      dispatch(addEntityState(newEntity));
      getFilteredEntities();

      dispatch(createToast('entity created'));
      hidePopup();
    }
  }, [entityPostStatus]);

  /* - - - - - - - - - - Edit Entity - - - - - - - - - - */
  const {
    fetch: editEntity,
    data: updatedEntity,
    fetchStatus: entityEditStatus,
    permission: canEditEntities
  } = useFetch<SlugDataIdOptionsModel<EntityTableAPIModel>, EntityTableDataModel>(
    EntitiesService.editEntity,
    EntitiesService.roles.update
  );

  useEffect(() => {
    updateEntity(updatedEntity, entityEditStatus);
  }, [entityEditStatus]);

  const updateEntity = (
    data: EntityTableDataModel | undefined,
    fetchStatus: FetchStatusOptions,
    toastMessage?: string
  ) => {
    if (fetchStatus === FetchStatusOptions.SUCCESS && data) {
      dispatch(editEntityState(data));
      getFilteredEntities();

      dispatch(createToast(toastMessage ?? 'entity edited'));
      hidePopup();
    }
  };

  /* - - - - - - - - - - Retire Entity - - - - - - - - - - */

  const {
    fetch: retireEntity,
    data: retiredEntity,
    fetchStatus: retireEntityStatus
  } = useFetch(EntitiesService.retireEntity, EntitiesService.roles.update);

  useEffect(() => {
    updateEntity(retiredEntity, retireEntityStatus);
  }, [retireEntityStatus]);

  const onRetireChange = (node: EntityTableDataModel, isRetired: boolean) =>
    retireEntity({
      slug: selectedProject.slug,
      id: node.id,
      retired: {
        retired: isRetired
      }
    });

  /* - - - - - - - - - - Delete Entity - - - - - - - - - - */
  const {
    fetch: deleteEntity,
    fetchStatus: entityDeleteStatus,
    fetchOptions: deleteEntityOptions,
    permission: canDeleteEntities
  } = useFetch<SlugIdOptionsModel<EntityTableDataModel['id']>, EntityTableDataModel>(
    EntitiesService.deleteEntity,
    EntitiesService.roles.delete
  );

  useEffect(() => {
    if (entityDeleteStatus === FetchStatusOptions.SUCCESS && deleteEntityOptions) {
      dispatch(deleteEntityState(deleteEntityOptions.id));
      getFilteredEntities();

      dispatch(createToast('entity deleted'));
    }
  }, [entityDeleteStatus]);

  const deleteTableEntity = (node: EntityTableDataModel) =>
    deleteEntity({
      slug: selectedProject.slug,
      id: node.id
    });

  /* - - - - - - - - - - Duplicate Entity - - - - - - - - - - */

  /**
   * Creates a duplicate of a node
   * @param node node to copy
   */
  const duplicateEntity = (node: EntityTableDataModel) =>
    postEntity({
      data: {
        ...node,
        name: copyNameFormatter({
          name: node.name,
          nameList: allEntities.map((entity) => entity.name)
        })
      },
      slug: selectedProject.slug
    });

  /* - - - - - - - - - - Publish Entity - - - - - - - - - - */

  const {
    fetch: publishEntity,
    data: publishEntityData,
    fetchStatus: publishEntityStatus
  } = useFetch(EntitiesService.publishEntity, EntitiesService.roles.update);

  useEffect(() => {
    updateEntity(publishEntityData, publishEntityStatus, 'entity published');
  }, [publishEntityStatus]);

  const onPublishEntity = (node: EntityTableDataModel) =>
    publishEntity({
      slug: selectedProject.slug,
      id: node.id
    });

  /* - - - - - - - - - - Approve Entity - - - - - - - - - - */

  const {
    fetch: approveEntity,
    data: approveEntityData,
    fetchStatus: approveEntityStatus
  } = useFetch(EntitiesService.approveEntity, EntitiesService.roles.update);

  useEffect(() => {
    updateEntity(approveEntityData, approveEntityStatus, 'entity approved');
  }, [approveEntityStatus]);

  const onApproveEntity = (node: EntityTableDataModel) =>
    approveEntity({
      slug: selectedProject.slug,
      id: node.id
    });

  /* - - - - - - - - - - Popup - - - - - - - - - - */

  /**
   * Callback to change the new entity pop-up visibility in state.
   * @param visibility boolean representing the visibility of the new entity popup
   * @param context the context entity, used for editing
   */
  const handlePopupDisplayChange = (visibility: boolean, context?: EntityTableDataModel) => {
    if (context) {
      setContextEntity(context);
      setPopupMode(FormMode.EDIT);
    } else {
      setPopupMode(FormMode.CREATE);
      setContextEntity(null);
    }
    setDisplayNewEntityPopup(visibility);
  };

  const onSubmit = (data: FieldValues) => {
    const tableData = data as EntityTableAPIModel;

    if (contextEntity && popupMode === FormMode.EDIT) {
      editEntity({
        slug: selectedProject.slug,
        id: contextEntity.id,
        data: tableData
      });
    } else {
      postEntity({ slug: selectedProject.slug, data: tableData });
      // set the filter to show proposed after creating a new object
      setFilterValue('status', PublishState.Proposed);
      setFilterQueryParameter({ active: false, status: Status.Not_Retired });
    }
  };

  const hidePopup = () => handlePopupDisplayChange(false);

  /* - - - - - - - - - - Query - - - - - - - - - - */

  const entitiesFilterDefaultValues: FieldValues = {
    status: Status.Not_Retired,
    published_lte: null,
    author: [],
    topics: []
  };

  const {
    control: filterControl,
    reset: resetFilterForm,
    resetField: resetFilterField,
    setValue: setFilterValue,
    getValues: getFilterValues,
    formState: { dirtyFields: dirtyFilterFields, isDirty: isFilterFormDirty }
  } = useForm({ defaultValues: entitiesFilterDefaultValues });

  const {
    search,
    searchText,
    clearSearch,
    simpleSearch,
    setQueryParameter: setFilterQueryParameter,
    queryString: filterQueryString,
    queryParameters: filterQueryParameters,
    clearAllQueryParameters,
    clearQueryParameter
  } = useQuery({
    query: onQuery,
    deps: [dirtyFilterFields],
    defaultValues: {
      ...mapTableStateToPagination(tablestate),
      status: Status.Not_Retired,
      default: false
    },
    initialValues: filterInitialValues
  });

  // query on filterQueryString change.
  // *** also fires onInit, so it replaces default getMessages req
  useEffect(() => {
    // getEntity is already done when on activateProject. Needs to do happen here again to offload filtering (such as retired) to backend
    getEntities({ slug: selectedProject.slug, query: filterQueryString });
  }, [filterQueryString]);

  // perform back end query using the search
  function onQuery(queryText: string) {
    // set query parameters which will build a queryParameter string
    setFilterQueryParameter({ search: queryText });
  }

  const resetFilter = () => {
    clearSearch();
    clearAllQueryParameters();
    resetFilterForm();
  };

  useEffect(() => setFilterQueryParameter(mapTableStateToPagination(tablestate)), [tablestate]);

  /* - - - - - - - - - - Filter Chips - - - - - - - - - - */

  const publishedBeforeChipActive = 'published_lte' in filterQueryParameters;

  const filterChips: FilterChip[] = [
    {
      name: `Published on or Before: ${humanReadableDateTimeFormatted(
        String(filterQueryParameters.published_lte)
      )}`,
      removeFn: () => {
        clearQueryParameter('published_lte');
        resetFilterField('published_lte');
      },
      enabled: publishedBeforeChipActive
    },
    {
      name: `Entity Filter: ${statusDisplayNames[String(filterQueryParameters?.status)]}`,
      removeFn: () => {
        clearQueryParameter('status');
        resetFilterField('status');
      },
      enabled: true,
      removable: filterQueryParameters?.status !== Status.Not_Retired
    },
    ...buildMultipleChips(filterQueryParameters.topics, (topicId: number) => ({
      name: `Topic: ${mapIdToName(topicId, allTopics)}`,
      removeFn: () => {
        const topics: number[] = getFilterValues('topics');
        const filteredTopics = topics.filter((id) => id !== topicId);

        if (filteredTopics.length === 0) {
          clearQueryParameter('topics');
          resetFilterField('topics');
        } else {
          setFilterValue('topics', filteredTopics);
          setFilterQueryParameter({ topics: filteredTopics });
        }
      },
      enabled: isArrayAndIncludes(filterQueryParameters.topics, topicId)
    })),
    ...buildMultipleChips(filterQueryParameters.author, (userId: number) => ({
      name: `Author: ${mapIdToName(userId, authors, 'user')}`,
      removeFn: () => {
        const authors: number[] = getFilterValues('author');
        const filteredAuthors = authors.filter((id) => id !== userId);

        if (filteredAuthors.length === 0) {
          clearQueryParameter('author');
          resetFilterField('author');
        } else {
          setFilterValue('author', filteredAuthors);
          setFilterQueryParameter({ author: filteredAuthors });
        }
      },
      enabled: isArrayAndIncludes(filterQueryParameters.author, userId)
    }))
  ];

  const filterDeps = [filterQueryString];

  /* - - - - - - - - - - Comments - - - - - - - - - - */

  const onThreadChange = (options: ThreadStateChangeEvent) => getEntity(options);

  return (
    <div className="specto-entity">
      <EntityPopup
        displayPopup={displayNewEntityPopup}
        parentElement={document.activeElement as HTMLElement}
        onHide={hidePopup}
        onSubmit={onSubmit}
        loading={
          entityPostStatus === FetchStatusOptions.LOADING ||
          entityEditStatus === FetchStatusOptions.LOADING
        }
        contextEntity={contextEntity}
        mode={popupMode}
        projectUser={ownProjectUser}
        authors={authors}
      />
      <div className="grid grid-nogutter">
        <div className="col flex flex-wrap xl:align-items-center align-items-start justify-content-between flex-column-reverse xl:flex-row">
          <SearchBar
            filterMenu={
              <EntityFilterMenu
                loading={getEntitiesStatus === FetchStatusOptions.LOADING}
                control={filterControl}
                disableStatus={publishedBeforeChipActive}
                authors={filterAuthors(user.is_superuser, authors, ownProjectUser.user)}
                topics={allTopics}
                onFormValueChange={(fieldValue) => {
                  if ('published_lte' in fieldValue) {
                    resetFilterField('status');
                    fieldValue['status'] = Status.Not_Retired;
                  }
                  setFilterQueryParameter(fieldValue);
                }}
              />
            }
            placeHolder="Search by Name, Description or Roles"
            className="specto-search-bar w-12 xl:w-8 mt-3 xl:mt-0"
            onChange={(e) => simpleSearch(e.target.value)}
            onSubmitText={search}
            onClearText={() => {
              clearSearch();
              search('');
            }}
            text={searchText}
            onFilterClearClick={() => resetFilter()}
            hideFilterClear={!isFilterFormDirty}
          />
          {canCreateEntities && !isVersionSelected && (
            <Button
              label="New Entity"
              icon="pi pi-plus"
              iconPos="right"
              onClick={() => handlePopupDisplayChange(true)}
            />
          )}
        </div>

        <div className="col-12 mb-4 pt-2">
          <FilterChips filterChips={filterChips} deps={filterDeps} className="lg:w-7" />
        </div>

        <div className="col-12">
          <EntityDataTable
            allTopics={allTopics}
            entities={entities}
            onThreadStateChange={onThreadChange}
            onEditInit={(entity) => handlePopupDisplayChange(true, entity)}
            onDuplicateEntity={duplicateEntity}
            onApproveEntity={onApproveEntity}
            onPublishEntity={onPublishEntity}
            onRetireChange={onRetireChange}
            onDeleteEntity={deleteTableEntity}
            readOnly={!canEditEntities || publishedBeforeChipActive}
            canDelete={canDeleteEntities}
            showComments={permissionBoolean(
              CommentsService.roles.general.list,
              selectedProject.project_user.role
            )}
            tablestate={tablestate}
            onTableStateChange={setTableState}
            totalRecords={getEntitiesData?.count ?? 0}
            loading={getEntitiesStatus === FetchStatusOptions.LOADING}
            authors={authors}
          />
        </div>
      </div>
    </div>
  );
};

export default Entity;
