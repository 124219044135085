import { useState } from 'react';
import { FetchStatusOptions } from 'constants/fetchStatus';
import { GENERIC_ERROR_MESSAGE } from 'constants/api';
import { PERMISSIONS_WARNING } from 'constants/warnings';
import { PermissionRoles } from 'models/permission-model';
import { usePermission } from 'hooks/usePermission';
import { useAppDispatch } from './store';
import { createToast } from 'features/toast/toastSlice';
import { responseErrorMessage } from 'util/api-response';

/**
 * For executing a fetch request to a server.
 * @param service A service to execute
 * @param roles permitted roles
 * @returns
 *  fetch: function that executes the service.
 *  data: the response data
 *  fetchStatus: the status of the fetch request.
 *  T: Options type
 *  E: Return type (in promise)
 */
const useFetch = <T, E = unknown>(
  service: (options: T) => Promise<E>,
  roles: PermissionRoles = false
) => {
  const permission = usePermission(roles);
  const [data, setData] = useState<E>();
  const dispatch = useAppDispatch();
  const [fetchOptions, setFetchOptions] = useState<T | undefined>(undefined);
  const [error, setError] = useState<string>(GENERIC_ERROR_MESSAGE);
  const [fetchStatus, setFetchStatus] = useState<FetchStatusOptions>(
    FetchStatusOptions.UNINITIALIZED
  );

  const fetch = async (options: T) => {
    await setFetchStatus(FetchStatusOptions.LOADING);
    setFetchOptions(options);

    if (!permission) {
      setError(PERMISSIONS_WARNING);
      dispatch(createToast(PERMISSIONS_WARNING, 'warn'));
      setFetchStatus(FetchStatusOptions.ERROR);
      return;
    }

    service(options)
      .then((responseData: E) => {
        setData(responseData);
        setFetchStatus(FetchStatusOptions.SUCCESS);
      })
      .catch((e) => {
        setError(e.response.data);
        setFetchStatus(FetchStatusOptions.ERROR);
        // Do not show toast if error is silent
        !e._silent && dispatch(createToast(responseErrorMessage(e), 'error'));
      });
  };

  /**
   * use fetch without it being async. So that we don;t get the warning expecting us to handle the resolved promise.
   * @param options
   */
  const nonAsyncFetch = (options: T): void => {
    fetch(options).catch();
  };

  return {
    fetch: nonAsyncFetch,
    fetchOptions,
    data,
    fetchStatus,
    error,
    permission
  };
};

export default useFetch;
