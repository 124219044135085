/**
 * Format to Hex
 * Takes a hex value (without #) and appends #
 * @param value value of hex
 */
export const pipeValueToHex = (value: string) => {
  return value?.[0] === '#' ? value : '#' + value;
};

/***
 * Convert hex to RGB.
 * @param hex hex color
 */
export const hex2rgb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return { r, g, b };
};
