import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import ExportResponsesPopup from './ExportResponsesPopup';
import { useForm } from 'react-hook-form';
import useFetch from 'hooks/useFetch';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { projectsSelector } from 'features/projects/projectsSlice';
import { FetchStatusOptions } from 'constants/fetchStatus';
import yaml from 'js-yaml';
import { createToast } from 'features/toast/toastSlice';
import ResponsesService from 'services/responsesService';
import { ExportResponseDataType } from 'models/response-table-data-model';

/**
 * Component that renders the Export Responses button and modal
 * @component
 */
const ExportResponses = () => {
  const [displayPopup, setDisplayPopup] = useState<boolean>(false);
  const { selectedProject } = useAppSelector(projectsSelector);
  const dispatch = useAppDispatch();

  const { getValues, control, watch, handleSubmit, reset } = useForm();

  useEffect(() => {
    !displayPopup && reset();
  }, [displayPopup]);

  const {
    fetch: exportResponses,
    data: exportResponsesData,
    fetchStatus: exportResponsesStatus
  } = useFetch(ResponsesService.exportResponses);

  /**
   * Function that creates an anchor HTML tag to download .json/.yaml file
   */
  const exportDataToFile = <T,>(data: T, format: string) => {
    const fileName = 'responses';
    const resData = format === 'json' ? JSON.stringify(data) : yaml.dump(data).substring(4);
    const blob = new Blob([resData], { type: `application/${format}` });
    const href = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + `.${format}`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  useEffect(() => {
    if (exportResponsesStatus === FetchStatusOptions.SUCCESS && exportResponsesData) {
      exportDataToFile(exportResponsesData, getValues('fileFormat'));
      dispatch(createToast('Responses File Exported'));
      setDisplayPopup(false);
    }
  }, [exportResponsesStatus]);

  /**
   * Handles submission of responses export form
   */
  const onSubmit = ({ statuses, replicate_production, fileFormat }: ExportResponseDataType) => {
    const statusesQuery =
      !replicate_production && statuses.length > 0 ? `?status=${statuses.join('&status=')}` : '';

    const replicateProductionQuery = replicate_production
      ? `?replicate_production=${replicate_production}`
      : '';

    const responsesExportQuery = statusesQuery || replicateProductionQuery;

    exportResponses({
      slug: selectedProject.slug,
      query: responsesExportQuery,
      headers: {
        headers: {
          Accept: `application/${fileFormat}`
        }
      }
    });
  };

  return (
    <>
      <Button
        raised
        label="Export Responses"
        icon="pi pi-download"
        iconPos="right"
        className="mr-3 md:flex-none"
        onClick={() => setDisplayPopup(true)}
        loading={exportResponsesStatus === FetchStatusOptions.LOADING}
      />
      <ExportResponsesPopup
        visible={displayPopup}
        hidePopup={() => setDisplayPopup(false)}
        onSubmit={onSubmit}
        control={control}
        handleSubmit={handleSubmit}
        watch={watch}
      />
    </>
  );
};

export default ExportResponses;
