import { useState, useEffect } from 'react';
import { debounce } from 'lodash';

export const useDelay = (loading?: boolean, delay?: number) => {
  const [stateLoading, setStateLoading] = useState(true);

  const setStateLoadingDebounced = debounce((flag: boolean) => {
    setStateLoading(flag);
  }, delay);

  useEffect(() => {
    if (loading !== undefined) {
      setStateLoadingDebounced(loading);
      return () => {
        setStateLoadingDebounced.cancel();
      };
    }
  }, [loading]);

  return { loading: stateLoading, setLoading: setStateLoadingDebounced };
};
