import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { utcToLocalDateFormatted } from 'util/dates';
import Avatar from 'components/Avatar';
import { ProjectMemberUserModel } from 'models/project-model';

type LastModifiedCellPropsModel = {
  nodeId: number | string;
  user?: ProjectMemberUserModel;
  lastModified: string;
  disableTooltip?: boolean;
};

/**
 * Component to display the last modified cell
 * @component
 * @param param0 Prop object containing the following:
 *
 *    nodeId: the id of the row item
 *
 *    user: user object with info to display in the tooltip
 *
 *    lastModified: a date string in UTC ISO 8601 format
 *
 *    disableToolTip: if true, the tooltip showing the user will not appear
 */
const LastModifiedCell = function ({
  nodeId,
  user,
  lastModified,
  disableTooltip
}: LastModifiedCellPropsModel) {
  return (
    <div id={'specto-last-modified-cell-' + nodeId} className="select-none w-max">
      {utcToLocalDateFormatted(lastModified)}

      {!disableTooltip && (
        <Tooltip target={'#specto-last-modified-cell-' + nodeId} position="bottom">
          <Avatar
            fullName={{
              first: user?.name?.split(' ')[0],
              last: user?.name?.split(' ')[1]
            }}
          />
          <span className="ml-2">{user?.name || 'User'}</span>
        </Tooltip>
      )}
    </div>
  );
};

export default LastModifiedCell;
