import React from 'react';
import { Chip } from 'primereact/chip';
import { Tooltip } from 'primereact/tooltip';

/**
 * Component for displaying chips in a cell
 *
 * @param nodeId id of the node
 * @param chips list of text to display
 * @param charLimit max number of characters to display per chip
 * @component
 */
const ChipsCell = function ({
  nodeId,
  chips,
  charLimit
}: {
  nodeId: number | string;
  chips?: string[];
  charLimit?: number;
}) {
  return (
    <div id={'specto-chips-cell-' + nodeId} className="select-none">
      {chips?.map((chip, i) => {
        const chipId = `chip-${nodeId}-${i}`;

        return chip ? (
          <span key={chipId}>
            <Chip
              className="specto-chip m-1"
              id={chipId}
              label={charLimit && chip.length > charLimit ? `${chip.slice(0, charLimit)}...` : chip}
            />
            {charLimit && chip.length > charLimit && (
              <Tooltip target={`#${chipId}`}>{chip}</Tooltip>
            )}
          </span>
        ) : (
          <span key={chipId} />
        );
      })}
    </div>
  );
};

export default ChipsCell;
