import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { ResponseFormContext } from './ResponseFormContext';
import { useFieldArray } from 'react-hook-form';
import { getDefaultResponseContent } from 'constants/responses';
import CardFieldComponent from './CardFieldComponent';
import { ToggleSwitch } from 'components/Forms';
import ToggleDividedButton from 'components/Forms/ToggleDividedButton';
import ResponseComponentContainer from './ResponseComponentContainer';
import { ResponseTypeOptions } from 'models/response-table-data-model';
import Info from 'components/Forms/Info';

/**
 * Component that displays dynamic fields to define a "carousel response" or a "browsing carousel"
 * @component
 */
const ResponseCarousel = function ({ componentIndex }: { componentIndex: number }) {
  const baseField = `content.${componentIndex}`;
  const contentField = `${baseField}.content`;
  const { control, getValues, setValue, watch } = useContext(ResponseFormContext);
  const { append, remove, fields, move } = useFieldArray({
    control,
    name: contentField
  });

  const type = watch(`${baseField}.type`);
  const [browsing, setBrowsing] = useState(type === ResponseTypeOptions.BROWSING_CAROUSEL);
  // All component contents should have the same target
  const [openInNewTab, setOpenInNewTab] = useState(
    getValues(`${contentField}.0.target`) === '_blank'
  );

  // Notify component that type has changed so extra fields can be created or removed
  useEffect(() => {
    setBrowsing(type === ResponseTypeOptions.BROWSING_CAROUSEL);
  }, [type]);

  // Update Target for all elements
  useEffect(() => {
    fields.forEach((_value, index) => {
      setValue(`${contentField}.${index}.target`, openInNewTab ? '_blank' : '_self');
    });
  }, [openInNewTab]);

  return (
    <ResponseComponentContainer
      componentIndex={componentIndex}
      title="Carousel"
      key={componentIndex}
    >
      <div className="w-full">
        <div className="flex flex-wrap align-items-center mb-3">
          <ToggleSwitch
            fieldName={`${baseField}.type`}
            className="pr-3"
            label="Include links"
            control={control}
            trueValue={ResponseTypeOptions.BROWSING_CAROUSEL}
            trueLabel="Include"
            falseLabel="Exclude"
            falseValue={ResponseTypeOptions.CAROUSEL}
            noPadding
            inlineLabel
            info="If this option is enabled, the carousel will include links to external websites. Otherwise, the carousel will only display images."
            ariaLabel="carousel links"
          />
          {browsing && (
            <div className="flex align-items-center w-full mt-4">
              {/* Not using a ToggleSwitch because it corresponds to multiple fields */}
              <h6 className="m-0">Open links in</h6>
              <ToggleDividedButton
                checked={openInNewTab}
                trueLabel="New Tab"
                falseLabel="Same Tab"
                ariaLabel="open carousel links"
                onChange={(e: { value: boolean }) => setOpenInNewTab(e.value)}
              />
              <Info text="If this option is enabled, links will open in a new tab. Otherwise, links will open in the same tab." />
            </div>
          )}
        </div>
        {fields.map((item, index) => (
          <ResponseComponentContainer
            componentIndex={index}
            title={'Card ' + (index + 1)}
            key={item.id}
            move={move}
            remove={remove}
            deleteLabel="REMOVE CARD"
            noDivider
            fieldLength={fields.length}
          >
            <CardFieldComponent baseField={`${contentField}.${index}`} browsing={browsing} />
          </ResponseComponentContainer>
        ))}

        <div className="flex justify-content-end">
          <Button
            className="font-semibold specto-text-medium p-button-text underline text-sm py-2 w-auto"
            label="ADD CARD"
            type="button"
            onClick={() =>
              append({
                ...getDefaultResponseContent(ResponseTypeOptions.CAROUSEL)[0],
                target: openInNewTab ? '_blank' : '_self'
              })
            }
            data-cy="add-carousel-card"
          />
        </div>
      </div>
    </ResponseComponentContainer>
  );
};

export default ResponseCarousel;
