import React from 'react';
// prime react
import { InputTextarea } from 'primereact/inputtextarea';
import { ColumnEditorOptions } from 'primereact/column';
import { textAreaInputMaxLength } from 'constants/inputs';

/**
 * Component to display the text editor for cells
 * @component
 * @param param0 Prop object containing the following:
 *
 *    options: The column editor options
 */
const TextAreaEditorCell = function ({
  options,
  viewOnly
}: {
  options: ColumnEditorOptions;
  viewOnly?: boolean;
}) {
  return viewOnly ? (
    <div>{options.value}</div>
  ) : (
    <InputTextarea
      autoResize={true}
      className="specto-input-text"
      style={{ marginTop: 5 }}
      rows={1}
      value={options.value}
      maxLength={textAreaInputMaxLength}
      aria-label="text area editor cell input"
      onChange={(e) => options.editorCallback?.(e.target.value)}
    />
  );
};

export default TextAreaEditorCell;
