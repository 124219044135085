import { UserGroupModel, UserGroupOptionModel, UserGroupState } from 'models/user-groups-model';
import { ProjectUserModel } from 'models/project-model';
import { isAdmin } from './permissions';

/**
 * Adds a generic state to a list of user groups
 *
 * @param userGroups user groups to add state to
 * @returns user groups with state
 */
export const addUserGroupState = (userGroups: UserGroupModel[]): UserGroupOptionModel[] => {
  return userGroups.map((userGroup) => ({
    ...userGroup,
    state: UserGroupState.UNCHANGED
  }));
};

/**
 * Creates a list of user groups with id, name and state
 *
 * @param userGroups list of user group ids to create options for
 * @param referenceGroups list of user group ids to compare against
 * @param allUserGroups list of all user groups
 * @returns list of user group options
 */
export const createUserGroupOptions = (
  userGroups: number[],
  allUserGroups: UserGroupModel[],
  referenceGroups?: number[]
): UserGroupOptionModel[] => {
  if (referenceGroups) {
    const added = userGroups
      .filter((userGroup) => !referenceGroups.includes(userGroup))
      .map((userGroup) => createUserGroupOption(userGroup, allUserGroups, UserGroupState.ADDED));
    const removed = referenceGroups
      .filter((userGroup) => !userGroups.includes(userGroup))
      .map((userGroup) => createUserGroupOption(userGroup, allUserGroups, UserGroupState.REMOVED));
    const unchanged = userGroups
      .filter((userGroup) => referenceGroups.includes(userGroup))
      .map((userGroup) =>
        createUserGroupOption(userGroup, allUserGroups, UserGroupState.UNCHANGED)
      );
    return [...unchanged, ...added, ...removed].sort((a, b) => a.id - b.id);
  } else {
    return userGroups.map((userGroup) =>
      createUserGroupOption(userGroup, allUserGroups, UserGroupState.UNCHANGED)
    );
  }
};

/**
 * Creates a user group option with id, name and state
 *
 * @param userGroup user group id
 * @param allUserGroups all user groups
 * @param state user group state
 * @returns user group option
 */
const createUserGroupOption = (
  userGroup: number,
  allUserGroups: UserGroupModel[],
  state: UserGroupState
): UserGroupOptionModel => {
  const group = allUserGroups.find((group) => group.id === userGroup);
  return {
    id: userGroup,
    name: group ? group.name : '',
    num_projects: group ? group.num_projects : 0,
    state: state
  };
};

/**
 * Returns a list of user group ids from a list of user group options
 *
 * @param userGroups user group options
 * @returns list of user group ids
 */
export const getUserGroupIds = (
  userGroups: UserGroupOptionModel[] | UserGroupModel[]
): number[] => {
  const groups: number[] = [];
  userGroups.forEach((userGroup) => {
    if ('state' in userGroup) {
      if (userGroup.state !== UserGroupState.REMOVED) {
        groups.push(userGroup.id);
      }
    } else {
      groups.push(userGroup.id);
    }
  });
  return groups;
};

/**
 * Checks to see if a project user shares at least one user group with a list of user groups
 *
 * @param projectUser project user to check
 * @param userGroups user groups to check against
 * @returns boolean to indicate if the project user shares at least one user group with the list of user groups
 */
export const userSharesUserGroupWith = (
  projectUser: ProjectUserModel,
  userGroups?: number[]
): boolean =>
  isAdmin(projectUser.role)
    ? true
    : userGroups === undefined ||
      userGroups.length === 0 ||
      userGroups.some((group) => projectUser.user_groups.includes(group));
