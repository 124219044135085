import { TextArea, TextField } from 'components/Forms';
import CheckboxField from 'components/Forms/CheckboxField';
import RatingField from 'components/Forms/RatingField';
import { AuthModel } from 'models/auth-model';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

/**
 * Popup to submit feedback.
 *
 * @param loading whether the form is in the process of submitting
 * @param onHide callback when hiding the popup
 * @param onSubmit callback when submitting the form
 * @param parentElement element to return focus to after the dialog is hidden
 * @param user the user
 * @param visible whether to display the popup or not
 * @component
 */
const FeedbackPopup = function ({
  loading,
  onHide,
  onSubmit,
  parentElement,
  user,
  visible
}: {
  loading?: boolean;
  onHide(): void;
  onSubmit(data: FieldValues): void;
  parentElement?: HTMLElement;
  user: AuthModel;
  visible: boolean;
}) {
  useEffect(() => {
    /**
     * Sets the default form values.
     */
    const defaultValues: FieldValues = {
      email: user.user.email,
      allow_contact: false,
      rating: 3,
      details: ''
    };
    reset(defaultValues);
  }, []);

  const { control, handleSubmit, reset } = useForm();

  // reset when closing
  useEffect(() => {
    !visible && reset();
  }, [visible]);

  /* - - - - - - - - - - Callbacks - - - - - - - - - - */
  const onPopupSave = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    handleSubmit(onSubmit)();
  };

  const hidePopup = () => {
    reset();
    onHide();
    parentElement?.focus();
  };

  return (
    <Dialog
      draggable={false}
      blockScroll={true}
      visible={visible}
      onHide={hidePopup}
      className="specto-popup feedback-popup w-full"
      contentClassName="feedback-body"
      headerClassName="feedback-header"
      header={
        <div className="feedback-title">
          <div className="flex justify-content-around">
            <h1 className="text-4xl line-height-3">{'Help us improve!'}</h1>
          </div>
          <div className="mx-18p text-center flex justify-content-around">
            <h2 className="text-base font-normal line-height-3">
              {'We value your feedback. Let us know what you think about Ask MA Studio.'}
            </h2>
          </div>
        </div>
      }
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="specto-text-medium m-0 mb-5">
          Required fields are marked with <span className="specto-form-asterisk-color">*</span>
        </div>
        <TextField
          control={control}
          label={'Your account email'}
          placeholder="myEmail@gmail.com"
          fieldName="email"
          noPadding
          className="mb-3"
          disabled
          required={false}
        />
        <CheckboxField
          control={control}
          fieldName="allow_contact"
          label="Reach out to me regarding my feedback"
        />
        <RatingField
          control={control}
          fieldName="rating"
          required
          label="Overall, how easy was it to use the Ask MA Studio?"
          oneStarText="Very Hard"
          fiveStarText="Very Easy"
        />
        <TextArea
          control={control}
          inputClassName="feedback-textarea"
          fieldName="details"
          label="Please explain more about your rating or share other suggestions"
          placeholder="Type your message..."
          requiredTextString="Please enter your feedback."
        />
        <div className="flex justify-content-center">
          <Button
            type="button"
            className="feedback-submit-button capitalize font-bold"
            label="Send Feedback"
            onClick={() => onPopupSave()}
            loading={loading}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default FeedbackPopup;
