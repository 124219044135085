/**
 * Attempts to parse a string as a json object.
 * If it fails, it returns the original string
 *
 * @param json string to parse
 * @returns parsed json or original string
 */
export const parseJson = (json: string) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return json;
  }
};
