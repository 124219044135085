import { DataTable, DataTableStateEvent } from 'primereact/datatable';
import { IconOptions } from 'primereact/utils';
import classnames from 'classnames';
import React from 'react';

/**
 * Function to change the table header sorting icons.
 * @param e sort options
 */
export const customSortIconFn = (e: IconOptions<DataTable<any>>) => (
  <span
    className={classnames(
      'p-sortable-column-icon',
      e?.sortOrder === 1 ? 'pi pi-sort-up' : e.sortOrder === -1 ? 'pi pi-sort-down' : 'pi pi-sort'
    )}
    data-pc-section="sortIcon"
  />
);

/**
 * Gets the default table state
 *
 * @returns default table state
 */
export const getDefaultTableState = () =>
  ({
    first: 0,
    rows: 5,
    page: 1,
    sortField: 'last_modified',
    sortOrder: -1,
    filters: {},
    multiSortMeta: undefined
  }) as DataTableStateEvent;

/**
 * Maps the table state to pagination options
 *
 * @param state table state
 * @returns pagination options
 */
export const mapTableStateToPagination = (state: DataTableStateEvent) => {
  return {
    limit: state.rows,
    offset: state.first,
    ordering: `${state.sortOrder === -1 ? '-' : ''}${state.sortField}`
  };
};
