import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_URL, LOGIN_API_URL, refresh_API_URL } from 'constants/api';
import TokenService from './tokenService';
import { store } from 'store';
import { logout } from 'features/auth/authSlice';

export const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
});

/**
 * Refresh Auth token & Retry on 401.
 */
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const config = error.config;

    // only retry 401's, one time
    if (error?.response?.status === 401) {
      if (config._retry) {
        // Logout user on failed consecutive retries
        store.dispatch(logout());
      } else {
        config._retry = true;
        try {
          // refresh the token unless you are getting a 401 on the login page or the refresh token endpoint
          if (![LOGIN_API_URL, refresh_API_URL].includes(config.url)) {
            const token = await TokenService.refreshToken();
            if (token) {
              config.headers.Authorization = `Bearer ${token.access}`;
              // retries call with new token
              return axiosInstance(config);
            }
          }
          // Logout user on failed refresh
          store.dispatch(logout());
          return Promise.reject(error);
        } catch (err) {
          return Promise.reject(err);
        }
      }
    }

    // default
    return Promise.reject(error);
  }
);

/**
 * Set token bearer header in the Axios instance
 * @param accessToken
 */
const setHeaderTokenBearer = (accessToken: string) => {
  axiosInstance.defaults.headers.common.Authorization = 'Bearer ' + accessToken;
};

/**
 * Remove token bearer header in the Axios instance
 */
const removeHeaderTokenBearer = () => {
  delete axiosInstance.defaults.headers.common.Authorization;
};

/**
 * Expecting "Bearer <some-token>" so split and return just the token
 */
const getHeaderTokenBearer = (): string | undefined => {
  const tokenArray =
    axiosInstance.defaults.headers.common?.Authorization?.toString?.().split?.(' ');
  return tokenArray?.length === 2 ? tokenArray[1] : undefined;
};

/**
 * Generic get request.
 * @param url: string url of request
 */
const get = <T>(url: string, headers?: AxiosRequestConfig): Promise<T> => {
  return axiosInstance
    .get(url, headers)
    .then((response: AxiosResponse<T>) => {
      return response.data;
    })
    .catch((error: AxiosError) => Promise.reject(error));
};

/**
 * Generic post request.
 * @param url: string url of request
 * @param data: data type of generic E
 * @param headers: custom headers if needed
 */
const post = <T, E = any>(url: string, data?: E, headers?: AxiosRequestConfig): Promise<T> => {
  return axiosInstance
    .post(url, data, headers)
    .then((response: AxiosResponse<T>) => {
      return response.data;
    })
    .catch((error: AxiosError) => Promise.reject(error));
};

/**
 * Generic put request.
 * @param url: string url of request
 * @param data: data type of generic E
 * @param headers: custom headers if needed
 */
const put = <T, E = any>(url: string, data?: E, headers?: AxiosRequestConfig): Promise<T> => {
  return axiosInstance
    .put(url, data, headers)
    .then((response: AxiosResponse<T>) => {
      return response.data;
    })
    .catch((error: AxiosError) => Promise.reject(error));
};

/**
 * Generic post request.
 * @param url: string url of request
 * @param data: data type of generic E
 * @param headers: custom headers if needed
 */
const patch = <T = unknown, E = any>(
  url: string,
  data?: E,
  headers?: AxiosRequestConfig
): Promise<T> => {
  return axiosInstance
    .patch(url, data, headers)
    .then((response: AxiosResponse<T>) => {
      return response.data;
    })
    .catch((error: AxiosError) => Promise.reject(error));
};

/**
 * Generic delete request.
 * @param url: string url of request
 */
const remove = <T>(url: string): Promise<T> => {
  return axiosInstance
    .delete(url)
    .then((response: AxiosResponse<T>) => {
      return response.data;
    })
    .catch((error: AxiosError) => Promise.reject(error));
};

const ApiService = {
  get,
  post,
  put,
  patch,
  remove,
  setHeaderTokenBearer,
  removeHeaderTokenBearer,
  getHeaderTokenBearer
};

export default ApiService;
