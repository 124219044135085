import React, { useContext } from 'react';
import { ResponseFormContext } from './ResponseFormContext';
import { TextField } from 'components/Forms';
import ResponseComponentContainer from './ResponseComponentContainer';
import { ResponseTypeNames, ResponseTypeOptions } from 'models/response-table-data-model';
import { urlInputMaxLength } from 'constants/inputs';

/**
 * Component that displays dynamic fields to define
 * an "image", "video", "Google form", "PDF" or "YouTube Playlist"
 * or anything that utilizes a single URL field
 * @component
 */
const ResponseSingleField = function ({
  componentIndex,
  type,
  altText,
  info
}: {
  componentIndex: number;
  type: ResponseTypeOptions;
  altText?: boolean;
  info?: string;
}) {
  const { control, getValues } = useContext(ResponseFormContext);
  const baseField = `content.${componentIndex}`;
  const contentField = `${baseField}.content`;
  const contentValueField = `${baseField}.contentValue`;

  return (
    <ResponseComponentContainer
      componentIndex={componentIndex}
      title={ResponseTypeNames[type]}
      key={componentIndex}
    >
      <>
        <TextField
          characterLimit={urlInputMaxLength}
          className="w-full pt-2 pb-1 mb-0"
          label={`${ResponseTypeNames[type]} URL`}
          defaultValue={getValues(contentField + '.url')}
          fieldName={contentValueField + '.url'}
          control={control}
          info={info}
        />
        {altText && (
          <TextField
            className="w-full pb-2"
            label={`Image Alt Text`}
            defaultValue={getValues(contentField + '.alt_text')}
            fieldName={contentValueField + '.alt_text'}
            control={control}
            noPadding
            info="This is descriptive text that conveys the meaning and context of an image. It's used for screen readers or if the image cannot be rendered."
          />
        )}
      </>
    </ResponseComponentContainer>
  );
};

export default ResponseSingleField;
