import React, { useContext } from 'react';
import { Button } from 'primereact/button';
import { TextField } from 'components/Forms';
import DropdownField from 'components/Forms/DropdownField';
import { ResponseFormContext } from './ResponseFormContext';
import { useAppSelector } from 'hooks/store';
import { intentsSelector, projectsSelector } from 'features/projects/projectsSlice';
import { useFieldArray } from 'react-hook-form';
import { getDefaultResponseContent } from 'constants/responses';
import ResponseComponentContainer from './ResponseComponentContainer';
import RemovableFormField from './RemovableFormField';
import { DragSort, SortableFormComponent } from 'features/drag-sort';
import { reorderResponseFields } from 'util/response';
import { ResponseTypeOptions } from 'models/response-table-data-model';
import { filterRetired } from 'util/status';
import { GenericItemTemplate } from 'components/Table';
import { isAdmin } from 'util/permissions';
import Info from 'components/Forms/Info';

/**
 * Component that displays dynamic fields to define a "buttons response"
 *
 * @param componentIndex Index of parent component.
 *
 * @component
 */
const ResponseButton = function ({ componentIndex }: { componentIndex: number }) {
  const contentField = `content.${componentIndex}.content`;
  const { control, moveField, getValues } = useContext(ResponseFormContext);
  const allIntents = useAppSelector(intentsSelector);
  const { selectedProject } = useAppSelector(projectsSelector);
  const { append, remove, fields, replace } = useFieldArray({
    control,
    name: contentField
  });

  const onReorder = (components: SortableFormComponent[]) =>
    replace(
      reorderResponseFields(components, fields, getValues()?.content?.[componentIndex]?.content)
    );

  return (
    <ResponseComponentContainer
      className="specto-response-button-container"
      componentIndex={componentIndex}
      title="Button"
      key={componentIndex}
      move={moveField}
    >
      <div className="w-full specto-response-button">
        <div className="grid">
          <div className="flex col pb-0 col-offset-1">
            <h6 className="mb-0 ml-1">Label</h6>
            <Info text="The text that will appear on the button" />
          </div>
          <div className="flex col pb-0">
            <h6 className="mb-0 ml-2">Intent</h6>
            <Info text="The Intent that will be triggered when the button is clicked" />
          </div>
          <div className="col-1 sm:col-2 flex justify-content-center pb-0" />
        </div>
        <DragSort onReorder={onReorder}>
          {fields.map((item, index) => (
            <RemovableFormField
              roundDeleteButton
              key={item.id}
              onRemove={() => remove(index)}
              disabled={fields.length < 2 || !isAdmin(selectedProject.project_user.role)}
            >
              <TextField
                /**
                 * Name needs to be "[useFormArrayName].[index].[fieldname]" to properly map field
                 * data to the final useFormArray data object
                 */
                className="col flex flex-wrap align-items-center mb-0"
                fieldName={`content.${componentIndex}.content.${index}.label`}
                noPadding
                control={control}
                placeholder="Enter text that should appear on the button."
                ariaLabel={`row ${index} label`}
                disabled={!isAdmin(selectedProject.project_user.role)}
              />
              <DropdownField
                /**
                 * Name needs to be "[useFormArrayName].[index].[fieldname]" to properly map field
                 * data to the final useFormArray data object
                 */
                className="col flex flex-wrap align-items-center overflow-hidden"
                fieldName={`content.${componentIndex}.content.${index}.payload`}
                placeholder="Select an Intent"
                filter
                control={control}
                options={filterRetired(
                  allIntents,
                  getValues(`content.${componentIndex}.content.${index}.payload`)
                )}
                itemTemplate={(item) => (
                  <GenericItemTemplate
                    id={item?.id}
                    itemName="intent"
                    allOptions={allIntents}
                    showRetiredIcon
                  />
                )}
                valueTemplate={(item) => (
                  <GenericItemTemplate
                    id={item?.id}
                    itemName="intent"
                    allOptions={allIntents}
                    showRetiredIcon
                  />
                )}
                optionLabel="name"
                optionValue="id"
                appendTo={null}
                noPadding
                disabled={!isAdmin(selectedProject.project_user.role)}
              />
            </RemovableFormField>
          ))}
        </DragSort>
        <div className="flex justify-content-end ml-5">
          <Button
            className="font-semibold specto-text-medium p-button-text underline text-sm py-2 w-auto"
            label="ADD BUTTON"
            type="button"
            onClick={() => append(getDefaultResponseContent(ResponseTypeOptions.BUTTONS)[0])}
            data-cy="add-button-option"
            disabled={!isAdmin(selectedProject.project_user.role)}
          />
          <div className="col-2"></div>
        </div>
      </div>
    </ResponseComponentContainer>
  );
};

export default ResponseButton;
