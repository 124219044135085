import React, { useEffect } from 'react';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
// components
import Popup from 'containers/Popup';
import ResponseDropdown from './ResponseDropdown';
import ResponseMultiselect from './ResponseMultiselect';
import ResponseSingleField from './ResponseSingleField';
import ResponseCarousel from './ResponseCarousel';
import ResponseListGroup from './ResponseListGroup';
import ResponseVideoPlaylist from './ResponseVideoPlaylist';
import ResponseRichText from './ResponseRichText';
import ResponseButtons from './ResponseButton';
import TagsField from 'components/Forms/TagsField';
// models
import {
  ResponseTableAPIModel,
  ResponseTableDataModel,
  ResponseTypeNames,
  ResponseTypeOptions
} from 'models/response-table-data-model';
// constants
import { getDefaultResponseContent } from 'constants/responses';
import { TextField, ToggleSwitch } from 'components/Forms';
import { ResponseFormContext, ResponseFormContextModel } from './ResponseFormContext';
import ResponseCard from './ResponseCard';
import ResponseTableCSV from './ResponseTableCSV';
import ResponseCustomAction from './ResponseCustomAction';
import { FormMode } from 'models/form-model';
import { PublishingNameConstraint } from 'constants/namingConstraint';
import { PublishingNameWarning } from 'constants/warnings';
import ResponseLiveChat from './ResponseLiveChatContent';
import ResponseOptionsList from './ResponseOptionsList';
import MenuButton from 'components/MenuButton/MenuButton';
import { flattenJSON } from 'util/form';
import UserGroupsField from 'components/UserGroups/UserGroupsField';
import { UserGroupModel } from 'models/user-groups-model';
import { createUserGroupOptions, getUserGroupIds } from 'util/userGroups';
import { ProjectMemberUserModel } from 'models/project-model';
import AutocompleteField from 'components/Forms/AutocompleteField';
import { filterAuthors } from 'util/users';
import Info from 'components/Forms/Info';

type ResponsePopupPropsModel = {
  allUserGroups: UserGroupModel[];
  contextResponse: ResponseTableDataModel | null;
  customActionMode?: boolean;
  displayPopup: boolean;
  formMode?: FormMode;
  isSuperUser?: boolean;
  isAdmin?: boolean;
  loading: boolean;
  onHide(): void;
  onSubmit(data: FieldValues): void;
  parentElement?: HTMLElement;
  errors?: Record<string, any>;
  projectUser: ProjectMemberUserModel;
  authors: ProjectMemberUserModel[];
};

/**
 * Response Popup to create or edit responses.
 * @param allUserGroups all user groups
 * @param contextResponse context response
 * @param customActionMode whether the response is a custom action
 * @param displayPopup whether to show or hide the popup
 * @param formMode form mode, such as Create or Edit
 * @param isSuperUser if the user is a superuser
 * @param isAdmin if the user is an admin or superuser
 * @param loading if the popup is loading
 * @param onHide hide popup callback
 * @param onSubmit submit callback
 * @param parentElement parent element housing the popup
 * @param errors errors from the form
 * @param projectUser the current project user
 * @param authors Lsit of current project members
 * @constructor
 */
const ResponsePopup = function ({
  allUserGroups,
  contextResponse,
  customActionMode,
  displayPopup,
  formMode = FormMode.CREATE,
  isSuperUser = false,
  isAdmin = false,
  loading,
  onHide,
  onSubmit,
  parentElement,
  errors,
  projectUser,
  authors
}: ResponsePopupPropsModel) {
  const modeTitle = customActionMode ? 'Custom Action' : 'Response';

  const omittedNewComponentOptions = [
    ResponseTypeOptions.BROWSING_CAROUSEL,
    ResponseTypeOptions.CUSTOM_ACTION,
    ResponseTypeOptions.CUSTOM_FORM,
    ResponseTypeOptions.MULTISELECT,
    ...(!isAdmin
      ? [
          ResponseTypeOptions.BUTTONS,
          ResponseTypeOptions.DROPDOWN,
          ResponseTypeOptions.LIVE_CHAT,
          ResponseTypeOptions.OPTIONS_LIST
        ]
      : [])
  ];

  const titles: Partial<
    Record<FormMode, { header: string; saveButtonText: string; subtitle?: string }>
  > = {
    [FormMode.CREATE]: {
      header: `New ${modeTitle}`,
      subtitle: `Create a new ${modeTitle}`,
      saveButtonText: `Create ${modeTitle}`
    },
    [FormMode.EDIT]: {
      header: `Edit ${modeTitle}`,
      subtitle: `Modify an existing ${modeTitle}`,
      saveButtonText: `Submit ${modeTitle}`
    },
    [FormMode.DUPLICATE]: {
      header: `Duplicate ${modeTitle}`,
      saveButtonText: `Create ${modeTitle}`,
      subtitle: 'Values from the original row have been pre-filled'
    }
  };

  const createComponent = (type: ResponseTypeOptions) => ({
    type: type,
    content: getDefaultResponseContent(type)
  });

  const defaultValues: FieldValues = {
    name: '',
    tags: [],
    content: customActionMode ? [createComponent(ResponseTypeOptions.CUSTOM_ACTION)] : [],
    feedback_icons: true,
    user_groups: [],
    author: projectUser?.user
  };

  const formMethods = useForm({ defaultValues });
  // field array for content
  const contentFieldArrayMethods = useFieldArray({
    control: formMethods.control,
    name: 'content'
  });

  const filteredAuthors = filterAuthors(
    isSuperUser,
    authors,
    formMode === FormMode.CREATE ? projectUser.user : contextResponse?.author
  );

  useEffect(() => {
    if (typeof errors === 'object') {
      const flattenedErrors = flattenJSON(errors);
      Object.entries(flattenedErrors).forEach(([key, value]) => {
        formMethods.setError(key, { type: 'custom', message: value });
      });
    }
  }, [errors]);

  /** Create context object from useForm and useField array
   *  Need to rename useFieldArray methods since they are called multiple times
   */
  const allFormMethods: ResponseFormContextModel = {
    ...formMethods,
    contentFields: contentFieldArrayMethods.fields,
    appendContent: contentFieldArrayMethods.append,
    removeContent: contentFieldArrayMethods.remove,
    moveField: contentFieldArrayMethods.move
  };

  /**
   * Conditionally disable the submit button. When to disable:
   *
   *    1) When no fields have been modified by the user
   *
   *    2) When only the status has changed, and it was changed to its original value before
   *    editing. When opened in edit mode, the form will default to a changed status. If the only
   *    change the user makes is switching the status back to the status the response was before
   *    editing, don't allow them to submit.
   *
   *    3) Always enable with Form mode is DUPLICATE
   */
  const submitDisabled =
    formMode !== FormMode.DUPLICATE &&
    (Object.keys(formMethods.formState.dirtyFields).length === 0 ||
      (contextResponse?.status === formMethods.watch('status') &&
        Object.keys(formMethods.formState.dirtyFields).length === 1 &&
        Object.keys(formMethods.formState.dirtyFields).includes('status')));

  const responseComponentOptions = Object.values(ResponseTypeOptions)
    .filter(
      (responseType: ResponseTypeOptions) => !omittedNewComponentOptions.includes(responseType)
    )
    .map((option) => {
      return {
        label: ResponseTypeNames[option],
        command: () => appendComponent(option)
      };
    });

  /* - - - - - - - - - - Effects - - - - - - - - - - */

  /**
   * Sets the default form values.
   *
   * Needs to be done in an effect and not on useForm hook call because the statuses are not
   * immediately available on component mount. There is a delay while they are pulled.
   */
  useEffect(() => {
    const defaultValuesToReset: FieldValues = contextResponse
      ? {
          name: contextResponse.name,
          tags: contextResponse.tags,
          content: contextResponse.content,
          feedback_icons: contextResponse.feedback_icons,
          user_groups: createUserGroupOptions(
            contextResponse.user_groups,
            allUserGroups,
            contextResponse.reference?.user_groups
          ),
          author: contextResponse.author
        }
      : defaultValues;

    formMethods.reset(defaultValuesToReset);
  }, [contextResponse]);

  /* - - - - - - - - - - Callbacks - - - - - - - - - - */

  // reset the form and field array
  const resetForm = () => {
    formMethods.reset();
    allFormMethods.reset();
    contentFieldArrayMethods.replace([]);
  };

  const submitPreprocess = (data: FieldValues) => {
    /** Format the data from FieldValues to ResponseTableAPIModel
     *
     * By default, field values places all content fields in the first level of the object. Combine
     * all content fields into a content object.
     *
     * Only set content fields if the fields were active on submission. Fields are not cleared in
     * a popup session when they are deactivated/hidden in case the user switches back to using
     * the field, allowing the form to repopulate with the draft data. Fields are only cleared
     * when closing the popup.
     */
    const responseData: ResponseTableAPIModel = {
      name: data.name,
      status: data.status,
      // map contentValue back to content
      content: JSON.parse(JSON.stringify(data.content).replace(/,"contentValue":/g, ',"content":')),
      tags: data.tags,
      feedback_icons: data.feedback_icons,
      user_groups: getUserGroupIds(data.user_groups),
      author: data.author
    };

    onSubmit(responseData);
  };

  const onPopupSave = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    allFormMethods.handleSubmit(submitPreprocess)();
  };

  const hidePopup = () => {
    resetForm();
    onHide();
    parentElement?.focus();
  };

  // call onHide when closing form, especially with Submit action
  useEffect(() => {
    !displayPopup && hidePopup();
  }, [displayPopup]);

  const appendComponent = (type: ResponseTypeOptions, removeAll?: boolean) => {
    removeAll && allFormMethods.reset();
    allFormMethods.appendContent(createComponent(type));
  };

  return (
    <Popup
      title={titles[formMode]?.header ?? ''}
      visible={displayPopup}
      loading={loading}
      saveButtonText={titles[formMode]?.saveButtonText}
      subtitle={titles[formMode]?.subtitle}
      onHide={hidePopup}
      onSave={onPopupSave}
      className="xl:w-7"
      submitDisabled={submitDisabled}
    >
      {/* Didn't use form context because extra setter functions are passed through */}
      <ResponseFormContext.Provider value={allFormMethods}>
        <form className="p-fluid" onSubmit={onPopupSave} aria-hidden="true">
          <div className="specto-text-medium -mt-2 mb-5">
            Required fields are marked with <span className="specto-form-asterisk-color">*</span>
          </div>
          <TextField
            fieldName="name"
            control={formMethods.control}
            label="Name"
            disabled={formMode === FormMode.EDIT}
            info={
              formMode === FormMode.EDIT
                ? "Response names can't be edited. Please duplicate or create a new Response instead."
                : 'This is the unique name that the Response will be referred to; it cannot be changed later.'
            }
            rules={{
              pattern: {
                value: PublishingNameConstraint,
                message: PublishingNameWarning
              }
            }}
          />
          {isSuperUser && <TagsField control={formMethods.control} />}
          <UserGroupsField
            control={formMethods.control}
            userGroups={allUserGroups}
            projectUser={projectUser}
            mode={formMode}
          />
          <AutocompleteField
            labelClassName="line-height-3"
            required={true}
            control={formMethods.control}
            options={filteredAuthors}
            optionLabel="name_reversed"
            optionValue="user"
            fieldName="author"
            label="Author"
            placeholder="Select Author or start typing"
            info="Author of this Response"
          />
          {!customActionMode && (
            <div className="flex flex-column">
              <div className="flex">
                <span>Feedback Icons</span>
                <Info text="Enable this option to add thumbs-up and thumbs-down feedback icons below this Response." />
              </div>
              <ToggleSwitch
                fieldName="feedback_icons"
                control={formMethods.control}
                className="mt-2"
                ariaDescribedBy="feedback-icon-label"
                trueLabel="On"
                falseLabel="Off"
              />
            </div>
          )}
          {customActionMode ? (
            <h5 className="mb-2">Description</h5>
          ) : (
            <h6 className="mb-5">
              Response Content <span className="specto-form-asterisk-color">*</span>
            </h6>
          )}
          {contentFieldArrayMethods.fields?.map((field: any, index: number) => {
            const responseType = field.type as ResponseTypeOptions;
            if (!responseType) return <div key={field.id}></div>;

            const ComponentInfo = responseTypeToComponent[responseType];
            if (!ComponentInfo) return <div key={field.id}></div>;

            const { component: Component, props: extraProps } = ComponentInfo;
            return <Component key={field.id} componentIndex={index} {...extraProps} />;
          })}
          {!customActionMode && (
            <div className="flex justify-content-end">
              <MenuButton
                options={responseComponentOptions}
                buttonProps={{
                  className: 'w-auto mt-5',
                  label: 'Add Content',
                  icon: 'pi pi-plus',
                  iconPos: 'right',
                  type: 'button'
                }}
              />
            </div>
          )}
        </form>
      </ResponseFormContext.Provider>
    </Popup>
  );
};

const responseTypeToComponent: Partial<
  Record<
    ResponseTypeOptions,
    {
      component: React.FC<any>;
      props?: Record<string, any>;
    }
  >
> = {
  [ResponseTypeOptions.TEXT]: {
    component: ResponseRichText
  },
  [ResponseTypeOptions.BUTTONS]: {
    component: ResponseButtons
  },
  [ResponseTypeOptions.DROPDOWN]: {
    component: ResponseDropdown
  },
  [ResponseTypeOptions.MULTISELECT]: {
    component: ResponseMultiselect
  },
  [ResponseTypeOptions.IMAGE]: {
    component: ResponseSingleField,
    props: {
      altText: true,
      type: ResponseTypeOptions.IMAGE,
      info: 'The URL of the image to display'
    }
  },
  [ResponseTypeOptions.GOOGLE_FORM]: {
    component: ResponseSingleField,
    props: {
      altText: false,
      type: ResponseTypeOptions.GOOGLE_FORM,
      info: 'The URL of the Google Form'
    }
  },
  [ResponseTypeOptions.VIDEO]: {
    component: ResponseSingleField,
    props: {
      altText: false,
      type: ResponseTypeOptions.VIDEO,
      info: 'The URL of the video to display'
    }
  },
  [ResponseTypeOptions.PDF]: {
    component: ResponseSingleField,
    props: { altText: false, type: ResponseTypeOptions.PDF, info: 'The URL of the PDF to display' }
  },
  [ResponseTypeOptions.YOUTUBE_PLAYLIST]: {
    component: ResponseSingleField,
    props: {
      altText: false,
      type: ResponseTypeOptions.YOUTUBE_PLAYLIST,
      info: 'The URL of the YouTube Playlist to display'
    }
  },
  [ResponseTypeOptions.LIST_GROUP]: {
    component: ResponseListGroup
  },
  [ResponseTypeOptions.CARD]: {
    component: ResponseCard
  },
  [ResponseTypeOptions.CAROUSEL]: {
    component: ResponseCarousel
  },
  [ResponseTypeOptions.BROWSING_CAROUSEL]: {
    component: ResponseCarousel
  },
  [ResponseTypeOptions.VIDEO_PLAYLIST]: {
    component: ResponseVideoPlaylist
  },
  [ResponseTypeOptions.TABLE]: {
    component: ResponseTableCSV
  },
  [ResponseTypeOptions.CUSTOM_ACTION]: {
    component: ResponseCustomAction
  },
  [ResponseTypeOptions.LIVE_CHAT]: {
    component: ResponseLiveChat
  },
  [ResponseTypeOptions.OPTIONS_LIST]: {
    component: ResponseOptionsList
  }
};

export default ResponsePopup;
