import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createToast } from 'features/toast/toastSlice';
import { EntityFieldArrayValues, EntityFormContext } from './EntityContextModel';
import { Entity, EntityLabelerOffset, EntityLabelRange } from './entity-labelling-model';
import {
  BaseEditor,
  BaseSelection,
  createEditor,
  Descendant,
  Range,
  Text,
  Transforms
} from 'slate';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';

type CustomElement = { type: 'paragraph'; children: CustomText[] };
type CustomText = { text: string; bold?: boolean };

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

type EntityLabelerProps = {
  text: string;
  allEntities: Entity[];
  focusEntityRange: EntityLabelRange | null;
};

const EntityLabeler = function ({ text, focusEntityRange }: EntityLabelerProps) {
  const dispatch = useDispatch();
  const { appendEntity, entityFields } = useContext(EntityFormContext);
  const [editor] = useState<ReactEditor>(() => withReact(createEditor()));
  const [focusRange, setFocusRange] = useState<EntityLabelRange | null>(focusEntityRange ?? null);
  const initialValue: Descendant[] = [{ type: 'paragraph', children: [{ text: text }] }];

  // internal range change that triggers focus change inside Entity Labeler
  useEffect(() => {
    focusRange ? focusEntity(focusRange[0], focusRange[1]) : clearEditor();
  }, [focusRange]);

  // external range change that triggers focus change inside the Entity Labeler
  useEffect(() => setFocusRange(focusEntityRange), [focusEntityRange]);

  const clearEditor = () => {
    Transforms.select(editor, [0, 0]);
    Transforms.removeNodes(editor, { at: [0], voids: true });
    Transforms.insertNodes(editor, initialValue);
  };

  const boldSelectedText = (start: number, end: number) => {
    Transforms.select(editor, {
      anchor: { path: [0, 0], offset: start },
      focus: { path: [0, 0], offset: end }
    });
    Transforms.setNodes(
      editor,
      { bold: true },
      {
        match: (n: any) => Text.isText(n),
        split: true,
        at: {
          anchor: { path: [0, 0], offset: start },
          focus: { path: [0, 0], offset: end }
        }
      }
    );
  };

  /***
   * Convert slate selection across multiple nested spans to simple indices as if it was plaintext
   * @param selection slate rich-text selection
   */
  const convertToFlattenedSelection = (
    selection: BaseSelection
  ): EntityLabelerOffset | undefined => {
    if (!selection || Range.isCollapsed(selection)) {
      return;
    }

    const content: CustomText[] = (editor.children[0] as any)?.children;

    // reverse range when selected backwards
    const originalAnchor = Range.isForward(selection) ? selection.anchor : selection.focus;
    const originalFocus = Range.isForward(selection) ? selection.focus : selection.anchor;

    // initialize to original offsets
    let start = originalAnchor.offset;
    let end = originalFocus.offset;

    // loop for index of all children
    const pathStart = originalAnchor.path[1];
    const pathEnd = originalFocus.path[1];

    // add the length of the text of the child nodes
    for (let i = 0; i < pathStart; i++) {
      start += content[i].text.length;
    }

    for (let i = 0; i < pathEnd; i++) {
      end += content[i].text.length;
    }

    return { trueAnchorOffset: start, trueFocusOffset: end };
  };

  const focusEntity = (start: number, end: number) => {
    clearEditor();

    boldSelectedText(start, end);
  };

  const onLabelEntity = () => {
    const selection = convertToFlattenedSelection(editor.selection);

    if (!selection) {
      return;
    }

    const selectionText = text.substring(selection.trueAnchorOffset, selection.trueFocusOffset);

    // selection adjust to trim trailing whitespace (esp. on Windows)
    if (selectionText.slice(-1) === ' ') {
      selection.trueFocusOffset -= 1;
    }

    // selection adjust to trim leading whitespace
    if (selectionText[0] === ' ') {
      selection.trueAnchorOffset += 1;
    }

    const entity: EntityFieldArrayValues = {
      start: selection.trueAnchorOffset,
      end: selection.trueFocusOffset,
      entity: null,
      role: null,
      group: null
    };

    if (
      entityFields.some(
        (entityField) =>
          Number(entityField?.start) === entity?.start && Number(entityField?.end) === entity?.end
      )
    ) {
      dispatch(createToast('duplicate entity label', 'warn'));
    } else {
      appendEntity(entity);
    }

    setFocusRange([selection.trueAnchorOffset, selection.trueFocusOffset]);
  };

  // Define a leaf rendering function that is memoized with `useCallback`.
  const renderLeaf = useCallback((props: any) => {
    return <Leaf {...props} />;
  }, []);

  return (
    <Slate editor={editor} initialValue={initialValue}>
      <Editable
        onMouseUp={onLabelEntity}
        readOnly={true}
        onDoubleClick={onLabelEntity}
        className="specto-input-text"
        style={{ minHeight: '5rem' }}
        renderLeaf={renderLeaf}
      />
    </Slate>
  );
};

const Leaf = (props: any) => {
  return (
    <span
      aria-label="focused entity"
      {...props.attributes}
      className={props.leaf.bold && 'specto-accent-bg-light font-semibold'}
    >
      {props.children}
    </span>
  );
};

export default EntityLabeler;
