import React, { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
// components
import Popup from 'containers/Popup';
import { DropdownField, TextField } from 'components/Forms';
// models
import { RegexTableDataModel } from 'models/regex-model';
import { EntityTableDataModel } from 'models/entity-model';
// constants
// util
import { filterRetired } from 'util/status';
import { FormMode } from 'models/form-model';
import { GenericItemTemplate } from 'components/Table';

type NewRegexPopupPropsModel = {
  allEntities: EntityTableDataModel[];
  contextRegex?: RegexTableDataModel | null;
  displayPopup: boolean;
  loading?: boolean;
  mode?: FormMode;
  onHide(): void;
  onSubmit(data: FieldValues): void;
  parentElement?: HTMLElement;
};

/**
 * Component that displays a popup to create a new regex.
 * @component
 * @param param0 Prop object containing the following:
 *
 *    allEntities: list of all entities
 *    contextRegex: the context RegEx
 *    displayPopup: Boolean representing whether to display the popup or not.
 *    loading: loading state
 *    mode: form mode
 *    onHide: callback for hiding the form
 *    onSubmit:  callback for when the form is submitted
 *    parentElement: Element to return focus to after the dialog is hidden
 *
 * @returns
 */
const RegexPopup = function ({
  allEntities,
  contextRegex,
  displayPopup,
  loading,
  mode = FormMode.CREATE,
  onHide,
  onSubmit,
  parentElement
}: NewRegexPopupPropsModel) {
  const { control, handleSubmit, reset, formState } = useForm();

  /* - - - - - - - - - - Effects - - - - - - - - - - */

  useEffect(() => {
    const defaultValues: FieldValues = contextRegex ?? {
      pattern: '',
      entity: null
    };
    reset(defaultValues);
  }, [contextRegex]);

  // reset when closing
  useEffect(() => {
    !displayPopup && reset();
  }, [displayPopup]);

  /* - - - - - - - - - - Callbacks - - - - - - - - - - */

  const onPopupSave = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    handleSubmit(onSubmit)();
  };

  const hidePopup = () => {
    reset();
    onHide();
    parentElement?.focus();
  };

  return (
    <Popup
      title={mode === FormMode.CREATE ? 'New Regex' : 'Edit Regex'}
      subtitle={mode === FormMode.CREATE ? 'Create a new Regex' : 'Modify an existing Regex'}
      visible={displayPopup}
      loading={loading}
      onHide={hidePopup}
      onSave={onPopupSave}
    >
      <form className="p-fluid" onSubmit={onPopupSave}>
        <div className="specto-text-medium -mt-2 mb-5">
          Required fields are marked with <span className="specto-form-asterisk-color">*</span>
        </div>

        <TextField fieldName="pattern" label="Pattern" control={control} />
        <DropdownField
          label="Entity"
          required
          fieldName="entity"
          placeholder="Select an Entity"
          filter
          control={control}
          options={filterRetired(allEntities, formState.defaultValues?.entity)}
          itemTemplate={(item) =>
            GenericItemTemplate({
              id: item.id,
              itemName: 'entity',
              allOptions: allEntities,
              showRetiredIcon: true
            })
          }
          optionLabel="name"
          optionValue="id"
          appendTo={null}
        />
      </form>
    </Popup>
  );
};

export default RegexPopup;
