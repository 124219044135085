import ApiService from 'services/apiService';
import { EntityTableAPIModel, EntityTableDataModel } from 'models/entity-model';
import { APPROVE_API_URL, ENTITIES_API_URL, PUBLISH_API_URL, RETIRE_API_URL } from 'constants/api';
import {
  RetiredOptionsModel,
  SlugDataIdOptionsModel,
  SlugDataOptionsModel,
  SlugIdOptionsModel,
  SlugQueryOptionsModel
} from 'models/api-model';
import { Permissions } from 'models/permission-model';
import { ProjectRole } from 'models/project-model';
import { PaginationModel } from 'models/filter-model';

const getEntities = (options: SlugQueryOptionsModel) =>
  ApiService.get<EntityTableDataModel[]>(
    '/' + options.slug + ENTITIES_API_URL + (options.query ?? '')
  );

const getPaginatedEntities = (options: SlugQueryOptionsModel) =>
  ApiService.get<PaginationModel<EntityTableDataModel>>(
    '/' + options.slug + ENTITIES_API_URL + (options.query ?? '')
  );

const getEntity = (options: SlugIdOptionsModel) =>
  ApiService.get<EntityTableDataModel>('/' + options.slug + ENTITIES_API_URL + options.id + '/');

const createEntity = (options: SlugDataOptionsModel<EntityTableAPIModel>) =>
  ApiService.post<EntityTableDataModel, EntityTableAPIModel>(
    '/' + options.slug + ENTITIES_API_URL,
    options.data
  );

const deleteEntity = (options: SlugIdOptionsModel) =>
  ApiService.remove<EntityTableDataModel>('/' + options.slug + ENTITIES_API_URL + options.id + '/');

const editEntity = (options: SlugDataIdOptionsModel<EntityTableAPIModel>) =>
  ApiService.put<EntityTableDataModel>(
    '/' + options.slug + ENTITIES_API_URL + options.id + '/',
    options.data
  );

const retireEntity = (options: RetiredOptionsModel) =>
  ApiService.put<EntityTableDataModel>(
    '/' + options.slug + ENTITIES_API_URL + options.id + RETIRE_API_URL,
    options.retired
  );

const publishEntity = (options: SlugIdOptionsModel) =>
  ApiService.post<EntityTableDataModel>(
    '/' + options.slug + ENTITIES_API_URL + options.id + PUBLISH_API_URL
  );

const approveEntity = (options: SlugIdOptionsModel) =>
  ApiService.post<EntityTableDataModel>(
    '/' + options.slug + ENTITIES_API_URL + options.id + APPROVE_API_URL
  );

const entitiesServiceRoles: Permissions = {
  list: [ProjectRole.NEUROSOPH],
  retrieve: [ProjectRole.NEUROSOPH],
  create: [ProjectRole.NEUROSOPH],
  update: [ProjectRole.NEUROSOPH],
  delete: [ProjectRole.NEUROSOPH]
};

const EntitiesService = {
  approveEntity,
  createEntity,
  deleteEntity,
  editEntity,
  getEntities,
  getEntity,
  getPaginatedEntities,
  publishEntity,
  retireEntity,
  roles: entitiesServiceRoles
};

export default EntitiesService;
