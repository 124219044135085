import { uniqueId } from 'util/uniqueIdGenerator';
import {
  ResponseGenericModel,
  ResponseTypeNames,
  ResponseTypeOptions
} from 'models/response-table-data-model';
import parse from 'html-react-parser';
import React, { useRef } from 'react';
import { Image } from 'primereact/image';
import { Tooltip } from 'primereact/tooltip';
import { truncateFuzzy } from 'util/strings';

/**
 * Returns the content of a response type component along with generating a unique id
 * @param type response type
 */
export const getDefaultResponseContent = (type: ResponseTypeOptions): any => {
  switch (type) {
    // Use fall through as all these types return the same value
    case ResponseTypeOptions.TEXT:
    case ResponseTypeOptions.CUSTOM_ACTION:
      return { text: '' };
    case ResponseTypeOptions.IMAGE:
      return { url: '', alt_text: '' };
    case ResponseTypeOptions.VIDEO:
    case ResponseTypeOptions.GOOGLE_FORM:
    case ResponseTypeOptions.PDF:
    case ResponseTypeOptions.YOUTUBE_PLAYLIST:
      return { url: '' };
    case ResponseTypeOptions.TABLE:
      return { name: '', description: '', value: '' };
    case ResponseTypeOptions.BUTTONS:
      return [
        {
          id: uniqueId('btn'),
          label: '',
          payload: -1
        }
      ];
    case ResponseTypeOptions.DROPDOWN:
      return {
        placeholder: '',
        elements: [
          {
            id: uniqueId('dd'),
            label: '',
            payload: -1
          }
        ]
      };
    case ResponseTypeOptions.MULTISELECT:
      return {
        placeholder: '',
        elements: [
          {
            id: uniqueId('ms'),
            label: ''
          }
        ]
      };
    case ResponseTypeOptions.LIST_GROUP:
      return {
        title: '',
        elements: [
          {
            id: uniqueId('lg'),
            primary_text: '',
            secondary_text: '',
            image: '',
            payload: '',
            target: '_self'
          }
        ]
      };
    case ResponseTypeOptions.CARD:
      return {
        id: uniqueId('cd'),
        primary_text: '',
        secondary_text: '',
        image: '',
        payload: '',
        target: '_self'
      };
    case ResponseTypeOptions.CAROUSEL:
      return [
        {
          id: uniqueId('car'),
          primary_text: '',
          secondary_text: '',
          image: '',
          payload: '',
          payload_label: '',
          target: '_self'
        }
      ];
    case ResponseTypeOptions.BROWSING_CAROUSEL:
      return [
        {
          id: uniqueId('car'),
          primary_text: '',
          secondary_text: '',
          image: '',
          payload: '',
          payload_label: '',
          target: '_self'
        }
      ];
    case ResponseTypeOptions.VIDEO_PLAYLIST:
      return [
        {
          id: uniqueId('vid'),
          url: ''
        }
      ];
    case ResponseTypeOptions.OPTIONS_LIST:
      return {
        pagination: 1,
        elements: [
          {
            id: uniqueId('ol'),
            label: '',
            payload: -1
          }
        ]
      };
  }
};

/**
 * Maps Returns content of a response type component with its table row display
 */
export const getTableResponseContent = (responseContent: ResponseGenericModel): JSX.Element => {
  switch (responseContent.type) {
    case ResponseTypeOptions.TEXT: {
      return (
        <ResponseComponentRowHeader
          title="Text"
          preview={parse(truncateFuzzy(responseContent.content.text, 300, 8))}
          noColon
        />
      );
    }
    case ResponseTypeOptions.IMAGE:
      return (
        <ResponseComponentRowHeader
          title={ResponseTypeOptions.IMAGE}
          preview={
            <Image
              imageClassName="inline-block max-w-10rem max-h-10rem"
              preview
              src={responseContent.content.url}
              alt={responseContent.content.alt_text}
            />
          }
          noColon
        />
      );
    case ResponseTypeOptions.CUSTOM_ACTION: {
      return (
        <ResponseComponentRowHeader
          title="Custom Action"
          preview={parse(truncateFuzzy(responseContent.content.text, 300, 8))}
          noColon
        />
      );
    }
    default:
      return (
        <ResponseComponentRowHeader title={ResponseTypeNames[responseContent.type] ?? ''} noColon />
      );
  }
};

const ResponseComponentRowHeader = ({
  title,
  noColon = false,
  className = 'font-bold text-lg',
  preview
}: {
  title: string;
  noColon?: boolean;
  className?: string;
  preview?: any;
}) => {
  const id = useRef(uniqueId('text'));

  return (
    <div id={id.current} className="w-max">
      <span className={className}>{title}</span>
      {!noColon && <span className={className}>:</span>}
      {preview && (
        <Tooltip className="max-w-20rem" position="bottom" target={`#${id.current}`}>
          {preview}
        </Tooltip>
      )}
    </div>
  );
};
