import React, { useEffect, useRef, useState } from 'react';
import { DataTablePageEvent, DataTableRowEditCompleteEvent } from 'primereact/datatable';
import { confirmDialog } from 'primereact/confirmdialog';
import SearchBar from 'components/SearchBar';
import {
  EditMessageInboxEntitiesOptionsModel,
  EditMessageInboxOptionsModel,
  EditMessageInboxTagsOptionsModel,
  GetMessageInboxOptionsModel,
  MessageInboxEntityLabelModel,
  MessageInboxPatchRequestAPIModel,
  MessageInboxTableDataModel
} from 'models/message-inbox-table-data-model';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { setLayout } from 'features/layout/layoutSlice';
import { FieldValues, useFieldArray, useForm } from 'react-hook-form';
import MessageInboxFilterMenu from './components/MessageInboxFilterMenu';
import useQuery from 'hooks/useQuery';
import { FetchStatusOptions } from 'constants/fetchStatus';
import useFetch from 'hooks/useFetch';
import MessageInboxService from 'services/messageInboxService';
import { Paginated, SlugIdOptionsModel } from 'models/api-model';
import {
  entitiesSelector,
  getTags,
  intentsSelector,
  projectsSelector,
  sentimentsSelector,
  synonymsSelector,
  tagsSelector
} from 'features/projects/projectsSlice';
import { MenuItem } from 'primereact/menuitem';
import Popup from 'containers/Popup';
import { createToast, showToast } from 'features/toast/toastSlice';
import { updateObjectInArray } from 'util/record';
import { TagModel } from 'models/tag-model';
import {
  AddNLUMessagesOptionsModel,
  NLUAPIModel,
  NLUCollisionModel,
  NLUConflictType,
  NLUModel
} from 'models/nlu-table-data-model';
import NluService from 'services/nluService';
import NLUService from 'services/nluService';
import { buildNLUDataRows } from './components/buildNLUDataRows';
import { dirtyValues } from 'util/form';
import NLUConflictForm from './components/NLUConflictForm';
import LoadingSpinner from 'components/LoadingSpinner';
import MessageInboxDataTable from './components/MessageInboxDataTable';
import { searchConversations } from 'features/conversations/conversationsSlice';
import { useNavigate } from 'react-router-dom';
import { routes } from 'constants/routes';
import { mapIdToName } from 'util/mapIdAndName';
import { Status } from 'models/status-model';
import {
  EntityFieldArrayValues,
  EntityFormContext,
  EntityFormContextModel,
  EntityModal
} from 'features/entity-labelling';
import { sortEntities } from 'util/entity';
import { FilterChips } from 'components/FilterChips/FilterChips';
import { FilterChip } from 'models/filter-chips';
import { humanReadableDateTimeFormatted } from 'util/dates';
import { buildMultipleChips } from 'util/filters';
import { isArrayAndIncludes } from 'util/arrays';
import { versionsSelector } from 'features/versions/versionsSlice';
import { Button } from 'primereact/button';
import BatchMessagesPopup from './components/BatchMessagesPopup';
import { CommentPopup, ThreadStateChangeEvent } from 'pages/Comments/CommentPopup';
import { CommentType } from 'models/comment-model';
import { permissionBoolean } from 'util/permissions';
import CommentsService from 'services/commentsService';
import { getMainLayout } from 'util/layout';

const messageInboxFilterDefaultValues: FieldValues = {
  tags: [],
  annotation: { name: '', queryValue: '' },
  review: { name: '', queryValue: '' },
  intent: [],
  sentiment: [],
  date: [],
  intent_confidence: [0, 100],
  sentiment_confidence: [0, 100],
  message_length: [0, 200],
  metadata: [{ field: '', value: '' }]
};

type MetadataFilterField = { field: string; value: string };

interface MessageInboxRowEditCompleteEvent extends DataTableRowEditCompleteEvent {
  data: MessageInboxTableDataModel;
  newData: MessageInboxTableDataModel;
}

const MessageInbox = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedProject, projects, metadataFilters } = useAppSelector(projectsSelector);
  const { isVersionSelected } = useAppSelector(versionsSelector);
  const allIntents = useAppSelector(intentsSelector);
  const allSentiments = useAppSelector(sentimentsSelector);
  const allTags = useAppSelector(tagsSelector);
  const [messageInboxNodes, setMessageInboxNodes] = useState<MessageInboxTableDataModel[]>([]);
  const [checkedRows, setCheckedRows] = useState<MessageInboxTableDataModel[]>([]);
  const [showCommentPopup, setShowCommentPopup] = useState(false);
  const [editNLUStatusLoading, setEditNLUStatusLoading] = useState(false);
  const [contextRow, setContextRow] = useState<MessageInboxTableDataModel | null>(null);
  const bulkActionsMenuRef = useRef<MenuItem>(null);
  const [nluCollisions, setNluCollisions] = useState<NLUCollisionModel>({
    duplicates: [],
    no_accepted_annotation: []
  });
  const [displayBatchMessagesPopup, setDisplayBatchMessagesPopup] = useState(false);
  const [conflictingMessagesRecord, setConflictingMessagesRecord] = useState<
    Record<MessageInboxTableDataModel['id'], MessageInboxTableDataModel>
  >({});
  const [conflictingNLURecord, setConflictingNLURecord] = useState<
    Record<NLUModel['id'], NLUModel>
  >({});
  const [conflictsPopupVisible, setConflictsPopupVisible] = useState(false);
  const {
    control: filterControl,
    reset: resetFilterForm,
    resetField: resetFilterField,
    getValues: getFilterField,
    setValue: setFilterField,
    formState: { dirtyFields: dirtyFilterFields, isDirty: isFilterFormDirty },
    handleSubmit: handleFilterFormSubmit,
    watch: watchFilterForm
  } = useForm({ defaultValues: messageInboxFilterDefaultValues });
  const {
    control: nluConflictControl,
    formState: { dirtyFields: nluConflictDirtyFields },
    handleSubmit: handleNluConflictSubmit,
    reset: resetNluConflictForm,
    setValue: setNluConflictFormValue
  } = useForm();
  const {
    search,
    searchText,
    clearSearch,
    simpleSearch,
    setQueryParameter,
    setAllMetaFields,
    clearAllQueryParameters,
    clearQueryParameter,
    queryString: filterQueryString,
    queryParameters: filterQueryParameters,
    paginate: { limit, offset, setOffset, setLimit, total, setTotal }
  } = useQuery({
    query: submitQueryForm,
    deps: [dirtyFilterFields],
    defaultValues: { ordering: '-id' }
  });
  const {
    data: getMessagesData,
    fetchStatus: getMessagesStatus,
    fetch: getMessages
  } = useFetch<GetMessageInboxOptionsModel, Paginated<MessageInboxTableDataModel>>(
    MessageInboxService.getInboxMessages,
    MessageInboxService.roles.list
  );
  const {
    data: getMessageData,
    fetchStatus: getMessageStatus,
    fetch: getMessage
  } = useFetch(MessageInboxService.getInboxMessage, MessageInboxService.roles.list);
  const {
    fetchStatus: editMessageStatus,
    data: editMessageData,
    fetch: editMessage
  } = useFetch<EditMessageInboxOptionsModel, MessageInboxTableDataModel>(
    MessageInboxService.editMessageInbox,
    MessageInboxService.roles.update
  );

  const {
    fetchStatus: editMessageTagStatus,
    data: editMessageTagData,
    fetch: editMessageTag
  } = useFetch<EditMessageInboxTagsOptionsModel, MessageInboxTableDataModel['tags']>(
    MessageInboxService.editMessageInboxTags,
    MessageInboxService.roles.update
  );
  const {
    fetchStatus: deleteMessageStatus,
    fetch: deleteMessage,
    permission: canDelete
  } = useFetch<SlugIdOptionsModel>(
    MessageInboxService.deleteMessageInboxMessage,
    MessageInboxService.roles.delete
  );
  const {
    fetchStatus: addNLUMessagesStatus,
    fetch: addNLUMessages,
    data: nluMessagesCollisionData
  } = useFetch<AddNLUMessagesOptionsModel, NLUCollisionModel>(
    NluService.addNLUMessages,
    NluService.roles.create
  );

  const bulkActionItems: MenuItem[] = [
    {
      label: 'Manually add selected rows to NLU Data',
      command: addRowsToNLUData
    }
  ];

  function populateNLUCollisionRecord(id: NLUModel['id']) {
    return NLUService.getSingleNLUData({ slug: selectedProject.slug, id }).then((nluData) =>
      setConflictingNLURecord((prevState) => ({ ...prevState, [id]: nluData }))
    );
  }

  function populateMessageCollisionRecord(id: MessageInboxTableDataModel['id']) {
    const messageInboxNode = messageInboxNodes.find((node) => node.id === id);

    if (!messageInboxNode) {
      return;
    }

    setConflictingMessagesRecord((prevState) => ({ ...prevState, [id]: messageInboxNode }));
  }

  // onInit
  useEffect(() => {
    dispatch(setLayout(getMainLayout(selectedProject.project_user.role, projects.length)));
  }, []);

  /* - - - - - - - - - - Get Messages - - - - - - - - - - */

  // handle get messages effect
  useEffect(() => {
    if (getMessagesStatus === FetchStatusOptions.SUCCESS && getMessagesData) {
      setMessageInboxNodes(getMessagesData.results);
      setTotal(getMessagesData.count);
    }
  }, [getMessagesStatus]);

  useEffect(() => {
    if (getMessageStatus === FetchStatusOptions.SUCCESS && getMessageData) {
      const updatedRowData = updateObjectInArray(messageInboxNodes, getMessageData, 'id');
      setMessageInboxNodes(updatedRowData || messageInboxNodes);
    }
  }, [getMessageStatus]);

  /* - - - - - - - - - - Open in Conversations - - - - - - - - - - */

  const openMessageInConversations = (rowNode: MessageInboxTableDataModel) => {
    navigate(routes.CONVERSATIONS);
    dispatch(
      searchConversations({
        searchText: rowNode.sender,
        id: rowNode.conversation,
        message:
          rowNode?.related_records.find((record) => record.user_fullname === 'Bot')?.text ??
          rowNode.text
      })
    );
  };

  /* - - - - - - - - - - NLU Messages & Collisions - - - - - - - - - - */

  const getProposedNewMessages = (nluData: NLUAPIModel[]) => {
    const messages: JSX.Element[] = [];

    nluData.forEach((data) => {
      const intent = allIntents.find((intent) => intent.id === data.intent);
      if (intent?.status === Status.New) {
        messages.push(<li key={messages.length}>{data.message}</li>);
      }
    });
    return messages;
  };

  const warningDialog = (messages: JSX.Element[]) => {
    confirmDialog({
      message: (
        <div>
          These messages have an intent with status: <b>&quot;{Status.New}&quot;</b>
          <br />
          Continue to add them to NLU Data?
          <ul className="list-disc">{messages}</ul>
        </div>
      ),
      header: 'Intent Status Warning',
      icon: 'pi pi-exclamation-triangle',
      accept: () =>
        addNLUMessages({
          slug: selectedProject.slug,
          updatedNLUData: checkedRows.map((row) => row.id)
        }),
      acceptLabel: 'Confirm',
      rejectLabel: 'Cancel'
    });
  };

  function addRowsToNLUData() {
    const { nluData, errors } = buildNLUDataRows({
      checkedRows,
      allIntents,
      allSentiments,
      sentimentModule: selectedProject.sentiment
    });

    if (errors.blankInput) {
      return;
    }

    if (nluData) {
      const proposedNewMessages = getProposedNewMessages(nluData);
      if (proposedNewMessages.length > 0) {
        warningDialog(proposedNewMessages);
      } else {
        addNLUMessages({
          slug: selectedProject.slug,
          updatedNLUData: checkedRows.map((row) => row.id)
        });
      }
    } else {
      dispatch(showToast({ message: errors.message, severity: 'error', life: 8000 }));
    }
  }

  // handle add messages to NLU effect
  useEffect(() => {
    if (addNLUMessagesStatus === FetchStatusOptions.SUCCESS && nluMessagesCollisionData) {
      if (
        nluMessagesCollisionData.duplicates.length == 0 &&
        nluMessagesCollisionData.no_accepted_annotation.length == 0
      ) {
        dispatch(createToast('Messages added to NLU table'));
      } else {
        dispatch(createToast('Conflicts with existing NLU data', 'warn'));

        const nluIds: NLUModel['id'][] = [];
        let messageIds: MessageInboxTableDataModel['id'][] = [];

        nluMessagesCollisionData.duplicates.forEach((duplicates) => {
          duplicates.forEach((conflict) => {
            if (conflict[0] === NLUConflictType.message) {
              messageIds.push(conflict[1]);
            } else {
              nluIds.push(conflict[1]);
            }
          });
        });

        // Remove duplicates by converting to a set then back to an array
        messageIds = [
          ...new Set<number>([...messageIds, ...nluMessagesCollisionData.no_accepted_annotation])
        ];

        // populate MessageInbox collision record
        messageIds.map((id) => populateMessageCollisionRecord(id));

        // sequential api calls to populate fetch and populate NLU Collision record
        Promise.all(nluIds.map((id) => populateNLUCollisionRecord(id))).catch((error) =>
          dispatch(createToast(error, 'error'))
        );

        setNluCollisions(nluMessagesCollisionData);
      }
    }
  }, [addNLUMessagesStatus]);

  // handle NLU collisions
  useEffect(() => {
    if (nluCollisions.duplicates.length > 0 || nluCollisions.no_accepted_annotation.length > 0) {
      setConflictsPopupVisible(true);
    }
  }, [nluCollisions]);

  /**
   * Cancel and reset the form.
   * By default, you can ask to confirm when cancelling when conflicts still exist
   * @param unresolvedChangesCheck whether to check for unresolved conflicts
   */
  const onConflictPopupClose = (unresolvedChangesCheck = true) => {
    if (nluCollisions.duplicates.length > 0 && unresolvedChangesCheck) {
      confirmCancelConflictResolution();
    } else {
      setConflictsPopupVisible(false);
      resetNluConflictForm();
    }
  };

  const onConflictsFormSave = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    handleNluConflictSubmit(onConflictResolve)();
  };

  const onConflictResolve = (data: FieldValues) => {
    const formValues: FieldValues = dirtyValues(nluConflictDirtyFields, data);
    const errorNLURows: string[] = [];

    onConflictPopupClose(false);

    // discard conflicts when there are no changes
    if (Object.keys(formValues).length === 0) {
      dispatch(createToast('Conflicts discarded', 'info'));
      return;
    }

    setEditNLUStatusLoading(true);
    Object.keys(formValues).forEach((key) => {
      // the key is the id of the data we are replacing in the NLU data table.
      // if there is no key, must be trying to add conflicting data that is new
      if (data[key].id) {
        NluService.editNLUData({
          slug: selectedProject.slug,
          updatedNLUData: {
            id: data[key].id,
            ...(data[key].intent && { intent: data[key].intent }),
            ...(data[key].sentiment && { sentiment: data[key].sentiment })
          }
        }).catch((error) => {
          errorNLURows.push(key);
          console.log(error);
        });
      } else {
        NluService.createNLUData({
          slug: selectedProject.slug,
          updatedNLUData: {
            message: key,
            intent: data[key].intent,
            sentiment: data[key].sentiment
          }
        }).catch((error) => {
          errorNLURows.push(key);
          console.log(error);
        });
      }
    });

    setEditNLUStatusLoading(false);

    // success or error message
    if (errorNLURows.length === 0) {
      dispatch(createToast('Successfully updated conflicting NLU rows'));
    } else {
      dispatch(
        createToast('Conflicts with updating messages: ' + errorNLURows.join(', '), 'error')
      );
    }
  };

  const confirmCancelConflictResolution = () => {
    confirmDialog({
      header: 'Cancel Resolution ?',
      message: (
        <div>
          Unresolved conflicts will <b>NOT</b> be added to NLU data.
          <br />
          <br />
          Original data will take precedence.
        </div>
      ),
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => onConflictPopupClose(false)
    });
  };

  /* - - - - - - - - - - Query & Pagination - - - - - - - - - - */

  // query on filterQueryString change.
  // *** also fires onInit, so it replaces default getMessages req
  useEffect(() => {
    getMessages({ slug: selectedProject.slug, query: filterQueryString });
  }, [filterQueryString]);

  // variables used in here from state need to be in useCallback deps
  function submitQueryForm(queryText: string) {
    handleFilterFormSubmit((values) => onQuery(values, queryText))();
  }

  // perform back end query using the filter & search
  const onQuery = (data: FieldValues, queryText: string) => {
    const filterFormValues: FieldValues = dirtyValues(dirtyFilterFields, data);

    // set query parameters which will build a queryParameter string
    setQueryParameter({
      ...{ search: queryText },
      ...(filterFormValues.intent && { intent: filterFormValues.intent }),
      ...(filterFormValues.sentiment && { sentiment: filterFormValues.sentiment }),
      ...(filterFormValues.tags && { tags: filterFormValues.tags.map((tag: TagModel) => tag.id) })
    });
  };

  const resetFilter = () => {
    clearSearch();
    clearAllQueryParameters();
    resetFilterForm();
  };

  // perform back end query using paginate
  const onPaginate = (paginateParams: DataTablePageEvent) => {
    // set pagination variables for the paginator component
    setLimit(paginateParams.rows);
    setOffset(paginateParams.first);

    // set query parameters which will build a queryParameter string
    setQueryParameter({
      limit: paginateParams.rows,
      offset: paginateParams.first
    });
  };

  /* - - - - - - - - - - Metadata Filter Fields - - - - - - - - - - */
  const metaPrefix = 'meta__';
  type MetaFormValue = { field: string; value: string };

  /**
   * build metadata query parameter based on current form values
   */
  const buildMetadataQueryParameters = () => {
    // only query metadata fields with both field & value
    const allFormMetadata: MetaFormValue[] = getFilterField().metadata?.filter(
      (meta: MetaFormValue) => meta.field?.length > 0 && meta.value?.length > 0
    );

    const allMetadataFields = [
      ...new Set<string>(allFormMetadata.map((meta) => metaPrefix + meta.field))
    ];

    const combinedMetaFields = allMetadataFields.reduce((acc: FieldValues, metaField) => {
      acc[metaField] = allFormMetadata
        .filter((meta: MetaFormValue) => metaPrefix + meta.field === metaField)
        .map((meta: MetaFormValue) => meta.value);
      return acc;
    }, {});

    setAllMetaFields(combinedMetaFields);
  };

  /* - - - - - - - - - - Filter Chips - - - - - - - - - - */
  const filterChips: FilterChip[] = [
    {
      name: `Date Greater Than: ${humanReadableDateTimeFormatted(
        String(filterQueryParameters.date_gte)
      )}`,
      removeFn: () => {
        clearQueryParameter('date_gte');
        const date = getFilterField('date');
        const newDate = [null, new Date(date[1])];
        setFilterField('date', newDate);
      },
      enabled: 'date_gte' in filterQueryParameters
    },
    {
      name: `Date Less Than: ${humanReadableDateTimeFormatted(
        String(filterQueryParameters.date_lte)
      )}`,
      removeFn: () => {
        clearQueryParameter('date_lte');
        const date = getFilterField('date');
        const newDate = [new Date(date[0]), null];
        setFilterField('date', newDate);
      },
      enabled: 'date_lte' in filterQueryParameters
    },
    ...buildMultipleChips(filterQueryParameters.intent, (intentId: number) => ({
      name: `Intent: ${mapIdToName(intentId, allIntents)}`,
      removeFn: () => {
        const intents: number[] = getFilterField('intent');
        const filteredIntents = intents.filter((id) => id !== intentId);

        if (filteredIntents.length === 0) {
          clearQueryParameter('intent');
          resetFilterField('intent');
        } else {
          setFilterField('intent', filteredIntents);
          setQueryParameter({ intent: filteredIntents });
        }
      },
      enabled: isArrayAndIncludes(filterQueryParameters.intent, intentId)
    })),
    ...buildMultipleChips(filterQueryParameters.sentiment, (sentimentId: number) => ({
      name: `Sentiment: ${mapIdToName(sentimentId, allSentiments)}`,
      removeFn: () => {
        const sentiments: number[] = getFilterField('sentiment');
        const filteredSentiments = sentiments.filter((id) => id !== sentimentId);

        if (filteredSentiments.length === 0) {
          clearQueryParameter('sentiment');
          resetFilterField('sentiment');
        } else {
          setFilterField('sentiment', filteredSentiments);
          setQueryParameter({ sentiment: filteredSentiments });
        }
      },
      enabled: isArrayAndIncludes(filterQueryParameters.sentiment, sentimentId)
    })),
    ...buildMultipleChips(filterQueryParameters.tags, (tagId: number) => ({
      name: `Tag: ${mapIdToName(tagId, allTags)}`,
      removeFn: () => {
        const tags: number[] = getFilterField('tags');
        const filteredTags = tags.filter((id) => id !== tagId);

        if (filteredTags.length === 0) {
          clearQueryParameter('tags');
          resetFilterField('tags');
        } else {
          setFilterField('tags', filteredTags);
          setQueryParameter({ tags: filteredTags });
        }
      },
      enabled: isArrayAndIncludes(filterQueryParameters.tags, tagId)
    })),
    {
      name: 'Labelled by',
      removeFn: () => {
        clearQueryParameter('labelled_by', 'reviewed');
        resetFilterField('annotation');
        resetFilterField('review');
      },
      enabled: ['labelled_by', 'reviewed'].some((s) => s in filterQueryParameters)
    },
    {
      name: 'Intent confidence',
      removeFn: () => {
        clearQueryParameter('bot_intent_confidence_gte', 'bot_intent_confidence_lte');
        resetFilterField('intent_confidence');
      },
      enabled: ['bot_intent_confidence_gte', 'bot_intent_confidence_lte'].some(
        (s) => s in filterQueryParameters
      )
    },
    {
      name: 'Sentiment confidence',
      removeFn: () => {
        clearQueryParameter('bot_sentiment_confidence_gte', 'bot_sentiment_confidence_lte');
        resetFilterField('sentiment_confidence');
      },
      enabled: ['bot_sentiment_confidence_gte', 'bot_sentiment_confidence_lte'].some(
        (s) => s in filterQueryParameters
      )
    },
    {
      name: 'Message Length',
      removeFn: () => {
        clearQueryParameter('length_gte', 'length_lte');
        resetFilterField('message_length');
      },
      enabled: ['length_gte', 'length_lte'].some((s) => s in filterQueryParameters)
    },
    ...buildMultipleChips(getFilterField('metadata'), (metadataField: MetadataFilterField) => ({
      name: `${
        metadataFilters.find((meta) => meta.filter === metadataField.field)?.alias ??
        `"${metadataField.field}"`
      } : ${metadataField.value}`,
      removeFn: () => {
        // all metadata from form (they do not contain metaPrefix)
        const allMetadata: MetadataFilterField[] = getFilterField('metadata');

        const filteredMeta = allMetadata.filter(
          (meta) => meta.field !== metadataField.field || meta.value !== metadataField.value
        );

        if (filteredMeta.length === 0) {
          clearQueryParameter(metaPrefix + metadataField.field);
          resetFilterField('metadata');
        } else {
          setFilterField('metadata', filteredMeta);
          buildMetadataQueryParameters();
        }
      },
      enabled: isArrayAndIncludes(
        filterQueryParameters[metaPrefix + metadataField.field],
        metadataField.value
      )
    }))
  ];

  const watch = watchFilterForm();
  const filterDeps = [watch];

  /* - - - - - - - - - - Editing - - - - - - - - - - */

  // handle edit message effect
  useEffect(() => {
    if (editMessageStatus === FetchStatusOptions.SUCCESS && editMessageData) {
      const updatedRowData = updateObjectInArray(messageInboxNodes, editMessageData, 'id');
      setMessageInboxNodes(updatedRowData || messageInboxNodes);
      setContextRow(null);
      dispatch(createToast('message edited'));
    }
  }, [editMessageStatus]);

  const onRowEditComplete = (e: MessageInboxRowEditCompleteEvent) => {
    const { data, newData } = e;

    if (data.text === newData.text) {
      return;
    }

    const updatedData: MessageInboxPatchRequestAPIModel = {
      text: newData.text
    };

    editMessage({
      slug: selectedProject.slug,
      id: data.id,
      updatedMessageInboxData: updatedData
    });
  };

  /**
   * Generic function to specify the new data property of the message inbox request data.
   * Using rowData, the other redundant data is filled in to make the request object structure.
   * @param newValues new values of the data
   * @param rowData row data to be updated
   */
  function editMessageInbox({
    newValues,
    rowData
  }: {
    rowData: MessageInboxTableDataModel;
    newValues: MessageInboxPatchRequestAPIModel;
  }) {
    setContextRow(rowData);
    const updatedData: MessageInboxPatchRequestAPIModel = {
      intent: rowData.intent,
      // dont include null sentiment in patch request
      ...(rowData.sentiment && { sentiment: rowData.sentiment }),
      reviewed: rowData.reviewed,
      text: rowData.text,
      ...newValues
    };

    editMessage({
      slug: selectedProject.slug,
      id: rowData.id,
      updatedMessageInboxData: updatedData
    });
  }

  /* - - - - - - - - - - Delete - - - - - - - - - - */

  // handle delete message effect
  useEffect(() => {
    if (deleteMessageStatus === FetchStatusOptions.SUCCESS) {
      setMessageInboxNodes(messageInboxNodes.filter((node) => node.id != contextRow?.id));
      setContextRow(null);
      dispatch(createToast('message deleted'));
    }
  }, [deleteMessageStatus]);

  const onDeleteRow = (rowData: MessageInboxTableDataModel) => {
    setContextRow(rowData);
    deleteMessage({ slug: selectedProject.slug, id: rowData.id });
  };

  /* - - - - - - - - - - Tags - - - - - - - - - - */

  // handle edit message tag effect
  useEffect(() => {
    if (editMessageTagStatus === FetchStatusOptions.SUCCESS && editMessageTagData) {
      const updatedRowData = { ...contextRow, tags: editMessageTagData };
      updateObjectInArray(messageInboxNodes, updatedRowData, 'id');
      dispatch(createToast('Message Tags updated'));
      setContextRow(null);
    }
  }, [editMessageTagStatus]);

  const onEditTags = (rowData: MessageInboxTableDataModel, changedTagsParams: TagModel[]) => {
    setContextRow(rowData);
    editMessageTag({
      id: rowData.id,
      tags: changedTagsParams.map((tag) => tag.id),
      slug: selectedProject.slug
    });
  };

  /* - - - - - - - - - - Synonym Lookup - - - - - - - - - - - */
  const allSynonyms = useAppSelector(synonymsSelector);
  const [synonymRecord, setSynonymRecord] = useState<Record<string, string>>({});

  useEffect(() => {
    const newRecord: Record<string, string> = {};

    allSynonyms.forEach((synonym) =>
      synonym.phrases.forEach((phrase) => (newRecord[phrase] = synonym.text))
    );

    setSynonymRecord(newRecord);
  }, [allSynonyms]);

  /* - - - - - - - - - - Batch Messages - - - - - - - - - - */
  const {
    fetchStatus: batchMessagesStatus,
    fetch: batchMessages,
    permission: canBatchMessages
  } = useFetch(MessageInboxService.batchMessages, MessageInboxService.roles.update);

  useEffect(() => {
    if (batchMessagesStatus === FetchStatusOptions.SUCCESS) {
      // Re-fetch tags and messages to find newly tagged messages
      dispatch(getTags({ slug: selectedProject.slug }));
      getMessages({ slug: selectedProject.slug, query: filterQueryString });
    }
  }, [batchMessagesStatus]);

  /* - - - - - - - - - - Entity Labelling - - - - - - - - - - */
  const allEntities = useAppSelector(entitiesSelector);

  const {
    fetchStatus: editMessageInboxEntitiesStatus,
    fetch: editMessageInboxEntities,
    data: editMessageInboxEntitiesData
  } = useFetch<EditMessageInboxEntitiesOptionsModel, MessageInboxEntityLabelModel[]>(
    MessageInboxService.editMessageInboxEntities,
    MessageInboxService.roles.update
  );

  // handle entity annotation effect
  useEffect(() => {
    if (
      editMessageInboxEntitiesStatus === FetchStatusOptions.SUCCESS &&
      editMessageInboxEntitiesData
    ) {
      const updatedRowData = updateObjectInArray(
        messageInboxNodes,
        editMessageInboxEntitiesData,
        'id'
      );
      updatedRowData && setMessageInboxNodes(updatedRowData);
      setContextEntityNode(null);
      onEntityLabelerHide();
      dispatch(createToast('entity annotated'));
    }
  }, [editMessageInboxEntitiesStatus]);

  const defaultValues: FieldValues = {
    entities: []
  };
  const entityForm = useForm({ defaultValues });

  const {
    control: entityLabelerControl,
    handleSubmit: handleEntityLabelerSubmit,
    reset: resetEntityLabeler
  } = entityForm;
  const entityFieldArrayMethods = useFieldArray({
    control: entityLabelerControl,
    name: 'entities' // unique name for your Field Array
  });

  /** Create context object from useForm and useField array
   *  Need to rename useFieldArray methods since they are called multiple times
   */
  const allFormMethods: EntityFormContextModel = {
    ...entityForm,
    entityFields: entityFieldArrayMethods.fields,
    appendEntity: entityFieldArrayMethods.append,
    removeEntity: entityFieldArrayMethods.remove,
    moveEntity: entityFieldArrayMethods.move
  };

  const [entityLabelerVisible, setEntityLabelerVisible] = useState(false);
  const [contextEntityNode, setContextEntityNode] = useState<MessageInboxTableDataModel | null>(
    null
  );

  const showEntityLabelerModal = (node: MessageInboxTableDataModel) => {
    const entities: EntityFieldArrayValues[] = sortEntities<EntityFieldArrayValues>(
      node.entity_labels.map((nodeEntity) => ({
        entity: nodeEntity.entity,
        start: nodeEntity.start,
        end: nodeEntity.end,
        role: nodeEntity.role ?? null,
        group: nodeEntity.group ?? null
      }))
    );

    resetEntityLabeler({ entities });
    setContextEntityNode(node);
    setEntityLabelerVisible(true);
  };

  const onEntityLabelerHide = () => {
    setEntityLabelerVisible(false);
    setContextEntityNode(null);
    resetEntityLabeler();
  };

  const onSaveEntityAnnotation = (data: FieldValues) =>
    contextEntityNode &&
    editMessageInboxEntities({
      slug: selectedProject.slug,
      id: contextEntityNode.id,
      updatedEntities: data.entities
    });

  const onEntityLabelerSave = () => {
    handleEntityLabelerSubmit(onSaveEntityAnnotation)();
  };

  /* - - - - - - - - - - Comments - - - - - - - - - - */

  const onThreadChange = (options: ThreadStateChangeEvent) => getMessage(options);

  const openComments = (annotation: MessageInboxTableDataModel) => {
    setContextRow(annotation);
    setShowCommentPopup(true);
  };

  return (
    <div className="specto-message-inbox">
      <BatchMessagesPopup
        displayPopup={displayBatchMessagesPopup}
        onPopupDisplayChange={setDisplayBatchMessagesPopup}
        postBatchMessages={batchMessages}
        postBatchMessagesStatus={batchMessagesStatus}
        projectSlug={selectedProject.slug}
        parentElement={document.activeElement as HTMLElement}
      />
      <EntityFormContext.Provider value={allFormMethods}>
        <div className="grid grid-nogutter">
          <div className="col flex flex-wrap xl:align-items-center align-items-start justify-content-between flex-column-reverse xl:flex-row">
            <SearchBar
              filterMenu={
                <MessageInboxFilterMenu
                  loading={getMessagesStatus === FetchStatusOptions.LOADING}
                  control={filterControl}
                  superUserView={canDelete}
                  onChangeMetadataField={buildMetadataQueryParameters}
                  onFormValueChange={(fieldValue) => setQueryParameter(fieldValue)}
                />
              }
              placeHolder="Search Messages"
              className="specto-search-bar w-12 xl:w-8 mt-3 xl:mt-0"
              onFilterClearClick={() => resetFilter()}
              hideFilterClear={!isFilterFormDirty}
              text={searchText}
              onChange={(e) => simpleSearch(e.target.value)}
              onSubmitText={search}
              onClearText={() => {
                clearSearch();
                search('');
              }}
            />
            {!isVersionSelected && canBatchMessages && (
              <Button label="Batch Messages" onClick={() => setDisplayBatchMessagesPopup(true)} />
            )}
          </div>

          <div className="col-12 mb-2 pt-2">
            <FilterChips filterChips={filterChips} deps={filterDeps} className="lg:w-7" />
          </div>

          <div className="col-12">
            <div className="card">
              <MessageInboxDataTable
                superUserView={canDelete}
                allMessageInboxData={messageInboxNodes}
                bulkActionItems={canDelete ? bulkActionItems : undefined}
                bulkActionsMenuRef={canDelete ? bulkActionsMenuRef : undefined}
                sentimentModule={selectedProject.sentiment}
                loading={getMessagesStatus === FetchStatusOptions.LOADING}
                checkedRows={checkedRows}
                setCheckedRows={setCheckedRows}
                rows={limit}
                first={offset}
                totalRecords={total}
                onPage={onPaginate}
                onEdit={editMessageInbox}
                openComments={openComments}
                openMessageInConversations={openMessageInConversations}
                onEditTags={onEditTags}
                onRowEditComplete={onRowEditComplete}
                onDeleteRow={onDeleteRow}
                openEntityEditor={showEntityLabelerModal}
                synonymRecord={synonymRecord}
                showComments={permissionBoolean(
                  CommentsService.roles.general.list,
                  selectedProject.project_user.role
                )}
              />
            </div>
          </div>
        </div>

        <LoadingSpinner enabled={editNLUStatusLoading} />

        <Popup
          title="NLU Conflicts"
          subtitle="Pick the label that should make it to the NLU table for each message"
          visible={conflictsPopupVisible}
          onHide={onConflictPopupClose}
          onSave={onConflictsFormSave}
        >
          <NLUConflictForm
            nluCollisions={nluCollisions}
            conflictingMessagesRecord={conflictingMessagesRecord}
            conflictingNLURecord={conflictingNLURecord}
            control={nluConflictControl}
            sentimentModule={selectedProject.sentiment}
            setValue={setNluConflictFormValue}
          />
        </Popup>

        <EntityModal
          node={contextEntityNode}
          control={entityLabelerControl}
          visible={entityLabelerVisible}
          onHide={onEntityLabelerHide}
          onSave={onEntityLabelerSave}
          allEntities={allEntities}
          synonymRecord={synonymRecord}
          loading={editMessageInboxEntitiesStatus === FetchStatusOptions.LOADING}
        />

        {contextRow && (
          <CommentPopup
            displayPopup={showCommentPopup}
            onPopupDisplayChange={setShowCommentPopup}
            objectType={CommentType.MESSAGE}
            objectId={contextRow.id}
            objectName={contextRow.text}
            parentElement={document.activeElement as HTMLElement}
            onThreadStateChange={onThreadChange}
          />
        )}
      </EntityFormContext.Provider>
    </div>
  );
};

export default MessageInbox;
