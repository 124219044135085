import { OPEN_PERMISSIONS } from 'constants/roles';
import ApiService from './apiService';
import { SlugOptionsModel } from 'models/api-model';
import { ANALYTICS_API_URL } from 'constants/api';
import { AnalyticsAuthToken, AnalyticsDashboard } from 'models/analytics-model';

const getToken = (options: SlugOptionsModel) =>
  ApiService.get<AnalyticsAuthToken>('/' + options.slug + ANALYTICS_API_URL + 'token/');

const getDashboards = (options: SlugOptionsModel) =>
  ApiService.get<AnalyticsDashboard[]>('/' + options.slug + ANALYTICS_API_URL + 'dashboards/');

const AnalyticsService = {
  getToken,
  getDashboards,
  roles: OPEN_PERMISSIONS
};

export default AnalyticsService;
