export type StatusModel = {
  id: number;
  name: string;
  default: boolean;
};

export enum Status {
  New = 'NW',
  Modified = 'MF',
  Approved = 'AP',
  Published = 'PB',
  Retired = 'RT',
  Not_Retired = '-RT'
}

export enum PublishState {
  Active = 'active',
  Proposed = 'proposed',
  Retired = 'retired'
}
