import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import App from './App';
import LoginPage from './pages/LoginPage/LoginPage';
import AccountSetup from './components/AccountSetup';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import VerifyEmail from 'components/AccountSetup/VerifyEmail';
import ConfirmResetPassword from 'pages/ResetPassword/ConfirmResetPassword';
import * as Sentry from '@sentry/react';

const AppWrapper = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <SentryRoutes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/account-setup" element={<AccountSetup />} />
      <Route path="/registration/verify-email/:key" element={<VerifyEmail />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/auth/password/reset/confirm/:uid/:token" element={<ConfirmResetPassword />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/*" element={<App />} />
    </SentryRoutes>
  );
};

export default AppWrapper;
