import React, { useState } from 'react';
import { DataTable, DataTableStateEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import useToggleKeys from 'hooks/useToggleKeys';
import EntityRowExpansionTemplate from './EntityRowExpansionTemplate';
import { EntityTableDataModel } from 'models/entity-model';
import { useAppSelector } from 'hooks/store';
import { CommentPopup, ThreadStateChangeEvent } from 'pages/Comments/CommentPopup';
import { CommentType } from 'models/comment-model';
import { CommentThreadButton } from 'pages/Comments/CommentThreadButton';
import { versionsSelector } from 'features/versions/versionsSlice';
import {
  ChipsCell,
  LastModifiedCell,
  RemoveRetiredDialog,
  RetireDialog,
  TableHeader,
  TagsCell,
  TextCell,
  TreeToggleButton
} from 'components/Table';
import { mapTags } from 'util/tags';
import { projectsSelector, tagsSelector } from 'features/projects/projectsSlice';
import { confirmDialog } from 'primereact/confirmdialog';
import { MenuButtonCell } from 'components/Table/MenuButtonCell';
import { Status } from 'models/status-model';
import { statusDisplayNames } from 'constants/status';
import { permissionBoolean } from 'util/permissions';
import paginatorTemplate from 'components/Table/PaginatorTemplate';
import { ProjectMemberUserModel, ProjectRole } from 'models/project-model';
import { customSortIconFn } from 'util/table';
import { TopicTableDataModel } from 'models/topic-model';
import { mapIdToName } from 'util/mapIdAndName';
import ViewAllLinkChipsCell from 'components/Table/ViewAllLinkChipsCell';
import AvatarCell from 'components/Avatar/AvatarCell';

type EntityDataTablePropsModel = {
  allTopics: TopicTableDataModel[];
  canDelete?: boolean;
  entities: EntityTableDataModel[];
  onApproveEntity?(node: EntityTableDataModel): void;
  onDeleteEntity?(e: EntityTableDataModel): void;
  onDuplicateEntity?(e: EntityTableDataModel): void;
  onEditInit?(e: EntityTableDataModel): void;
  onPublishEntity?(node: EntityTableDataModel): void;
  onRetireChange?(e: EntityTableDataModel, isRetired: boolean): void;
  onThreadStateChange?(options: ThreadStateChangeEvent): void;
  readOnly?: boolean;
  showComments?: boolean;
  tablestate: DataTableStateEvent;
  onTableStateChange(tablestate: DataTableStateEvent): void;
  totalRecords: number;
  loading?: boolean;
  authors: ProjectMemberUserModel[];
};

/**
 * Component for displaying the Entity Data Table.
 *
 * @param allTopics List of all topics
 * @param canDelete if the user can delete
 * @param entities List of all the entities
 * @param loading if the table is loading
 * @param onApproveEntity Callback function to approve an entity
 * @param onDeleteEntity when the delete action is performed
 * @param onDuplicateEntity when the duplicate action is performed
 * @param onEditInit when the edit action is performed
 * @param onPublishEntity Callback function for the publishing entity action
 * @param onRetireChange when the retire action is performed
 * @param onTableStateChange when the table state changes
 * @param onThreadStateChange when the comment action is performed
 * @param readOnly if the table is read only
 * @param showComments if the comments should be shown
 * @param tablestate the table state
 * @param totalRecords the total number of records
 * @param authors List of current project members
 *
 * @constructor
 * @component
 */
const EntityDataTable = function ({
  allTopics,
  canDelete = false,
  entities,
  loading = false,
  onApproveEntity,
  onDeleteEntity,
  onDuplicateEntity,
  onEditInit,
  onPublishEntity,
  onRetireChange,
  onTableStateChange,
  onThreadStateChange,
  readOnly = false,
  showComments,
  tablestate,
  totalRecords,
  authors
}: EntityDataTablePropsModel) {
  const version = useAppSelector(versionsSelector);
  const allTags = useAppSelector(tagsSelector);
  const { selectedProject } = useAppSelector(projectsSelector);
  const {
    keys: expandedRows,
    toggleKeySingle: toggleRow,
    isKeyExpanded: isRowExpanded
  } = useToggleKeys();
  // Store the entity that will be retired or deleted
  const [selectedNode, setSelectedNode] = useState<EntityTableDataModel>(
    {} as EntityTableDataModel
  );
  const [showRetireDialog, setShowRetireDialog] = useState(false);
  const [showCommentPopup, setShowCommentPopup] = useState(false);

  /* - - - - - - - - - - Dialogs - - - - - - - - - */

  /**
   * Opens the Retire dialog
   * @param node object being retired
   */
  const openRetirePopup = (node: any) => {
    setSelectedNode(node);
    setShowRetireDialog(true);
  };

  /**
   * Opens the comments dialog
   * @param node object to open the comments dialog for
   */
  const openComments = (node: any) => {
    setSelectedNode(node);
    setShowCommentPopup(true);
  };

  /**
   * Delete confirmation popup
   * @param node node to delete
   */
  const confirmDeleteRow = (node: EntityTableDataModel) => {
    confirmDialog({
      message: (
        <div>
          <h6>This action cannot be undone.</h6>
          <ul className="list-disc">
            <li>This Entity cannot be recovered.</li>
          </ul>
        </div>
      ),
      header: 'Delete Row?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => onDeleteEntity?.(node)
    });
  };

  const canPublish = (node: EntityTableDataModel) =>
    node.status === Status.Approved &&
    permissionBoolean(
      [ProjectRole.ADMIN, ProjectRole.NEUROSOPH],
      selectedProject.project_user.role
    );

  // Admins can approve "New" or "Modified" statuses
  const canApprove = (node: EntityTableDataModel) =>
    (node.status === Status.New || node.status === Status.Modified) &&
    permissionBoolean(
      [ProjectRole.ADMIN, ProjectRole.NEUROSOPH],
      selectedProject.project_user.role
    );

  return (
    <div>
      <h6>{TableHeader({ tableType: 'Entity', itemCount: totalRecords })}</h6>
      <DataTable
        className="specto-data-table"
        dataKey="id"
        value={entities}
        sortIcon={customSortIconFn}
        paginator
        paginatorTemplate={paginatorTemplate}
        selectionAutoFocus={false}
        lazy
        loading={loading}
        first={tablestate.first}
        rows={tablestate.rows}
        totalRecords={totalRecords}
        onPage={onTableStateChange}
        onSort={(e) => onTableStateChange({ ...e, page: 1, first: 0 })}
        sortField={tablestate.sortField}
        sortOrder={tablestate.sortOrder}
        expandedRows={expandedRows}
        rowExpansionTemplate={(node: EntityTableDataModel) =>
          EntityRowExpansionTemplate({
            allTags,
            isVersion: version.isVersionSelected,
            node,
            readOnly,
            showComments,
            authors
          })
        }
        editMode="row"
        onRowDoubleClick={(e) =>
          !e.data.default &&
          !version.isVersionSelected &&
          e.data.status !== Status.Retired &&
          !readOnly &&
          onEditInit?.(e.data as EntityTableDataModel)
        }
        metaKeySelection={false}
      >
        <Column
          columnKey="util-history"
          className="specto-table-icon"
          header={
            <i className="pi pi-history specto-r-button-lg">
              <div className="hidden">History</div>
            </i>
          }
          body={(node: EntityTableDataModel) =>
            TreeToggleButton({
              display: node.history.length > 0,
              toggleRowExpansion: () => toggleRow(node.id),
              expanded: isRowExpanded(node.id)
            })
          }
        />
        <Column
          field="name"
          className="specto-table__column--md"
          header="Name"
          sortable
          body={(node: EntityTableDataModel) =>
            TextCell({
              id: 'name-' + node.id,
              text: node.name
            })
          }
        />
        <Column field="description" className="specto-table__column--xl" header="Description" />
        <Column
          field="topics"
          className="specto-table__column--md"
          header="Topics"
          body={(node) =>
            ViewAllLinkChipsCell({
              nodeId: 'topic-' + node.id,
              chips: node.topics.map((topic: number) => ({
                label: mapIdToName(topic, allTopics),
                url: `/topics?search=${mapIdToName(topic, allTopics)}`
              })),
              url: `/topics?entities=[${node.id}]`,
              emptyMessage: 'No Topics'
            })
          }
        />
        <Column
          field="authors"
          header="Author"
          className="specto-table__column--md"
          align="center"
          body={(node: EntityTableDataModel) => {
            const fullName = authors.find((pm) => pm.user === node?.author)?.name || '';
            return fullName ? <AvatarCell fullName={fullName} id={node.id} /> : null;
          }}
        />
        <Column
          field="roles"
          className="specto-table__column--md"
          header="Roles"
          body={(node) => ChipsCell({ nodeId: node.id, chips: node.roles })}
        />
        <Column
          field="last_modified"
          className="specto-table__column--md"
          header="Last Modified"
          body={(node) =>
            LastModifiedCell({
              nodeId: node.id,
              user: authors.find((author) => author.user === node.user),
              lastModified: node.last_modified
            })
          }
          sortable
        />
        <Column
          field="status"
          header="Status"
          className="specto-table__column--sm"
          body={(node: EntityTableDataModel) =>
            TextCell({ id: 'status-' + node.id, text: statusDisplayNames[node.status] })
          }
          sortable
        />
        <Column
          field="tags"
          header={<span className="pi pi-tag" />}
          body={(node: EntityTableDataModel) => (
            <TagsCell tags={mapTags(allTags, node.tags)} editable={false} />
          )}
          className="specto-table-icon specto-table__column--sm"
        />
        <Column
          field="util-comments"
          hidden={!showComments || readOnly || version.isVersionSelected}
          header="Comments"
          className="specto-table-icon specto-table__column--sm"
          body={(node: EntityTableDataModel) => (
            <CommentThreadButton
              key={`${node.id}-comments`}
              threadResolved={node.comment_thread_resolved}
              action={() => openComments(node)}
            />
          )}
        />
        <Column
          columnKey="util-action"
          className="specto-table__column--sm-2 pl-0"
          header="Actions"
          alignHeader="center"
          align="center"
          rowEditor
          hidden={readOnly || version.isVersionSelected}
          body={(node: EntityTableDataModel) => (
            <MenuButtonCell
              hidden={node.default}
              data-cy={`${node.id}-actions`}
              menu={[
                {
                  label: 'Approve',
                  visible: !readOnly && canApprove(node),
                  command: () => onApproveEntity?.(node)
                },
                {
                  label: 'Delete',
                  visible: canDelete && node.status === Status.Retired,
                  command: () => confirmDeleteRow(node)
                },
                {
                  label: 'Duplicate',
                  visible: node.status !== Status.Retired,
                  command: () => onDuplicateEntity?.(node)
                },
                {
                  label: 'Edit',
                  visible: node.status !== Status.Retired,
                  command: () => onEditInit?.(node)
                },
                {
                  label: 'Publish',
                  visible: !readOnly && canPublish(node),
                  command: () => onPublishEntity?.(node)
                },
                {
                  label: 'Retire',
                  visible: canDelete && node.status !== Status.Retired,
                  command: () => openRetirePopup(node)
                },
                {
                  label: 'Unretire',
                  visible: canDelete && node.status === Status.Retired,
                  command: () =>
                    RemoveRetiredDialog({
                      objectName: CommentType.ENTITY,
                      accept: () => onRetireChange?.(node, false)
                    })
                },
                {
                  label: 'No Actions Available',
                  disabled: true,
                  visible: !canDelete && node.status === Status.Retired
                }
              ]}
            />
          )}
        />
      </DataTable>
      {/* Need to use dialog component instead of method because the Retire dialog
          needs to be closed before the delete dialog can be opened */}
      <RetireDialog
        objectName={CommentType.ENTITY}
        accept={() => selectedNode && onRetireChange?.(selectedNode, true)}
        onHide={() => setShowRetireDialog(false)}
        showRetireDialog={showRetireDialog}
      />

      <CommentPopup
        displayPopup={showCommentPopup}
        onPopupDisplayChange={setShowCommentPopup}
        objectType={CommentType.ENTITY}
        objectId={selectedNode.id}
        objectName={selectedNode.name}
        parentElement={document.activeElement as HTMLElement}
        onThreadStateChange={onThreadStateChange}
      />
    </div>
  );
};

export default EntityDataTable;
