export const entityOptionColors = [
  '#2bb0f3',
  '#cd98ff',
  '#ffd633',
  '#60ce77',
  '#fc8c37',
  '#33b3ff',
  '#BF8CDB',
  '#E5C322',
  '#55C35E',
  '#F95F24',
  '#F4498F',
  '#F864F9',
  '#299ED5',
  '#2DCF68',
  '#E5E500',
  '#4FB4F9',
  '#CA5FCA',
  '#5BD7D7',
  '#305DD5',
  '#C464C4',
  '#30D7A9',
  '#E68C2B',
  '#6D2FCF',
  '#E600E6',
  '#2DA9A9',
  '#CCCC00',
  '#2DA9D7',
  '#B28FDA',
  '#D5CC38',
  '#67CB81',
  '#F57E45',
  '#EE5B9C',
  '#F17DFC',
  '#35E677',
  '#C970C9',
  '#6FEAEA',
  '#4468F5',
  '#D36DD3',
  '#3AE6B9',
  '#FFAD5F',
  '#6D3AFF',
  '#FF4DFF',
  '#3AB3BB',
  '#49B6F0',
  '#B08BDA',
  '#D3C02C',
  '#6FC88E',
  '#F5753C',
  '#F17FF8',
  '#33E67A'
];
