import ApiService from 'services/apiService';
import { SentimentModel } from 'models/sentiment-model';
import { SENTIMENTS_API_URL } from 'constants/api';
import { SlugOptionsModel } from 'models/api-model';

const getSentiments = (options: SlugOptionsModel) =>
  ApiService.get<SentimentModel[]>('/' + options.slug + SENTIMENTS_API_URL);

const SentimentsService = { getSentiments };

export default SentimentsService;
