import React, { useEffect, useState } from 'react';
// prime react
import { Button } from 'primereact/button';
// components
import SearchBar from 'components/SearchBar';
import SynonymDataTable from './components/SynonymDataTable';
import SynonymPopup from './components/SynonymPopup';
// constants
import { FetchStatusOptions } from 'constants/fetchStatus';
// models
import {
  EditSynonymOptionsModel,
  PostSynonymOptionsModel,
  SynonymTableAPIModel,
  SynonymTableDataModel
} from 'models/synonym-model';
// hooks
import { useAppDispatch, useAppSelector } from 'hooks/store';
import useFetch from 'hooks/useFetch';
// services
import SynonymsService from 'services/synonymsService';
// selectors
import { setLayout } from 'features/layout/layoutSlice';
import {
  addSynonym as addSynonymState,
  deleteSynonym as deleteSynonymState,
  editSynonym as editSynonymState,
  getSynonyms,
  projectsSelector,
  synonymsSelector
} from 'features/projects/projectsSlice';
import { SlugIdOptionsModel } from 'models/api-model';
import { versionsSelector } from 'features/versions/versionsSlice';
import { createToast } from 'features/toast/toastSlice';
import useDataFilter from 'hooks/useDataFilter';
import { ThreadStateChangeEvent } from 'pages/Comments/CommentPopup';
import { FormMode } from 'models/form-model';
import { FieldValues } from 'react-hook-form';
import { permissionBoolean } from 'util/permissions';
import CommentsService from 'services/commentsService';
import { getMainLayout } from 'util/layout';

const Synonym = () => {
  const { selectedProject, projects } = useAppSelector(projectsSelector);
  const { isVersionSelected } = useAppSelector(versionsSelector);
  const allSynonyms = useAppSelector(synonymsSelector);
  const [contextSynonym, setContextSynonym] = useState<SynonymTableDataModel | null>(null);
  const [popupMode, setPopupMode] = useState<FormMode>(FormMode.CREATE);
  const [displaySynonymPopup, setDisplaySynonymPopup] = useState(false);
  const dispatch = useAppDispatch();
  const {
    searchText,
    search,
    clearSearch,
    simpleSearch,
    results: filteredSynonyms,
    loading
  } = useDataFilter({
    searchTextFields: 'text,phrases',
    items: allSynonyms
  });

  /* - - - - - - - - - - Init - - - - - - - - - - */

  useEffect(() => {
    dispatch(setLayout(getMainLayout(selectedProject.project_user.role, projects.length)));
    dispatch(getSynonyms({ slug: selectedProject.slug }));
  }, []);

  /* - - - - - - - - - - Get Synonyms - - - - - - - - - - */

  const {
    fetch: getSynonym,
    data: getSynonymData,
    fetchStatus: getSynonymStatus
  } = useFetch(SynonymsService.getSynonym, SynonymsService.roles.list);

  useEffect(() => {
    if (getSynonymStatus === FetchStatusOptions.SUCCESS && getSynonymData) {
      dispatch(editSynonymState(getSynonymData));
    }
  }, [getSynonymStatus]);

  /* - - - - - - - - - - Create Synonym - - - - - - - - - - */

  const {
    fetch: postSynonym,
    data: newSynonym,
    fetchStatus: synonymPostStatus,
    permission: canCreateSynonym
  } = useFetch<PostSynonymOptionsModel, SynonymTableDataModel>(
    SynonymsService.createSynonym,
    SynonymsService.roles.create
  );

  useEffect(() => {
    if (synonymPostStatus === FetchStatusOptions.SUCCESS && newSynonym) {
      dispatch(addSynonymState(newSynonym));
      dispatch(createToast('synonym created'));
      hidePopup();
    }
  }, [synonymPostStatus]);

  /* - - - - - - - - - - Edit Synonym - - - - - - - - - - */
  const {
    fetch: editSynonym,
    data: updatedSynonym,
    fetchStatus: synonymEditStatus,
    permission: canEditSynonym
  } = useFetch<EditSynonymOptionsModel, SynonymTableDataModel>(
    SynonymsService.editSynonym,
    SynonymsService.roles.update
  );

  useEffect(() => {
    if (synonymEditStatus === FetchStatusOptions.SUCCESS && updatedSynonym) {
      dispatch(editSynonymState(updatedSynonym));
      dispatch(createToast('synonym edited'));
      hidePopup();
    }
  }, [synonymEditStatus]);

  /* - - - - - - - - - - Delete Synonym - - - - - - - - - - */

  const {
    fetch: deleteSynonym,
    fetchStatus: synonymDeleteStatus,
    fetchOptions: deleteSynonymOptions,
    permission: canDelete
  } = useFetch<SlugIdOptionsModel<SynonymTableDataModel['id']>, SynonymTableDataModel>(
    SynonymsService.deleteSynonym,
    SynonymsService.roles.delete
  );

  useEffect(() => {
    if (synonymDeleteStatus === FetchStatusOptions.SUCCESS && deleteSynonymOptions) {
      dispatch(deleteSynonymState(deleteSynonymOptions.id));
      dispatch(createToast('synonym deleted'));
    }
  }, [synonymDeleteStatus]);

  const deleteTableSynonym = (node: SynonymTableDataModel) =>
    deleteSynonym({
      slug: selectedProject.slug,
      id: node.id
    });

  /* - - - - - - - - - - Popup - - - - - - - - - - */

  /**
   * Callback to change the new Synonym pop-up visibility in state.
   * @param visibility boolean representing the visibility of the new Synonym popup
   * @param context the context Synonym, used for editing
   */
  const handlePopupDisplayChange = (visibility: boolean, context?: SynonymTableDataModel) => {
    if (context) {
      setContextSynonym(context);
      setPopupMode(FormMode.EDIT);
    } else {
      setPopupMode(FormMode.CREATE);
      setContextSynonym(null);
    }
    setDisplaySynonymPopup(visibility);
  };

  const onSubmit = (data: FieldValues) => {
    const tableData = data as SynonymTableAPIModel;

    if (contextSynonym && popupMode === FormMode.EDIT) {
      editSynonym({
        slug: selectedProject.slug,
        synonymId: contextSynonym.id,
        updatedSynonymData: tableData
      });
    } else {
      postSynonym({ slug: selectedProject.slug, newSynonymData: tableData });
    }
  };

  const hidePopup = () => handlePopupDisplayChange(false);

  /* - - - - - - - - - - Comments - - - - - - - - - - */

  const onThreadChange = (options: ThreadStateChangeEvent) => getSynonym(options);

  return (
    <div className="specto-synonym">
      <SynonymPopup
        displayPopup={displaySynonymPopup}
        onHide={hidePopup}
        onSubmit={onSubmit}
        contextSynonym={contextSynonym}
        mode={popupMode}
        loading={
          synonymPostStatus === FetchStatusOptions.LOADING ||
          synonymEditStatus === FetchStatusOptions.LOADING
        }
        parentElement={document.activeElement as HTMLElement}
      />
      <div className="grid grid-nogutter">
        <div className="col flex flex-wrap xl:align-items-center align-items-start justify-content-between flex-column-reverse xl:flex-row">
          <SearchBar
            placeHolder="Search by Synonym or Words/Phrases"
            text={searchText}
            loading={loading}
            className="specto-search-bar w-12 lg:w-7 xl:w-5 mt-3 xl:mt-0"
            onChange={(e) => simpleSearch(e.target.value)}
            onSubmitText={search}
            onClearText={() => {
              clearSearch();
              search('');
            }}
          />
          {!isVersionSelected && canCreateSynonym && (
            <Button
              label="New Synonym"
              icon="pi pi-plus"
              iconPos="right"
              onClick={() => handlePopupDisplayChange(true)}
            />
          )}
        </div>
        <div className="col-12 mt-4">
          <div className="card">
            <SynonymDataTable
              synonyms={filteredSynonyms}
              canDelete={canDelete}
              onThreadStateChange={onThreadChange}
              onDeleteSynonym={deleteTableSynonym}
              onEditInit={(synonym) => handlePopupDisplayChange(true, synonym)}
              readOnly={!canEditSynonym}
              showComments={permissionBoolean(
                CommentsService.roles.general.list,
                selectedProject.project_user.role
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Synonym;
