import { randomInt } from 'util/random';

/**
 * Generate a random unique ID
 * @param base base string to include at the start of id's 'base-id'
 * @returns A unique string ID
 */
export const uniqueId = (base?: string) => {
  const uniqueId = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
  return base ? `${base}-${uniqueId}` : uniqueId;
};

/**
 * Generate Name in a sequential copy format to generate a unique name.
 * Based on Unix copy style
 * @param name to copy
 * @param nameList all existing ids
 */
export const copyNameFormatter = function ({
  name,
  nameList
}: {
  name: string;
  nameList: string[];
}) {
  const maxTries = 30;
  const usedNames = new Set(nameList);
  let copyName = `${name}-copy`;
  let copyNumber = 1;

  let primaryCopy = false;
  let secondaryCopy = false;

  // matches 'some_name-copy'
  if (new RegExp('-copy$').test(name)) {
    primaryCopy = true;
  }

  // matches 'some_name-copy (1)'
  if (new RegExp('-copy-\\d+$').test(name)) {
    secondaryCopy = true;
  }

  // if it is a normal name and 'some_name-copy' doesn't clash, return it
  if (!primaryCopy && !secondaryCopy && !usedNames.has(copyName)) {
    return copyName;
  }

  // already a primary copy of format 'some_name-copy' so set copyName to that
  if (primaryCopy) {
    copyName = name;
  }

  if (secondaryCopy) {
    // update index
    const parsedIndex = Number(name.split(/\D+/)[1]);
    if (!isNaN(parsedIndex)) {
      copyNumber = parsedIndex + 1;
    }

    // extract just 'some_name-copy'
    const newCopyName = name.split(/-\d+$/)[0];
    if (newCopyName.length > 0) {
      copyName = newCopyName;
    }
  }

  // expecting copyName to be 'some_name copy'
  while (copyNumber < maxTries) {
    if (!usedNames.has(`${copyName}-${copyNumber}`)) {
      return `${copyName}-${copyNumber}`;
    }
    copyNumber++;
  }

  // fallback to a random number
  return `${copyName}-${randomInt()}`;
};
