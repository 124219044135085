import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import classnames from 'classnames';

import { setLayout } from 'features/layout/layoutSlice';
import ModalLarge from 'containers/modals/ModalLarge';
import { dirtyValues, getFormErrorMessage, mapKeys } from 'util/form';
import {
  EditEventsDatabaseSyncOptionsModel,
  EditEventsDatabaseOptionsModel,
  EventsDatabaseSyncModel,
  EventsDatabaseSyncRequestAPIModel,
  EventsDatabaseModel,
  EventsDatabaseRequestAPIModel
} from 'models/project-model';
import { Password } from 'primereact/password';
import useFetch from 'hooks/useFetch';
import ProjectsService from 'services/projectsService';
import {
  addMetadataFilters as addMetadataStore,
  deleteMetadataFilters as deleteMetadataStore,
  editMetadataFilters as editMetadataStore,
  projectsSelector
} from 'features/projects/projectsSlice';
import { FetchStatusOptions } from 'constants/fetchStatus';
import { createToast } from 'features/toast/toastSlice';
import {
  SlugDataIdOptionsModel,
  SlugDataOptionsModel,
  SlugIdOptionsModel,
  SlugOptionsModel
} from 'models/api-model';
import { TextField, ToggleDividedButton } from 'components/Forms';
import { DatabaseField, EventsDatabaseSyncField } from 'constants/databaseFields';
import { MetadataAPIModel, MetadataFilterModel } from 'models/metadata-filter-model';
import { confirmDialog } from 'primereact/confirmdialog';
import useToggleKeys from 'hooks/useToggleKeys';
import useFilter from 'hooks/useFilter';
import MetadataService from 'services/metadataService';
import LoadingSpinner from 'components/LoadingSpinner';
import { getProjectSettingsLayout } from 'util/layout';
import { authSelector } from 'features/auth/authSlice';

const EventDatabaseManager = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(authSelector);
  const { selectedProject, metadataFilters } = useAppSelector(projectsSelector);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const {
    data: eventsDatabaseEditData,
    fetch: editEventsDatabaseData,
    fetchStatus: editEventsDatabaseDataStatus
  } = useFetch<EditEventsDatabaseOptionsModel, EventsDatabaseModel>(
    ProjectsService.editEventsDatabaseData,
    ProjectsService.roles.database.update
  );
  const {
    data: eventsDatabaseGetData,
    fetch: getEventsDatabaseData,
    fetchStatus: getEventsDatabaseDataStatus
  } = useFetch<SlugOptionsModel, EventsDatabaseModel>(
    ProjectsService.getEventsDatabaseData,
    ProjectsService.roles.database.retrieve
  );
  const {
    data: eventsDatabaseSyncEditData,
    fetch: editEventsDatabaseSyncData,
    fetchStatus: editEventsDatabaseSyncDataStatus
  } = useFetch<EditEventsDatabaseSyncOptionsModel, EventsDatabaseSyncModel>(
    ProjectsService.editEventsDatabaseSyncData,
    ProjectsService.roles.database.update
  );
  const {
    data: eventsDatabaseSyncGetData,
    fetch: getEventsDatabaseSyncData,
    fetchStatus: getEventsDatabaseSyncDataStatus
  } = useFetch<SlugOptionsModel, EventsDatabaseSyncModel>(
    ProjectsService.getEventsDatabaseSyncData,
    ProjectsService.roles.database.retrieve
  );

  const databaseRelatedKeys = {
    databaseName: 'db_name',
    databaseHost: 'db_host',
    databasePort: 'db_port',
    databaseUser: 'db_user',
    databasePassword: 'db_password',
    validConnection: 'valid_connection',
    syncEvents: 'sync_events'
  };

  const eventsDatabaseDefaultValues: FieldValues = {
    databaseName: '',
    databaseHost: '',
    databasePort: 0,
    databaseUser: '',
    databasePassword: '',
    validConnection: false,
    syncEvents: false
  };

  const eventsDatabaseSyncDefaultValues: FieldValues = {
    validConnection: false,
    syncEvents: false
  };

  const {
    control: eventsDatabaseControl,
    reset: resetEventsDatabase,
    formState: { dirtyFields: eventsDatabaseDirtyFields, isDirty: isEventsDatabaseFormDirty },
    handleSubmit: eventsDatabaseHandleSubmit
  } = useForm({ defaultValues: eventsDatabaseDefaultValues, mode: 'onSubmit' });

  const {
    control: eventsDatabaseSyncControl,
    reset: resetEventsDatabaseSync,
    resetField: resetEventsDatabaseSyncField,
    watch: watchEventsDatabaseSync,
    formState: { errors: eventsDatabaseSyncErrors },
    handleSubmit: eventsDatabaseSyncHandleSubmit
  } = useForm({ defaultValues: eventsDatabaseSyncDefaultValues });
  const watchCurrentSync = watchEventsDatabaseSync(EventsDatabaseSyncField.SYNC_EVENTS);

  // onInit
  useEffect(() => {
    dispatch(setLayout(getProjectSettingsLayout(user.is_superuser)));

    getEventsDatabaseData({ slug: selectedProject.slug });
    getEventsDatabaseSyncData({ slug: selectedProject.slug });
  }, []);

  // get events database details
  useEffect(() => {
    if (getEventsDatabaseDataStatus === FetchStatusOptions.SUCCESS && eventsDatabaseGetData) {
      setIsInitialLoad(false);
      resetEventsDatabaseForm(eventsDatabaseGetData);
    }
  }, [getEventsDatabaseDataStatus]);

  useEffect(() => {
    if (
      getEventsDatabaseSyncDataStatus === FetchStatusOptions.SUCCESS &&
      eventsDatabaseSyncGetData
    ) {
      eventsDatabaseSyncForm(eventsDatabaseSyncGetData);
    }
  }, [getEventsDatabaseSyncDataStatus]);

  // edit events database details
  useEffect(() => {
    if (editEventsDatabaseDataStatus === FetchStatusOptions.SUCCESS && eventsDatabaseEditData) {
      resetEventsDatabaseForm(eventsDatabaseEditData);
      dispatch(createToast('events database details edited'));
    }
  }, [editEventsDatabaseDataStatus]);

  // edit events database sync details
  useEffect(() => {
    if (
      editEventsDatabaseSyncDataStatus === FetchStatusOptions.SUCCESS &&
      eventsDatabaseSyncEditData
    ) {
      resetEventsDatabaseSync({
        syncEvents: eventsDatabaseSyncEditData.sync_events,
        validConnection: eventsDatabaseSyncEditData.valid_connection
      });
      dispatch(createToast('events database sync details edited'));
    } else if (editEventsDatabaseSyncDataStatus === FetchStatusOptions.ERROR) {
      resetEventsDatabaseSyncField(EventsDatabaseSyncField.SYNC_EVENTS);
    }
  }, [editEventsDatabaseSyncDataStatus]);

  const resetEventsDatabaseForm = (data: FieldValues) => {
    const storeValues = {
      databaseName: data.db_name || '',
      databaseHost: data.db_host || '',
      databasePort: data.db_port || '',
      databaseUser: data.db_user || '',
      databasePassword: '',
      validConnection: data.valid_connection || false
    };

    resetEventsDatabase(storeValues);
  };

  const eventsDatabaseSyncForm = (data: FieldValues) =>
    resetEventsDatabaseSync({
      validConnection: data.valid_connection || false,
      syncEvents: data.sync_events || false
    });

  const onEditEventsDatabase = (data: FieldValues) => {
    const formValues = dirtyValues(eventsDatabaseDirtyFields, data);

    if (Object.keys(formValues).length === 0) return;

    // map keys from front-end keys to back-end keys
    const editEventsDatabaseRequest: EventsDatabaseRequestAPIModel = mapKeys(
      databaseRelatedKeys,
      data
    );

    editEventsDatabaseData({
      slug: selectedProject.slug,
      eventsDatabase: editEventsDatabaseRequest
    });
  };

  const onEditEventsDatabaseSync = (data: FieldValues) => {
    if (Object.keys(data).length === 0 || !selectedProject) {
      return;
    }

    // can equate directly because this type has all optional properties that formValues may or may not have
    const editEventsDatabaseSyncRequest: EventsDatabaseSyncRequestAPIModel = {
      sync_events: data.syncEvents
    };

    editEventsDatabaseSyncData({
      slug: selectedProject.slug,
      sync_events: editEventsDatabaseSyncRequest.sync_events
    });
  };

  const editEventsDatabaseFormValidate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    eventsDatabaseHandleSubmit(onEditEventsDatabase)();
  };

  const editEventsDatabaseSyncFormValidate = () => {
    eventsDatabaseSyncHandleSubmit(onEditEventsDatabaseSync)();
  };

  /* - - - - - - - - - - Metadata - - - - - - - - - - */
  const messageInboxMetadataDefaultValues: FieldValues = {
    metadata: [{ field: '', alias: '' }]
  };

  const {
    toggleKeySingle: setMetadataEditMode,
    isKeyExpanded: isMetadataEditMode,
    clearKeys: clearAllMetadataEditMode,
    hasExpandedKeys: someMetadataEditMode,
    keys: editModeMetadata
  } = useToggleKeys();
  const {
    toggleKeySingle: setMetadataRowLoading,
    isKeyExpanded: isMetadataRowLoading,
    clearKeys: clearAllMetadataRowLoading
  } = useToggleKeys();
  const {
    filteredData: filteredMetadataData,
    // search: searchMetadata,
    // searchText,
    setData: setFilteredMetadataData
    // loading: searchMetadataLoading
  } = useFilter<MetadataFilterModel>();
  const { fetchStatus: getMetadataStatus } = useFetch<SlugOptionsModel, MetadataFilterModel[]>(
    MetadataService.getMetadata,
    MetadataService.roles.list
  );
  const {
    data: editMetadataData,
    fetchStatus: editMetadataStatus,
    fetch: editMetadata
  } = useFetch<SlugDataIdOptionsModel<MetadataAPIModel>, MetadataFilterModel>(
    MetadataService.editMetadata,
    MetadataService.roles.update
  );
  const {
    fetchStatus: deleteMetadataStatus,
    fetch: deleteMetadata,
    fetchOptions: deleteMetadataOptions
  } = useFetch<SlugIdOptionsModel<MetadataFilterModel['id']>>(
    MetadataService.deleteMetadata,
    MetadataService.roles.delete
  );
  const {
    data: createMetadataData,
    fetchStatus: createMetadataStatus,
    fetch: createMetadata
  } = useFetch<SlugDataOptionsModel<MetadataAPIModel>, MetadataFilterModel>(
    MetadataService.createMetadata,
    MetadataService.roles.create
  );

  const {
    control: newMetadataControl,
    handleSubmit: newMetadataHandleSubmit,
    reset: resetNewMetadataForm
  } = useForm({
    defaultValues: messageInboxMetadataDefaultValues,
    mode: 'onSubmit'
  });

  const {
    control: editMetadataControl,
    formState: { dirtyFields: editMetadataDirtyFields },
    handleSubmit: editMetadataHandleSubmit,
    reset: editMetadataFormReset
  } = useForm();

  // editMetadata response effect
  useEffect(() => {
    if (editMetadataStatus === FetchStatusOptions.SUCCESS && editMetadataData) {
      editMetadataFormReset();
      clearAllMetadataEditMode();
      dispatch(editMetadataStore(editMetadataData));
      dispatch(createToast('metadata field edited'));
    }
  }, [editMetadataStatus]);

  // createMetadata response effect
  useEffect(() => {
    if (createMetadataStatus === FetchStatusOptions.SUCCESS && createMetadataData) {
      dispatch(addMetadataStore(createMetadataData));
      dispatch(createToast('metadata field created'));
      resetNewMetadataForm();
    }
  }, [createMetadataStatus]);

  // deleteMetadata response effect
  useEffect(() => {
    if (deleteMetadataStatus === FetchStatusOptions.SUCCESS && deleteMetadataOptions) {
      editMetadataFormReset();
      clearAllMetadataRowLoading();
      dispatch(deleteMetadataStore(deleteMetadataOptions.id));
      dispatch(createToast('metadata field deleted'));
    }
  }, [deleteMetadataStatus]);

  const onAddMetadataFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    newMetadataHandleSubmit(onCreateMetadata)();
  };

  const onEditMetadataFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    editMetadataHandleSubmit(onEditMetadata)();
  };

  const onEditMetadataCancel = () => {
    clearAllMetadataEditMode();
    editMetadataFormReset();
  };

  const onCreateMetadata = (data: FieldValues) => {
    const createMetadataRequest: MetadataAPIModel = {
      filter: data.filter,
      alias: data.alias
    };

    createMetadata({ slug: selectedProject.slug, data: createMetadataRequest });
  };

  const onEditMetadata = (data: FieldValues) => {
    const formValues = dirtyValues(editMetadataDirtyFields, data);
    if (Object.keys(formValues).length === 0 || !selectedProject || !someMetadataEditMode) {
      return;
    }

    // get the edit mode metadata id, it will be the only key expanded
    // should get this from the active edit mode useKey rather than the form.
    // useKeys guarantees only 1 edit mode active at a time, the form record formValues does not.
    const editModeMetadataId = Object.keys(editModeMetadata)?.[0];

    // pipe local forms newMetadataName property to name, if it exists
    const editMetadataRequest: MetadataAPIModel = {
      ...(formValues[editModeMetadataId]?.filter && {
        filter: formValues[editModeMetadataId].filter
      }),
      ...(formValues[editModeMetadataId]?.alias && { alias: formValues[editModeMetadataId].alias })
    };

    editMetadata({ id: editModeMetadataId, slug: selectedProject.slug, data: editMetadataRequest });
  };

  const onMetadataDelete = (metadata: MetadataFilterModel) => {
    // manually set the row loading for the specific metadata
    setMetadataRowLoading(metadata.id);
    deleteMetadata({ slug: selectedProject.slug, id: metadata.id });
  };

  const uniqueFilterFieldRule = (value: string, fieldValues: FieldValues) =>
    !filteredMetadataData.some(
      (meta) => meta.filter === value && fieldValues[meta.id] === undefined
    ) || 'Duplicate Field';

  // set metadata filters when
  useEffect(() => setFilteredMetadataData(metadataFilters), [metadataFilters]);

  const confirmDeleteMetadata = (metadata: MetadataFilterModel) => {
    confirmDialog({
      message: 'This action cannot be undone.',
      header: 'Delete Metadata?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => onMetadataDelete(metadata)
    });
  };

  return (
    <ModalLarge
      loading={getMetadataStatus === FetchStatusOptions.LOADING}
      className="specto-project-settings"
    >
      <h1 className="align-self-center mb-7">Event Database Manager</h1>

      <h5>Event Database Settings</h5>
      <span className="mb-4">Manage the connection to the Rasa Client-Conversation DB</span>

      <form
        className="p-fluid flex flex-wrap justify-content-between mb-5"
        onSubmit={editEventsDatabaseSyncFormValidate}
      >
        <div className="w-full">
          <div>
            <h5>Sync Status:</h5>
            <Controller
              name={EventsDatabaseSyncField.SYNC_EVENTS}
              control={eventsDatabaseSyncControl}
              render={({ field }) => {
                return (
                  <ToggleDividedButton
                    className="specto-nav-skip"
                    checked={field.value}
                    disabled={
                      editEventsDatabaseSyncDataStatus === FetchStatusOptions.LOADING ||
                      getEventsDatabaseSyncDataStatus === FetchStatusOptions.LOADING ||
                      (!field.value && isEventsDatabaseFormDirty)
                    }
                    tooltip={`Event Database configuration has ${
                      isEventsDatabaseFormDirty ? 'unsaved changes' : 'errors'
                    }`}
                    tooltipOptions={{
                      showOnDisabled: true,
                      position: 'bottom',
                      disabled: field.value || !isEventsDatabaseFormDirty
                    }}
                    onChange={(e: any) => {
                      // only when events database form is valid or toggling off the sync
                      if (!isEventsDatabaseFormDirty || !e.value) {
                        field.onChange(e.value);
                        editEventsDatabaseSyncFormValidate();
                      }
                    }}
                  />
                );
              }}
            />
            {getFormErrorMessage(eventsDatabaseSyncErrors?.syncEvents)}
          </div>
        </div>
        <div>
          <Controller
            name={EventsDatabaseSyncField.VALID_CONNECTION}
            control={eventsDatabaseSyncControl}
            render={({ field }) => {
              return (
                <div className="flex mb-3">
                  <h5 className="mr-3">Valid Connection:</h5>
                  {getEventsDatabaseDataStatus === FetchStatusOptions.LOADING || isInitialLoad ? (
                    <LoadingSpinner small position="inline" />
                  ) : field.value ? (
                    <span className="text-green-300 text-xl font-bold">True</span>
                  ) : (
                    <span className="p-error text-xl font-bold">False</span>
                  )}
                </div>
              );
            }}
          />
        </div>
      </form>

      <form
        className="p-fluid mt-3 flex flex-wrap justify-content-between"
        onSubmit={editEventsDatabaseFormValidate}
      >
        <TextField
          fieldName={DatabaseField.DATABASE_NAME}
          label="Database Name"
          className="lg:w-6 lg:pr-3 w-full"
          control={eventsDatabaseControl}
          disabled={watchCurrentSync}
        />
        <TextField
          fieldName={DatabaseField.DATABASE_HOST}
          label="Database Host"
          className="lg:w-6 lg:pl-3 w-full"
          control={eventsDatabaseControl}
          disabled={watchCurrentSync}
        />
        <TextField
          fieldName={DatabaseField.DATABASE_USER}
          label="Database User"
          className="lg:w-6 lg:pr-3 w-full"
          control={eventsDatabaseControl}
          disabled={watchCurrentSync}
        />
        <TextField
          fieldName={DatabaseField.DATABASE_PORT}
          label="Database Port"
          className="lg:w-6 lg:pl-3 w-full"
          keyFilter="pint"
          control={eventsDatabaseControl}
          disabled={watchCurrentSync}
        />
        <div className="field mb-3 lg:w-6 lg:pr-3 w-full">
          <label htmlFor="databasePassword">Database Password</label>
          <Controller
            name={DatabaseField.DATABASE_PASSWORD}
            control={eventsDatabaseControl}
            rules={{ required: 'password required to update configuration' }}
            render={({ field, fieldState }) => (
              <>
                <Password
                  id={field.name}
                  {...field}
                  toggleMask
                  feedback={false}
                  placeholder="********"
                  disabled={watchCurrentSync}
                  className={classnames('specto-input-password', {
                    'p-invalid': fieldState.error
                  })}
                />
                {getFormErrorMessage(fieldState.error)}
              </>
            )}
          />
        </div>

        {/* Placeholder div to force the next one to wrap to the next line */}
        <div className="w-full"></div>

        <div className="field mb-5 lg:w-5 w-full align-content-center force-wrap">
          <Button
            loading={
              editEventsDatabaseDataStatus === FetchStatusOptions.LOADING ||
              getEventsDatabaseDataStatus === FetchStatusOptions.LOADING
            }
            type="submit"
            label="Update Details"
            className="m-auto py-2 px-3 font-bold w-full"
            disabled={watchCurrentSync}
            tooltip="Disable sync to edit configuration"
            tooltipOptions={{
              showOnDisabled: true,
              disabled: !watchCurrentSync
            }}
          />
        </div>
      </form>

      <form className="w-full mt-8" onSubmit={onAddMetadataFormSubmit}>
        <h5>Metadata Filters</h5>
        <div>Populate field suggestions when filtering with metadata</div>
        <hr className="w-8" />

        <div className="flex flex-column md:flex-row w-full align-content-start md:align-content-center justify-content-between">
          <TextField
            className="col md:mr-1 pl-0"
            fieldName="filter"
            control={newMetadataControl}
            placeholder="Field"
            rules={{ validate: uniqueFilterFieldRule }}
            noPadding
          />
          <TextField
            className="col md:mr-1 pl-0"
            fieldName="alias"
            control={newMetadataControl}
            placeholder="Alias"
            noPadding
          />

          <div className="md:m-auto mt-3 flex justify-content-start">
            <Button
              label="Add Metadata"
              type="submit"
              loading={createMetadataStatus === FetchStatusOptions.LOADING}
            />
          </div>
        </div>
      </form>

      <form className="w-full pt-6" onSubmit={onEditMetadataFormSubmit}>
        <h6 className="w-6 mb-4">All Metadata Filters</h6>

        <div className="w-full">
          <div className="flex justify-content-between w-full">
            <div className="flex align-items-center justify-content-start w-full">
              <div className="flex w-full">
                <span className="w-6 m-0 specto-text-muted">Field</span>
                <span className="w-6 m-0 specto-text-muted">Alias</span>
              </div>
            </div>
            <div className="flex w-1 mx-3"></div>
          </div>
        </div>

        <hr className="w-8 mt-0 mb-2" />

        {filteredMetadataData?.map((metadata: MetadataFilterModel) =>
          isMetadataEditMode(metadata.id) ? (
            <div
              key={metadata.id}
              className="flex flex-column md:flex-row w-full align-content-start md:align-content-center justify-content-between"
            >
              <TextField
                placeholder="Field"
                defaultValue={metadata.filter}
                fieldName={metadata.id + '.filter'}
                rules={{ validate: uniqueFilterFieldRule }}
                className="col md:mr-5 pl-0"
                control={editMetadataControl}
                noPadding
              />
              <TextField
                placeholder="Alias"
                defaultValue={metadata.alias}
                fieldName={metadata.id + '.alias'}
                className="col md:mr-5 pl-0"
                control={editMetadataControl}
                noPadding
              />

              <div className="md:m-auto mt-3 flex">
                <Button
                  icon="pi pi-check"
                  aria-label="Confirm Metadata Edit"
                  className="p-button-rounded specto-r-button-lg"
                  type="submit"
                  loading={editMetadataStatus === FetchStatusOptions.LOADING}
                />
                <Button
                  icon="pi pi-times"
                  aria-label="Cancel Metadata Edit"
                  className="p-button-rounded specto-r-button-lg ml-1"
                  onClick={onEditMetadataCancel}
                  disabled={editMetadataStatus === FetchStatusOptions.LOADING}
                  type="button"
                />
              </div>
            </div>
          ) : (
            <div className="w-full pb-2" key={metadata.id}>
              <div className="flex justify-content-between w-full">
                <div className="flex align-items-center justify-content-start w-full">
                  <div className="flex w-full">
                    <h6 className="w-6 m-0 font-semibold">{metadata.filter}</h6>
                    <h6 className="w-6 m-0 font-semibold">{metadata.alias}</h6>
                  </div>
                </div>

                <div className="flex">
                  <Button
                    icon="pi pi-pencil"
                    aria-label="Edit Metadata"
                    className="p-button-rounded specto-r-button-lg"
                    onClick={() => setMetadataEditMode(metadata.id)}
                    disabled={editMetadataStatus === FetchStatusOptions.LOADING}
                    type="button"
                  />
                  <Button
                    icon="pi pi-trash"
                    aria-label="Delete Metadata"
                    className="p-button-rounded specto-r-button-lg ml-1"
                    onClick={() => confirmDeleteMetadata(metadata)}
                    loading={isMetadataRowLoading(metadata.id)}
                    disabled={deleteMetadataStatus === FetchStatusOptions.LOADING}
                    type="button"
                  />
                </div>
              </div>
            </div>
          )
        )}
      </form>
    </ModalLarge>
  );
};

export default EventDatabaseManager;
