import Avatar from 'components/Avatar';
import { UserModel } from 'models/user-model';
import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { UserMention } from './UserMention';
import classnames from 'classnames';

/**
 * Full sized input that allows mentions
 * Includes a send button and avatar
 *
 * @param users all users to search for
 * @param currentUser user currently logged in
 * @param addComment function to add a comment
 * @param disabled whether the input can be interacted with
 * @param className custom class name
 * @component
 */
export const MentionInput = function ({
  users,
  currentUser,
  addComment,
  disabled,
  className
}: {
  users: UserModel[];
  currentUser: UserModel;
  addComment(text: string): void;
  disabled: boolean;
  className?: string;
}) {
  const [text, setText] = useState('');

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addComment(text);
    setText('');
  };

  return (
    <form className={classnames(className, 'flex pt-2 align-items-center')} onSubmit={onSubmit}>
      <div className="w-1">
        <Avatar
          fullName={{
            first: currentUser.first_name,
            last: currentUser.last_name
          }}
          className="mr-2"
        />
      </div>
      <UserMention
        className="w-full"
        users={users}
        disabled={disabled}
        comment={text}
        setComment={setText}
      />

      <Button className="ml-3 w-3" type="submit" label="SEND" disabled={disabled} />
    </form>
  );
};
