import { ConversationDataModel, ConversationsDataModel } from 'models/events-model';
import React from 'react';
import { humanReadableDateTimeFormatted24 } from 'util/dates';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAll';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyToClipboard from 'react-copy-to-clipboard';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import conversationPaginatorTemplate from './ConversationPaginatorTemplate';
import classNames from 'classnames';
import Widget from 'components/Widget/Widget';
import { FetchStatusOptions } from 'constants/fetchStatus';
import LoadingSpinner from 'components/LoadingSpinner';
import Info from 'components/Forms/Info';
import { List } from '@mui/material';

/**
 * @param conversationsData data needed to render the convos
 * @param handleCopyConversationId handles adding conversation ID to clipboard
 * @param handleSelectConversation handles selecting conversation
 * @param handlePaginationChange handles toggling pagination
 * @param copiedConversationId currently copied conversation ID
 * @param paginationState current pagination state (page/limit)
 * @param selectedConversation currently selected and viewed conversation
 * @param getConversationsStatus current fetch status of conversations
 * @component
 */
const ConversationViewerContent = ({
  conversationsData,
  handleCopyConversationId,
  handleSelectConversation,
  handlePaginationChange,
  copiedConversationId,
  paginationState,
  selectedConversation,
  getConversationsStatus
}: {
  conversationsData: ConversationsDataModel;
  copiedConversationId: number | null;
  handleCopyConversationId(id: number): void;
  handleSelectConversation(conversation: ConversationDataModel): void;
  handlePaginationChange(first: number, rows: number): void;
  selectedConversation: ConversationDataModel | null;
  getConversationsStatus: FetchStatusOptions;
  paginationState: {
    rows: number;
    first: number;
    totalRecords: number;
    filters: any;
  };
}) => {
  return (
    <div className="convo-viewer-content bg-white border-1 h-full w-full flex flex-row">
      <div className="convo-view-convos-ctr flex flex-column border-right-1">
        <div className="convo-view-convos-header flex flex-row border-bottom-1 px-4 py-3 justify-content-between">
          <div className="all-conversations-header text-xl">All Conversations</div>
          <div className="flex flex-row align-items-center text-lg conversations-count">
            {conversationsData?.count}
            {conversationsData?.results_limited && (
              <Info
                text="Large number of results. Results have been limited for this query. "
                tooltipOptions={{ position: 'bottom' }}
              />
            )}
          </div>
        </div>
        <List className="flex flex-column h-full overflow-auto m-0 p-0">
          {getConversationsStatus === FetchStatusOptions.LOADING ? (
            <LoadingSpinner position="inline" />
          ) : (
            conversationsData?.results?.map((convo) => (
              <button
                tabIndex={0}
                key={convo.id}
                className={classNames(
                  'conversation-row border-none align-items-center flex flex-row gap-4 border-bottom-1',
                  {
                    'conversation-row-selected': convo.id === selectedConversation?.id
                  }
                )}
                onClick={() => handleSelectConversation(convo)}
                onKeyDown={(e) => e.code === 'Enter' && handleSelectConversation(convo)}
              >
                <CopyToClipboard text={convo.id.toString()}>
                  <div
                    role="button"
                    tabIndex={0}
                    className="copy-convo-id-btn flex flex-row p-2 align-items-center gap-2 border-none"
                    onKeyDown={(e) => {
                      if (e.code === 'Space') {
                        e.preventDefault();
                        handleCopyConversationId(convo.id);
                      }
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCopyConversationId(convo.id);
                    }}
                  >
                    <i className="pi pi-hashtag font-semibold convo-id-icon"></i>
                    {convo?.id.toString().padStart(8, '0')}
                    {copiedConversationId === convo.id ? (
                      <DoneAllRoundedIcon className="convo-row-icon" fontSize="small" />
                    ) : (
                      <ContentCopyIcon className="convo-row-icon" fontSize="small" />
                    )}
                  </div>
                </CopyToClipboard>
                <div className="flex flex-row align-items-center gap-2">
                  <CalendarTodayIcon htmlColor="#141414" fontSize="small" />{' '}
                  {humanReadableDateTimeFormatted24(convo.timestamp)}
                </div>
              </button>
            ))
          )}
        </List>
        <div className="convo-viewer-pagination border-top-1">
          <Paginator
            totalRecords={paginationState.totalRecords}
            first={paginationState.first}
            rows={paginationState.rows}
            template={conversationPaginatorTemplate}
            onPageChange={(e: PaginatorPageChangeEvent) => handlePaginationChange(e.first, e.rows)}
          />
        </div>
      </div>
      <div className="convo-preview-col flex flex-column flex-1">
        <div className="convo-view-convos-header flex flex-row border-bottom-1 px-4 py-3 justify-content-between">
          <div className="widget-preview-header text-xl">Preview</div>
        </div>
        <div
          className={classNames(
            'align-self-center flex flex-1 h-full w-full p-2 text-align-center overflow-x-auto',
            { 'pb-7': getConversationsStatus === FetchStatusOptions.LOADING }
          )}
        >
          {getConversationsStatus === FetchStatusOptions.LOADING ? (
            <LoadingSpinner position="inline" />
          ) : (
            selectedConversation && (
              <div className="widget-container">
                <Widget />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ConversationViewerContent;
