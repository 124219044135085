import { GENERIC_ERROR_MESSAGE } from 'constants/api';
import { QueryParameters } from 'models/api-model';

/**
 * Extract error message from Specto Chatbot Studio response
 * @param error error message
 */
export const responseErrorMessage = (error: any) => {
  let errorMsg =
    error?.detail ??
    Object.values(error?.response?.data)[0] ??
    error?.response?.data ??
    error?.message ??
    error;

  // show the first error if it's an array of errors
  if (Array.isArray(errorMsg)) {
    errorMsg = errorMsg[0];
  }

  const errorMessage = prettyObject(errorMsg);

  return errorMessage.length > 3 && errorMessage.length < 100
    ? errorMessage
    : GENERIC_ERROR_MESSAGE;
};

const prettyObject = (obj: string) =>
  JSON.stringify(obj)
    .replaceAll(/["\\[\]{}]/gi, '')
    .replaceAll(':', ': ')
    .replaceAll(',', '\n\n\n');

/**
 * Build structured query parameter from a QueryParameters object.
 * @param parameters QueryParameters object
 *
 * Filters out parameters that are '' because they filtered through Boolean() before joined & built.
 * This effects only empty arrays as should an array contain content,
 * it will be mapped and joined to become strings
 */
export const buildQueryString = (parameters: QueryParameters): string =>
  '?' +
  Object.entries(parameters)
    .map(([parameter, value]) =>
      Array.isArray(value)
        ? value.map((arrValue) => `${parameter}=${encodeURIComponent(arrValue)}`).join('&')
        : value !== '' && `${parameter}=${encodeURIComponent(value)}`
    )
    .filter(Boolean)
    .join('&');
