import React from 'react';
import { Control, Controller } from 'react-hook-form';
import classNames from 'classnames';
import { getFormErrorMessage } from 'util/form';
import classnames from 'classnames';
import { Chips } from 'primereact/chips';
import Info from './Info';

/**
 * Component that displays a basic text field in a form.
 * @component
 * @param param0 Prop object containing the following:
 *    ariaLabel: extra aria labels
 *    className: additional className
 *    control: react-hook-form control
 *    defaultValue: default value
 *    disabled: is input disabled
 *    fieldName: the field name in react-hook-form
 *    label: label for the input
 *    noPadding: removing padding
 *    placeholder: input placeholder text
 *    required: is field required
 *    info: info text next to header
 */
const ChipsField = function ({
  ariaLabel,
  className,
  control,
  defaultValue = [],
  disabled,
  fieldName,
  label,
  noPadding,
  placeholder,
  required = true,
  info
}: {
  ariaLabel?: string;
  className?: string;
  control: Control;
  defaultValue?: string[];
  disabled?: boolean;
  fieldName: string;
  label?: string;
  noPadding?: boolean;
  placeholder?: string;
  required?: boolean;
  info?: string;
}) {
  return (
    <div className={classnames(className, { field: !noPadding })}>
      <div className="flex">
        {label && (
          <label htmlFor={fieldName + 'input'} className="specto-text-medium">
            {label} {required && <span className="specto-form-asterisk-color">*</span>}
          </label>
        )}
        {info && <Info text={info} />}
      </div>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        rules={{
          ...(required && { required: 'Required field' })
        }}
        render={({ field, fieldState }) => (
          <>
            <span className="p-float-label">
              <Chips
                id={field.name}
                inputId={field.name + 'input'}
                {...field}
                ref={null}
                onChange={(e) => field.onChange(e.value)}
                separator=","
                allowDuplicate={false}
                disabled={disabled}
                className={classNames('specto-input-chips', {
                  'p-invalid': fieldState.error
                })}
                aria-label={ariaLabel}
                aria-invalid={fieldState?.error ? 'true' : 'false'}
                data-cy={field.name}
                placeholder={placeholder}
              />
            </span>
            {getFormErrorMessage(fieldState.error, noPadding)}
          </>
        )}
      />
    </div>
  );
};

export default ChipsField;
