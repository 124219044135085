import {
  AutocompleteRenderGetTagProps,
  Autocomplete,
  TextField,
  Checkbox,
  Chip,
  ClickAwayListener
} from '@mui/material';
import classNames from 'classnames';
import { SelectItemOptionsType } from 'primereact/selectitem';
import React from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Tooltip } from 'primereact/tooltip';
import { uniqueId } from 'util/uniqueIdGenerator';
import parse from 'html-react-parser';

/**
 * MultiAutocomplete for inline editing in a table
 *
 * @param disabled if the field is disabled
 * @param isHidden if the field should be hidden
 * @param isOptionEqualToValue if the option is equal to the value
 * @param itemTemplate the template for the item
 * @param onChange the change handler
 * @param onClickAway the click away handler
 * @param onClose the close handler
 * @param onOpen the open handler
 * @param open if the field is open
 * @param optionLabel the option label
 * @param optionValue the option value
 * @param options the options
 * @param placeholder the placeholder
 * @param readOnly if the field is read only
 * @param renderTags the render tags
 * @param tooltip the tooltip
 * @param value the value
 *
 * @component
 */
const MultiAutocompleteCell = function ({
  disabled,
  isHidden,
  isOptionEqualToValue,
  itemTemplate,
  onChange,
  onClickAway,
  onClose,
  onOpen,
  open,
  optionLabel,
  optionValue,
  options,
  placeholder,
  readOnly,
  renderTags,
  tooltip,
  value
}: {
  disabled?: boolean;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  isHidden?: (value: any) => boolean;
  itemTemplate?: (item: any) => JSX.Element;
  onChange?(e: any): void;
  onClickAway?: () => void;
  onClose?: () => void;
  onOpen?: () => void;
  open?: boolean;
  optionLabel?: string;
  optionValue?: string;
  options: SelectItemOptionsType;
  placeholder?: string;
  readOnly?: boolean;
  renderTags?: (value: any, getTagProps: AutocompleteRenderGetTagProps) => React.ReactNode;
  tooltip?: string;
  value: any[];
}) {
  const inputId = uniqueId('multi-autocomplete-input');
  const component = (
    <div>
      {tooltip && <Tooltip content={tooltip} target={`#${inputId}`} position="top" />}
      <Autocomplete
        className="w-full material-field"
        value={
          // The autocomplete wants the full option object but the controller only stores the value
          // so we need to find the option object that matches the value
          optionValue
            ? value?.map?.((val: any) => options?.find((item) => item[optionValue] === val) ?? val)
            : value
        }
        options={options}
        onChange={(_e, value) => {
          const val = optionValue ? value.map((item) => item[optionValue]) : value;
          onChange?.(val);
        }}
        renderInput={(params) => (
          <div id={inputId}>
            <TextField
              {...params}
              className={classNames(`specto-input-select autocomplete-input`)}
              placeholder={value.length > 0 ? '' : placeholder}
              aria-label={`Currently selected: ${value}`}
            />
          </div>
        )}
        renderOption={(props, option, { selected }) => {
          // Need to create list items with checkboxes
          if (optionLabel && typeof option === 'string') {
            return (
              <div
                key={uniqueId('autocomplete-option')}
                className="p-component autocomplete-subheader"
              >
                {parse(option)}
              </div>
            );
          } else {
            if (isHidden && isHidden(optionValue ? option[optionValue] : option)) {
              return undefined;
            } else {
              const label = optionLabel ? option[optionLabel] ?? option : option;
              return (
                <li
                  {...props}
                  key={uniqueId('autocomplete-option')}
                  className={classNames(props.className, 'material-option')}
                >
                  <Checkbox checked={selected} />
                  {itemTemplate ? itemTemplate(option) : label}
                </li>
              );
            }
          }
        }}
        renderTags={
          renderTags
            ? renderTags
            : (value, getTagProps) =>
                value.map((val, index) => {
                  return (
                    <Chip
                      {...getTagProps({ index })}
                      className="autocomplete-field-chips"
                      label={optionLabel ? val[optionLabel] : val}
                      deleteIcon={<CancelOutlinedIcon />}
                    />
                  );
                })
        }
        getOptionLabel={(option) => (optionLabel ? option[optionLabel] ?? option : option)}
        isOptionEqualToValue={
          isOptionEqualToValue
            ? isOptionEqualToValue
            : (option, value) =>
                optionValue ? option[optionValue] === value[optionValue] : option === value
        }
        disableClearable
        readOnly={readOnly}
        disabled={disabled}
        multiple
        disableCloseOnSelect
        popupIcon={<ExpandMoreIcon />}
        onOpen={onOpen}
        onClose={onClose}
        open={open}
      />
    </div>
  );

  // need to wrap the component in a click away listener if we want to handle click away
  return onClickAway ? (
    <ClickAwayListener onClickAway={onClickAway}>{component}</ClickAwayListener>
  ) : (
    component
  );
};

export default MultiAutocompleteCell;
