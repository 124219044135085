import { combineReducers, ReducersMapObject } from 'redux';
import authReducer from 'features/auth/authSlice';
import layoutReducer from 'features/layout/layoutSlice';
import projectsReducer from 'features/projects/projectsSlice';
import toastReducer from 'features/toast/toastSlice';
import versionsReducer from 'features/versions/versionsSlice';
import conversationsReducer from 'features/conversations/conversationsSlice';
import widgetReducer from 'features/widget/widgetSlice';
import widgetHistoryReducer from 'features/widget/widgetHistorySlice';

function makeRootReducer(asyncReducers?: ReducersMapObject) {
  return combineReducers({
    auth: authReducer,
    conversations: conversationsReducer,
    layout: layoutReducer,
    projects: projectsReducer,
    toast: toastReducer,
    versions: versionsReducer,
    widget: widgetReducer,
    widgetHistory: widgetHistoryReducer,
    ...asyncReducers
  });
}

// another layer to listen to each action and reset state on logout
export const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/logoutUser') {
    state = undefined;
  } else if (action.type === 'cypress/replaceState') {
    state = action.payload;
  }
  return makeRootReducer()(state, action);
};

export default rootReducer;
