import React, { useEffect, useState } from 'react';
import { Chips, ChipsRemovableOptions, ChipsRemoveEvent } from 'primereact/chips';
import classnames from 'classnames';
import { FilterChip } from 'models/filter-chips';

/**
 * Dynamic chips with built-in functions
 * @param ariaLabel aria label
 * @param className custom classname
 * @param deps dependency array that the "enabled" members rely on for all chips
 * @param filterChips FilterChip array
 * @constructor
 */
export const FilterChips = ({
  ariaLabel = 'filter chips',
  className,
  deps = [],
  filterChips
}: {
  ariaLabel?: string;
  className?: string;
  deps?: any[];
  filterChips: FilterChip[];
}) => {
  const [chipValue, setChipValue] = useState<string[]>([]);

  useEffect(() => {
    setChipValue(filterChips.filter((chip) => chip.enabled).map((chip) => chip.name));
  }, [...deps]);

  useEffect(() => {
    const spectoChipsElement: HTMLElement | null =
      document.querySelector<HTMLElement>('.specto-filter-chips');

    const inputElement = spectoChipsElement?.querySelector('input');
    if (inputElement) {
      inputElement.disabled = true;
    }
  }, []);

  const removeChips = (e: ChipsRemoveEvent) => {
    const originalChip = filterChips.find((chip) => chip.name === e.value[0]);
    originalChip?.removeFn();
  };

  /**
   * Determine if a chip is removable
   * @param e chip to be removed
   * @returns boolean
   */
  const removable = (e: ChipsRemovableOptions) => {
    const originalChip = filterChips.find((chip) => chip.name === e.value);
    return originalChip?.removable ?? true;
  };

  return (
    <Chips
      className={classnames('specto-filter-chips', className)}
      value={chipValue}
      aria-label={ariaLabel}
      removeIcon="pi pi-times text-xs font-bold"
      keyfilter={/[*]/}
      onRemove={removeChips}
      removable={removable}
      pt={{ input: { 'aria-label': 'Active Filters' } }}
    />
  );
};
