import React from 'react';
// components
import { RichTextForm } from 'components/Table';
// context
import { ResponseFormContext } from './ResponseFormContext';
// utils
import { getFormErrorMessage } from 'util/form';

/**
 * Component that formats and displays the response rich text field in a form
 * @component
 * @param componentIndex index of this component in the component list
 */
const ResponseCustomAction = function ({ componentIndex }: { componentIndex: number }) {
  const {
    formState: { errors },
    control,
    setValue,
    getValues
  } = React.useContext(ResponseFormContext);

  const baseField = `content.${componentIndex}`;
  const contentField = `${baseField}.content.text`;
  const contentValueField = `${baseField}.contentValue.text`;

  return (
    <div className="w-full">
      <RichTextForm
        control={control}
        setValue={setValue}
        defaultValue={getValues(contentField)}
        controlRules={{ required: 'required' }}
        controlName={contentValueField}
        minHeight="20vh"
      />
      {getFormErrorMessage(errors.richTextResponse)}
    </div>
  );
};

export default ResponseCustomAction;
