import { Button } from 'primereact/button';
import React from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Control, FieldValues, UseFormGetValues } from 'react-hook-form';
import { Paper, Popover } from '@mui/material';
import { uniqueId } from 'util/uniqueIdGenerator';
import DatePickerField from 'components/Forms/DatePickerField';
import classNames from 'classnames';
import { onDateChange } from 'util/calendar';
import { humanReadableDateFormatted24 } from 'util/dates';
import { DateTime } from 'luxon';

/**
 *
 * @param control form Control
 * @param onFormValueChange handles changes when picking dates
 * @param getFilterValues function to fetch filter values by field
 * @component
 */

const ConversationDatePicker = ({
  control,
  onFormValueChange,
  getFilterValues
}: {
  control: Control;
  onFormValueChange(data: FieldValues): void;
  getFilterValues: UseFormGetValues<FieldValues>;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const buttonId = uniqueId('menu-btn');
  const menuId = uniqueId('menu');
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 1000 }}
      >
        <Paper className="p-3 w-30rem" tabIndex={0}>
          <form
            className={classNames('flex flex-wrap overflow-y-auto gap-3')}
            onSubmit={(e) => e.preventDefault()}
            style={{ maxHeight: '70vh' }}
          >
            <DatePickerField
              control={control}
              label="Start Date:"
              placeholder="Select a date"
              fieldName="timestamp_gte"
              className="w-12 specto-border border-round border-1 p-3"
              onChange={(e) => onDateChange(e, onFormValueChange, 'timestamp_', true, 'gte')}
              maxDate={DateTime.fromJSDate(getFilterValues('timestamp_lte'))}
              readOnly
            />
            <DatePickerField
              control={control}
              label="End Date:"
              placeholder="Select a date"
              fieldName="timestamp_lte"
              className="w-12 specto-border border-round border-1 p-3"
              onChange={(e) => onDateChange(e, onFormValueChange, 'timestamp_', true)}
              minDate={DateTime.fromJSDate(getFilterValues('timestamp_gte'))}
              readOnly
            />
          </form>
        </Paper>
      </Popover>
      <Button
        disabled={Boolean(getFilterValues('conversation'))}
        id={buttonId}
        label={`${humanReadableDateFormatted24(
          getFilterValues('timestamp_gte')
        )} — ${humanReadableDateFormatted24(getFilterValues('timestamp_lte'))}`}
        icon={<CalendarTodayIcon htmlColor="#141414" fontSize="small" />}
        iconPos="right"
        className="date-picker-btn bg-white border-1 text-900 font-light mr-3 flex flex-row-reverse gap-1"
        onClick={handleClick}
        tooltip={
          getFilterValues('conversation')
            ? 'Remove Conversation ID filter to enable filtering by dates.'
            : ''
        }
        tooltipOptions={{
          showOnDisabled: Boolean(getFilterValues('conversation')),
          position: 'bottom'
        }}
      />
    </>
  );
};

export default ConversationDatePicker;
