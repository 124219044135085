import React, { useContext } from 'react';
import { ResponseFormContext } from './ResponseFormContext';
import { TextField, ToggleSwitch } from 'components/Forms';
import ResponseComponentContainer from './ResponseComponentContainer';

/**
 * Component that displays dynamic fields to define a "List Group"
 * @component
 */
const ResponseCard = function ({ componentIndex }: { componentIndex: number }) {
  const baseField = `content.${componentIndex}`;
  const contentField = `${baseField}.content`;
  const { control } = useContext(ResponseFormContext);

  return (
    <ResponseComponentContainer
      componentIndex={componentIndex}
      className="specto-response-list-group-container"
      title="Card"
      key={componentIndex}
    >
      <div className="specto-response-list-group w-full">
        <div className="grid mb-2">
          <div className="col-12 flex align-items-center">
            <ToggleSwitch
              fieldName={`${contentField}.target`}
              label="Open link in"
              className="mb-0"
              control={control}
              trueLabel="New Tab"
              falseLabel="Same Tab"
              trueValue="_blank"
              falseValue="_self"
              inlineLabel
              info="If this option is enabled, links will open in a new tab. Otherwise, links will open in the same tab."
            />
          </div>
          <div className="col-1 sm:col-2" />
        </div>

        <div className="w-full">
          <TextField
            label="Primary Text"
            className="pb-3"
            noPadding
            fieldName={`${contentField}.primary_text`}
            control={control}
            info="The primary text of the card"
          />
          <TextField
            label="Secondary Text"
            className="mb-3"
            noPadding
            fieldName={`${contentField}.secondary_text`}
            control={control}
            info="The secondary text of the card"
          />
          <TextField
            label="Image URL"
            className="mb-3"
            noPadding
            fieldName={`${contentField}.image`}
            control={control}
            info="The URL of the image to display"
          />
          <TextField
            label="Image Alt Text"
            className="mb-3"
            noPadding
            fieldName={`${contentField}.alt_text`}
            control={control}
            info="This is descriptive text that conveys the meaning and context of an image. It's used for screen readers or if the image cannot be rendered."
          />
          <TextField
            label="Link URL"
            className="mb-3"
            noPadding
            fieldName={`${contentField}.payload`}
            control={control}
            info="The URL that will be opened if the card is clicked"
          />
        </div>
      </div>
    </ResponseComponentContainer>
  );
};

export default ResponseCard;
