import { AutocompleteChangeReason, AutocompleteChangeDetails, Chip } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { addUserGroupState } from 'util/userGroups';
import MultiAutocompleteField from '../Forms/MultiAutocompleteField';
import { Control } from 'react-hook-form';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { UserGroupModel, UserGroupState, UserGroupOptionModel } from 'models/user-groups-model';
import { ProjectUserModel } from 'models/project-model';
import { isAdmin } from 'util/permissions';
import { Tooltip } from 'primereact/tooltip';
import { uniqueId } from 'lodash';
import { FormMode } from 'models/form-model';

/**
 * Field for selecting user groups.
 *
 * @param control control from react-hook-form
 * @param mode Form mode: Create, Edit, Delete.
 * @param userGroups user groups to display
 * @param projectUser currently logged in user's project user
 * @returns
 */
const UserGroupsField = function ({
  control,
  mode,
  userGroups,
  projectUser
}: {
  control: Control;
  mode: FormMode;
  userGroups: UserGroupModel[];
  projectUser: ProjectUserModel;
}) {
  const isOptionAvailable = (option: UserGroupOptionModel) =>
    isAdmin(projectUser?.role) || projectUser?.user_groups.includes(option.id);
  return (
    <MultiAutocompleteField
      fieldName="user_groups"
      label="User Groups"
      required={false}
      control={control}
      options={[
        ...addUserGroupState(userGroups),
        { id: -1, name: 'removed', state: UserGroupState.UNCHANGED }
      ]}
      optionLabel="name"
      placeholder="Select User Groups or start typing"
      info="User groups that are associated with this topic"
      renderTags={(value: UserGroupOptionModel[], getTagProps) =>
        value.map((val, index) => {
          const tagProps = getTagProps({ index });
          const chipId = uniqueId('user-group-chip');
          return (
            <div key={chipId}>
              <Chip
                {...tagProps}
                id={chipId}
                className={classNames({
                  'autocomplete-field-chips': val.state === UserGroupState.UNCHANGED,
                  'autocomplete-field-chips--added': val.state === UserGroupState.ADDED,
                  'autocomplete-field-chips--removed': val.state === UserGroupState.REMOVED
                })}
                label={val.name}
                deleteIcon={isOptionAvailable(val) ? <CancelOutlinedIcon /> : undefined}
                onDelete={isOptionAvailable(val) ? tagProps.onDelete : undefined}
              />
              {val.state !== UserGroupState.UNCHANGED ? (
                <Tooltip
                  baseZIndex={10001}
                  target={`#${chipId}`}
                  position="bottom"
                  content={
                    val.state === UserGroupState.ADDED
                      ? 'This user group will be added once this item has been published.'
                      : 'This user group will be removed once this item has been published.'
                  }
                />
              ) : undefined}
            </div>
          );
        })
      }
      isOptionEqualToValue={(option, value) =>
        (option.id === value.id && value.state !== UserGroupState.REMOVED) ||
        (option.id === -1 && value.state === UserGroupState.REMOVED)
      }
      handleValueChanged={(
        value: UserGroupOptionModel[],
        _newValue: UserGroupOptionModel[],
        reason: AutocompleteChangeReason,
        details: AutocompleteChangeDetails<UserGroupOptionModel> | undefined
      ) => {
        const userGroups = [...value];
        if (details) {
          if (reason === 'selectOption') {
            const group = userGroups.findIndex((group) => group.id === details.option.id);
            if (group === -1) {
              userGroups.push({
                ...details.option,
                state: mode === FormMode.EDIT ? UserGroupState.ADDED : UserGroupState.UNCHANGED
              });
            } else {
              userGroups[group].state =
                userGroups[group].state === UserGroupState.ADDED
                  ? UserGroupState.ADDED
                  : UserGroupState.UNCHANGED;
            }
          } else if (reason === 'removeOption') {
            const group = userGroups.findIndex((group) => group.id === details.option.id);
            if (group !== -1) {
              if (mode !== FormMode.EDIT || userGroups[group].state === UserGroupState.ADDED) {
                userGroups.splice(group, 1);
              } else {
                userGroups[group].state = UserGroupState.REMOVED;
              }
            }
          }
        }
        return userGroups;
      }}
      isHidden={(value: UserGroupOptionModel) => value.id === -1 || !isOptionAvailable(value)}
    />
  );
};

export default UserGroupsField;
