// API URL
export const LOCAL_URL = 'http://127.0.0.1:18000/';
export const SNOW_WHITE_DEV_URL = 'http://192.168.101.10:18001/';
export const SNOW_WHITE_STAGE_URL = 'http://192.168.101.10:18000/';
export const SNOW_WHITE_SCRUM_A_URL = 'http://192.168.101.10:18002/';
export const SNOW_WHITE_SCRUM_B_URL = 'http://192.168.101.10:18003/';
export const PROD_URL = 'https://askmastudio.mass.gov/api';

export const API_URL = PROD_URL;

// General
export const GENERIC_ERROR_MESSAGE = 'An error has occurred.\nPlease try again later.';

// AUTH
export const AUTH_API_URL = '/auth/';
export const LOGIN_API_URL = AUTH_API_URL + 'login/';
export const MS_LOGIN_URL = LOGIN_API_URL + 'microsoft/';
export const LOGOUT_API_URL = AUTH_API_URL + 'logout/';

// CONVERSATIONS
export const CONVERSATIONS_API_URL = '/convos/';
export const CONVERSATION_TAGS_API_URL = '/tags/';
export const CONVERSATION_DELETE_MESSAGE_API_URL = '/del-msg/';

// PROJS
export const PROJECTS_API_URL = '/projs/';
export const PROJECT_MEMBERS_API_URL = '/members/';
export const PROJECT_EVENTS_DATABASE_API_URL = 'events-database/';
export const PROJECT_EVENTS_DATABASE_SYNC_API_URL = 'events-database/sync/';
export const PROJECT_RESPONSE_DATABASE_URL = '/response-database/';

// LANGUAGE
export const LANGUAGES_API_URL = '/languages/';

// TOKEN
export const refresh_API_URL = '/auth/token/refresh/';

// TAGS
export const TAGS_API_URL = '/tags/';

// USERS
export const USERS_API_URL = '/users/';

// INTENTS
export const INTENTS_API_URL = '/intents/';

// SENTIMENTS
export const SENTIMENTS_API_URL = '/sentiments/';

export const MESSAGE_INBOX_API_URL = '/messages/';

// RESPONSES
export const RESPONSES_API_URL = '/responses/';

// RESPONSE TYPES
export const RESPONSETYPES_API_URL = '/responsetypes/';

// CATEGORIES
export const CATEGORIES_API_URL = '/categories/';

// STATUSES
export const STATUSES_API_URL = '/statuses/';

// NLU
export const NLU_API_URL = '/nlus/';

// VERSIONS
export const VERSIONS_API_URL = '/versions/';

// COMMENTS
export const COMMENTS_API_URL = '/comments/';

// ENTITIES
export const ENTITIES_API_URL = '/entities/';

// SYNONYMS
export const SYNONYMS_API_URL = '/synonyms/';

// REGEX
export const REGEX_API_URL = '/regex/';

// ENTITY GROUPS
export const ENTITY_GROUPS_API_URL = '/entity-groups/';

// LOOKUP TABLES
export const LOOKUP_TABLES_API_URL = '/lookuptables/';

// TOPICS
export const TOPICS_API_URL = '/topics/';

// METADATA FILTERS
export const METADATA_FILTERS_API_URL = '/metadatafilters/';

// RETIRING
export const RETIRE_API_URL = '/retired/';

// PUBLISHING
export const PUBLISH_API_URL = '/publish/';
export const APPROVE_API_URL = '/approve/';

// FEEDBACK
export const FEEDBACK_API_URL = '/feedback/';

// EXPORT
export const EXPORT_API_URL = 'export/';

// USER GROUPS
export const USER_GROUPS_API_URL = '/usergroups/';

// NOTIFICATIONS
export const NOTIFICATIONS_API_URL = '/notifications/';

// ANALYTICS
export const ANALYTICS_API_URL = '/analytics/';

// EVENTS
export const EVENTS_CONVERSATIONS_API_URL = '/events/conversations/';
