import ApiService from 'services/apiService';
import { TopicTableAPIModel, TopicTableDataModel } from 'models/topic-model';
import { APPROVE_API_URL, PUBLISH_API_URL, RETIRE_API_URL, TOPICS_API_URL } from 'constants/api';
import {
  RetiredOptionsModel,
  SlugDataIdOptionsModel,
  SlugDataOptionsModel,
  SlugIdOptionsModel,
  SlugQueryOptionsModel
} from 'models/api-model';
import { NON_GUEST_ROLES } from 'constants/roles';
import { Permissions } from 'models/permission-model';
import { ProjectRole } from 'models/project-model';
import { PaginationModel } from 'models/filter-model';

const getTopics = (options: SlugQueryOptionsModel) =>
  ApiService.get<TopicTableDataModel[]>(
    '/' + options.slug + TOPICS_API_URL + (options.query ?? '')
  );

const getPaginatedTopics = (options: SlugQueryOptionsModel) =>
  ApiService.get<PaginationModel<TopicTableDataModel>>(
    '/' + options.slug + TOPICS_API_URL + (options.query ?? '')
  );

const getTopic = (options: SlugIdOptionsModel) =>
  ApiService.get<TopicTableDataModel>('/' + options.slug + TOPICS_API_URL + options.id + '/');

const createTopic = (options: SlugDataOptionsModel<TopicTableAPIModel>) =>
  ApiService.post<TopicTableDataModel, TopicTableAPIModel>(
    '/' + options.slug + TOPICS_API_URL,
    options.data
  );

const deleteTopic = (options: SlugIdOptionsModel) =>
  ApiService.remove<TopicTableDataModel>('/' + options.slug + TOPICS_API_URL + options.id + '/');

const editTopic = (options: SlugDataIdOptionsModel<TopicTableAPIModel>) =>
  ApiService.put<TopicTableDataModel>(
    '/' + options.slug + TOPICS_API_URL + options.id + '/',
    options.data
  );

const retireTopic = (options: RetiredOptionsModel) =>
  ApiService.put<TopicTableDataModel>(
    '/' + options.slug + TOPICS_API_URL + options.id + RETIRE_API_URL,
    options.retired
  );

const publishTopic = (options: SlugIdOptionsModel) =>
  ApiService.post<TopicTableDataModel>(
    '/' + options.slug + TOPICS_API_URL + options.id + PUBLISH_API_URL
  );

const approveTopic = (options: SlugIdOptionsModel) =>
  ApiService.post<TopicTableDataModel>(
    '/' + options.slug + TOPICS_API_URL + options.id + APPROVE_API_URL
  );

const topicsServiceRoles: Permissions = {
  list: true,
  retrieve: true,
  create: NON_GUEST_ROLES,
  update: NON_GUEST_ROLES,
  delete: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN]
};

const TopicsService = {
  approveTopic,
  createTopic,
  deleteTopic,
  editTopic,
  getPaginatedTopics,
  getTopic,
  getTopics,
  publishTopic,
  retireTopic,
  roles: topicsServiceRoles
};

export default TopicsService;
