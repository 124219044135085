import React, { useEffect } from 'react';
import { useAppDispatch } from 'hooks/store';
import { Navigate, Outlet } from 'react-router-dom';
import { createToast } from 'features/toast/toastSlice';
import { guardWarning } from 'constants/warnings';
import { routes } from 'constants/routes';
import { usePermission } from 'hooks/usePermission';
import { PermissionRoles } from 'models/permission-model';

type GuardedRouteProps = {
  redirectPath?: string;
  children?: React.ReactNode;
  roles?: PermissionRoles;
};

/**
 * Can be guard multiple routes as an outlet
 * <Route element={<AuthGuardedRoute/>}> {... your routes } </Route>
 *
 * Can be used for single routes
 * <Route element={<AuthGuardedRoute><YourComponent></AuthGuardedRoute>}/>
 *
 * @param guards array of guards that are conditions to access this route
 * @param redirectPath path to redirect to
 * @param children child component when guarding a single route
 */
export const PermissionGuardedRoute = ({
  redirectPath = routes.PROJECT_HOME,
  children,
  roles
}: GuardedRouteProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const permission = usePermission(roles);

  // create toast message on failed guard
  useEffect(() => {
    if (!permission) {
      dispatch(createToast(guardWarning.permission, 'warn'));
    }
  }, [permission]);

  return permission ? <>{children || <Outlet />}</> : <Navigate to={redirectPath} replace />;
};
