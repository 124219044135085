import React from 'react';
import { MessageInboxMetadataModel } from 'models/message-inbox-table-data-model';
import { Tooltip } from 'primereact/tooltip';
import yaml from 'js-yaml';
import parse from 'html-react-parser';
import { TooltipOptions } from 'primereact/tooltip/tooltipoptions';

type RoundMetadataIconOptionsModel = {
  metadata?: MessageInboxMetadataModel;
  id: number;
  className?: string;
  hidden?: boolean;
  position?: TooltipOptions['position'];
};
/**
 * Round metadata icon component
 * @param metadata metadata of the message
 * @param id  id of the message
 * @param className class name of the message
 * @param hidden  show/hide the component
 * @param position  position of the tooltip
 * @constructor
 */
const MetadataIcon = ({
  metadata,
  id,
  className,
  hidden = false,
  position = 'bottom'
}: RoundMetadataIconOptionsModel) =>
  hidden || !metadata ? (
    <></>
  ) : (
    <div className={className}>
      <i
        id={'specto-metadata-tooltip-' + id}
        style={{ cursor: 'default' }}
        className="pi pi-info-circle p-button p-button-rounded p-button-text specto-r-button-lg"
      />
      <Tooltip target={`#specto-metadata-tooltip-${id}`} position={position}>
        <pre style={{ fontFamily: 'Trebuchet MS' }}>
          {parse(
            yaml
              .dump(metadata)
              .replace(/(\w+):/g, '<b>$1: </b>')
              .replace(/ {2}/g, '\t')
              .replace(/'{2}/g, "' '")
          )}
        </pre>
      </Tooltip>
    </div>
  );

export default MetadataIcon;
