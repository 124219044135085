import { UserDataReturnModel } from './user-model';
import { Status } from './status-model';
import { UserGroupModel } from './user-groups-model';
import { TopicTableDataModel } from './topic-model';

// data in the history array in a response
export interface ResponseHistoryModel {
  id: number;
  last_modified: string;
  user: UserDataReturnModel;
  name: string;
  status: Status;
  feedback_icons: boolean;
  content: ResponseGenericModel[];
  tags: number[];
  user_groups: UserGroupModel['id'][];
  author: number;
}

// full response data returned from server
export interface ResponseTableDataModel extends ResponseHistoryModel {
  id: number;
  history: ResponseHistoryModel[];
  topics: TopicTableDataModel['id'][];
  comment_thread_resolved: boolean | null;
  reference?: ResponseHistoryModel;
}

// data needed to create a response
export interface ResponseTableAPIModel {
  name: string;
  status: Status;
  feedback_icons: boolean;
  content: ResponseGenericModel[];
  tags: number[];
  user_groups: UserGroupModel['id'][];
  author: number;
}

export enum ResponseTypeOptions {
  BROWSING_CAROUSEL = 'BC',
  BUTTONS = 'BT',
  CARD = 'CD',
  CAROUSEL = 'CR',
  CUSTOM_ACTION = 'CA',
  CUSTOM_FORM = 'CF',
  DROPDOWN = 'DD',
  GOOGLE_FORM = 'GF',
  IMAGE = 'IM',
  LIST_GROUP = 'LG',
  LIVE_CHAT = 'LC',
  MULTISELECT = 'MS',
  OPTIONS_LIST = 'OL',
  PDF = 'PD',
  TEXT = 'RT',
  TABLE = 'TB',
  VIDEO = 'VI',
  VIDEO_PLAYLIST = 'VP',
  YOUTUBE_PLAYLIST = 'YP'
}

export const ResponseTypeNames: Record<ResponseTypeOptions, string> = {
  [ResponseTypeOptions.BROWSING_CAROUSEL]: 'Browsing Carousel',
  [ResponseTypeOptions.BUTTONS]: 'Buttons',
  [ResponseTypeOptions.CARD]: 'Card',
  [ResponseTypeOptions.CAROUSEL]: 'Carousel',
  [ResponseTypeOptions.CUSTOM_ACTION]: 'Custom Action',
  [ResponseTypeOptions.CUSTOM_FORM]: 'Custom Form',
  [ResponseTypeOptions.DROPDOWN]: 'Dropdown',
  [ResponseTypeOptions.GOOGLE_FORM]: 'Google Form',
  [ResponseTypeOptions.IMAGE]: 'Image',
  [ResponseTypeOptions.LIST_GROUP]: 'List Group',
  [ResponseTypeOptions.LIVE_CHAT]: 'Live Chat',
  [ResponseTypeOptions.MULTISELECT]: 'Multiselect',
  [ResponseTypeOptions.OPTIONS_LIST]: 'Options List',
  [ResponseTypeOptions.PDF]: 'PDF',
  [ResponseTypeOptions.TEXT]: 'Text',
  [ResponseTypeOptions.TABLE]: 'Table',
  [ResponseTypeOptions.VIDEO]: 'Video',
  [ResponseTypeOptions.VIDEO_PLAYLIST]: 'Video Playlist',
  [ResponseTypeOptions.YOUTUBE_PLAYLIST]: 'YouTube Playlist'
};

export type ResponseGenericModel<T = any> = {
  id: string;
  type: ResponseTypeOptions;
  content: T;
};

export type ResponseGenericURLModel = {
  url: string;
};

export type ResponseRichTextContent = {
  text: string;
};

export type ResponseCustomActionContent = {
  text: string;
};

export type ResponseButtonContent = {
  id: string;
  label: string;
  payload: number;
};

export type ResponseDropdownContent = {
  placeholder: string;
  elements: [
    {
      id: string;
      label: string;
      payload: number;
    }
  ];
};

export type ResponseMultiSelectContent = {
  placeholder: string;
  elements: [
    {
      id: string;
      label: string;
    }
  ];
};

export type ResponseImageContent = {
  url: string;
  alt_text: string;
};

export type ResponseCardContent = {
  id: string;
  primary_text: string;
  secondary_text: string;
  image: string;
  payload: string;
  target: string;
};

export type ResponseListGroupContent = {
  title: string;
  elements: [
    {
      id: string;
      primary_text: string;
      secondary_text: string;
      image: string;
      payload: string;
      target: string;
    }
  ];
};

export type ResponseCarouselContent = [
  {
    id: string;
    primary_text: string;
    secondary_text: string;
    image: string;
  }
];

export type ResponseBrowsingCarouselContent = [
  {
    id: string;
    primary_text: string;
    secondary_text: string;
    image: string;
    payload: string;
    payload_label: string;
    target: string;
  }
];

export type ResponseCustomVideoPlaylistContent = [
  {
    id: string;
    url: string;
  }
];

export type ResponseTableContent = {
  name: string;
  description: string;
  value: string;
};

export type ResponseLiveChatContent = {
  service: ResponseLiveChatService;
  host: string;
  tenant_name?: string;
};

export enum ResponseLiveChatService {
  Five9 = 'Five9',
  ServiceNow = 'ServiceNow'
}

export type ExportResponseDataType = {
  statuses: string[];
  replicate_production: boolean;
  fileFormat: 'json' | 'yaml';
};
