import React, { useContext, useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { Button } from 'primereact/button';
import { DropdownField } from 'components/Forms';
import { MessageInboxTableDataModel } from 'models/message-inbox-table-data-model';
import Popup from 'containers/Popup';
import { EntityFieldArrayValues, EntityFormContext } from './EntityContextModel';
import EntityLabeler from './EntityLabeler';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { EntityTableDataModel } from 'models/entity-model';
import { Tooltip } from 'primereact/tooltip';
import { EntityLabelRange } from './entity-labelling-model';
import classnames from 'classnames';
import { useAppSelector } from 'hooks/store';
import { entityGroupsSelector } from 'features/projects/projectsSlice';
import { filterRetired } from 'util/status';

type EntityModalProps = {
  node: MessageInboxTableDataModel | null;
  control: Control;
  visible: boolean;
  loading?: boolean;
  allEntities: EntityTableDataModel[];
  synonymRecord: Record<string, string>;
  onHide(): any;
  onSave(): any;
};

const EntityModal = function ({
  node,
  onHide,
  onSave,
  visible,
  allEntities,
  synonymRecord,
  loading
}: EntityModalProps) {
  const baseFieldName = 'entities';
  const { control, removeEntity, entityFields, getValues, setValue } =
    useContext(EntityFormContext);
  const [focusedFieldIndex, setFocusedFieldIndex] = useState<number | null>(0);
  const [focusedEntityRange, setFocusedEntityRange] = useState<EntityLabelRange | null>(null);
  const NoneValue = null;
  const NONE_OPTION = { id: NoneValue, name: 'NONE' };
  const allGroups = useAppSelector(entityGroupsSelector);

  // when a new Entity is added to the array field, focus it
  useEffect(() => {
    entityFields.length > 0 ? focusEntityField(entityFields.length - 1) : clearEntityFocus();
  }, [entityFields.length]);

  // clear entity focus on close/open
  useEffect(clearEntityFocus, [visible]);

  function clearEntityFocus() {
    setFocusedEntityRange(null);
    setFocusedFieldIndex(null);
  }

  const focusEntityField = (index: number) => {
    const field: Record<keyof EntityFieldArrayValues, any> = entityFields[index];

    if (field.start === undefined || field.end === undefined) {
      return;
    }

    setFocusedFieldIndex(index);
    setFocusedEntityRange([field.start, field.end]);
  };

  return (
    <Popup
      title="Labeled Entities"
      subtitle="Highlight a word or multiple words to annotate an Entity."
      className="specto-entity-modal md:w-10 lg:w-10 xl:w-10"
      visible={visible}
      onHide={onHide}
      onSave={onSave}
      loading={loading}
    >
      <form className="specto-entity-modal-content">
        <h5>Entity Labeler</h5>
        <EntityLabeler
          text={node?.text ?? ''}
          allEntities={allEntities}
          focusEntityRange={focusedEntityRange}
        />

        <h5 className="mt-4 mb-3">Labeled Entities</h5>

        {entityRowHeaders}

        {entityFields.length < 1 && dummyEntityField}

        {entityFields.map((field: Record<keyof EntityFieldArrayValues, any>, index: number) => (
          <div
            key={field.id}
            className={classnames(
              'w-full one-row grid py-2 px-4 border-solid border-round',
              index === focusedFieldIndex ? 'specto-border' : 'border-transparent',
              index === 0 && '-mt-2'
            )}
            onClick={() => focusEntityField(index)}
          >
            <div className="flex flex-wrap align-content-center justify-content-center w-max -ml-6 mr-3">
              <div
                id={'specto-entity-conflict-row-' + index}
                className="w-1rem h-1rem border-circle specto-error-background"
                style={{
                  visibility: entityFields.some(
                    (entity, j) =>
                      index !== j &&
                      ((field.start <= entity.start && field.end > entity.start) ||
                        (field.start > entity.start && field.start < entity.end))
                  )
                    ? 'visible'
                    : 'hidden'
                }}
              ></div>
            </div>
            <Tooltip target={'#specto-entity-conflict-row-' + index}>
              The range of this entity overlaps with another entity
            </Tooltip>

            <div className="col specto-entity-modal-word">
              <InputText
                className="specto-input-text pointer-events-none"
                disabled
                value={node?.text.substring(field.start, field.end)}
              />
            </div>

            <div className="col specto-entity-modal-synonym">
              <InputText
                className="specto-input-text"
                disabled
                value={
                  synonymRecord[node?.text.substring(field.start, field.end).toLowerCase() ?? '']
                }
              />
            </div>

            <div className="col specto-entity-modal-type">
              <DropdownField
                fieldName={`${baseFieldName}.${index}.entity`}
                filter
                required={true}
                defaultValue={NoneValue}
                placeholder="NONE"
                control={control}
                optionValue="id"
                optionLabel="name"
                options={[
                  NONE_OPTION,
                  ...filterRetired(allEntities, node?.entity_labels?.[index]?.entity)
                ]}
                appendTo={null}
                noPadding
                onChange={() => {
                  // Need to clear the role and group when the entity changes
                  // because not all entities have the same role and group options
                  setValue(`${baseFieldName}.${index}.role`, null);
                  setValue(`${baseFieldName}.${index}.group`, null);
                }}
              />
            </div>

            <div className="col specto-entity-modal-role">
              <DropdownField
                fieldName={`${baseFieldName}.${index}.role`}
                defaultValue={NoneValue}
                placeholder="NONE"
                filter
                required={false}
                control={control}
                optionValue="id"
                optionLabel="name"
                options={[
                  NONE_OPTION,
                  ...(
                    allEntities.find(
                      (entity) => entity.id === getValues(`${baseFieldName}.${index}.entity`)
                    )?.roles ?? []
                  ).map((option) => ({ name: option, id: option }))
                ]}
                appendTo={null}
                noPadding
              />
            </div>

            <div className="col specto-entity-modal-group">
              <DropdownField
                fieldName={`${baseFieldName}.${index}.group`}
                defaultValue={NoneValue}
                filter
                placeholder="NONE"
                required={false}
                control={control}
                optionValue="id"
                optionLabel="name"
                options={[
                  NONE_OPTION,
                  ...allGroups.filter((group) =>
                    group.entities.includes(getValues(`${baseFieldName}.${index}.entity`))
                  )
                ]}
                appendTo={null}
                noPadding
              />
            </div>

            <div className="flex flex-wrap align-content-center justify-content-center w-max pl-3 -mr-1">
              <Button
                icon="pi pi-trash"
                className="p-button-rounded specto-r-button-lg"
                onClick={() => removeEntity(index)}
                type="button"
                aria-label="delete entity"
              />
            </div>
          </div>
        ))}
      </form>
    </Popup>
  );
};

const entityRowHeaders = (
  <div className="w-full one-row grid px-3">
    <div className="col">
      <h6>Word/Phrase</h6>
    </div>

    <div className="col">
      <h6>Synonym</h6>
    </div>

    <div className="col">
      <h6>Type</h6>
    </div>

    <div className="col">
      <h6>Role</h6>
    </div>

    <div className="col">
      <h6>Group</h6>
    </div>

    <div className="flex flex-wrap align-content-center justify-content-center w-max pl-3 -mr-1 pt-4">
      <div style={{ width: 40 }}></div>
    </div>
  </div>
);

const dummyEntityField = (
  <div className="w-full one-row grid -mt-2 py-2 px-4">
    <div className="flex flex-wrap align-content-center justify-content-center w-max -ml-6 mr-3">
      <div className="w-1rem h-1rem border-circle"></div>
    </div>

    <div className="col">
      <InputText className="specto-input-text" disabled />
    </div>

    <div className="col">
      <InputText className="specto-input-text" disabled />
    </div>

    <div className="col">
      <Dropdown className="specto-input-select" disabled />
    </div>

    <div className="col">
      <Dropdown className="specto-input-select" disabled />
    </div>

    <div className="col">
      <Dropdown className="specto-input-select" disabled />
    </div>

    <div className="flex flex-wrap align-content-center justify-content-center w-max pl-3 -mr-1">
      <Button
        icon="pi pi-trash"
        disabled
        className="p-button-rounded specto-r-button-lg"
        type="button"
      />
    </div>
  </div>
);

export default EntityModal;
