export interface UserGroupAPIModel {
  name: string;
}

export interface UserGroupModel extends UserGroupAPIModel {
  id: number;
  num_projects: number;
}

export interface UserGroupItemModel {
  id: number;
  state: UserGroupState;
}

export interface UserGroupOptionModel extends UserGroupModel, UserGroupItemModel {}

export enum UserGroupState {
  UNCHANGED,
  REMOVED,
  ADDED
}
