import React from 'react';
import { ProjectMemberUserModel } from 'models/project-model';
import HistoryPopup from 'components/History/HistoryPopup';
import { Chip } from 'primereact/chip';
import _ from 'lodash';
import { utcToLocalDateFormatted } from 'util/dates';
import { statusDisplayNames } from 'constants/status';
import { mapIdToName } from 'util/mapIdAndName';
import { CategoryModel } from 'models/category-model';
import { UserGroupModel } from 'models/user-groups-model';
import { IntentTableDataModel } from 'models/intent-table-data-model';
import classNames from 'classnames';
import { HistoryStepRightArrow } from 'components/History/HistoryStepper';
import { uniqueId } from 'util/uniqueIdGenerator';

type IntentHistoryPopupPropsModel = {
  allCategories: CategoryModel[];
  allUserGroups: UserGroupModel[];
  authors: ProjectMemberUserModel[];
  node: IntentTableDataModel;
  onHide(): void;
  parentElement?: HTMLElement;
  visible: boolean;
};

/**
 * Component for showing the history objects of a table row in a dropdown.
 * @component
 * @param allCategories all categories
 * @param allUserGroups all user groups
 * @param authors List of current project members
 * @param node The row data
 * @param onHide Callback when hiding the popup
 * @param parentElement The element to return focus to after the dialog is hidden
 * @param visible Whether the popup is visible
 *
 */
const IntentHistoryPopup = function ({
  allCategories,
  allUserGroups,
  authors,
  node,
  onHide,
  parentElement,
  visible
}: IntentHistoryPopupPropsModel) {
  const ContentTemplate = ({
    current = true,
    node,
    retired
  }: {
    current?: boolean;
    node?: IntentTableDataModel;
    retired?: boolean;
  }) => (
    <div className="specto-intent-history-popup-content h-full">
      <div className="flex">
        <strong>{current ? 'Active version' : 'Selected version'}</strong>

        {node ? (
          <>
            <div className="px-2">{'•'}</div>
            <div>{`Modified on ${utcToLocalDateFormatted(node.last_modified)}`}</div>
          </>
        ) : undefined}
      </div>
      {!node || retired ? (
        <div className="flex h-full align-items-center">
          <span className="text-center w-full">
            {!node ? "Intent hasn't been published yet" : 'Intent is retired'}
          </span>
        </div>
      ) : (
        <>
          <table className="info">
            <tbody>
              <tr>
                <td>Name</td>
                <td>{node.name}</td>
              </tr>
              <tr>
                <td>Display Name</td>
                <td>{node.display_name}</td>
              </tr>
              <tr>
                <td>Author</td>
                <td className={classNames({ 'empty-cell': !node.author })}>
                  {node.author
                    ? authors.find((author) => author.user === node.author)?.name
                    : 'None'}
                </td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{statusDisplayNames[node.status]}</td>
              </tr>
              <tr>
                <td>Category</td>
                <td>{mapIdToName(node.category, allCategories)}</td>
              </tr>
              <tr>
                <td className="chips-label-cell">User Groups</td>
                <td
                  className={classNames({
                    'empty-cell--chips': node.user_groups.length === 0
                  })}
                >
                  {node.user_groups.length > 0
                    ? node.user_groups.map((userGroup) => {
                        const label = allUserGroups.find((group) => group.id === userGroup)?.name;
                        return (
                          <Chip
                            className="user-group-chips mb-1"
                            label={label ? label : userGroup.toString()}
                            key={uniqueId(`user-group-chip-${userGroup}`)}
                          />
                        );
                      })
                    : 'None'}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );

  const findChanges = (currentItem: IntentTableDataModel, oldItem: IntentTableDataModel) => {
    let changes = [];

    if (oldItem.status !== currentItem.status) {
      changes.push(`Status changed to ${statusDisplayNames[currentItem.status]}`);
    }

    if (oldItem.name !== currentItem.name) {
      changes.push([
        `Name of the intent was changed.`,
        <div className="align-items-center" key={uniqueId()}>
          {oldItem.name}
          <HistoryStepRightArrow />
          {currentItem.name}
        </div>
      ]);
    }

    if (oldItem.display_name !== currentItem.display_name) {
      changes.push([
        `Display name of the intent was changed.`,
        <div className="align-items-center" key={uniqueId()}>
          {oldItem.display_name}
          <HistoryStepRightArrow />
          {currentItem.display_name}
        </div>
      ]);
    }
    if (oldItem.author !== currentItem.author) {
      if (!oldItem.author) {
        changes.push(`Author changed to ${mapIdToName(currentItem.author, authors, 'user')}`);
      } else if (!currentItem.author) {
        changes.push(`Author was removed`);
      } else {
        changes.push([
          `Author of the intent was changed.`,
          <div className="align-items-center" key={uniqueId()}>
            {`${mapIdToName(oldItem.author, authors, 'user')}`}
            <HistoryStepRightArrow />
            {`${mapIdToName(currentItem.author, authors, 'user')}`}
          </div>
        ]);
      }
    }

    if (oldItem.category !== currentItem.category) {
      changes.push([
        `Category of the intent was changed.`,
        <div className="align-items-center" key={uniqueId()}>
          {`${mapIdToName(oldItem.category, allCategories)}`}
          <HistoryStepRightArrow />
          {`${mapIdToName(currentItem.category, allCategories)}`}
        </div>
      ]);
    }

    const userGroupsAdded = _.difference(currentItem.user_groups, oldItem.user_groups);
    changes = changes.concat(
      userGroupsAdded.map((group) => `User group "${mapIdToName(group, allUserGroups)}" added`)
    );
    const removedUserGroups = _.difference(oldItem.user_groups, currentItem.user_groups);
    changes = changes.concat(
      removedUserGroups.map((group) => `User group "${mapIdToName(group, allUserGroups)}" removed`)
    );

    return changes;
  };

  return (
    <HistoryPopup
      ContentTemplate={ContentTemplate}
      findChanges={findChanges}
      node={node}
      objectType="Intent"
      onHide={onHide}
      parentElement={parentElement}
      users={authors}
      visible={visible}
    />
  );
};

export default IntentHistoryPopup;
