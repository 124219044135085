import AutocompleteField from 'components/Forms/AutocompleteField';
import CheckboxField from 'components/Forms/CheckboxField';
import MultiAutocompleteField from 'components/Forms/MultiAutocompleteField';
import Popup from 'containers/Popup';
import { Status } from 'models/status-model';
import React from 'react';
import { Control, FieldValues, UseFormHandleSubmit, UseFormWatch } from 'react-hook-form';

const exportResponseStatuses = Object.entries(Status)
  .filter((status) => status[1] !== Status.Not_Retired)
  .map((status) => ({
    name: status[0],
    abbreviation: status[1]
  }));

export const exportResponsesFileFormats = ['yaml', 'json'];

/**
 *
 * @param visible whether the modal is open or not
 * @param hidePopup function to hide modal after submission or clicking the modal close button
 * @param onSubmit function to submit form after clicking "Export"
 * @param control form controller
 * @param getValues function to get current form values
 * @param handleSubmit function that handles onSubmit
 * @param watch function to watch for form changes
 * @param unregister function to unregister watch function on component unmount
 * @component
 */
const ExportResponsesPopup = ({
  visible,
  hidePopup,
  onSubmit,
  control,
  handleSubmit,
  watch
}: {
  visible: boolean;
  hidePopup(): void;
  onSubmit(data: FieldValues): void;
  control: Control;
  handleSubmit: UseFormHandleSubmit<FieldValues, undefined>;
  watch: UseFormWatch<FieldValues>;
}) => {
  // /**
  //  * Watcher for when the Replicate Production checkbox field changes
  //  * Enables/disables the Statuses autocomplete field
  //  */
  const watchReplicateProduction = watch('replicate_production');

  return (
    <Popup
      title="Export Responses"
      subtitle="Export Responses to File"
      visible={visible}
      onHide={hidePopup}
      saveButtonText="Export"
      onSave={() => handleSubmit(onSubmit)()}
    >
      <form onSubmit={(e) => e?.preventDefault()}>
        <MultiAutocompleteField
          disabled={watchReplicateProduction}
          defaultValue={[Status.Approved, Status.New, Status.Modified, Status.Published]}
          labelClassName="line-height-3 font-bold"
          required={false}
          control={control}
          options={exportResponseStatuses}
          itemTemplate={(item) => item.name}
          fieldName="statuses"
          label="Statuses"
          optionValue="abbreviation"
          optionLabel="name"
          placeholder="Select Statuses"
          info="Responses with the selected Statuses will be exported"
          tooltipTitle={
            watchReplicateProduction &&
            'Uncheck "Replicate Production" to export Responses based on Status'
          }
        />
        <CheckboxField
          control={control}
          fieldName="replicate_production"
          label="Replicate Production"
        />
        <AutocompleteField
          defaultValue={exportResponsesFileFormats[0]}
          labelClassName="line-height-3 font-bold"
          required={true}
          control={control}
          options={exportResponsesFileFormats}
          fieldName="fileFormat"
          label="File format"
          placeholder="Select File Format"
          info="File format for exported Responses"
        />
      </form>
    </Popup>
  );
};

export default ExportResponsesPopup;
