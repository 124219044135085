import React, { useEffect, useState } from 'react';
import SearchBar from 'components/SearchBar';
import {
  CreateNLUOptionsModel,
  EditNLUOptionsModel,
  GetNLUOptionsModel,
  NLUModel
} from 'models/nlu-table-data-model';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { setLayout } from 'features/layout/layoutSlice';
import {
  intentsSelector,
  projectsSelector,
  sentimentsSelector
} from 'features/projects/projectsSlice';
import { Paginated, SlugIdOptionsModel } from 'models/api-model';
import useFetch from 'hooks/useFetch';
import NluService from 'services/nluService';
import { FetchStatusOptions } from 'constants/fetchStatus';
import { Button } from 'primereact/button';
import NLUDataTable from './components/NLUDataTable';
import { createToast } from 'features/toast/toastSlice';
import { DataTablePageEvent } from 'primereact/datatable';
import useQuery from 'hooks/useQuery';
import NLUPopup from './components/NLUPopup';
import { versionsSelector } from 'features/versions/versionsSlice';
import { FieldValues, useForm } from 'react-hook-form';
import NLUFilterMenu from './components/NLUFilterMenu';
import { FilterChips } from 'components/FilterChips/FilterChips';
import { FilterChip } from 'models/filter-chips';
import { ThreadStateChangeEvent } from 'pages/Comments/CommentPopup';
import { FormMode } from 'models/form-model';
import { permissionBoolean } from 'util/permissions';
import CommentsService from 'services/commentsService';
import { getMainLayout } from 'util/layout';
import { Status } from 'models/status-model';

const NLUData = () => {
  const dispatch = useAppDispatch();
  const { selectedProject, projects } = useAppSelector(projectsSelector);
  const { isVersionSelected } = useAppSelector(versionsSelector);
  const allIntents = useAppSelector(intentsSelector);
  const allSentiments = useAppSelector(sentimentsSelector);
  const [allNLUData, setAllNLUData] = useState<NLUModel[]>([]);
  const [contextNLU, setContextNLU] = useState<NLUModel | null>(null);
  const [popupMode, setPopupMode] = useState<FormMode>(FormMode.CREATE);
  const [displayNLUPopup, setDisplayNLUPopup] = useState(false); // is the popup visible

  // onInit
  useEffect(() => {
    dispatch(setLayout(getMainLayout(selectedProject.project_user.role, projects.length)));
  }, []);

  /* - - - - - - - - - - Query - - - - - - - - - - */

  const nluFilterDefaultValues: FieldValues = {
    status: null
  };

  const {
    control: filterControl,
    reset: resetFilterForm,
    formState: { dirtyFields: dirtyFilterFields, isDirty: isFilterFormDirty }
  } = useForm({ defaultValues: nluFilterDefaultValues });
  const {
    search,
    searchText,
    clearSearch,
    simpleSearch,
    setQueryParameter: setFilterQueryParameter,
    queryString: filterQueryString,
    queryParameters: filterQueryParameters,
    clearAllQueryParameters,
    clearQueryParameter,
    paginate: { limit, offset, setOffset, setLimit, total, setTotal }
  } = useQuery({
    query: onQuery,
    deps: [dirtyFilterFields],
    defaultValues: { ordering: '-id' }
  });

  // query on filterQueryString change.
  // *** also fires onInit, so it replaces default getMessages req
  useEffect(() => {
    getNLUData({ slug: selectedProject.slug, query: filterQueryString });
  }, [filterQueryString]);

  // perform back end query using the search
  function onQuery(queryText: string) {
    // set query parameters which will build a queryParameter string
    setFilterQueryParameter({ search: queryText });
  }

  // perform back end query using paginate
  const onPaginate = (paginateParams: DataTablePageEvent) => {
    // set pagination variables for the paginator component
    setLimit(paginateParams.rows);
    setOffset(paginateParams.first);

    // set query parameters which will build a queryParameter string
    setFilterQueryParameter({
      limit: paginateParams.rows,
      offset: paginateParams.first
    });
  };

  const resetFilter = () => {
    clearSearch();
    clearAllQueryParameters();
    resetFilterForm();
  };

  /* - - - - - - - - - - Filter Chips - - - - - - - - - - */

  const statusNameRecord: Partial<Record<string, string>> = {
    [Status.New]: 'New',
    [Status.Retired]: 'Retired'
  };

  const filterChips: FilterChip[] = [
    {
      name: `Intents with Status: ${
        statusNameRecord[String(filterQueryParameters?.intent__status)]
      }`,
      removeFn: () => clearQueryParameter('intent__status'),
      enabled: filterQueryString.includes('intent__status')
    }
  ];

  const filterDeps = [filterQueryString];

  /* - - - - - - - - - - Get NLU Data - - - - - - - - - - */

  const {
    fetch: getNLUData,
    data: NLUData,
    fetchStatus: getNLUDataStatus
  } = useFetch<GetNLUOptionsModel, Paginated<NLUModel>>(
    NluService.getNLUData,
    NluService.roles.list
  );

  useEffect(() => {
    /**
     * Only fire on successful get request
     * nluData included as a type guard
     */
    if (getNLUDataStatus === FetchStatusOptions.SUCCESS && NLUData) {
      setAllNLUData(NLUData.results);
      setTotal(NLUData.count);
    }
  }, [getNLUDataStatus]);

  /* - - - - - - - - - - Get Single NLU Data - - - - - - - - - - */

  const {
    fetch: getSingleNLUData,
    data: singleNLUData,
    fetchStatus: getSingleNLUDataStatus
  } = useFetch(NluService.getSingleNLUData, NluService.roles.retrieve);

  useEffect(() => {
    if (getSingleNLUDataStatus === FetchStatusOptions.SUCCESS && singleNLUData) {
      setAllNLUData((prevState) =>
        prevState.map((mess) => (mess.id === singleNLUData.id ? singleNLUData : mess))
      );
    }
  }, [getSingleNLUDataStatus]);

  /* - - - - - - - - - - Create NLU Data - - - - - - - - - - */

  const {
    fetch: createMessage,
    data: createMessageData,
    fetchStatus: createMessageStatus,
    permission: canCreateNLU
  } = useFetch<CreateNLUOptionsModel, NLUModel>(NluService.createNLUData, NluService.roles.create);

  useEffect(() => {
    if (createMessageStatus === FetchStatusOptions.SUCCESS && createMessageData) {
      setAllNLUData((prevState) => [createMessageData, ...prevState]);
      setDisplayNLUPopup(false);
      dispatch(createToast('message created'));
      hidePopup();
    }
  }, [createMessageStatus]);

  /* - - - - - - - - - - Edit NLU Data - - - - - - - - - - */

  const {
    fetch: editMessage,
    data: editMessageData,
    fetchStatus: editMessageStatus
  } = useFetch<EditNLUOptionsModel, NLUModel>(NluService.editNLUData, NluService.roles.update);

  useEffect(() => {
    if (editMessageStatus === FetchStatusOptions.SUCCESS && editMessageData) {
      setAllNLUData((prevState) =>
        prevState.map((mess) => (mess.id === editMessageData.id ? editMessageData : mess))
      );
      dispatch(createToast('message edited'));
      hidePopup();
    }
  }, [editMessageStatus]);

  /* - - - - - - - - - - Delete NLU Data - - - - - - - - - - */

  const {
    fetch: deleteNLUData,
    fetchStatus: deleteNLUDataStatus,
    permission: canDelete
  } = useFetch<SlugIdOptionsModel>(NluService.deleteNLUData, NluService.roles.delete);

  useEffect(() => {
    if (deleteNLUDataStatus === FetchStatusOptions.SUCCESS) {
      setAllNLUData((prevState) => prevState.filter((element) => element.id !== contextNLU?.id));
      setContextNLU(null);
      dispatch(createToast('message deleted'));
    }
  }, [deleteNLUDataStatus]);

  /* - - - - - - - - - - Popup - - - - - - - - - - */

  /**
   * Callback to change the new NLU pop-up visibility in state.
   * @param visibility boolean representing the visibility of the new NLU popup
   * @param context the context NLU, used for editing
   */
  const handlePopupDisplayChange = (visibility: boolean, context?: NLUModel) => {
    if (context) {
      setContextNLU(context);
      setPopupMode(FormMode.EDIT);
    } else {
      setPopupMode(FormMode.CREATE);
      setContextNLU(null);
    }
    setDisplayNLUPopup(visibility);
  };

  const onSubmit = (data: FieldValues) => {
    const tableData = data as NLUModel;

    if (contextNLU && popupMode === FormMode.EDIT) {
      editMessage({
        slug: selectedProject.slug,
        updatedNLUData: tableData
      });
    } else {
      createMessage({ slug: selectedProject.slug, updatedNLUData: tableData });
    }
  };

  const hidePopup = () => handlePopupDisplayChange(false);

  /* - - - - - - - - - - Table Actions - - - - - - - - - - */

  const deleteTableNLUData = (node: NLUModel) => {
    setContextNLU(node);
    deleteNLUData({
      slug: selectedProject.slug,
      id: node.id
    });
  };

  const onThreadChange = (options: ThreadStateChangeEvent) => getSingleNLUData(options);

  return (
    <div className="specto-nlu-data">
      <div className="grid grid-nogutter">
        <div className="col flex flex-wrap xl:align-items-center align-items-start justify-content-between flex-column-reverse xl:flex-row">
          <SearchBar
            filterMenu={
              <NLUFilterMenu
                control={filterControl}
                onFormValueChange={(fieldValue) => setFilterQueryParameter(fieldValue)}
              />
            }
            placeHolder="Search Messages"
            className="specto-search-bar w-12 xl:w-8 mt-3 xl:mt-0"
            text={searchText}
            onFilterClearClick={() => resetFilter()}
            hideFilterClear={!isFilterFormDirty}
            onChange={(e) => simpleSearch(e.target.value)}
            onSubmitText={search}
            onClearText={() => {
              clearSearch();
              search('');
            }}
          />
          {!isVersionSelected && canCreateNLU && (
            <Button
              label="New NLU Data"
              icon="pi pi-plus"
              iconPos="right"
              onClick={() => setDisplayNLUPopup(true)}
            />
          )}
        </div>

        <div className="col-12 mb-4 pt-2">
          <FilterChips filterChips={filterChips} deps={filterDeps} className="lg:w-7" />
        </div>

        <div className="col-12">
          <div className="card">
            <NLUDataTable
              allNLUData={allNLUData}
              canDelete={canDelete}
              onEditInit={(nluData) => handlePopupDisplayChange(true, nluData)}
              onDeleteNLUData={deleteTableNLUData}
              onThreadStateChange={onThreadChange}
              sentiment={selectedProject.sentiment}
              loading={
                getNLUDataStatus === FetchStatusOptions.LOADING ||
                deleteNLUDataStatus === FetchStatusOptions.LOADING
              }
              totalRecords={total}
              onPage={onPaginate}
              first={offset}
              rows={limit}
              showComments={permissionBoolean(
                CommentsService.roles.general.list,
                selectedProject.project_user.role
              )}
            />
          </div>
        </div>
      </div>

      <NLUPopup
        displayPopup={displayNLUPopup}
        contextNLU={contextNLU}
        allIntents={allIntents}
        allSentiments={allSentiments}
        sentimentModule={selectedProject.sentiment}
        loading={
          createMessageStatus === FetchStatusOptions.LOADING ||
          editMessageStatus === FetchStatusOptions.LOADING
        }
        onSubmit={onSubmit}
        onHide={hidePopup}
        mode={popupMode}
        parentElement={document.activeElement as HTMLElement}
      />
    </div>
  );
};

export default NLUData;
