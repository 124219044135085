import ApiService from 'services/apiService';
import {
  RegexTableDataModel,
  PostRegexOptionsModel,
  EditRegexOptionsModel,
  RegexTableAPIModel,
  GetRegexOptionsModel
} from 'models/regex-model';
import { REGEX_API_URL } from 'constants/api';
import { SlugIdOptionsModel, SlugOptionsModel } from 'models/api-model';
import { Permissions } from 'models/permission-model';
import { ProjectRole } from 'models/project-model';

const getAllRegex = (options: SlugOptionsModel) =>
  ApiService.get<RegexTableDataModel[]>('/' + options.slug + REGEX_API_URL);

const getRegex = (options: GetRegexOptionsModel) =>
  ApiService.get<RegexTableDataModel>('/' + options.slug + REGEX_API_URL + options.id + '/');

const createRegex = (options: PostRegexOptionsModel) =>
  ApiService.post<RegexTableDataModel, RegexTableAPIModel>(
    '/' + options.slug + REGEX_API_URL,
    options.newRegexData
  );

const deleteRegex = (options: SlugIdOptionsModel) =>
  ApiService.remove<RegexTableDataModel>('/' + options.slug + REGEX_API_URL + options.id + '/');

const editRegex = (options: EditRegexOptionsModel) =>
  ApiService.put<RegexTableDataModel>(
    '/' + options.slug + REGEX_API_URL + options.regexId + '/',
    options.updatedRegexData
  );

const regexServiceRoles: Permissions = {
  list: [ProjectRole.NEUROSOPH],
  retrieve: [ProjectRole.NEUROSOPH],
  create: [ProjectRole.NEUROSOPH],
  update: [ProjectRole.NEUROSOPH],
  delete: [ProjectRole.NEUROSOPH]
};

const RegexService = {
  getAllRegex,
  getRegex,
  createRegex,
  deleteRegex,
  editRegex,
  roles: regexServiceRoles
};

export default RegexService;
