import React, { CSSProperties } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import classnames from 'classnames';

type props = {
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
  noBodyPadding?: boolean;
  title: string;
  subtitle?: string;
  extraFooterElement?: React.ReactNode;
  loading?: boolean;
  visible: boolean;
  saveButtonText?: string | null;
  cancelButtonText?: string;
  submitDisabled?: boolean;
  cancelHidden?: boolean;
  footerVisible?: boolean;
  onSave?(): void;
  onCancel?(): void;
  onHide(): void;
  subtitleIcon?: string;
};
const Popup = ({
  title,
  className,
  style,
  loading,
  visible,
  saveButtonText = 'Submit',
  cancelButtonText = 'Cancel',
  submitDisabled = false,
  cancelHidden = false,
  onHide,
  children,
  noBodyPadding = false,
  onSave,
  onCancel,
  subtitle = '',
  extraFooterElement,
  footerVisible = true,
  subtitleIcon,
  ...custom
}: props) => (
  <Dialog
    {...custom}
    aria-modal="true"
    visible={visible}
    onHide={onHide}
    draggable={false}
    blockScroll={true}
    headerClassName="py-3"
    contentClassName="p-0 specto-popup__content-wrapper border-none border-y-1 surface-border"
    className={classnames('specto-popup', className, 'w-11 md:w-8 xl:w-5')}
    style={style}
    header={
      <div className="specto-popup__header pt-3 px-2">
        <div className="flex justify-content-between">
          <h2>{title}</h2>
        </div>
        <div className="font-normal text-base">
          {subtitleIcon && <i className={`${subtitleIcon} mr-2`}></i>}
          <span className="vertical-align-top">{subtitle}</span>
        </div>
      </div>
    }
    footer={
      footerVisible && (
        <div className="specto-popup__footer pt-4">
          {extraFooterElement}
          <div className="flex justify-content-end align-content-center">
            {!cancelHidden && (
              <Button
                label={cancelButtonText}
                className="p-button-text font-semibold"
                data-cy="popup-cancel"
                onClick={() => {
                  onCancel ? onCancel() : undefined;
                  onHide();
                }}
              />
            )}
            {onSave && saveButtonText && (
              <Button
                raised
                loading={loading}
                disabled={submitDisabled}
                label={saveButtonText}
                className="ml-5 py-2 px-3 font-bold"
                data-cy="popup-save"
                onClick={onSave}
              />
            )}
          </div>
        </div>
      )
    }
  >
    <div className="specto-popup__content flex flex-column">
      <div className={classnames('specto-popup__body', { 'px-5 py-5': !noBodyPadding })}>
        {children}
      </div>
    </div>
  </Dialog>
);

export default Popup;
