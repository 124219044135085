import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'store';
import { SpectoLayout } from 'models/layout-model';

export interface LayoutState {
  layout: SpectoLayout;
}

const initialState: LayoutState = {
  layout: SpectoLayout.main
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setLayoutAction: (state, action: PayloadAction<SpectoLayout>) => {
      state.layout = action.payload;
    },
    resetLayout: (state) => {
      state.layout = initialState.layout;
    }
  }
});

// actions
const { setLayoutAction } = layoutSlice.actions;

// custom synchronous thunk
export const setLayout =
  (layout: SpectoLayout): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    if (state.layout.layout !== layout) {
      dispatch(setLayoutAction(layout));
    }
  };

// selectors
export const layoutSelector = (state: RootState) => state.layout;

export default layoutSlice.reducer;
