import React, { CSSProperties } from 'react';
import classnames from 'classnames';
import { TooltipOptions } from 'primereact/tooltip/tooltipoptions';
import MenuButton, { MenuItemProps } from 'components/MenuButton/MenuButton';

export const iconButtonCellIcon = {
  DELETE: 'pi-trash',
  EDIT: 'pi-pencil'
};

type RoundIconButtonCellOptionsModel = {
  'data-cy'?: string;
  className?: string;
  disabled?: boolean;
  hidden?: boolean;
  loading?: boolean;
  menu: MenuItemProps[];
  style?: CSSProperties;
  tooltip?: string;
  tooltipOptions?: TooltipOptions;
};
/**
 * Menu button cell.
 *
 * @param className - Additional className to apply to the button.
 * @param cypressTag - Tag used for identifying the button in Cypress tests.
 * @param disabled - Indicates whether the button is disabled.
 * @param hidden - Indicates whether the button should be hidden.
 * @param icon - Icon symbol to be displayed.
 * @param loading - Indicates whether the button is in a loading state.
 * @param style - CSS property style to apply to the button.
 * @param tooltip - Tooltip text to display when hovering over the button.
 * @param tooltipOptions - Options for customizing the tooltip behavior.
 * @constructor
 */
export const MenuButtonCell = ({
  'data-cy': cypressTag,
  className,
  disabled = false,
  hidden = false,
  loading,
  menu,
  style,
  tooltip,
  tooltipOptions = { position: 'bottom' }
}: RoundIconButtonCellOptionsModel) => {
  if (hidden) return <></>;

  return (
    <div className="flex justify-content-center align-content-center specto-text-medium ">
      <MenuButton
        options={menu}
        buttonProps={{
          label: 'Actions',
          icon: 'pi pi-chevron-down',
          className: classnames(className, ''),
          type: 'button',
          tooltip: tooltip,
          disabled: disabled,
          style: style,
          loading: loading,
          tooltipOptions: tooltipOptions
        }}
        cypressTag={cypressTag}
      />
    </div>
  );
};
