import { NLUAPIModel } from 'models/nlu-table-data-model';
import { mapIdToName } from 'util/mapIdAndName';
import { MessageInboxTableDataModel } from 'models/message-inbox-table-data-model';
import { IntentTableDataModel } from 'models/intent-table-data-model';
import { SentimentModel } from 'models/sentiment-model';

type AddCheckedRowsProps = {
  checkedRows: MessageInboxTableDataModel[];
  allIntents: IntentTableDataModel[];
  allSentiments: SentimentModel[];
  sentimentModule: boolean;
};

export function buildNLUDataRows({
  checkedRows,
  allIntents,
  allSentiments,
  sentimentModule
}: AddCheckedRowsProps) {
  let validNLUData = true;
  const missingIntents: IntentTableDataModel['name'][] = [];
  const missingSentiments: SentimentModel['id'][] = [];
  let blankInput = false;

  if (checkedRows.length === 0) {
    validNLUData = false;
    blankInput = true;
  }

  const nluData: NLUAPIModel[] = checkedRows.map((row) => {
    const intentName = mapIdToName(row.intent, allIntents);
    const intentId = row.intent;
    const sentimentName = mapIdToName(row.sentiment, allSentiments);
    const sentimentId = row.sentiment;

    // allow null/undefined sentiment id. But throw error if using an id that does not match anything known
    if (sentimentId && !sentimentName) {
      missingSentiments.push(sentimentId);
      validNLUData = false;
    }

    if (intentId === undefined) {
      intentName && missingIntents.push(intentName);
      validNLUData = false;
    }

    if (intentId) {
      return {
        intent: intentId,
        ...(sentimentModule && { sentiment: sentimentId }),
        message: row.text
      };
    } else {
      validNLUData = false;
      return blankNLUData;
    }
  });

  let errorMessage = 'Invalid rows were attempted to be added to NLU';
  if (validNLUData) {
    errorMessage = '';
  } else if (missingIntents.length > 0) {
    errorMessage = 'The following intents do not exist: ' + missingIntents.join(', ');
  } else if (missingSentiments.length > 0) {
    errorMessage = 'The following sentiments do not exist: ' + missingSentiments.join(', ');
  }

  return {
    ...(validNLUData && { nluData }),
    errors: {
      ...(missingIntents.length > 0 && { missingIntents }),
      ...(missingSentiments.length > 0 && { missingSentiments }),
      blankInput,
      message: errorMessage
    }
  };
}

const blankNLUData = {
  intent: -1,
  sentiment: -1,
  message: ''
};
