import { FetchStatusOptions } from 'constants/fetchStatus';
import Popup from 'containers/Popup';
import { projectsSelector } from 'features/projects/projectsSlice';
import { useAppSelector } from 'hooks/store';
import useFetch from 'hooks/useFetch';
import { CommentModel, CommentType } from 'models/comment-model';
import React, { createRef, useEffect, useState } from 'react';
import CommentsService from 'services/commentsService';
import { CommentBox } from './CommentBox';
import { authSelector } from 'features/auth/authSlice';
import { MentionInput } from './MentionInput';
import { confirmDialog } from 'primereact/confirmdialog';
import { SlugIdOptionsModel } from 'models/api-model';
import LoadingSpinner from 'components/LoadingSpinner';
import { debounce } from 'lodash';
import UsersService from 'services/usersService';

type CommentPopupProps = {
  displayPopup: boolean;
  onPopupDisplayChange: (shouldShow: boolean) => void;
  objectType: CommentType;
  objectId: number;
  objectName: string;
  onThreadStateChange?: (e: ThreadStateChangeEvent) => void;
  parentElement?: HTMLElement;
};

export type ThreadStateChangeEvent = SlugIdOptionsModel<number>;

/**
 * Popup that displays all comments relevant to an intent, response, etc.
 *
 * @param displayPopup whether to show the popup
 * @param onPopupDisplayChange function that changes whether the popup should be shown
 * @param objectType intent, response, etc.
 * @param objectId id of the intent, response, etc.
 * @param objectName name of the intent, response, etc
 * @param onThreadStateChange action to perform when the thread is resolved or reopened
 * @param parentElement element to return focus to
 * @component
 */
export const CommentPopup = function ({
  displayPopup,
  onPopupDisplayChange,
  objectType,
  objectId,
  objectName,
  onThreadStateChange,
  parentElement
}: CommentPopupProps) {
  const { selectedProject } = useAppSelector(projectsSelector);
  const currentUser = useAppSelector(authSelector).user;
  const [comments, setComments] = useState<CommentModel[]>([]);
  const [commentList, setCommentList] = useState<JSX.Element[]>([]);
  const [threadResolved, setThreadResolved] = useState<boolean | null>(null);
  const [initializing, setInitializing] = useState(true);
  const { fetch: fetchUsers, data: users } = useFetch(
    UsersService.getUsers,
    UsersService.roles.list
  );
  const scrollToDiv = createRef<HTMLDivElement>();

  const commentOptions = {
    slug: selectedProject.slug,
    object_type: objectType,
    object_id: objectId
  };

  const {
    fetch: fetchComments,
    data: fetchCommentsData,
    fetchStatus: fetchCommentsStatus
  } = useFetch(CommentsService.getComments, CommentsService.roles.general.list);

  const {
    fetch: createComment,
    data: createCommentData,
    fetchStatus: createCommentStatus,
    permission: canCreate
  } = useFetch(CommentsService.createComment, CommentsService.roles.own.create);

  const {
    fetch: editComment,
    data: editCommentData,
    fetchStatus: editCommentStatus
  } = useFetch(CommentsService.editComment, CommentsService.roles.own.update);

  const {
    fetch: resolveThread,
    data: resolveThreadData,
    fetchStatus: resolveThreadStatus
  } = useFetch(CommentsService.resolveComment, CommentsService.roles.general.update);

  const { fetch: deleteThread, fetchStatus: deleteThreadStatus } = useFetch(
    CommentsService.deleteThread,
    CommentsService.roles.general.delete
  );

  const readOnly = !canCreate;

  /**
   * Retrieves all relevant comments
   */
  const getComments = () => fetchComments(commentOptions);

  /**
   * Update and replaces a comment
   *
   * @param newText new comment text
   * @param id of the comment
   */
  const updateComment = (newText: string, id: number) => {
    editComment({
      ...commentOptions,
      id: id,
      text: newText,
      mentions: []
    });
  };

  /**
   * Resolves a comment thread
   */
  const resolveCommentThread = () => {
    resolveThread(commentOptions);
  };

  /**
   * Hides the popup
   */
  const hidePopup = () => {
    setComments([]);
    onPopupDisplayChange(false);
    parentElement?.focus();
  };

  /**
   * Creates a new comment
   * @param text comment text
   */
  const addComment = (text: string) => {
    const mentions: number[] = [];
    // Use Regex to extract the id of the people mentioned
    text.match(/@\[.*?]\(\d+\)/g)?.forEach((mention) => {
      const matches = mention.match(/\((.*?)\)/);

      if (matches) {
        const userId = parseInt(matches[1]);
        if (userId) {
          mentions.push(userId);
        }
      }
    });

    createComment({
      ...commentOptions,
      text: text,
      mentions: mentions
    });
  };

  // Send a request to retrieve the relevant
  // comments when opening the popup
  useEffect(() => {
    setInitializing(true);
    setThreadResolved(null);
    if (displayPopup) {
      fetchUsers({ slug: selectedProject.slug });
      getComments();
    }
  }, [displayPopup]);

  // Store the relevant comments on success
  useEffect(() => {
    if (fetchCommentsStatus === FetchStatusOptions.SUCCESS && fetchCommentsData) {
      // Add small delay so that loading spinner appears
      debounce(() => {
        setComments(fetchCommentsData);
        setInitializing(false);
      }, 500)();
    }
  }, [fetchCommentsStatus]);

  // Add the new comment when successfully adding a comment
  useEffect(() => {
    if (createCommentStatus === FetchStatusOptions.SUCCESS && createCommentData) {
      setComments([...comments, createCommentData]);
      // Re-fetch the object to update the comment icon on data tables if necessary
      if (comments?.[0]?.thread_resolved ?? null !== createCommentData.thread_resolved) {
        onThreadStateChange?.({ slug: commentOptions.slug, id: commentOptions.object_id });
      }
    }
  }, [createCommentStatus]);

  // Indicate the thread is resolved and re-fetch comments
  useEffect(() => {
    if (resolveThreadStatus === FetchStatusOptions.SUCCESS && resolveThreadData) {
      setThreadResolved(resolveThreadData.comment_thread_resolved);
      getComments();
      onThreadStateChange?.({ slug: commentOptions.slug, id: commentOptions.object_id });
    }
  }, [resolveThreadStatus]);

  // Replace an edited comment with the edited version
  useEffect(() => {
    if (editCommentStatus === FetchStatusOptions.SUCCESS && editCommentData) {
      const editedComments = [...comments];
      const index = comments.findIndex((value) => value.id === editCommentData.id);
      editedComments[index] = editCommentData;
      setComments(editedComments);
    }
  }, [editCommentStatus]);

  // Update the thread_resolved field on the original object
  useEffect(() => {
    if (deleteThreadStatus === FetchStatusOptions.SUCCESS) {
      onThreadStateChange?.({ slug: commentOptions.slug, id: commentOptions.object_id });
    }
  }, [deleteThreadStatus]);

  /**
   * Creates a component to display each comment
   *
   * @param comment Comment to display
   * @param key key
   * @returns Comment component
   */
  const renderComment = (comment: CommentModel, key: number) => {
    const user = users?.find((user) => user.id === comment.user);
    return (
      <CommentBox
        user={user}
        users={users}
        comment={comment}
        updateComment={updateComment}
        disableEditing={comment.thread_resolved || currentUser.id !== user?.id || readOnly}
        key={key}
      />
    );
  };

  /**
   * Create components for each comment whenever the comments are updated
   */
  useEffect(() => {
    setCommentList(
      comments.map((comment, index) => {
        if (threadResolved === null) {
          // Update the thread status using the first comment's thread_resolved field
          // This field should be the same among all comments
          setThreadResolved(comment.thread_resolved);
        }
        return renderComment(comment, index);
      })
    );
  }, [comments]);

  /**
   * Scroll to bottom of comments to always show the newest comments
   */
  useEffect(() => {
    scrollToDiv.current?.scrollIntoView({ behavior: 'smooth' });
  }, [commentList]);

  /**
   * Dialog to confirm thread deletion
   */
  const confirmDeleteDialog = () =>
    confirmDialog({
      message: 'This action cannot be undone.',
      header: 'Delete Comment Thread?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => deleteThread(commentOptions),
      reject: () => onPopupDisplayChange(true)
    });

  return (
    <Popup
      title="Comments"
      subtitle={`${objectType.charAt(0).toUpperCase() + objectType.slice(1)}: ${objectName}`}
      visible={displayPopup}
      onSave={resolveCommentThread}
      saveButtonText={threadResolved ? 'Reopen Thread' : 'Resolve Thread'}
      submitDisabled={threadResolved === null || readOnly}
      cancelHidden={threadResolved !== true}
      onHide={hidePopup}
      onCancel={confirmDeleteDialog}
      cancelButtonText="Delete Thread"
      extraFooterElement={
        !readOnly && (
          <MentionInput
            users={users ?? []}
            className="pb-4"
            currentUser={currentUser}
            addComment={addComment}
            disabled={threadResolved === true}
          />
        )
      }
    >
      <div className="flex flex-column pt-1 justify-content-around">
        {initializing ? (
          <LoadingSpinner position="inline" />
        ) : commentList.length > 0 ? (
          commentList
        ) : (
          'No comments'
        )}
        <div ref={scrollToDiv} />
      </div>
    </Popup>
  );
};
