import ApiService from 'services/apiService';
import {
  EntityGroupTableDataModel,
  PostEntityGroupOptionsModel,
  EditEntityGroupOptionsModel,
  EntityGroupTableAPIModel,
  GetEntityGroupOptionsModel
} from 'models/entity-group-model';
import { ENTITY_GROUPS_API_URL } from 'constants/api';
import { SlugIdOptionsModel, SlugOptionsModel } from 'models/api-model';
import { Permissions } from 'models/permission-model';
import { ProjectRole } from 'models/project-model';

const getEntityGroups = (options: SlugOptionsModel) =>
  ApiService.get<EntityGroupTableDataModel[]>('/' + options.slug + ENTITY_GROUPS_API_URL);

const getEntityGroup = (options: GetEntityGroupOptionsModel) =>
  ApiService.get<EntityGroupTableDataModel>(
    '/' + options.slug + ENTITY_GROUPS_API_URL + options.id + '/'
  );

const createEntityGroup = (options: PostEntityGroupOptionsModel) =>
  ApiService.post<EntityGroupTableDataModel, EntityGroupTableAPIModel>(
    '/' + options.slug + ENTITY_GROUPS_API_URL,
    options.newEntityGroupData
  );

const deleteEntityGroup = (options: SlugIdOptionsModel) =>
  ApiService.remove<EntityGroupTableDataModel>(
    '/' + options.slug + ENTITY_GROUPS_API_URL + options.id + '/'
  );

const editEntityGroup = (options: EditEntityGroupOptionsModel) =>
  ApiService.put<EntityGroupTableDataModel>(
    '/' + options.slug + ENTITY_GROUPS_API_URL + options.entityGroupId + '/',
    options.updatedEntityGroupData
  );

const entityGroupsServiceRoles: Permissions = {
  list: [ProjectRole.NEUROSOPH],
  retrieve: [ProjectRole.NEUROSOPH],
  create: [ProjectRole.NEUROSOPH],
  update: [ProjectRole.NEUROSOPH],
  delete: [ProjectRole.NEUROSOPH]
};

const EntityGroupsService = {
  getEntityGroups,
  getEntityGroup,
  createEntityGroup,
  deleteEntityGroup,
  editEntityGroup,
  roles: entityGroupsServiceRoles
};

export default EntityGroupsService;
