import { Tooltip } from 'primereact/tooltip';
import { TooltipOptions } from 'primereact/tooltip/tooltipoptions';
import React from 'react';
import { uniqueId } from 'util/uniqueIdGenerator';

/**
 * An info icon that displays a tooltip when hovered over or focused
 *
 * @param text text that will be displayed when the user hovers over or focuses the info icon
 * @param tooltipOptions options for the tooltip
 * @component
 */
const Info = function ({
  text,
  tooltipOptions
}: {
  text: string;
  tooltipOptions?: TooltipOptions;
}) {
  const infoId = uniqueId('info');
  return (
    <div>
      <Tooltip target={`#${infoId}`} content={text} event="both" {...tooltipOptions} />
      <i id={infoId} tabIndex={0} className="pi pi-info-circle ml-2 mr-2" aria-label={text} />
    </div>
  );
};

export default Info;
