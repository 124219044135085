import React from 'react';
import { Button } from 'primereact/button';

type TreeToggleButtonOptionsType = {
  display: boolean;
  toggleRowExpansion(e: React.MouseEvent<HTMLButtonElement>): void;
  expanded: boolean;
};

/**
 * Component that creates a tree toggle button if a row history exists.
 * @component
 * @param display if it should display or not (ex. when it has data stored in its child data structure)
 * @param toggleRowExpansion fn to expand row
 * @param expanded if the row is expanded
 */
const TreeToggleButton = ({
  display,
  toggleRowExpansion,
  expanded
}: TreeToggleButtonOptionsType) =>
  display ? (
    <Button
      icon={expanded ? 'pi pi-angle-down' : 'pi pi-angle-right'}
      className="p-button-rounded p-button-text specto-r-button-lg m-auto"
      onClick={toggleRowExpansion}
      aria-label="toggle history"
      aria-expanded={expanded}
    />
  ) : (
    <></>
  );

export default TreeToggleButton;
