import React, { useEffect, useRef, useState } from 'react';
import SpectoTaggy from './SpectoTaggy';
import { EntityDisplayLabel, EntityDisplayOption } from './entity-labelling-model';
import { Tooltip } from 'primereact/tooltip';
import { uniqueId } from 'util/uniqueIdGenerator';
import { MessageInboxEntityLabelModel } from 'models/message-inbox-table-data-model';
import { EntityTableDataModel } from 'models/entity-model';
import { pipeToEntityDisplayLabels, sortEntities } from 'util/entity';
import { EntityGroupTableDataModel } from 'models/entity-group-model';
import { mapIdToName } from 'util/mapIdAndName';

type EntityDisplayProps = {
  text: string;
  entityLabels: MessageInboxEntityLabelModel[];
  options: EntityDisplayOption[];
  allEntities: EntityTableDataModel[];
  allGroups: EntityGroupTableDataModel[];
  synonymRecord: Record<string, string>;
};

const EntityDisplay = function ({
  text,
  entityLabels,
  options,
  allEntities,
  allGroups,
  synonymRecord
}: EntityDisplayProps) {
  const id = useRef(uniqueId()).current;
  const [cleanedEntities, setCleanedEntities] = useState<EntityDisplayLabel[]>([]);
  const [sortedMessageInboxLabels, setSortedMessageInboxLabels] = useState<
    MessageInboxEntityLabelModel[]
  >([]);
  const endIndex = useRef(-1);
  const EmptyMsg = 'None';

  useEffect(() => {
    endIndex.current = -1;
    const entities: EntityDisplayLabel[] = sortEntities<EntityDisplayLabel>(
      pipeToEntityDisplayLabels(entityLabels, allEntities)
    );

    const entitiesCleaned: EntityDisplayLabel[] = [];

    entities.forEach((entity) => {
      if (entity.start === undefined || entity.start > endIndex.current) {
        entitiesCleaned.push(entity);
        endIndex.current = entity.end ?? 0;
      }
    });

    setCleanedEntities(entitiesCleaned);
    setSortedMessageInboxLabels(sortEntities(entityLabels));
  }, [entityLabels]);

  return (
    <SpectoTaggy
      text={text}
      spans={cleanedEntities}
      ents={options}
      entityTemplate={(element, token, index) => (
        <span id={`specto-entity-tag-${id}-${index}`}>
          {element}
          <Tooltip className="mt-2" target={`#specto-entity-tag-${id}-${index}`} position="bottom">
            <div>
              Synonym:&nbsp;
              <strong>{synonymRecord[token.token?.toLowerCase() ?? ''] || EmptyMsg}</strong>
            </div>
            <div>
              Role: <strong>{sortedMessageInboxLabels[index]?.role || EmptyMsg}</strong>
            </div>
            <div>
              Group:&nbsp;
              <strong>
                {mapIdToName(sortedMessageInboxLabels[index]?.group, allGroups) || EmptyMsg}
              </strong>
            </div>
          </Tooltip>
        </span>
      )}
    />
  );
};

export default EntityDisplay;
