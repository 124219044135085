import React, { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
// primereact
// components
import Popup from 'containers/Popup';
import { DropdownField, TextField } from 'components/Forms';
// utils
// models
import { SentimentModel } from 'models/sentiment-model';
import { IntentTableDataModel } from 'models/intent-table-data-model';
import { GenericItemTemplate } from 'components/Table';
import { FormMode } from 'models/form-model';
import { NLUModel } from 'models/nlu-table-data-model';
import { filterRetired } from 'util/status';

type NewIntentPopupPropsModel = {
  allIntents: IntentTableDataModel[];
  allSentiments: SentimentModel[];
  contextNLU?: NLUModel | null;
  displayPopup: boolean;
  loading: boolean;
  mode?: FormMode;
  onHide(): void;
  onSubmit(data: FieldValues): void;
  parentElement?: HTMLElement;
  sentimentModule: boolean;
};

const NLUPopup = function ({
  allIntents,
  allSentiments,
  contextNLU,
  displayPopup,
  loading,
  mode = FormMode.CREATE,
  onHide,
  onSubmit,
  parentElement,
  sentimentModule
}: NewIntentPopupPropsModel) {
  const { control, handleSubmit, reset, formState } = useForm();

  useEffect(() => {
    const defaultValues: FieldValues = contextNLU ?? {
      message: '',
      intent: '',
      sentiment: null
    };
    reset(defaultValues);
  }, [contextNLU]);

  // reset when closing
  useEffect(() => {
    !displayPopup && reset();
  }, [displayPopup]);

  /* - - - - - - - - - - Callbacks - - - - - - - - - - */

  const onPopupSave = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    handleSubmit(onSubmit)();
  };

  const hidePopup = () => {
    reset();
    onHide();
    parentElement?.focus();
  };

  return (
    <Popup
      title={mode === FormMode.CREATE ? 'New NLU' : 'Edit NLU'}
      subtitle={mode === FormMode.CREATE ? 'Create new NLU data' : 'Modify existing NLU data'}
      visible={displayPopup}
      loading={loading}
      onHide={hidePopup}
      onSave={onPopupSave}
    >
      <form className="p-fluid" onSubmit={onPopupSave}>
        <div className="specto-text-medium -mt-2 mb-5">
          Required fields are marked with <span className="specto-form-asterisk-color">*</span>
        </div>

        <TextField fieldName="message" label="Message" control={control} />

        <DropdownField
          fieldName="intent"
          label="Intent"
          filter
          optionLabel="name"
          optionValue="id"
          appendTo={null}
          options={filterRetired(allIntents, formState.defaultValues?.intent)}
          itemTemplate={(item) =>
            GenericItemTemplate({
              id: item.id,
              itemName: 'intent',
              allOptions: allIntents,
              showRetiredIcon: true,
              showNewIcon: true
            })
          }
          valueTemplate={(node: IntentTableDataModel) =>
            node ? (
              <GenericItemTemplate
                id={node?.id}
                allOptions={allIntents}
                itemName="intent"
                showNewIcon
                showRetiredIcon
              />
            ) : (
              <span>&nbsp;</span>
            )
          }
          control={control}
        />

        {sentimentModule && (
          <DropdownField
            fieldName="sentiment"
            label="Sentiment"
            options={allSentiments}
            optionLabel="name"
            optionValue="id"
            appendTo={null}
            control={control}
          />
        )}
      </form>
    </Popup>
  );
};

export default NLUPopup;
