import { SortableFormComponent } from 'features/drag-sort';
import { ResponseTypeOptions } from '../models/response-table-data-model';

/**
 * Reorders components using the field array keys and updated values from an updated field array
 * fields arrays are not updated on input change, current values are from getValues.
 * These getValues fields somehow have a different ID so a mapping is required.
 * @param components sorted components
 * @param fieldsWithCurrentKeys arrayFields with the correct keys
 * @param fieldsWithCurrentValues arrayFields with the correct values (from getValues)
 */
export const reorderResponseFields = function <T extends Record<'id', string> = any>(
  components: SortableFormComponent[],
  fieldsWithCurrentKeys: T[],
  fieldsWithCurrentValues?: T[]
): T[] {
  if (!fieldsWithCurrentValues) {
    return fieldsWithCurrentKeys;
  }

  // fields are not updated on input change, current values are from getValues but somehow have a different ID
  const fieldsCopy: T[] = fieldsWithCurrentKeys.map((field, i) => ({
    ...fieldsWithCurrentValues[i],
    id: field.id
  }));

  const reorderedKeys: string[] = components.map((component: any) => component?.component?.key);

  return reorderedKeys.reduce(function (orderedField: T[], index) {
    const field = fieldsCopy.find((field) => field.id === index);
    if (field) {
      orderedField.push(field);
    }
    return orderedField;
  }, []);
};

/**
 * Checks if the content type matches an array of content types.
 * @param type
 * @param responseTypes
 */
export const contentTypeMatch = (
  type: ResponseTypeOptions,
  responseTypes: ResponseTypeOptions[]
): boolean => responseTypes.includes(type);
