import ApiService from 'services/apiService';
import {
  LookupTableDataModel,
  PostLookupTableOptionsModel,
  EditLookupTableOptionsModel,
  GetLookupTableOptionsModel,
  LookupTableEditData
} from 'models/lookup-table-model';
import { LOOKUP_TABLES_API_URL } from 'constants/api';
import { SlugIdOptionsModel, SlugOptionsModel } from 'models/api-model';
import { Permissions } from 'models/permission-model';
import { ProjectRole } from 'models/project-model';

const getLookupTables = (options: SlugOptionsModel) =>
  ApiService.get<LookupTableDataModel[]>('/' + options.slug + LOOKUP_TABLES_API_URL);

const getLookupTable = (options: GetLookupTableOptionsModel) =>
  ApiService.get<LookupTableDataModel>(
    '/' + options.slug + LOOKUP_TABLES_API_URL + options.id + '/'
  );

const createLookupTable = (options: PostLookupTableOptionsModel) =>
  ApiService.post<LookupTableDataModel, LookupTableEditData>(
    '/' + options.slug + LOOKUP_TABLES_API_URL,
    options.newLookupTableData,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );

const deleteLookupTable = (options: SlugIdOptionsModel) =>
  ApiService.remove<LookupTableDataModel>(
    '/' + options.slug + LOOKUP_TABLES_API_URL + options.id + '/'
  );

const editLookupTable = (options: EditLookupTableOptionsModel) =>
  ApiService.put<LookupTableDataModel>(
    '/' + options.slug + LOOKUP_TABLES_API_URL + options.lookupTableId + '/',
    options.updatedLookupTableData,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );

const lookupTableServiceRoles: Permissions = {
  list: [ProjectRole.NEUROSOPH],
  retrieve: [ProjectRole.NEUROSOPH],
  create: [ProjectRole.NEUROSOPH],
  update: [ProjectRole.NEUROSOPH],
  delete: [ProjectRole.NEUROSOPH]
};

const LookupTableService = {
  getLookupTables,
  getLookupTable,
  createLookupTable,
  deleteLookupTable,
  editLookupTable,
  roles: lookupTableServiceRoles
};

export default LookupTableService;
