//redux
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
// store
import { RootState } from 'store';
//services
import ProjectsService from 'services/projectsService';
import CategoriesService from 'services/categoriesService';
import TagsService from 'services/tagsService';
import IntentsService from 'services/intentsService';
import ResponsesService from 'services/responsesService';
import SentimentsService from 'services/sentimentsService';
import EntitiesService from 'services/entitiesService';
import SynonymsService from 'services/synonymsService';
import RegexService from 'services/regexService';
import EntityGroupsService from 'services/entityGroupsService';
import LookupTableService from 'services/lookupTableService';
import LanguagesService from 'services/languageService';
import TopicsService from 'services/topicsService';
import MetadataService from 'services/metadataService';
// models
import { ProjectDetailModel, ProjectMemberModel, ProjectSummaryModel } from 'models/project-model';
import { contentFiltersModel } from 'models/content-filters-model';
import { CategoryModel } from 'models/category-model';
import { TagModel } from 'models/tag-model';
import { ResponseTableDataModel } from 'models/response-table-data-model';
import { IntentTableDataModel } from 'models/intent-table-data-model';
import { SlugOptionsModel, SlugQueryOptionsModel } from 'models/api-model';
import { SentimentModel } from 'models/sentiment-model';
import { LanguageModel } from 'models/language-model';
import { EntityTableDataModel } from 'models/entity-model';
import { SynonymTableDataModel } from 'models/synonym-model';
import { RegexTableDataModel } from 'models/regex-model';
import { EntityGroupTableDataModel } from 'models/entity-group-model';
import { LookupTableDataModel } from 'models/lookup-table-model';
import { TopicTableDataModel } from 'models/topic-model';
import { MetadataFilterModel } from 'models/metadata-filter-model';
import UserGroupsService from 'services/userGroupsService';
import { UserGroupModel } from 'models/user-groups-model';
import { UserModel } from 'models/user-model';
import UsersService from 'services/usersService';

export type ProjectsState = {
  selectedProject: ProjectDetailModel;
  intents: IntentTableDataModel[];
  sentiments: SentimentModel[];
  categories: CategoryModel[];
  tags: TagModel[];
  responses: ResponseTableDataModel[];
  entities: EntityTableDataModel[];
  synonyms: SynonymTableDataModel[];
  regex: RegexTableDataModel[];
  entityGroups: EntityGroupTableDataModel[];
  lookupTables: LookupTableDataModel[];
  topics: TopicTableDataModel[];
  projects: ProjectSummaryModel[];
  supportedLanguages: LanguageModel[];
  userGroups: UserGroupModel[];
  projectMembers: ProjectMemberModel[];
  users: UserModel[];
  isProjectSelected: boolean;
  isFetching: boolean;
  isError?: boolean;
  isVersion: boolean;
  metadataFilters: MetadataFilterModel[];
  contentFilters?: contentFiltersModel;
  filtersDirty?: boolean;
};

const initialState: ProjectsState = {
  selectedProject: {} as ProjectDetailModel,
  intents: [],
  sentiments: [],
  categories: [],
  tags: [],
  responses: [],
  entities: [],
  synonyms: [],
  entityGroups: [],
  regex: [],
  lookupTables: [],
  topics: [],
  projects: [],
  supportedLanguages: [],
  metadataFilters: [],
  userGroups: [],
  projectMembers: [],
  users: [],
  isProjectSelected: false,
  isFetching: false,
  isError: false,
  isVersion: false,
  filtersDirty: false,
  contentFilters: {
    active: true
  } as contentFiltersModel
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    selectProject: (state, action: PayloadAction<ProjectDetailModel>) => {
      state.selectedProject = action.payload;
      state.isProjectSelected = true;
    },
    exitProject: (state) => {
      state.selectedProject = initialState.selectedProject;
      state.isProjectSelected = false;
      state.intents = [];
      state.categories = [];
      state.tags = [];
      state.responses = [];
      state.entities = [];
      state.synonyms = [];
      state.regex = [];
      state.entityGroups = [];
      state.lookupTables = [];
      state.entityGroups = [];
      state.topics = [];
      state.metadataFilters = [];
      state.projectMembers = [];
      state.users = [];
      state.filtersDirty = false;
      state.contentFilters = initialState.contentFilters;
    },
    updateProject: (state, action: PayloadAction<ProjectSummaryModel>) => {
      if (state.selectedProject) {
        Object.assign(state.selectedProject, action.payload);
      }

      // update project in projects list
      state.projects = state.projects.map((project) =>
        project.slug === action.payload.slug ? { ...project, ...action.payload } : project
      );
    },
    addProject: (state, action: PayloadAction<ProjectSummaryModel>) => {
      state.projects = [action.payload, ...state.projects];
    },
    removeProject: (state, action: PayloadAction<string>) => {
      state.projects = state.projects.filter(
        (project: ProjectSummaryModel) => project.slug !== action.payload
      );
    },
    /**
     * Intents
     */
    addIntent: (state, action: PayloadAction<IntentTableDataModel>) => {
      state.intents = [...state.intents, action.payload];
    },
    editIntent: (state, action: PayloadAction<IntentTableDataModel>) => {
      state.intents = state.intents.map((intent) =>
        intent.id === action.payload.id ? action.payload : intent
      );
    },
    deleteIntent: (state, action: PayloadAction<IntentTableDataModel['id']>) => {
      state.intents = state.intents.filter((intent) => intent.id !== action.payload);
    },
    /**
     * Sentiments
     */
    addSentiment: (state, action: PayloadAction<SentimentModel>) => {
      state.sentiments = [...state.sentiments, action.payload];
    },
    editSentiment: (state, action: PayloadAction<SentimentModel>) => {
      state.sentiments = state.sentiments.map((sentiment) =>
        sentiment.id === action.payload.id ? action.payload : sentiment
      );
    },
    deleteSentiment: (state, action: PayloadAction<SentimentModel['id']>) => {
      state.sentiments = state.sentiments.filter((sentiment) => sentiment.id !== action.payload);
    },
    /**
     * Responses
     */
    addResponse: (state, action: PayloadAction<ResponseTableDataModel>) => {
      state.responses = [...state.responses, action.payload];
    },
    editResponse: (state, action: PayloadAction<ResponseTableDataModel>) => {
      state.responses = state.responses.map((response) =>
        response.id === action.payload.id ? action.payload : response
      );
    },
    deleteResponse: (state, action: PayloadAction<ResponseTableDataModel['id']>) => {
      state.responses = state.responses.filter((response) => response.id !== action.payload);
    },
    /**
     * Categories
     */
    addCategory: (state, action: PayloadAction<CategoryModel>) => {
      state.categories = [...state.categories, action.payload];
    },
    editCategory: (state, action: PayloadAction<CategoryModel>) => {
      state.categories = state.categories.map((category) =>
        category.id === action.payload.id ? action.payload : category
      );
    },
    deleteCategory: (state, action: PayloadAction<CategoryModel['id']>) => {
      state.categories = state.categories.filter((category) => category.id !== action.payload);
    },
    /**
     * Tags
     */
    addTag: (state, action: PayloadAction<TagModel>) => {
      state.tags = [...state.tags, action.payload];
    },
    editTag: (state, action: PayloadAction<TagModel>) => {
      state.tags = state.tags.map((tag) => (tag.id === action.payload.id ? action.payload : tag));
    },
    deleteTag: (state, action: PayloadAction<TagModel['id']>) => {
      state.tags = state.tags.filter((tag) => tag.id !== action.payload);
    },
    /**
     * Entities
     */
    addEntity: (state, action: PayloadAction<EntityTableDataModel>) => {
      state.entities = [...state.entities, action.payload];
    },
    editEntity: (state, action: PayloadAction<EntityTableDataModel>) => {
      state.entities = state.entities.map((entity) =>
        entity.id === action.payload.id ? action.payload : entity
      );
    },
    deleteEntity: (state, action: PayloadAction<EntityTableDataModel['id']>) => {
      state.entities = state.entities.filter((entity) => entity.id !== action.payload);
    },
    /**
     * Synonyms
     */
    addSynonym: (state, action: PayloadAction<SynonymTableDataModel>) => {
      state.synonyms = [...state.synonyms, action.payload];
    },
    editSynonym: (state, action: PayloadAction<SynonymTableDataModel>) => {
      state.synonyms = state.synonyms.map((synonym) =>
        synonym.id === action.payload.id ? action.payload : synonym
      );
    },
    deleteSynonym: (state, action: PayloadAction<SynonymTableDataModel['id']>) => {
      state.synonyms = state.synonyms.filter((synonym) => synonym.id !== action.payload);
    },
    /**
     * Regex
     */
    addRegex: (state, action: PayloadAction<RegexTableDataModel>) => {
      state.regex = [...state.regex, action.payload];
    },
    editRegex: (state, action: PayloadAction<RegexTableDataModel>) => {
      state.regex = state.regex.map((regex) =>
        regex.id === action.payload.id ? action.payload : regex
      );
    },
    deleteRegex: (state, action: PayloadAction<RegexTableDataModel['id']>) => {
      state.regex = state.regex.filter((regex) => regex.id !== action.payload);
    },
    /**
     * Entity Groups
     */
    addEntityGroup: (state, action: PayloadAction<EntityGroupTableDataModel>) => {
      state.entityGroups = [...state.entityGroups, action.payload];
    },
    editEntityGroup: (state, action: PayloadAction<EntityGroupTableDataModel>) => {
      state.entityGroups = state.entityGroups.map((group) =>
        group.id === action.payload.id ? action.payload : group
      );
    },
    deleteEntityGroup: (state, action: PayloadAction<EntityGroupTableDataModel['id']>) => {
      state.entityGroups = state.entityGroups.filter((group) => group.id !== action.payload);
    },
    /**
     * Lookup Tables
     */
    addLookupTable: (state, action: PayloadAction<LookupTableDataModel>) => {
      state.lookupTables = [...state.lookupTables, action.payload];
    },
    editLookupTable: (state, action: PayloadAction<LookupTableDataModel>) => {
      state.lookupTables = state.lookupTables.map((lookupTable) =>
        lookupTable.id === action.payload.id ? action.payload : lookupTable
      );
    },
    deleteLookupTable: (state, action: PayloadAction<LookupTableDataModel['id']>) => {
      state.lookupTables = state.lookupTables.filter(
        (lookupTable) => lookupTable.id !== action.payload
      );
    },
    /**
     * Topics
     */
    addTopic: (state, action: PayloadAction<TopicTableDataModel>) => {
      state.topics = [...state.topics, action.payload];
    },
    editTopic: (state, action: PayloadAction<TopicTableDataModel>) => {
      state.topics = state.topics.map((topic) =>
        topic.id === action.payload.id ? action.payload : topic
      );
    },
    deleteTopic: (state, action: PayloadAction<TopicTableDataModel['id']>) => {
      state.topics = state.topics.filter((topic) => topic.id !== action.payload);
    },
    /**
     * Metadata Filters
     */
    addMetadataFilters: (state, action: PayloadAction<MetadataFilterModel>) => {
      state.metadataFilters = [...state.metadataFilters, action.payload];
    },
    editMetadataFilters: (state, action: PayloadAction<MetadataFilterModel>) => {
      state.metadataFilters = state.metadataFilters.map((metadataFilters) =>
        metadataFilters.id === action.payload.id ? action.payload : metadataFilters
      );
    },
    deleteMetadataFilters: (state, action: PayloadAction<MetadataFilterModel['id']>) => {
      state.metadataFilters = state.metadataFilters.filter(
        (metadataFilters) => metadataFilters.id !== action.payload
      );
    },
    /**
     * User Group Filters
     */
    addUserGroup: (state, action: PayloadAction<UserGroupModel>) => {
      state.userGroups = [...state.userGroups, action.payload];
    },
    editUserGroup: (state, action: PayloadAction<UserGroupModel>) => {
      state.userGroups = state.userGroups.map((userGroup) =>
        userGroup.id === action.payload.id ? action.payload : userGroup
      );
    },
    deleteUserGroup: (state, action: PayloadAction<UserGroupModel['id']>) => {
      state.userGroups = state.userGroups.filter((userGroup) => userGroup.id !== action.payload);
    },
    /**
     * mark filters as dirty
     */
    dirtyFilters: (state) => {
      state.filtersDirty = true;
    },

    /**
     * Content Filters
     */
    updateContentFilters: (state, action: PayloadAction<contentFiltersModel>) => {
      if (state.contentFilters) {
        for (const key in action.payload) {
          state.contentFilters[key as keyof contentFiltersModel] =
            action.payload[key as keyof contentFiltersModel];
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      /**
       * Projects
       */
      .addCase(getProjects.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getProjects.fulfilled, (state, action: PayloadAction<ProjectSummaryModel[]>) => {
        state.projects = action.payload;
        state.isFetching = false;
        state.isError = false;
      })
      .addCase(getProjects.rejected, (state) => {
        state.projects = initialState.projects;
        state.isFetching = false;
        state.isError = true;
      })
      /**
       * User Groups
       */
      .addCase(getUserGroups.fulfilled, (state, action: PayloadAction<UserGroupModel[]>) => {
        state.userGroups = action.payload;
      })
      .addCase(getUserGroups.rejected, (state) => {
        state.userGroups = initialState.userGroups;
        state.isError = true;
      })
      /**
       * Users
       */
      .addCase(getUsers.fulfilled, (state, action: PayloadAction<UserModel[]>) => {
        state.users = action.payload;
      })
      .addCase(getUsers.rejected, (state) => {
        state.users = initialState.users;
        state.isError = true;
      })
      /**
       * Project Members
       */
      .addCase(
        getProjectMembersList.fulfilled,
        (state, action: PayloadAction<ProjectMemberModel[]>) => {
          state.projectMembers = action.payload;
        }
      )
      .addCase(getProjectMembersList.rejected, (state) => {
        state.projectMembers = initialState.projectMembers;
        state.isError = true;
      })
      /**
       * Intents
       */
      .addCase(getIntents.fulfilled, (state, action: PayloadAction<IntentTableDataModel[]>) => {
        state.intents = action.payload;
      })
      .addCase(getIntents.rejected, (state) => {
        state.intents = initialState.intents;
        state.isError = true;
      })
      /**
       * Sentiments
       */
      .addCase(getSentiments.fulfilled, (state, action: PayloadAction<SentimentModel[]>) => {
        state.sentiments = action.payload;
      })
      .addCase(getSentiments.rejected, (state) => {
        state.sentiments = initialState.sentiments;
        state.isError = true;
      })
      /**
       * Categories
       */
      .addCase(getCategories.fulfilled, (state, action: PayloadAction<CategoryModel[]>) => {
        state.categories = action.payload;
      })
      .addCase(getCategories.rejected, (state) => {
        state.categories = initialState.categories;
        state.isError = true;
      })
      /**
       * Tags
       */
      .addCase(getTags.fulfilled, (state, action: PayloadAction<TagModel[]>) => {
        state.tags = action.payload;
      })
      .addCase(getTags.rejected, (state) => {
        state.tags = initialState.tags;
        state.isError = true;
      })
      /**
       * Responses
       */
      .addCase(getResponses.fulfilled, (state, action: PayloadAction<ResponseTableDataModel[]>) => {
        state.responses = action.payload;
      })
      .addCase(getResponses.rejected, (state) => {
        state.responses = initialState.responses;
        state.isError = true;
      })
      /**
       * Entities
       */
      .addCase(getEntities.fulfilled, (state, action: PayloadAction<EntityTableDataModel[]>) => {
        state.entities = action.payload;
      })
      .addCase(getEntities.rejected, (state) => {
        state.entities = initialState.entities;
        state.isError = true;
      })
      /**
       * Synonyms
       */
      .addCase(getSynonyms.fulfilled, (state, action: PayloadAction<SynonymTableDataModel[]>) => {
        state.synonyms = action.payload;
      })
      .addCase(getSynonyms.rejected, (state) => {
        state.synonyms = initialState.synonyms;
        state.isError = true;
      })
      /**
       * Regex
       */
      .addCase(getRegex.fulfilled, (state, action: PayloadAction<RegexTableDataModel[]>) => {
        state.regex = action.payload;
      })
      .addCase(getRegex.rejected, (state) => {
        state.regex = initialState.regex;
        state.isError = true;
      })
      /**
       * Regex
       */
      .addCase(
        getEntityGroups.fulfilled,
        (state, action: PayloadAction<EntityGroupTableDataModel[]>) => {
          state.entityGroups = action.payload;
        }
      )
      .addCase(getEntityGroups.rejected, (state) => {
        state.entityGroups = initialState.entityGroups;
        state.isError = true;
      })
      /**
       * Lookup Tables
       */
      .addCase(
        getLookupTables.fulfilled,
        (state, action: PayloadAction<LookupTableDataModel[]>) => {
          state.lookupTables = action.payload;
        }
      )
      .addCase(getLookupTables.rejected, (state) => {
        state.lookupTables = initialState.lookupTables;
        state.isError = true;
      })
      /**
       * Topics
       */
      .addCase(getTopics.fulfilled, (state, action: PayloadAction<TopicTableDataModel[]>) => {
        state.topics = action.payload;
      })
      .addCase(getTopics.rejected, (state) => {
        state.topics = initialState.topics;
        state.isError = true;
      })
      /**
       * Languages
       */
      .addCase(getSupportedLanguages.fulfilled, (state, action: PayloadAction<LanguageModel[]>) => {
        state.supportedLanguages = action.payload;
      })
      .addCase(getSupportedLanguages.rejected, (state) => {
        state.supportedLanguages = initialState.supportedLanguages;
        state.isError = true;
      })
      /**
       * Metadata Filters
       */
      .addCase(
        getMetadataFilters.fulfilled,
        (state, action: PayloadAction<MetadataFilterModel[]>) => {
          state.metadataFilters = action.payload;
        }
      )
      .addCase(getMetadataFilters.rejected, (state) => {
        state.metadataFilters = initialState.metadataFilters;
        state.isError = true;
      });
  }
});

/* - - - - - - - - - - Selectors - - - - - - - - - - */

export const projectsSelector = (state: RootState) => state.projects;
export const intentsSelector = (state: RootState) => state.projects.intents;
export const sentimentsSelector = (state: RootState) => state.projects.sentiments;
export const categoriesSelector = (state: RootState) => state.projects.categories;
export const tagsSelector = (state: RootState) => state.projects.tags;
export const responsesSelector = (state: RootState) => state.projects.responses;
export const entitiesSelector = (state: RootState) => state.projects.entities;
export const synonymsSelector = (state: RootState) => state.projects.synonyms;
export const regexSelector = (state: RootState) => state.projects.regex;
export const lookupTableSelector = (state: RootState) => state.projects.lookupTables;
export const entityGroupsSelector = (state: RootState) => state.projects.entityGroups;
export const topicsSelector = (state: RootState) => state.projects.topics;
export const userGroupsSelector = (state: RootState) => state.projects.userGroups;
export const projectMembersSelector = (state: RootState) => state.projects.projectMembers;
export const usersSelector = (state: RootState) => state.projects.users;
export const filtersDirtySelector = (state: RootState) => state.projects.filtersDirty;
export const contentFiltersSelector = (state: RootState) => state.projects.contentFilters;

/* - - - - - - - - - - Actions - - - - - - - - - - */

export const {
  updateProject,
  exitProject,
  addProject,
  removeProject,
  selectProject,
  addIntent,
  editIntent,
  deleteIntent,
  addResponse,
  editResponse,
  deleteResponse,
  addCategory,
  editCategory,
  deleteCategory,
  addTag,
  editTag,
  deleteTag,
  addEntity,
  editEntity,
  deleteEntity,
  addSynonym,
  editSynonym,
  deleteSynonym,
  addRegex,
  editRegex,
  deleteRegex,
  addEntityGroup,
  editEntityGroup,
  deleteEntityGroup,
  addLookupTable,
  editLookupTable,
  deleteLookupTable,
  addTopic,
  editTopic,
  deleteTopic,
  addMetadataFilters,
  editMetadataFilters,
  deleteMetadataFilters,
  addUserGroup,
  editUserGroup,
  deleteUserGroup,
  dirtyFilters,
  updateContentFilters
} = projectsSlice.actions;

/* - - - - - - - - - - Thunks - - - - - - - - - - */

/**
 * Get projects and save result to store
 */
export const getProjects = createAsyncThunk('projects/getProjects', async (data, thunkAPI) => {
  try {
    return await ProjectsService.getProjects();
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

/**
 * Get project intents and save result to store
 */
export const getIntents = createAsyncThunk(
  'projects/getIntents',
  async (data: SlugQueryOptionsModel, thunkAPI) => {
    try {
      return await IntentsService.getIntents(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
 * Get project sentiments and save result to store
 */
export const getSentiments = createAsyncThunk(
  'projects/getSentiments',
  async (data: SlugOptionsModel, thunkAPI) => {
    try {
      return await SentimentsService.getSentiments(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
 * Get project categories and save result to store
 */
export const getCategories = createAsyncThunk(
  'projects/getCategories',
  async (data: SlugOptionsModel, thunkAPI) => {
    try {
      return await CategoriesService.getCategories(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
 * Get project tags and save result to store
 */
export const getTags = createAsyncThunk(
  'projects/getTags',
  async (data: SlugOptionsModel, thunkAPI) => {
    try {
      return await TagsService.getTags(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
 * Get project responses and save result to store
 */
export const getResponses = createAsyncThunk(
  'projects/getResponses',
  async (data: SlugQueryOptionsModel, thunkAPI) => {
    try {
      return await ResponsesService.getResponses(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
 * Get project entities and save result to store
 */
export const getEntities = createAsyncThunk(
  'projects/getEntities',
  async (data: SlugQueryOptionsModel, thunkAPI) => {
    try {
      return await EntitiesService.getEntities(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
 * Get project synonyms and save result to store
 */
export const getSynonyms = createAsyncThunk(
  'projects/getSynonyms',
  async (data: SlugOptionsModel, thunkAPI) => {
    try {
      return await SynonymsService.getSynonyms(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
 * Get project regex and save result to store
 */
export const getRegex = createAsyncThunk(
  'projects/getRegex',
  async (data: SlugOptionsModel, thunkAPI) => {
    try {
      return await RegexService.getAllRegex(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
 * Get project entity groups and save result to store
 */
export const getEntityGroups = createAsyncThunk(
  'projects/getEntityGroups',
  async (data: SlugOptionsModel, thunkAPI) => {
    try {
      return await EntityGroupsService.getEntityGroups(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
 * Get project lookup tables and save result to store
 */
export const getLookupTables = createAsyncThunk(
  'projects/getLookupTables',
  async (data: SlugOptionsModel, thunkAPI) => {
    try {
      return await LookupTableService.getLookupTables(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
 * Get project topics and save result to store
 */
export const getTopics = createAsyncThunk(
  'projects/getTopics',
  async (data: SlugQueryOptionsModel, thunkAPI) => {
    try {
      return await TopicsService.getTopics(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSupportedLanguages = createAsyncThunk(
  'projects/getSupportedLanguages',
  async (data, thunkAPI) => {
    try {
      return await LanguagesService.getLanguages();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
 * Get project metadata filters, which populate the Metadata filter options in the Message Inbox
 */
export const getMetadataFilters = createAsyncThunk(
  'projects/getMetadataFilters',
  async (data: SlugOptionsModel, thunkAPI) => {
    try {
      return await MetadataService.getMetadata(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
 * Get User Groups and save result to store
 */
export const getUserGroups = createAsyncThunk('projects/getUserGroups', async (data, thunkAPI) => {
  try {
    return await UserGroupsService.getUserGroups();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

/**
 * Get Project Members and save result to store
 */
export const getProjectMembersList = createAsyncThunk(
  'projects/getProjectMembers',
  async (data: SlugOptionsModel, thunkAPI) => {
    try {
      return await ProjectsService.getProjectMembers(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

/**
 * Get Users and save result to store
 */
export const getUsers = createAsyncThunk('projects/getUsers', async (data, thunkAPI) => {
  try {
    return await UsersService.getUsers();
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export default projectsSlice.reducer;
