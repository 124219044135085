import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import { uniqueId } from 'util/uniqueIdGenerator';

/**
 * Generic paragraph Cell
 * @param text text to display
 * @param maxLines max number of lines to display including ellipsis
 * @component
 */
const ParagraphCell = ({ maxLines = 4, text }: { maxLines?: number; text: string }) => {
  // Use number of new lines to determine if the text should be truncated
  const lines = text.split('\n');
  // ellipsis is counted towards the number of lines, so subtract 1
  const paragraph =
    lines.length > maxLines ? lines.slice(0, maxLines - 1).join('\n') + '\n...' : text;
  const id = uniqueId('topic-description');
  return (
    <span
      id={id}
      className="block overflow-hidden text-overflow-ellipsis"
      style={{ whiteSpace: 'pre', width: 'inherit' }}
    >
      {paragraph}
      <Tooltip target={`#${id}`} position="mouse" showDelay={500} content={text} />
    </span>
  );
};

export default ParagraphCell;
