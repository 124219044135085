import React from 'react';
// prime react
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem, MenuItemCommandEvent } from 'primereact/menuitem';
// models

const bulkActionItemsDefault: MenuItem[] = [
  {
    label: 'Export to CSV',
    command: (e: MenuItemCommandEvent) => {
      console.log(e);
    }
  }
];

/**
 * Component for table headers
 * @component
 * @param param0 Prop object containing the following:
 *
 *    allItems: a list of all the row items
 */
const TableHeader = function ({
  tableType,
  itemCount,
  bulkActionsMenuRef,
  bulkActionItems,
  menuClassName
}: {
  tableType?: string;
  itemCount?: number;
  bulkActionsMenuRef?: React.RefObject<any>;
  bulkActionItems?: MenuItem[];
  menuClassName?: string;
}) {
  return (
    <div className="flex justify-content-between align-content-center">
      <span className="flex align-self-center m-0">
        {`${tableType}` ?? ''} Count: {itemCount ?? ''}
      </span>
      {bulkActionsMenuRef && (
        <Menu
          model={bulkActionItems ?? bulkActionItemsDefault}
          popup
          ref={bulkActionsMenuRef}
          id="popup_menu"
          className={menuClassName ?? ''}
        />
      )}
      {bulkActionsMenuRef && (
        <Button
          label="Bulk Actions"
          icon="pi pi-chevron-down"
          iconPos="right"
          aria-controls="popup_menu"
          aria-haspopup
          onClick={(event) => bulkActionsMenuRef.current.toggle(event)}
        />
      )}
    </div>
  );
};

export default TableHeader;
