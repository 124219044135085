import { useEffect, useState } from 'react';

export enum MatchMode {
  EQUALS,
  NOT_EQUALS,
  INCLUDES,
  EXCLUDES,
  CUSTOM
}

type UseFilterOptions<T = any> = {
  items: T[];
  searchTextFields: string;
  filters?: Filters<T>;
  delay?: number;
};

type Filters<T = string> = {
  [K in keyof T]?: Filter;
};

/**
 * field: comma seperated list of fields that the filter should apply to,
 *        acts as an OR filter on these fields
 * value: value for the filter to compare against
 * matchMode: match mode
 * customFilterFunc: function to use if matchMode is custom
 */
interface Filter {
  value: any;
  matchMode: MatchMode;
  useSearchText?: boolean;
  customFilterFunc?: (itemValue: any, Filter: any) => boolean;
}

const useDataFilter = function <T = any>(options: UseFilterOptions<T>) {
  const [searchText, setSearchText] = useState('');
  const [textFilterValue, setTextFilterValue] = useState('');
  const [filters, setFilters] = useState<Filters<T>>(options?.filters || {});
  const delay = options?.delay ?? 1000;
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<T[]>([]);

  /**
   * Function that uses the filter information to perform a comparison against the value
   *
   * @param value value of the item to compare against
   * @param filter filter details
   * @returns whether the item fulfills the filter
   */
  const matchModeFilter = (value: any, filter: Filter) => {
    if (filter.value === undefined) {
      return true;
    }

    // Convert values to lower case if possible
    const v1 = Array.isArray(value)
      ? value.every((item) => typeof item === 'string')
        ? value.map((item) => item.toLowerCase())
        : value
      : typeof value === 'string'
        ? value.toLowerCase()
        : value;

    const v2 = typeof filter.value === 'string' ? filter.value.toLowerCase() : filter.value;

    switch (filter.matchMode) {
      case MatchMode.EQUALS: {
        return v1 === v2;
      }
      case MatchMode.NOT_EQUALS: {
        return v1 !== v2;
      }
      case MatchMode.INCLUDES: {
        return v1.includes(v2);
      }
      case MatchMode.EXCLUDES: {
        return !v1.includes(v2);
      }
      case MatchMode.CUSTOM: {
        return filter.customFilterFunc ? filter.customFilterFunc(v1, v2) : false;
      }
    }
  };

  /**
   * Separates the filter into fields and compares each filter to the value
   * Acts as OR between filters
   *
   * @param item item to compare against
   * @param fields fields of the item to compare against
   * @param filter value and match mode to compare against
   * @returns whether and of the fields of the item match the filter value
   */
  const matchesFilter = (item: T, fields: string, filter: Filter): boolean => {
    // empty string skips matching; return true
    if (filter?.value === '') {
      return true;
    }

    // fields can be comma seperated (with no space)
    const filterFields = fields.split(',');
    for (let filterFieldIndex = 0; filterFieldIndex < filterFields.length; filterFieldIndex++) {
      const matches = matchModeFilter(
        item[filterFields[filterFieldIndex] as keyof typeof item],
        filter
      );
      if (matches) return matches;
    }
    return false;
  };

  /**
   * Compares all filters against all items
   * @returns filtered list of items
   */
  const filterAll = () => {
    const itemFilters = Object.entries<any>(filters);
    return options.items.filter((item) => {
      for (let filterIndex = 0; filterIndex < itemFilters.length; filterIndex++) {
        const matches = matchesFilter(
          item,
          itemFilters[filterIndex][0],
          itemFilters[filterIndex][1]
        );
        if (!matches) return matches;
      }
      return true;
    });
  };

  /**
   * Adds or edits a filter
   * @param filterKey field to filter on
   * @param value value the field is being compared to
   * @param matchMode match mode
   */
  function addFilter(filterKey: string, value: any, matchMode: MatchMode) {
    setFilters({ ...filters, [filterKey]: { value: value, matchMode: matchMode } });
  }

  /**
   * Searches based on search text
   * @param value search value
   */
  function search(value: string) {
    // if (value === textFilterValue) return;
    setTextFilterValue(value);
  }

  function simpleSearch(text: string) {
    setSearchText(text);
  }

  useEffect(() => {
    textFilterValue.length > 0 && setLoading(true);
    // Wait until the user stops typing for one second
    // before performing search
    const debounceSearch = setTimeout(() => {
      setLoading(false);
      addFilter(options.searchTextFields, textFilterValue, MatchMode.INCLUDES);
    }, delay);

    // Clean up function
    return () => {
      clearTimeout(debounceSearch);
    };
  }, [textFilterValue]);

  // Update results whenever filter changes
  useEffect(() => {
    setResults(filterAll());
  }, [filters, options.items]);

  // Clears searchtext and resets filters to initial filters
  const resetFilters = () => {
    clearSearch();
    setFilters(options.filters || {});
  };

  const clearSearch = () => setSearchText('');

  return {
    addFilter,
    clearSearch,
    filters,
    loading,
    resetFilters,
    simpleSearch,
    results,
    search,
    searchText
  };
};

export default useDataFilter;
