import { useEffect, useState } from 'react';

const useToggleKeys = () => {
  type RowKey = string | number;
  const [expandedKeys, setExpandedKeys] = useState<Record<RowKey, boolean>>({});
  const [hasExpandedKeys, setHasExpandedKeys] = useState<boolean>(false);

  // check if key is expanded
  const isKeyExpanded = (key: RowKey): boolean => expandedKeys?.[key];

  // hasExpandedKeys updates when expandedKeys changes
  useEffect(() => {
    setHasExpandedKeys(Object.keys(expandedKeys).length > 0);
  }, [expandedKeys]);

  // clear all keys
  const clearKeys = () => setExpandedKeys({});

  // multiple key expansion
  const toggleKeyMulti = (key: RowKey) => {
    const expandedKeysCopy = { ...expandedKeys };

    if (isKeyExpanded(key)) {
      delete expandedKeysCopy[key];
    } else {
      expandedKeysCopy[key] = true;
    }
    setExpandedKeys(expandedKeysCopy);
  };

  // single row expansion
  const toggleKeySingle = (key: RowKey) => {
    setExpandedKeys(isKeyExpanded(key) ? {} : { [key]: true });
  };

  // remove key if exists
  const deleteKey = (key: RowKey) => {
    const expandedKeysCopy = { ...expandedKeys };
    delete expandedKeysCopy[key];
    setExpandedKeys(expandedKeysCopy);
  };

  return {
    keys: expandedKeys,
    toggleKeyMulti,
    toggleKeySingle,
    isKeyExpanded,
    deleteKey,
    clearKeys,
    hasExpandedKeys
  };
};

export default useToggleKeys;
