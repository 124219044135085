import React, { KeyboardEvent, SyntheticEvent } from 'react';

/**
 * Function that allows triggering of a function on keypress
 * @param e keyboard event
 */
export const onEnter = (e: KeyboardEvent) => (fn: (e?: any) => any) => {
  if (e.key === 'Enter') fn(e);
};

export const stopPropagation = (e: SyntheticEvent) => e?.stopPropagation?.();

/**
 * Block keyboard on load
 * @param e
 * @param loading
 */
export const blockKeyboardOnLoad = (e: React.KeyboardEvent<HTMLDivElement>, loading?: boolean) => {
  if (loading) {
    e.preventDefault();
    e.stopPropagation();
  }
};
