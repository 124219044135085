import React from 'react';
import { Control, Controller } from 'react-hook-form';
import classnames from 'classnames';
import { Calendar, CalendarChangeEvent, CalendarProps } from 'primereact/calendar';

/**
 * Component that displays the tag's field for the create response form.
 * @param className class name
 * @param control form Control
 * @param fieldName field name
 * @param label form label
 * @param labelClassName label class name
 * @param onChange onChange callback
 * @param placeholder input placeholder
 * @param selectionMode calendar selection mode: single or double
 * @constructor
 */
const CalendarField = function ({
  className,
  control,
  fieldName = 'date',
  label,
  labelClassName,
  onChange,
  placeholder = 'Select a date range',
  selectionMode = 'range'
}: {
  className?: string;
  control: Control;
  fieldName?: string;
  filterPlaceholder?: string;
  label?: string;
  labelClassName?: string;
  noPadding?: boolean;
  onChange?(e: CalendarChangeEvent): void;
  placeholder?: string;
  selectionMode?: CalendarProps['selectionMode'];
}) {
  return (
    <div className={className}>
      <label htmlFor={fieldName + 'input'} className={classnames(labelClassName)}>
        {label}
      </label>
      <Controller
        name={fieldName}
        control={control}
        render={({ field }) => (
          <Calendar
            id={field.name}
            inputId={field.name + 'input'}
            value={field.value}
            className="w-full specto-input-number"
            placeholder={placeholder}
            onChange={(e) => {
              field.onChange(e.value);
              onChange?.(e);
            }}
            selectionMode={selectionMode}
            maxDate={new Date()}
            hourFormat="12"
            showIcon
            readOnlyInput
            data-cy={field.name}
          />
        )}
      />
    </div>
  );
};

export default CalendarField;
