import React from 'react';
import { Control, Controller } from 'react-hook-form';
import classnames from 'classnames';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { uniqueId } from 'lodash';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';

/**
 * Component that displays the tag's field for the create response form.
 * @param control form control
 * @param onChange function to call when the date is changed
 * @param label the title of the field
 * @param labelClassName classnames that affect the label
 * @param placeholder placeholder for the input
 * @param className classNames that affect the input
 * @param fieldName name of the field
 * @param minDate the earliest date that can be selected
 * @param maxDate the latest date that can be selected
 * @param readOnly whether can change input with keyboard or not
 * @component
 */
const DatePickerField = function ({
  control,
  onChange,
  label,
  labelClassName,
  placeholder = 'Select a date range',
  className,
  fieldName = 'date',
  minDate,
  maxDate,
  readOnly = false
}: {
  control: Control;
  className?: string;
  fieldName?: string;
  label?: string;
  labelClassName?: string;
  onChange?(e: Date): void;
  placeholder?: string;
  minDate?: DateTime;
  maxDate?: DateTime;
  readOnly?: boolean;
}) {
  const id = uniqueId('calendar');
  const currentDate = DateTime.fromJSDate(new Date());
  const minDateValue = minDate ? minDate : null;
  const maxDateValue = maxDate ? maxDate : currentDate;
  return (
    <div className={className}>
      <label htmlFor={id} className={classnames(labelClassName)}>
        {label}
      </label>
      <Controller
        name={fieldName}
        control={control}
        render={({ field }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                className="w-full datepicker-field material-field"
                slotProps={{ textField: { placeholder, id }, field: { readOnly } }}
                value={field.value ? DateTime.fromJSDate(field.value) : field.value}
                onChange={(e) => {
                  if (e && e.invalid === null) {
                    field.onChange(e.toJSDate());
                    onChange?.(e.toJSDate());
                  }
                }}
                data-cy={field.name}
                maxDate={maxDateValue}
                minDate={minDateValue}
              />
            </LocalizationProvider>
          );
        }}
      />
    </div>
  );
};

export default DatePickerField;
