import React, { CSSProperties } from 'react';
import { Control, Controller } from 'react-hook-form';
import classNames from 'classnames';
import classnames from 'classnames';
import { getFormErrorMessage, oneSpaceFormatter } from 'util/form';
import { KeyFilterType } from 'primereact/keyfilter';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { MultiSelect, MultiSelectChangeEvent, MultiSelectProps } from 'primereact/multiselect';
import Info from './Info';

/**
 * Component that displays a basic text field in a form.
 * @component
 * @param param0 Prop object containing the following:

 *    characterLimit: character limit for the input
 *    className: custom class name
 *    control: react-hook-form control
 *    defaultValue: default value for MultiSelect
 *    disabled: whether the input is disabled or not
 *    editable: if the MultiSelect selection is editable
 *    fieldName: the field name in react-hook-form
 *    filter: enable/disable primeReact filter option (to allow MultiSelect to be searched)
 *    filterBy: what the filter should search by
 *    formState: react-hook-form form state
 *    label: label for the input
 *    labelClassName: class name for cus
 *    noPadding: errorBoundary adds 20px margin when no error occurs so that when an error happens the UI doesn't move
 *    onChange: optional onChange function
 *    optionGroupChildren: what property of the options contains the groups children
 *    optionGroupLabel: what property of the options contains the groups label
 *    optionLabel: what property of the options contains the label
 *    optionValue: what property of the options contains the value
 *    options: MultiSelect options
 *    placeholder: MultiSelect placeholder
 *    readOnly: for readonly input
 *    required: if the MultiSelect is required
 *    style: CSS style
 *    tooltip: tooltip for the input
 */
const MultiSelectField = function ({
  appendTo = 'self',
  characterLimit = 200,
  className,
  control,
  defaultValue = '',
  disabled,
  display = 'comma',
  fieldName,
  filter,
  filterBy,
  filterPlaceholder,
  info,
  itemTemplate,
  label,
  labelClassName,
  maxSelectedLabels,
  noPadding,
  onChange,
  optionDisabled,
  optionGroupChildren,
  optionGroupLabel,
  optionLabel,
  optionValue,
  options,
  placeholder,
  readOnly,
  required = true,
  showSelectAll = false,
  style,
  tooltip
}: {
  appendTo?: MultiSelectProps['appendTo'];
  characterLimit?: number;
  className?: string;
  control: Control;
  defaultValue?: any;
  disabled?: boolean;
  display?: 'comma' | 'chip';
  editable?: boolean;
  fieldName: string;
  filter?: boolean;
  filterBy?: string;
  filterPlaceholder?: string;
  info?: string;
  itemTemplate?: MultiSelectProps['itemTemplate'];
  keyFilter?: KeyFilterType;
  label?: string;
  labelClassName?: string;
  maxSelectedLabels?: number;
  noPadding?: boolean;
  onChange?(e: MultiSelectChangeEvent): void;
  optionDisabled?: MultiSelectProps['optionDisabled'];
  optionGroupChildren?: string;
  optionGroupLabel?: string;
  optionLabel?: string;
  optionValue?: string;
  options: SelectItemOptionsType;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  showSelectAll?: boolean;
  style?: CSSProperties;
  tooltip?: string;
}) {
  return (
    <div className={classnames(className, { 'field pb-3': !noPadding })} style={style}>
      <div className="flex">
        {label && (
          <label htmlFor={fieldName + 'input'} className={classnames(labelClassName)}>
            {label} {required && <span className="specto-form-asterisk-color">*</span>}
          </label>
        )}
        {info && <Info text={info} />}
      </div>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        rules={{
          ...(required && { required: 'Required field' }),
          maxLength: {
            value: characterLimit,
            message: `Exceeded max length of ${characterLimit} characters`
          }
        }}
        render={({ field, fieldState }) => (
          <>
            <MultiSelect
              {...field}
              id={field.name}
              inputId={field.name + 'input'}
              value={field?.value}
              placeholder={placeholder}
              onChange={(e) => {
                field.onChange(oneSpaceFormatter(e.value));
                onChange?.(e);
              }}
              options={options}
              removeIcon="pi pi-times text-xs font-bold"
              optionDisabled={optionDisabled}
              itemTemplate={itemTemplate}
              filterPlaceholder={filterPlaceholder}
              optionLabel={optionLabel}
              optionValue={optionValue}
              disabled={disabled}
              maxSelectedLabels={maxSelectedLabels}
              filter={filter}
              ref={null}
              filterBy={filterBy}
              optionGroupLabel={optionGroupLabel}
              optionGroupChildren={optionGroupChildren}
              className={classNames('specto-input-select', {
                'p-invalid': fieldState.error
              })}
              readOnly={readOnly}
              appendTo={appendTo}
              aria-invalid={fieldState?.error ? 'true' : 'false'}
              display={display}
              showSelectAll={showSelectAll}
              tooltip={tooltip}
              tooltipOptions={{ position: 'bottom' }}
              data-cy={field.name}
            />
            {getFormErrorMessage(fieldState.error, noPadding)}
          </>
        )}
      />
    </div>
  );
};

export default MultiSelectField;
