import { SlugQueryOptionsModel } from 'models/api-model';
import ApiService from './apiService';
import { PaginationModel } from 'models/filter-model';
import { EVENTS_CONVERSATIONS_API_URL } from 'constants/api';
import { ConversationDataModel } from 'models/events-model';
import { OPEN_VIEW_ADMIN_MODIFY_PERMISSIONS } from 'constants/roles';

const getPaginatedConversations = (options: SlugQueryOptionsModel) =>
  ApiService.get<PaginationModel<ConversationDataModel>>(
    '/' + options.slug + EVENTS_CONVERSATIONS_API_URL + (options.query ?? '')
  );

const EventsService = {
  getPaginatedConversations,
  roles: OPEN_VIEW_ADMIN_MODIFY_PERMISSIONS
};

export default EventsService;
