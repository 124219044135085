import React, { useEffect, useMemo, useState } from 'react';
// prime react
import { Button } from 'primereact/button';
// components
import SearchBar from 'components/SearchBar';
import TopicDataTable from './components/TopicDataTable';
import TopicPopup from './components/TopicPopup';
import TopicFilterMenu from './components/TopicFilterMenu';
// constants
import { FetchStatusOptions } from 'constants/fetchStatus';
// models
import { TopicTableAPIModel, TopicTableDataModel } from 'models/topic-model';
// hooks
import { useAppDispatch, useAppSelector } from 'hooks/store';
import useFetch from 'hooks/useFetch';
// services
import TopicsService from 'services/topicsService';
// selectors
import { setLayout } from 'features/layout/layoutSlice';
import {
  addTopic as addTopicState,
  deleteTopic as deleteTopicState,
  editTopic as editTopicState,
  entitiesSelector,
  intentsSelector,
  projectMembersSelector,
  projectsSelector,
  responsesSelector,
  topicsSelector,
  userGroupsSelector,
  usersSelector,
  dirtyFilters,
  filtersDirtySelector,
  contentFiltersSelector,
  updateContentFilters
} from 'features/projects/projectsSlice';
import { SlugDataIdOptionsModel, SlugDataOptionsModel, SlugIdOptionsModel } from 'models/api-model';
import { versionsSelector } from 'features/versions/versionsSlice';
import { createToast } from 'features/toast/toastSlice';
// utils
import { FilterChips } from 'components/FilterChips/FilterChips';
import { FieldValues, useForm } from 'react-hook-form';
import { ThreadStateChangeEvent } from 'pages/Comments/CommentPopup';
import { copyNameFormatter } from 'util/uniqueIdGenerator';
import { FormMode } from 'models/form-model';
import useQuery from 'hooks/useQuery';
import { FilterChip } from 'models/filter-chips';
import { humanReadableDateTimeFormatted } from 'util/dates';
import { isAdmin, permissionBoolean } from 'util/permissions';
import CommentsService from 'services/commentsService';
import { getMainLayout } from 'util/layout';
import { PublishState, Status } from 'models/status-model';
import { statusDisplayNames } from 'constants/status';
import { mapIdToName } from '../../util/mapIdAndName';
import { isArrayAndIncludes, sortbyProperty } from '../../util/arrays';
import { filterRetired } from '../../util/status';
import { DataTableStateEvent } from 'primereact/datatable';
import { getDefaultTableState, mapTableStateToPagination } from 'util/table';
import { getUserGroupIds } from 'util/userGroups';
import { authSelector } from 'features/auth/authSlice';
import {
  filterAuthors,
  findProjectMember,
  mapSuperUsersToAuthors,
  mapUsersToProjectMembers
} from 'util/users';
import { ProjectMemberUserModel } from 'models/project-model';
import { useSearchParams } from 'react-router-dom';
import { parseJson } from 'util/query';
import TopicHistoryPopup from './components/TopicHistoryPopup';
import {
  getPersistedFilters,
  populateFilterMenu,
  buildMultipleChips,
  getAppliedFilters
} from 'util/filters';

const Topic = () => {
  const dispatch = useAppDispatch();
  const { selectedProject, projects } = useAppSelector(projectsSelector);
  const { isVersionSelected } = useAppSelector(versionsSelector);
  const contentFilters = useAppSelector(contentFiltersSelector);
  const allTopics = useAppSelector(topicsSelector);
  const allIntents = useAppSelector(intentsSelector);
  const allResponses = useAppSelector(responsesSelector);
  const allEntities = useAppSelector(entitiesSelector);
  const allUserGroups = useAppSelector(userGroupsSelector);
  const allProjectMembers = useAppSelector(projectMembersSelector);
  const allUsers = useAppSelector(usersSelector);
  const { user } = useAppSelector(authSelector);
  const filtersDirty = useAppSelector(filtersDirtySelector);
  const [contextTopic, setContextTopic] = useState<TopicTableDataModel | null>(null);
  const [popupMode, setPopupMode] = useState<FormMode>(FormMode.CREATE);
  const [displayNewTopicPopup, setDisplayNewTopicPopup] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [tablestate, setTableState] = useState<DataTableStateEvent>(getDefaultTableState());
  const validQueryParams = [
    'status',
    'author',
    'intents',
    'responses',
    'entities',
    'user_groups',
    'active',
    'published_lte'
  ];
  const [searchParams] = useSearchParams();

  const filterInitialValues = useMemo(() => {
    const params: { [key: string]: any } = {};
    if (searchParams.size === 0) {
      // When the page is first loaded, User Group filters may already be applied based on the Project Member
      // If the user clicked on clear button on any page, then do not set the user groups
      // filtersDirty checks if the user modified the filters or if the filters were pre applied from the store
      if (!filtersDirty) {
        params.user_groups = isAdmin(selectedProject.project_user.role)
          ? []
          : selectedProject.project_user.user_groups;
      }
      getPersistedFilters(params, contentFilters);
    } else {
      searchParams.forEach((value, key) => {
        if ([...validQueryParams, 'search'].includes(key)) {
          params[key] = parseJson(value);
        }
      });
      // Clear query params after consuming
      window.history.replaceState(null, '', window.location.pathname);
    }
    return params;
  }, [searchParams]);

  // Find admins from users and convert them to the same object as author
  const superUserAuthors: ProjectMemberUserModel[] = mapSuperUsersToAuthors(
    allUsers.filter((u) => u.is_superuser),
    allUserGroups.map((ug) => ug.id)
  );

  // Map users data to fit authors data structure
  const authors: ProjectMemberUserModel[] = mapUsersToProjectMembers(allUsers, allProjectMembers);

  // List of all authors including super uers
  const allAuthors: ProjectMemberUserModel[] = sortbyProperty(
    authors.concat(superUserAuthors),
    'name_reversed'
  );

  // Get currently logged in project user as a project user
  const ownProjectUser = findProjectMember(allAuthors, user) as ProjectMemberUserModel;

  /* - - - - - - - - - - Init - - - - - - - - - - */
  useEffect(() => {
    dispatch(setLayout(getMainLayout(selectedProject.project_user.role, projects.length)));
    if (searchParams.size === 0) {
      if (!filtersDirty) {
        // Initial value of the filter form for user_groups is different than the default value
        setFilterField(
          'user_groups',
          isAdmin(selectedProject.project_user.role) ? [] : selectedProject.project_user.user_groups
        );
      }
    } else {
      searchParams.forEach((value, key) => {
        if (validQueryParams.includes(key)) {
          setFilterField(key, parseJson(value));
        }
      });
    }

    populateFilterMenu(validQueryParams, filterInitialValues, setFilterField, Status, PublishState);
  }, []);

  /* - - - - - - - - - - Get All Topics - - - - - - - - - - */
  const {
    fetch: getTopics,
    data: getTopicsData,
    fetchStatus: getTopicsStatus
  } = useFetch(TopicsService.getPaginatedTopics, TopicsService.roles.retrieve);

  function getFilteredTopics() {
    getTopics({ slug: selectedProject.slug, query: filterQueryString });
  }

  /* - - - - - - - - - - Get Topic - - - - - - - - - - */

  const {
    fetch: getTopic,
    data: getTopicData,
    fetchStatus: getTopicStatus
  } = useFetch(TopicsService.getTopic, TopicsService.roles.list);

  // update state with modified state after onThreadStateChange event
  useEffect(() => {
    if (getTopicStatus === FetchStatusOptions.SUCCESS && getTopicData) {
      dispatch(editTopicState(getTopicData));
      getFilteredTopics();
    }
  }, [getTopicStatus]);

  /* - - - - - - - - - - Create Topic - - - - - - - - - - */
  const {
    fetch: createTopic,
    data: createTopicData,
    fetchStatus: createTopicStatus,
    permission: canCreateTopic
  } = useFetch<SlugDataOptionsModel<TopicTableAPIModel>, TopicTableDataModel>(
    TopicsService.createTopic,
    TopicsService.roles.create
  );

  useEffect(() => {
    if (createTopicStatus === FetchStatusOptions.SUCCESS && createTopicData) {
      dispatch(addTopicState(createTopicData));
      getFilteredTopics();

      dispatch(createToast('topic created'));
      hidePopup();
    }
  }, [createTopicStatus]);

  /* - - - - - - - - - - Edit Topic - - - - - - - - - - */
  const {
    fetch: editTopic,
    data: editTopicData,
    fetchStatus: editTopicStatus,
    permission: canEditTopic
  } = useFetch<SlugDataIdOptionsModel<TopicTableAPIModel>, TopicTableDataModel>(
    TopicsService.editTopic,
    TopicsService.roles.update
  );

  useEffect(() => {
    updateTopic(editTopicData, editTopicStatus);
  }, [editTopicStatus]);

  const updateTopic = (
    data: TopicTableDataModel | undefined,
    fetchStatus: FetchStatusOptions,
    toastMessage?: string
  ) => {
    if (fetchStatus === FetchStatusOptions.SUCCESS && data) {
      dispatch(editTopicState(data));
      getFilteredTopics();

      hidePopup();
      dispatch(createToast(toastMessage ?? 'topic edited'));
    }
  };

  /* - - - - - - - - - - Retire Topic - - - - - - - - - - */
  const {
    fetch: retireTopic,
    data: retireTopicData,
    fetchStatus: retireTopicStatus
  } = useFetch(TopicsService.retireTopic, TopicsService.roles.update);

  useEffect(() => {
    updateTopic(retireTopicData, retireTopicStatus);
  }, [retireTopicStatus]);

  const onRetireChange = (node: TopicTableDataModel, isRetired: boolean) =>
    retireTopic({
      slug: selectedProject.slug,
      id: node.id,
      retired: {
        retired: isRetired
      }
    });

  /* - - - - - - - - - - Delete Topic - - - - - - - - - - */
  const {
    fetch: deleteTopic,
    fetchStatus: topicDeleteStatus,
    fetchOptions: deleteTopicOptions,
    permission: canDeleteTopic
  } = useFetch<SlugIdOptionsModel<TopicTableDataModel['id']>, TopicTableDataModel>(
    TopicsService.deleteTopic,
    TopicsService.roles.delete
  );

  useEffect(() => {
    if (topicDeleteStatus === FetchStatusOptions.SUCCESS && deleteTopicOptions) {
      dispatch(deleteTopicState(deleteTopicOptions.id));
      getFilteredTopics();

      dispatch(createToast('Topic deleted'));
    }
  }, [topicDeleteStatus]);

  const deleteTableTopic = (node: TopicTableDataModel) =>
    deleteTopic({
      slug: selectedProject.slug,
      id: node.id
    });

  /* - - - - - - - - - - Duplicate Topic - - - - - - - - - - */

  /**
   * Creates a duplicate of a node
   * @param node node to copy
   */
  const duplicateTopic = (node: TopicTableDataModel) => {
    const name = copyNameFormatter({
      name: node.name,
      nameList: allTopics.map((topic) => topic.name)
    });
    handlePopupDisplayChange(true, FormMode.DUPLICATE, {
      ...node,
      name,
      user_groups: [],
      reference: undefined
    });
  };

  /* - - - - - - - - - - Publish Topic - - - - - - - - - - */

  const {
    fetch: publishTopic,
    data: publishTopicData,
    fetchStatus: publishTopicStatus
  } = useFetch(TopicsService.publishTopic, TopicsService.roles.update);

  useEffect(() => {
    updateTopic(publishTopicData, publishTopicStatus, 'topic published');
  }, [publishTopicStatus]);

  const onPublishTopic = (node: TopicTableDataModel) =>
    publishTopic({
      slug: selectedProject.slug,
      id: node.id
    });

  /* - - - - - - - - - - Approve Topic - - - - - - - - - - */

  const {
    fetch: approveTopic,
    data: approveTopicData,
    fetchStatus: approveTopicStatus
  } = useFetch(TopicsService.approveTopic, TopicsService.roles.update);

  useEffect(() => {
    updateTopic(approveTopicData, approveTopicStatus, 'topic approved');
  }, [approveTopicStatus]);

  const onApproveTopic = (node: TopicTableDataModel) =>
    approveTopic({
      slug: selectedProject.slug,
      id: node.id
    });

  /* - - - - - - - - - - Popup - - - - - - - - - - */

  /**
   * Callback to change the new topic pop-up visibility in state.
   * @param visibility boolean representing the visibility of the new topic popup
   * @param context the context topic, used for editing
   */
  const handlePopupDisplayChange = (
    visibility: boolean,
    mode = FormMode.CREATE,
    context: TopicTableDataModel | null = null
  ) => {
    setPopupMode(mode);
    setContextTopic(context);
    setDisplayNewTopicPopup(visibility);
  };

  const onSubmit = (data: FieldValues) => {
    data.user_groups = getUserGroupIds(data.user_groups);
    const tableData = data as TopicTableAPIModel;

    if (contextTopic && popupMode === FormMode.EDIT) {
      editTopic({
        slug: selectedProject.slug,
        id: contextTopic.id,
        data: tableData
      });
    } else {
      createTopic({ slug: selectedProject.slug, data: tableData });
      // set the filter to show proposed after creating a new object
      setFilterField('state', PublishState.Proposed);
      setFilterQueryParameter({ active: false, status: Status.Not_Retired });
    }
  };

  const hidePopup = () => handlePopupDisplayChange(false);

  /* - - - - - - - - - - Query - - - - - - - - - - */

  const topicsFilterDefaultValues: FieldValues = {
    state: PublishState.Active,
    published_lte: null,
    intents: [],
    responses: [],
    entities: [],
    user_groups: [],
    author: []
  };

  const {
    control: filterControl,
    reset: resetFilterForm,
    resetField: resetFilterField,
    getValues: getFilterField,
    setValue: setFilterField,
    formState: {
      dirtyFields: dirtyFilterFields,
      isDirty: isFilterFormDirty,
      defaultValues: filterDefaultValues
    }
  } = useForm({ defaultValues: topicsFilterDefaultValues, mode: 'onChange' });

  const {
    search,
    searchText,
    clearSearch,
    simpleSearch,
    setQueryParameter: setFilterQueryParameter,
    queryString: filterQueryString,
    queryParameters: filterQueryParameters,
    clearAllQueryParameters,
    clearQueryParameter
  } = useQuery({
    query: onQuery,
    deps: [dirtyFilterFields],
    defaultValues: {
      ...mapTableStateToPagination(tablestate),
      active: true,
      status: Status.Not_Retired
    },
    initialValues: filterInitialValues
  });

  // dispatch dirtyFilters action when filter form is dirty
  // sets filterDirty to true in redux store
  useEffect(() => {
    if (isFilterFormDirty) {
      dispatch(dirtyFilters());
    }
  }, [isFilterFormDirty]);

  // query on filterQueryString change.
  // *** also fires onInit, so it replaces default getMessages req
  // this also updates the filters in redux store
  useEffect(() => {
    // getTopic is already done when on activateProject. Needs to happen here again to offload filtering (such as retired) to backend
    getFilteredTopics();

    const appliedFilters = getAppliedFilters(validQueryParams, filterQueryParameters);
    dispatch(updateContentFilters(appliedFilters));
  }, [filterQueryString]);

  // perform back end query using the search
  function onQuery(queryText: string) {
    // set query parameters which will build a queryParameter string
    setFilterQueryParameter({ search: queryText });
  }

  const resetFilter = () => {
    clearSearch();
    // User groups should be set to an empty array instead of the default value
    clearAllQueryParameters({ user_groups: [] });
    resetFilterForm();
  };

  useEffect(() => setFilterQueryParameter(mapTableStateToPagination(tablestate)), [tablestate]);

  /* - - - - - - - - - - Filter Chips - - - - - - - - - - */

  const publishedBeforeFilterEnabled = 'published_lte' in filterQueryParameters;
  const activeFilterEnabled =
    filterQueryParameters?.active === true && filterQueryParameters?.status === Status.Not_Retired;
  const proposedFilterEnabled =
    filterQueryParameters?.active === false && filterQueryParameters?.status === Status.Not_Retired;
  const retiredFilterEnabled = filterQueryParameters?.status === Status.Retired;

  const filterChips: FilterChip[] = [
    {
      name: `State: Retired`,
      removeFn: () => {
        clearQueryParameter('status', 'active');
        resetFilterField('state');
      },
      enabled: retiredFilterEnabled
    },
    {
      name: `State: Proposed`,
      removeFn: () => {
        clearQueryParameter('status', 'active');
        setFilterField('state', topicsFilterDefaultValues['state'], { shouldDirty: true });
      },
      enabled: proposedFilterEnabled
    },
    {
      name: `State: ${statusDisplayNames[Status.Not_Retired]}`,
      removeFn: () => {
        clearQueryParameter('status', 'active');
        setFilterField('state', topicsFilterDefaultValues['state'], { shouldDirty: true });
      },
      enabled: activeFilterEnabled,
      removable: false
    },
    {
      name: `Published on or Before: ${humanReadableDateTimeFormatted(
        String(filterQueryParameters.published_lte)
      )}`,
      removeFn: () => {
        clearQueryParameter('published_lte');
        setFilterField('published_lte', topicsFilterDefaultValues['published_lte'], {
          shouldDirty: true
        });
      },
      enabled: publishedBeforeFilterEnabled
    },
    ...buildMultipleChips(filterQueryParameters.intents, (intentId: number) => ({
      name: `Intent: ${mapIdToName(intentId, allIntents)}`,
      removeFn: () => {
        const intents: number[] = getFilterField('intents');
        const filteredIntents = intents.filter((id) => id !== intentId);

        if (filteredIntents.length === 0) {
          clearQueryParameter('intents');
          setFilterField('intents', topicsFilterDefaultValues['intents'], { shouldDirty: true });
        } else {
          setFilterField('intents', filteredIntents);
          setFilterQueryParameter({ intents: filteredIntents });
        }
      },
      enabled: isArrayAndIncludes(filterQueryParameters.intents, intentId)
    })),
    ...buildMultipleChips(filterQueryParameters.responses, (responseId: number) => ({
      name: `Response: ${mapIdToName(responseId, allResponses)}`,
      removeFn: () => {
        const responses: number[] = getFilterField('responses');
        const filteredResponses = responses.filter((id) => id !== responseId);

        if (filteredResponses.length === 0) {
          clearQueryParameter('responses');
          setFilterField('responses', topicsFilterDefaultValues['responses'], {
            shouldDirty: true
          });
        } else {
          setFilterField('responses', filteredResponses);
          setFilterQueryParameter({ responses: filteredResponses });
        }
      },
      enabled: isArrayAndIncludes(filterQueryParameters.responses, responseId)
    })),
    ...buildMultipleChips(filterQueryParameters.entities, (entityId: number) => ({
      name: `Entity: ${mapIdToName(entityId, allEntities)}`,
      removeFn: () => {
        const entities: number[] = getFilterField('entities');
        const filteredEntities = entities.filter((id) => id !== entityId);

        if (filteredEntities.length === 0) {
          clearQueryParameter('entities');
          setFilterField('entities', topicsFilterDefaultValues['entities'], { shouldDirty: true });
        } else {
          setFilterField('entities', filteredEntities);
          setFilterQueryParameter({ entities: filteredEntities });
        }
      },
      enabled: isArrayAndIncludes(filterQueryParameters.entities, entityId)
    })),
    ...buildMultipleChips(filterQueryParameters.user_groups, (userGroup) => ({
      name: `User Group: ${userGroup === 0 ? 'None' : mapIdToName(userGroup, allUserGroups)}`,
      removeFn: () => {
        const updatedUserGroups = (filterQueryParameters.user_groups as number[]).filter(
          (group) => group !== userGroup
        );
        setFilterQueryParameter({
          user_groups: updatedUserGroups
        });
        setFilterField('user_groups', updatedUserGroups, {
          shouldDirty: true
        });
      },
      enabled: true,
      removable: true
    })),
    ...buildMultipleChips(filterQueryParameters.author, (userId: number) => ({
      name: `Author: ${mapIdToName(userId, allAuthors, 'user')}`,
      removeFn: () => {
        const authors: number[] = getFilterField('author');
        const filteredAuthors = authors.filter((id) => id !== userId);

        if (filteredAuthors.length === 0) {
          clearQueryParameter('author');
          setFilterField('author', topicsFilterDefaultValues['author'], { shouldDirty: true });
        } else {
          setFilterField('author', filteredAuthors);
          setFilterQueryParameter({ author: filteredAuthors });
        }
      },
      enabled: isArrayAndIncludes(filterQueryParameters.author, userId)
    }))
  ];

  const filterDeps = [filterChips];

  /* - - - - - - - - - - Comments - - - - - - - - - - */

  const onThreadChange = (options: ThreadStateChangeEvent) => getTopic(options);

  /* - - - - - - - - - - History - - - - - - - - - - */

  const onHistoryOpen = (node: TopicTableDataModel) => {
    setContextTopic(node);
    setShowHistory(true);
  };

  const onHistoryClose = () => {
    setShowHistory(false);
    setContextTopic(null);
  };

  return (
    <div className="specto-topic">
      {displayNewTopicPopup && (
        <TopicPopup
          intents={filterRetired(allIntents, filterDefaultValues?.intents)}
          responses={allResponses}
          entities={allEntities}
          displayPopup={displayNewTopicPopup}
          onHide={hidePopup}
          onSubmit={onSubmit}
          contextTopic={contextTopic}
          mode={popupMode}
          loading={
            createTopicStatus === FetchStatusOptions.LOADING ||
            editTopicStatus === FetchStatusOptions.LOADING
          }
          parentElement={document.activeElement as HTMLElement}
          isSuperUser={user.is_superuser}
          userGroups={allUserGroups}
          projectUser={ownProjectUser}
          projectMemberOptions={allAuthors}
        />
      )}
      <div className="grid grid-nogutter">
        <div className="col flex flex-wrap xl:align-items-center align-items-start justify-content-between flex-column-reverse xl:flex-row">
          <SearchBar
            filterMenu={
              <TopicFilterMenu
                loading={getTopicsStatus === FetchStatusOptions.LOADING}
                intents={allIntents}
                responses={allResponses}
                entities={allEntities}
                userGroups={allUserGroups}
                authors={filterAuthors(user.is_superuser, allAuthors, ownProjectUser?.user)}
                projectUser={ownProjectUser}
                showEntities={user.is_superuser}
                control={filterControl}
                disableState={publishedBeforeFilterEnabled}
                onFormValueChange={(fieldValue) => {
                  if ('published_lte' in fieldValue) {
                    resetFilterField('status');
                    fieldValue['status'] = undefined;
                    fieldValue['active'] = true;
                  }
                  setFilterQueryParameter(fieldValue);
                }}
              />
            }
            placeHolder="Search by Name or Description..."
            className="specto-search-bar w-12 xl:w-8 mt-3 xl:mt-0"
            text={searchText}
            onFilterClearClick={() => resetFilter()}
            hideFilterClear={!isFilterFormDirty}
            onChange={(e) => simpleSearch(e.target.value)}
            onSubmitText={search}
            onClearText={() => {
              clearSearch();
              search('');
            }}
          />

          <Button
            label="New Topic"
            raised
            className="align-self-end xl:align-self-center"
            visible={!isVersionSelected && canCreateTopic}
            icon="pi pi-plus"
            iconPos="right"
            onClick={() => handlePopupDisplayChange(true)}
          />
        </div>

        <div className="col-12 mb-4 pt-2">
          <FilterChips filterChips={filterChips} deps={filterDeps} className="lg:w-7" />
        </div>

        <div className="col-12">
          <TopicDataTable
            topics={getTopicsData?.results ?? []}
            allIntents={allIntents}
            allResponses={allResponses}
            allUserGroups={allUserGroups}
            allEntities={allEntities}
            onThreadStateChange={onThreadChange}
            onEditInit={(topic) => handlePopupDisplayChange(true, FormMode.EDIT, topic)}
            onHistoryOpen={onHistoryOpen}
            onDuplicateTopic={duplicateTopic}
            onPublishTopic={onPublishTopic}
            onApproveTopic={onApproveTopic}
            onRetireChange={onRetireChange}
            onDeleteTopic={deleteTableTopic}
            readOnly={!canEditTopic || publishedBeforeFilterEnabled}
            canDelete={canDeleteTopic}
            showComments={permissionBoolean(
              CommentsService.roles.general.list,
              selectedProject.project_user.role
            )}
            isSuperUser={user.is_superuser}
            isAdmin={isAdmin(selectedProject.project_user.role)}
            tablestate={tablestate}
            onTableStateChange={setTableState}
            totalRecords={getTopicsData?.count ?? 0}
            loading={getTopicsStatus === FetchStatusOptions.LOADING}
            authors={allAuthors}
            projectUser={ownProjectUser}
          />
        </div>
        {contextTopic ? (
          <TopicHistoryPopup
            allEntities={allEntities}
            allIntents={allIntents}
            allResponses={allResponses}
            allUserGroups={allUserGroups}
            authors={allAuthors}
            isSuperUser={user.is_superuser}
            node={contextTopic}
            onHide={onHistoryClose}
            visible={showHistory}
            parentElement={document.activeElement as HTMLElement}
          />
        ) : undefined}
      </div>
    </div>
  );
};

export default Topic;
