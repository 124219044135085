import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { routes } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { setLayout } from 'features/layout/layoutSlice';
import { useAppDispatch } from 'hooks/store';
import { NO_LAYOUT } from 'constants/layouts';

type ErrorPageProps = {
  title?: string;
  message?: string;
};

const ErrorPage = ({
  title = 'An Unexpected Error Occurred',
  message = 'Navigate home to stay logged in, or reload to clear your session'
}: ErrorPageProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Do not show the side menu or
    // top bar on the error page
    dispatch(setLayout(NO_LAYOUT));
  }, []);

  // change the browser url to the default route
  window.history.replaceState({}, 'Error', routes.ERROR);

  // reset to base url, reloading the page
  const reload = () => {
    window.location.href = window.location.origin;
  };

  const navigateHome = () => navigate('/login');

  return (
    <div className="specto-error-page flex align-items-center justify-content-center h-full">
      <div>
        <img
          className="specto-error-svg"
          src="/assets/error/error-page.svg"
          alt="error page decorations"
        />
      </div>

      <div className="message-box">
        <h1>{title}</h1>
        <p>{message}</p>
        <Button label="Back to Home" onClick={navigateHome} className="mx-3" />
        <Button label="Reload" onClick={reload} />
      </div>
    </div>
  );
};

export default ErrorPage;
