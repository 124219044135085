// Layout values
export enum SpectoLayout {
  mainNoDashboard = 'main_no_dashboard_layout',
  main = 'main_layout',
  adminMainNoDashboard = 'admin_main_no_dashboard_layout',
  adminMain = 'admin_main_layout',
  superuser = 'SUPERUSER_LAYOUT',
  systemSettings = 'system_settings_layout',
  adminProjectSettings = 'admin_project_settings_layout',
  superUserProjectSettings = 'superuser_project_settings_layout',
  userSettings = 'user_settings_layout',
  fullscreen = 'fullscreen_layout',
  none = 'no_layout'
}
