import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Button } from 'primereact/button';
import { setLayout } from 'features/layout/layoutSlice';
import ModalLarge from 'containers/modals/ModalLarge';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { dirtyValues } from 'util/form';
import LoadingSpinner from 'components/LoadingSpinner';
import SearchBar from 'components/SearchBar';
import { FetchStatusOptions } from 'constants/fetchStatus';
import useFilter from 'hooks/useFilter';
import {
  PatchTagOptionsModel,
  PostTagOptionsModel,
  TagModel,
  TagPatchRequestAPIModel,
  TagRequestAPIModel
} from 'models/tag-model';
import ColorSelect from 'components/ColorSelect';
import useToggleKeys from 'hooks/useToggleKeys';
import { pipeValueToHex } from 'util/color';
import useFetch from 'hooks/useFetch';
import TagsService from 'services/tagsService';
import {
  addTag,
  deleteTag as deleteTagState,
  editTag as editTagState,
  projectsSelector,
  tagsSelector
} from 'features/projects/projectsSlice';
import { confirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'components/Tags';
import { createToast } from 'features/toast/toastSlice';
import { SlugIdOptionsModel, SlugOptionsModel } from 'models/api-model';
import { TextField } from 'components/Forms';
import { DataView } from 'primereact/dataview';
import { authSelector } from 'features/auth/authSlice';
import { getProjectSettingsLayout } from 'util/layout';

const TagManager = () => {
  const dispatch = useAppDispatch();
  const { selectedProject } = useAppSelector(projectsSelector);
  const tags = useAppSelector(tagsSelector);
  const { user } = useAppSelector(authSelector);
  const {
    toggleKeySingle: setTagEditMode,
    isKeyExpanded: isTagEditMode,
    clearKeys: clearAllTagEditMode,
    hasExpandedKeys: someTagEditMode,
    keys: editModeTags
  } = useToggleKeys();
  const {
    toggleKeySingle: setTagRowLoading,
    isKeyExpanded: isTagRowLoading,
    clearKeys: clearAllTagsRowLoading
  } = useToggleKeys();
  const {
    filteredData: filteredTagsData,
    search: searchTags,
    searchText,
    simpleSearch,
    clearSearch,
    setData: setFilteredTagsData,
    loading: searchTagsLoading
  } = useFilter<TagModel>({ searchParameters: ['name'] });
  const { fetchStatus: getTagsStatus } = useFetch<SlugOptionsModel, TagModel[]>(
    TagsService.getTags,
    TagsService.roles.list
  );
  const {
    data: editTagData,
    fetchStatus: editTagStatus,
    fetch: editTag
  } = useFetch<PatchTagOptionsModel, TagModel>(TagsService.editTag, TagsService.roles.update);
  const {
    fetchStatus: deleteTagStatus,
    fetch: deleteTag,
    fetchOptions: deleteTagOptions
  } = useFetch<SlugIdOptionsModel<TagModel['id']>, TagModel>(
    TagsService.deleteTag,
    TagsService.roles.delete
  );
  const {
    data: createTagData,
    fetchStatus: createTagStatus,
    fetch: createTag
  } = useFetch<PostTagOptionsModel, TagModel>(TagsService.createTag, TagsService.roles.create);

  const defaultValues: FieldValues = {
    newTagName: '',
    newTagColor: 'ff0000'
  };
  const { control: newTagControl, handleSubmit: newTagHandleSubmit } = useForm({
    defaultValues,
    mode: 'onSubmit'
  });

  const {
    control: editTagControl,
    formState: { dirtyFields: editTagDirtyFields },
    handleSubmit: editTagHandleSubmit,
    reset: editTagFormReset
  } = useForm();

  // onInit
  useEffect(() => {
    dispatch(setLayout(getProjectSettingsLayout(user.is_superuser)));
  }, []);

  // set filtered tags to tag data
  useEffect(() => setFilteredTagsData(tags), [tags]);

  // editTag response effect
  useEffect(() => {
    if (editTagStatus === FetchStatusOptions.SUCCESS && editTagData) {
      editTagFormReset();
      clearAllTagEditMode();
      dispatch(editTagState(editTagData));
      dispatch(createToast('tag edited'));
    }
  }, [editTagStatus]);

  // createTag response effect
  useEffect(() => {
    if (createTagStatus === FetchStatusOptions.SUCCESS && createTagData) {
      dispatch(addTag(createTagData));
      dispatch(createToast('tag created'));
    }
  }, [createTagStatus]);

  // deleteTag response effect
  useEffect(() => {
    if (deleteTagStatus === FetchStatusOptions.SUCCESS && deleteTagOptions) {
      editTagFormReset();
      clearAllTagsRowLoading();
      dispatch(deleteTagState(deleteTagOptions.id));
      dispatch(createToast('tag deleted'));
    }
  }, [deleteTagStatus]);

  const onAddTagFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    newTagHandleSubmit(onCreateTag)();
  };

  const onEditTagFormSubmit = (e: any) => {
    e.preventDefault();
    editTagHandleSubmit(onEditTag)();
  };

  const onEditTagCancel = () => {
    clearAllTagEditMode();
    editTagFormReset();
  };

  const onCreateTag = (data: FieldValues) => {
    const createTagRequest: TagRequestAPIModel = {
      name: data.newTagName,
      color: pipeValueToHex(data.newTagColor)
    };

    createTag({ slug: selectedProject.slug, tag: createTagRequest });
  };

  const onEditTag = (data: FieldValues) => {
    const formValues = dirtyValues(editTagDirtyFields, data);
    if (Object.keys(formValues).length === 0 || !selectedProject || !someTagEditMode) {
      return;
    }

    // get the edit mode tag id, it will be the only key expanded
    // should get this from the active edit mode useKey rather than the form.
    // useKeys guarantees only 1 edit mode active at a time, the form record formValues does not.
    const editModeTagId = Object.keys(editModeTags)?.[0];

    // pipe local forms newTagName property to name, and newTagColor to color if they exist
    const editTagRequest: TagPatchRequestAPIModel = {
      id: editModeTagId,
      ...(formValues[editModeTagId]?.name && { name: formValues[editModeTagId].name }),
      ...(formValues[editModeTagId]?.color && {
        color: pipeValueToHex(formValues[editModeTagId].color)
      })
    };
    editTag({ slug: selectedProject.slug, tag: editTagRequest });
  };

  const onTagDelete = (tag: TagModel) => {
    // manually set the row loading for the specific tag
    setTagRowLoading(tag.id);
    deleteTag({ slug: selectedProject.slug, id: tag.id });
  };

  const confirmDeleteTag = (tag: TagModel) => {
    confirmDialog({
      message: 'This action cannot be undone.',
      header: 'Delete Tag?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => onTagDelete(tag)
    });
  };

  if (getTagsStatus === FetchStatusOptions.LOADING) {
    return <LoadingSpinner />;
  }

  const tagRow = (tag: TagModel) => (
    <div className="pl-2 w-full specto-system-settings__styled-list" key={tag.id}>
      {isTagEditMode(tag.id) ? (
        <div className="h-5rem flex justify-content-between align-items-center w-full">
          <div className="col md:mr-5 pl-0 flex align-items-center">
            <div>
              <Controller
                name={tag.id + '.color'}
                control={editTagControl}
                defaultValue={tag.color}
                render={({ field, fieldState }) => (
                  <ColorSelect
                    noText
                    className={classNames('mr-2 mb-2', {
                      'p-invalid': fieldState.error
                    })}
                    color={field.value}
                    onChange={(e) => field.onChange(e.value)}
                  />
                )}
              />
            </div>

            <TextField
              fieldName={tag.id + '.name'}
              control={editTagControl}
              defaultValue={tag.name}
              placeholder="Tag Name"
              noPadding
            />
          </div>

          <div className="md:m-auto mt-3 flex">
            <Button
              icon="pi pi-check"
              aria-label="Confirm tag edit"
              className="p-button-rounded specto-r-button-lg"
              loading={editTagStatus === FetchStatusOptions.LOADING}
              type="submit"
            />
            <Button
              icon="pi pi-times"
              aria-label="Cancel tag edit"
              className="p-button-rounded specto-r-button-lg ml-1"
              onClick={onEditTagCancel}
              disabled={editTagStatus === FetchStatusOptions.LOADING}
              type="button"
            />
          </div>
        </div>
      ) : (
        <div className="h-5rem flex justify-content-between align-items-center w-full">
          <Tag tag={tag} />
          <div className="flex">
            <Button
              icon="pi pi-pencil"
              aria-label="Edit tag"
              className="p-button-rounded specto-r-button-lg"
              onClick={() => setTagEditMode(tag.id)}
              disabled={editTagStatus === FetchStatusOptions.LOADING}
              type="button"
            />
            <Button
              icon="pi pi-trash"
              aria-label="Delete tag"
              className="p-button-rounded specto-r-button-lg ml-1"
              onClick={() => confirmDeleteTag(tag)}
              loading={isTagRowLoading(tag.id)}
              disabled={deleteTagStatus === FetchStatusOptions.LOADING}
              type="button"
            />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <ModalLarge className="specto-system-settings">
      <h1 className="align-self-center mb-7">Tag Manager</h1>

      <h6>Tag Settings</h6>
      <span>Tags that are modified here affect the data model.</span>
      <div className="col col-12 lg:col-8 pl-0 mt-3">
        <SearchBar
          className="pb-4"
          placeHolder="Search All Tags"
          text={searchText}
          formInputStyle
          loading={searchTagsLoading}
          onChange={(e) => simpleSearch(e.target.value)}
          onSubmitText={searchTags}
          onClearText={() => {
            clearSearch();
            searchTags('');
          }}
        />
      </div>

      <h6>Add Tag</h6>
      <form className="w-full" onSubmit={onAddTagFormSubmit}>
        <div className="flex flex-column md:flex-row w-full align-content-start md:align-content-center justify-content-between">
          <TextField
            fieldName="newTagName"
            placeholder="Tag Name"
            className="col md:mr-5 pl-0"
            control={newTagControl}
            noPadding
          />

          <div className="flex justify-content-between md:m-auto mt-3">
            <Controller
              name="newTagColor"
              control={newTagControl}
              render={({ field, fieldState }) => (
                <ColorSelect
                  className={classNames('mr-2', {
                    'p-invalid': fieldState.error
                  })}
                  color={field.value}
                  onChange={(e) => field.onChange(e.value)}
                />
              )}
            />
            <Button
              label="Add Tag"
              type="submit"
              loading={createTagStatus === FetchStatusOptions.LOADING}
            />
          </div>
        </div>
      </form>

      <form className="w-full" onSubmit={onEditTagFormSubmit}>
        <DataView
          value={filteredTagsData}
          className="mt-5 w-full"
          itemTemplate={tagRow}
          header={<h6>All Tags</h6>}
          paginator
          rows={5}
        />
      </form>
    </ModalLarge>
  );
};

export default TagManager;
