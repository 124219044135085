import ApiService from 'services/apiService';
import { USER_GROUPS_API_URL } from 'constants/api';
import { UserGroupAPIModel, UserGroupModel } from 'models/user-groups-model';
import { ProjectRole } from 'models/project-model';
import { Permissions } from 'models/permission-model';

// Fetch all user groups
const getUserGroups = () => ApiService.get<UserGroupModel[]>(USER_GROUPS_API_URL);

// Add new user group
const createUserGroup = (options: UserGroupAPIModel) =>
  ApiService.post<UserGroupModel>(USER_GROUPS_API_URL, options);

// Edit existing user group
const modifyUserGroup = (options: UserGroupModel) =>
  ApiService.patch<UserGroupModel>(USER_GROUPS_API_URL + options.id + '/', options);

// Delete existing user group
const removeUserGroup = (id: number) => ApiService.remove(USER_GROUPS_API_URL + id + '/');

const userGroupsServiceRoles: Permissions = {
  list: true,
  retrieve: true,
  create: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN],
  update: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN],
  delete: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN]
};

const UserGroupsService = {
  getUserGroups,
  createUserGroup,
  modifyUserGroup,
  removeUserGroup,
  roles: userGroupsServiceRoles
};
export default UserGroupsService;
