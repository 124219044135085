import { Status } from 'models/status-model';

export const ProposedNewIntentWarning = `This Intent has status "${Status.New}."`;

export const PERMISSIONS_WARNING =
  'You do not have sufficient privileges to access this page or perform this action.';

export const guardWarning = {
  neurosoph: 'To access this page, you must have NeuroSoph privileges.',
  project: 'To access this page, please select a project first.',
  login: 'To access this page, please login first.',
  admin: 'To access this page, you must be an Admin.',
  permission: PERMISSIONS_WARNING,
  version: 'You cannot view this page while inside a version.'
};

export const PublishingNameWarning =
  'Names may only contain numbers, letters, underscores (_), and hyphens (-), and they must start with a letter or underscore (_).';

export const FilterStatusExclusiveWarning =
  'To edit this filter, clear the "Published on or Before" date field.';
