import LoadingSpinner from 'components/LoadingSpinner';
import { FetchStatusOptions } from 'constants/fetchStatus';
import ModalFormFullScreen from 'containers/modals/ModalFormFullScreen';
import useFetch from 'hooks/useFetch';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import AuthService from 'services/authService';

const VerifyEmail = function () {
  const { key } = useParams();
  const { fetch: verifyEmail, fetchStatus: verifyEmailStatus } = useFetch(
    AuthService.verifyEmail,
    AuthService.roles.update
  );

  const message = (status: FetchStatusOptions) => {
    switch (status) {
      case FetchStatusOptions.SUCCESS:
        return (
          <div>
            <h4>Email was successfully verified.</h4>{' '}
            <Link to="/login" className="specto-link">
              Return to Login
            </Link>
          </div>
        );
      case FetchStatusOptions.ERROR:
        return (
          <div>
            <h4>An error occurred while verifying your email.</h4>
            <span>Please try again at a later time.</span>
          </div>
        );
      default:
        return <LoadingSpinner />;
    }
  };

  useEffect(() => {
    key && verifyEmail({ key: key });
  }, [key]);

  return (
    <ModalFormFullScreen onSubmit={() => null}>{message(verifyEmailStatus)}</ModalFormFullScreen>
  );
};

export default VerifyEmail;
