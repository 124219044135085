import React from 'react';
import classnames from 'classnames';
import { Avatar as AvatarPrimeReact } from 'primereact/avatar';

type AvatarProps = {
  className?: string;
  fullName: { first?: string; last?: string };
  outlined?: boolean;
  url?: string;
};

/**
 * User Avatar component.
 * @param url avatar image url
 * @param className class name
 * @param fullName fullName of the user
 * @param outlined outlined style
 * @constructor
 */
const Avatar = ({ url, className, fullName, outlined }: AvatarProps) => (
  <AvatarPrimeReact
    image={url}
    label={
      hasAtLeastOneCharacter(fullName.first)
        ? fullName.first?.[0]?.toLocaleUpperCase() + (fullName.last?.[0]?.toLocaleUpperCase() || '')
        : 'U'
    }
    aria-label={`${fullName.first} ${fullName.last}`}
    style={{ width: 35, height: 35, minWidth: 35, userSelect: 'none' }}
    data-cy="user-avatar"
    shape="circle"
    className={classnames(className, 'specto-avatar font-semibold', {
      'bg-primary': !outlined,
      'text-lg': hasAtLeastOneCharacter(fullName.first),
      'bg-transparent': outlined,
      'border-primary border-2': outlined
    })}
  />
);

const hasAtLeastOneCharacter = (name?: string | null) => !!name && name.trim?.() !== '';

export default Avatar;
