import React, { useState } from 'react';
import { DataTable, DataTableStateEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IntentTableDataModel } from 'models/intent-table-data-model';
import { CategoryModel } from 'models/category-model';
import {
  IdToNameCell,
  LastModifiedCell,
  RemoveRetiredDialog,
  RetireDialog,
  TableHeader,
  TagsCell,
  TextCell
} from 'components/Table';
import { useAppSelector } from 'hooks/store';
import { CommentPopup, ThreadStateChangeEvent } from 'pages/Comments/CommentPopup';
import { CommentType } from 'models/comment-model';
import { CommentThreadButton } from 'pages/Comments/CommentThreadButton';
import { versionsSelector } from 'features/versions/versionsSlice';
import { mapTags } from 'util/tags';
import {
  projectsSelector,
  tagsSelector,
  contentFiltersSelector
} from 'features/projects/projectsSlice';
import { confirmDialog } from 'primereact/confirmdialog';
import { MenuButtonCell } from 'components/Table/MenuButtonCell';
import { Status } from 'models/status-model';
import { statusDisplayNames } from 'constants/status';
import { permissionBoolean } from 'util/permissions';
import { ProjectMemberUserModel, ProjectRole } from 'models/project-model';
import { customSortIconFn } from 'util/table';
import paginatorTemplate from 'components/Table/PaginatorTemplate';
import { UserGroupModel } from 'models/user-groups-model';
import UserGroupsCell from 'components/UserGroups/UserGroupsCell';
import { TopicTableDataModel } from 'models/topic-model';
import { mapIdToName } from 'util/mapIdAndName';
import ViewAllLinkChipsCell from 'components/Table/ViewAllLinkChipsCell';
import AvatarCell from 'components/Avatar/AvatarCell';
import ClickableTextCell from 'components/Table/ClickableTextCell';

type IntentDataTablePropsModel = {
  allCategories: CategoryModel[];
  allTopics: TopicTableDataModel[];
  allUserGroups: UserGroupModel[];
  canDelete?: boolean;
  intents: IntentTableDataModel[];
  isSuperUser?: boolean;
  isAdmin?: boolean;
  onApproveIntent?(node: IntentTableDataModel): void;
  onDeleteIntent?(e: IntentTableDataModel): void;
  onDuplicateIntent?(e: IntentTableDataModel): void;
  onEditInit?(e: IntentTableDataModel): void;
  onHistoryOpen(e: IntentTableDataModel): void;
  onPublishIntent?(node: IntentTableDataModel): void;
  onRetireChange?(e: IntentTableDataModel, isRetired: boolean): void;
  onThreadStateChange?(options: ThreadStateChangeEvent): void;
  readOnly?: boolean;
  showComments?: boolean;
  tablestate: DataTableStateEvent;
  onTableStateChange(tablestate: DataTableStateEvent): void;
  totalRecords: number;
  loading?: boolean;
  authors: ProjectMemberUserModel[];
};

/**
 * Component for displaying the Intent Table.
 *
 * @param allCategories List of all the categories
 * @param allTopics List of all the topics
 * @param allUserGroups List of all the user groups
 * @param canDelete Flag indicating if the user can delete
 * @param intents List of all the intents
 * @param loading Flag indicating if the table is loading
 * @param onApproveIntent Callback function to approve an intent
 * @param onDeleteIntent Callback function for the delete intent action
 * @param onDuplicateIntent Callback function for the duplicate intent action
 * @param onEditInit Callback function for initializing intent edit
 * @param onHistoryOpen Callback function for opening the history popup
 * @param onPublishIntent Callback function for the publishing intent action
 * @param onRetireChange Callback function for the retire intent action
 * @param onTableStateChange Callback function for the table state change action
 * @param onThreadStateChange Callback function for the thread state change action
 * @param readOnly Flag indicating if the table is read-only
 * @param showComments if the comments should be shown
 * @param tablestate Table state
 * @param totalRecords Total number of records
 * @param isSuperUser If the current user is a superuser or not
 * @param isAdmin If the current user has admin+ privileges
 * @param authors List of current project members
 * @constructor
 * @component
 */
const IntentDataTable = function ({
  allCategories,
  allTopics,
  allUserGroups,
  canDelete = false,
  intents,
  isSuperUser = false,
  isAdmin = false,
  loading = false,
  onApproveIntent,
  onDeleteIntent,
  onDuplicateIntent,
  onEditInit,
  onHistoryOpen,
  onPublishIntent,
  onRetireChange,
  onTableStateChange,
  onThreadStateChange,
  readOnly = false,
  showComments,
  tablestate,
  totalRecords,
  authors
}: IntentDataTablePropsModel) {
  const version = useAppSelector(versionsSelector);
  const allTags = useAppSelector(tagsSelector);
  const contentFilters = useAppSelector(contentFiltersSelector);
  const { selectedProject } = useAppSelector(projectsSelector);
  // Store the intent that will be retired or deleted
  const [selectedNode, setSelectedNode] = useState<IntentTableDataModel>(
    {} as IntentTableDataModel
  );
  const [showRetireDialog, setShowRetireDialog] = useState(false);
  const [showCommentPopup, setShowCommentPopup] = useState(false);

  const active = contentFilters?.active;
  const status = contentFilters?.status;

  /* - - - - - - - - - - Dialogs - - - - - - - - - - */

  /**
   * Opens the Retire dialog
   * @param node object being retired
   */
  const openRetirePopup = (node: any) => {
    setSelectedNode(node);
    setShowRetireDialog(true);
  };

  /**
   * Opens the comments dialog
   * @param node object to open the comments dialog for
   */
  const openComments = (node: any) => {
    setSelectedNode(node);
    setShowCommentPopup(true);
  };

  /**
   * Delete confirmation popup
   * @param node intent TableData
   */
  const confirmDeleteRow = (node: IntentTableDataModel) => {
    confirmDialog({
      message: (
        <div>
          <h6>This action cannot be undone.</h6>
          <ul className="list-disc">
            <li>This Intent cannot be recovered.</li>
            <li>Anything using this Intent will use the Intent &quot;NOT FOUND&quot; instead.</li>
          </ul>
        </div>
      ),
      header: 'Delete Row?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => onDeleteIntent?.(node)
    });
  };

  const canPublish = (node: IntentTableDataModel) =>
    node.status === Status.Approved &&
    permissionBoolean(
      [ProjectRole.ADMIN, ProjectRole.NEUROSOPH],
      selectedProject.project_user.role
    );

  // Strategists and admins can approve "New" or "Modified" statuses
  const canApprove = (node: IntentTableDataModel) =>
    (node.status === Status.New || node.status === Status.Modified) &&
    permissionBoolean(
      [ProjectRole.ADMIN, ProjectRole.NEUROSOPH, ProjectRole.CONTENT_STRATEGIST],
      selectedProject.project_user.role
    );

  return (
    <div>
      <h6>{TableHeader({ tableType: 'Intent', itemCount: totalRecords })}</h6>
      <DataTable
        className="specto-data-table"
        dataKey="id"
        value={intents}
        sortIcon={customSortIconFn}
        paginator
        paginatorTemplate={paginatorTemplate}
        selectionAutoFocus={false}
        lazy
        loading={loading}
        first={tablestate.first}
        rows={tablestate.rows}
        scrollable
        totalRecords={totalRecords}
        onPage={onTableStateChange}
        onSort={(e) => onTableStateChange({ ...e, page: 1, first: 0 })}
        sortField={tablestate.sortField}
        sortOrder={tablestate.sortOrder}
        editMode="row"
        onRowDoubleClick={(e) => onHistoryOpen?.(e.data as IntentTableDataModel)}
        metaKeySelection={false}
        data-cy="intent-data-table"
      >
        <Column
          field="name"
          className="specto-table__column-lg"
          bodyClassName="specto-table__column--text-button"
          header="Name"
          body={(node: IntentTableDataModel) =>
            ClickableTextCell({ text: node.name, action: () => onHistoryOpen(node) })
          }
          sortable
          pt={{ sort: { 'aria-label': 'sortable' } }}
        />
        <Column
          field="display_name"
          className="specto-table__column--xl"
          bodyClassName="specto-table__column--text"
          header="Display Name"
          sortable
          pt={{ sort: { 'aria-label': 'sortable' } }}
        />
        {isAdmin && (
          <Column
            field="category"
            className="specto-table__column--md"
            bodyClassName="specto-table__column--text"
            header="Category"
            body={(node: IntentTableDataModel) =>
              IdToNameCell({ id: node.category, allOptions: allCategories })
            }
            pt={{ sort: { 'aria-label': 'sortable' } }}
          />
        )}
        <Column
          field="user_groups"
          className="specto-table__column--md"
          bodyClassName="specto-table__column--chips"
          header="User Groups"
          body={(node: IntentTableDataModel) =>
            UserGroupsCell({
              nodeId: node.id,
              userGroups: node.user_groups,
              referenceUserGroups: node.reference?.user_groups,
              allUserGroups
            })
          }
        />
        <Column
          field="topics"
          className="specto-table__column--md"
          bodyClassName="specto-table__column--chips"
          header="Topics"
          body={(node) =>
            ViewAllLinkChipsCell({
              nodeId: 'topic-' + node.id,
              chips: node.topics.map((topic: number) => ({
                label: mapIdToName(topic, allTopics),
                url: `/topics?status=${status}&active=${active}&search=${mapIdToName(
                  topic,
                  allTopics
                )}`
              })),
              url: `/topics?status=${status}&active=${active}&intents=[${node.id}]`,
              emptyMessage: 'No Topics'
            })
          }
        />
        <Column
          field="authors"
          header="Author"
          className="specto-table__column--sm"
          bodyClassName="specto-table__column--icon"
          align="center"
          body={(node: IntentTableDataModel) => {
            const fullName = authors.find((pm) => pm.user === node?.author)?.name || '';
            return fullName ? <AvatarCell fullName={fullName} id={node.id} /> : null;
          }}
        />
        <Column
          field="last_modified"
          className="specto-table__column--md"
          bodyClassName="specto-table__column--text"
          header="Last Modified"
          body={(node) =>
            LastModifiedCell({
              nodeId: node.id,
              user: authors.find((author) => author.user === node.user),
              lastModified: node.last_modified
            })
          }
          sortable
          pt={{ sort: { 'aria-label': 'sortable' } }}
        />
        <Column
          field="status"
          header="Status"
          className="specto-table__column--sm"
          bodyClassName="specto-table__column--text"
          body={(node: IntentTableDataModel) =>
            TextCell({ id: 'status-' + node.id, text: statusDisplayNames[node.status] })
          }
          sortable
          pt={{ sort: { 'aria-label': 'sortable' } }}
        />
        {isSuperUser && (
          <Column
            field="tags"
            header={<span className="pi pi-tag" />}
            body={(node: IntentTableDataModel) => (
              <TagsCell tags={mapTags(allTags, node.tags)} editable={false} />
            )}
            className="specto-table-icon"
            bodyClassName="specto-table__column--icon-button"
          />
        )}
        <Column
          field="util-comments"
          hidden={!showComments || readOnly || version.isVersionSelected}
          header="Comments"
          className="specto-table__column--sm"
          bodyClassName="specto-table__column--icon-button"
          alignHeader="center"
          body={(node: IntentTableDataModel) => (
            <CommentThreadButton
              key={`${node.id}-comments`}
              threadResolved={node.comment_thread_resolved}
              action={() => openComments(node)}
              data-cy={`comment-button-${node.id}`}
            />
          )}
        />
        <Column
          columnKey="util-action"
          className="specto-table__column--sm-2 border-left-1"
          bodyClassName="specto-table__column--button"
          header="Actions"
          hidden={readOnly || version.isVersionSelected}
          alignHeader="center"
          align="center"
          frozen
          alignFrozen="right"
          rowEditor
          body={(node: IntentTableDataModel) => (
            <MenuButtonCell
              hidden={node.default}
              data-cy={`${node.id}-actions`}
              menu={[
                {
                  label: 'Approve',
                  visible: !readOnly && canApprove(node),
                  command: () => onApproveIntent?.(node)
                },
                {
                  label: 'Delete',
                  visible: canDelete && node.status === Status.Retired,
                  command: () => confirmDeleteRow(node)
                },
                {
                  label: 'Duplicate',
                  visible: node.status !== Status.Retired && isAdmin,
                  command: () => onDuplicateIntent?.(node)
                },
                {
                  label: 'Edit',
                  visible: node.status !== Status.Retired,
                  command: () => onEditInit?.(node)
                },
                {
                  label: 'Publish',
                  visible: !readOnly && canPublish(node),
                  command: () => onPublishIntent?.(node)
                },
                {
                  label: 'Retire',
                  visible: canDelete && node.status !== Status.Retired,
                  command: () => openRetirePopup(node)
                },
                {
                  label: 'Unretire',
                  visible: canDelete && node.status === Status.Retired,
                  command: () =>
                    RemoveRetiredDialog({
                      objectName: CommentType.INTENT,
                      accept: () => onRetireChange?.(node, false)
                    })
                },
                {
                  label: 'No Actions Available',
                  disabled: true,
                  visible: !canDelete && node.status === Status.Retired
                }
              ]}
            />
          )}
        />
      </DataTable>
      {/* Need to use dialog component instead of method because the Retire dialog
          needs to be closed before the delete dialog can be opened */}
      <RetireDialog
        objectName={CommentType.INTENT}
        accept={() => onRetireChange?.(selectedNode, true)}
        onHide={() => setShowRetireDialog(false)}
        showRetireDialog={showRetireDialog}
      />

      <CommentPopup
        displayPopup={showCommentPopup}
        onPopupDisplayChange={setShowCommentPopup}
        objectType={CommentType.INTENT}
        objectId={selectedNode.id}
        objectName={selectedNode.name}
        parentElement={document.activeElement as HTMLElement}
        onThreadStateChange={onThreadStateChange}
      />
    </div>
  );
};

export default IntentDataTable;
