export const SYNC_IN_PROGRESS = 'Sync in progress...';

export enum DatabaseField {
  DATABASE_NAME = 'databaseName',
  DATABASE_HOST = 'databaseHost',
  DATABASE_PORT = 'databasePort',
  DATABASE_USER = 'databaseUser',
  DATABASE_PASSWORD = 'databasePassword'
}

export enum EventsDatabaseSyncField {
  VALID_CONNECTION = 'validConnection',
  SYNC_EVENTS = 'syncEvents'
}

export enum DatabaseReadFrequency {
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY'
}

// Map that contains each unit of time's value in ms
// i.e. 1 hour = 3,600,000 ms
export const ReadFrequencyMap = new Map<string, number>([
  [DatabaseReadFrequency.HOURLY, 3600000],
  [DatabaseReadFrequency.DAILY, 86400000],
  [DatabaseReadFrequency.WEEKLY, 604800000]
]);
