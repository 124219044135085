import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import classNames from 'classnames';

import { useAppSelector } from 'hooks/store';
import AppTopbar from 'containers/AppTopbar';
import AppBreadcrumb from 'containers/AppBreadcrumb';
import { layoutSelector } from 'features/layout/layoutSlice';
import { guard, GuardedRoute } from 'containers/guards';
import { mainMenu, MENU_MODE_STATIC } from 'features/layout/menus';
import { SpectoMenu } from 'models/menu-model';
import {
  allRouters,
  mainRouter,
  pageRouter,
  projectSettingsRouter,
  systemSettingsRouter,
  userSettingsRouter
} from 'routers';
import { routes } from 'constants/routes';
import { layoutMap } from 'features/layout/layoutMap';
import { authSelector } from 'features/auth/authSlice';
import { projectsSelector } from 'features/projects/projectsSlice';
import { guardWarning } from 'constants/warnings';
import { SpectoLayout } from 'models/layout-model';
import ErrorBoundary from 'features/error/ErrorBoundary';
import VersionManager from 'components/VersionManager/VersionManager';
import { MenuItemCommandEvent } from 'primereact/menuitem';
import { Menu } from 'primereact/menu';
import { versionsSelector } from 'features/versions/versionsSlice';
import ProjectsService from 'services/projectsService';
import { permissionBoolean } from 'util/permissions';
import { NO_LAYOUT } from 'constants/layouts';
import BrandBanner from 'components/BrandBanner/BrandBanner';
import BrowserNotSupported from 'pages/BrowserNotSupported/BrowserNotSupported';
import { supportedBrowsers } from 'constants/supportedBrowsers';

export const App = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [menuMode, setMenuMode] = useState<string>(MENU_MODE_STATIC);
  const [menu, setMenu] = useState<SpectoMenu>(mainMenu);
  const [colorScheme, setColorScheme] = useState('light');
  const [menuTheme, setMenuTheme] = useState('layout-sidebar-mayflower');
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
  const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] = useState(false);
  const [configActive, setConfigActive] = useState(false);
  const [logoColor, setLogoColor] = useState('dark');
  const [, setComponentTheme] = useState('blue');
  const [logoUrl, setLogoUrl] = useState('assets/layout/images/askma-logo-dark.svg');
  const [displayVersionHistory, setDisplayVersionHistory] = useState(false);
  const versionListMenuRef = useRef<Menu>(null);
  const { layout } = useAppSelector(layoutSelector);
  const { user, isLoggedIn } = useAppSelector(authSelector);
  const { isProjectSelected, selectedProject } = useAppSelector(projectsSelector);
  const { selectedVersion, isVersionSelected } = useAppSelector(versionsSelector);

  // BRAND BANNER STATE
  const brandBannerRef = useRef<HTMLButtonElement>(null);
  const [bannerHeight, setBannerHeight] = useState(0);
  const [, setWindowWidth] = useState(window.innerWidth);

  // browser support
  const [isBrowserSupported, setIsBrowserSupported] = useState(true);
  const [isMacOS, setIsMacOS] = useState(false);

  let menuClick = false;
  let userMenuClick = false;
  let notificationMenuClick = false;
  let configClick = false;
  // Check if the browser is supported
  // List of supported browsers: Google Chrome, Mozilla Firefox, Safari, Microsoft Edge, Opera, Samsung Internet

  useEffect(() => {
    const browserAgent = window.navigator.userAgent;
    const browserName = supportedBrowsers.find((name) => browserAgent.indexOf(name) > -1);
    const isSupported = !!browserName;
    setIsBrowserSupported(isSupported);
    setIsMacOS(browserAgent.indexOf('Mac') > -1);
  }, []);

  useEffect(() => {
    setMenu(layoutMap[layout]?.menu || layoutMap[SpectoLayout.main]?.menu);

    // some layouts in layoutMap specify a menu mode. defaults to static
    onMenuModeChange({ value: layoutMap[layout]?.menuMode || MENU_MODE_STATIC });
  }, [layout]);

  // block scroll on mobile
  useEffect(() => {
    if (staticMenuMobileActive) {
      blockBodyScroll();
    } else {
      unblockBodyScroll();
    }
  }, [staticMenuMobileActive]);

  useEffect(() => {
    if (!brandBannerRef?.current) return;
    const resizeObserver = new ResizeObserver(() => {
      // Do what you want to do when the size of the element changes
      if (brandBannerRef?.current) setBannerHeight(brandBannerRef?.current?.clientHeight);
    });
    resizeObserver.observe(brandBannerRef?.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  // React18 Workaround as to not doubly-apply stylesheets
  // useEffect(() => {
  //   changeStyleSheetUrl('layout-css', 'layout-' + colorScheme + '.css', 1);
  //   changeStyleSheetUrl('theme-css', 'theme-' + colorScheme + '.css', 1);
  // }, []);

  // To keep App.tsx as similar to Diamond Theme template
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const changeMenuTheme = (name: string, logoColor: any, componentTheme: string) => {
    onMenuThemeChange(name);
    changeStyleSheetUrl('theme-css', componentTheme, 2);
    setComponentTheme(componentTheme);

    const appLogoLink = document.getElementById('app-logo') as HTMLImageElement;
    const appLogoUrl = `/assets/layout/images/askma-logo-${
      logoColor === 'dark' ? 'dark' : 'white'
    }.svg`;
    const horizontalLogoLink = document.getElementById('logo-horizontal') as HTMLImageElement;
    const horizontalLogoUrl = `/assets/layout/images/logo-${
      logoColor === 'dark' ? 'dark' : 'white'
    }.svg`;

    if (appLogoLink) {
      appLogoLink.src = appLogoUrl;
    }
    if (horizontalLogoLink) {
      horizontalLogoLink.src = horizontalLogoUrl;
    }
    setLogoColor(logoColor);
    // setMenuColor(name);
  };

  // To keep App.tsx as similar to Diamond Theme template
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const changeComponentTheme = (theme: string) => {
    setComponentTheme(theme);
    changeStyleSheetUrl('theme-css', theme, 3);
  };

  // To keep App.tsx as similar to Diamond Theme template
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const changeColorScheme = (e: any) => {
    setColorScheme(e.value);

    const scheme = e.value;
    changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1);
    changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1);

    changeLogo(scheme);
  };

  const changeStyleSheetUrl = (id: string, value: string, from: number) => {
    const element = document.getElementById(id);
    const urlTokens = element?.getAttribute('href')?.split('/') || [];

    if (from === 1) {
      // which function invoked this function
      urlTokens[urlTokens.length - 1] = value;
    } else if (from === 2) {
      // which function invoked this function
      if (value !== null) {
        urlTokens[urlTokens.length - 2] = value;
      }
    } else if (from === 3) {
      // which function invoked this function
      urlTokens[urlTokens.length - 2] = value;
    }

    const newURL = urlTokens.join('/');

    replaceLink(element, newURL);
  };

  const changeLogo = (scheme: string) => {
    const appLogoLink = document.getElementById('app-logo') as HTMLImageElement;
    const mobileLogoLink = document.getElementById('logo-mobile') as HTMLImageElement;
    const invoiceLogoLink = document.getElementById('invoice-logo') as HTMLImageElement;
    const footerLogoLink = document.getElementById('footer-logo') as HTMLImageElement;
    setLogoUrl(`/assets/layout/images/askma-logo-${scheme === 'light' ? 'dark' : 'white'}.svg`);

    if (appLogoLink) {
      appLogoLink.src = `/assets/layout/images/logo-${
        scheme === 'light' ? logoColor : 'white'
      }.svg`;
    }

    if (mobileLogoLink) {
      mobileLogoLink.src = logoUrl;
    }

    if (invoiceLogoLink) {
      invoiceLogoLink.src = logoUrl;
    }

    if (footerLogoLink) {
      footerLogoLink.src = logoUrl;
    }
  };

  const replaceLink = (linkElement: any, href: string) => {
    if (!linkElement) {
      return;
    }

    if (isIE()) {
      linkElement.setAttribute('href', href);
    } else {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        const _linkElement = document.getElementById(id);
        _linkElement && _linkElement.remove();
        cloneLinkElement.setAttribute('id', id);
      });
    }
  };

  const isIE = () => {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  };

  const onDocumentClick = () => {
    // if (!searchClick && searchActive) {
    //   onSearchHide();
    // }

    if (!userMenuClick) {
      setTopbarUserMenuActive(false);
    }

    if (!notificationMenuClick) {
      setTopbarNotificationMenuActive(false);
    }

    // if (!rightMenuClick) {
    //   setRightMenuActive(false);
    // }

    if (!menuClick) {
      if (isSlim() || isHorizontal()) {
        setMenuActive(false);
      }

      if (overlayMenuActive || staticMenuMobileActive) {
        hideOverlayMenu();
      }

      unblockBodyScroll();
    }

    if (configActive && !configClick) {
      setConfigActive(false);
    }

    // searchClick = false;
    configClick = false;
    userMenuClick = false;
    // rightMenuClick = false;
    notificationMenuClick = false;
    menuClick = false;
  };

  const onMenuClick = () => {
    menuClick = true;
  };

  const onMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    menuClick = true;
    setTopbarUserMenuActive(false);
    setTopbarNotificationMenuActive(false);
    // setRightMenuActive(false);

    if (isOverlay()) {
      setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
    }

    if (isDesktop()) {
      setStaticMenuDesktopInactive(
        (prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive
      );
    } else {
      setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
    }

    event.preventDefault();
  };

  /**
   * Mobile is considered to be less than 1091px
   * @returns true if the window width is less than 1091px
   */
  const isMobile = () => window.innerWidth <= 1091;

  useEffect(() => {
    const updateWindowDimensions = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  const onMenuitemClick = (event: any) => {
    if (!event.item.items) {
      hideOverlayMenu();

      if (isSlim() || isHorizontal()) {
        setMenuActive(false);
      }
    }
  };

  const onRootMenuitemClick = () => {
    setMenuActive((prevMenuActive) => !prevMenuActive);
  };

  const onMenuThemeChange = (name: string) => {
    setMenuTheme('layout-sidebar-' + name);
  };

  const onMenuModeChange = (e: { value: string }) => {
    setMenuMode(e.value);
    if (e.value === MENU_MODE_STATIC) {
      setStaticMenuDesktopInactive(false);
    }
  };

  const onTopbarUserMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    userMenuClick = true;
    setTopbarUserMenuActive((prevTopbarUserMenuActive) => !prevTopbarUserMenuActive);

    hideOverlayMenu();

    event.preventDefault();
  };

  const onTopbarNotificationMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    notificationMenuClick = true;
    setTopbarNotificationMenuActive(
      (prevTopbarNotificationMenuActive) => !prevTopbarNotificationMenuActive
    );

    hideOverlayMenu();

    event.preventDefault();
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
    unblockBodyScroll();
  };

  const blockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' '
      );
    }
  };

  const isSlim = () => {
    return menuMode === 'slim';
  };

  const isHorizontal = () => {
    return menuMode === 'horizontal';
  };

  const isOverlay = () => {
    return menuMode === 'overlay';
  };

  const isDesktop = () => {
    return window.innerWidth > 1091;
  };

  const containerClassName = classNames(
    'layout-wrapper',
    {
      'layout-overlay': menuMode === 'overlay',
      'layout-static': menuMode === 'static',
      'layout-slim': menuMode === 'slim',
      'layout-horizontal': menuMode === 'horizontal',
      'layout-sidebar-dim': colorScheme === 'dim',
      'layout-sidebar-dark': colorScheme === 'dark',
      'layout-overlay-active': overlayMenuActive,
      'layout-mobile-active': staticMenuMobileActive,
      'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static'
      // 'p-input-filled': inputStyle === 'filled',
      // 'p-ripple-disabled': !ripple
    },
    colorScheme === 'light' ? menuTheme : ''
  );

  /* - - - - - - - - - - Version History - - - - - - - - - - */

  // const versionSelection = (version: VersionHistory) => {
  //   const versionedProjectDetail: ProjectDetailModel = {
  //     ...selectedProject,
  //     name: version.version_name,
  //     manager: version.version_description,
  //     slug: version.slug
  //   };

  //   // only when selecting going from development-project -> version
  //   if (!isVersionSelected) {
  //     // save dev version to go back to it eventually
  //     dispatch(saveDevelopmentProject(selectedProject));
  //   }

  //   dispatch(selectVersion(version));
  //   ProjectsService.activateProject({ dispatch, project: versionedProjectDetail });
  //   navigate(routes.PROJECT_HOME);
  //   dispatch(createToast(`viewing version "${version.version_name}"`, 'info'));
  // };

  const versionHistoryMenu = {
    label: 'Versions',
    icon: 'pi pi-fw pi-align-left',
    items: [
      {
        label: (
          <div className="flex align-content-center">
            {selectedVersion?.version_name || 'Select a version'}
            <i className="m-auto ml-4 pi pi-chevron-down" />
          </div>
        ),
        command: (e: MenuItemCommandEvent) => versionListMenuRef?.current?.toggle(e.originalEvent)
      },
      {
        label: 'Version Manager',
        icon: 'pi pi-briefcase',
        command: () => setDisplayVersionHistory(true)
      }
    ]
  };

  // const developmentMenuItem: MenuItem = {
  //   label: 'Development',
  //   disabled: !isVersionSelected,
  //   command: () => {
  //     ProjectsService.activateProject({ dispatch, project: developmentProject });
  //     dispatch(clearSelectedVersion());
  //     navigate(routes.PROJECT_HOME);
  //     dispatch(createToast('viewing Development version', 'info'));
  //   }
  // };

  // const versionListMenu: MenuItem[] = versions.map((version) => ({
  //   label: version.version_name,
  //   disabled: isVersionSelected && version.slug === selectedVersion.slug,
  //   command: () => versionSelection(version)
  // }));

  return !isBrowserSupported ? (
    <BrowserNotSupported macOs={isMacOS} />
  ) : (
    <div className={containerClassName} data-theme={colorScheme} onClick={onDocumentClick}>
      <BrandBanner className="fixed top-0 left-0" brandBannerRef={brandBannerRef} />
      <div
        className="layout-content-wrapper relative"
        style={{
          height: `calc(100vh - ${bannerHeight}px)`,
          paddingTop: isMobile()
            ? `calc(${bannerHeight || 0}px + 7.125rem)`
            : `${bannerHeight || 0}px`
        }}
      >
        {layout !== NO_LAYOUT ? (
          <AppTopbar
            bannerHeight={bannerHeight}
            routers={allRouters}
            topbarNotificationMenuActive={topbarNotificationMenuActive}
            topbarUserMenuActive={topbarUserMenuActive}
            onMenuButtonClick={onMenuButtonClick}
            onTopbarNotification={onTopbarNotificationMenuButtonClick}
            onTopbarUserMenu={onTopbarUserMenuButtonClick}
            toggleDarkMode={() =>
              changeColorScheme({ value: colorScheme === 'light' ? 'dark' : 'light' })
            }
            menu={[
              ...menu,
              ![
                SpectoLayout.adminProjectSettings,
                SpectoLayout.systemSettings,
                SpectoLayout.userSettings
              ].includes(layout) &&
                isProjectSelected &&
                user.is_superuser
            ]}
            menuMode={menuMode}
            menuActive={menuActive}
            staticMenuMobileActive={staticMenuMobileActive}
            onMenuClick={onMenuClick}
            onMenuitemClick={onMenuitemClick}
            onRootMenuitemClick={onRootMenuitemClick}
            menuExpanded={isMobile() ? staticMenuMobileActive : !staticMenuDesktopInactive}
          />
        ) : undefined}
        <div className="layout-content">
          <div className="layout-breadcrumb viewname" style={{ textTransform: 'uppercase' }}>
            <AppBreadcrumb routers={allRouters} />
          </div>

          <ErrorBoundary>
            <Routes>
              <Route
                element={
                  <GuardedRoute
                    redirectPath={routes.LOGIN}
                    guards={[guard(isLoggedIn, guardWarning.login)]}
                  />
                }
              >
                <Route
                  element={
                    <GuardedRoute
                      redirectPath={routes.HOME}
                      guards={[
                        guard(isProjectSelected, guardWarning.project),
                        guard(
                          permissionBoolean(
                            ProjectsService.roles.project.update,
                            selectedProject?.project_user?.role
                          ),
                          guardWarning.admin
                        ),
                        guard(!isVersionSelected, guardWarning.version)
                      ]}
                    />
                  }
                  path={routes.PROJECT_SETTINGS}
                >
                  {projectSettingsRouter.map((router, index) => (
                    <Route key={`router${index}`} path={router.path} element={router.component} />
                  ))}
                </Route>

                <Route
                  element={
                    <GuardedRoute
                      redirectPath={routes.HOME}
                      guards={[guard(user.is_superuser, guardWarning.neurosoph)]}
                    />
                  }
                  path={routes.SYSTEM_SETTINGS}
                >
                  {systemSettingsRouter.map((router, index) => (
                    <Route key={`router${index}`} path={router.path} element={router.component} />
                  ))}
                </Route>

                <Route path={routes.USER_SETTINGS}>
                  {userSettingsRouter.map((router, index) => (
                    <Route key={`router${index}`} path={router.path} element={router.component} />
                  ))}
                </Route>

                <Route
                  element={
                    <GuardedRoute
                      redirectPath={routes.HOME}
                      guards={[guard(isProjectSelected, guardWarning.project)]}
                    />
                  }
                >
                  {pageRouter.map((router, index) => (
                    <Route key={`router${index}`} path={router.path} element={router.component} />
                  ))}
                </Route>

                {mainRouter.map((router, index) => (
                  <Route key={`router${index}`} path={router.path} element={router.component} />
                ))}
              </Route>
            </Routes>
          </ErrorBoundary>
        </div>
      </div>
      <VersionManager
        displayPopup={displayVersionHistory}
        onPopupDisplayChange={setDisplayVersionHistory}
      />
      {/* <Menu model={[developmentMenuItem, ...versionListMenu]} popup ref={versionListMenuRef} /> */}
    </div>
  );
};

export default App;
