import React, { useState } from 'react';
// prime react
import { DataTable, DataTableStateEvent, DataTableValue } from 'primereact/datatable';
import { Column } from 'primereact/column';
// components
import { LastModifiedCell, TableHeader, TagsCell, TextCell } from 'components/Table';
// selectors
import { useAppSelector } from 'hooks/store';
import {
  projectsSelector,
  tagsSelector,
  contentFiltersSelector
} from 'features/projects/projectsSlice';
// models
import { ResponseTableDataModel, ResponseTypeOptions } from 'models/response-table-data-model';
// utils
import { mapTags } from 'util/tags';
// constants
import { CommentPopup, ThreadStateChangeEvent } from 'pages/Comments/CommentPopup';
import { CommentType } from 'models/comment-model';
import { versionsSelector } from 'features/versions/versionsSlice';
import { confirmDialog } from 'primereact/confirmdialog';
import { RemoveRetiredDialog, RetireDialog } from 'components/Table/RetireDialog';
import { Status } from 'models/status-model';
import { statusDisplayNames } from 'constants/status';
import { ProjectMemberUserModel, ProjectRole } from 'models/project-model';
import { MenuButtonCell } from 'components/Table/MenuButtonCell';
import { CommentThreadButton } from 'pages/Comments/CommentThreadButton';
import { permissionBoolean } from 'util/permissions';
import { customSortIconFn } from 'util/table';
import paginatorTemplate from 'components/Table/PaginatorTemplate';
import UserGroupsCell from 'components/UserGroups/UserGroupsCell';
import { UserGroupModel } from 'models/user-groups-model';
import { userSharesUserGroupWith } from 'util/userGroups';
import { isNotAuthorOrAdmin } from 'util/users';
import { TopicTableDataModel } from 'models/topic-model';
import { mapIdToName } from 'util/mapIdAndName';
import ViewAllLinkChipsCell from 'components/Table/ViewAllLinkChipsCell';
import AvatarCell from 'components/Avatar/AvatarCell';
import ClickableTextCell from 'components/Table/ClickableTextCell';
import { Tooltip } from 'primereact/tooltip';
import { Badge } from 'primereact/badge';

type ResponseDataTablePropsModel = {
  allTopics: TopicTableDataModel[];
  allUserGroups: UserGroupModel[];
  canDelete?: boolean;
  canPublish?(node: ResponseTableDataModel): boolean;
  isSuperUser?: boolean;
  isAdmin?: boolean;
  onApproveResponse?(node: ResponseTableDataModel): void;
  onDeleteResponse?(e: ResponseTableDataModel): void;
  onDuplicateResponse?(e: ResponseTableDataModel, isActionRow: boolean): void;
  onEditInit?(e: ResponseTableDataModel, isActionRow: boolean): void;
  onHistoryOpen(e: ResponseTableDataModel): void;
  onPublishResponse?(node: ResponseTableDataModel): void;
  onRetireChange?(e: ResponseTableDataModel, isRetired: boolean): void;
  onThreadStateChange?(options: ThreadStateChangeEvent): void;
  readOnly?: boolean;
  responses: ResponseTableDataModel[];
  showComments?: boolean;
  tablestate: DataTableStateEvent;
  onTableStateChange(tablestate: DataTableStateEvent): void;
  totalRecords: number;
  loading?: boolean;
  authors: ProjectMemberUserModel[];
  projectUser: ProjectMemberUserModel;
};

/**
 * Component for displaying the Response Table.
 *
 * @param allTopics List of all topics
 * @param allUserGroups List of all user groups
 * @param canDelete Flag indicating if the user can delete
 * @param canPublish Function indicating if the user can publish
 * @param hideHistoryColumn show or hide the history column
 * @param isSuperUser Flag indicating if the user is a superuser
 * @param isAdmin Flag indicating if the user is an admin
 * @param loading Flag indicating if the table is loading
 * @param onApproveResponse Callback function to approve a response
 * @param onDeleteResponse Callback function for the delete response action
 * @param onDuplicateResponse Callback function for the duplicate response action
 * @param onEditInit Callback function for initializing intent edit
 * @param onPublishResponse Callback function for the publishing response action
 * @param onRetireChange Callback function for the retire response action
 * @param onTableStateChange Callback function for the table state change action
 * @param onThreadStateChange Callback function for the thread state change action
 * @param readOnly Flag indicating if the table is read-only
 * @param responses List of all responses
 * @param showComments Flag indicating if comments should be shown
 * @param tablestate Table state
 * @param totalRecords Total number of records
 * @param authors List of current project members
 * @param projectUser Current project user
 * @constructor
 * @component
 */
const ResponseDataTable = function ({
  allTopics,
  allUserGroups,
  canDelete = false,
  canPublish,
  isSuperUser = false,
  isAdmin = false,
  loading = false,
  onApproveResponse,
  onDeleteResponse,
  onDuplicateResponse,
  onEditInit,
  onHistoryOpen,
  onPublishResponse,
  onRetireChange,
  onTableStateChange,
  onThreadStateChange,
  readOnly = false,
  responses,
  showComments,
  tablestate,
  totalRecords,
  authors,
  projectUser
}: ResponseDataTablePropsModel) {
  const [selectedNode, setSelectedNode] = useState<ResponseTableDataModel>(
    {} as ResponseTableDataModel
  );
  const [showCommentPopup, setShowCommentPopup] = useState(false);
  const [showRetireDialog, setShowRetireDialog] = useState(false);

  const { selectedProject } = useAppSelector(projectsSelector);
  const version = useAppSelector(versionsSelector);

  const allTags = useAppSelector(tagsSelector);
  const contentFilters = useAppSelector(contentFiltersSelector);
  const isActionRow = (type: ResponseTypeOptions) => type === ResponseTypeOptions.CUSTOM_ACTION;

  const isLiveChat = (type: ResponseTypeOptions) => type === ResponseTypeOptions.LIVE_CHAT;

  const canEditIfAction = (firstContentType: ResponseTypeOptions): boolean =>
    isActionRow(firstContentType) ? isSuperUser : true;

  const canEditIfLiveChat = (firstContentType: ResponseTypeOptions): boolean =>
    isLiveChat(firstContentType) ? isSuperUser : true;

  const canEdit = (type: ResponseTypeOptions, node: DataTableValue): boolean =>
    !readOnly && node.status !== Status.Retired && canEditIfAction(type) && canEditIfLiveChat(type);

  const active = contentFilters?.active;
  const status = contentFilters?.status;
  /**
   * Delete confirmation popup
   * @param node node to be deleted
   */
  const confirmDeleteRow = (node: ResponseTableDataModel) => {
    confirmDialog({
      message: 'This action cannot be undone.',
      header: 'Delete Row?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => onDeleteResponse?.(node)
    });
  };

  // Strategists and admins can approve "New" or "Modified" statuses
  const canApprove = (node: ResponseTableDataModel) =>
    (node.status === Status.New || node.status === Status.Modified) &&
    permissionBoolean(
      [ProjectRole.ADMIN, ProjectRole.NEUROSOPH, ProjectRole.CONTENT_STRATEGIST],
      selectedProject.project_user.role
    );

  return (
    <div>
      <h6>{TableHeader({ tableType: 'Response', itemCount: totalRecords })}</h6>
      <DataTable<ResponseTableDataModel[]>
        className="specto-data-table"
        dataKey="id"
        sortIcon={customSortIconFn}
        value={responses}
        paginator
        paginatorTemplate={paginatorTemplate}
        lazy
        loading={loading}
        first={tablestate.first}
        rows={tablestate.rows}
        scrollable
        totalRecords={totalRecords}
        onPage={onTableStateChange}
        onSort={(e) => onTableStateChange({ ...e, page: 1, first: 0 })}
        sortField={tablestate.sortField}
        sortOrder={tablestate.sortOrder}
        editMode="row"
        onRowDoubleClick={(e) => onHistoryOpen(e.data as ResponseTableDataModel)}
        metaKeySelection={false}
        data-cy="response-data-table"
      >
        <Column
          field="name"
          className="specto-table__column--lg"
          bodyClassName="specto-table__column--text-button"
          header="Name"
          body={(node: ResponseTableDataModel) => (
            <div className="flex w-max p-overlay-badge">
              {ClickableTextCell({
                text: node.name,
                action: () => onHistoryOpen(node)
              })}
              {node.content?.[0]?.type === ResponseTypeOptions.CUSTOM_ACTION
                ? [
                    <Badge
                      className="specto-custom-action-badge"
                      id={`ca-${node.name}-${node.id}`}
                    />,
                    <Tooltip
                      target={`#ca-${node.name}-${node.id}`}
                      content="This is a Custom Action"
                    />
                  ]
                : undefined}
            </div>
          )}
          sortable
          pt={{ sort: { 'aria-label': 'sortable' } }}
        />
        <Column
          field="user_groups"
          className="specto-table__column--md"
          bodyClassName="specto-table__column--chips"
          header="User Groups"
          body={(node) =>
            UserGroupsCell({
              nodeId: node.id,
              userGroups: node.user_groups,
              referenceUserGroups: node.reference.user_groups,
              allUserGroups
            })
          }
        />
        <Column
          field="topics"
          className="specto-table__column--md"
          bodyClassName="specto-table__column--chips"
          header="Topics"
          body={(node) =>
            ViewAllLinkChipsCell({
              nodeId: 'topic-' + node.id,
              chips: node.topics.map((topic: number) => ({
                label: mapIdToName(topic, allTopics),
                url: `/topics?status=${status}&active=${active}&search=${mapIdToName(
                  topic,
                  allTopics
                )}`
              })),
              url: `/topics?status=${status}&active=${active}&responses=[${node.id}]`,
              emptyMessage: 'No Topics'
            })
          }
        />
        <Column
          field="author"
          header="Author"
          className="specto-table__column--sm"
          bodyClassName="specto-table__column--icon"
          align="center"
          body={(node: ResponseTableDataModel) => {
            const fullName = authors.find((pm) => pm.user === node?.author)?.name || '';
            return fullName ? <AvatarCell fullName={fullName} id={node.id} /> : null;
          }}
        />
        <Column
          field="last_modified"
          className="specto-table__column--md"
          bodyClassName="specto-table__column--text"
          header="Last Modified"
          body={(node) =>
            LastModifiedCell({
              nodeId: node.id,
              user: authors.find((author) => author.user === node.user),
              lastModified: node.last_modified
            })
          }
          sortable
          pt={{ sort: { 'aria-label': 'sortable' } }}
        />
        <Column
          field="status"
          header="Status"
          className="specto-table__column--sm"
          bodyClassName="specto-table__column--text"
          body={(node: ResponseTableDataModel) =>
            TextCell({ id: 'status-' + node.id, text: statusDisplayNames[node.status] })
          }
          sortable
          pt={{ sort: { 'aria-label': 'sortable' } }}
        />
        {isSuperUser && (
          <Column
            field="tags"
            header={<span className="pi pi-tag" />}
            className="specto-table-icon"
            bodyClassName="specto-table__column--icon-button"
            body={(node: ResponseTableDataModel) => (
              <TagsCell tags={mapTags(allTags, node.tags)} editable={false} />
            )}
          />
        )}
        <Column
          field="util-comments"
          hidden={!showComments || readOnly || version.isVersionSelected}
          header="Comments"
          alignHeader="center"
          className="specto-table__column--sm specto-table-icon"
          bodyClassName="specto-table__column--icon-button"
          body={(node: ResponseTableDataModel) => (
            <CommentThreadButton
              threadResolved={node.comment_thread_resolved}
              action={() => {
                setSelectedNode(node);
                setShowCommentPopup(true);
              }}
              data-cy={`comment-button-${node.id}`}
            />
          )}
        />
        <Column
          columnKey="util-action"
          className="specto-table__column--sm-2 border-left-1"
          bodyClassName="specto-table__column--button"
          header="Actions"
          alignHeader="center"
          align="center"
          hidden={readOnly || version.isVersionSelected}
          frozen
          alignFrozen="right"
          body={(node: ResponseTableDataModel) => (
            <div className="flex justify-content-center align-content-center">
              <MenuButtonCell
                data-cy={`${node.id}-actions`}
                menu={[
                  {
                    label: 'Approve',
                    visible: !readOnly && canApprove(node),
                    disabled:
                      !userSharesUserGroupWith(
                        selectedProject.project_user,
                        node.reference?.user_groups
                      ) && isNotAuthorOrAdmin(projectUser, node.reference?.author),
                    tooltip:
                      !userSharesUserGroupWith(
                        selectedProject.project_user,
                        node.reference?.user_groups
                      ) && isNotAuthorOrAdmin(projectUser, node.reference?.author)
                        ? 'You cannot make changes to this response.'
                        : undefined,
                    command: () => onApproveResponse?.(node)
                  },
                  {
                    label: 'Delete',
                    visible: canDelete && node.status === Status.Retired,
                    command: () => confirmDeleteRow(node)
                  },
                  {
                    label: 'Duplicate',
                    visible: !readOnly && node.status !== Status.Retired && isAdmin,
                    command: () => onDuplicateResponse?.(node, isActionRow(node.content?.[0]?.type))
                  },
                  {
                    label: 'Edit',
                    visible: canEdit(node.content?.[0]?.type, node),
                    disabled:
                      !userSharesUserGroupWith(
                        selectedProject.project_user,
                        node.reference?.user_groups
                      ) && isNotAuthorOrAdmin(projectUser, node.reference?.author),
                    tooltip:
                      !userSharesUserGroupWith(
                        selectedProject.project_user,
                        node.reference?.user_groups
                      ) && isNotAuthorOrAdmin(projectUser, node.reference?.author)
                        ? 'You cannot make changes to this response.'
                        : undefined,
                    command: () =>
                      onEditInit?.(
                        responses.filter((element) => element.id === node.id)[0],
                        isActionRow(node.content?.[0]?.type)
                      )
                  },
                  {
                    label: 'Publish',
                    visible: !readOnly && canPublish?.(node),
                    disabled:
                      !userSharesUserGroupWith(
                        selectedProject.project_user,
                        node.reference?.user_groups
                      ) && isNotAuthorOrAdmin(projectUser, node.reference?.author),
                    tooltip:
                      !userSharesUserGroupWith(
                        selectedProject.project_user,
                        node.reference?.user_groups
                      ) && isNotAuthorOrAdmin(projectUser, node.reference?.author)
                        ? 'You cannot make changes to this response.'
                        : undefined,
                    command: () => onPublishResponse?.(node)
                  },
                  {
                    label: 'Retire',
                    visible: !readOnly && canDelete && node.status !== Status.Retired,
                    command: () => {
                      setSelectedNode(node);
                      setShowRetireDialog(true);
                    }
                  },
                  {
                    label: 'Unretire',
                    visible: !readOnly && canDelete && node.status === Status.Retired,
                    command: () =>
                      RemoveRetiredDialog({
                        objectName: CommentType.RESPONSE,
                        accept: () => onRetireChange?.(node, false)
                      })
                  },
                  {
                    label: 'No Actions Available',
                    disabled: true,
                    visible: !canDelete && node.status === Status.Retired
                  }
                ]}
              />
            </div>
          )}
        />
      </DataTable>

      {/* Need to use dialog component instead of method because the Retire dialog
          needs to be closed before the delete dialog can be opened */}
      <RetireDialog
        objectName={CommentType.RESPONSE}
        accept={() => onRetireChange?.(selectedNode, true)}
        onHide={() => setShowRetireDialog(false)}
        showRetireDialog={showRetireDialog}
      />

      <CommentPopup
        displayPopup={showCommentPopup}
        onPopupDisplayChange={setShowCommentPopup}
        objectType={CommentType.RESPONSE}
        objectId={selectedNode.id}
        objectName={selectedNode.name}
        onThreadStateChange={onThreadStateChange}
        parentElement={document.activeElement as HTMLElement}
      />
    </div>
  );
};

export default ResponseDataTable;
