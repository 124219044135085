import ApiService from 'services/apiService';
import {
  SynonymTableDataModel,
  PostSynonymOptionsModel,
  EditSynonymOptionsModel,
  SynonymTableAPIModel,
  GetSynonymOptionsModel
} from 'models/synonym-model';
import { SYNONYMS_API_URL } from 'constants/api';
import { SlugIdOptionsModel, SlugOptionsModel } from 'models/api-model';
import { Permissions } from 'models/permission-model';
import { ProjectRole } from 'models/project-model';

const getSynonyms = (options: SlugOptionsModel) =>
  ApiService.get<SynonymTableDataModel[]>('/' + options.slug + SYNONYMS_API_URL);

const getSynonym = (options: GetSynonymOptionsModel) =>
  ApiService.get<SynonymTableDataModel>('/' + options.slug + SYNONYMS_API_URL + options.id + '/');

const createSynonym = (options: PostSynonymOptionsModel) =>
  ApiService.post<SynonymTableDataModel, SynonymTableAPIModel>(
    '/' + options.slug + SYNONYMS_API_URL,
    options.newSynonymData
  );

const deleteSynonym = (options: SlugIdOptionsModel) =>
  ApiService.remove<SynonymTableDataModel>(
    '/' + options.slug + SYNONYMS_API_URL + options.id + '/'
  );

const editSynonym = (options: EditSynonymOptionsModel) =>
  ApiService.put<SynonymTableDataModel>(
    '/' + options.slug + SYNONYMS_API_URL + options.synonymId + '/',
    options.updatedSynonymData
  );

const synonymsServiceRoles: Permissions = {
  list: [ProjectRole.NEUROSOPH],
  retrieve: [ProjectRole.NEUROSOPH],
  create: [ProjectRole.NEUROSOPH],
  update: [ProjectRole.NEUROSOPH],
  delete: [ProjectRole.NEUROSOPH]
};

const SynonymsService = {
  getSynonyms,
  getSynonym,
  createSynonym,
  deleteSynonym,
  editSynonym,
  roles: synonymsServiceRoles
};

export default SynonymsService;
