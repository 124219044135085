import { TextField } from 'components/Forms';
import AutocompleteField from 'components/Forms/AutocompleteField';
import MultiAutocompleteField from 'components/Forms/MultiAutocompleteField';
import RadioButtonField from 'components/Forms/RadioButtonField';
import Popup from 'containers/Popup';
import { authSelector } from 'features/auth/authSlice';
import { useAppSelector } from 'hooks/store';
import { FormMode } from 'models/form-model';
import { ProjectMemberUserModel, ProjectRole } from 'models/project-model';
import { UserGroupModel } from 'models/user-groups-model';
import { UserModel } from 'models/user-model';
import React, { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

/**
 * @param displayPopup Whether to display popup or not
 * @param onHide Handle closing the modal (cancel or close buttons)
 * @param parentElement The currently active parent element of the modal
 * @param context The current info (context) of the opened modal (name, role,user groups)
 * @param memberRolesOptions List of member roles
 * @param UserGroups List of user groups
 * @param onSubmit Handle submit after saving changes in edit modal (Enter key or Save button)
 * @param mode current popup mode (CREATE/EDIT)
 * @param suggestedUsers suggested users to add as members
 *
 * @component
 */
const MemberPopup = ({
  displayPopup,
  onHide,
  parentElement,
  context,
  memberRolesOptions,
  userGroups,
  onSubmit,
  mode,
  suggestedUsers
}: {
  displayPopup: boolean;
  onHide(): void;
  parentElement: HTMLElement;
  context: ProjectMemberUserModel | null;
  memberRolesOptions(isAdmin: boolean): {
    label: string;
    value: ProjectRole;
    hidden: boolean;
    id: number;
  }[];
  userGroups: UserGroupModel[];
  onSubmit(fields: FieldValues): void;
  mode: FormMode;
  suggestedUsers: UserModel[];
}) => {
  const { user: ownUser } = useAppSelector(authSelector);
  const { control, handleSubmit, reset, watch } = useForm();

  /**
   * Water for the role so that the user groups multiselect is dynamically
   * hidden/shown.
   */
  const watchRole = watch('role');

  useEffect(() => {
    /**
     * Sets the default form values.
     *
     * Needs to be done in an effect and not on useForm hook call because the
     * statuses are not immediately available on component mount. There is a
     * delay while they are pulled.
     */
    const defaultValues: FieldValues = context ?? {
      name: '',
      role: ProjectRole.GUEST,
      user_groups: []
    };
    reset(defaultValues);
  }, [context]);

  // reset when closing
  useEffect(() => {
    !displayPopup && reset();
  }, [displayPopup]);

  // Submit Member changes after saving
  const onPopupSave = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    handleSubmit(onSubmit)();
  };

  // Hide popup after closing popup or clicking cancel
  const hidePopup = () => {
    reset();
    onHide();
    parentElement?.focus();
  };

  return (
    <Popup
      title={mode === FormMode.CREATE ? 'Add Member' : 'Edit Member'}
      visible={displayPopup}
      onHide={hidePopup}
      onSave={onPopupSave}
      saveButtonText={mode === FormMode.CREATE ? 'Add Member' : 'Save Edits'}
    >
      <form onSubmit={onPopupSave} aria-hidden="true">
        {mode === FormMode.CREATE ? (
          <AutocompleteField
            labelClassName="line-height-3"
            required={true}
            control={control}
            options={suggestedUsers}
            optionLabel={(user) => `${user.first_name} ${user.last_name}`}
            optionValue="id"
            fieldName="user"
            label="User"
            placeholder="Choose a User"
          />
        ) : (
          <TextField
            required={false}
            control={control as any}
            className="w-full"
            fieldName="name"
            label="Name"
            defaultValue={context?.first_name + ' ' + context?.last_name}
            disabled
          />
        )}
        <RadioButtonField
          label="User Role"
          fieldName="role"
          labelClassName="line-height-3 font-bold"
          tooltip="You cannot change your own User Role."
          disabled={ownUser.id === context?.user}
          tooltipOptions={{ showOnDisabled: true, disabled: !(ownUser.id === context?.user) }}
          className="col specto-border"
          control={control}
          options={memberRolesOptions(context?.role === ProjectRole.NEUROSOPH)}
        />

        {watchRole !== ProjectRole.ADMIN && (
          <MultiAutocompleteField
            className="w-full"
            labelClassName="line-height-3 font-bold"
            fieldName="user_groups"
            label="User Groups"
            control={control}
            options={userGroups}
            optionValue="id"
            optionLabel="name"
            placeholder="Select User Groups"
            required={false}
            noPadding
          />
        )}
        {watchRole === ProjectRole.ADMIN && (
          <>
            <label className="line-height-3 font-bold">User Groups</label>
            <div className="specto-border p-3 bg-white-alpha-20	border-2 flex flex-row align-items-center gap-2">
              <i className="pi pi-info-circle text-primary"></i>
              Admins are part of all User Groups
            </div>
          </>
        )}
      </form>
    </Popup>
  );
};

export default MemberPopup;
