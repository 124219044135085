import React from 'react';
import TruncatedLinkChip from './TruncatedLinkChip';

/**
 * Component for displaying chips in a cell
 *
 * @param nodeId id of the node
 * @param chips list of chips to display
 * @component
 */
const LinkChipsCell = function ({
  nodeId,
  chips
}: {
  nodeId: number | string;
  chips?: { label: string; url: string }[];
}) {
  return (
    <div id={'specto-chips-cell-' + nodeId} className="select-none">
      {chips?.map((chip, i) => {
        const chipId = `chip-${nodeId}-${i}`;

        return chip ? (
          <TruncatedLinkChip chipId={chipId} label={chip.label} nav_url={chip.url} />
        ) : (
          <span key={chipId} />
        );
      })}
    </div>
  );
};

export default LinkChipsCell;
