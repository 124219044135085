import { Status } from 'models/status-model';
import { ProposedNewIntentWarning } from 'constants/warnings';
import { IntentTableDataModel } from 'models/intent-table-data-model';

/**
 * Finds all models that are not retired
 *
 * @param models models to filter
 * @param currentId ids to exclude from being filtered
 * @returns list of models that are not retired
 */
export const filterRetired = <T extends { id: number; status: Status }>(
  models: T[],
  currentId?: number[] | number
) => {
  const ids = Array.isArray(currentId) ? currentId : [currentId];
  return models.filter((model) => model.status !== Status.Retired || ids?.includes(model.id));
};

/**
 * Get Status warnings of intents
 * @param intentId intent id's
 * @param allIntents all intents
 */
export const getStatusWarnings = (
  intentId: IntentTableDataModel['id'],
  allIntents: IntentTableDataModel[]
) => {
  const intent = allIntents.find((intent) => intent.id === intentId);
  return intent?.status === Status.New ? ProposedNewIntentWarning : '';
};
