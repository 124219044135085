import React, { CSSProperties, useContext } from 'react';
import { Button } from 'primereact/button';
import { ResponseFormContext } from './ResponseFormContext';
import classnames from 'classnames';
import MoveComponent from 'components/Forms/MoveComponent';
import { UseFieldArrayMove } from 'react-hook-form';

type ResponseDropdownProps = {
  children: React.JSX.Element;
  className?: string;
  componentIndex: number;
  deleteLabel?: string;
  fieldLength?: number;
  move?: UseFieldArrayMove;
  noDivider?: boolean;
  remove?(index: number): void;
  style?: CSSProperties;
  title: string;
};

/**
 * Renders a custom response type with the option of adding a title and more options.
 *
 * @param children children to be rendered
 * @param className class name
 * @param componentIndex component index
 * @param deleteLabel label for the delete button
 * @param fieldLength current field length
 * @param move move field callback
 * @param noDivider whether to show a divider or not
 * @param remove remove field callback
 * @param style custom style
 * @param title field title
 * @constructor
 */
const ResponseComponentContainer = function ({
  title,
  children,
  className,
  componentIndex,
  noDivider = false,
  deleteLabel = 'DELETE',
  fieldLength,
  move,
  remove,
  style
}: ResponseDropdownProps) {
  const { contentFields, removeContent, moveField } = useContext(ResponseFormContext);

  return (
    <div className={classnames(className, 'flex flex-wrap')} style={style}>
      <div className="flex w-full align-items-center">
        <h5 className="mb-0">{title}</h5>
        <MoveComponent
          componentIndex={componentIndex}
          move={move ? move : moveField}
          length={fieldLength ?? contentFields.length}
        />
        <Button
          label={deleteLabel}
          className="specto-text-medium font-semibold p-button-text underline text-sm py-2 w-auto"
          type="button"
          onClick={() => (remove ? remove(componentIndex) : removeContent(componentIndex))}
          data-cy={`remove-item-${componentIndex}`}
        />
      </div>
      {children}
      {!noDivider && componentIndex !== contentFields?.length - 1 && <hr className="w-full my-4" />}
    </div>
  );
};

export default ResponseComponentContainer;
