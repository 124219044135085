import React from 'react';
import { useState } from 'react';
interface BrowserNotSupportedProps {
  macOs: boolean;
}

const BrowserNotSupported = (props: BrowserNotSupportedProps) => {
  const [logoUrl, setLogoUrl] = useState('assets/layout/images/askma-logo-light.svg');
  return (
    <div className="flex bg-white m-0 h-full">
      <div className="browser-support-body border-round-3xl">
        <img
          src={logoUrl}
          alt="AskMa Logo"
          className="mb-5 sm:max-w-15rem md:max-w-18rem max-w-13rem mt-4"
        />
        <h1 className="browser-support-header m-0 pr-1 pl-1 md:text-5xl sm:text-3xl text-2xl ml-3 mr-3">
          We are sorry,
        </h1>
        <h1 className="browser-support-header m-0 pr-1 pl-1 md:text-5xl sm:text-3xl text-2xl ml-3 mr-3">
          we don't support this browser
        </h1>
        <p className="mt-3 md:text-2xl sm:text-xl text-lg mr-3 ml-3">
          Please consider using one of the following supported browsers:
        </p>
        <ul className="w-full list-none flex p-0 justify-content-center align-items-center gap-3 mt-6 md:text-2xl sm:text-xl text-lg flex-wrap">
          <span>
            <a href="https://www.google.com/intl/en_ca/chrome/" target="_">
              {' '}
              <li className="browser-support-li">
                <img
                  className="sm:h-4rem sm:w-4rem md:h-6rem md:w-6rem h-4rem w-4rem"
                  src="https://www.google.com/chrome/static/images/chrome-logo-m100.svg"
                  alt="Google chrome download link"
                />
                Google Chrome
              </li>
            </a>
          </span>

          <span>
            <a href="https://www.mozilla.org/en-CA/firefox/new/" target="_">
              {' '}
              <li className="browser-support-li">
                <img
                  className="sm:h-4rem sm:w-4rem md:h-6rem md:w-6rem h-4rem w-4rem"
                  src="https://www.mozilla.org/media/img/favicons/firefox/browser/apple-touch-icon.79c2abeb4e35.png"
                  alt="Mozilla Firefox download link"
                />
                Mozilla Firefox
              </li>
            </a>
          </span>
          <span>
            <a href="https://www.microsoft.com/en-us/edge/download" target="_">
              {' '}
              <li className="browser-support-li">
                <img
                  className="sm:h-4rem sm:w-4rem md:h-6rem md:w-6rem h-4rem w-4rem"
                  src="https://edgestatic.azureedge.net/shared/cms/lrs1c69a1j/section-images/29bfeef37eef4ca3bcf962274c1c7766.png"
                  alt="Microsoft Edge download link"
                />
                Microsoft Edge
              </li>
            </a>
          </span>
          {props.macOs && (
            <span>
              <a href="https://support.apple.com/downloads/safari" target="_">
                {' '}
                <li className="browser-support-li">
                  <img
                    className="sm:h-4rem sm:w-4rem md:h-6rem md:w-6rem h-4rem w-4rem"
                    src="https://support.apple.com/kb/image.jsp?productid=PL165&size=240x240"
                    alt="Apple Safari download link"
                  />
                  Apple Safari
                </li>
              </a>
            </span>
          )}
        </ul>
      </div>
    </div>
  );
};

export default BrowserNotSupported;
