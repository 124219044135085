import { ProjectRole } from 'models/project-model';
import { Permissions } from 'models/permission-model';

export const OPEN_VIEW_ADMIN_MODIFY_PERMISSIONS: Permissions = {
  list: true,
  retrieve: true,
  create: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN],
  update: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN],
  delete: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN]
};

export const OPEN_PERMISSIONS: Permissions = {
  list: true,
  retrieve: true,
  create: true,
  update: true,
  delete: true
};

export const NON_GUEST_ROLES: ProjectRole[] = [
  ProjectRole.NEUROSOPH,
  ProjectRole.ADMIN,
  ProjectRole.CONTENT_STRATEGIST,
  ProjectRole.CONTENT_AUTHOR
];
