import React, { useContext, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { LookupTableDataModel, LookupTableDataTablePropsModel } from 'models/lookup-table-model';
import { CommentPopup } from 'pages/Comments/CommentPopup';
import { CommentType } from 'models/comment-model';
import { CommentThreadButton } from 'pages/Comments/CommentThreadButton';
import { mapIdToName } from 'util/mapIdAndName';
import { LastModifiedCell, TableHeader, TextCell } from 'components/Table';
import { confirmDialog } from 'primereact/confirmdialog';
import { useAppSelector } from 'hooks/store';
import { versionsSelector } from 'features/versions/versionsSlice';
import { MenuButtonCell } from 'components/Table/MenuButtonCell';
import { customSortIconFn } from 'util/table';
import LookupTableContext from '../../../context/LookupTableContext';
import paginatorTemplate from 'components/Table/PaginatorTemplate';

/**
 * Component for displaying the Lookup Data Table.
 *
 * @param allEntities List of all entities
 * @param canDelete permission to delete lookuptable
 * @param lookupTables List of lookup tables
 * @param onDeleteLookupTable Callback function for deleting a lookup table
 * @param onEdit Callback function for editing a lookup table
 * @param onThreadStateChange Callback function for the thread state change action
 * @param readOnly Flag indicating if the table is read-only
 * @param showComments if the comments should be shown
 *
 * @constructor
 * @component
 */
const LookupTableDataTable = function ({
  onEditInit,
  showComments
}: LookupTableDataTablePropsModel) {
  const version = useAppSelector(versionsSelector);
  // Store the lookup table that will be retired or deleted
  const [selectedNode, setSelectedNode] = useState<LookupTableDataModel>(
    {} as LookupTableDataModel
  );
  const [showCommentPopup, setShowCommentPopup] = useState(false);

  /**
   * Context Consumer
   */
  const {
    filteredLookupTables: lookupTables,
    onThreadChange: onThreadStateChange,
    deleteTableLookup: onDeleteLookupTable,
    canDeleteLookupTable: canDelete,
    canEditLookupTable: readOnly,
    allEntities
  } = useContext(LookupTableContext);

  /* - - - - - - - - - - Dialogs - - - - - - - - - - */

  /**
   * Opens the comments dialog
   * @param node object to open the comments dialog for
   */
  const openComments = (node: LookupTableDataModel) => {
    setSelectedNode(node);
    setShowCommentPopup(true);
  };

  const handleEdit = (node: LookupTableDataModel) => {
    !version.isVersionSelected && !readOnly && onEditInit?.(node);
  };

  /**
   * Delete confirmation popup
   * @param node node to delete
   */
  const confirmDeleteRow = (node: LookupTableDataModel) => {
    confirmDialog({
      message: (
        <div>
          <h6>This action cannot be undone.</h6>
          <ul className="list-disc">
            <li>This Lookup Table cannot be recovered.</li>
          </ul>
        </div>
      ),
      header: 'Delete Row?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => onDeleteLookupTable?.(node)
    });
  };

  return (
    <div>
      <h6>{TableHeader({ tableType: 'Lookup Table', itemCount: lookupTables.length })}</h6>
      <DataTable
        className="specto-lookup-table__table"
        dataKey="id"
        value={lookupTables}
        sortIcon={customSortIconFn}
        paginator
        paginatorTemplate={paginatorTemplate}
        selectionAutoFocus={false}
        rows={5}
        editMode="row"
        onRowDoubleClick={(e: any) => handleEdit(e.data)}
        metaKeySelection={false}
      >
        <Column
          field="entity"
          className="specto-table__column--lg"
          header="Entity"
          body={(node) => (
            <TextCell id={node.id} text={mapIdToName(node.entity, allEntities) ?? ''} />
          )}
        />
        <Column
          field="phrases"
          className="specto-table__column--lg"
          header="Phrases"
          sortable
          body={(node) =>
            node.phrases.length > 5
              ? `${node.phrases.slice(0, 5).join(', ')}... and ${node.phrases.length - 5} more`
              : node.phrases.join(', ')
          }
        />
        <Column
          field="last_modified"
          className="specto-table__column--md"
          header="Last Modified"
          alignHeader="left"
          align="left"
          body={(node) =>
            LastModifiedCell({
              nodeId: node.id,
              user: node.user,
              lastModified: node.last_modified
            })
          }
          sortable
        />
        <Column
          field="util-comments"
          hidden={!showComments || readOnly || version.isVersionSelected}
          header="Comments"
          className="specto-table-icon"
          body={(node: LookupTableDataModel) => (
            <CommentThreadButton
              threadResolved={node.comment_thread_resolved}
              action={() => openComments(node)}
            />
          )}
        />
        <Column
          columnKey="util-action"
          className="specto-table__column--sm-2 pl-0"
          header="Actions"
          alignHeader="center"
          align="center"
          rowEditor
          hidden={readOnly || version.isVersionSelected}
          body={(node: LookupTableDataModel) => (
            <MenuButtonCell
              data-cy={`${node.id}-actions`}
              menu={[
                {
                  label: 'Edit',
                  command: () => onEditInit?.(node)
                },
                {
                  label: 'Delete',
                  visible: canDelete,
                  command: () => confirmDeleteRow(node)
                }
              ]}
            />
          )}
        />
      </DataTable>

      <CommentPopup
        displayPopup={showCommentPopup}
        onPopupDisplayChange={setShowCommentPopup}
        objectType={CommentType.LOOKUP_TABLE}
        objectId={selectedNode.id}
        objectName={mapIdToName(selectedNode.entity, allEntities) ?? ''}
        parentElement={document.activeElement as HTMLElement}
        onThreadStateChange={onThreadStateChange}
      />
    </div>
  );
};

export default LookupTableDataTable;
