import { SpectoMenu } from 'models/menu-model';
import { routes } from 'constants/routes';
import { ReactComponent as AccessibilityIcon } from 'assets/accessibility/accessibility-icon.svg';
import { ReactComponent as ConversationViewerIcon } from 'assets/forum/forum-icon.svg';
import React from 'react';

// menu mode
export const MENU_MODE_OVERLAY = 'overlay';
export const MENU_MODE_STATIC = 'static';
export const MENU_MODE_SLIM = 'slim';
export const MENU_MODE_HORIZONTAL = 'horizontal';

const baseMenu = (hideDashboard = false) => {
  const menuItems: SpectoMenu = [
    {
      label: 'Projects',
      icon: 'pi pi-fw pi-home',
      items: [
        {
          label: 'Analytics',
          icon: 'pi pi-fw pi-chart-line',
          to: '/analytics'
        }
      ]
    },
    { separator: true }
  ];

  if (!hideDashboard && 'items' in menuItems[0]) {
    menuItems[0]?.items?.unshift({
      label: 'Dashboard',
      icon: 'pi pi-fw pi-home',
      to: routes.HOME
    });
  }

  return menuItems;
};

export const infoMenu: SpectoMenu = [
  {
    label: '',
    icon: '',
    className: 'mt-auto',

    items: [
      {
        label: 'Need Help?',
        icon: 'pi pi-question-circle',
        url: process.env.REACT_APP_DOCUMENTATION_URL
      },
      {
        label: 'Privacy Policy',
        icon: 'pi pi-shield',
        url: process.env.REACT_APP_PRIVACY_POLICY_URL
      },
      {
        label: 'Accessibility Policy',
        icon: <AccessibilityIcon className="layout-menuitem-icon-svg" />,
        url: process.env.REACT_APP_ACCESSIBILITY_POLICY_URL
      }
    ]
  }
];

const botContent = (superUser = false, isAdmin = false) => {
  return {
    label: 'Bot Content',
    icon: 'pi pi-fw pi-id-card',
    items: [
      {
        label: 'Topics',
        icon: 'pi pi-fw pi-list',
        to: '/topics'
      },
      {
        label: 'Responses',
        icon: 'pi pi-fw pi-chart-line',
        to: '/responses'
      },
      {
        label: 'Intents',
        icon: 'pi pi-fw pi-id-card',
        to: routes.INTENTS
      },
      {
        label: 'Conversation Viewer',
        icon: <ConversationViewerIcon className="layout-menuitem-icon-svg" />,
        to: routes.CONVERSATION_VIEWER
      },
      ...(superUser
        ? [
            {
              label: 'Entities',
              icon: 'pi pi-ellipsis-v',
              items: [
                {
                  label: 'Entity Table',
                  icon: 'pi pi-fw pi-hashtag',
                  to: '/entities'
                },
                {
                  label: 'Entity Groups',
                  icon: 'pi pi-fw pi-box',
                  to: '/entity-groups'
                },
                {
                  label: 'Synonyms',
                  icon: 'pi pi-fw pi-arrows-h',
                  to: '/synonyms'
                },
                {
                  label: 'Regular Expressions',
                  icon: 'pi pi-fw pi-question',
                  to: '/regex'
                },
                {
                  label: 'Lookup Tables',
                  icon: 'pi pi-fw pi-table',
                  to: '/lookuptables'
                }
              ]
            }
          ]
        : [])
    ]
  };
};

// Menu for non-superusers/admins that have access to multiple projects
export const mainMenu: SpectoMenu = [...baseMenu(), botContent(), ...infoMenu];

// Menu for non-superusers/admins that only have access to one project
export const mainMenuNoDashboard: SpectoMenu = [...baseMenu(true), botContent(), ...infoMenu];

// Menu for admins that have access to multiple projects
export const adminMainMenu: SpectoMenu = [...baseMenu(), botContent(false, true), ...infoMenu];

// Menu for admins that only have access to one project
export const adminMainMenuNoDashboard: SpectoMenu = [
  ...baseMenu(true),
  botContent(false, true),
  ...infoMenu
];

// Menu for superusers
export const superUserMenu: SpectoMenu = [
  ...baseMenu(),
  botContent(true),
  { separator: false },
  {
    label: 'NLU Annotation',
    icon: 'pi pi-fw pi-id-card',
    items: [
      {
        label: 'Message Inbox',
        icon: 'pi pi-fw pi-inbox',
        to: '/message-inbox'
      },
      {
        label: 'Conversation Data',
        icon: 'pi pi-fw pi-comments',
        to: routes.CONVERSATIONS
      }
    ]
  },
  { separator: false },
  {
    label: 'Training',
    icon: 'pi pi-fw pi-id-card',
    items: [{ label: 'NLU Data', icon: 'pi pi-fw pi-th-large', to: '/nlu-data' }]
  },
  { separator: false },
  ...infoMenu
];

const projectSettingsMenu = (isSuperUser = false): SpectoMenu => [
  {
    label: 'Projects',
    icon: 'pi pi-fw pi-home',
    items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: routes.HOME }]
  },
  { separator: true },
  {
    label: 'Project Settings',
    icon: 'pi pi-fw pi-cog',
    items: [
      {
        label: 'General Settings',
        icon: 'pi pi-fw pi-cog',
        to: '/project-settings/general'
      },
      {
        label: 'Members',
        icon: 'pi pi-fw pi-users',
        to: '/project-settings/members'
      },
      {
        label: 'User Groups',
        icon: 'pi pi-fw pi-users',
        to: routes.USER_GROUPS
      },
      {
        label: 'Intent Categories',
        icon: 'pi pi-fw pi-id-card',
        to: '/project-settings/intent-category-manager'
      },
      ...(isSuperUser
        ? [
            {
              label: 'Tag Manager',
              icon: 'pi pi-fw pi-tags',
              to: '/project-settings/tag-manager'
            },
            {
              label: 'Event Database Manager',
              icon: 'pi pi-fw pi-database',
              to: routes.EVENT_DATABASE_MANAGER
            },
            {
              label: 'Response Database Manager',
              icon: 'pi pi-fw pi-database',
              to: routes.RESPONSE_DATABASE_MANAGER
            }
          ]
        : [])
    ]
  },
  ...infoMenu
];

export const adminProjectSettingsMenu = [...projectSettingsMenu()];
export const superUserProjectSettingsMenu = [...projectSettingsMenu(true)];

export const systemSettingsMenu: SpectoMenu = [
  {
    label: 'Projects',
    icon: 'pi pi-fw pi-home',
    items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: routes.HOME }]
  },
  { separator: true },
  {
    label: 'System',
    icon: 'pi pi-fw pi-cog',
    items: [
      {
        label: 'System Users',
        icon: 'pi pi-fw pi-cog',
        to: routes.SYSTEM_USERS
      }
      // {
      //   label: 'User Groups',
      //   icon: 'pi pi-fw pi-users',
      //   to: routes.USER_GROUPS
      // }
    ]
  }
];

export const userSettingsMenu: SpectoMenu = [
  {
    label: 'Projects',
    icon: 'pi pi-fw pi-home',
    items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: routes.HOME }]
  },
  { separator: true },
  {
    label: 'User',
    icon: 'pi pi-fw pi-cog',
    items: [
      {
        label: 'User Settings',
        icon: 'pi pi-fw pi-user',
        to: routes.USER_SETTINGS
      },
      ...(process.env.REACT_APP_USE_SSO !== 'true'
        ? [
            {
              label: 'Security Settings',
              icon: 'pi pi-fw pi-lock',
              to: routes.USER_SETTINGS + '/security'
            }
          ]
        : []),
      {
        label: 'Notification Settings',
        icon: 'pi pi-fw pi-bell',
        to: routes.USER_SETTINGS + '/notifications'
      }
    ]
  }
];
