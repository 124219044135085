import React, { useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
// components
import Popup from 'containers/Popup';
import { TextField } from 'components/Forms';
import ColorSelect from 'components/ColorSelect';
import NumberField from 'components/Forms/NumberField';
import DateTimeField from 'components/Forms/DateTimeField';
// utils
import classNames from 'classnames';
// models
import { FetchStatusOptions } from 'constants/fetchStatus';
import { PostBatchMessagesOptionsModel } from 'models/message-inbox-table-data-model';
// constants
import { pipeValueToHex } from 'util/color';

type NewIntentPopupPropsModel = {
  displayPopup: boolean;
  onPopupDisplayChange: (shouldShow: boolean) => void;
  parentElement?: HTMLElement;
  postBatchMessages: (options: PostBatchMessagesOptionsModel) => void;
  postBatchMessagesStatus: FetchStatusOptions;
  projectSlug: string;
};

type BatchMessagesFormModel = {
  color: string;
  date: Date[];
  max_batch_size: number;
  min_group_size: number;
  name: string;
};

/**
 * Component that displays a popup to create a new intent.
 *
 * @component
 * @param displayPopup boolean that determines whether the popup should show
 * @param onPopupDisplayChange function to hide or show the popup
 * @param parentElement element that triggers this popup
 * @param postBatchMessages function that makes the post request to batch messages
 * @param postBatchMessagesStatus status of the batch message request
 * @param projectSlug slug of the project
 */
const BatchMessagesPopup = function ({
  displayPopup,
  onPopupDisplayChange,
  parentElement,
  postBatchMessages,
  postBatchMessagesStatus,
  projectSlug
}: NewIntentPopupPropsModel) {
  const [isSubmitLoading, setSubmitLoading] = useState(false); // is the popup submit loading

  const { control, handleSubmit, reset } = useForm();

  /* - - - - - - - - - - Effects - - - - - - - - - - */

  useEffect(() => {
    /**
     * Sets the default form values.
     *
     * Needs to be done in an effect and not on useForm hook call because the statuses are not
     * immediately available on component mount. There is a delay while they are pulled.
     */
    const defaultValues: FieldValues = {
      name: '',
      color: '#FF0000',
      date: null,
      min_group_size: 0,
      max_batch_size: 0
    };
    reset(defaultValues);
  }, []);

  // hide popup after successful submit
  useEffect(() => {
    if (postBatchMessagesStatus === FetchStatusOptions.SUCCESS) {
      hidePopup();
    } else if (postBatchMessagesStatus === FetchStatusOptions.ERROR) {
      setSubmitLoading(false);
    }
  }, [postBatchMessagesStatus]);

  /* - - - - - - - - - - Callbacks - - - - - - - - - - */

  const onSubmit = (data: FieldValues) => {
    const postData = data as BatchMessagesFormModel;
    setSubmitLoading(true);
    const { date, color, ...batchMessagesData } = postData;
    postBatchMessages({
      slug: projectSlug,
      params: {
        start: date[0].toISOString().replace('Z', '+00:00'),
        end: date[1].toISOString().replace('Z', '+00:00'),
        color: pipeValueToHex(color),
        ...batchMessagesData
      }
    });
  };

  const onPopupSave = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    handleSubmit(onSubmit)();
  };

  const hidePopup = () => {
    reset();
    setSubmitLoading(false);
    onPopupDisplayChange(false);
    parentElement?.focus();
  };

  return (
    <Popup
      title="Batch Messages"
      subtitle="Subsample and tag messages for review"
      visible={displayPopup}
      loading={isSubmitLoading}
      saveButtonText="Submit"
      onHide={hidePopup}
      onSave={onPopupSave}
    >
      <form onSubmit={onPopupSave}>
        <div className="specto-text-medium -mt-2 mb-5">
          Required fields are marked with <span className="specto-form-asterisk-color">*</span>
        </div>

        <div className="flex">
          <TextField
            fieldName="name"
            label="Tag"
            control={control}
            className="col md:mr-5 pl-0"
            placeholder="Tag Name"
          />

          <div className="flex justify-content-between">
            <Controller
              name="color"
              control={control}
              render={({ field, fieldState }) => (
                <ColorSelect
                  className={classNames('pb-2', {
                    'p-invalid': fieldState.error
                  })}
                  color={field.value}
                  onChange={(e) => field.onChange(e.value)}
                />
              )}
            />
          </div>
        </div>

        <DateTimeField
          fieldName="date"
          control={control}
          label="Date Range"
          placeholder="Select a date range"
        />

        <NumberField fieldName="min_group_size" label="Minimum Group Size" control={control} />
        <NumberField fieldName="max_batch_size" label="Max Batch Size" control={control} />
      </form>
    </Popup>
  );
};

export default BatchMessagesPopup;
