import React from 'react';
import {
  MultiStateCheckboxChangeEvent,
  MultiStateCheckboxProps
} from 'primereact/multistatecheckbox';
import { MultiCheckboxCell } from 'components/Table';

type CheckBoxCellCellProps = {
  onChange(e: MultiStateCheckboxChangeEvent): void;
  value: ReviewCheckboxValue;
  viewOnly?: boolean;
};

export enum ReviewCheckboxValue {
  'reviewed' = 'reviewed',
  'annotation-copy' = 'annotation-copy',
  'annotator-reviewed' = 'annotator-reviewed',
  'unchecked' = 'unchecked',
  'unchangeable' = 'unchangeable'
}

const reviewCheckboxOptions: MultiStateCheckboxProps['options'] = [
  { value: 'reviewed', icon: 'pi pi-check-circle', className: 'specto-reviewed-checkbox' },
  {
    value: 'annotation-copy',
    icon: 'pi pi-copy',
    className: 'specto-annotator-reviewed-checkbox'
  },
  {
    value: 'annotator-reviewed',
    icon: 'pi pi-check-circle',
    className: 'specto-annotator-reviewed-checkbox'
  },
  {
    value: 'unchangeable',
    icon: 'pi pi-check-circle',
    className: 'specto-reviewed-checkbox'
  }
];

const reviewCheckboxTooltipRecord: Record<ReviewCheckboxValue, string> = {
  reviewed: 'Unmark as reviewed.',
  'annotation-copy': 'Use this annotation.',
  'annotator-reviewed': 'This has been reviewed by someone else.\nClick to apply your annotation.',
  unchangeable: 'This has been accepted by NeuroSoph and cannot be changed further.',
  unchecked: 'Mark as reviewed.'
};

const ReviewCheckboxCell = ({ value, onChange, viewOnly = false }: CheckBoxCellCellProps) => (
  <MultiCheckboxCell
    value={value === ReviewCheckboxValue.unchecked ? null : value}
    options={reviewCheckboxOptions}
    onChange={onChange}
    optionValue="value"
    viewOnly={viewOnly}
    tooltip={reviewCheckboxTooltipRecord[value]}
  />
);

export default ReviewCheckboxCell;
