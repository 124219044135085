import { ListItemIcon, MenuItem, MenuList } from '@mui/material';
import { ProjectMemberUserModel } from 'models/project-model';
import { Status } from 'models/status-model';
import React from 'react';
import { utcToLocalDateFormatted } from 'util/dates';
import CheckRoundedIcon from '@mui/icons-material/Check';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAll';
import PaceIcon from 'assets/pace/PaceIcon';
import { Tooltip } from 'primereact/tooltip';
import { statusDisplayNames } from 'constants/status';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import { uniqueId } from 'util/uniqueIdGenerator';
import { Divider } from 'primereact/divider';

/**
 * Component for showing the history objects of a table row in a dropdown.
 * @component
 * @param activeIndex The index of the active history item
 * @param findChanges Function to find changes between two items
 * @param objectType The type of object
 * @param onSelect Callback when selecting a history item
 * @param items The history items
 * @param users List of current project members
 */
const HistoryStepper = function ({
  activeIndex,
  findChanges,
  objectType,
  onSelect,
  items,
  users
}: {
  activeIndex: number;
  findChanges(item1: any, item2: any): any[];
  objectType: string;
  onSelect(id: number, index: number): void;
  items: any[];
  users: ProjectMemberUserModel[];
}) {
  const getIcon = (status: Status) => {
    switch (status) {
      case Status.New:
      case Status.Modified:
        return <PaceIcon />;
      case Status.Approved:
        return <CheckRoundedIcon />;
      case Status.Published:
        return <DoneAllRoundedIcon />;
      case Status.Retired:
        return <CloseRoundedIcon />;
    }
  };

  const ToolTipIcon = ({ status }: { status: Status }) => {
    const id = uniqueId('icon-');
    return (
      <div className="flex align-items-center" id={id}>
        {getIcon(status)}
        <Tooltip target={`#${id}`} position="bottom" content={statusDisplayNames[status]} />
      </div>
    );
  };

  return (
    <div className="h-full">
      <div className="overflow-y-auto h-full">
        <MenuList className="history-log">
          {items.map((item, index) => (
            <MenuItem
              key={uniqueId(`history-log-item-${index}-`)}
              onClick={() => onSelect(item.id, index)}
              selected={index === activeIndex}
              className="history-log-item"
            >
              <div className="flex w-full">
                <ListItemIcon>
                  <ToolTipIcon status={item.status} />
                </ListItemIcon>
                <div className="label">
                  {`${utcToLocalDateFormatted(item.last_modified)} • ${
                    users.find((user) => user.user === item.user)?.name ?? ''
                  }`}
                </div>
              </div>
              <div className="flex w-full">
                <Divider className="connector" layout="vertical" />
                <div className="content">
                  {index < items.length - 1 ? (
                    findChanges(item, items[index + 1]).map((change) => (
                      <div className="item" key={uniqueId('change-')}>
                        {change}
                      </div>
                    ))
                  ) : (
                    <div className="item" key={uniqueId('change-')}>
                      {`${objectType} was created`}
                    </div>
                  )}
                </div>
              </div>
            </MenuItem>
          ))}
        </MenuList>
      </div>
    </div>
  );
};

/**
 * Right Arrow Icon for the HistoryStepper
 * @component
 */
export const HistoryStepRightArrow = () => (
  <EastRoundedIcon className="mx-1" sx={{ fontSize: '10px' }} />
);

export default HistoryStepper;
