import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  unstable_HistoryRouter as Router,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import AppWrapper from './AppWrapper';
import { Toast } from 'features/toast/Toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { PersistGate } from 'redux-persist/integration/react';
import { MsalProvider } from '@azure/msal-react';
import pca from 'config/msalInstance';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    })
  ],
  tracesSampleRate: 1.0
});

if ('Cypress' in window) {
  (window as any).Cypress.store = store;
}

const container = document.getElementById('app');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

const history: any = createBrowserHistory({ window });

root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={history}>
            <Toast />
            <ConfirmDialog />
            <AppWrapper />
          </Router>
        </PersistGate>
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
