import { ProjectSummaryModel } from './project-model';
import { UserGroupModel } from './user-groups-model';

export enum NotificationType {
  DAILY_REPORT = 'DR',
  CREATED = 'CR',
  MODIFIED = 'MF',
  APPROVED = 'AP',
  PUBLISHED = 'PB',
  RETIRED = 'RT',
  DELETED = 'DL'
}

export enum NotificationSetting {
  MY_CONTENT_ONLY = 'MC',
  ALL_CONTENT = 'AC',
  DISABLED = 'DI'
}

export type NotificationDetails = {
  title: string;
  description: string;
};

export const NotificationTypeDetails: Record<NotificationType, NotificationDetails> = {
  [NotificationType.DAILY_REPORT]: {
    title: 'Daily Report',
    description:
      'Notifications with all pending content awaiting approval or publication in this project'
  },
  [NotificationType.CREATED]: {
    title: 'New Content',
    description: 'Enable project notifications that new content has been created'
  },
  [NotificationType.MODIFIED]: {
    title: 'Edits',
    description: 'Enable project notifications that content has been edited'
  },
  [NotificationType.DELETED]: {
    title: 'Deletions',
    description: 'Enable project notifications that content has been deleted'
  },
  [NotificationType.RETIRED]: {
    title: 'Retirements',
    description: 'Enable project notifications that content has been retired'
  },
  [NotificationType.APPROVED]: {
    title: 'Approval',
    description: 'Enable project notifications that content has been approved'
  },
  [NotificationType.PUBLISHED]: {
    title: 'Publishing',
    description: 'Enable project notifications that content has been published'
  }
};

export interface NotificationModelDetails {
  setting: NotificationSetting;
  user_groups: UserGroupModel['id'][];
}

export interface NotificationModel extends NotificationModelDetails {
  project: ProjectSummaryModel['slug'];
  type: NotificationType;
}

export type NotificationViewModel = {
  [project: string]: NotificationViewModelDetails;
};

export type NotificationViewModelDetails = {
  [key in NotificationType]: NotificationModelDetails;
};
