import { SentimentModel } from './sentiment-model';
import { SlugOptionsModel } from './api-model';
import { MessageInboxTableDataModel } from './message-inbox-table-data-model';

export type NLUModel = {
  id: number;
  message: string;
  intent: number;
  sentiment: SentimentModel['id'] | null;
  comment_thread_resolved: boolean | null;
};

// data needed to create or edit NLU data
export type NLUAPIModel = {
  intent: NLUModel['intent'];
  message: NLUModel['message'];
  sentiment?: NLUModel['sentiment'] | null;
};

export type NLUCollisionModel = {
  duplicates: DuplicateNLUModel[];
  no_accepted_annotation: number[];
};

export type DuplicateNLUModel = Array<[NLUConflictType, number]>;

export enum NLUConflictType {
  'message' = 'message',
  'nlu' = 'nlu'
}

export interface NLUPostAPIModel {
  id: NLUModel['id'];
  message?: NLUModel['message'];
  intent?: NLUModel['intent'];
  sentiment?: NLUModel['sentiment'];
}

export interface GetNLUOptionsModel extends SlugOptionsModel {
  query: string;
}

export type GetSingleNLUOptionsModel = {
  slug: string;
  id: number;
};

// data required for an API call to edit a NLU message.
// requires full NLU Model to leverage the id in the query parameter
export type EditNLUOptionsModel = {
  slug: string;
  updatedNLUData: NLUPostAPIModel;
};

// data required for an API call to add a NLU message
export type CreateNLUOptionsModel = {
  slug: string;
  updatedNLUData: NLUAPIModel;
};

// data required for an API call to add messages to NLU Data
export type AddNLUMessagesOptionsModel = {
  slug: string;
  updatedNLUData: MessageInboxTableDataModel['id'][];
};
