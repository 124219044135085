import React from 'react';
import { Button } from 'primereact/button';
import { ProjectSummaryModel } from 'models/project-model';
import classNames from 'classnames';
import { onEnter, stopPropagation } from 'util/keyboard';
import { kebabCase } from 'lodash';
import { isAdmin } from 'util/permissions';

type ProjectBlockProps = {
  project: ProjectSummaryModel;
  onProjectSelect: (project: ProjectSummaryModel) => void;
  loading?: boolean;
  onSettingsSelect?: (project: ProjectSummaryModel) => void;
  settingsVisible?: boolean;
  className?: string;
  active?: boolean;
};

/**
 *
 * @param className Custom classname(s).
 * @param project Project object.
 * @param onSettingsSelect On project settings click.
 * @param settingsVisible Whether project settings is visible.
 * @param onProjectSelect Handler function when project is selected.
 * @param loading Loading status when launcing project settings.
 * @param active Whether the project is active or not.
 *
 * @component
 */
const ProjectBlock = ({
  className,
  project,
  onSettingsSelect,
  settingsVisible = false,
  onProjectSelect,
  loading,
  active
}: ProjectBlockProps) => {
  const { company, manager, name, project_user } = project;
  const onActivate = () => onProjectSelect(project);

  return (
    <div
      tabIndex={0}
      className={classNames(className, 'specto-project-widget', {
        'specto-project-widget--active': active
      })}
      onClick={onActivate}
      onKeyDown={(e) => onEnter(e)(onActivate)}
      data-cy={`project-${kebabCase(name)}`}
    >
      <div className="specto-project-widget__header">
        <div className="specto-project-widget__header-title-container">
          <h2>{company}</h2>
          {isAdmin(project_user.role) && (
            <Button
              loading={!!loading}
              icon="pi pi-cog"
              data-cy={`project-settings-icon-${kebabCase(name)}`}
              aria-label="More project options"
              className={classNames('p-button-rounded -mt-1 specto-r-button-lg', {
                'p-button-plain p-button-text': !loading,
                hidden: !settingsVisible
              })}
              onClick={(e) => {
                e.stopPropagation();
                onSettingsSelect?.(project);
              }}
              onKeyDown={stopPropagation}
            />
          )}
        </div>
        <div className="specto-project-widget__subtitle-row">
          <div>{manager}</div>
        </div>
      </div>
      <div className="specto-project-widget__body">
        <div className="specto-project-widget__body-container">
          <img
            src={`/assets/projects/${project.slug}/logo.svg`}
            onError={({ currentTarget }) => {
              // Prevent error loop if default logo is missing
              currentTarget.onerror = null;
              // Look for default logo
              currentTarget.src = '/assets/layout/images/logo-muted.svg';
            }}
            alt="project logo"
            width={68}
            height={68}
          />
          <h4 className="specto-project-widget__project-title">{name}</h4>
        </div>
      </div>
    </div>
  );
};

export default ProjectBlock;
