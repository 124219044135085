import ApiService from 'services/apiService';
import { MetadataFilterModel, MetadataAPIModel } from 'models/metadata-filter-model';
import { METADATA_FILTERS_API_URL } from 'constants/api';
import {
  SlugDataOptionsModel,
  SlugIdOptionsModel,
  SlugDataIdOptionsModel,
  SlugOptionsModel
} from 'models/api-model';
import { ProjectRole } from 'models/project-model';
import { Permissions } from 'models/permission-model';

const getMetadata = (options: SlugOptionsModel) =>
  ApiService.get<MetadataFilterModel[]>('/' + options.slug + METADATA_FILTERS_API_URL);

const createMetadata = (options: SlugDataOptionsModel<MetadataAPIModel>) =>
  ApiService.post<MetadataFilterModel, MetadataAPIModel>(
    '/' + options.slug + METADATA_FILTERS_API_URL,
    options.data
  );

const deleteMetadata = (options: SlugIdOptionsModel) =>
  ApiService.remove('/' + options.slug + METADATA_FILTERS_API_URL + options.id + '/');

const editMetadata = (options: SlugDataIdOptionsModel<MetadataAPIModel>) =>
  ApiService.patch<MetadataFilterModel>(
    '/' + options.slug + METADATA_FILTERS_API_URL + options.id + '/',
    options.data
  );

const metadataServiceRoles: Permissions = {
  list: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN, ProjectRole.CONTENT_STRATEGIST],
  retrieve: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN, ProjectRole.CONTENT_STRATEGIST],
  create: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN],
  update: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN],
  delete: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN]
};

const MetadataService = {
  getMetadata,
  createMetadata,
  deleteMetadata,
  editMetadata,
  roles: metadataServiceRoles
};

export default MetadataService;
