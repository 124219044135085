import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import classnames from 'classnames';
import { basicInputMaxLength } from 'constants/inputs';
import FilterMenu from 'components/MenuButton/FilterMenu';
import { Tooltip } from 'primereact/tooltip';
import { onEnter } from 'util/keyboard';

type ProjectBlockProps = {
  ariaLabel?: string;
  className?: string;
  filterMenu?: React.ReactNode;
  formInputStyle?: boolean;
  hideFilterClear?: boolean;
  loading?: boolean;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  onClearText?(e: React.MouseEvent<HTMLButtonElement>): void;
  onFilterClearClick?(e: React.MouseEvent<HTMLButtonElement>): void;
  onSubmitText?(value: string): void;
  placeHolder: string;
  searchIcon?: boolean;
  tabIndex?: number;
  text: string;
  datePicker?: React.ReactNode;
  searchMaxWidth?: number;
  tooltipText?: string;
  inputDisabled?: boolean;
};

/**
 * Search bar reusable component
 * @param ariaLabel aria label
 * @param className class name
 * @param filter show or hide the filter
 * @param formInputStyle flag to use the form input style
 * @param hideFilterClear hide the clear button
 * @param loading toggle the loading indicator
 * @param onChange search bar change callback
 * @param onClearText callback to clear the text
 * @param onFilterClearClick onclick for the filter clear button
 * @param onSubmitText callback submitting the text
 * @param placeHolder search input placeholder
 * @param searchIcon whether to show the search icon in the search input
 * @param tabIndex tab index
 * @param text search text
 * @param datePicker whether to render date picker filter alongside search
 * @param searchMaxWidth the max width of the search bar
 * @param tooltipText the text that the tooltip displays
 * @param inputDisabled if typing in text input is disabled or not
 * @constructor
 */
const SearchBar = ({
  ariaLabel,
  className,
  filterMenu,
  formInputStyle = false,
  hideFilterClear,
  loading,
  onChange,
  onClearText,
  onFilterClearClick,
  onSubmitText,
  placeHolder,
  searchIcon,
  tabIndex,
  text,
  datePicker,
  searchMaxWidth = 50,
  tooltipText,
  inputDisabled = false
}: ProjectBlockProps) => {
  return (
    <div
      className={classnames(
        className,
        'specto-search-bar grid grid-nogutter justify-content-start align-items-center w-full'
      )}
      style={{ maxWidth: `${searchMaxWidth}rem` }}
    >
      <div className="col-12 sm:col flex flex-nowrap mb-3 sm:mb-0">
        <span className="p-input-icon-left p-input-icon-right">
          <i
            className={classnames('pi pi-search pointer-events-none', {
              'pl-1': formInputStyle,
              hidden: !searchIcon
            })}
          />
          <InputText
            className={classnames(
              'specto-nav-skip border-right-none pr-6',
              formInputStyle ? 'specto-input-text' : 'specto-search-bar__input',
              { 'pl-6': searchIcon }
            )}
            disabled={inputDisabled}
            tabIndex={tabIndex}
            aria-label={ariaLabel || placeHolder}
            role="search"
            autoComplete="off"
            value={text}
            placeholder={placeHolder}
            onKeyDown={(e) => e.key === 'Enter' && onSubmitText?.(text)}
            onChange={onChange}
            maxLength={basicInputMaxLength}
            tooltip={tooltipText && inputDisabled ? tooltipText : placeHolder}
            tooltipOptions={{
              position: 'bottom',
              showDelay: inputDisabled ? 0 : 1000,
              showOnDisabled: true
            }}
            data-cy="searchbar"
          />
          {loading && <i className="pi pi-spin pi-spinner" />}
          {!loading && onClearText && text.length > 0 && (
            <i
              tabIndex={0}
              className="specto-search-bar__close-icon pi pi-times cursor-pointer p-button p-button-text overflow-visible"
              id="searchbar-clear-text-icon"
              aria-label="clear search text"
              role="button"
              onClick={onClearText}
              onKeyDown={(e) => onEnter(e)(onClearText)}
            >
              <Tooltip target="#searchbar-clear-text-icon" position="bottom" showDelay={1000}>
                clear
              </Tooltip>
            </i>
          )}
        </span>
        <div>
          {onSubmitText && (
            <Button
              label="Search"
              disabled={loading || inputDisabled}
              icon="pi pi-search"
              iconPos="right"
              className="specto-search-bar__search-button sm:mr-3"
              type="button"
              onClick={() => onSubmitText(text)}
              data-cy="searchbar-submit"
            />
          )}
        </div>
      </div>
      {datePicker && datePicker}
      {filterMenu && (
        <div className="flex justify-content-start sm:justify-content-center mr-3">
          <FilterMenu
            buttonProps={{
              label: 'Filter',
              'aria-label': 'Open Filter Menu',
              icon: 'pi pi-filter-fill',
              iconPos: 'right',
              type: 'button',
              text: true
            }}
          >
            {filterMenu}
          </FilterMenu>
        </div>
      )}
      {filterMenu && onFilterClearClick && (
        <div className="flex justify-content-start">
          <Button
            label="Clear"
            aria-label="Clear Search and Filters"
            style={{ visibility: hideFilterClear && text.length === 0 ? 'hidden' : 'visible' }}
            icon="pi pi-filter-slash"
            iconPos="right"
            type="button"
            outlined
            onClick={onFilterClearClick}
            data-cy="searchbar-filter-clear"
          />
        </div>
      )}
    </div>
  );
};

export default SearchBar;
