import React, { useState } from 'react';
import classNames from 'classnames';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Link, useNavigate } from 'react-router-dom';
import AppBreadcrumb from 'containers/AppBreadcrumb';
import AppMenu from 'containers/AppMenu';
import { SpectoLayout } from 'models/layout-model';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { authSelector, logout } from 'features/auth/authSlice';
import Avatar from 'components/Avatar';
import { projectsSelector } from 'features/projects/projectsSlice';
import { layoutSelector } from 'features/layout/layoutSlice';
import { routes } from 'constants/routes';
import { Button } from 'primereact/button';
import AccessibilityPanel from 'features/accessibility/accessibility-panel';
import ProjectsService from 'services/projectsService';
import { permissionBoolean } from 'util/permissions';
import { ReactComponent as AccessibilityIcon } from 'assets/accessibility/accessibility-icon.svg';
import settingsIcon from '@massds/mayflower-assets/static/images/icons/settings.svg';
import { CustomIcon } from 'components/CustomIcon/CustomIcon';
import Feedback from 'components/Feedback/Feedback';

type AppTopbarType = {
  onTopbarUserMenu: any;
  onTopbarNotification: any;
  onMenuClick: any;
  onMenuitemClick: any;
  onRootMenuitemClick: any;
  staticMenuMobileActive: any;
  menuActive: any;
  menuMode: any;
  menu: any;
  routers: any;
  onMenuButtonClick: any;
  topbarUserMenuActive: boolean;
  topbarNotificationMenuActive: boolean;
  menuExpanded: boolean;
  toggleDarkMode(): void;
  bannerHeight?: number;
};

/**
 * @param onTopbarUserMenu Handler function to open user menu.
 * @param onTopbarNotification Handler function to open notification menu.
 * @param onMenuClick Handler function for menu click.
 * @param onMenuitemClick Function for when menu item is clicked.
 * @param onRootMenuitemClick Function for when root menu item is clicked.
 * @param staticMenuMobileActive Whether the mobile version of menu is visible.
 * @param menuActive If menu menu in focus.
 * @param menuMode Type of menu to display.
 * @param menu Menu object.
 * @param routers App-wide routing of paths.
 * @param onMenuButtonClick Handler function for when menu button is clicked.
 * @param topbarUserMenuActive Whtether the topbar menu is in focus.
 * @param topbarNotificationMenuActive Whtether the topbar notification menu is in focus.
 * @param menuExpanded Whether the side menu is expanded based on window width.
 * @param toggleDarkMode Function to toggle dark/light mode.
 *
 * @component
 */
const AppTopbar = (props: AppTopbarType) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(authSelector);
  const { layout } = useAppSelector(layoutSelector);
  const { isProjectSelected, selectedProject } = useAppSelector(projectsSelector);
  const [displayAccessibilityPanel, setDisplayAccessibilityPanel] = useState(false);
  const [darkModeToggle, showDarkModeToggle] = useState(false);
  const notificationsItemClassName = classNames('notifications-item', {
    'active-menuitem': props.topbarNotificationMenuActive
  });
  const profileItemClassName = classNames('profile-item', {
    'active-menuitem fadeInDown': props.topbarUserMenuActive
  });

  const onLogout = () => {
    dispatch(logout());
    navigate(routes.LOGIN);
  };

  /**
   * topbar notification click
   * doubles as secret dark mode toggle when SHIFT is held
   * @param e click event
   */
  const onTopbarNotificationClick = (e: any) => {
    showDarkModeToggle(e.shiftKey);
    props.onTopbarNotification(e);
  };

  const userMenuItems: MenuItem[] = [
    {
      label: `${user.first_name} ${user.last_name}`,
      items: [
        {
          label: 'Logout',
          command: onLogout
        }
      ]
    }
  ];

  return (
    <div className="layout-topbar" style={{ top: `${(props.bannerHeight || 0) - 1}px` }}>
      <div className="topbar-left">
        <div className="flex">
          {isProjectSelected && (
            <button
              type="button"
              className="menu-button p-link fadein"
              aria-label="navigation menu"
              onClick={props.onMenuButtonClick}
              aria-expanded={props.menuExpanded}
            >
              <i className="pi pi-angle-double-left" />
            </button>
          )}

          {/*<img*/}
          {/*  className="hidden lg:block bg-contain ml-4"*/}
          {/*  style={{ height: 35 }}*/}
          {/*  src="/assets/layout/images/askma-logo-dark.png"*/}
          {/*  alt="specto-logo"*/}
          {/*/>*/}
        </div>

        <Link to="/" aria-label="Link to home">
          <img
            id="logo-horizontal"
            style={{ height: 35 }}
            className="horizontal-logo"
            src="/assets/layout/images/askma-logo-dark.svg"
            alt="specto logo"
          />
        </Link>

        <img
          id="logo-mobile"
          className="mobile-logo"
          style={{ height: 35 }}
          src="/assets/layout/images/logo-dark.svg"
          alt="specto logo"
        />
      </div>

      {(isProjectSelected ||
        layout === SpectoLayout.systemSettings ||
        layout === SpectoLayout.userSettings) && (
        <AppMenu
          bannerHeight={props.bannerHeight || 0}
          model={props.menu}
          menuMode={props.menuMode}
          active={props.menuActive}
          mobileMenuActive={props.staticMenuMobileActive}
          onMenuClick={props.onMenuClick}
          onMenuitemClick={props.onMenuitemClick}
          onRootMenuitemClick={props.onRootMenuitemClick}
        />
      )}

      <div className="layout-mask modal-in" />

      <div className="specto-breadcrumb-topbar-lg layout-breadcrumb viewname">
        <AppBreadcrumb routers={props.routers} />
      </div>

      <AccessibilityPanel
        visible={displayAccessibilityPanel}
        onHide={() => setDisplayAccessibilityPanel(false)}
      />

      <div className="topbar-right">
        <ul className="topbar-menu">
          <li>
            <div className="specto-breadcrumb-topbar-sm layout-breadcrumb viewname">
              <AppBreadcrumb routers={props.routers} />
            </div>
          </li>

          <li>
            <Feedback />
          </li>

          <li className={notificationsItemClassName}>
            <Button
              text
              rounded
              className="ml-2 p-button-plain p-button-text"
              style={{ height: '2.5rem', width: '2.5rem' }}
              onClick={() => setDisplayAccessibilityPanel(!displayAccessibilityPanel)}
              icon={<AccessibilityIcon className="specto-svg-icon" />}
              aria-label="accessibility"
            />
          </li>

          <li className={notificationsItemClassName}>
            <Button
              type="button"
              onClick={onTopbarNotificationClick}
              text
              rounded
              className="p-button-plain"
              icon={<CustomIcon src={settingsIcon} imageSize="small" alt="studio settings" />}
              aria-label="settings"
              data-cy="chatbot-studio-settings"
            />
            <ul className="notifications-menu fade-in-up p-menu p-component profile-menu">
              {permissionBoolean(
                ProjectsService.roles.project.update,
                selectedProject?.project_user?.role
              ) && (
                <li role="menuitem">
                  <button
                    type="button"
                    className="p-link"
                    tabIndex={0}
                    onClick={() => navigate(routes.PROJECT_GENERAL_SETTINGS)}
                  >
                    <div className="notification-item">
                      <div className="notification-summary">Project Settings</div>
                    </div>
                  </button>
                </li>
              )}
              {user.is_superuser && (
                <li role="menuitem">
                  <button
                    type="button"
                    className="p-link"
                    onClick={() => navigate(routes.SYSTEM_USERS)}
                  >
                    <div className="notification-item">
                      <div className="notification-summary">System Settings</div>
                    </div>
                  </button>
                </li>
              )}
              {process.env.NODE_ENV === 'development' && darkModeToggle && (
                <li role="menuitem">
                  <button type="button" className="p-link" onClick={props.toggleDarkMode}>
                    <div className="notification-item">
                      <div className="notification-summary">Toggle Dark Mode</div>
                    </div>
                  </button>
                </li>
              )}
              <li role="menuitem">
                <button
                  type="button"
                  className="p-link"
                  onClick={() => navigate(routes.USER_SETTINGS)}
                >
                  <div className="notification-item">
                    <div className="notification-summary">User Settings</div>
                  </div>
                </button>
              </li>
              {process.env.NODE_ENV === 'development' && darkModeToggle && (
                <li role="menuitem">
                  <button type="button" className="p-link" onClick={props.toggleDarkMode}>
                    <div className="notification-item">
                      <div className="notification-summary">Toggle Dark Mode</div>
                    </div>
                  </button>
                </li>
              )}
            </ul>
          </li>

          <li className={profileItemClassName}>
            <button
              type="button"
              className="p-link overflow-visible border-circle"
              onClick={props.onTopbarUserMenu}
              aria-label="User menu"
            >
              <Avatar
                className="m-1"
                fullName={{
                  first: user?.first_name,
                  last: user?.last_name
                }}
              />
            </button>
            <ul className="profile-menu fade-in-up p-0">
              <Menu className="profile-menu w-full border-none" model={userMenuItems} />
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AppTopbar;
