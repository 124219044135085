import React, { useEffect, useState } from 'react';
import { confirmDialog } from 'primereact/confirmdialog';
import LoadingSpinner from 'components/LoadingSpinner';
import {
  ConversationDetailMessageModel,
  ConversationDetailModel,
  ConversationModel,
  GetConversationsOptionsModel
} from 'models/conversation-model';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { setLayout } from 'features/layout/layoutSlice';
import ModalLarge from 'containers/modals/ModalLarge';
import ConversationDetail from './components/ConversationDetail';
import ConversationListItem from './components/ConversationListItem';
import useFetch from 'hooks/useFetch';
import ConversationsService from 'services/conversationsService';
import { Paginated, SlugIdOptionsModel } from 'models/api-model';
import { projectsSelector, tagsSelector } from 'features/projects/projectsSlice';
import { createToast } from 'features/toast/toastSlice';
import { FetchStatusOptions } from 'constants/fetchStatus';
import useToggleKeys from 'hooks/useToggleKeys';
import EmptyConversation from './components/EmptyConversation';
import SearchBar from 'components/SearchBar';
import useQuery from 'hooks/useQuery';
import {
  clearConversationsSearch,
  selectConversations,
  setConversationsLoading
} from 'features/conversations/conversationsSlice';
import { Button } from 'primereact/button';
import ConversationFilterMenu from './components/ConversationFilterMenu';
import { FieldValues, useForm } from 'react-hook-form';
import { FilterChips } from 'components/FilterChips/FilterChips';
import { FilterChip } from 'models/filter-chips';
import { humanReadableDateTimeFormatted } from 'util/dates';
import { debounce } from 'lodash';
import { getMainLayout } from 'util/layout';

const conversationFilterDefaultValues: FieldValues = {
  date: []
};

const Conversations = function () {
  const dispatch = useAppDispatch();
  const { selectedProject, projects } = useAppSelector(projectsSelector);
  const {
    searchText: globalConversationSearchText,
    id: globalConversationsSenderId,
    loading: globalLoading,
    active: globalSearchActive
  } = useAppSelector(selectConversations);
  const allTags = useAppSelector(tagsSelector);
  const {
    control: filterControl,
    reset: resetFilterForm,
    getValues: getFilterField,
    setValue: setFilterField,
    formState: { dirtyFields: dirtyFilterFields, isDirty: isFilterFormDirty }
  } = useForm({ defaultValues: conversationFilterDefaultValues });
  const {
    searchText: searchConversationsText,
    search: searchConversations,
    setQueryParameter,
    clearAllQueryParameters,
    clearQueryParameter,
    clearSearch,
    simpleSearch,
    queryString,
    queryParameters,
    paginate: { limit, total, setTotal, setLimit }
  } = useQuery({
    query: onQuery,
    deps: [dirtyFilterFields],
    defaultValues: { ordering: '-start', limit: 10 }
  });
  const {
    data: conversationsData,
    fetchStatus: fetchConversationsStatus,
    fetch: fetchConversations
  } = useFetch<GetConversationsOptionsModel, Paginated<ConversationModel>>(
    ConversationsService.getConversations,
    ConversationsService.roles.list
  );
  const {
    data: conversationDetailsData,
    fetchStatus: fetchConversationDetailsStatus,

    fetch: fetchConversationDetails
  } = useFetch<SlugIdOptionsModel, ConversationDetailModel>(
    ConversationsService.getConversationDetails,
    ConversationsService.roles.retrieve
  );
  const {
    fetchStatus: removeConversationStatus,
    fetchOptions: removeConversationOptions,
    fetch: removeConversation,
    permission: canDeleteConversation
  } = useFetch<SlugIdOptionsModel<ConversationModel['id']>>(
    ConversationsService.deleteConversation,
    ConversationsService.roles.delete
  );
  const {
    isKeyExpanded: isConversationDeleteLoading,
    toggleKeySingle: setConversationDeleteLoading,
    clearKeys: clearAllConversationDeleteLoading
  } = useToggleKeys();
  const [conversations, setConversations] = useState<ConversationModel[]>([]);
  const [selectedConversationDetail, setSelectedConversationDetail] =
    useState<ConversationDetailModel | null>(null);

  const PAGINATE_STEP_AMOUNT = 25;

  const confirmDeleteItem = (conversationItem: ConversationModel) => {
    confirmDialog({
      message: 'This action cannot be undone.',
      header: 'Delete Conversation?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => deleteConversation(conversationItem)
    });
  };

  // onInit
  useEffect(() => {
    dispatch(setLayout(getMainLayout(selectedProject.project_user.role, projects.length)));
  }, []);

  // cleanup only on navigation away, not init (when unmounted)
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);

    return () => {
      if (isMounted) {
        debounce(() => dispatch(clearConversationsSearch()), 1000)();
      }
    };
  }, [isMounted]);

  // get conversations effect handle
  useEffect(() => {
    if (fetchConversationsStatus === FetchStatusOptions.SUCCESS && conversationsData) {
      setConversations(conversationsData.results);
      setTotal(conversationsData.count);
    }
  }, [fetchConversationsStatus]);

  /* - - - - - - - - - - Query - - - - - - - - - - */

  // query on filterQueryString change.
  // *** also fires onInit, so it replaces default getMessages request
  useEffect(() => {
    // if global loading (from Message Inbox "show in Conversations"), don't fetch conversations
    // ensure searchConversationsText has a value, so it doesn't fetch all conversations, only to fetch one we want later
    if (globalSearchActive && searchConversationsText.length === 0) {
      return;
    }

    fetchConversations({ slug: selectedProject.slug, query: queryString });
  }, [queryString]);

  // perform back end query using the filter & search
  function onQuery(queryText: string) {
    // set query parameters which will build a queryParameter string
    setQueryParameter({ search: queryText });
  }

  // global conversation search & select query handler
  useEffect(() => {
    if (globalSearchActive) {
      searchConversations(globalConversationSearchText);
      getConversationDetails(globalConversationsSenderId);
    }
  }, [globalSearchActive]);

  // set global loading false when done global loading
  useEffect(() => {
    if (
      globalSearchActive &&
      // !searching &&
      fetchConversationsStatus === FetchStatusOptions.SUCCESS &&
      fetchConversationDetailsStatus &&
      FetchStatusOptions.SUCCESS
    ) {
      dispatch(setConversationsLoading({ loading: false }));
    }
  }, [fetchConversationsStatus, fetchConversationDetailsStatus, globalLoading]);

  const resetFilter = () => {
    globalSearchActive && dispatch(clearConversationsSearch());
    clearSearch();
    clearAllQueryParameters();
    resetFilterForm();
  };

  /* - - - - - - - - - - Filter Chips - - - - - - - - - - */
  const filterChips: FilterChip[] = [
    {
      name: `Date Greater Than: ${humanReadableDateTimeFormatted(
        String(queryParameters.start__gte)
      )}`,
      removeFn: () => {
        clearQueryParameter('start__gte');
        const date = getFilterField('date');
        const newDate = [null, new Date(date[1])];
        setFilterField('date', newDate);
      },
      enabled: 'start__gte' in queryParameters
    },
    {
      name: `Date Less Than: ${humanReadableDateTimeFormatted(String(queryParameters.start__lte))}`,
      removeFn: () => {
        clearQueryParameter('start__lte');
        const date = getFilterField('date');
        const newDate = [new Date(date[0]), null];
        setFilterField('date', newDate);
      },
      enabled: 'start__lte' in queryParameters
    }
  ];

  const filterDeps = [queryString];

  /* - - - - - - - - - - get Conversation Details - - - - - - - - - - */

  // get conversation details effect handle
  useEffect(() => {
    if (fetchConversationDetailsStatus === FetchStatusOptions.SUCCESS && conversationDetailsData) {
      setSelectedConversationDetail(conversationDetailsData);
    }
  }, [fetchConversationDetailsStatus]);

  const getConversationDetails = (id: number) => {
    fetchConversationDetails({ id: id, slug: selectedProject.slug });
  };

  /* - - - - - - - - - - Delete Conversation - - - - - - - - - - */

  // delete conversation effect handle
  useEffect(() => {
    if (removeConversationStatus === FetchStatusOptions.SUCCESS && removeConversationOptions) {
      setConversations(
        conversations.filter(
          (conversationItem: ConversationModel) =>
            conversationItem.id !== removeConversationOptions.id
        )
      );
      setConversationDeleteLoading(removeConversationOptions.id);

      // unselect conversation detail if it is the one that was deleted
      if (selectedConversationDetail?.id === removeConversationOptions.id) {
        setSelectedConversationDetail(null);
      }

      dispatch(createToast('conversation deleted'));
    } else if (removeConversationStatus === FetchStatusOptions.ERROR) {
      clearAllConversationDeleteLoading();
    }
  }, [removeConversationStatus]);

  const deleteConversation = (conversationItem: ConversationModel) => {
    setConversationDeleteLoading(conversationItem.id);
    removeConversation({ slug: selectedProject.slug, id: conversationItem.id });
  };

  /* - - - - - - - - - - Delete Conversation Detail Message - - - - - - - - - - */
  const deleteConversationDetailMessage = (message: ConversationDetailMessageModel['text']) => {
    if (message === null || !selectedConversationDetail) {
      return;
    }
    const conversationMessagesCopy = [...selectedConversationDetail.messages];

    conversationMessagesCopy
      .filter((conversation) => conversation.text === message)
      .forEach((conversation) => {
        conversation.text = null;
      });

    const selectedConversationDetailCopy = { ...selectedConversationDetail };
    selectedConversationDetailCopy.messages = conversationMessagesCopy;
    setSelectedConversationDetail(selectedConversationDetailCopy);
  };

  /* - - - - - - - - - - Tags - - - - - - - - - - */
  const updateConversationListTags = (tags: ConversationModel['tags']) => {
    if (!selectedConversationDetail) {
      return;
    }

    const conversationsListCopy = [...conversations];

    const conversation = conversationsListCopy.find(
      (conversation) => conversation.id === selectedConversationDetail.id
    );

    if (!conversation) {
      return;
    }

    conversation.tags = tags;
    setConversations(conversationsListCopy);
  };

  const ConversationListItemFooter = () => (
    <div className="w-full flex justify-content-center">
      <Button
        type="button"
        icon="pi pi-plus"
        label="Load More"
        loading={fetchConversationsStatus === FetchStatusOptions.LOADING}
        onClick={() => setLimit(limit + PAGINATE_STEP_AMOUNT, true)}
      />
    </div>
  );

  return (
    <div className="specto-conversations">
      <div
        className="col flex flex-wrap xl:align-items-center align-items-start justify-content-between"
        style={{ maxWidth: '100rem' }}
      >
        <SearchBar
          filterMenu={
            <ConversationFilterMenu
              control={filterControl}
              onFormValueChange={(fieldValue) => setQueryParameter(fieldValue)}
            />
          }
          placeHolder="Search by Sender ID"
          className="specto-search-bar w-12 xl:w-8 mt-3 xl:mt-0"
          text={searchConversationsText}
          onChange={(e) => simpleSearch(e.target.value)}
          onSubmitText={searchConversations}
          onClearText={() => {
            clearSearch();
            searchConversations('');
          }}
          onFilterClearClick={() => resetFilter()}
          hideFilterClear={!isFilterFormDirty}
        />

        <div className="col-12 mb-4 pt-2">
          <FilterChips filterChips={filterChips} deps={filterDeps} />
        </div>

        {conversations.length > 0 ||
        searchConversationsText.length > 0 ||
        fetchConversationsStatus === FetchStatusOptions.LOADING ||
        globalLoading ? (
          <ModalLarge
            noPadding
            style="grid"
            className="specto-conversation-data"
            size="medium"
            grow={false}
            fitParent
          >
            <div className="specto-conversation-data__conversation-list col-5 border-right-1 specto-border flex-column overflow-y-auto h-full">
              {(fetchConversationsStatus === FetchStatusOptions.LOADING || globalLoading) && (
                <div className="w-full h-full flex justify-content-center align-content-center">
                  <LoadingSpinner small position="inline" />
                </div>
              )}
              {conversations.length > 0 ? (
                [
                  conversations.map((conversationItem) => (
                    <ConversationListItem
                      key={conversationItem.id}
                      canDelete={canDeleteConversation}
                      conversation={conversationItem}
                      disabled={removeConversationStatus === FetchStatusOptions.LOADING}
                      loading={isConversationDeleteLoading(conversationItem.id)}
                      onClick={() => getConversationDetails(conversationItem.id)}
                      onDelete={() => confirmDeleteItem(conversationItem)}
                      selected={
                        selectedConversationDetail !== null &&
                        selectedConversationDetail.id === conversationItem.id
                      }
                      allTags={allTags}
                    />
                  )),
                  total && limit < total && <ConversationListItemFooter key={1} />
                ]
              ) : (
                <div className="specto-empty-conversation-detail h-full flex align-items-center justify-content-center">
                  <h5 className="specto-text-muted text-center">
                    No results found!
                    <br />
                    Please try a different query.
                  </h5>
                </div>
              )}
            </div>

            <div className="specto-conversation-data__conversation-detail col-7 specto-border flex-column h-full">
              {fetchConversationDetailsStatus === FetchStatusOptions.SUCCESS &&
              selectedConversationDetail &&
              !globalLoading ? (
                <ConversationDetail
                  conversation={selectedConversationDetail}
                  allTags={allTags}
                  onTagsChange={updateConversationListTags}
                  onDeleteConversationDetailMessage={deleteConversationDetailMessage}
                  onScrollComplete={() => dispatch(clearConversationsSearch())}
                />
              ) : fetchConversationDetailsStatus === FetchStatusOptions.LOADING || globalLoading ? (
                <div className="w-full h-full flex justify-content-center align-content-center">
                  <LoadingSpinner small position="inline" />
                </div>
              ) : (
                <div className="specto-empty-conversation-detail h-full flex align-items-center justify-content-center">
                  <h5 className="specto-text-muted text-center">
                    Select a conversation to
                    <br /> view its details.
                  </h5>
                </div>
              )}
            </div>
          </ModalLarge>
        ) : (
          <EmptyConversation />
        )}
      </div>
    </div>
  );
};

export default Conversations;
