import classNames from 'classnames';
import { TextField } from 'components/Forms';
import { basicInputMaxLength } from 'constants/inputs';
import { UserModel } from 'models/user-model';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { oneSpaceFormatter } from 'util/form';
import { isSameUser } from 'util/users';
import { ToggleDividedButton } from 'components/Forms';
import { onEnter } from 'util/keyboard';

type UserProfileProps = {
  control: Control;
  disableSubmitOnEnter?: boolean;
  onDeactivate?(e: React.MouseEvent): void;
  onDelete?(e: React.MouseEvent): void;
  onFormSubmit(e: React.FormEvent<HTMLFormElement>): void;
  selectedUser: UserModel | null;
  user: UserModel;
};

/**
 * Contains fields for updating the user profile
 *
 * @param control control
 * @param disableSubmitOnEnter disable submitting the form when the enter key is pressed
 * @param onDeactivate function for deactivating users
 * @param onDelete function for deleting users
 * @param onFormSubmit function for submitting the form
 * @param selectedUser user being updated
 * @param user currently logged-in user
 *
 * @component
 */
const UserProfileForm = function ({
  control,
  disableSubmitOnEnter,
  onDeactivate,
  onDelete,
  onFormSubmit,
  selectedUser,
  user
}: UserProfileProps) {
  return (
    <form
      className="p-fluid w-full"
      onSubmit={onFormSubmit}
      onKeyDown={(e) => !disableSubmitOnEnter && onEnter(e)(onFormSubmit)}
    >
      <div className="specto-text-medium m-0 -mt-2 mb-5">
        Required fields are marked with <span className="specto-form-asterisk-color">*</span>
      </div>

      <h5 className="mt-0">General</h5>
      <TextField fieldName="first_name" label="First Name" control={control} dataCy="first_name" />

      <TextField fieldName="last_name" label="Last Name" control={control} dataCy="last_name" />

      <div className="field">
        <label htmlFor="email">Email</label>
        <Controller
          name="email"
          control={control}
          defaultValue={''}
          rules={{
            required: 'Email is required.',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Invalid email address. E.g. example@email.com'
            },
            maxLength: {
              value: basicInputMaxLength,
              message: `Exceeded max length of ${basicInputMaxLength} characters`
            }
          }}
          render={({ field, fieldState }) => (
            <div>
              <span className="p-input-icon-right">
                <i className="pi pi-envelope" />
                <InputText
                  {...field}
                  disabled
                  placeholder="abc@123.ca"
                  tooltipOptions={{
                    position: 'bottom',
                    showOnDisabled: true
                  }}
                  tooltip={'Create a new account to have a different email'}
                  id={field.name}
                  onChange={(e) => field.onChange(oneSpaceFormatter(e.target.value))}
                  className={classNames('specto-input-text', {
                    'p-invalid': fieldState.error
                  })}
                  autoComplete="off"
                  aria-label="user email field"
                  data-cy="email"
                />
              </span>
            </div>
          )}
        />
      </div>

      {user.is_superuser && (
        <div className="field mt-6 mb-3">
          <h5 id="superuser-toggle">Superuser Permission</h5>
          <Controller
            name="is_superuser"
            control={control}
            defaultValue={selectedUser?.is_superuser}
            render={({ field }) => (
              <ToggleDividedButton
                disabled={!user.is_superuser || isSameUser(user, selectedUser)}
                checked={field.value}
                onChange={(e) => field.onChange(e.value)}
                tooltip={
                  isSameUser(user, selectedUser)
                    ? 'You cannot edit your own superuser permission'
                    : ''
                }
                data-cy="is_superuser"
                aria-label="superuser permission"
              />
            )}
          />
        </div>
      )}

      {(onDelete || onDeactivate) && <h6 className="p-error pt-4 pb-2">Danger Zone</h6>}
      <div className="flex">
        {onDeactivate && (
          <Button
            type="button"
            label={(selectedUser?.is_active ? 'Deactivate' : 'Reactivate') + ' User'}
            className="w-auto font-semibold p-button-text underline pt-2"
            disabled={isSameUser(user, selectedUser)}
            tooltip="Cannot deactivate yourself"
            tooltipOptions={{ showOnDisabled: true, disabled: !isSameUser(user, selectedUser) }}
            onClick={onDeactivate}
          />
        )}
        {onDelete && (
          <Button
            type="button"
            label="Delete User"
            className="w-auto font-semibold p-button-text underline pt-2"
            disabled={isSameUser(user, selectedUser) || selectedUser?.is_active}
            tooltip={
              isSameUser(user, selectedUser)
                ? 'Cannot delete yourself'
                : 'User must be deactivated before deleted'
            }
            tooltipOptions={{
              showOnDisabled: true,
              disabled: !isSameUser(user, selectedUser) && !selectedUser?.is_active
            }}
            onClick={onDelete}
          />
        )}
      </div>
    </form>
  );
};

export default UserProfileForm;
