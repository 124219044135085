import React from 'react';
import classnames from 'classnames';
import { Tooltip } from 'primereact/tooltip';

/**
 * Generic Text Cell with a tooltip
 * @param text text to display
 * @param id unique ID
 */
const TextCell = ({ text, id }: { id: string | number; text: string }) => (
  <span className={classnames('text-cell-' + id, '')}>
    {text}
    <Tooltip target={'.text-cell-' + id} position="bottom" showDelay={500}>
      {text}
    </Tooltip>
  </span>
);

export default TextCell;
