import React, { useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import ModalFormFullScreen from 'containers/modals/ModalFormFullScreen';
import { TextField } from 'components/Forms';
import PasswordField from 'components/Forms/PasswordField';
import { FieldValues, useForm } from 'react-hook-form';
import useFetch from 'hooks/useFetch';
import AuthService from 'services/authService';
import { FetchStatusOptions } from 'constants/fetchStatus';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { createToast } from 'features/toast/toastSlice';
import { RegisterUserOptionsModel } from 'models/auth-model';
import { authSelector } from 'features/auth/authSlice';

const AccountSetup = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoggedIn } = useAppSelector(authSelector);
  const defaultValues: FieldValues = {
    email: '',
    first_name: '',
    last_name: '',
    password1: '',
    password2: '',
    signup_code: ''
  };

  const {
    data: registerDataUser,
    fetch: registerUser,
    fetchStatus: registerUserStatus
  } = useFetch(AuthService.registerUser, AuthService.roles.create);
  const { control, handleSubmit, reset, watch } = useForm();

  useEffect(() => {
    reset(defaultValues);
  }, []);

  useEffect(() => {
    if (registerUserStatus === FetchStatusOptions.SUCCESS && registerDataUser) {
      dispatch(createToast(registerDataUser.detail));
      navigate('/login');
    }
  }, [registerUserStatus]);

  const onSubmit = (data: FieldValues) => {
    const postData = data as RegisterUserOptionsModel;
    registerUser(postData);
  };

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit(onSubmit)();
  };

  // User should not be able to access this page while logged in
  return isLoggedIn ? (
    <Navigate to="/" replace />
  ) : (
    <ModalFormFullScreen onSubmit={onFormSubmit}>
      <h2 className="pt-4">Account Setup</h2>
      <span className="specto-account-setup__subtitle pb-7">Let's finalize some details</span>

      <div className="flex justify-content-between w-full">
        <TextField
          className="pr-2"
          label="First Name"
          control={control}
          placeholder="John"
          fieldName="first_name"
        />
        <TextField
          className="pl-2"
          label="Last Name"
          control={control}
          placeholder="Smith"
          fieldName="last_name"
        />
      </div>

      <TextField
        className="w-full"
        label="Email"
        control={control}
        placeholder="john.smith@example.com"
        fieldName="email"
      />

      <PasswordField
        className="w-full"
        toggleMask
        feedback={false}
        fieldName="password1"
        label="Password"
        control={control}
      />

      <PasswordField
        className="w-full"
        validate={(val) => (watch('password1') !== val ? 'Password does not match' : undefined)}
        toggleMask
        feedback={false}
        fieldName="password2"
        label="Confirm Password"
        control={control}
      />

      <TextField className="w-full" label="Signup Code" control={control} fieldName="signup_code" />

      <Button
        label="Create Account"
        className="w-full p-3 mt-6 mb-5 justify-content-center text-xl font-bold"
        type="submit"
        raised
        loading={registerUserStatus === FetchStatusOptions.LOADING}
      />

      <div key="title-text" className="flex justify-content-end align-items-start mb-5 w-full">
        <Link to="/login" className="specto-link">
          Already have an Account?
        </Link>
      </div>
    </ModalFormFullScreen>
  );
};

export default AccountSetup;
