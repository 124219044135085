import { Dialog } from 'primereact/dialog';
import React from 'react';
import { ReactComponent as CheckCircle } from 'assets/check_circle/check_circle.svg';

/**
 * Popup to indicate sucessful feedback submission.
 *
 * @param visible whether to display the popup or not
 * @param onHide callback when hiding the popup
 * @param parentElement element to return focus to after the dialog is hidden
 * @returns
 */
const FeedbackSubmitPopup = function ({
  visible,
  onHide,
  parentElement
}: {
  visible: boolean;
  onHide(): void;
  parentElement?: HTMLElement;
}) {
  const hidePopup = () => {
    onHide();
    parentElement?.focus();
  };
  return (
    <Dialog
      draggable={false}
      blockScroll={true}
      visible={visible}
      onHide={hidePopup}
      className="specto-popup feedback-popup w-full"
      contentClassName="feedback-body"
      headerClassName="feedback-header"
      header={
        <div className="feedback-title">
          <div className="flex justify-content-around">
            <h1 className="text-4xl line-height-3">{'Thank You!'}</h1>
          </div>
          <div className="text-center flex justify-content-around">
            <h2 className="text-base font-normal line-height-3">
              {'We really appreciate your feedback'}
            </h2>
          </div>
        </div>
      }
    >
      <div className="flex justify-content-center align-items-center px-5 py-5 w-full">
        <CheckCircle />
      </div>
    </Dialog>
  );
};

export default FeedbackSubmitPopup;
