/***
 * first checks if the array parameter is an array
 * then returns a standard Array.includes() on the value parameter
 * @param array array parameter
 * @param value value to check if it exists in the array
 */
export const isArrayAndIncludes = (array: unknown, value: any) =>
  Array.isArray(array) && array.includes(value);

/**
 * @param array An array of ojects
 * @param property Compare the values of selected property
 * @returns Sorted array based on selected property
 */
export const sortbyProperty = <T>(array: T[], property: keyof T): T[] => {
  return array.sort((a, b) => {
    const valueA =
      typeof a[property] === 'string' ? (a[property] as string).toLowerCase() : a[property];
    const valueB =
      typeof b[property] === 'string' ? (b[property] as string).toLowerCase() : b[property];
    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
  });
};
