import React from 'react';
import { ColorPicker, ColorPickerChangeEvent } from 'primereact/colorpicker';
import classNames from 'classnames';

type ColorSelectProps = {
  color: string;
  onChange(e: ColorPickerChangeEvent): void;
  className?: string;
  noText?: boolean;
};

const ColorSelect = ({ color, onChange, className = '', noText }: ColorSelectProps) => (
  <div className={classNames('specto-color-select my-auto', className)}>
    <div className="flex my-auto">
      {!noText && <h6 className="specto-color-select__title mr-1 my-auto">Select Color</h6>}
      <ColorPicker value={color} onChange={onChange} />
    </div>
  </div>
);

export default ColorSelect;
