import { TextField } from 'components/Forms';
import React from 'react';
import { Control } from 'react-hook-form';
import QRCode from 'react-qr-code';

/**
 * Fields for setting up Multi-Factor Authentication
 *
 * @param QRLink link for the QR code
 * @param control control
 *
 * @component
 */
const MultiFactorAuthSetupFields = function ({
  QRLink,
  control
}: {
  QRLink: string;
  control: Control;
}) {
  return (
    <div className="w-full text-center">
      <h6>Scan this code using your choice of Authenticator App,</h6>
      <h6 className="mt-0">then enter the verification code to complete the setup.</h6>
      <div className="md:w-4 w-10 mx-auto mt-4 mb-3">
        <QRCode value={QRLink} style={{ height: 'auto', maxWidth: '100%', width: '100%' }} />
      </div>

      <h5 className="my-0">- OR -</h5>

      <h6 className="mt-3">Enter the setup code manually</h6>

      <div className="text-2xl mt-4 md:text-3xl md:mt-2" style={{ wordBreak: 'break-all' }}>
        {QRLink.split('secret=')[1].split('&')[0].toLowerCase()}
      </div>

      <TextField
        className="mt-4 text-left"
        noPadding
        label="Verification Code"
        control={control}
        fieldName="token"
      />
    </div>
  );
};

export default MultiFactorAuthSetupFields;
