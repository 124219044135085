import { MessageInboxTableDataModel } from 'models/message-inbox-table-data-model';
import { EntityTableDataModel } from 'models/entity-model';
import { EntityDisplayLabel } from 'features/entity-labelling';

/***
 * Pipe MessageInbox Entity Labels to EntityLabels
 * @param entities Message Inbox Entity Labels
 * @param allEntities Entities from the Table Data Model
 */
export const pipeToEntityDisplayLabels = (
  entities: MessageInboxTableDataModel['entity_labels'],
  allEntities: EntityTableDataModel[]
): EntityDisplayLabel[] => {
  const entityList: (EntityDisplayLabel | undefined)[] = entities.map((nodeEntity) => {
    const mappedEntity = allEntities.find((entity) => entity.id === nodeEntity.entity);
    if (!mappedEntity) {
      return undefined;
    }
    const entityLabel: EntityDisplayLabel = {
      type: mappedEntity.name,
      start: nodeEntity.start,
      end: nodeEntity.end
    };

    return entityLabel;
  });

  return entityList.filter(Boolean) as EntityDisplayLabel[];
};

/**
 * Sort Entities
 * @param entities  Entity Display Labels to be sorted
 */
export const sortEntities = <T extends { start?: number } = any>(entities: T[]): T[] =>
  entities.sort((a, b) => {
    // Make sure spans are ordered by the start index
    // return 0 if equal or mixed content, e.g. a.start compared with b.undefined
    if (a.start !== undefined && b.start !== undefined) {
      return a.start > b.start ? 1 : b.start > a.start ? -1 : 0;
    }

    // return 0 if mixed content, e.g. a.start compared with b.undefined
    return 0;
  });
