import React, { CSSProperties, useState } from 'react';
import classnames from 'classnames';
import { Button } from 'primereact/button';

type NavSkipProps = {
  className?: string;
  style?: CSSProperties;
};

const NavSkip = function ({ className, style }: NavSkipProps) {
  const [hasFocus, setFocus] = useState(false);

  return (
    <Button
      label="Skip to Content"
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onClick={() => {
        let element: HTMLElement | null = document.querySelector<HTMLElement>('.specto-nav-skip');

        // if div, focus on the first focusable element inside it
        if (element?.tagName === 'DIV') {
          element = element.querySelector(
            "a[href], button, input, select, textarea, [tabindex]:not([tabindex='-1'])"
          );
        }

        element?.focus();
      }}
      className={classnames(
        'h-3rem py-3 w-12rem',
        'flex align-items-center justify-content-center',
        'border-round text-primary surface-card',
        'absolute transition-duration-300',
        hasFocus ? 'fadein' : 'top-100',
        className
      )}
      style={style}
    />
  );
};

export default NavSkip;
