import React from 'react';
import LinkChipsCell from './LinkChipsCell';
import { Link } from 'react-router-dom';

/**
 * Link Chips Cell with a view all link
 *
 * @param nodeId id of the object
 * @param url url to view all
 * @param chips list of chips
 * @param emptyMessage message to display if chips is empty
 * @returns
 */
const ViewAllLinkChipsCell = function ({
  nodeId,
  url,
  chips,
  emptyMessage
}: {
  nodeId: number | string;
  url: string;
  chips?: { label: string; url: string }[];
  emptyMessage?: string;
}) {
  return chips && chips.length > 0 ? (
    <div>
      <LinkChipsCell nodeId={nodeId} chips={chips} />
      <Link to={url}>View in table →</Link>
    </div>
  ) : (
    <div className="specto-chips-none">{emptyMessage}</div>
  );
};

export default ViewAllLinkChipsCell;
