import React, { useEffect, useRef } from 'react';
import { Toast as PrimeToast } from 'primereact/toast';
import { useAppSelector } from 'hooks/store';
import { toastSelector } from './toastSlice';
import { toSentenceCase } from 'util/strings';

export const Toast = () => {
  const toastRef = useRef<PrimeToast>(null);
  const { message, severity, id, life } = useAppSelector(toastSelector);

  useEffect(() => {
    message &&
      toastRef?.current?.show({
        severity: severity,
        summary: capitalizeFirst(toSentenceCase(message)),
        life: life
      });
  }, [id]);

  return <PrimeToast ref={toastRef} style={{ zIndex: 10002 }} />;
};

const capitalizeFirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
