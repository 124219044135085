import ApiService from 'services/apiService';
import {
  PatchTagOptionsModel,
  PostTagOptionsModel,
  TagModel,
  TagPatchRequestAPIModel,
  TagPostRequestAPIModel
} from 'models/tag-model';
import { TAGS_API_URL } from 'constants/api';
import { SlugIdOptionsModel, SlugOptionsModel } from 'models/api-model';
import { OPEN_VIEW_ADMIN_MODIFY_PERMISSIONS } from 'constants/roles';

const getTags = (options: SlugOptionsModel) =>
  ApiService.get<TagModel[]>('/' + options.slug + TAGS_API_URL);

const createTag = (options: PostTagOptionsModel) =>
  ApiService.post<TagModel, TagPostRequestAPIModel>('/' + options.slug + TAGS_API_URL, options.tag);

const editTag = (options: PatchTagOptionsModel) =>
  ApiService.patch<TagModel, TagPatchRequestAPIModel>(
    '/' + options.slug + TAGS_API_URL + options.tag.id + '/',
    options.tag
  );

const deleteTag = (options: SlugIdOptionsModel) =>
  ApiService.remove<TagModel>('/' + options.slug + TAGS_API_URL + options.id + '/');

const TagsService = {
  getTags,
  editTag,
  deleteTag,
  createTag,
  roles: OPEN_VIEW_ADMIN_MODIFY_PERMISSIONS
};

export default TagsService;
