import React from 'react';
// utils
import { mapIdToName } from 'util/mapIdAndName';

type IdToNameCellPropsModel = {
  id: number | string | null;
  allOptions: { id: number | string; name: string }[];
  fallback?: string;
};

/**
 * Component to create a cell that displays the name of an item when given an id and a list of
 * objects containing corresponding id's and names.
 * @component
 * @param param0 Prop object containing the following:
 *
 *    id: The id of the item.
 *
 *    allOptions: A list of objects containing corresponding id's and names.
 *
 *    fallback: fallback if id is not found
 */
const IdToNameCell = ({ id, allOptions, fallback = '' }: IdToNameCellPropsModel) => (
  <span>{mapIdToName(id, allOptions) ?? fallback}</span>
);

export default IdToNameCell;
