/**
 * Update an object in a list of objects.
 * @param records array of records
 * @param updateObject new object
 * @param key of object to object
 */
export const updateObjectInArray = <T = any[]>(
  records: T[],
  updateObject: any,
  key: string | number
) => {
  const recordsCopy = [...records];
  const recordObject = recordsCopy.find((record: any) => record[key] === updateObject[key]);

  if (!recordObject) {
    return false;
  }

  Object.assign(recordObject, updateObject);

  // return copy of all records including updated object
  return recordsCopy;
};

/**
 * Determines whether the given value is JSON string.
 *
 * @param value Value
 */
export function isStringified(value: string): boolean {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * Filter out properties with undefined values from a record
 * @param record record to filter
 */
export function filterUndefinedRecordValues<T extends Record<string, any>>(record: T): T {
  return Object.fromEntries(Object.entries(record).filter(([, value]) => value !== undefined)) as T;
}
