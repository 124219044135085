import PasswordField from 'components/Forms/PasswordField';
import { FetchStatusOptions } from 'constants/fetchStatus';
import ModalFormFullScreen from 'containers/modals/ModalFormFullScreen';
import { authSelector } from 'features/auth/authSlice';
import { createToast } from 'features/toast/toastSlice';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import useFetch from 'hooks/useFetch';
import { ResetPasswordOptionsModel } from 'models/auth-model';
import { Button } from 'primereact/button';
import React, { useEffect } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { Navigate, useNavigate, useParams } from 'react-router';
import AuthService from 'services/authService';

const ConfirmResetPassword = function () {
  const { uid, token } = useParams();
  const defaultValues: FieldValues = {
    uid: uid,
    token: token,
    new_password1: '',
    new_password2: ''
  };

  const {
    data: resetPasswordData,
    fetch: resetPassword,
    fetchStatus: resetPasswordStatus
  } = useFetch(AuthService.resetPassword, AuthService.roles.update);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoggedIn } = useAppSelector(authSelector);
  const { control, handleSubmit, reset } = useForm();

  useEffect(() => {
    reset(defaultValues);
  }, []);

  useEffect(() => {
    if (resetPasswordStatus === FetchStatusOptions.SUCCESS && resetPasswordData) {
      dispatch(createToast(resetPasswordData.detail));
      navigate('/login');
    }
  }, [resetPasswordStatus]);

  const onSubmit = (data: FieldValues) => {
    const postData = data as ResetPasswordOptionsModel;
    resetPassword(postData);
  };

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit(onSubmit)();
  };

  return uid && token && !isLoggedIn ? (
    <ModalFormFullScreen onSubmit={onFormSubmit}>
      <h2 className="pt-4">Reset Password</h2>
      <span className="specto-account-setup__subtitle pb-7">Choose a new password</span>

      <PasswordField
        key="password-field"
        className="w-full"
        control={control}
        fieldName="new_password1"
        label="Password"
      />

      <PasswordField
        key="confirm-password-field"
        className="w-full"
        control={control}
        fieldName="new_password2"
        label="Confirm Password"
      />

      <Button
        key="submit-button"
        label="Reset Password"
        className="w-full p-3 mt-6 mb-5 justify-content-center text-xl font-bold"
        type="submit"
        iconPos="left"
        disabled={resetPasswordStatus === FetchStatusOptions.LOADING}
      />
    </ModalFormFullScreen>
  ) : (
    <Navigate to="/" />
  );
};

export default ConfirmResetPassword;
