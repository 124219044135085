import React from 'react';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { Control, Controller } from 'react-hook-form';
import classNames from 'classnames';
import { useAppSelector } from 'hooks/store';
import { tagsSelector } from 'features/projects/projectsSlice';
import { getFormErrorMessage } from 'util/form';
import { TagModel } from 'models/tag-model';
import { Tag } from 'components/Tags';
import ChipTagTemplate from 'components/Forms/ChipTagTemplate';
import classnames from 'classnames';

/**
 * Component that displays the tag's field for the create response form.
 * @param className class name
 * @param control form Control
 * @param filterPlaceholder filter placeholder
 * @param label input label
 * @param labelClassName label classname
 * @param noPadding if default padding should be removed
 * @param onChange onChange callback
 * @param placeholder input placeholder
 * @constructor
 */
const TagsField = function ({
  className,
  control,
  filterPlaceholder = 'Start typing to search...',
  label = 'Tags',
  labelClassName,
  noPadding = false,
  onChange,
  placeholder = 'Select Tags'
}: {
  className?: string;
  control: Control;
  filterPlaceholder?: string;
  label?: string;
  labelClassName?: string;
  noPadding?: boolean;
  onChange?(e: MultiSelectChangeEvent): void;
  placeholder?: string;
}) {
  const allTags = useAppSelector(tagsSelector);

  return (
    <div className={classnames(className, { field: !noPadding })}>
      <label htmlFor="tagsinput" className={classnames(labelClassName)}>
        {label}
      </label>
      <Controller
        name="tags"
        control={control}
        render={({ field, fieldState }) => (
          <>
            <MultiSelect
              id={field.name}
              inputId="tagsinput"
              {...field}
              itemTemplate={(tag: TagModel) => Tag({ tag })}
              selectedItemTemplate={(id: number) =>
                ChipTagTemplate(allTags.find((tag) => tag.id === id))
              }
              placeholder={placeholder}
              filterPlaceholder={filterPlaceholder}
              display="chip"
              options={allTags}
              optionLabel="name"
              onChange={(e) => {
                e.value && field.onChange(e.value);
                onChange?.(e);
              }}
              optionValue="id"
              className={classNames('specto-input-select', {
                'p-invalid': fieldState.error
              })}
              appendTo={null}
              filter
              showSelectAll={false}
              aria-label="tags"
              data-cy={field.name}
            />
            {getFormErrorMessage(fieldState.error, noPadding)}
          </>
        )}
      />
    </div>
  );
};

export default TagsField;
