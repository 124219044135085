import React, { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import Popup from 'containers/Popup';
import { Timeline } from 'primereact/timeline';
import VersionFormPopup from './VersionFormPopup';
import { PostVersionOptionsModel, VersionHistory, VersionPostAPIModel } from 'models/version-model';
import NotesCell from 'pages/MessageInbox/components/NotesCell';
import { IconButtonCell } from 'components/Table';
import { confirmDialog } from 'primereact/confirmdialog';
import useFetch from 'hooks/useFetch';
import VersionsService from 'services/versionsService';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { projectsSelector } from 'features/projects/projectsSlice';
import { FetchStatusOptions } from 'constants/fetchStatus';
import { createToast } from 'features/toast/toastSlice';
import {
  addVersion,
  removeVersion,
  updateVersion,
  versionsSelector
} from 'features/versions/versionsSlice';
import { humanReadableDateTimeFormatted } from 'util/dates';
import { SlugOptionsModel } from 'models/api-model';
import { FormMode } from 'models/form-model';

type NewVersionPopupPropsModel = {
  displayPopup: boolean;
  onPopupDisplayChange: (visible: boolean) => void;
};

const defaultValues: FieldValues = {
  version_name: '',
  version_description: ''
};

const defaultVersionHistory = {} as VersionHistory;

const VersionManager = function ({
  displayPopup,
  onPopupDisplayChange
}: NewVersionPopupPropsModel) {
  const dispatch = useAppDispatch();
  const { selectedProject } = useAppSelector(projectsSelector);
  const { versions, isVersionSelected, selectedVersion } = useAppSelector(versionsSelector);
  const [displayVersionPopup, setDisplayVersionPopup] = useState(false);
  const [versionFormMode, setFormMode] = useState<FormMode>(FormMode.READ_ONLY);
  const [contextVersion, setContextVersion] = useState<VersionHistory>(defaultVersionHistory);
  const { control, handleSubmit, reset } = useForm({ defaultValues });

  /* - - - - - - - - - - Callbacks - - - - - - - - - - */
  const hidePopup = () => {
    reset();
    onPopupDisplayChange(false);
  };

  const onVersionFormSubmit = (data: FieldValues) => {
    const formData: VersionPostAPIModel = {
      version_name: data.version_name,
      version_description: data.version_description
    };

    if (versionFormMode === FormMode.CREATE) {
      createVersion({ slug: selectedProject.slug, version: formData });
    } else if (versionFormMode === FormMode.EDIT && contextVersion?.slug) {
      editVersion({ slug: contextVersion.slug, version: formData });
    }
  };

  const openVersionPopup = (mode: FormMode, versionData?: VersionHistory): void => {
    if (mode === FormMode.CREATE && isVersionSelected) {
      dispatch(
        createToast('You must be on the Development version to create a new version.', 'warn')
      );
      return;
    }

    setFormMode(mode);
    versionData && setContextVersion(versionData);

    // pre-fill form values with versionData if passed in
    reset(
      versionData
        ? {
            version_name: versionData.version_name,
            version_description: versionData.version_description
          }
        : defaultValues
    );

    setDisplayVersionPopup(true);
  };

  /* - - - - - - - - - - Create - - - - - - - - - - */
  const {
    data: createVersionData,
    fetchStatus: createVersionStatus,
    fetch: createVersion,
    permission: canCreateVersion
  } = useFetch<PostVersionOptionsModel, VersionHistory>(
    VersionsService.createVersion,
    VersionsService.roles.create
  );

  // create versions effect handle
  useEffect(() => {
    if (createVersionStatus === FetchStatusOptions.SUCCESS && createVersionData) {
      dispatch(addVersion(createVersionData));
      dispatch(createToast('version created'));
      setDisplayVersionPopup(false);
    }
  }, [createVersionStatus]);

  /* - - - - - - - - - - Edit - - - - - - - - - - */
  const {
    data: editVersionData,
    fetchStatus: editVersionStatus,
    fetch: editVersion,
    permission: canEditVersion
  } = useFetch(VersionsService.editVersion, VersionsService.roles.update);

  // edit versions effect handle
  useEffect(() => {
    if (editVersionStatus === FetchStatusOptions.SUCCESS && editVersionData) {
      dispatch(updateVersion(editVersionData));
      dispatch(createToast('version edited'));
      setDisplayVersionPopup(false);
    }
  }, [editVersionStatus]);

  /* - - - - - - - - - - Delete - - - - - - - - - - */
  const {
    fetchStatus: deleteVersionStatus,
    fetch: deleteVersion,
    permission: canDeleteVersion
  } = useFetch<SlugOptionsModel>(VersionsService.deleteVersion, VersionsService.roles.delete);

  const confirmDeleteVersion = (version: VersionHistory) => {
    confirmDialog({
      message: 'This action cannot be undone.',
      header: 'Delete Version?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => onDeleteVersion(version)
    });
  };

  const onDeleteVersion = (version: VersionHistory) => {
    setContextVersion(version);
    deleteVersion({ slug: version.slug });
  };

  // delete versions effect handle
  useEffect(() => {
    if (deleteVersionStatus === FetchStatusOptions.SUCCESS) {
      dispatch(removeVersion(contextVersion.slug));
      setContextVersion(defaultVersionHistory);
      dispatch(createToast('version deleted'));
    }
  }, [deleteVersionStatus]);

  /* - - - - - - - - - - Template - - - - - - - - - - */

  const versionHistoryTimelineTemplate = (version: VersionHistory) => (
    <div className="grid mb-5">
      <div className="col">
        <div style={{ minWidth: '5em' }} className="pr-2 mb-2 specto-text-medium">
          {version.version_name}
        </div>
        <div className="specto-text-medium-light">
          {humanReadableDateTimeFormatted(version.time_created)}
        </div>
      </div>
      <div className="col-4 flex flex-nowrap justify-content-end">
        {canDeleteVersion && version.slug !== selectedVersion.slug && (
          <IconButtonCell
            icon="pi-trash"
            loading={deleteVersionStatus === FetchStatusOptions.LOADING}
            action={() => confirmDeleteVersion(version)}
          />
        )}
        <NotesCell
          action={() =>
            openVersionPopup(canEditVersion ? FormMode.EDIT : FormMode.READ_ONLY, version)
          }
          notes={version.version_description}
        />
      </div>
    </div>
  );

  return (
    <>
      <Popup
        title="Version History"
        subtitle="View, edit or add a named version"
        className="max-w-27rem"
        visible={displayPopup}
        onHide={hidePopup}
        noBodyPadding
        onSave={canCreateVersion ? () => openVersionPopup(FormMode.CREATE) : undefined}
        cancelButtonText="Close"
        saveButtonText="Create Version"
      >
        <Timeline
          value={versions}
          dataKey="slug"
          align="left"
          content={versionHistoryTimelineTemplate}
          className="specto-timeline px-4 pt-5"
        />
      </Popup>
      <VersionFormPopup
        displayPopup={displayVersionPopup}
        onPopupDisplayChange={setDisplayVersionPopup}
        mode={versionFormMode}
        control={control}
        onSubmit={() => handleSubmit(onVersionFormSubmit)()}
      />
    </>
  );
};

export default VersionManager;
