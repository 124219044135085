import React from 'react';
import { ResponseTableDataModel, ResponseTypeOptions } from 'models/response-table-data-model';
import { ProjectMemberUserModel } from 'models/project-model';
import { UserGroupModel } from 'models/user-groups-model';
import HistoryPopup from 'components/History/HistoryPopup';
import { Chip } from 'primereact/chip';
import _, { uniqueId } from 'lodash';
import { utcToLocalDateFormatted } from 'util/dates';
import { statusDisplayNames } from 'constants/status';
import Widget from 'components/Widget/Widget';
import HistoryWidget from 'components/Widget/HistoryWidget';
import { mapIdToName } from 'util/mapIdAndName';
import classNames from 'classnames';
import { HistoryStepRightArrow } from 'components/History/HistoryStepper';
import parse from 'html-react-parser';

type ResponseHistoryPopupPropsModel = {
  allUserGroups: UserGroupModel[];
  authors: ProjectMemberUserModel[];
  node: ResponseTableDataModel;
  onHide(): void;
  onHistorySelect(id: number): void;
  parentElement?: HTMLElement;
  visible: boolean;
};

/**
 * Component for showing the history objects of a table row in a dropdown.
 * @component
 * @param allUserGroups all user groups
 * @param authors List of current project members
 * @param node The row data
 * @param onHide Callback when hiding the popup
 * @param parentElement The element to return focus to after the dialog is hidden
 * @param visible Whether the popup is visible
 */
const ResponseHistoryPopup = function ({
  allUserGroups,
  authors,
  node,
  onHide,
  onHistorySelect,
  parentElement,
  visible
}: ResponseHistoryPopupPropsModel) {
  const modes = ['Response', 'Details'];
  const isCustomAction = node?.content?.[0]?.type === ResponseTypeOptions.CUSTOM_ACTION;

  const DetailsTemplate = ({ node }: { node: ResponseTableDataModel }) => {
    return (
      <table className="info">
        <tbody>
          <tr>
            <td>Author</td>
            <td
              className={classNames({
                'empty-cell': !node.author
              })}
            >
              {node.author ? authors.find((author) => author.user === node.author)?.name : 'None'}
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{statusDisplayNames[node.status]}</td>
          </tr>
          <tr>
            <td className="chips-label-cell">User Groups</td>
            <td
              className={classNames({
                'empty-cell--chips': node.user_groups.length === 0
              })}
            >
              {node.user_groups.length > 0
                ? node.user_groups.map((userGroup) => {
                    const label = allUserGroups.find((group) => group.id === userGroup)?.name;
                    return (
                      <Chip
                        className="user-group-chips mb-1"
                        label={label ? label : userGroup.toString()}
                        key={uniqueId(`user-group-chip-${userGroup}`)}
                      />
                    );
                  })
                : 'None'}
            </td>
          </tr>
          {isCustomAction ? (
            <tr>
              <td>Action</td>
              <td>{parse(node?.content?.[0]?.content.text)}</td>
            </tr>
          ) : undefined}
        </tbody>
      </table>
    );
  };

  const ContentTemplate = ({
    current = true,
    mode,
    node,
    retired
  }: {
    current?: boolean;
    mode?: string;
    node?: ResponseTableDataModel;
    retired?: boolean;
  }) => {
    const objectName = isCustomAction ? 'Custom Action' : 'Response';
    return (
      <div className="specto-response-history-popup-content h-full">
        <div className="flex title">
          <strong>{current ? 'Live widget version' : 'Selected version'}</strong>

          {node ? (
            <>
              <div className="px-2">{'•'}</div>
              <div>{`Modified on ${utcToLocalDateFormatted(node.last_modified)}`}</div>
            </>
          ) : undefined}
        </div>
        {!node || retired ? (
          <div className="flex h-full align-items-center">
            <span className="text-center w-full">
              {!node ? `${objectName} hasn't been published yet` : `${objectName} is retired`}
            </span>
          </div>
        ) : mode === modes[0] ? (
          <div className="widget-container">{current ? <HistoryWidget /> : <Widget />}</div>
        ) : (
          <DetailsTemplate node={node} />
        )}
      </div>
    );
  };

  const findChanges = (currentItem: ResponseTableDataModel, oldItem: ResponseTableDataModel) => {
    let changes = [];

    if (oldItem.status !== currentItem.status) {
      changes.push(`Status changed to ${statusDisplayNames[currentItem.status]}`);
    }

    if (oldItem.name !== currentItem.name) {
      changes.push([
        `Name of the response was changed.`,
        <div className="align-items-center" key={uniqueId()}>
          {oldItem.name}
          <HistoryStepRightArrow />
          {currentItem.name}
        </div>
      ]);
    }

    if (oldItem.author !== currentItem.author) {
      if (!oldItem.author) {
        changes.push(`Author changed to ${mapIdToName(currentItem.author, authors, 'user')}`);
      } else if (!currentItem.author) {
        changes.push(`Author was removed`);
      } else {
        changes.push([
          `Author of the response was changed.`,
          <div className="align-items-center" key={uniqueId()}>
            {`${mapIdToName(oldItem.author, authors, 'user')}`}
            <HistoryStepRightArrow />
            {`${mapIdToName(currentItem.author, authors, 'user')}`}
          </div>
        ]);
      }
    }
    const userGroupsAdded = _.difference(currentItem.user_groups, oldItem.user_groups);
    changes = changes.concat(
      userGroupsAdded.map((group) => `User group "${mapIdToName(group, allUserGroups)}" added`)
    );
    const removedUserGroups = _.difference(oldItem.user_groups, currentItem.user_groups);
    changes = changes.concat(
      removedUserGroups.map((group) => `User group "${mapIdToName(group, allUserGroups)}" removed`)
    );

    if (!_.isEqual(currentItem.content, oldItem.content)) {
      changes.push('Content was edited');
    }

    return changes;
  };

  return (
    <HistoryPopup
      ContentTemplate={ContentTemplate}
      findChanges={findChanges}
      modes={isCustomAction ? undefined : modes}
      defaultMode={modes[0]}
      node={node}
      objectType={
        node.content?.[0]?.type === ResponseTypeOptions.CUSTOM_ACTION ? 'Custom Action' : 'Response'
      }
      onHide={onHide}
      onHistorySelect={onHistorySelect}
      parentElement={parentElement}
      users={authors}
      visible={visible}
    />
  );
};

export default ResponseHistoryPopup;
