import ApiService from 'services/apiService';
import { AUTH_API_URL, LOGIN_API_URL, LOGOUT_API_URL, MS_LOGIN_URL } from 'constants/api';
import {
  AuthRequestAPIModel,
  AuthModel,
  PostAuthOptionsModel,
  AuthResponseModel,
  ForgotPasswordOptionsModel,
  RegisterUserOptionsModel,
  VerifyEmailOptionsModel,
  ResetPasswordOptionsModel,
  MFACreateResponseModel,
  RecoveryCodeResponseModel,
  VerifyCodeOptionsModel,
  ChangePasswordOptionsModel,
  SendRecoveryCodesOptionsModel,
  SSOPostAuthOptionsModel,
  SSOAuthRequestAPIModel
} from 'models/auth-model';
import TokenService from 'services/tokenService';
import { EditUserOptionsModel, UserModel, UserRequestAPIModel } from 'models/user-model';
import { OPEN_PERMISSIONS } from 'constants/roles';

const login = (options: PostAuthOptionsModel): Promise<AuthModel> =>
  ApiService.post<AuthModel, AuthRequestAPIModel>(LOGIN_API_URL, options)
    .then((auth: AuthModel) => {
      if (auth.access) {
        // Set User in local session
        TokenService.setAuth(auth);

        // Set Token Bearer in Axios instance
        ApiService.setHeaderTokenBearer(auth.access);
      }

      return auth;
    })
    .catch((reason: string) => {
      return Promise.reject(reason);
    });

const loginMicrosoft = (options: SSOPostAuthOptionsModel): Promise<AuthModel> =>
  ApiService.post<AuthModel, SSOAuthRequestAPIModel>(MS_LOGIN_URL, options).then(
    (auth: AuthModel) => {
      if (auth.access) {
        // Set User in local session
        TokenService.setAuth(auth);

        // Set Token Bearer in Axios instance
        ApiService.setHeaderTokenBearer(auth.access);
      }
      return auth;
    }
  );

const validateLogin = (options: PostAuthOptionsModel) =>
  ApiService.post<AuthModel, AuthRequestAPIModel>(LOGIN_API_URL, options);

const updateUser = (options: EditUserOptionsModel) =>
  ApiService.patch<UserModel, UserRequestAPIModel>(AUTH_API_URL + 'update/', options.user);

const registerUser = (options: RegisterUserOptionsModel) =>
  ApiService.post<AuthResponseModel, RegisterUserOptionsModel>(
    AUTH_API_URL + 'registration/',
    options
  );

const verifyEmail = (options: VerifyEmailOptionsModel) =>
  ApiService.post<AuthResponseModel, VerifyEmailOptionsModel>(
    AUTH_API_URL + 'registration/verify-email/',
    options
  );

const forgotPassword = (options: ForgotPasswordOptionsModel) =>
  ApiService.post<AuthResponseModel, ForgotPasswordOptionsModel>(
    AUTH_API_URL + 'password/reset/',
    options
  );

const resetPassword = (options: ResetPasswordOptionsModel) =>
  ApiService.post<AuthResponseModel, ResetPasswordOptionsModel>(
    AUTH_API_URL + 'password/reset/confirm/',
    options
  );

const changePassword = (options: ChangePasswordOptionsModel) =>
  ApiService.post<AuthResponseModel, ChangePasswordOptionsModel>(
    AUTH_API_URL + 'password/change/',
    options
  );

const logout = () => {
  ApiService.post(LOGOUT_API_URL).then(() => {
    TokenService.removeAuth();
  });
};

const createMFA = () => ApiService.post<MFACreateResponseModel>(AUTH_API_URL + 'mfa/create/');

const removeMFA = () => ApiService.post<UserModel>(AUTH_API_URL + 'mfa/disable/');

const createRecoveryCodes = () =>
  ApiService.post<RecoveryCodeResponseModel>(AUTH_API_URL + 'mfa/static/create/');

const getRecoveryCodes = () =>
  ApiService.get<RecoveryCodeResponseModel>(AUTH_API_URL + 'mfa/static/list/');

const verifyMFACode = (options: VerifyCodeOptionsModel) =>
  ApiService.post<AuthModel, VerifyCodeOptionsModel>(AUTH_API_URL + 'mfa/verify/', options);

const sendRecoveryCodes = (options: SendRecoveryCodesOptionsModel) =>
  ApiService.post(AUTH_API_URL + 'mfa/static/emergency/' + options.user_id + '/');

const AuthService = {
  login,
  loginMicrosoft,
  validateLogin,
  updateUser,
  registerUser,
  verifyEmail,
  forgotPassword,
  resetPassword,
  changePassword,
  createMFA,
  removeMFA,
  createRecoveryCodes,
  getRecoveryCodes,
  verifyMFACode,
  sendRecoveryCodes,
  logout,
  roles: OPEN_PERMISSIONS
};

export default AuthService;
