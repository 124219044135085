const LOGIN = '/login';

const LOGIN_REDIRECT = LOGIN + '/redirect';

const HOME = '/';

const PROJECT_SETTINGS = '/project-settings';

const PROJECT_GENERAL_SETTINGS = PROJECT_SETTINGS + '/general';

const EVENT_DATABASE_MANAGER = PROJECT_SETTINGS + '/event-database-manager';

const RESPONSE_DATABASE_MANAGER = PROJECT_SETTINGS + '/response-database-manager';

const USER_GROUPS = PROJECT_SETTINGS + '/user-groups';

const SYSTEM_SETTINGS = '/system-settings';

const SYSTEM_USERS = SYSTEM_SETTINGS + '/system-users';

const USER_SETTINGS = '/user-settings';

const CONVERSATIONS = '/conversation-data';

const ERROR = '/error';

const INTENTS = '/intents';

const RESPONSES = '/responses';

const CONVERSATION_VIEWER = '/conversation-viewer';

const PROJECT_HOME = RESPONSES;

export const routes = {
  CONVERSATIONS,
  PROJECT_GENERAL_SETTINGS,
  EVENT_DATABASE_MANAGER,
  RESPONSE_DATABASE_MANAGER,
  ERROR,
  HOME,
  LOGIN,
  LOGIN_REDIRECT,
  PROJECT_SETTINGS,
  INTENTS,
  RESPONSES,
  SYSTEM_SETTINGS,
  SYSTEM_USERS,
  USER_GROUPS,
  USER_SETTINGS,
  PROJECT_HOME,
  CONVERSATION_VIEWER
};
