import { Popover } from '@mui/material';
import { Button, ButtonProps } from 'primereact/button';
import React from 'react';
import { uniqueId } from 'util/uniqueIdGenerator';

/**
 * Menu designed to open a menu that controls filters
 *
 * @param children filter form to display
 * @param buttonProps props for the button
 * @component
 */
const FilterMenu = function ({
  children,
  buttonProps
}: {
  children: React.ReactNode;
  buttonProps: ButtonProps;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const buttonId = uniqueId('menu-btn');
  const menuId = uniqueId('menu');
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 1000 }}
      >
        {children}
      </Popover>
      <Button
        {...buttonProps}
        id={buttonId}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      />
    </div>
  );
};

export default FilterMenu;
