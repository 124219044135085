import React, { useState } from 'react';
import { DataTable, DataTableStateEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TopicTableDataModel } from 'models/topic-model';
import { mapIdToName } from 'util/mapIdAndName';
import { useAppSelector } from 'hooks/store';
import { CommentPopup, ThreadStateChangeEvent } from 'pages/Comments/CommentPopup';
import { CommentType } from 'models/comment-model';
import { CommentThreadButton } from 'pages/Comments/CommentThreadButton';
import { versionsSelector } from 'features/versions/versionsSlice';
import {
  LastModifiedCell,
  RemoveRetiredDialog,
  RetireDialog,
  TableHeader,
  TagsCell,
  TextCell
} from 'components/Table';
import { mapTags } from 'util/tags';
import {
  projectsSelector,
  tagsSelector,
  contentFiltersSelector
} from 'features/projects/projectsSlice';
import { EntityTableDataModel } from 'models/entity-model';
import { IntentTableDataModel } from 'models/intent-table-data-model';
import { ResponseTableDataModel } from 'models/response-table-data-model';
import { confirmDialog } from 'primereact/confirmdialog';
import { MenuButtonCell } from 'components/Table/MenuButtonCell';
import { Status } from 'models/status-model';
import { statusDisplayNames } from 'constants/status';
import { ProjectMemberUserModel, ProjectRole } from 'models/project-model';
import { permissionBoolean } from 'util/permissions';
import { customSortIconFn } from 'util/table';
import paginatorTemplate from 'components/Table/PaginatorTemplate';
import ParagraphCell from 'components/Table/ParagraphCell';
import UserGroupsCell from 'components/UserGroups/UserGroupsCell';
import { UserGroupModel } from 'models/user-groups-model';
import { userSharesUserGroupWith } from 'util/userGroups';
import { isNotAuthorOrAdmin } from 'util/users';
import LinkChipsCell from 'components/Table/LinkChipsCell';
import AvatarCell from 'components/Avatar/AvatarCell';
import ClickableTextCell from 'components/Table/ClickableTextCell';

type TopicDataTablePropsModel = {
  allEntities: EntityTableDataModel[];
  allIntents: IntentTableDataModel[];
  allResponses: ResponseTableDataModel[];
  allUserGroups: UserGroupModel[];
  canDelete?: boolean;
  isSuperUser?: boolean;
  isAdmin?: boolean;
  onApproveTopic?(node: TopicTableDataModel): void;
  onDeleteTopic?(e: TopicTableDataModel): void;
  onDuplicateTopic?(e: TopicTableDataModel): void;
  onEditInit?(topic: TopicTableDataModel): void;
  onHistoryOpen(e: TopicTableDataModel): void;
  onPublishTopic?(node: TopicTableDataModel): void;
  onRetireChange?(e: TopicTableDataModel, isRetired: boolean): void;
  onThreadStateChange?(options: ThreadStateChangeEvent): void;
  readOnly?: boolean;
  showComments?: boolean;
  topics: TopicTableDataModel[];
  tablestate: DataTableStateEvent;
  onTableStateChange(tablestate: DataTableStateEvent): void;
  totalRecords: number;
  loading?: boolean;
  authors: ProjectMemberUserModel[];
  projectUser: ProjectMemberUserModel;
};

/**
 * Component for displaying the Topic Table.
 *
 * @param allEntities List of all the entities
 * @param allIntents List of all the intents
 * @param allResponses List of all the responses
 * @param canDelete Flag indicating if the user can delete
 * @param isSuperUser Flag indicating if the user is a superuser
 * @param isAdmin Flag indicating if the user is an admin+
 * @param loading Flag indicating if the table is loading
 * @param onApproveTopic Callback function to approve a topic
 * @param onDeleteTopic Callback function for the delete topic action
 * @param onDuplicateTopic Callback function for the duplicate topic action
 * @param onEditInit Callback function for initializing the topic edit
 * @param onHistoryOpen Callback function for opening the history
 * @param onPublishTopic Callback function for the publishing topic action
 * @param onRetireChange Callback function for the retire topic action
 * @param onTableStateChange Callback function for the table state change action
 * @param onThreadStateChange Callback function for the thread state change action
 * @param readOnly Flag indicating if the table is read-only
 * @param showComments if the comments should be shown
 * @param tablestate The table state
 * @param topics List of all the topics
 * @param totalRecords The total number of records
 * @param authors current list of project members.
 * @param projectUser Current project user
 *
 * @constructor
 * @component
 */
const TopicDataTable = function ({
  allEntities,
  allIntents,
  allResponses,
  allUserGroups,
  canDelete = false,
  isSuperUser = false,
  isAdmin = false,
  loading = false,
  onApproveTopic,
  onDeleteTopic,
  onDuplicateTopic,
  onEditInit,
  onHistoryOpen,
  onPublishTopic,
  onRetireChange,
  onTableStateChange,
  onThreadStateChange,
  readOnly = false,
  showComments,
  tablestate,
  topics,
  totalRecords,
  authors,
  projectUser
}: TopicDataTablePropsModel) {
  const version = useAppSelector(versionsSelector);
  const allTags = useAppSelector(tagsSelector);
  const contentFilters = useAppSelector(contentFiltersSelector);
  const { selectedProject } = useAppSelector(projectsSelector);
  const [selectedNode, setSelectedNode] = useState<TopicTableDataModel>({} as TopicTableDataModel);
  const [showRetireDialog, setShowRetireDialog] = useState(false);
  const [showCommentPopup, setShowCommentPopup] = useState(false);
  const active = contentFilters?.active;
  const status = contentFilters?.status;

  const canEdit = (status: Status): boolean =>
    status !== Status.Retired && !version.isVersionSelected && !readOnly;

  /* - - - - - - - - - - Button Actions - - - - - - - - - - */

  /**
   * Opens the Retire dialog
   * @param node object being retired
   */
  const openRetirePopup = (node: any) => {
    setSelectedNode(node);
    setShowRetireDialog(true);
  };

  /**
   * Opens the comments dialog
   * @param node object to open the comments dialog for
   */
  const openComments = (node: any) => {
    setSelectedNode(node);
    setShowCommentPopup(true);
  };

  const confirmDeleteRow = (node: TopicTableDataModel) => {
    confirmDialog({
      message: (
        <div>
          <h6>This action cannot be undone.</h6>
          <ul className="list-disc">
            <li>This Topic cannot be recovered.</li>
          </ul>
        </div>
      ),
      header: 'Delete Row?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => onDeleteTopic?.(node)
    });
  };

  const canPublish = (node: TopicTableDataModel) =>
    node.status === Status.Approved &&
    permissionBoolean(
      [ProjectRole.ADMIN, ProjectRole.NEUROSOPH],
      selectedProject.project_user.role
    );

  // Strategists and admins can approve "New" or "Modified" statuses
  const canApprove = (node: TopicTableDataModel) =>
    (node.status === Status.New || node.status === Status.Modified) &&
    permissionBoolean(
      [ProjectRole.ADMIN, ProjectRole.NEUROSOPH, ProjectRole.CONTENT_STRATEGIST],
      selectedProject.project_user.role
    );

  return (
    <div>
      <h6>{TableHeader({ tableType: 'Topic', itemCount: totalRecords })}</h6>
      <DataTable<TopicTableDataModel[]>
        className="specto-data-table specto-border border-1"
        dataKey="id"
        value={topics}
        paginator
        sortIcon={customSortIconFn}
        selectionAutoFocus={false}
        paginatorTemplate={paginatorTemplate}
        lazy
        loading={loading}
        first={tablestate.first}
        rows={tablestate.rows}
        scrollable
        totalRecords={totalRecords}
        onPage={onTableStateChange}
        onSort={(e) => onTableStateChange({ ...e, page: 1, first: 0 })}
        sortField={tablestate.sortField}
        sortOrder={tablestate.sortOrder}
        editMode="row"
        onRowDoubleClick={(e) => onHistoryOpen(e.data as TopicTableDataModel)}
        metaKeySelection={false}
      >
        <Column
          field="name"
          className="specto-table__column--lg"
          bodyClassName="specto-table__column--text-button"
          header="Name"
          body={(node: TopicTableDataModel) =>
            ClickableTextCell({ text: node.name, action: () => onHistoryOpen(node) })
          }
          sortable
          pt={{ sort: { 'aria-label': 'sortable' } }}
        />
        <Column
          field="description"
          className="specto-table__column--xl"
          bodyClassName="specto-table__column--text"
          header="Description"
          body={(node: TopicTableDataModel) => <ParagraphCell text={node.description} />}
          pt={{ sort: { 'aria-label': 'sortable' } }}
        />
        <Column
          field="authors"
          header="Author"
          className="specto-table__column--sm"
          bodyClassName="specto-table__column--icon"
          align="center"
          body={(node: TopicTableDataModel) => {
            const fullName = authors.find((pm) => pm.user === node?.author)?.name || '';
            return fullName ? <AvatarCell fullName={fullName} id={node.id} /> : null;
          }}
        />
        <Column
          field="user_groups"
          className="specto-table__column--md"
          bodyClassName="specto-table__column--chips"
          header="User Groups"
          body={(node: TopicTableDataModel) =>
            UserGroupsCell({
              nodeId: node.id,
              userGroups: node.user_groups,
              referenceUserGroups: node.reference?.user_groups,
              allUserGroups: allUserGroups
            })
          }
          pt={{ sort: { 'aria-label': 'sortable' } }}
        />
        <Column
          field="intents"
          className="specto-table__column--md"
          bodyClassName="specto-table__column--chips"
          header="Intents"
          body={(node) =>
            LinkChipsCell({
              nodeId: 'intent-' + node.id,
              chips: node.intents.map((intent: number) => ({
                label: mapIdToName(intent, allIntents),
                url: `/intents?status=${status}&active=${active}&search=${mapIdToName(
                  intent,
                  allIntents
                )}`
              }))
            })
          }
        />
        <Column
          field="responses"
          className="specto-table__column--md"
          bodyClassName="specto-table__column--chips"
          header="Responses"
          body={(node) =>
            LinkChipsCell({
              nodeId: 'response-' + node.id,
              chips: node.responses.map((response: number) => ({
                label: mapIdToName(response, allResponses),
                url: `/responses?status=${status}&active=${active}&search=${mapIdToName(
                  response,
                  allResponses
                )}`
              }))
            })
          }
        />
        {isSuperUser && (
          <Column
            field="entities"
            className="specto-table__column--md"
            bodyClassName="specto-table__column--chips"
            header="Entities"
            body={(node) =>
              LinkChipsCell({
                nodeId: 'entity-' + node.id,
                chips: node.entities.map((entity: number) => ({
                  label: mapIdToName(entity, allEntities),
                  url: `/entities?status=${status}&active=${active}&search=${mapIdToName(
                    entity,
                    allEntities
                  )}`
                }))
              })
            }
          />
        )}
        <Column
          field="last_modified"
          className="specto-table__column--md"
          bodyClassName="specto-table__column--text"
          header="Last Modified"
          body={(node) =>
            LastModifiedCell({
              nodeId: 'last-modified-' + node.id,
              user: authors.find((author) => author.user === node.user),
              lastModified: node.last_modified
            })
          }
          sortable
          pt={{ sort: { 'aria-label': 'sortable' } }}
        />
        <Column
          field="status"
          header="Status"
          className="specto-table__column--sm"
          bodyClassName="specto-table__column--text"
          body={(node: TopicTableDataModel) =>
            TextCell({ id: node.id, text: statusDisplayNames[node.status] })
          }
          sortable
          pt={{ sort: { 'aria-label': 'sortable' } }}
        />
        {isSuperUser && (
          <Column
            field="tags"
            header={<span className="pi pi-tag" />}
            body={(node: TopicTableDataModel) => (
              <TagsCell tags={mapTags(allTags, node.tags)} editable={false} />
            )}
            className="specto-table-icon specto-table__column--sm"
            bodyClassName="specto-table__column--icon-button"
          />
        )}
        <Column
          field="util-comments"
          hidden={!showComments || readOnly || version.isVersionSelected}
          header="Comments"
          alignHeader="center"
          className="specto-table-icon specto-table__column--sm"
          bodyClassName="specto-table__column--icon-button"
          body={(node: TopicTableDataModel) => (
            <CommentThreadButton
              key={`${node.id}-comments`}
              threadResolved={node.comment_thread_resolved}
              action={() => openComments(node)}
              data-cy={`comment-button-${node.id}`}
            />
          )}
        />
        <Column
          columnKey="util-action"
          className="specto-table__column--sm-2 border-left-1"
          bodyClassName="specto-table__column--button"
          header="Actions"
          alignHeader="center"
          align="center"
          hidden={readOnly || version.isVersionSelected}
          frozen
          alignFrozen="right"
          body={(node: TopicTableDataModel) => (
            <MenuButtonCell
              data-cy={`${node.id}-actions`}
              menu={[
                {
                  label: 'Approve',
                  visible: !readOnly && canApprove(node),
                  disabled:
                    !userSharesUserGroupWith(
                      selectedProject.project_user,
                      node.reference?.user_groups
                    ) && isNotAuthorOrAdmin(projectUser, node.reference?.author),
                  tooltip:
                    !userSharesUserGroupWith(
                      selectedProject.project_user,
                      node.reference?.user_groups
                    ) && isNotAuthorOrAdmin(projectUser, node.reference?.author)
                      ? 'You cannot make changes to this topic.'
                      : undefined,
                  command: () => onApproveTopic?.(node)
                },
                {
                  label: 'Delete',
                  visible: canDelete && node.status === Status.Retired,
                  command: () => confirmDeleteRow(node)
                },
                {
                  label: 'Duplicate',
                  visible: node.status !== Status.Retired && isAdmin,
                  command: () => onDuplicateTopic?.(node)
                },
                {
                  label: 'Edit',
                  visible: canEdit(node.status),
                  disabled:
                    !userSharesUserGroupWith(
                      selectedProject.project_user,
                      node.reference?.user_groups
                    ) && isNotAuthorOrAdmin(projectUser, node.reference?.author),
                  tooltip:
                    !userSharesUserGroupWith(
                      selectedProject.project_user,
                      node.reference?.user_groups
                    ) && isNotAuthorOrAdmin(projectUser, node.reference?.author)
                      ? 'You cannot make changes to this topic.'
                      : undefined,
                  command: () => onEditInit?.(node)
                },
                {
                  label: 'Publish',
                  visible: !readOnly && canPublish(node),
                  disabled:
                    !userSharesUserGroupWith(
                      selectedProject.project_user,
                      node.reference?.user_groups
                    ) && isNotAuthorOrAdmin(projectUser, node.reference?.author),
                  tooltip:
                    !userSharesUserGroupWith(
                      selectedProject.project_user,
                      node.reference?.user_groups
                    ) && isNotAuthorOrAdmin(projectUser, node.reference?.author)
                      ? 'You cannot make changes to this topic.'
                      : undefined,
                  command: () => onPublishTopic?.(node)
                },
                {
                  label: 'Retire',
                  visible: canDelete && node.status !== Status.Retired,
                  command: () => openRetirePopup(node)
                },
                {
                  label: 'Unretire',
                  visible: canDelete && node.status === Status.Retired,
                  command: () =>
                    RemoveRetiredDialog({
                      objectName: CommentType.INTENT,
                      accept: () => onRetireChange?.(node, false)
                    })
                },
                {
                  label: 'No Actions Available',
                  disabled: true,
                  visible: !canDelete && node.status === Status.Retired
                }
              ]}
            />
          )}
        />
      </DataTable>
      {/* Need to use dialog component instead of method because the Retire dialog
          needs to be closed before the delete dialog can be opened */}
      <RetireDialog
        objectName={CommentType.TOPIC}
        accept={() => onRetireChange?.(selectedNode, true)}
        onHide={() => setShowRetireDialog(false)}
        showRetireDialog={showRetireDialog}
      />

      <CommentPopup
        displayPopup={showCommentPopup}
        onPopupDisplayChange={setShowCommentPopup}
        objectType={CommentType.TOPIC}
        objectId={selectedNode.id}
        objectName={selectedNode.name}
        parentElement={document.activeElement as HTMLElement}
        onThreadStateChange={onThreadStateChange}
      />
    </div>
  );
};

export default TopicDataTable;
