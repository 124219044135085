import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'hooks/store';
import { authSelector } from 'features/auth/authSlice';
import Login from 'components/Login';
import SSOLogin from 'components/Login/SSOLogin';
import BrandBanner from 'components/BrandBanner/BrandBanner';

const LoginPage = () => {
  const { isLoggedIn } = useAppSelector(authSelector);

  return (
    <div className="specto-login-page flex flex-column ">
      <BrandBanner className="top-0 left-0 fixed" />
      {isLoggedIn ? (
        <Navigate to="/" replace />
      ) : process.env.REACT_APP_USE_SSO === 'true' ? (
        <SSOLogin />
      ) : (
        <Login />
      )}
    </div>
  );
};

export default LoginPage;
