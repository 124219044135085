export type LanguageModel = {
  id: number;
  name: string;
};

export enum ResponseLanguageTranslation {
  English = 'English Only',
  Automated = 'Automated',
  Browser = 'Browser'
}
