import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { FieldValues, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'hooks/store';

import ProjectBlock from 'components/ProjectBlock';
import SearchBar from 'components/SearchBar';
import LoadingSpinner from 'components/LoadingSpinner';
import { FULLSCREEN_LAYOUT } from 'constants/layouts';
import {
  ProjectDetailModel,
  ProjectsPostRequestAPIModel,
  ProjectSummaryModel
} from 'models/project-model';
import { setLayout } from 'features/layout/layoutSlice';
import { createToast } from 'features/toast/toastSlice';
import {
  addProject,
  exitProject,
  getProjects,
  projectsSelector
} from 'features/projects/projectsSlice';
import ProjectsService from 'services/projectsService';
import { dirtyValues } from 'util/form';
import useFetch from 'hooks/useFetch';
import { FetchStatusOptions } from 'constants/fetchStatus';
import useToggleKeys from 'hooks/useToggleKeys';
import useFilter from 'hooks/useFilter';
import { SlugOptionsModel } from 'models/api-model';
import { clearSelectedVersion, versionsSelector } from 'features/versions/versionsSlice';
import { routes } from 'constants/routes';
import { authSelector } from 'features/auth/authSlice';
import NewProjectPopup from './components/NewProjectPopup';
import { permissionBoolean } from 'util/permissions';
import { getMainLayout } from 'util/layout';
import classNames from 'classnames';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { isProjectSelected, selectedProject, projects, isFetching } =
    useAppSelector(projectsSelector);
  const { developmentProject, isVersionSelected } = useAppSelector(versionsSelector);
  const { user } = useAppSelector(authSelector);
  const [nextNavLocation, setNextNavLocation] = useState('/');
  const [displayPopup, setDisplayPopup] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const pathname = searchParams.get('pathname');
  const pj_slug = searchParams.get('pj_slug');

  const {
    toggleKeySingle: toggleProjectLoading,
    isKeyExpanded: isProjectLoading,
    clearKeys: clearAllProjectLoading,
    hasExpandedKeys: isSomeProjectLoading
  } = useToggleKeys();
  const {
    setData: setAllProjectsData,
    searchText: searchProjectText,
    search: searchProjects,
    filteredData: filteredProjects,
    clearSearch: clearProjectsSearch,
    simpleSearch,
    loading: searchProjectLoading
  } = useFilter<ProjectSummaryModel>({ searchParameters: ['name', 'manager', 'company'] });
  const {
    data: projectDetailsData,
    fetch: fetchProjectDetails,
    fetchStatus: projectDetailsStatus
  } = useFetch<SlugOptionsModel, ProjectDetailModel>(
    ProjectsService.getProjectDetails,
    ProjectsService.roles.project.retrieve
  );
  const {
    data: createProjectData,
    fetch: createProject,
    fetchStatus: createProjectStatus
  } = useFetch<ProjectsPostRequestAPIModel, ProjectSummaryModel>(
    ProjectsService.createProject,
    user.is_superuser || ProjectsService.roles.project.create
  );

  const defaultValues: FieldValues = {
    company: '',
    manager: '',
    name: '',
    defaultIntentsResponses: false
  };
  const {
    control,
    formState: { dirtyFields },
    handleSubmit,
    reset
  } = useForm({ defaultValues });

  useEffect(() => {
    dispatch(
      setLayout(
        isProjectSelected
          ? getMainLayout(selectedProject?.project_user?.role, projects.length)
          : FULLSCREEN_LAYOUT
      )
    );
  }, [isProjectSelected]);

  /**
   * Skip dashboard if user is not an admin & only one project OR
   * There is pj_slug & pathname query params in URL
   */
  useEffect(() => {
    // Check to see if the dashboard has initialized
    // and that the projects have been fetched
    // So that the skip check does not occur prematurely
    if (pathname && pj_slug && projects.map((p) => p.slug).includes(pj_slug)) {
      setNextNavLocation('/' + pathname);
      fetchProjectDetails({ slug: pj_slug });
    } else if (initialized && !isFetching && projects.length === 1 && !user.is_superuser) {
      onProjectSelect(projects[0]);
    }
  }, [initialized, isFetching]);

  // onInit
  useEffect(() => {
    // Exit the project when returning to dashboard
    isProjectSelected && dispatch(exitProject());
    dispatch(getProjects());
    setTimeout(
      () => {
        setInitialized(true);
      },
      user.is_superuser ? 750 : 0
    );
  }, []);

  // create project details effect
  useEffect(() => {
    if (createProjectStatus === FetchStatusOptions.SUCCESS && createProjectData) {
      dispatch(addProject(createProjectData));
      clearProjectsSearch();
      hidePopup();
      dispatch(createToast('project created'));
    }
  }, [createProjectStatus]);

  // set all filtered data to projects
  useEffect(() => setAllProjectsData(projects), [projects]);

  const onProjectSelect = (project: ProjectSummaryModel) => {
    setNextNavLocation(routes.PROJECT_HOME);
    toggleProjectLoading(project.slug);
    fetchProjectDetails({ slug: project.slug });
  };

  // Get project details effect
  useEffect(() => {
    if (projectDetailsStatus === FetchStatusOptions.SUCCESS && projectDetailsData) {
      ProjectsService.activateProject({ dispatch, project: projectDetailsData });
      dispatch(clearSelectedVersion());

      if (
        nextNavLocation === routes.PROJECT_GENERAL_SETTINGS &&
        !permissionBoolean(
          ProjectsService.roles.project.update,
          projectDetailsData.project_user.role
        )
      ) {
        navigate(routes.PROJECT_HOME);
        dispatch(
          createToast("You do not have the permissions to access this Project's settings.", 'warn')
        );
      } else {
        navigate(nextNavLocation);
      }
    } else if (projectDetailsStatus === FetchStatusOptions.ERROR) {
      clearAllProjectLoading();
    }
  }, [projectDetailsStatus]);

  const selectProjectSettings = (project: ProjectSummaryModel) => {
    setNextNavLocation(routes.PROJECT_GENERAL_SETTINGS);
    toggleProjectLoading(project.slug);
    fetchProjectDetails({ slug: project.slug });
  };

  const hidePopup = (values?: any) => {
    reset(values);
    setDisplayPopup(false);
  };

  const onSubmit = (data: FieldValues) => {
    const formValues = dirtyValues(dirtyFields, data);

    const projectCreateRequest: ProjectsPostRequestAPIModel = {
      project: formValues
    };

    createProject(projectCreateRequest);
  };

  const onPopupSave = () => {
    handleSubmit(onSubmit)();
  };

  const isDashboardEmpty = () => initialized && !isFetching && projects.length === 0;

  const DashboardEmptyState = () => (
    <div className="col-12 flex flex-column px-5 align-items-center justify-content-center text-center">
      <div className="w-max mb-4 border-circle">
        <img
          src="/assets/layout/images/logo-muted.svg"
          alt="project-logo"
          className="h-13rem border-circle"
        />
      </div>
      {user.is_superuser ? (
        <>
          <h1 className="specto-text-medium">No Projects Available</h1>
          <h6 className="specto-text-muted">
            Create a project to get started. Once created, projects will appear here.
          </h6>
        </>
      ) : (
        <>
          <h1 className="specto-text-medium">Thank you for logging into the Studio</h1>
          <h6 className="specto-text-muted mt-1">
            You will be added to a project soon and will receive an email notification
          </h6>
          <div className="flex flex-row mt-5 align-items-center gap-2">
            <i className="pi pi-question-circle text-primary text-xl"></i>
            <a
              className="underline"
              href="https://massgovdigital.gitbook.io/ask-ma-chatbot-playbook/how-to-work-with-ask-ma/getting-support"
              target="_blank"
            >
              Get help or learn more about Ask MA Studio
            </a>
          </div>
        </>
      )}
    </div>
  );

  // Prevent flash of the dashboard appearing when the dashboard is skipped
  if (isFetching || !initialized || (projects.length === 1 && !user.is_superuser)) {
    return <LoadingSpinner />;
  }

  return (
    <div
      className={classNames('layout-dashboard grid grid-nogutter m-0', {
        'h-full': isDashboardEmpty()
      })}
    >
      <div className="col flex flex-wrap xl:align-items-center align-items-start justify-content-between flex-column-reverse xl:flex-row">
        {projects.length > 0 && (
          <SearchBar
            placeHolder="Search Projects"
            text={searchProjectText}
            loading={searchProjectLoading}
            className="specto-search-bar w-12 lg:w-7 xl:w-5 mt-3 xl:mt-0"
            tabIndex={0}
            onChange={(e) => simpleSearch(e.target.value)}
            onSubmitText={searchProjects}
            onClearText={() => {
              clearProjectsSearch();
              searchProjects('');
            }}
          />
        )}

        {user.is_superuser && !isVersionSelected && (
          <Button
            label="New Project"
            raised
            data-cy="new-project-button"
            icon="pi pi-plus"
            disabled={isSomeProjectLoading}
            onClick={() => setDisplayPopup(true)}
            tabIndex={0}
          />
        )}
      </div>

      {filteredProjects.length > 0 && (
        <div className="grid col-12 px-0 mt-4">
          {filteredProjects?.map((project: ProjectSummaryModel) => (
            <div className="col xl:col-4" key={project.slug}>
              <ProjectBlock
                active={
                  project.slug === selectedProject.slug || project.slug === developmentProject.slug
                }
                key={project.slug}
                project={project}
                loading={isProjectLoading(project.slug)}
                onSettingsSelect={selectProjectSettings}
                settingsVisible={!isVersionSelected}
                onProjectSelect={onProjectSelect}
              />
            </div>
          ))}
        </div>
      )}

      {isDashboardEmpty() && <DashboardEmptyState />}

      <NewProjectPopup
        visible={displayPopup}
        loading={createProjectStatus === FetchStatusOptions.LOADING}
        onHide={hidePopup}
        onSave={onPopupSave}
        control={control}
      />
    </div>
  );
};

export default Dashboard;
