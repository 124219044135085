import React from 'react';
import './_drag-sort.scss';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FormComponent, SortableFormComponent } from './drag-types';

type DragSortProps = {
  children: React.ReactNode;
  onReorder(components: SortableFormComponent[]): void;
};

/***
 * Pipes a React component to a { component: React Component } so it can be sorted in a structured format
 * @param component react component
 */
export const sortableFormComponentPipe = (component: FormComponent): SortableFormComponent[] =>
  (Array.isArray(component)
    ? component.map((component: FormComponent) => ({ component: component }))
    : component
      ? [{ component }]
      : []
  ).filter((value: SortableFormComponent) => value.component);

/***
 * Sortable children components leveraging reacts DataTable sortable rows
 * @param children children that will become drag-sortable
 * @param onRowReorder sorted data
 */
const DragSort = ({ children, onReorder }: DragSortProps) => {
  const reorder = (e: any) => {
    onReorder(e.value);
  };

  return (
    <DataTable
      value={sortableFormComponentPipe(children)}
      reorderableRows
      emptyMessage=" "
      className="specto-drag-sort"
      onRowReorder={reorder}
      tableStyle={{ tableLayout: 'fixed' }}
    >
      <Column rowReorder bodyClassName="col-1 px-0 py-2" header="Drag Sort" />
      <Column
        field="component"
        header="Component"
        bodyClassName="py-2 pr-0"
        bodyStyle={{ paddingLeft: 3 }}
      />
    </DataTable>
  );
};

export default DragSort;
