import React from 'react';
// components
import { IconButtonCell, iconButtonCellIcon } from 'components/Table';
import { ColumnBodyOptions } from 'primereact/column';

type RowEditorCellPropsModel = {
  nodeId: number;
  options: ColumnBodyOptions;
  rowEditIndex: Record<string, never>;
  deleteItem?: (id: number) => void;
  disableEdit?: boolean;
  disableDelete?: boolean;
  otherActions?: (JSX.Element | null)[];
};

/**
 * Component to display cell deletion or cell editing buttons.
 * @component
 * @param param0 Prop object containing the following:
 *
 *    node: the row data
 *
 *    options: Extra options. Normally this is of type ColumnBodyOptions. However, ColumnBody
 *    options says rowEditor is of type ColumnBodyRowEditorOptions which currently does not show
 *    that rowEditor has an 'element' attribute. Must type options as any for this reason.
 *
 *    rowEditIndex: id of the row being edited
 *
 *    deleteItem: callback to delete a row.
 *
 *    canDelete: if the current can delete
 *
 *    disableEdit: hides the edit button
 *
 *    otherActions: List of other components with actions that can be performed
 */
const RowEditorCell = function ({
  nodeId,
  options,
  rowEditIndex,
  deleteItem,
  disableEdit = false,
  disableDelete = false,
  otherActions
}: RowEditorCellPropsModel) {
  const showDeleteButton = !disableDelete && deleteItem && Object.keys(rowEditIndex).length === 0;

  return (
    <div
      id={`specto-row-editor-${nodeId}`}
      className="flex justify-content-center align-content-center"
    >
      {!disableEdit &&
        (options.rowEditor?.editing ? (
          <>
            <IconButtonCell
              icon="pi-check"
              action={(e) => options.rowEditor?.onSaveClick?.(e)}
              tooltip="Save"
              data-cy={`save-button-${nodeId}`}
            />
            <IconButtonCell
              icon="pi-times"
              action={(e) => options.rowEditor?.onCancelClick?.(e)}
              tooltip="Cancel"
              data-cy={`cancel-button-${nodeId}`}
            />
          </>
        ) : (
          <IconButtonCell
            icon={iconButtonCellIcon.EDIT}
            action={(e) => options.rowEditor?.onInitClick?.(e)}
            tooltip="Edit"
            data-cy={`edit-button-${nodeId}`}
          />
        ))}
      {otherActions}
      {showDeleteButton && (
        <IconButtonCell
          icon={iconButtonCellIcon.DELETE}
          action={() => deleteItem(nodeId)}
          tooltip="Delete"
          data-cy={`delete-button-${nodeId}`}
        />
      )}
    </div>
  );
};

export default RowEditorCell;
