import React from 'react';
import { Tooltip, TooltipProps } from 'primereact/tooltip';
import { uniqueId } from 'util/uniqueIdGenerator';
import classnames from 'classnames';
import { Status } from 'models/status-model';

type GenericItemTemplateProps = {
  allOptions: { id: number; name: string; status: Status }[];
  dropdownPadding?: boolean;
  id: number;
  itemName?: string;
  showRetiredIcon?: boolean;
  spaceBetween?: boolean;
  showNewIcon?: boolean;
};

/**
 * Generic Item template
 * @param allOptions all options
 * @param dropdownPadding have extra padding so the dropdown option appears better
 * @param id generic id
 * @param itemName custom item name
 * @param showNewIcon whether to show the new option badge
 * @param showRetiredIcon whether to show the retired option badge
 * @param spaceBetween whether the badge should hug the option or there should be space
 */
const GenericItemTemplate = ({
  allOptions,
  dropdownPadding = false,
  id,
  itemName = 'item',
  showNewIcon,
  showRetiredIcon,
  spaceBetween = false
}: GenericItemTemplateProps) => {
  const tooltipKey = uniqueId();
  const option = allOptions.find((option) => option.id === id);

  const genericStatusIconRecord: Partial<
    Record<Status, { icon: string; tooltip: TooltipProps['children'] | JSX.Element }>
  > = {
    [Status.New]: {
      icon: 'pi pi-circle-fill text-green-300',
      tooltip: (
        <div>
          This {itemName} has status <br />
          <strong className="line-height-4">{Status.New}</strong>
        </div>
      )
    },
    [Status.Retired]: {
      icon: 'pi pi-circle-fill specto-error-icon',
      tooltip: `This ${itemName} has been retired`
    }
  };

  const mappedIcon =
    showRetiredIcon && option?.status == Status.Retired
      ? genericStatusIconRecord[Status.Retired]
      : showNewIcon && option?.status === Status.New
        ? genericStatusIconRecord[Status.New]
        : undefined;

  return (
    <div
      className={classnames('specto-generic-item-template flex align-content-center', {
        'justify-content-between': spaceBetween,
        'pl-3 pr-7': dropdownPadding
      })}
    >
      <span className={classnames({ 'pr-2': !spaceBetween })}>{option?.name}&nbsp;</span>

      {mappedIcon && (
        <div
          className={classnames(
            'label-item-template-tooltip-' + tooltipKey,
            'flex align-items-center'
          )}
        >
          <i className={mappedIcon.icon} />
          <Tooltip
            baseZIndex={9999}
            target={'.label-item-template-tooltip-' + tooltipKey}
            position="bottom"
          >
            {mappedIcon.tooltip}
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default GenericItemTemplate;
