import React, { useContext } from 'react';
import { ResponseFormContext } from './ResponseFormContext';
import { TextField } from 'components/Forms';

const CardFieldComponent = function ({
  baseField,
  browsing = false
}: {
  baseField: string;
  browsing?: boolean;
}) {
  const { control } = useContext(ResponseFormContext);

  return (
    <div className="col flex flex-column w-full">
      <TextField
        label="Primary Text"
        fieldName={`${baseField}.primary_text`}
        control={control}
        className="pb-3"
        noPadding
        info="The primary text of the card"
      />
      <TextField
        label="Secondary Text"
        fieldName={`${baseField}.secondary_text`}
        control={control}
        className="pb-3"
        noPadding
        info="The secondary text of the card"
      />
      <TextField
        label="Image URL"
        fieldName={`${baseField}.image`}
        control={control}
        className="pb-3"
        noPadding
        info="The URL of the image to display"
      />
      <TextField
        label="Image Alt Text"
        fieldName={`${baseField}.alt_text`}
        control={control}
        className="pb-3"
        noPadding
        info="This is descriptive text that conveys the meaning and context of an image. It's used for screen readers or if the image cannot be rendered."
      />
      {browsing && (
        <div className="flex justify-content-between">
          <TextField
            label="Link Label"
            fieldName={`${baseField}.payload_label`}
            control={control}
            className="w-6 pr-3"
            info="The text that will appear on the link button"
          />
          <TextField
            className="w-6 pl-3"
            label="Link URL"
            fieldName={`${baseField}.payload`}
            control={control}
            info="The URL that will be opened if the link button is clicked"
          />
        </div>
      )}
    </div>
  );
};

export default CardFieldComponent;
