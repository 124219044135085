import React, { useContext } from 'react';
import { Button } from 'primereact/button';
import { ResponseFormContext } from './ResponseFormContext';
import { useFieldArray } from 'react-hook-form';
import { getDefaultResponseContent } from 'constants/responses';
import { TextField } from 'components/Forms';
import ResponseComponentContainer from './ResponseComponentContainer';
import RemovableFormField from './RemovableFormField';
import { ResponseTypeOptions } from 'models/response-table-data-model';
import { DragSort, SortableFormComponent } from 'features/drag-sort';
import { reorderResponseFields } from 'util/response';
import Info from 'components/Forms/Info';

/**
 * Component that displays dynamic fields to define a "video playlist"
 * @component
 */
const ResponseVideoPlaylist = function ({ componentIndex }: { componentIndex: number }) {
  const contentField = `content.${componentIndex}.content`;
  const { control, moveField, getValues } = useContext(ResponseFormContext);
  const { append, remove, fields, replace } = useFieldArray({
    control,
    name: contentField
  });

  const onReorder = (components: SortableFormComponent[]) =>
    replace(
      reorderResponseFields(components, fields, getValues()?.content?.[componentIndex]?.content)
    );

  return (
    <ResponseComponentContainer
      componentIndex={componentIndex}
      title="Video Playlist"
      key={componentIndex}
      move={moveField}
    >
      <div className="w-full pt-0">
        <div className="flex col col-offset-1 pt-5 pb-0">
          <h6 className="mb-0">Video URLs</h6>
          <Info text="The URLs of the videos to be displayed" />
        </div>
        <DragSort onReorder={onReorder}>
          {fields.map((item: any, index) => (
            <RemovableFormField
              disabled={fields.length < 2}
              key={item.id}
              onRemove={() => remove(index)}
              roundDeleteButton
            >
              <TextField
                noPadding
                fieldName={`${contentField}.${index}.url`}
                control={control}
                className="col w-full"
                placeholder="Enter video URL"
                ariaLabel={`row ${index} url`}
              />
            </RemovableFormField>
          ))}
        </DragSort>
        <div className="flex justify-content-end ml-5">
          <Button
            className="font-semibold specto-text-medium p-button-text underline text-sm py-2 w-auto"
            label="ADD VIDEO"
            type="button"
            onClick={() => append(getDefaultResponseContent(ResponseTypeOptions.VIDEO_PLAYLIST)[0])}
            data-cy="add-video"
          />
          <div className="col-2"></div>
        </div>
      </div>
    </ResponseComponentContainer>
  );
};

export default ResponseVideoPlaylist;
