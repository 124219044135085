import React, { useEffect, useState } from 'react';
import ModalLarge from 'containers/modals/ModalLarge';
import useFilter from 'hooks/useFilter';
import SearchBar from 'components/SearchBar';
import { Button } from 'primereact/button';
import { UserGroupAPIModel, UserGroupModel } from 'models/user-groups-model';
import UserGroupsPopup from './UserGroupsPopup';
import { FormMode } from 'models/form-model';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import {
  addUserGroup,
  deleteUserGroup,
  editUserGroup,
  getUserGroups,
  userGroupsSelector
} from 'features/projects/projectsSlice';
import { setLayout } from 'features/layout/layoutSlice';
import { FieldValues } from 'react-hook-form';
import useFetch from 'hooks/useFetch';
import UserGroupsService from 'services/userGroupsService';
import { FetchStatusOptions } from 'constants/fetchStatus';
import { createToast } from 'features/toast/toastSlice';
import MenuButton from 'components/MenuButton/MenuButton';
import { confirmDialog } from 'primereact/confirmdialog';
import { getProjectSettingsLayout } from 'util/layout';
import { authSelector } from 'features/auth/authSlice';

const UserGroups = () => {
  const dispatch = useAppDispatch();
  const { user: ownUser } = useAppSelector(authSelector);
  const [popupMode, setPopupMode] = useState<FormMode>(FormMode.CREATE);
  const [userGroupContext, setUserGroupContext] = useState<UserGroupModel | null>(null);
  const [displayUserGroupPopup, setDisplayUserGroupPopup] = useState<boolean>(false);
  const allUserGroups = useAppSelector(userGroupsSelector);

  const {
    filteredData: filteredUserGroupsData,
    search: searchUsers,
    searchText,
    clearSearch,
    simpleSearch,
    setData: setFilteredUserGroupsData,
    loading: searchUsersLoading
  } = useFilter<UserGroupModel>();

  /**
   * Callback to change the new user group pop-up visibility in state.
   * @param visibility boolean representing the visibility of the new user group popup
   * @param context the context user group, used for editing
   */
  const handlePopupDisplayChange = (
    visibility: boolean,
    mode = FormMode.CREATE,
    context: UserGroupModel | null = null
  ) => {
    setPopupMode(mode);
    setUserGroupContext(context);
    setDisplayUserGroupPopup(visibility);
  };

  /* - - - - - - - - - - Create User Group - - - - - - - - - - */
  const {
    fetch: createUserGroup,
    data: createUserGroupData,
    fetchStatus: createUserGroupStatus
  } = useFetch(UserGroupsService.createUserGroup, UserGroupsService.roles.create);

  useEffect(() => {
    if (createUserGroupStatus === FetchStatusOptions.SUCCESS && createUserGroupData) {
      dispatch(addUserGroup(createUserGroupData));
      dispatch(createToast('User Group Created'));
      hidePopup();
    }
  }, [createUserGroupStatus]);

  /* - - - - - - - - - - Edit User Group - - - - - - - - - - */
  const {
    fetch: modifyUserGroup,
    data: modifyUserGroupData,
    fetchStatus: modifyUserGroupStatus
  } = useFetch(UserGroupsService.modifyUserGroup, UserGroupsService.roles.update);

  useEffect(() => {
    if (modifyUserGroupStatus === FetchStatusOptions.SUCCESS && modifyUserGroupData) {
      dispatch(editUserGroup(modifyUserGroupData));
      dispatch(createToast('User Group Edited'));
      hidePopup();
    }
  }, [modifyUserGroupStatus]);

  /* - - - - - - - - - - Delete User Group - - - - - - - - - - */
  const { fetch: removeUserGroup, fetchStatus: deleteUserGroupStatus } = useFetch(
    UserGroupsService.removeUserGroup,
    UserGroupsService.roles.delete
  );

  useEffect(() => {
    if (deleteUserGroupStatus === FetchStatusOptions.SUCCESS && userGroupContext) {
      dispatch(deleteUserGroup(userGroupContext?.id));
      dispatch(createToast('User Group Deleted'));
      hidePopup();
    }
  }, [deleteUserGroupStatus]);

  /* Handler function when deletion of user group is confirmed inside the popup */
  const onDeleteUserGroup = (userGroup: UserGroupModel) => removeUserGroup(userGroup.id);

  // set all filtered data to projects
  useEffect(() => setFilteredUserGroupsData(allUserGroups), [allUserGroups]);

  /* - - - - - - - - - - Get Filtered (All) User Groups - - - - - - - - - - */
  useEffect(() => {
    dispatch(getUserGroups());
    dispatch(setLayout(getProjectSettingsLayout(ownUser.is_superuser)));
    setFilteredUserGroupsData(allUserGroups);
  }, []);

  const onSubmit = (data: FieldValues) => {
    const userGroupData = data as UserGroupAPIModel;

    if (userGroupContext && popupMode === FormMode.EDIT) {
      modifyUserGroup({
        id: userGroupContext.id,
        num_projects: userGroupContext.num_projects,
        ...userGroupData
      });
    } else createUserGroup(userGroupData);
  };

  /**
   * Function that hides the popup
   */
  const hidePopup = () => handlePopupDisplayChange(false);

  /**
   * Delete confirmation popup
   * @param node node to delete
   */
  const confirmDeleteUserGroup = (node: UserGroupModel) => {
    confirmDialog({
      message:
        'Deleting this user group will remove it from all your projects and automatically detach it from any associated Responses, Intents, and Topics.',
      header: `Delete "${node.name}" User Group?`,
      acceptClassName: 'p-button-danger',
      accept: () => onDeleteUserGroup?.(node),
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete'
    });
  };

  return (
    <ModalLarge className="specto-system-settings relative">
      <div className="info-banner bg-red top-0 absolute px-3 py-2 w-full left-0 text-center	">
        <i className="pi pi-info-circle text-primary mr-2"></i>
        <span className="vertical-align-top">
          Any changes to User Groups will be applied to all projects in your organization
        </span>
      </div>
      <h1 className="align-self-center mb-7">User Groups</h1>

      <h6>User Groups</h6>

      <div className="w-full flex-column-reverse gap-5 flex align-items-start p-0 xl:flex-row justify-content-between">
        <SearchBar
          className="specto-search-bar w-12 xl:w-8 pb-5 column"
          placeHolder="Search all User Groups"
          text={searchText}
          formInputStyle
          loading={searchUsersLoading}
          onChange={(e) => simpleSearch(e.target.value)}
          onSubmitText={searchUsers}
          onClearText={() => {
            clearSearch();
            searchUsers('');
          }}
        />
        <Button
          onClick={() => setDisplayUserGroupPopup(true)}
          label="New Group"
          icon="pi pi-plus"
          iconPos="right"
          className="white-space-nowrap align-items-center flex justify-content-end align-self-end xl:align-self-start"
        />
      </div>

      <div className="user-groups-container w-full">
        {filteredUserGroupsData.map(({ id, name, num_projects }) => (
          <div className="user-group-widget flex flex-column p-3" key={id}>
            <div className="w-full flex flex-row justify-content-between align-items-center">
              <h4 className="user-group-name">{name}</h4>
              <MenuButton
                options={[
                  {
                    label: 'Edit',
                    visible: true,
                    command: () =>
                      handlePopupDisplayChange(true, FormMode.EDIT, { id, name, num_projects }),
                    icon: <i className="pi pi-pencil"></i>
                  },
                  {
                    label: 'Delete',
                    visible: true,
                    command: () => {
                      setUserGroupContext({ id, name, num_projects });
                      confirmDeleteUserGroup({ id, name, num_projects });
                    },
                    icon: <i className="pi pi-trash"></i>
                  }
                ]}
                buttonProps={{
                  label: '',
                  icon: <i className="pi pi-ellipsis-v"></i>,
                  type: 'button',
                  className: 'border-circle bg-primary-reverse text-color'
                }}
              />
            </div>
            <p>
              Used in {num_projects} project{num_projects !== 1 ? 's' : ''}
            </p>
          </div>
        ))}
      </div>

      <UserGroupsPopup
        formMode={popupMode}
        onHide={hidePopup}
        displayPopup={displayUserGroupPopup}
        parentElement={document.activeElement as HTMLElement}
        userGroupContext={userGroupContext}
        onSubmit={onSubmit}
      />
    </ModalLarge>
  );
};

export default UserGroups;
