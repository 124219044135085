import React from 'react';
import { Control, Controller } from 'react-hook-form';
import classNames from 'classnames';
import classnames from 'classnames';
import { getFormErrorMessage, oneSpaceFormatter } from 'util/form';
import { KeyFilterType } from 'primereact/keyfilter';
import { Dropdown, DropdownChangeEvent, DropdownProps } from 'primereact/dropdown';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { TooltipOptions } from 'primereact/tooltip/tooltipoptions';
import { UseControllerProps } from 'react-hook-form/dist/types/controller';
import Info from './Info';

/**
 * Component that displays a basic text field in a form.
 *
 * @param appendTo where to append the component.
 * @param characterLimit Character limit for the input.
 * @param className Custom class name.
 * @param control react-hook-form control.
 * @param defaultValue Default value for dropdown.
 * @param disabled Whether the input is disabled or not.
 * @param editable If the dropdown selection is editable.
 * @param fieldName The field name in react-hook-form.
 * @param filter Enable/disable primeReact filter option (to allow dropdown to be searched).
 * @param filterBy What the filter should search by.
 * @param formState react-hook-form form state.
 * @param itemTemplate dropdown item template.
 * @param label Label for the input.
 * @param labelClassName Class name for custom label styling.
 * @param noPadding errorBoundary adds 20px margin when no error occurs so that when an error happens the UI doesn't move.
 * @param onChange Optional onChange function.
 * @param optionDisabled If a single item is disabled.
 * @param optionGroupChildren What property of the options contains the group's children.
 * @param optionGroupLabel What property of the options contains the group's label.
 * @param optionLabel What property of the options contains the label.
 * @param optionValue What property of the options contains the value.
 * @param options Dropdown options.
 * @param placeholder Dropdown placeholder.
 * @param readOnly For readonly input.
 * @param required If the dropdown is required.
 * @param tooltip the tooltip.
 * @param tooltipOptions the options for the tooltip.
 * @param valueTemplate dropdown item template.
 */
const DropdownField = function ({
  appendTo = 'self',
  characterLimit = 200,
  className,
  control,
  defaultValue = '',
  disabled,
  editable,
  fieldName,
  filter,
  filterBy,
  filterPlaceholder,
  info,
  itemTemplate,
  label,
  labelClassName,
  noPadding,
  onChange,
  optionDisabled,
  optionGroupChildren,
  optionGroupLabel,
  optionLabel,
  optionValue,
  options,
  placeholder,
  readOnly,
  required = true,
  rules,
  tooltip,
  tooltipOptions,
  valueTemplate
}: {
  appendTo?: DropdownProps['appendTo'];
  characterLimit?: number;
  className?: string;
  control: Control;
  defaultValue?: any;
  disabled?: boolean;
  editable?: boolean;
  fieldName: string;
  filter?: boolean;
  filterBy?: string;
  filterPlaceholder?: string;
  info?: string;
  itemTemplate?: DropdownProps['itemTemplate'];
  keyFilter?: KeyFilterType;
  label?: string;
  labelClassName?: string;
  noPadding?: boolean;
  onChange?(e: DropdownChangeEvent): void;
  optionDisabled?: DropdownProps['optionDisabled'];
  optionGroupChildren?: string;
  optionGroupLabel?: string;
  optionLabel?: string;
  optionValue?: string;
  options: SelectItemOptionsType;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  rules?: UseControllerProps['rules'];
  tooltip?: string;
  tooltipOptions?: TooltipOptions;
  valueTemplate?: DropdownProps['valueTemplate'];
}) {
  return (
    <div className={classnames(className, { 'field pb-3': !noPadding })}>
      <div className="flex">
        {label && (
          <label htmlFor={fieldName + 'input'} className={classnames(labelClassName)}>
            {label} {required && <span className="specto-form-asterisk-color">*</span>}
          </label>
        )}
        {info && <Info text={info} />}
      </div>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        rules={{
          ...(required && { required: 'Required field' }),
          maxLength: {
            value: characterLimit,
            message: `Exceeded max length of ${characterLimit} characters`
          },
          ...rules
        }}
        render={({ field, fieldState }) => (
          <>
            <Dropdown
              {...field}
              id={field.name}
              inputId={fieldName + 'input'}
              aria-labelledby={fieldName + 'input'}
              value={field?.value}
              placeholder={placeholder}
              filterPlaceholder={filterPlaceholder}
              onChange={(e) => {
                field.onChange(oneSpaceFormatter(e.value));
                onChange?.(e);
              }}
              options={options}
              optionLabel={optionLabel}
              optionValue={optionValue}
              valueTemplate={valueTemplate}
              itemTemplate={itemTemplate}
              editable={editable}
              disabled={disabled}
              tooltip={tooltip}
              tooltipOptions={tooltipOptions}
              optionDisabled={optionDisabled}
              filter={filter}
              ref={null}
              filterBy={filterBy}
              optionGroupLabel={optionGroupLabel}
              optionGroupChildren={optionGroupChildren}
              className={classNames('specto-input-select', {
                'p-invalid': fieldState.error
              })}
              readOnly={readOnly}
              appendTo={appendTo}
              aria-invalid={fieldState?.error ? 'true' : 'false'}
              data-cy={field.name}
            />
            {getFormErrorMessage(fieldState.error, noPadding)}
          </>
        )}
      />
    </div>
  );
};

export default DropdownField;
