import Menu from '@mui/material/Menu';
import { ListItemIcon, MenuItem } from '@mui/material';
import { Button, ButtonProps } from 'primereact/button';
import React, { ReactElement } from 'react';
import { uniqueId } from 'util/uniqueIdGenerator';
import { Tooltip } from 'primereact/tooltip';

export type MenuItemProps = {
  label: string;
  visible?: boolean;
  disabled?: boolean;
  command?: () => void;
  tooltip?: string;
  icon?: ReactElement;
};
/**
 * Button that opens a menu
 *
 * @param options menu options
 * @param buttonProps button props
 * @param cypressTag cypress tag
 * @returns
 */
const MenuButton = function ({
  options,
  buttonProps,
  cypressTag
}: {
  options: MenuItemProps[];
  buttonProps: ButtonProps;
  cypressTag?: string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const buttonId = uniqueId('menu-btn');
  const menuId = uniqueId('menu');
  const visibleOptions = options.filter((o) => ('visible' in o ? o.visible : true));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  return (
    <div>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{ zIndex: 3600 }}
        MenuListProps={{
          'aria-labelledby': buttonId
        }}
      >
        {visibleOptions.map((option, index) => (
          <MenuItem
            className="material-option flex flex-row align-items-center"
            disabled={option.disabled}
            onClick={() => {
              handleClose();
              option.command && option.command();
            }}
            aria-label={option.label}
            id={`${menuId}-${index}`}
            key={`${menuId}-${index}`}
          >
            {option.tooltip ? (
              <Tooltip
                baseZIndex={3601}
                target={`#${menuId}-${index}`}
                position="left"
                content={option.tooltip}
              />
            ) : undefined}
            {option?.icon && <ListItemIcon className="text-color">{option.icon}</ListItemIcon>}
            {option.label}
          </MenuItem>
        ))}
      </Menu>
      <Button
        {...buttonProps}
        id={buttonId}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? buttonId : undefined}
        onClick={handleClick}
        data-cy={cypressTag}
      />
    </div>
  );
};

export default MenuButton;
