import ApiService from 'services/apiService';
import { IntentTableAPIModel, IntentTableDataModel } from 'models/intent-table-data-model';
import { APPROVE_API_URL, INTENTS_API_URL, PUBLISH_API_URL, RETIRE_API_URL } from 'constants/api';
import {
  RetiredOptionsModel,
  SlugDataIdOptionsModel,
  SlugDataOptionsModel,
  SlugIdOptionsModel,
  SlugQueryOptionsModel
} from 'models/api-model';
import { OPEN_VIEW_ADMIN_MODIFY_PERMISSIONS } from 'constants/roles';
import { PaginationModel } from 'models/filter-model';

const getIntents = (options: SlugQueryOptionsModel) =>
  ApiService.get<IntentTableDataModel[]>(
    '/' + options.slug + INTENTS_API_URL + (options.query ?? '')
  );

const getPaginatedIntents = (options: SlugQueryOptionsModel) =>
  ApiService.get<PaginationModel<IntentTableDataModel>>(
    '/' + options.slug + INTENTS_API_URL + (options.query ?? '')
  );

const getIntent = (options: SlugIdOptionsModel) =>
  ApiService.get<IntentTableDataModel>('/' + options.slug + INTENTS_API_URL + options.id + '/');

const createIntent = (options: SlugDataOptionsModel<IntentTableAPIModel>) =>
  ApiService.post<IntentTableDataModel, IntentTableAPIModel>(
    '/' + options.slug + INTENTS_API_URL,
    options.data
  );

const deleteIntent = (options: SlugIdOptionsModel) =>
  ApiService.remove<IntentTableDataModel>('/' + options.slug + INTENTS_API_URL + options.id + '/');

const editIntent = (options: SlugDataIdOptionsModel<IntentTableAPIModel>) =>
  ApiService.put<IntentTableDataModel>(
    '/' + options.slug + INTENTS_API_URL + options.id + '/',
    options.data
  );

const retireIntent = (options: RetiredOptionsModel) =>
  ApiService.put<IntentTableDataModel>(
    '/' + options.slug + INTENTS_API_URL + options.id + RETIRE_API_URL,
    options.retired
  );

const publishIntent = (options: SlugIdOptionsModel) =>
  ApiService.post<IntentTableDataModel>(
    '/' + options.slug + INTENTS_API_URL + options.id + PUBLISH_API_URL
  );

const approveIntent = (options: SlugIdOptionsModel) =>
  ApiService.post<IntentTableDataModel>(
    '/' + options.slug + INTENTS_API_URL + options.id + APPROVE_API_URL
  );

const IntentsService = {
  approveIntent,
  createIntent,
  deleteIntent,
  editIntent,
  getIntent,
  getIntents,
  getPaginatedIntents,
  publishIntent,
  retireIntent,
  roles: OPEN_VIEW_ADMIN_MODIFY_PERMISSIONS
};

export default IntentsService;
