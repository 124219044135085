import React from 'react';
import { TagModel } from 'models/tag-model';

type TagProps = {
  tag: TagModel;
  size?: 'small' | 'normal';
};

/**
 * Component for displaying the tag name with a circle to the left that is the tag color.
 * @component
 * @param tag tag
 * @param size small tag shows just the dot, otherwise normal
 */
const Tag = function ({ tag, size = 'normal' }: TagProps) {
  return (
    <div className="specto-tag flex justify-content-left align-items-center" data-cy={tag.id}>
      <span
        className="border-circle"
        style={{ width: 10, minWidth: 10, maxWidth: 10, height: 10, backgroundColor: tag.color }}
      />
      {size === 'normal' && <div className="font-semibold mx-2">{tag.name}</div>}
    </div>
  );
};

export default Tag;
