import React from 'react';
import { Navigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { routes } from 'constants/routes';

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

const ErrorBoundary = function ({ children }: ErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary fallback={<Navigate to={routes.ERROR} />}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
