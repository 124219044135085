import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { VersionHistory, VersionSummary } from 'models/version-model';
import VersionsService from 'services/versionsService';
import { SlugIdOptionsModel } from 'models/api-model';
import { ProjectDetailModel } from 'models/project-model';

export type VersionsState = {
  developmentProject: ProjectDetailModel;
  error: boolean;
  loading: boolean;
  selectedVersion: VersionHistory;
  versions: VersionHistory[];
  isVersionSelected: boolean;
};

const initialState: VersionsState = {
  developmentProject: {} as ProjectDetailModel,
  selectedVersion: {} as VersionHistory,
  error: false,
  loading: false,
  versions: [],
  isVersionSelected: false
};

export const versionsSlice = createSlice({
  name: 'versions',
  initialState,
  reducers: {
    selectVersion: (state, action: PayloadAction<VersionHistory>) => {
      state.selectedVersion = action.payload;
      state.isVersionSelected = true;
    },
    clearVersions: (state) => {
      state.versions = initialState.versions;
      state.developmentProject = initialState.developmentProject;
      state.selectedVersion = initialState.selectedVersion;
      state.isVersionSelected = false;
    },
    clearSelectedVersion: (state) => {
      state.selectedVersion = initialState.selectedVersion;
      state.developmentProject = initialState.developmentProject;
      state.isVersionSelected = false;
    },
    updateVersion: (state, action: PayloadAction<VersionSummary>) => {
      if (state.selectedVersion?.slug === action.payload.slug) {
        Object.assign(state.selectedVersion, action.payload);
      }

      state.versions = state?.versions?.map((version) =>
        version.slug === action.payload.slug ? { ...version, ...action.payload } : version
      );
    },
    addVersion: (state, action: PayloadAction<VersionHistory>) => {
      state.versions = [action.payload, ...state.versions];
    },
    removeVersion: (state, action: PayloadAction<VersionHistory['slug']>) => {
      state.versions = state.versions.filter(
        (version: VersionHistory) => version.slug !== action.payload
      );
    },
    saveDevelopmentProject: (state, action: PayloadAction<ProjectDetailModel>) => {
      state.developmentProject = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVersions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVersions.fulfilled, (state, action: PayloadAction<VersionHistory[]>) => {
        state.versions = action.payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(getVersions.rejected, (state) => {
        state.versions = initialState.versions;
        state.loading = false;
        state.error = true;
      });
  }
});

/* - - - - - - - - - - Selectors - - - - - - - - - - */

export const versionsSelector = (state: RootState) => state.versions;

export const {
  selectVersion,
  clearSelectedVersion,
  clearVersions,
  updateVersion,
  addVersion,
  removeVersion,
  saveDevelopmentProject
} = versionsSlice.actions;

/* - - - - - - - - - - Thunks - - - - - - - - - - */
export const getVersions = createAsyncThunk<
  VersionHistory[],
  { slug: SlugIdOptionsModel['slug'] },
  { state: RootState }
>('versions/getVersions', async (data, thunkAPI) => {
  try {
    return await VersionsService.getVersions({ slug: data.slug });
    // return mockVersionHistory;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export default versionsSlice.reducer;
