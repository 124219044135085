import ApiService from 'services/apiService';
import {
  CategoryModel,
  CategoryPatchRequestAPIModel,
  CategoryPostRequestAPIModel,
  GetCategoryOptionsModel,
  PatchCategoryOptionsModel,
  PostCategoryOptionsModel
} from 'models/category-model';
import { CATEGORIES_API_URL } from 'constants/api';
import { SlugIdOptionsModel, SlugOptionsModel } from 'models/api-model';
import { OPEN_VIEW_ADMIN_MODIFY_PERMISSIONS } from 'constants/roles';

const getCategories = (options: SlugOptionsModel) =>
  ApiService.get<CategoryModel[]>('/' + options.slug + CATEGORIES_API_URL);

const getCategory = (options: GetCategoryOptionsModel) =>
  ApiService.get<CategoryModel[]>('/' + options.slug + CATEGORIES_API_URL + options.category.id);

const createCategory = (options: PostCategoryOptionsModel) =>
  ApiService.post<CategoryModel, CategoryPostRequestAPIModel>(
    '/' + options.slug + CATEGORIES_API_URL,
    options.category
  );

const editCategory = (options: PatchCategoryOptionsModel) =>
  ApiService.patch<CategoryModel, CategoryPatchRequestAPIModel>(
    '/' + options.slug + CATEGORIES_API_URL + options.category.id + '/',
    options.category
  );

const deleteCategory = (options: SlugIdOptionsModel) =>
  ApiService.remove<never>('/' + options.slug + CATEGORIES_API_URL + options.id + '/');

const CategoriesService = {
  getCategories,
  getCategory,
  createCategory,
  editCategory,
  deleteCategory,
  roles: OPEN_VIEW_ADMIN_MODIFY_PERMISSIONS
};

export default CategoriesService;
