import { TextField } from 'components/Forms';
import { PublishingNameConstraint } from 'constants/namingConstraint';
import { PublishingNameWarning } from 'constants/warnings';
import Popup from 'containers/Popup';
import { FormMode } from 'models/form-model';
import { UserGroupModel } from 'models/user-groups-model';
import React, { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

/**
 * Component for displaying the Topic Table.
 *
 * @param formMode form mode, such as Create, Edit or Delete
 * @param onHide function for hidiing the popup
 * @param displayPopup whether to show the popup
 * @param parentElement element to return focus to after the popup is hidden
 * @param userGroupContext the current user group that is being edited
 * @param onSubmit function that handles submission for creating or editing a user group
 *
 * @component
 */
const UserGroupsPopup = ({
  formMode,
  onHide,
  displayPopup,
  parentElement,
  userGroupContext,
  onSubmit
}: {
  formMode: FormMode;
  onHide(): void;
  displayPopup: boolean;
  parentElement: HTMLElement;
  userGroupContext?: UserGroupModel | null;
  onSubmit(data: FieldValues): void;
}) => {
  useEffect(() => {
    /**
     * Sets the default form values.
     *
     * Needs to be done in an effect and not on useForm hook call because the statuses are not
     * immediately available on component mount. There is a delay while they are pulled.
     */
    const defaultValues: FieldValues = userGroupContext ?? {
      name: ''
    };
    reset(defaultValues);
  }, [userGroupContext]);

  const { control, handleSubmit, reset } = useForm();

  // reset when closing
  useEffect(() => {
    !displayPopup && reset();
  }, [displayPopup]);

  /* - - - - - - - - - - Callbacks - - - - - - - - - - */
  const onPopupSave = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    handleSubmit(onSubmit)();
  };

  const hidePopup = () => {
    reset();
    onHide();
    parentElement?.focus();
  };

  /* Conditional naming for modal header and button based on form mode */
  const titles: Partial<
    Record<FormMode, { header: string; saveButtonText: string; subtitle?: string }>
  > = {
    [FormMode.CREATE]: {
      header: `New User Group`,
      subtitle: 'This user group will be created for all projects in your organization',
      saveButtonText: `Create User Group`
    },
    [FormMode.EDIT]: {
      header: `Edit User Group`,
      subtitle:
        'Modifying this user group will apply changes across all projects in your organization',
      saveButtonText: `Save`
    }
  };
  return (
    <Popup
      title={titles[formMode]?.header ?? ''}
      subtitle={titles[formMode]?.subtitle ?? ''}
      visible={displayPopup}
      onHide={hidePopup}
      onSave={onPopupSave}
      saveButtonText={titles[formMode]?.saveButtonText}
      subtitleIcon="pi pi-info-circle"
    >
      <form className="p-fluid" onSubmit={onPopupSave}>
        <div className="specto-text-medium m-0 -mt-2 mb-5">
          Required fields are marked with <span className="p-error">*</span>
        </div>
        <TextField
          fieldName="name"
          label="Enter Name of the Group"
          control={control}
          rules={{
            pattern: {
              value: PublishingNameConstraint,
              message: PublishingNameWarning
            }
          }}
        />
      </form>
    </Popup>
  );
};

export default UserGroupsPopup;
