import TextField from 'components/Forms/TextField';
import Popup from 'containers/Popup';
import React from 'react';
import { Control } from 'react-hook-form';

type NewProjectPopupProps = {
  control: Control;
  loading: boolean;
  onHide(): void;
  onSave(): void;
  visible: boolean;
};

/**
 * Popup for new project.
 *
 * @param control form control
 * @param loading loading state
 * @param onHide callback for when form is hidden
 * @param onSave callback for when form is saved
 * @param visible form visibility
 * @constructor
 */
const NewProjectPopup = function ({
  control,
  loading,
  onHide,
  onSave,
  visible
}: NewProjectPopupProps) {
  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSave();
  };

  return (
    <Popup
      title="New Project"
      subtitle="Create a new project"
      visible={visible}
      loading={loading}
      saveButtonText="Create Project"
      onHide={() => onHide()}
      onSave={() => onSave()}
    >
      <form className="p-fluid" onSubmit={onFormSubmit}>
        <div className="specto-text-medium -mt-2 mb-5">
          Required fields are marked with <span className="specto-form-asterisk-color">*</span>
        </div>

        <h5 className="mt-0">General</h5>

        <TextField fieldName="company" label="Company" control={control} />
        <TextField fieldName="manager" label="Manager" control={control} />
        <TextField fieldName="name" label="Project Name" control={control} />
      </form>
    </Popup>
  );
};

export default NewProjectPopup;
