import { Status } from 'models/status-model';

export const statusDisplayNames: Record<string, string> = {
  [Status.New]: 'New',
  [Status.Modified]: 'Modified',
  [Status.Approved]: 'Approved',
  [Status.Published]: 'Published',
  [Status.Retired]: 'Retired',
  [Status.Not_Retired]: 'Active'
};
