import React from 'react';
import classnames from 'classnames';
import LoadingSpinner from 'components/LoadingSpinner';

type ModalProps = {
  children: React.ReactNode;
  className?: string;
  noPadding?: boolean;
  style?: 'grid' | 'column';
  align?: 'center' | 'start';
  justify?: 'center' | 'start';
  size?: 'large' | 'medium';
  grow?: boolean;
  loading?: boolean;
  fitParent?: boolean;
};

const modalSizes: Record<string, string> = {
  large: '80vh',
  medium: '70vh'
};

/**
 * Reusable modal container
 * @param children the contents of the component
 * @param size the minimum height of the component
 * @param className custom classnames
 * @param noPadding inner padding of the component
 * @param style whether contents are in a grid or flex column
 * @param align align the flex contents
 * @param justify justify the flex contents
 * @param grow whether the modal grows or not. caps with maxHeight
 * @param loading if the component is in a loading state
 * @param fitParent width of the modal will be the max width it can
 * @constructor
 */
const ModalLarge = ({
  children,
  size = 'large',
  className = '',
  noPadding = false,
  style = 'column',
  align = 'start',
  justify = 'center',
  grow = true,
  loading,
  fitParent = false
}: ModalProps) => (
  <div
    style={{ minHeight: modalSizes[size], ...(!grow && { height: modalSizes[size] }) }}
    className={classnames(
      'specto-modal-large',
      ' card flex m-auto col-12 shadow-3',
      'align-items-' + align,
      'justify-items-' + justify,
      'justify-content-' + justify,
      noPadding ? 'p-0' : 'p-5 md:py-8 md:px-7',
      !fitParent && 'lg:col-8 md:col-9',
      {
        'flex-column': style === 'column',
        grid: style === 'grid'
      },
      className
    )}
  >
    {loading ? <LoadingSpinner position="inline" /> : children}
  </div>
);

export default ModalLarge;
