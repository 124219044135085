import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { setLayout } from 'features/layout/layoutSlice';
import ModalLarge from 'containers/modals/ModalLarge';
import { dirtyValues, mapKeys } from 'util/form';
import { ResponseDatabaseModel } from 'models/project-model';
import useFetch from 'hooks/useFetch';
import ProjectsService from 'services/projectsService';
import { projectsSelector } from 'features/projects/projectsSlice';
import { FetchStatusOptions } from 'constants/fetchStatus';
import { createToast } from 'features/toast/toastSlice';
import { SlugDataOptionsModel, SlugOptionsModel } from 'models/api-model';
import { PasswordField, TextField } from 'components/Forms';
import { DatabaseField } from 'constants/databaseFields';
import LoadingSpinner from 'components/LoadingSpinner';
import { getProjectSettingsLayout } from 'util/layout';
import { authSelector } from 'features/auth/authSlice';

const ResponseDatabaseManager = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(authSelector);
  const { selectedProject } = useAppSelector(projectsSelector);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const {
    data: editResponseDatabaseData,
    fetch: editResponseDatabase,
    fetchStatus: editResponseDatabaseDataStatus
  } = useFetch<SlugDataOptionsModel<Partial<ResponseDatabaseModel>>, ResponseDatabaseModel>(
    ProjectsService.editResponseDatabase,
    ProjectsService.roles.database.update
  );
  const {
    data: responseDatabaseGetData,
    fetch: getResponseDatabaseData,
    fetchStatus: getResponseDatabaseDataStatus
  } = useFetch<SlugOptionsModel, ResponseDatabaseModel>(
    ProjectsService.getResponseDatabase,
    ProjectsService.roles.database.retrieve
  );

  const databaseRelatedKeys = {
    databaseName: 'name',
    databaseHost: 'host',
    databasePort: 'port',
    databaseUser: 'user',
    databasePassword: 'password'
  };

  const responseDatabaseDefaultValues: FieldValues = {
    databaseName: '',
    databaseHost: '',
    databasePort: '',
    databaseUser: '',
    databasePassword: '',
    validConnection: false
  };

  const {
    control: responseDatabaseControl,
    reset: resetResponseDatabase,
    formState: { dirtyFields: responseDatabaseDirtyFields },
    handleSubmit: responseDatabaseHandleSubmit
  } = useForm({ defaultValues: responseDatabaseDefaultValues, mode: 'onSubmit' });

  // onInit
  useEffect(() => {
    dispatch(setLayout(getProjectSettingsLayout(user.is_superuser)));

    getResponseDatabaseData({ slug: selectedProject.slug });
  }, []);

  // get response database details
  useEffect(() => {
    if (getResponseDatabaseDataStatus === FetchStatusOptions.SUCCESS && responseDatabaseGetData) {
      setIsInitialLoad(false);
      resetResponseDatabaseForm(responseDatabaseGetData);
    }
  }, [getResponseDatabaseDataStatus]);

  // edit response database details
  useEffect(() => {
    if (editResponseDatabaseDataStatus === FetchStatusOptions.SUCCESS && editResponseDatabaseData) {
      resetResponseDatabaseForm(editResponseDatabaseData);
      dispatch(createToast('response database details edited'));
    }
  }, [editResponseDatabaseDataStatus]);

  const resetResponseDatabaseForm = (data: ResponseDatabaseModel) => {
    const storeValues = {
      databaseName: data.name || '',
      databaseHost: data.host || '',
      databasePort: data.port || '',
      databaseUser: data.user || '',
      databasePassword: '',
      validConnection: data.valid_connection
    };

    resetResponseDatabase(storeValues);
  };

  const onEditResponseDatabase = (data: FieldValues) => {
    const formValues = dirtyValues(responseDatabaseDirtyFields, data);

    if (Object.keys(formValues).length === 0) {
      return;
    }

    // map keys from front-end keys to back-end keys
    const editResponseDatabaseRequest: Partial<ResponseDatabaseModel> = mapKeys(
      databaseRelatedKeys,
      data
    );

    editResponseDatabase({ slug: selectedProject.slug, data: editResponseDatabaseRequest });
  };

  const editResponseDatabaseFormValidate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    responseDatabaseHandleSubmit(onEditResponseDatabase)();
  };

  return (
    <ModalLarge className="specto-project-settings">
      <h1 className="align-self-center mb-7">Response Database Manager</h1>

      <h5>Database Settings</h5>
      <span className="mb-4">Manage the connection to the Response Publishing Database</span>

      <Controller
        name="validConnection"
        control={responseDatabaseControl}
        render={({ field }) => (
          <div className="flex mb-3">
            <h5 className="mr-3">Valid Connection:</h5>
            {getResponseDatabaseDataStatus === FetchStatusOptions.LOADING || isInitialLoad ? (
              <LoadingSpinner small position="inline" />
            ) : field.value ? (
              <span className="text-green-300 text-xl font-bold">True</span>
            ) : (
              <span className="p-error text-xl font-bold">False</span>
            )}
          </div>
        )}
      />

      <form
        className="p-fluid mt-3 flex flex-wrap justify-content-between"
        onSubmit={editResponseDatabaseFormValidate}
      >
        <TextField
          fieldName={DatabaseField.DATABASE_NAME}
          label="Database Name"
          className="lg:w-6 lg:pr-3 w-full"
          control={responseDatabaseControl}
          required
        />

        <TextField
          fieldName={DatabaseField.DATABASE_HOST}
          label="Database Host"
          className="lg:w-6 lg:pl-3 w-full"
          control={responseDatabaseControl}
          required
        />

        <TextField
          fieldName={DatabaseField.DATABASE_USER}
          label="Database User"
          className="lg:w-6 lg:pr-3 w-full"
          control={responseDatabaseControl}
          required
        />

        <TextField
          fieldName={DatabaseField.DATABASE_PORT}
          label="Database Port"
          className="lg:w-6 lg:pl-3 w-full"
          keyFilter="pint"
          control={responseDatabaseControl}
          required
        />

        <PasswordField
          label="Database Password"
          className="lg:w-6 lg:pr-3 w-full"
          fieldName={DatabaseField.DATABASE_PASSWORD}
          control={responseDatabaseControl}
          rules={{ required: 'password required to update configuration' }}
          required
        />

        <div className="w-full"></div>

        <div className="field mt-3 mb-5 lg:w-5 w-full align-content-center">
          <Button
            loading={editResponseDatabaseDataStatus === FetchStatusOptions.LOADING}
            disabled={getResponseDatabaseDataStatus === FetchStatusOptions.LOADING}
            type="submit"
            label="Update Details"
            className="m-auto py-2 px-3 font-bold w-full"
          />
        </div>
      </form>
    </ModalLarge>
  );
};

export default ResponseDatabaseManager;
