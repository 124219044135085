import React from 'react';
import { Control, Controller } from 'react-hook-form';
import classNames from 'classnames';
import classnames from 'classnames';
import { getFormErrorMessage, oneSpaceFormatter } from 'util/form';
import { KeyFilterType } from 'primereact/keyfilter';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { UseControllerProps } from 'react-hook-form/dist/types/controller';
import Info from './Info';
import { uniqueId } from 'util/uniqueIdGenerator';
import { Autocomplete, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

/**
 * Component that displays a basic text field in a form.
 *
 * @param characterLimit Character limit for the input.
 * @param className Custom class name.
 * @param control react-hook-form control.
 * @param defaultValue Default value for dropdown.
 * @param disabled Whether the input is disabled or not.
 * @param editable If the dropdown selection is editable.
 * @param fieldName The field name in react-hook-form.
 * @param formState react-hook-form form state.
 * @param itemTemplate dropdown item template.
 * @param label Label for the input.
 * @param labelClassName Class name for custom label styling.
 * @param noPadding errorBoundary adds 20px margin when no error occurs so that when an error happens the UI doesn't move.
 * @param onChange Optional onChange function.
 * @param optionLabel What property of the options contains the label.
 * @param optionValue What property of the options contains the value.
 * @param options Dropdown options.
 * @param placeholder Dropdown placeholder.
 * @param readOnly For readonly input.
 * @param required If the dropdown is required.
 */
const AutocompleteField = function ({
  fieldName,
  label,
  characterLimit = 200,
  control,
  defaultValue = '',
  className,
  labelClassName,
  disabled,
  placeholder,
  required = true,
  readOnly,
  noPadding,
  editable,
  optionLabel,
  optionValue,
  options,
  onChange,
  info,
  rules
}: {
  fieldName: string;
  disabled?: boolean;
  label?: string;
  characterLimit?: number;
  defaultValue?: any;
  className?: string;
  labelClassName?: string;
  control: Control;
  required?: boolean;
  keyFilter?: KeyFilterType;
  placeholder?: string;
  readOnly?: boolean;
  editable?: boolean;
  optionLabel?: string | ((option: any) => string);
  optionValue?: string;
  noPadding?: boolean;
  options: SelectItemOptionsType;
  onChange?(e: any): void;
  info?: string;
  rules?: UseControllerProps['rules'];
}) {
  const selectId = uniqueId('select');
  return (
    <div className={classnames(className, { 'field pb-3': !noPadding })}>
      <div className="flex">
        {label && (
          <label htmlFor={selectId} className={classnames(labelClassName)}>
            {label} {required && <span className="p-error">*</span>}
          </label>
        )}
        {info && <Info text={info} />}
      </div>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        rules={{
          ...(required && { required: 'Required field' }),
          maxLength: {
            value: characterLimit,
            message: `Exceeded max length of ${characterLimit} characters`
          },
          ...rules
        }}
        render={({ field, fieldState }) => (
          <>
            <Autocomplete
              {...field}
              className="w-full material-field"
              value={
                // The autocomplete wants the full option object but the controller only stores the value
                // so we need to find the option object that matches the value
                options.find(
                  (option) => (optionValue ? option[optionValue] : option) === field.value
                ) ?? field.value
              }
              id={selectId}
              options={options}
              onChange={(_e, value) => {
                // The autocomplete returns the full option object unless the user types in a new value
                const val = optionValue ? value[optionValue] ?? value : value;
                field.onChange(oneSpaceFormatter(val));
                onChange?.(val);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classNames('specto-input-select autocomplete-input', {
                    'p-invalid': fieldState.error
                  })}
                  placeholder={placeholder}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} className={classNames(props.className, 'material-option')}>
                  {optionLabel
                    ? (typeof optionLabel === 'string'
                        ? option[optionLabel]
                        : optionLabel(option)) ?? option
                    : option}
                </li>
              )}
              getOptionLabel={(option) =>
                option
                  ? optionLabel
                    ? typeof optionLabel === 'string'
                      ? option[optionLabel] ?? option
                      : optionLabel(option) ?? option
                    : option
                  : ''
              }
              isOptionEqualToValue={(option, value) => {
                const o = optionValue ? option[optionValue] : option;
                const v = optionValue ? value[optionValue] : value;
                return JSON.stringify(o) === JSON.stringify(v);
              }}
              disableClearable
              freeSolo={editable}
              autoSelect
              readOnly={readOnly}
              disabled={disabled}
              popupIcon={<ExpandMoreIcon />}
            />
            {getFormErrorMessage(fieldState.error, noPadding)}
          </>
        )}
      />
    </div>
  );
};

export default AutocompleteField;
