import React from 'react';
import { Control } from 'react-hook-form';
import Popup from 'containers/Popup';
import { UserModel } from 'models/user-model';
import { useAppSelector } from 'hooks/store';
import { authSelector } from 'features/auth/authSlice';
import UserProfileForm from './UserProfileForm';

type UserProfilePopupProps = {
  selectedUser: UserModel | null;
  visible: boolean;
  loading: boolean;
  control: Control;
  onDelete?(e: React.MouseEvent): void;
  onDeactivate?(e: React.MouseEvent): void;
  onHide(): void;
  onSave(): void;
};

const UserProfilePopup = function ({
  selectedUser,
  visible,
  loading,
  onHide,
  onDelete,
  onDeactivate,
  control,
  onSave
}: UserProfilePopupProps) {
  const { user } = useAppSelector(authSelector);

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSave();
  };

  return (
    <Popup
      title={selectedUser ? `${selectedUser.first_name} ${selectedUser.last_name}` : 'Form'}
      subtitle="Edit User Settings"
      visible={visible}
      loading={loading}
      onHide={() => onHide()}
      onSave={onSave}
    >
      <UserProfileForm
        user={user}
        selectedUser={selectedUser}
        control={control}
        onDelete={onDelete}
        onDeactivate={onDeactivate}
        onFormSubmit={onFormSubmit}
        disableSubmitOnEnter
      />
    </Popup>
  );
};

export default UserProfilePopup;
