import React from 'react';
import classnames from 'classnames';

type ModalProps = {
  children: React.ReactNode;
  className?: string;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
};

const ModalFormFullScreen = ({ children, className, onSubmit }: ModalProps) => {
  return (
    <form onSubmit={onSubmit}>
      <div
        className={classnames(
          'specto-modal-fullscreen flex flex-column align-items-center w-screen h-screen',
          className
        )}
      >
        <div className="xl:col-5 lg:col-7 md:col-8 col-12 mt-auto">
          <img
            className="specto-logo"
            src="/assets/layout/images/askma-logo-light.svg"
            alt="company logo"
          />
        </div>

        <div className="flex justify-content-center w-full m-auto mt-0 h-max">
          <div className="specto-modal-fullscreen pt-0 xl:col-5 lg:col-7 md:col-8 col-12">
            <div className="specto-modal-fullscreen__card flex flex-column justify-content-center align-items-start">
              {children}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ModalFormFullScreen;
