import React from 'react';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';

type CheckBoxCellCellProps = {
  checked: boolean;
  onChange(e: CheckboxChangeEvent): void;
  viewOnly?: boolean;
};

const CheckBoxCell = ({ checked, onChange, viewOnly = false }: CheckBoxCellCellProps) => {
  return <Checkbox checked={checked} onChange={onChange} readOnly={viewOnly} />;
};

export default CheckBoxCell;
