import React, { useEffect } from 'react';
import { useAppDispatch } from 'hooks/store';
import { Navigate, Outlet } from 'react-router-dom';
import { GuardType } from 'models/guard-model';
import { createToast } from 'features/toast/toastSlice';

type GuardedRouteProps = {
  redirectPath: string;
  children?: React.ReactNode;
  guards?: GuardType[];
};

/**
 * Can be guard multiple routes as an outlet
 * <Route element={<AuthGuardedRoute/>}> {... your routes } </Route>
 *
 * Can be used for single routes
 * <Route element={<AuthGuardedRoute><YourComponent></AuthGuardedRoute>}/>
 *
 * @param guards array of guards that are conditions to access this route
 * @param redirectPath path to redirect to
 * @param children child component when guarding a single route
 */
export const GuardedRoute = ({
  guards,
  redirectPath,
  children
}: GuardedRouteProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const failedGuard: GuardType | undefined = guards?.find((guard) => !guard.canActivate);

  // create toast if guard message exists
  useEffect(() => {
    failedGuard?.message && dispatch(createToast(failedGuard.message, 'warn'));
  }, [failedGuard]);

  return failedGuard ? <Navigate to={redirectPath} replace /> : <>{children || <Outlet />}</>;
};
