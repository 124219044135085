import React from 'react';
import { Button } from 'primereact/button';

/**
 * Text cell with a clickable action
 * @param text text to display
 * @param action action to perform when clicked
 */
const ClickableTextCell = ({ text, action }: { text: string; action(): void }) => (
  <Button
    link
    text
    className="clickable-text-cell"
    onClick={action}
    tooltip={text}
    tooltipOptions={{ position: 'bottom', showDelay: 500 }}
    label={text}
  />
);

export default ClickableTextCell;
