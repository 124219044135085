import React from 'react';
import {
  MultiStateCheckbox,
  MultiStateCheckboxChangeEvent,
  MultiStateCheckboxProps
} from 'primereact/multistatecheckbox';

type CheckBoxCellCellProps = {
  value?: string | null | boolean;
  onChange(e: MultiStateCheckboxChangeEvent): void;
  options: MultiStateCheckboxProps['options'];
  optionValue: string;
  tooltip?: string;
  viewOnly?: boolean;
};

const MultiCheckboxCell = ({
  value,
  onChange,
  options,
  optionValue,
  tooltip,
  viewOnly = false
}: CheckBoxCellCellProps) => {
  return (
    <MultiStateCheckbox
      value={value}
      className="specto-checkbox"
      options={options}
      onChange={onChange}
      readOnly={viewOnly}
      optionValue={optionValue}
      tooltipOptions={{ position: 'bottom' }}
      tooltip={tooltip}
    />
  );
};

export default MultiCheckboxCell;
