import React from 'react';
import Avatar from 'components/Avatar';
import { ProjectMemberModel, ProjectMemberUserModel, ProjectRole } from 'models/project-model';
import { UserModel } from 'models/user-model';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { UserGroupModel } from 'models/user-groups-model';
import { ChipsCell } from 'components/Table';
import { Tooltip } from 'primereact/tooltip';
import { isAdmin } from 'util/permissions';
import { FormMode } from 'models/form-model';
import { MenuButtonCell } from 'components/Table/MenuButtonCell';
import ParagraphCell from 'components/Table/ParagraphCell';

/**
 * @param filteredMembersData List of Members filtered by the search bar
 * @param systemUsersRecord List of system users
 * @param ownUser User who is currently logged in
 * @param memberRolesOptions List of different member roles
 * @param onClickDeleteUser Handler for deleting a project member
 * @param handlePopupDisplayChange handles hiding Edit Members Popup
 * @param isMemberRowLoading Checks whether current member row in table is loading or now
 * @param userGroups list of all user groups
 * @component
 */
const MembersDataTable = ({
  filteredMembersData,
  systemUsersRecord,
  ownUser,
  memberRolesOptions,
  onClickDeleteUser,
  handlePopupDisplayChange,
  isMemberRowLoading,
  userGroups
}: {
  filteredMembersData: ProjectMemberModel[];
  systemUsersRecord: Record<string, UserModel>;
  ownUser: UserModel;
  memberRolesOptions(isAdmin: boolean): {
    label: string;
    value: ProjectRole;
    hidden: boolean;
  }[];
  onClickDeleteUser(projectUser: ProjectMemberModel): void;
  handlePopupDisplayChange(
    visibility: boolean,
    popupMode: FormMode,
    context?: ProjectMemberModel | null
  ): void;
  isMemberRowLoading(key: any): boolean;
  userGroups: UserGroupModel[];
}) => {
  const chipCharLimit = 15;
  return (
    <DataTable
      className="specto-data-table mt-5"
      value={filteredMembersData.filter((m) => m.role !== ProjectRole.NEUROSOPH)}
      paginator
      rows={5}
    >
      <Column
        field="project-member"
        className="specto-table__column--lg"
        header="Project Member"
        body={(node: ProjectMemberUserModel) => (
          <div className="flex align-items-center justify-content-start w-full">
            <Avatar
              fullName={{
                first: systemUsersRecord[node?.user || '']?.first_name,
                last: systemUsersRecord[node?.user || '']?.last_name
              }}
              className="mr-2"
            />
            <div className="w-full overflow-hidden">
              <div className="font-semibold">
                {`${systemUsersRecord[node?.user || '']?.first_name} ${systemUsersRecord[
                  node?.user || ''
                ]?.last_name}`}
              </div>
              <div
                id={`email-${node.id.toString()}`}
                className="w-full specto-text-muted white-space-nowrap overflow-hidden text-overflow-ellipsis"
              >
                {node?.email}
              </div>
              <Tooltip
                className="max-w-20rem"
                position="bottom"
                target={`#email-${node.id.toString()}`}
              >
                {node?.email}
              </Tooltip>
            </div>
          </div>
        )}
      />
      <Column
        field="user-role"
        className="specto-table__column--lg"
        header="User Role"
        body={(node: ProjectMemberModel) => (
          <ParagraphCell
            text={
              memberRolesOptions(node.role === ProjectRole.NEUROSOPH).find(
                (role) => role.value === node.role
              )?.label || ''
            }
          />
        )}
      />
      <Column
        field="user-groups"
        className="specto-table__column--md"
        header="User Groups"
        body={(node: ProjectMemberModel) => {
          const found = userGroups.filter((ug) => node.user_groups.includes(ug.id));

          if (isAdmin(node.role) || found.length === userGroups.length)
            return <div>All User Groups</div>;
          else if (found.length === 0) return <div>No User Groups</div>;
          else
            return ChipsCell({
              nodeId: 'usergroup-' + node.id,
              chips: found.map((ug) => ug.name),
              charLimit: chipCharLimit
            });
        }}
      />
      <Column
        field="actions"
        className="specto-table__column--xs"
        header="Actions"
        alignHeader="center"
        body={(node: ProjectMemberModel) => (
          <div className="flex justify-content-center align-content-center">
            <MenuButtonCell
              data-cy={`${node.id}-actions`}
              loading={isMemberRowLoading(node.id)}
              disabled={node?.user === ownUser?.id}
              tooltip="You cannot change your own User Role."
              tooltipOptions={{ showOnDisabled: true, disabled: !(ownUser.id === node?.user) }}
              menu={[
                {
                  label: 'Edit',
                  command: () => handlePopupDisplayChange(true, FormMode.EDIT, node)
                },
                {
                  label: 'Delete',
                  command: () => onClickDeleteUser(node)
                }
              ]}
            />
          </div>
        )}
      />
    </DataTable>
  );
};

export default MembersDataTable;
