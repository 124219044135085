import React, { useRef } from 'react';
import { TagModel } from 'models/tag-model';
import { Tags } from 'components/Tags';
import { useAppSelector } from 'hooks/store';
import { tagsSelector } from 'features/projects/projectsSlice';
import { OverlayPanel } from 'primereact/overlaypanel';
import PopupSmall from 'containers/Popup/PopupSmall';
import { TagTemplate } from 'components/Forms';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import ChipTagTemplate from 'components/Forms/ChipTagTemplate';

type TableCellProps = {
  tags: TagModel[];
  onTagChange?(tags: TagModel[]): void;
  loading?: boolean;
  editable?: boolean;
};

/**
 * Component that displays the formatted tags for a response.
 * @component
 * @param node The row data
 */
const TagsCell = ({ tags, onTagChange, loading, editable = true }: TableCellProps) => {
  const allTags = useAppSelector(tagsSelector);
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const empty = tags.length === 0;

  // stop propagation because all non-button clicks autoFocus the leftmost checkbox
  const toggleTagEditPanel = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onTagChange && overlayPanelRef?.current?.toggle(e);
  };

  // when showing an empty component in related records
  if (empty && !editable) {
    return <></>;
  }

  return (
    <div className="specto-tag-cell">
      {empty || onTagChange ? (
        <Button
          icon="pi pi-plus"
          aria-label="Add tags"
          className="p-button-rounded p-button-text specto-r-button-lg"
          onClick={toggleTagEditPanel}
          tooltip="Add Tags"
          data-cy="add-tags-button"
        />
      ) : (
        <Tags tags={tags} onClick={toggleTagEditPanel} className="mx-auto" />
      )}

      {onTagChange && (
        <PopupSmall popupRef={overlayPanelRef}>
          <div className="flex flex-wrap gap-3">
            <div className="w-12 specto-border border-round border-1 p-3">
              <h6>Tags</h6>
              <MultiSelect
                multiple
                filter
                className="specto-input-select w-full"
                placeholder="Select Tags"
                filterPlaceholder="Start typing to search..."
                optionLabel="name"
                filterBy="name"
                options={allTags}
                value={tags}
                onChange={(e) => e.value && onTagChange(e.value)}
                itemTemplate={TagTemplate}
                selectedItemTemplate={ChipTagTemplate}
                aria-label="Tags"
                disabled={loading}
                showSelectAll={false}
              />
            </div>
          </div>
        </PopupSmall>
      )}
    </div>
  );
};

export default TagsCell;
