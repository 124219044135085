import React, { useState } from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { basicInputMaxLength } from 'constants/inputs';

/**
 * Dialog used for confirming deletion
 * User must type the correct relevant name to confirm the deletion
 * The accept button is hidden until the user correctly enters the  name
 *
 * @param confirmName: name of the item to deleted
 * @param visible: visibility of the dialog
 * @param setVisible: state function to set the visibility of the dialog
 * @param accept: accept callback function
 * @param reject: reject callback function
 * @param prompt: the prompt text
 * @component
 */
export const NamedDeletionDialog = ({
  confirmName,
  visible,
  accept,
  reject,
  onHide,
  prompt
}: {
  confirmName: string;
  prompt: string;
  visible: boolean;
  accept(): void;
  onHide(): void;
  reject?(): void;
}) => {
  const [confirmDeleteName, setConfirmDeleteName] = useState('');

  // Uses ConfirmDialog element rather than confirmDialog function
  // As inputtext and state does not work well in the function
  return (
    <ConfirmDialog
      visible={visible}
      onHide={() => {
        onHide();
        setConfirmDeleteName('');
      }}
      message={
        <div>
          <label htmlFor="specto-delete-confirmation-dialogue" className="mb-2">
            {prompt}
          </label>
          <InputText
            id="specto-delete-confirmation-dialogue"
            className="specto-input-text w-full mt-2"
            value={confirmDeleteName}
            onChange={(e) => setConfirmDeleteName(e.target.value)}
            placeholder={confirmName}
            maxLength={basicInputMaxLength}
            autoComplete="off"
            aria-label="delete--confirmation"
          />
        </div>
      }
      header="Delete  ?"
      icon="pi pi-exclamation-triangle"
      acceptClassName={classNames('p-button-danger', confirmName !== confirmDeleteName && 'hidden')}
      accept={accept}
      reject={reject}
    />
  );
};
