import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import FeedbackPopup from './FeedbackPopup';
import FeedbackSubmitPopup from './FeedbackSubmitPopup';
import { useAppSelector } from 'hooks/store';
import { authSelector } from 'features/auth/authSlice';
import feedbackService from 'services/feedbackService';
import useFetch from 'hooks/useFetch';
import { FieldValues } from 'react-hook-form';
import { FeedbackModel } from 'models/feedback-model';
import { FetchStatusOptions } from 'constants/fetchStatus';

const Feedback = function () {
  const user = useAppSelector(authSelector);
  const [displayPopup, setDisplayPopup] = useState(false);
  const [displaySubmitPopup, setDisplaySubmitPopup] = useState(false);

  const { fetch: sendFeedback, fetchStatus: sendFeedbackStatus } = useFetch(
    feedbackService.sendFeedback,
    feedbackService.roles.create
  );

  useEffect(() => {
    if (sendFeedbackStatus === FetchStatusOptions.SUCCESS) {
      setDisplayPopup(false);
      setDisplaySubmitPopup(true);
    }
  }, [sendFeedbackStatus]);

  const onSubmit = (data: FieldValues) => {
    const feedback = {
      allow_contact: data.allow_contact,
      rating: data.rating,
      details: data.details
    } as FeedbackModel;

    sendFeedback(feedback);
  };

  return (
    <div>
      <FeedbackPopup
        visible={displayPopup}
        onHide={() => setDisplayPopup(false)}
        onSubmit={onSubmit}
        parentElement={document.activeElement as HTMLElement}
        user={user}
        loading={sendFeedbackStatus === FetchStatusOptions.LOADING}
      />
      <FeedbackSubmitPopup
        visible={displaySubmitPopup}
        onHide={() => setDisplaySubmitPopup(false)}
        parentElement={document.activeElement as HTMLElement}
      />
      <Button
        className="feedback-button capitalize font-normal"
        onClick={() => setDisplayPopup(true)}
        label="Send Feedback"
        icon="pi pi-comment"
      />
    </div>
  );
};

export default Feedback;
