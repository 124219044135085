import jwtDecode, { JwtPayload } from 'jwt-decode';
import { AuthModel } from 'models/auth-model';
import { RefreshTokenModel, RefreshTokenRequestAPIModel } from 'models/token-model';
import { LOCAL_STORAGE_KEY } from 'constants/token';
import { refresh_API_URL } from 'constants/api';
import ApiService from 'services/apiService';

const getLocalRefreshToken = () => {
  const auth: AuthModel = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
  return auth?.refresh || null;
};

/**
 * Refreshes access token using Refresh Token
 */
const refreshToken = async (): Promise<RefreshTokenModel | null> => {
  const refresh: string | null = getLocalRefreshToken();

  if (!refresh) {
    return Promise.reject(Error('REFRESH TOKEN NULL'));
  }

  return ApiService.post<RefreshTokenModel, RefreshTokenRequestAPIModel>(refresh_API_URL, {
    refresh
  })
    .then((refreshToken: RefreshTokenModel) => {
      if (refreshToken.access) {
        // Set token bearer in Axios instance with new access token
        ApiService.setHeaderTokenBearer(refreshToken.access);

        // Update local access token
        updateLocalAccessToken(refreshToken.access);
      }

      return refreshToken;
    })
    .catch((error) => {
      // Set silent error to true so that we don't show a toast
      error._silent = true;
      return Promise.reject(error);
    });
};

const getLocalAccessToken = () => {
  const auth: AuthModel = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
  return auth?.access;
};

const updateLocalAccessToken = (accessToken: string) => {
  const auth: AuthModel = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
  if (auth?.access) {
    auth.access = accessToken;
    setAuth(auth);
  }
};

const getAuth = (): AuthModel => {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');
};

const setAuth = (user: AuthModel) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(user));
};

const removeAuth = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
};

const clear = () => {
  localStorage.clear();
};

const decodeJwt = (token: string): JwtPayload => {
  return jwtDecode<JwtPayload>(token);
};

const expired = (token: string): boolean => {
  const expiry = decodeJwt(token)?.exp;

  return !!(expiry && expiry < Date.now() / 1000);
};

const TokenService = {
  clear,
  expired,
  decodeJwt,
  getLocalRefreshToken,
  refreshToken,
  getLocalAccessToken,
  updateLocalAccessToken,
  getAuth,
  setAuth,
  removeAuth
};
export default TokenService;
