import { TagModel } from 'models/tag-model';
import { SlugIdOptionsModel, SlugOptionsModel } from 'models/api-model';
import { SentimentModel } from 'models/sentiment-model';
import { EntityTableDataModel } from './entity-model';

export type MessageInboxTableDataModel = {
  id: number;
  sender?: string;
  conversation?: number;
  text: string;
  intent: number;
  entity_labels: MessageInboxEntityLabelModel[];
  bot_intents: BotIntent[];
  bot_sentiments: BotSentiment[];
  sentiment: SentimentModel['id'];
  reviewed: boolean;
  metadata: MessageInboxMetadataModel;
  comment_thread_resolved: boolean | null;
  labelled_by: MessageInboxLabelledByModel;
  tags: TagModel['id'][];
  related_records: MessageInboxRelatedRecord[];
};

export type MessageInboxRelatedRecord = {
  user_fullname: string | null;
  text: string;
  intent: number;
  sentiment: MessageInboxTableDataModel['sentiment'];
  entity_labels: MessageInboxEntityLabelModel[];
  datetime: string;
};

export type BotIntent = {
  intent: number;
  is_predicted: boolean;
  confidence: number | null;
};

export type BotSentiment = {
  sentiment: MessageInboxTableDataModel['sentiment'];
  is_predicted: boolean;
  confidence: number | null;
};

export interface Label {
  id?: number;
  name: string;
  is_predicted: boolean;
  confidence: number | null;
}

/**
 * NA: no annotation
 * SN: single annotation
 * ML: multi annotation
 * CF: conflict
 * AC: accepted (admin annotation)
 */
export enum MessageInboxLabelledByModel {
  NONE = 'NA',
  SINGLE = 'SN',
  MULTIPLE = 'ML',
  CONFLICT = 'CF',
  ACCEPTED = 'AC'
}

export type MessageInboxPatchRequestAPIModel = {
  text?: string;
  intent?: number;
  sentiment?: MessageInboxTableDataModel['sentiment'];
  reviewed?: boolean;
  notes?: string;
};

export type MessageInboxEntityLabelPutRequestAPIModel = {
  entity: EntityTableDataModel['id'];
  start: number;
  end: number;
}[];

export type MessageInboxEntityLabelModel = {
  entity: EntityTableDataModel['id'];
  role: string;
  group: number;
  start: number;
  end: number;
  id: number;
  confidence: number;
  value: string;
  extractor: string;
};

export type MessageInboxPutRequestAPIModel = {
  type_name?: string;
  action_name?: string;
  intent?: number;
  confidence?: number;
  text?: string;
};

export type MessageInboxTagsPutRequestAPIModel = MessageInboxTableDataModel['tags'];

export interface GetMessageInboxOptionsModel extends SlugOptionsModel {
  query: string;
}

export interface GetMessageOptionsModel extends SlugOptionsModel {
  id: number;
}

export interface EditMessageInboxOptionsModel extends SlugIdOptionsModel {
  updatedMessageInboxData: MessageInboxPatchRequestAPIModel;
}

export interface ModifyMessageInboxOptionsModel extends SlugIdOptionsModel {
  updatedMessageInboxData: MessageInboxPutRequestAPIModel;
}

export interface EditMessageInboxTagsOptionsModel extends SlugIdOptionsModel {
  tags: MessageInboxTableDataModel['tags'];
}

export interface EditMessageInboxEntitiesOptionsModel extends SlugIdOptionsModel {
  updatedEntities: MessageInboxEntityLabelPutRequestAPIModel;
}

export type BatchMessagesModel = {
  name: string;
  color: string;
  start: string;
  end: string;
  min_group_size: number;
  max_batch_size: number;
};

export interface PostBatchMessagesOptionsModel {
  slug: string;
  params: BatchMessagesModel;
}

export type MessageInboxMetadataModel = Record<string, any>;
