import { SpectoLayout } from 'models/layout-model';

export const MAIN_LAYOUT = SpectoLayout.main;
export const MAIN_NO_DASHBOARD_LAYOUT = SpectoLayout.mainNoDashboard;
export const ADMIN_MAIN_LAYOUT = SpectoLayout.adminMain;
export const ADMIN_MAIN_NO_DASHBOARD_LAYOUT = SpectoLayout.adminMainNoDashboard;
export const SUPERUSER_LAYOUT = SpectoLayout.superuser;
export const SYSTEM_SETTINGS_LAYOUT = SpectoLayout.systemSettings;
export const ADMIN_PROJECT_SETTINGS_LAYOUT = SpectoLayout.adminProjectSettings;
export const SUPERUSER_PROJECT_SETTINGS_LAYOUT = SpectoLayout.superUserProjectSettings;
export const USER_SETTINGS_LAYOUT = SpectoLayout.userSettings;
export const FULLSCREEN_LAYOUT = SpectoLayout.fullscreen;
export const NO_LAYOUT = SpectoLayout.none;
