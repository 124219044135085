import React, { useState } from 'react';
import classnames from 'classnames';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Tooltip } from 'primereact/tooltip';
import { uniqueId } from 'util/uniqueIdGenerator';
import { TooltipOptions } from 'primereact/tooltip/tooltipoptions';

type ToggleButtonType = string | boolean | number;

/**
 * Divided button toggle component
 * @param ariaDescribedBy description of toggle
 * @param ariaLabel aria label
 * @param ariaLabelledBy id of label of the toggle
 * @param className class name
 * @param disabled whether the button is disabled
 * @param falseLabel label on the false button
 * @param falseValue false button value
 * @param onChange callback on value change
 * @param trueLabel label on the true button
 * @param trueValue true button value
 * @param value value of toggle
 * @constructor
 */
const ToggleDividedButton = function ({
  ariaLabel,
  ariaLabelledBy,
  ariaDescribedBy,
  checked,
  className,
  disabled = false,
  falseLabel = 'off',
  falseValue = false,
  onChange,
  tooltip,
  tooltipOptions,
  trueLabel = 'on',
  trueValue = true
}: {
  ariaLabel?: string;
  ariaLabelledBy?: string;
  ariaDescribedBy?: string;
  checked?: ToggleButtonType;
  className?: string;
  disabled?: boolean;
  falseLabel?: string;
  falseValue?: ToggleButtonType;
  onChange?(e: { value: ToggleButtonType }): void;
  tooltip?: string;
  tooltipOptions?: TooltipOptions;
  trueLabel?: string;
  trueValue?: ToggleButtonType;
}) {
  const [id] = useState(uniqueId());
  const isChecked = checked === trueValue;

  return (
    <div
      role="group"
      className={classnames('flex w-max', className)}
      id={'specto-toggle-button-' + id}
      aria-labelledby={ariaLabelledBy}
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedBy}
    >
      <Button
        text
        label={trueLabel}
        type="button"
        aria-disabled={disabled}
        aria-pressed={isChecked}
        aria-label={ariaLabel ? `${ariaLabel} ${trueLabel.toLowerCase()}` : undefined}
        style={{ whiteSpace: 'nowrap' }}
        className={
          isChecked ? 'specto-text-dark opacity-100' : disabled ? 'specto-text-muted' : 'font-light'
        }
        onClick={(e) => !disabled && !isChecked && onChange?.({ ...e, value: trueValue })}
      />
      <Divider layout="vertical" className="my-auto mx-1 h-1rem min-h-0 border-black-alpha-90" />
      <Button
        text
        aria-disabled={disabled}
        aria-pressed={!isChecked}
        label={falseLabel}
        type="button"
        style={{ whiteSpace: 'nowrap' }}
        aria-label={ariaLabel ? `${ariaLabel} ${falseLabel.toLowerCase()}` : undefined}
        className={
          !isChecked
            ? 'specto-text-dark opacity-100'
            : disabled
              ? 'specto-text-muted'
              : 'font-light'
        }
        onClick={(e) => !disabled && isChecked && onChange?.({ ...e, value: falseValue })}
      />
      {tooltip && (
        <Tooltip target={'#specto-toggle-button-' + id} position="bottom" {...tooltipOptions}>
          {tooltip}
        </Tooltip>
      )}
    </div>
  );
};

export default ToggleDividedButton;
