import React from 'react';
import { Button } from 'primereact/button';
import { ConversationModel } from 'models/conversation-model';
import { TagModel } from 'models/tag-model';
import { Tags } from 'components/Tags';
import { humanReadableDateTimeFormatted } from 'util/dates';
import { mapTags } from 'util/tags';

type ConversationItemProps = {
  conversation: ConversationModel;
  onClick: any;
  onDelete?: any;
  canDelete?: boolean;
  loading?: boolean;
  disabled?: boolean;
  selected?: boolean;
  allTags: TagModel[];
};

const ConversationListItem = ({
  conversation,
  onClick,
  onDelete,
  canDelete = true,
  selected = false,
  disabled,
  loading,
  allTags
}: ConversationItemProps) => (
  <div
    className={
      'specto-conversation-item w-full mt-1 mb-3 cursor-pointer border-round invisible-button-toggle ' +
      (selected ? 'specto-bg-light' : 'specto-bg-hover-muted')
    }
    tabIndex={1}
    onClick={onClick}
  >
    <div className="w-full flex flex-nowrap justify-content-between p-3">
      <div className="w-10 flex flex-grow-1 flex-nowrap">
        <div className="pr-2">
          <i className="pi pi-user border-circle specto-r-button-lg specto-bg-muted" />
        </div>
        <div className="w-9 flex-grow-1 mr-3">
          <div className="font-bold text-overflow-ellipsis white-space-nowrap overflow-hidden mb-1">
            {humanReadableDateTimeFormatted(conversation.start)}
          </div>
          <div className="specto-text-muted text-overflow-ellipsis white-space-nowrap overflow-hidden">
            {conversation.sender}
          </div>

          <Tags tags={mapTags(allTags, conversation.tags)} className="mt-2" />
        </div>
      </div>

      {canDelete && (
        <Button
          icon="pi pi-trash"
          aria-label="Delete conversation message"
          className="p-button-rounded specto-r-button-lg invisible-button"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          loading={loading}
          disabled={disabled}
        />
      )}
    </div>
    <hr className="w-11 mt-2 ml-2 mb-0" />
  </div>
);

export default ConversationListItem;
