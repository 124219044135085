import React from 'react';
import { MessageInboxLabelledByModel } from 'models/message-inbox-table-data-model';
import { Tooltip } from 'primereact/tooltip';

type LabelledByCellProps = {
  labelledBy: MessageInboxLabelledByModel;
  id: number | string;
};

const labelledByIcon: Record<
  MessageInboxLabelledByModel,
  { icon: JSX.Element | null; tooltip: string | null }
> = {
  [MessageInboxLabelledByModel.ACCEPTED]: {
    icon: <i className="pi pi-check-circle text-2xl text-green-300" />,
    tooltip: 'NeuroSoph reviewed and accepted'
  },
  [MessageInboxLabelledByModel.SINGLE]: {
    icon: <i className="pi pi-user text-xl" />,
    tooltip: 'Annotated by a single annotator'
  },
  [MessageInboxLabelledByModel.MULTIPLE]: {
    icon: <i className="pi pi-users text-2xl" />,
    tooltip: 'Annotated by multiple annotators'
  },
  [MessageInboxLabelledByModel.CONFLICT]: {
    icon: <i className="pi pi-exclamation-circle text-3xl p-error" />,
    tooltip: 'Annotation conflict'
  },
  [MessageInboxLabelledByModel.NONE]: {
    icon: null,
    tooltip: null
  }
};

const LabelledByCell = ({ labelledBy, id }: LabelledByCellProps) => (
  <div className={'specto-labelled-by-cell-' + id}>
    {labelledByIcon[labelledBy].icon}
    <Tooltip target={'.specto-labelled-by-cell-' + id} position="bottom">
      {labelledByIcon[labelledBy].tooltip}
    </Tooltip>
  </div>
);

export default LabelledByCell;
