import ApiService from './apiService';
import { PROJECTS_API_URL, VERSIONS_API_URL } from 'constants/api';
import {
  EditVersionOptionsModel,
  PostVersionOptionsModel,
  VersionHistory,
  VersionPostAPIModel,
  VersionsPatchAPIModel,
  VersionSummary
} from 'models/version-model';
import { SlugOptionsModel } from 'models/api-model';
import { OPEN_VIEW_ADMIN_MODIFY_PERMISSIONS } from 'constants/roles';

/**
 * Get a list of versions
 */
const getVersions = (options: SlugOptionsModel) =>
  ApiService.get<VersionHistory[]>(PROJECTS_API_URL + options.slug + VERSIONS_API_URL);

/**
 Create a new version
 */
const createVersion = (options: PostVersionOptionsModel) =>
  ApiService.post<VersionHistory, VersionPostAPIModel>(
    PROJECTS_API_URL + options.slug + VERSIONS_API_URL,
    options.version
  );

const editVersion = (options: EditVersionOptionsModel) =>
  ApiService.patch<VersionSummary, VersionsPatchAPIModel>(
    PROJECTS_API_URL + options.slug + VERSIONS_API_URL,
    options.version
  );

const deleteVersion = (options: SlugOptionsModel) =>
  ApiService.remove(PROJECTS_API_URL + options.slug + VERSIONS_API_URL);

const VersionsService = {
  getVersions,
  createVersion,
  editVersion,
  deleteVersion,
  roles: OPEN_VIEW_ADMIN_MODIFY_PERMISSIONS
};

export default VersionsService;
