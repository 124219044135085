import {
  ADMIN_PROJECT_SETTINGS_LAYOUT,
  SUPERUSER_LAYOUT,
  MAIN_LAYOUT,
  MAIN_NO_DASHBOARD_LAYOUT,
  SUPERUSER_PROJECT_SETTINGS_LAYOUT,
  ADMIN_MAIN_NO_DASHBOARD_LAYOUT,
  ADMIN_MAIN_LAYOUT
} from 'constants/layouts';
import { isAdmin, isSuperUser } from './permissions';
import { ProjectRole } from 'models/project-model';

/**
 * Function to determine what layout to render based on
 * whether the user is a superuser or not
 *
 * @param role the user's role in the project
 * @param projectCount the number of projects the user is a member of
 * @returns SUPERUSER_LAYOUT if the user is a superuser,
 *          MAIN_NO_DASHBOARD_LAYOUT if the user is not a superuser and only has one project,
 *          MAIN_LAYOUT otherwise
 */
export const getMainLayout = (role: ProjectRole, projectCount?: number) =>
  isSuperUser(role)
    ? SUPERUSER_LAYOUT
    : isAdmin(role)
      ? projectCount === 1
        ? ADMIN_MAIN_NO_DASHBOARD_LAYOUT
        : ADMIN_MAIN_LAYOUT
      : projectCount === 1
        ? MAIN_NO_DASHBOARD_LAYOUT
        : MAIN_LAYOUT;

export const getProjectSettingsLayout = (superUser: boolean) =>
  superUser ? SUPERUSER_PROJECT_SETTINGS_LAYOUT : ADMIN_PROJECT_SETTINGS_LAYOUT;
