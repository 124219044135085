import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import React from 'react';

/**
 * Dialog for archiving
 *
 * @param objectName type of the object
 * @param accept function to perform on accept
 * @param showRetireDialog boolean to show dialog
 * @param onHide function to perform on hide
 * @component
 */
export const RetireDialog = ({
  objectName,
  accept,
  showRetireDialog,
  onHide
}: {
  objectName: string;
  accept: () => void;
  showRetireDialog: boolean;
  onHide: () => void;
}) => {
  return (
    <ConfirmDialog
      header={`Retire ${objectName.charAt(0).toUpperCase() + objectName.slice(1)}?`}
      icon="pi pi-info-circle"
      message={
        <ul className="list-disc">
          <li>
            Anything currently using this {objectName} will continue using this {objectName}.
          </li>
          <li>Nothing will be able to select this {objectName} while retired.</li>
          <li>
            This {objectName} will be hidden by default and can be unhidden through the filter menu.
          </li>
          <li>This {objectName} cannot be edited while retired.</li>
          <li>This {objectName} can be unretired after being retired.</li>
          <li>This {objectName} can be deleted after being retired.</li>
        </ul>
      }
      acceptClassName="p-button-danger"
      accept={accept}
      visible={showRetireDialog}
      onHide={onHide}
    />
  );
};

/**
 * Dialog for unretiring
 *
 * @param objectName type of the object
 * @param accept function to perform on accept
 * @component
 */
export const RemoveRetiredDialog = ({
  objectName,
  accept
}: {
  objectName: string;
  accept: () => void;
}) => {
  return confirmDialog({
    header: `Unretire ${objectName.charAt(0).toUpperCase() + objectName.slice(1)}?`,
    icon: 'pi pi-info-circle',
    draggable: false,
    message: (
      <ul className="list-disc">
        <li>This {objectName} will be selectable again.</li>
        <li>This {objectName} will be editable again.</li>
        <li>This {objectName} will no longer be hidden by default.</li>
        <li>This {objectName} cannot be deleted unless retired again.</li>
      </ul>
    ),
    acceptClassName: 'p-button-danger',
    accept: accept
  });
};
