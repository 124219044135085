import React, { useEffect, useState } from 'react';
// prime react
import { Button } from 'primereact/button';
// components
import SearchBar from 'components/SearchBar';
import EntityGroupDataTable from './components/EntityGroupDataTable';
import EntityGroupPopup from './components/EntityGroupPopup';
// constants
import { FetchStatusOptions } from 'constants/fetchStatus';
// models
import {
  EditEntityGroupOptionsModel,
  EntityGroupTableAPIModel,
  EntityGroupTableDataModel,
  PostEntityGroupOptionsModel
} from 'models/entity-group-model';
// hooks
import { useAppDispatch, useAppSelector } from 'hooks/store';
import useFetch from 'hooks/useFetch';
// services
import EntityGroupsService from 'services/entityGroupsService';
// selectors
import { setLayout } from 'features/layout/layoutSlice';
import {
  addEntityGroup as addEntityGroupState,
  deleteEntityGroup as deleteEntityGroupState,
  editEntityGroup as editEntityGroupState,
  entitiesSelector,
  entityGroupsSelector,
  getEntityGroups,
  projectsSelector
} from 'features/projects/projectsSlice';
import { SlugIdOptionsModel } from 'models/api-model';
import { versionsSelector } from 'features/versions/versionsSlice';
import { createToast } from 'features/toast/toastSlice';
import { filterDefault } from 'util/default';
import { ThreadStateChangeEvent } from 'pages/Comments/CommentPopup';
import useDataFilter, { MatchMode } from 'hooks/useDataFilter';
import { FormMode } from 'models/form-model';
import { FieldValues } from 'react-hook-form';
import { permissionBoolean } from 'util/permissions';
import CommentsService from 'services/commentsService';
import { getMainLayout } from 'util/layout';

const EntityGroup = () => {
  const { selectedProject, projects } = useAppSelector(projectsSelector);
  const { isVersionSelected } = useAppSelector(versionsSelector);
  const allEntityGroups = useAppSelector(entityGroupsSelector);
  const allEntities = useAppSelector(entitiesSelector);
  const entities = filterDefault(allEntities);
  const [contextEntityGroup, setContextEntityGroup] = useState<EntityGroupTableDataModel | null>(
    null
  );
  const [popupMode, setPopupMode] = useState<FormMode>(FormMode.CREATE);
  const [displayEntityGroupPopup, setDisplayEntityGroupPopup] = useState(false);
  const dispatch = useAppDispatch();
  const {
    searchText,
    search,
    clearSearch,
    simpleSearch,
    results: filteredEntityGroups,
    loading
  } = useDataFilter({
    searchTextFields: 'name',
    filters: {
      default: { value: false, matchMode: MatchMode.EQUALS }
    },
    items: allEntityGroups
  });

  /* - - - - - - - - - - Init - - - - - - - - - - */

  useEffect(() => {
    dispatch(setLayout(getMainLayout(selectedProject.project_user.role, projects.length)));
    dispatch(getEntityGroups({ slug: selectedProject.slug }));
  }, []);

  /* - - - - - - - - - - Get Entity Groups - - - - - - - - - - */

  const {
    fetch: getEntityGroup,
    data: getEntityGroupData,
    fetchStatus: getEntityGroupStatus
  } = useFetch(EntityGroupsService.getEntityGroup, EntityGroupsService.roles.list);

  useEffect(() => {
    if (getEntityGroupStatus === FetchStatusOptions.SUCCESS && getEntityGroupData) {
      dispatch(editEntityGroupState(getEntityGroupData));
    }
  }, [getEntityGroupStatus]);

  /* - - - - - - - - - - Create Entity Group - - - - - - - - - - */

  const {
    fetch: postEntityGroup,
    data: newEntityGroup,
    fetchStatus: entityGroupPostStatus,
    permission: canCreateEntityGroup
  } = useFetch<PostEntityGroupOptionsModel, EntityGroupTableDataModel>(
    EntityGroupsService.createEntityGroup,
    EntityGroupsService.roles.create
  );

  useEffect(() => {
    if (entityGroupPostStatus === FetchStatusOptions.SUCCESS && newEntityGroup) {
      dispatch(addEntityGroupState(newEntityGroup));
      dispatch(createToast('entity group created'));
      hidePopup();
    }
  }, [entityGroupPostStatus]);

  /* - - - - - - - - - - Edit Entity Group - - - - - - - - - - */
  const {
    fetch: editEntityGroup,
    data: updatedEntityGroup,
    fetchStatus: entityGroupEditStatus,
    permission: canEditEntityGroup
  } = useFetch<EditEntityGroupOptionsModel, EntityGroupTableDataModel>(
    EntityGroupsService.editEntityGroup,
    EntityGroupsService.roles.update
  );

  useEffect(() => {
    if (entityGroupEditStatus === FetchStatusOptions.SUCCESS && updatedEntityGroup) {
      dispatch(editEntityGroupState(updatedEntityGroup));
      dispatch(createToast('entity group edited'));
      hidePopup();
    }
  }, [entityGroupEditStatus]);

  /* - - - - - - - - - - Delete Entity Group - - - - - - - - - - */

  const {
    fetch: deleteEntityGroup,
    fetchStatus: entityGroupDeleteStatus,
    fetchOptions: deleteEntityGroupOptions,
    permission: canDeleteEntityGroup
  } = useFetch<SlugIdOptionsModel<EntityGroupTableDataModel['id']>, EntityGroupTableDataModel>(
    EntityGroupsService.deleteEntityGroup,
    EntityGroupsService.roles.delete
  );

  useEffect(() => {
    if (entityGroupDeleteStatus === FetchStatusOptions.SUCCESS && deleteEntityGroupOptions) {
      dispatch(deleteEntityGroupState(deleteEntityGroupOptions.id));
      dispatch(createToast('entity group deleted'));
    }
  }, [entityGroupDeleteStatus]);

  const deleteTableEntityGroup = (node: EntityGroupTableDataModel) =>
    deleteEntityGroup({
      slug: selectedProject.slug,
      id: node.id
    });

  /* - - - - - - - - - - Popup - - - - - - - - - - */

  /**
   * Callback to change the new Entity Group pop-up visibility in state.
   * @param visibility boolean representing the visibility of the new Entity Group popup
   * @param context the context Entity Group, used for editing
   */
  const handlePopupDisplayChange = (visibility: boolean, context?: EntityGroupTableDataModel) => {
    if (context) {
      setContextEntityGroup(context);
      setPopupMode(FormMode.EDIT);
    } else {
      setPopupMode(FormMode.CREATE);
      setContextEntityGroup(null);
    }
    setDisplayEntityGroupPopup(visibility);
  };

  const onSubmit = (data: FieldValues) => {
    const tableData = data as EntityGroupTableAPIModel;

    if (contextEntityGroup && popupMode === FormMode.EDIT) {
      editEntityGroup({
        slug: selectedProject.slug,
        entityGroupId: contextEntityGroup.id,
        updatedEntityGroupData: tableData
      });
    } else {
      postEntityGroup({ slug: selectedProject.slug, newEntityGroupData: tableData });
    }
  };

  const hidePopup = () => handlePopupDisplayChange(false);

  /* - - - - - - - - - - Comments - - - - - - - - - - */

  const onThreadChange = (options: ThreadStateChangeEvent) => getEntityGroup(options);

  return (
    <div className="specto-entity-group">
      <EntityGroupPopup
        entities={entities}
        displayPopup={displayEntityGroupPopup}
        onHide={hidePopup}
        onSubmit={onSubmit}
        contextEntityGroup={contextEntityGroup}
        mode={popupMode}
        loading={
          entityGroupPostStatus === FetchStatusOptions.LOADING ||
          entityGroupEditStatus === FetchStatusOptions.LOADING
        }
        parentElement={document.activeElement as HTMLElement}
      />
      <div className="grid grid-nogutter">
        <div className="col flex flex-wrap xl:align-items-center align-items-start justify-content-between flex-column-reverse xl:flex-row">
          <SearchBar
            placeHolder="Search by name"
            className="specto-search-bar w-12 lg:w-7 xl:w-5 mt-3 xl:mt-0"
            text={searchText}
            loading={loading}
            onChange={(e) => simpleSearch(e.target.value)}
            onSubmitText={search}
            onClearText={() => {
              clearSearch();
              search('');
            }}
          />
          {!isVersionSelected && canCreateEntityGroup && (
            <Button
              label="New Entity Group"
              icon="pi pi-plus"
              iconPos="right"
              onClick={() => handlePopupDisplayChange(true)}
            />
          )}
        </div>

        <div className="col-12 mt-4">
          <div className="card">
            <EntityGroupDataTable
              entityGroups={filteredEntityGroups}
              allEntities={entities}
              canDelete={canDeleteEntityGroup}
              onEditInit={(entityGroup) => handlePopupDisplayChange(true, entityGroup)}
              onThreadStateChange={onThreadChange}
              onDeleteEntityGroup={deleteTableEntityGroup}
              readOnly={!canEditEntityGroup}
              showComments={permissionBoolean(
                CommentsService.roles.general.list,
                selectedProject.project_user.role
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntityGroup;
