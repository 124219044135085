import React, { CSSProperties, RefObject, useState } from 'react';
import stateSeal from '../../assets/brand_banner/mass-gov-state-seal.png';
import govLogo from '../../assets/brand_banner/gov_building_icon.svg';
import lockIconSm from '../../assets/brand_banner/lock-small-icon.svg';
import lockIconLg from '../../assets/brand_banner/lock-large-icon.svg';
import classNames from 'classnames';

const buttonStyle = {
  expandedIcon: (isExpanded: boolean): CSSProperties => ({
    transform: isExpanded ? 'rotate(180deg)' : '',
    transition: '.2s all'
  })
};

/**
 * Mass.gov brand banner to display througout the app.
 *
 * @param brandBannerRef Current ref for the brand banner component to get its
 * dimensions and update styling accordingly
 * @param className string of classnames to style component
 * @component
 */

const BrandBanner = ({
  brandBannerRef,
  className
}: {
  brandBannerRef?: RefObject<HTMLButtonElement>;
  className?: string;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
    <button
      ref={brandBannerRef}
      id="mass-gov-brand-banner"
      className={classNames(className, 'flex flex-column border-none gap-3 z-2 px-3 py-2')}
    >
      <div
        className="mass-gov-brand-banner-header flex flex-row align-items-center gap-2 cursor-pointer w-screen"
        onClick={() => setExpanded((c) => !c)}
      >
        <img src={stateSeal} alt="mass-gov-state-seal" width={20} height={20} />
        <div className="flex flex-row flex-wrap gap-1">
          <span className="mr-2">An official website of the Commonwealth of Massachusetts</span>
          <span className="highlighted-expand-text font-semibold">
            Here's how you know{' '}
            <i
              className="pi pi-angle-down vertical-align-middle"
              style={buttonStyle.expandedIcon(expanded)}
            ></i>
          </span>
        </div>
      </div>
      {expanded && (
        <div className="flex flex-row flex-wrap gap-3">
          <div className="flex flex-row gap-3 align-items-start">
            <img src={govLogo} alt="Government building icon" />
            <div className="flex flex-column align-items-start max-w-29rem text-left gap-1">
              <span className="font-semibold">Official websites use .mass.gov</span>
              <span>
                A .mass.gov website belongs to an official government organization in Massachusetts
              </span>
            </div>
          </div>
          <div className="flex flex-row gap-3 align-items-start">
            <img src={lockIconLg} alt="Large lock icon" />
            <div className="flex flex-column align-items-start max-w-29rem text-left gap-1">
              <span className="font-semibold">Secure websites use HTTPS certificate</span>
              <span>
                A lock icon (<img src={lockIconSm} alt="small lock icon" />) or https:// means
                you’ve safely connected to the official website. Share sensitive information only on
                official, secure websites.
              </span>
            </div>
          </div>
        </div>
      )}
    </button>
  );
};

export default BrandBanner;
