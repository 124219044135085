import React, { ReactElement, createContext, useEffect, useState } from 'react';

// constants
import { FetchStatusOptions } from 'constants/fetchStatus';

// hooks
import { useAppDispatch, useAppSelector } from 'hooks/store';
import useDataFilter from 'hooks/useDataFilter';
import useFetch from 'hooks/useFetch';

// models
import {
  EditLookupTableOptionsModel,
  LookupTableDataModel,
  LookupTableEditData,
  PostLookupTableOptionsModel
} from 'models/lookup-table-model';
import { SlugIdOptionsModel } from 'models/api-model';
import { EntityTableDataModel } from 'models/entity-model';
import { ProjectDetailModel } from 'models/project-model';

// pages
import { ThreadStateChangeEvent } from 'pages/Comments/CommentPopup';

// selectors
import { versionsSelector } from 'features/versions/versionsSlice';
import {
  addLookupTable as addLookupTableState,
  deleteLookupTable as deleteLookupTableState,
  editLookupTable as editLookupTableState,
  entitiesSelector,
  lookupTableSelector,
  projectsSelector
} from 'features/projects/projectsSlice';
import { setLayout } from 'features/layout/layoutSlice';
import { createToast } from 'features/toast/toastSlice';

// services
import LookupTableService from 'services/lookupTableService';

// utils
import { getMainLayout } from 'util/layout';

/**
 * Lookup Table Context state values type
 */
type LookupTableType = {
  loading: boolean;
  searchText: string;
  search: (arg: string) => void;
  simpleSearch: (arg: string) => void;
  clearSearch: () => void;
  handlePopupDisplayChange: (arg1: boolean, arg2?: LookupTableDataModel) => void;
  isVersionSelected: boolean;
  canCreateLookupTable: boolean;
  displayNewLookupTablePopup: boolean;
  rowEditContextNode: LookupTableDataModel | null;
  editTableLookup: (arg1: LookupTableEditData) => void;
  filteredLookupTables: LookupTableDataModel[];
  canDeleteLookupTable?: boolean;
  onThreadChange: (arg1: ThreadStateChangeEvent) => void;
  deleteTableLookup?: (arg1: LookupTableDataModel) => void;
  allEntities: EntityTableDataModel[];
  canEditLookupTable: boolean;
  selectedProject: ProjectDetailModel;
};

/**
 * Lookup Table Context with initial state values
 */
const LookupTableContext = createContext<LookupTableType>({
  loading: false,
  searchText: '',
  search: () => undefined,
  simpleSearch: () => undefined,
  clearSearch: () => undefined,
  handlePopupDisplayChange: () => undefined,
  isVersionSelected: false,
  canCreateLookupTable: false,
  displayNewLookupTablePopup: false,
  rowEditContextNode: null,
  editTableLookup: () => undefined,
  filteredLookupTables: [],
  canDeleteLookupTable: false,
  onThreadChange: () => undefined,
  deleteTableLookup: () => undefined,
  allEntities: [],
  canEditLookupTable: false,
  selectedProject: {} as ProjectDetailModel
});

type ChildrenType = { children?: ReactElement | ReactElement[] };

export const LookupTableProvider = ({ children }: ChildrenType): ReactElement => {
  const { selectedProject, projects } = useAppSelector(projectsSelector);
  const { isVersionSelected } = useAppSelector(versionsSelector);
  const allLookupTables = useAppSelector(lookupTableSelector);
  const allEntities = useAppSelector(entitiesSelector);
  const [displayNewLookupTablePopup, setDisplayNewLookupTablePopup] = useState(false);
  const [rowEditContextNode, setRowEditContextNode] = useState<LookupTableDataModel | null>(null);
  const dispatch = useAppDispatch();

  const {
    searchText,
    search,
    clearSearch,
    simpleSearch,
    results: filteredLookupTables,
    loading
  } = useDataFilter({
    searchTextFields: 'phrases',
    items: allLookupTables
  });

  /* - - - - - - - - - - Init - - - - - - - - - - */
  useEffect(() => {
    dispatch(setLayout(getMainLayout(selectedProject.project_user.role, projects.length)));
  }, []);

  /* - - - - - - - - - - Re-fetch LookupTable - - - - - - - - - - */
  const {
    fetch: getLookupTable,
    data: getLookupTableData,
    fetchStatus: getLookupTableStatus,
    permission: canDeleteLookupTable
  } = useFetch(LookupTableService.getLookupTable, LookupTableService.roles.list);

  useEffect(() => {
    if (getLookupTableStatus === FetchStatusOptions.SUCCESS && getLookupTableData) {
      dispatch(editLookupTableState(getLookupTableData));
    }
  }, [getLookupTableStatus]);

  /* - - - - - - - - - - Post LookupTable - - - - - - - - - - */
  const {
    fetch: postLookupTable,
    data: newLookupTable,
    fetchStatus: lookupTablePostStatus,
    permission: canCreateLookupTable
  } = useFetch<PostLookupTableOptionsModel, LookupTableDataModel>(
    LookupTableService.createLookupTable,
    LookupTableService.roles.create
  );

  useEffect(() => {
    if (lookupTablePostStatus === FetchStatusOptions.SUCCESS && newLookupTable) {
      dispatch(addLookupTableState(newLookupTable));
      dispatch(createToast('lookup table created'));
      handlePopupDisplayChange(false);
    }
  }, [lookupTablePostStatus]);

  /* - - - - - - - - - - Edit Lookup Table - - - - - - - - - - */
  const {
    fetch: editLookupTable,
    data: editLookupTableData,
    fetchStatus: lookupTableEditStatus,
    permission: canEditLookupTable
  } = useFetch<EditLookupTableOptionsModel, LookupTableDataModel>(
    LookupTableService.editLookupTable,
    LookupTableService.roles.update
  );

  useEffect(() => {
    if (lookupTableEditStatus === FetchStatusOptions.SUCCESS && editLookupTableData) {
      dispatch(editLookupTableState(editLookupTableData));
      dispatch(createToast('lookup table edited'));
      handlePopupDisplayChange(false);
    }
  }, [lookupTableEditStatus]);

  /* - - - - - - - - - - Delete LookupTable - - - - - - - - - - */
  const {
    fetch: deleteLookupTable,
    fetchStatus: lookupTableDeleteStatus,
    fetchOptions: deleteLookupTableOptions
  } = useFetch<SlugIdOptionsModel<LookupTableDataModel['id']>, LookupTableDataModel>(
    LookupTableService.deleteLookupTable,
    LookupTableService.roles.delete
  );

  useEffect(() => {
    if (lookupTableDeleteStatus === FetchStatusOptions.SUCCESS && deleteLookupTableOptions) {
      dispatch(deleteLookupTableState(deleteLookupTableOptions.id));
      dispatch(createToast('lookup table deleted'));
    }
  }, [lookupTableDeleteStatus]);

  /**
   * Callback to change the new lookup table pop-up visibility in state.
   * @param shouldShow boolean representing the visibility of the new lookup table popup
   * @param rowData row data
   */
  const handlePopupDisplayChange = (shouldShow: boolean, rowData?: LookupTableDataModel) => {
    setRowEditContextNode(rowData ?? null);
    setDisplayNewLookupTablePopup(shouldShow);
  };

  /* - - - - - - - - - - Table Actions - - - - - - - - - - */
  const editTableLookup = (data: LookupTableEditData) => {
    // The backend wants 'phrases' as a json array string
    const postData: LookupTableEditData = {
      ...data,
      phrases: JSON.stringify(data.phrases),
      file: data.file
    };

    rowEditContextNode
      ? editLookupTable({
          slug: selectedProject.slug,
          lookupTableId: rowEditContextNode.id,
          updatedLookupTableData: postData
        })
      : postLookupTable({ slug: selectedProject.slug, newLookupTableData: postData });
  };

  const deleteTableLookup = (node: LookupTableDataModel) =>
    deleteLookupTable({
      slug: selectedProject.slug,
      id: node.id
    });

  const onThreadChange = (options: ThreadStateChangeEvent) => getLookupTable(options);

  return (
    <LookupTableContext.Provider
      value={{
        searchText,
        loading,
        search,
        simpleSearch,
        clearSearch,
        handlePopupDisplayChange,
        isVersionSelected,
        canCreateLookupTable,
        displayNewLookupTablePopup,
        rowEditContextNode,
        editTableLookup,
        filteredLookupTables,
        allEntities,
        onThreadChange,
        deleteTableLookup,
        canDeleteLookupTable,
        canEditLookupTable: !canEditLookupTable,
        selectedProject
      }}
    >
      {children}
    </LookupTableContext.Provider>
  );
};

export default LookupTableContext;
