import React, { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
// components
import Popup from 'containers/Popup';
import { TextField } from 'components/Forms';
import MultiSelectField from 'components/Forms/MultiSelectField';
// models
import { EntityGroupTableDataModel } from 'models/entity-group-model';
import { EntityTableDataModel } from 'models/entity-model';
import { FormMode } from 'models/form-model';
import { filterRetired } from 'util/status';
import { GenericItemTemplate } from 'components/Table';

type EntityGroupPopupPropsModel = {
  contextEntityGroup?: EntityGroupTableDataModel | null;
  displayPopup: boolean;
  entities: EntityTableDataModel[];
  loading?: boolean;
  mode?: FormMode;
  onHide(): void;
  onSubmit(data: FieldValues): void;
  parentElement?: HTMLElement;
};

/**
 * Component that displays a popup to create a new entity group.
 *
 * @component
 * @param param0 Prop object containing the following:
 *    contextEntityGroup: context Entity Group
 *    displayPopup: Boolean representing whether to display the popup or not.
 *    entities: list of Entities that the user can select from
 *    loading: loading state
 *    mode: Form mode
 *    onHide: callback to hide the entity
 *    onSubmit: callback for submission
 *    parentElement: Element to return focus to after the dialog is hidden
 *
 * @returns
 */
const EntityGroupPopup = function ({
  contextEntityGroup,
  displayPopup,
  entities,
  loading,
  mode = FormMode.CREATE,
  onHide,
  onSubmit,
  parentElement
}: EntityGroupPopupPropsModel) {
  const { control, handleSubmit, reset, formState } = useForm();

  /* - - - - - - - - - - Effects - - - - - - - - - - */

  useEffect(() => {
    const defaultValues: FieldValues = contextEntityGroup ?? {
      name: '',
      entities: []
    };
    reset(defaultValues);
  }, [contextEntityGroup]);

  // reset when closing
  useEffect(() => {
    !displayPopup && reset();
  }, [displayPopup]);

  /* - - - - - - - - - - Callbacks - - - - - - - - - - */
  const onPopupSave = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    handleSubmit(onSubmit)();
  };

  const hidePopup = () => {
    reset();
    onHide();
    parentElement?.focus();
  };

  return (
    <Popup
      title={mode === FormMode.CREATE ? 'New Entity Group' : 'Edit Entity Group'}
      subtitle={
        mode === FormMode.CREATE ? 'Create a new Entity Group' : 'Modify an existing Entity Group'
      }
      visible={displayPopup}
      loading={loading}
      onHide={hidePopup}
      onSave={onPopupSave}
    >
      <form className="p-fluid" onSubmit={onPopupSave}>
        <div className="specto-text-medium -mt-2 mb-5">
          Required fields are marked with <span className="specto-form-asterisk-color">*</span>
        </div>

        <TextField fieldName="name" label="Name" control={control} />
        <MultiSelectField
          appendTo={null}
          fieldName="entities"
          label="Entities"
          required
          control={control}
          options={filterRetired(entities, formState.defaultValues?.entities)}
          itemTemplate={(item) =>
            GenericItemTemplate({
              id: item.id,
              itemName: 'entity',
              allOptions: entities,
              showRetiredIcon: true
            })
          }
          optionLabel="name"
          optionValue="id"
          filter
          display="chip"
        />
      </form>
    </Popup>
  );
};

export default EntityGroupPopup;
