import {
  IconButtonCell,
  RowEditorCell,
  TableHeader,
  TableIcons,
  TagsCell,
  TextAreaEditorCell,
  TreeToggleButton
} from 'components/Table';
import { DataTable, DataTableProps, DataTableRowEditCompleteEvent } from 'primereact/datatable';
import {
  BotIntent,
  Label,
  MessageInboxLabelledByModel,
  MessageInboxPatchRequestAPIModel,
  MessageInboxTableDataModel
} from 'models/message-inbox-table-data-model';
import MessageInboxRowExpansionTemplate from './MessageInboxRowExpansionTemplate';
import { Column } from 'primereact/column';
import ReviewCheckboxCell, { ReviewCheckboxValue } from './ReviewCheckboxCell';
import LabelledByCell from './LabelledByCell';
import { mapTags } from 'util/tags';
import React, { useCallback, useEffect, useState } from 'react';
import { MenuItem } from 'primereact/menuitem';
import { useAppSelector } from 'hooks/store';
import {
  entitiesSelector,
  entityGroupsSelector,
  intentsSelector,
  sentimentsSelector,
  tagsSelector
} from 'features/projects/projectsSlice';
import { TagModel } from 'models/tag-model';
import useToggleKeys from 'hooks/useToggleKeys';
import { getLabelStatus, IntentSentimentLabelTemplate } from 'util/messsage-inbox';
import { Status } from 'models/status-model';
import DropDownCell from './DropDownCell';
import { filterRetired } from 'util/status';
import { Button } from 'primereact/button';
import { EntityDisplay, EntityDisplayOption } from 'features/entity-labelling';
import { entityOptionColors } from 'features/entity-labelling/option-colors';
import { hex2rgb } from 'util/color';
import { useDelay } from 'hooks/useDelay';
import { confirmDialog } from 'primereact/confirmdialog';
import MetadataIcon from 'components/Table/MetadataIcon';
import { CommentThreadButton } from 'pages/Comments/CommentThreadButton';
import { customSortIconFn } from 'util/table';
import paginatorTemplate from 'components/Table/PaginatorTemplate';

type MessageInboxDataTableProps = {
  loading: boolean;
  allMessageInboxData: any;
  bulkActionItems?: MenuItem[];
  bulkActionsMenuRef?: React.RefObject<MenuItem>;
  onPage: any;
  rows: number;
  first: number;
  totalRecords?: number;
  checkedRows: DataTableProps<MessageInboxTableDataModel[]>['selection'];
  setCheckedRows: any;
  onEdit(e: {
    rowData: MessageInboxTableDataModel;
    newValues: MessageInboxPatchRequestAPIModel;
  }): void;
  sentimentModule: boolean;
  openComments: (message: MessageInboxTableDataModel) => void;
  synonymRecord: Record<string, string>;
  superUserView?: boolean;
  openEntityEditor(node: MessageInboxTableDataModel): void;
  onRowEditComplete(e: DataTableRowEditCompleteEvent): void;
  openMessageInConversations(node: MessageInboxTableDataModel): void;
  onEditTags(node: MessageInboxTableDataModel, tags: TagModel[]): void;
  onDeleteRow(node: MessageInboxTableDataModel): void;
  showComments?: boolean;
};

/**
 * Component for displaying the Message Inbox Table.
 *
 * @param loading Flag indicating if the table is loading
 * @param allMessageInboxData List of all message inbox data
 * @param bulkActionItems Bulk action items
 * @param bulkActionsMenuRef Reference to the bulk actions menu
 * @param openMessageInConversations Callback function for opening a message in conversations
 * @param onPage Current page number
 * @param rows Number of rows per page
 * @param first Index of the first row being displayed
 * @param totalRecords Total number of records
 * @param checkedRows Checked rows
 * @param setCheckedRows Function to set the checked rows
 * @param openComments Flag indicating if comments are open
 * @param onEdit Callback function for editing
 * @param sentimentModule Sentiment module
 * @param onDeleteRow Callback function for deleting a row
 * @param onEditTags Callback function for editing tags
 * @param openEntityEditor Flag indicating if the entity editor is open
 * @param onRowEditComplete Callback function for when a row is edited
 * @param synonymRecord Synonym record
 * @param superUserView Flag indicating if it is in superuser view
 * @param showComments if the comments should be shown
 *
 * @constructor
 * @component
 */
const MessageInboxDataTable = function ({
  loading,
  allMessageInboxData,
  bulkActionItems,
  bulkActionsMenuRef,
  openMessageInConversations,
  onPage,
  rows,
  first,
  totalRecords,
  checkedRows,
  setCheckedRows,
  openComments,
  onEdit,
  sentimentModule,
  onDeleteRow,
  onEditTags,
  openEntityEditor,
  onRowEditComplete,
  synonymRecord,
  superUserView = false,
  showComments
}: MessageInboxDataTableProps) {
  const tags = useAppSelector(tagsSelector);
  const allIntents = useAppSelector(intentsSelector);
  const allSentiments = useAppSelector(sentimentsSelector);
  const allEntities = useAppSelector(entitiesSelector);
  const allGroups = useAppSelector(entityGroupsSelector);
  const {
    toggleKeySingle: toggleRow,
    isKeyExpanded: isRowExpanded,
    keys: expandedRows
  } = useToggleKeys();

  const { loading: stateLoading } = useDelay(loading);

  const rowViewOnly = (row: MessageInboxTableDataModel) =>
    row.labelled_by === MessageInboxLabelledByModel.ACCEPTED && !superUserView;

  const labelItemTemplateTooltip = (item?: Label) => (
    <div>
      This intent has status <br />
      <strong className="line-height-4">{getLabelStatus(allIntents, item)}</strong>
    </div>
  );

  const labelItemTemplateIcon = useCallback(
    (item?: Label) =>
      getLabelStatus(allIntents, item) === Status.New
        ? 'pi pi-circle-fill text-green-300'
        : allIntents.find((intent) => intent.name === item?.name)?.status === Status.Retired
          ? 'pi pi-circle-fill specto-error-icon'
          : undefined,
    [allIntents]
  );

  /**
   * Attaches a message's bot intents if they exist
   *
   * @param botIntents list of bot intents from the message
   * @param currentIntentId intent id
   * @returns list of intents with bot intent attached
   */
  const IntentsWithBotIntents = (botIntents: BotIntent[], currentIntentId: number) => {
    const availableIntents = filterRetired(allIntents, currentIntentId);
    return availableIntents.map((intent) => {
      const botIntent = botIntents.find((botIntent) => botIntent.intent === intent.id);
      return {
        ...intent,
        is_predicted: botIntent ? botIntent.is_predicted : false,
        confidence: botIntent ? botIntent.confidence : null
      };
    });
  };

  const [editingRows, setEditingRows] = useState({});

  const setActiveRowIndex = (index: number) => {
    setEditingRows({ [`${allMessageInboxData[index].id}`]: true });
  };

  const onRowEditChange = (e: any) => {
    setEditingRows(e.data);
  };

  /* - - - - - - - - - - Entity Options - - - - - - - - - - */

  const [entityOptions, setEntityOptions] = useState<EntityDisplayOption[]>([]);

  // loop through entity colors, pick a random color if none is found
  useEffect(() => {
    setEntityOptions(
      allEntities.map((entity, i) => ({
        type: entity.name,
        color: hex2rgb(
          entityOptionColors[i] ??
            entityOptionColors[Math.floor(Math.random() * entityOptionColors.length)]
        )
      }))
    );
  }, [allEntities]);

  /* - - - - - - - - - - Dialogs - - - - - - - - - - */
  const confirmDeleteRow = (node: MessageInboxTableDataModel) => {
    confirmDialog({
      message: 'This action cannot be undone.',
      header: 'Delete Row?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => onDeleteRow?.(node)
    });
  };

  return (
    <>
      <h6>
        {TableHeader({
          tableType: 'Message',
          itemCount: totalRecords,
          bulkActionsMenuRef,
          bulkActionItems,
          menuClassName: 'w-20rem'
        })}
      </h6>
      <DataTable
        className="specto-data-table"
        dataKey="id"
        loading={stateLoading}
        sortIcon={customSortIconFn}
        selectionMode="checkbox"
        selectionPageOnly
        selectionAutoFocus={false}
        rowClassName={() => 'invisible-button-toggle'}
        editMode="row"
        editingRows={editingRows}
        onRowEditChange={onRowEditChange}
        onRowDoubleClick={(e) => e?.index && setActiveRowIndex(e.index)}
        paginator
        paginatorTemplate={paginatorTemplate}
        onPage={onPage}
        rows={rows}
        first={first}
        totalRecords={totalRecords}
        lazy
        value={allMessageInboxData}
        metaKeySelection={false}
        expandedRows={expandedRows}
        selection={checkedRows}
        onSelectionChange={(e: any) => setCheckedRows(e.value)}
        onRowEditComplete={onRowEditComplete}
        rowExpansionTemplate={(node: MessageInboxTableDataModel) => (
          <MessageInboxRowExpansionTemplate
            node={node}
            superUserView={!!superUserView}
            onCheck={(childRow) =>
              onEdit({
                rowData: node,
                newValues: {
                  reviewed: true,
                  intent: childRow.intent,
                  sentiment: childRow.sentiment,
                  text: childRow.text
                }
              })
            }
            sentiment={sentimentModule}
            allGroups={allGroups}
            allEntities={allEntities}
            synonymRecord={synonymRecord}
            entityOptions={entityOptions}
          />
        )}
      >
        <Column selectionMode="multiple" className="specto-table-icon" />
        <Column
          columnKey="util-toggle"
          className="specto-table-icon"
          header={TableIcons.HistoryTableIcon}
          body={(node: MessageInboxTableDataModel) => (
            <TreeToggleButton
              display={node.related_records.length > 0}
              toggleRowExpansion={() => toggleRow(node.id)}
              expanded={isRowExpanded(node.id)}
            />
          )}
        />
        <Column
          field="text"
          header="Message"
          className="specto-table__column--lg"
          style={{ maxWidth: 'initial !important' }}
          body={(node: MessageInboxTableDataModel) => (
            <EntityDisplay
              text={node.text}
              entityLabels={node.entity_labels}
              options={entityOptions}
              allEntities={allEntities}
              allGroups={allGroups}
              synonymRecord={synonymRecord}
            />
          )}
          editor={(options) =>
            TextAreaEditorCell({
              options,
              viewOnly: rowViewOnly(options.rowData)
            })
          }
        />
        <Column
          columnKey="util-row-editor-entity-labeling"
          className="specto-table__column--sm px-0"
          style={{ maxWidth: '10rem' }}
          alignHeader="center"
          align="center"
          rowEditor
          body={(node, options) =>
            RowEditorCell({
              nodeId: node.id,
              options,
              rowEditIndex: editingRows,
              disableDelete: !superUserView,
              otherActions: [
                <Button
                  key={`${node.id}-entity-labeler`}
                  icon="pi pi-ticket"
                  className="p-button-rounded specto-r-button-lg p-button-text"
                  onClick={() => openEntityEditor(node)}
                  tooltip="Label Entities"
                  tooltipOptions={{ position: 'bottom' }}
                  type="button"
                  aria-label="delete entity"
                />
              ]
            })
          }
        />
        <Column
          field="intent"
          className="specto-table__column--lg"
          header="Intent Label"
          body={(node: MessageInboxTableDataModel) => {
            return (
              <DropDownCell
                options={IntentsWithBotIntents(node.bot_intents, node.intent)}
                value={node.intent}
                filter
                valueTemplate={(item) =>
                  IntentSentimentLabelTemplate({
                    label: item,
                    icon: labelItemTemplateIcon(item),
                    iconTooltip: labelItemTemplateTooltip(item),
                    dropdown: false,
                    truncatedNameTooltip: true
                  })
                }
                itemTemplate={(item) =>
                  IntentSentimentLabelTemplate({
                    label: item,
                    icon: labelItemTemplateIcon(item),
                    iconTooltip: labelItemTemplateTooltip(item)
                  })
                }
                optionLabel="name"
                optionValue="id"
                viewOnly={rowViewOnly(node)}
                onChange={(e) =>
                  onEdit({
                    rowData: node,
                    newValues: { intent: e.target.value }
                  })
                }
              />
            );
          }}
        />
        {sentimentModule && (
          <Column
            field="sentiment"
            className="specto-table__column--md-2"
            header="Sentiment"
            body={(node: MessageInboxTableDataModel) => (
              <DropDownCell
                options={allSentiments}
                optionLabel="name"
                optionValue="id"
                value={node.sentiment}
                valueTemplate={(item) =>
                  IntentSentimentLabelTemplate({ label: item, dropdown: false })
                }
                itemTemplate={(item) => IntentSentimentLabelTemplate({ label: item })}
                viewOnly={rowViewOnly(node)}
                onChange={(e) =>
                  onEdit({
                    rowData: node,
                    newValues: { sentiment: e.target.value }
                  })
                }
              />
            )}
          />
        )}
        <Column
          columnKey="reviewed"
          className="specto-table-icon"
          header={<i className="pi pi-check-circle specto-r-button-lg" />}
          body={(node: MessageInboxTableDataModel) => (
            <ReviewCheckboxCell
              onChange={() =>
                onEdit({
                  rowData: node,
                  newValues: { reviewed: !node.reviewed }
                })
              }
              value={
                node.reviewed
                  ? rowViewOnly(node)
                    ? ReviewCheckboxValue.unchangeable
                    : ReviewCheckboxValue.reviewed
                  : ReviewCheckboxValue.unchecked
              }
              viewOnly={rowViewOnly(node)}
            />
          )}
        />
        <Column
          columnKey="comments"
          hidden={!showComments}
          className="specto-table-icon"
          body={(node: MessageInboxTableDataModel) => (
            <CommentThreadButton
              threadResolved={node.comment_thread_resolved}
              action={() => openComments(node)}
            />
          )}
        />
        <Column
          hidden={!superUserView}
          columnKey="labelledBy"
          className="specto-table-icon"
          header="Labelled By"
          body={(node: MessageInboxTableDataModel) => (
            <LabelledByCell labelledBy={node.labelled_by} id={node.id} />
          )}
        />
        <Column
          columnKey="tag"
          className="specto-table-icon"
          header={<i className="pi pi-tags specto-r-button-lg max-w-max" />}
          body={(node: MessageInboxTableDataModel) => (
            <TagsCell
              tags={mapTags(tags, node.tags)}
              onTagChange={(newTags) => onEditTags(node, newTags)}
            />
          )}
        />
        <Column
          columnKey="util-trash"
          hidden={!superUserView}
          className="specto-table-icon-sm"
          body={(node: MessageInboxTableDataModel) => (
            <IconButtonCell
              icon="pi-trash"
              action={() => confirmDeleteRow(node)}
              className="invisible-button"
              tooltip="Delete"
            />
          )}
        />
        <Column
          columnKey="util-open-in-conversation"
          className="specto-table-icon-sm"
          body={(node: MessageInboxTableDataModel) =>
            node.sender &&
            node.conversation && (
              <IconButtonCell
                icon="pi-external-link"
                className="invisible-button"
                action={() => openMessageInConversations(node)}
                tooltip={
                  node.related_records.length > 0
                    ? 'Show Original Message in Conversation'
                    : 'Show in Conversation'
                }
                tooltipOptions={{ position: 'left' }}
              />
            )
          }
        />
        <Column
          columnKey="util-metadata"
          className="specto-table-icon-sm"
          body={(node: MessageInboxTableDataModel) => (
            <MetadataIcon
              metadata={node.metadata}
              id={node.id}
              className="invisible-button"
              position="left"
            />
          )}
        />
      </DataTable>
    </>
  );
};

export default MessageInboxDataTable;
