import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  MessageInboxRelatedRecord,
  MessageInboxTableDataModel
} from 'models/message-inbox-table-data-model';
import ReviewCheckboxCell, { ReviewCheckboxValue } from './ReviewCheckboxCell';
import AvatarCell from '../../../components/Avatar/AvatarCell';
import { IntentSentimentLabelTemplate } from 'util/messsage-inbox';
import { intentsSelector, sentimentsSelector } from 'features/projects/projectsSlice';
import { useAppSelector } from 'hooks/store';
import { EntityDisplay, EntityDisplayOption } from 'features/entity-labelling';
import { EntityTableDataModel } from 'models/entity-model';
import { EntityGroupTableDataModel } from 'models/entity-group-model';
import { useDelay } from 'hooks/useDelay';

type MessageInboxRowExpansionPropsModel = {
  node: MessageInboxTableDataModel;
  onCheck?(recordRow: MessageInboxRelatedRecord): void;
  sentiment?: boolean;
  entityOptions: EntityDisplayOption[];
  allEntities: EntityTableDataModel[];
  allGroups: EntityGroupTableDataModel[];
  synonymRecord: Record<string, string>;
  superUserView?: boolean;
};

const MessageInboxRowExpansionTemplate = function ({
  node,
  onCheck,
  sentiment,
  entityOptions,
  allEntities,
  allGroups,
  synonymRecord,
  superUserView = false
}: MessageInboxRowExpansionPropsModel) {
  const allIntents = useAppSelector(intentsSelector);
  const allSentiments = useAppSelector(sentimentsSelector);
  const { loading } = useDelay(false, node.entity_labels.length === 0 ? 250 : 500);

  /**
   * Gets the bot intent for an intent if they exist
   *
   * @param intentId id of the intent to get the bot intent of
   * @returns bot intent
   */
  const getBotIntent = (intentId: number) => {
    const intent = allIntents.find((intent) => intent.id === intentId);
    const botIntent = node.bot_intents.find((intent) => intent.intent === intentId);
    return {
      name: intent ? intent.name : '',
      is_predicted: botIntent ? botIntent.is_predicted : false,
      confidence: botIntent ? botIntent.confidence : null
    };
  };

  /**
   * Gets the bot sentiment if they exist
   * @param sentimentId id of the sentiment to get the bot sentiment of
   * @returns bot sentiment
   */
  const getBotSentiment = (sentimentId: number) => {
    const sentiment = allSentiments.find((sentiment) => sentiment.id === sentimentId);
    const botSentiment = node.bot_sentiments?.find(
      (botSentiment) => botSentiment.sentiment === sentimentId
    );
    return {
      name: sentiment ? sentiment.name : '',
      is_predicted: botSentiment ? botSentiment.is_predicted : false,
      confidence: botSentiment ? botSentiment.confidence : null
    };
  };

  return (
    <DataTable<MessageInboxRelatedRecord[]>
      value={node?.related_records}
      className="specto-data-table__tr-child"
      loading={loading}
    >
      <Column columnKey="blank-select" className="specto-table-icon" />
      <Column
        columnKey="util-toggle"
        className="specto-table-icon"
        body={(rowNode: MessageInboxRelatedRecord, options) => (
          <AvatarCell fullName={rowNode.user_fullname} id={node.id + options.rowIndex} />
        )}
      />
      <Column
        field="message"
        className="specto-table__column--lg"
        body={(rowNode: MessageInboxTableDataModel) =>
          rowNode.entity_labels.length > 0 ? (
            <EntityDisplay
              text={rowNode.text}
              entityLabels={rowNode.entity_labels}
              options={entityOptions}
              allEntities={allEntities}
              allGroups={allGroups}
              synonymRecord={synonymRecord}
            />
          ) : (
            <span className={rowNode.text === node.text ? '' : 'text-indigo-400'}>
              {rowNode.text}
            </span>
          )
        }
      />
      <Column
        columnKey="blank-row-editor-entity-labeling"
        className="specto-table__column--sm px-0"
      />
      <Column
        field="intent"
        className="specto-table__column--lg"
        bodyClassName="pl-5 pr-7"
        body={(rowNode: MessageInboxRelatedRecord) => (
          <IntentSentimentLabelTemplate dropdown={false} label={getBotIntent(rowNode.intent)} />
        )}
      />
      {sentiment && (
        <Column
          field="sentiment"
          className="specto-table__column--md-2"
          body={(rowNode: MessageInboxRelatedRecord) => (
            <IntentSentimentLabelTemplate
              dropdown={false}
              label={getBotSentiment(rowNode.sentiment)}
            />
          )}
        />
      )}
      <Column
        columnKey="reviewed"
        className="specto-table-icon"
        body={(rowNode: MessageInboxRelatedRecord) =>
          superUserView && (
            <ReviewCheckboxCell
              value={ReviewCheckboxValue['annotation-copy']}
              onChange={() => onCheck?.(rowNode)}
            />
          )
        }
      />
      <Column columnKey="blank-comments" className="specto-table-icon" />
      <Column columnKey="blank-labelledBy" className="specto-table-icon" hidden={!superUserView} />
      <Column columnKey="blank-tag" className="specto-table-icon" />
      <Column columnKey="blank-trash" className="specto-table-icon-sm" hidden={!superUserView} />
      <Column columnKey="blank-open-in-conversation" className="specto-table-icon-sm" />
      <Column columnKey="blank-metadata" className="specto-table-icon-sm" />
    </DataTable>
  );
};

export default MessageInboxRowExpansionTemplate;
