import ApiService from 'services/apiService';
import { ResponseTableAPIModel, ResponseTableDataModel } from 'models/response-table-data-model';
import {
  APPROVE_API_URL,
  EXPORT_API_URL,
  PUBLISH_API_URL,
  RESPONSES_API_URL,
  RETIRE_API_URL
} from 'constants/api';
import {
  RetiredOptionsModel,
  SlugDataIdOptionsModel,
  SlugDataOptionsModel,
  SlugHeadersOptionsModel,
  SlugIdOptionsModel,
  SlugQueryOptionsModel
} from 'models/api-model';
import { ProjectRole } from 'models/project-model';
import { Permissions } from 'models/permission-model';
import { NON_GUEST_ROLES } from 'constants/roles';
import { PaginationModel } from 'models/filter-model';

const getResponses = (options: SlugQueryOptionsModel) =>
  ApiService.get<ResponseTableDataModel[]>(
    '/' + options.slug + RESPONSES_API_URL + (options.query ?? '')
  );

const getPaginatedResponses = (options: SlugQueryOptionsModel) =>
  ApiService.get<PaginationModel<ResponseTableDataModel>>(
    '/' + options.slug + RESPONSES_API_URL + (options.query ?? '')
  );

const getResponse = (options: SlugIdOptionsModel) =>
  ApiService.get<ResponseTableDataModel>('/' + options.slug + RESPONSES_API_URL + options.id + '/');

const createResponse = (options: SlugDataOptionsModel<ResponseTableAPIModel>) =>
  ApiService.post<ResponseTableDataModel, ResponseTableAPIModel>(
    '/' + options.slug + RESPONSES_API_URL,
    options.data
  );

const deleteResponse = (options: SlugIdOptionsModel) =>
  ApiService.remove<ResponseTableDataModel>(
    '/' + options.slug + RESPONSES_API_URL + options.id + '/'
  );

const editResponse = (options: SlugDataIdOptionsModel<ResponseTableAPIModel>) =>
  ApiService.put<ResponseTableDataModel>(
    '/' + options.slug + RESPONSES_API_URL + options.id + '/',
    options.data
  );

const retireResponse = (options: RetiredOptionsModel) =>
  ApiService.put<ResponseTableDataModel>(
    '/' + options.slug + RESPONSES_API_URL + options.id + RETIRE_API_URL,
    options.retired
  );

const previewResponse = (options: SlugIdOptionsModel) =>
  ApiService.get<any>(
    '/' + options.slug + RESPONSES_API_URL + options.id + '/widget-representation/'
  );

const publishResponse = (options: SlugIdOptionsModel) =>
  ApiService.post<ResponseTableDataModel>(
    '/' + options.slug + RESPONSES_API_URL + options.id + PUBLISH_API_URL
  );

const approveResponse = (options: SlugIdOptionsModel) =>
  ApiService.post<ResponseTableDataModel>(
    '/' + options.slug + RESPONSES_API_URL + options.id + APPROVE_API_URL
  );

const exportResponses = (options: SlugHeadersOptionsModel) => {
  return ApiService.get<SlugHeadersOptionsModel>(
    '/' + options.slug + RESPONSES_API_URL + EXPORT_API_URL + options.query,
    options.headers
  );
};

const responsesServiceRoles: Permissions = {
  list: true,
  retrieve: true,
  create: NON_GUEST_ROLES,
  update: NON_GUEST_ROLES,
  delete: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN]
};

const ResponsesService = {
  approveResponse,
  createResponse,
  deleteResponse,
  editResponse,
  exportResponses,
  getResponse,
  getResponses,
  getPaginatedResponses,
  previewResponse,
  publishResponse,
  retireResponse,
  roles: responsesServiceRoles
};

export default ResponsesService;
