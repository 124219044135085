import React from 'react';
import { Control, FieldValues } from 'react-hook-form';
import RadioButtonField from 'components/Forms/RadioButtonField';
import { onDateChange } from 'util/calendar';
import { Status } from 'models/status-model';
import { FilterStatusExclusiveWarning } from 'constants/warnings';
import DatePickerField from 'components/Forms/DatePickerField';
import { Paper } from '@mui/material';
import LoadingSpinner from 'components/LoadingSpinner';
import { ProjectMemberUserModel } from 'models/project-model';
import MultiAutocompleteField from 'components/Forms/MultiAutocompleteField';
import { TopicTableDataModel } from 'models/topic-model';
import { GenericItemTemplate } from 'components/Table';

type EntityFilterProps = {
  control: Control;
  disableStatus?: boolean;
  loading?: boolean;
  onFormValueChange(data: FieldValues): void;
  topics: TopicTableDataModel[];
  authors: ProjectMemberUserModel[];
};

/**
 * @param control Control
 * @param disableStatus if the state field should be disabled
 * @param loading whether the form is in the process of submitting
 * @param onFormValueChange onChange callback function
 * @param topics List of topics
 * @param authors List of current project members
 *
 * @component
 */
function EntityFilterMenu({
  control,
  disableStatus,
  loading,
  onFormValueChange,
  topics,
  authors
}: EntityFilterProps) {
  return (
    <Paper className="p-3 w-30rem">
      {loading ? (
        <LoadingSpinner position="inline" />
      ) : (
        <form
          className="flex flex-wrap overflow-y-scroll gap-3"
          onSubmit={(e) => {
            e.preventDefault();
          }}
          style={{ maxHeight: '70vh' }}
        >
          <RadioButtonField
            label="Show:"
            fieldName="status"
            labelClassName="line-height-3 font-bold"
            className="col specto-border border-round border-1 p-3"
            disabled={disableStatus}
            tooltip={FilterStatusExclusiveWarning}
            tooltipOptions={{ showOnDisabled: true, disabled: !disableStatus }}
            control={control}
            options={[
              { id: 1, value: Status.Not_Retired, label: 'Active Entities' },
              { id: 2, value: Status.Retired, label: 'Retired Entities' }
            ]}
            noPadding
            onChange={(e) => onFormValueChange({ status: e.value })}
          />
          <DatePickerField
            label="Published on or Before:"
            placeholder="Select a date"
            fieldName="published_lte"
            labelClassName="line-height-3 font-bold"
            className="w-12 specto-border border-round border-1 p-3"
            control={control}
            onChange={(e) => onDateChange(e, onFormValueChange, 'published_', true)}
          />
          <MultiAutocompleteField
            className="w-12 specto-border border-round border-1 p-3"
            labelClassName="line-height-3 font-bold"
            itemTemplate={(item) =>
              GenericItemTemplate({
                id: item.id,
                itemName: 'topic',
                allOptions: topics,
                showRetiredIcon: true
              })
            }
            fieldName="topics"
            label="Topics"
            control={control}
            options={topics}
            optionValue="id"
            optionLabel="name"
            onChange={(e) => {
              onFormValueChange({ topics: e });
            }}
            placeholder="Select Topics or start typing"
            required={false}
            noPadding
          />
          <MultiAutocompleteField
            className="w-12 specto-border border-round border-1 p-3"
            labelClassName="line-height-3 font-bold"
            fieldName="author"
            label="Authors"
            control={control}
            options={authors}
            optionValue="user"
            optionLabel="name_reversed"
            onChange={(e) => onFormValueChange({ author: e })}
            placeholder="Select Authors or start typing"
            required={false}
            noPadding
          />
        </form>
      )}
    </Paper>
  );
}

export default EntityFilterMenu;
