import {
  mainMenu,
  mainMenuNoDashboard,
  superUserMenu,
  MENU_MODE_OVERLAY,
  adminProjectSettingsMenu,
  systemSettingsMenu,
  userSettingsMenu,
  superUserProjectSettingsMenu,
  adminMainMenu,
  adminMainMenuNoDashboard
} from 'features/layout/menus';
import { SpectoMenu, SpectoMenuMode } from 'models/menu-model';
import { SpectoRouter } from 'models/routers-model';
import { mainRouter } from 'routers';
import {
  FULLSCREEN_LAYOUT,
  MAIN_LAYOUT,
  MAIN_NO_DASHBOARD_LAYOUT,
  SUPERUSER_LAYOUT,
  ADMIN_PROJECT_SETTINGS_LAYOUT,
  SUPERUSER_PROJECT_SETTINGS_LAYOUT,
  SYSTEM_SETTINGS_LAYOUT,
  USER_SETTINGS_LAYOUT,
  ADMIN_MAIN_LAYOUT,
  ADMIN_MAIN_NO_DASHBOARD_LAYOUT
} from 'constants/layouts';

type LayoutMap = {
  [key: string]: {
    router: SpectoRouter;
    menu: SpectoMenu;
    menuMode?: SpectoMenuMode;
  };
};

export const layoutMap: LayoutMap = {
  [MAIN_LAYOUT]: {
    menu: mainMenu,
    router: mainRouter
  },
  [MAIN_NO_DASHBOARD_LAYOUT]: {
    menu: mainMenuNoDashboard,
    router: mainRouter
  },
  [ADMIN_MAIN_LAYOUT]: {
    menu: adminMainMenu,
    router: mainRouter
  },
  [ADMIN_MAIN_NO_DASHBOARD_LAYOUT]: {
    menu: adminMainMenuNoDashboard,
    router: mainRouter
  },
  [SUPERUSER_LAYOUT]: {
    menu: superUserMenu,
    router: mainRouter
  },
  [ADMIN_PROJECT_SETTINGS_LAYOUT]: {
    menu: adminProjectSettingsMenu,
    router: mainRouter
  },
  [SUPERUSER_PROJECT_SETTINGS_LAYOUT]: {
    menu: superUserProjectSettingsMenu,
    router: mainRouter
  },
  [SYSTEM_SETTINGS_LAYOUT]: {
    menu: systemSettingsMenu,
    router: mainRouter
  },
  [USER_SETTINGS_LAYOUT]: {
    menu: userSettingsMenu,
    router: mainRouter
  },
  [FULLSCREEN_LAYOUT]: {
    menu: mainMenu,
    router: mainRouter,
    menuMode: MENU_MODE_OVERLAY
  }
};
