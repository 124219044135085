import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { getFormErrorMessage } from 'util/form';
import classnames from 'classnames';
import { Calendar } from 'primereact/calendar';

/**
 * React hook form field for date time
 *
 * @param className classNames that affect the input
 * @param control form control
 * @param defaultValue default value for the field
 * @param fieldName name of the field
 * @param label the title of the field
 * @param labelClassName classnames that affect the label
 * @param noPadding whether the padding should be removed
 * @param placeholder placeholder for the input
 * @param required whether the field is required
 * @component
 */
const DateTimeField = function ({
  className,
  control,
  defaultValue = '',
  fieldName,
  label,
  labelClassName,
  noPadding,
  placeholder,
  required = true
}: {
  className?: string;
  control: Control;
  defaultValue?: string;
  fieldName: string;
  label?: string;
  labelClassName?: string;
  noPadding?: boolean;
  placeholder?: string;
  required?: boolean;
}) {
  return (
    <div className={classnames(className, { field: !noPadding })}>
      {label && (
        <label htmlFor={fieldName + 'input'} className={classnames(labelClassName)}>
          {label} {required && <span className="specto-form-asterisk-color">*</span>}
        </label>
      )}
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        rules={{
          ...(required && { required: 'Required field' })
        }}
        render={({ field, fieldState }) => (
          <>
            <Calendar
              id={field.name}
              inputId={field.name + 'input'}
              value={field.value}
              className="w-full specto-input-number"
              placeholder={placeholder}
              onChange={(e) => field.onChange(e.value)}
              selectionMode="range"
              maxDate={new Date()}
              showTime
              hourFormat="12"
              showIcon
              readOnlyInput
              data-cy={field.name}
            />
            {getFormErrorMessage(fieldState.error, noPadding)}
          </>
        )}
      />
    </div>
  );
};

export default DateTimeField;
