import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';
import { getFormErrorMessage, oneSpaceFormatter } from 'util/form';
import { KeyFilterType } from 'primereact/keyfilter';
import classnames from 'classnames';
import { UseControllerProps } from 'react-hook-form/dist/types/controller';
import Info from './Info';

/**
 * Component that displays a basic text field in a form
 * @param ariaLabel arial label for the input
 * @param autoFocus if the component should autofocus
 * @param characterLimit character limit for the input
 * @param className class name for container
 * @param control Control
 * @param dataCy cypress data label
 * @param defaultValue default value
 * @param disabled if the input is disabled
 * @param fieldName field name for control
 * @param info info tooltip
 * @param keyFilter input key filter
 * @param label input label
 * @param labelClassName label class name
 * @param noPadding whether the component should have padding or not
 * @param onChange onChange callback
 * @param placeholder input placeholder
 * @param readOnly if theinput is readonly
 * @param required if the input is required
 * @param rules custom form rules
 * @param tabIndex input tab index
 * @param tooltip component tooltip
 * @constructor
 */
const TextField = function ({
  ariaLabel,
  autoFocus,
  characterLimit = 200,
  className,
  control,
  dataCy,
  defaultValue = '',
  disabled,
  fieldName,
  info,
  keyFilter,
  label,
  labelClassName,
  noPadding,
  onChange,
  placeholder,
  readOnly,
  required = true,
  rules,
  tabIndex,
  tooltip
}: {
  ariaLabel?: string;
  autoFocus?: boolean;
  characterLimit?: number;
  className?: string;
  control: Control;
  dataCy?: string;
  defaultValue?: string;
  disabled?: boolean;
  fieldName: string;
  info?: string;
  keyFilter?: KeyFilterType;
  label?: string;
  labelClassName?: string;
  noPadding?: boolean;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  rules?: UseControllerProps['rules'];
  tabIndex?: number;
  tooltip?: string;
}) {
  return (
    <div className={classnames(className, { field: !noPadding })}>
      <div className="flex">
        {label && (
          <label htmlFor={fieldName} className={classnames(labelClassName)}>
            {label} {required && <span className="specto-form-asterisk-color">*</span>}
          </label>
        )}
        {info && <Info text={info} />}
      </div>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        rules={{
          ...(required && { required: 'Required field' }),
          maxLength: {
            value: characterLimit,
            message: `Exceeded max length of ${characterLimit} characters`
          },
          ...rules
        }}
        render={({ field, fieldState }) => (
          <>
            <InputText
              maxLength={characterLimit}
              id={field.name}
              {...field}
              ref={null}
              autoFocus={autoFocus}
              data-cy={dataCy}
              placeholder={placeholder}
              onChange={(e) => {
                field.onChange(oneSpaceFormatter(e.target.value));
                e.target.value = String(oneSpaceFormatter(e.target.value));
                onChange?.(e);
              }}
              disabled={disabled}
              keyfilter={keyFilter}
              className={classNames('specto-input-text', {
                'p-invalid': fieldState.error
              })}
              readOnly={readOnly}
              autoComplete="off"
              aria-label={ariaLabel}
              aria-invalid={fieldState?.error ? 'true' : 'false'}
              tabIndex={tabIndex}
              tooltip={tooltip}
              tooltipOptions={{ position: 'bottom' }}
            />
            {getFormErrorMessage(fieldState.error, noPadding)}
          </>
        )}
      />
    </div>
  );
};

export default TextField;
