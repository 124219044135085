import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ToastMessage } from 'primereact/toast';
import { WritableDraft } from 'immer/dist/internal';

export type ToastState = {
  message: string | null;
  severity: ToastMessage['severity'];
  id: number;
  life: number;
};

const initialState: ToastState = {
  message: null,
  severity: 'success',
  id: 0,
  life: 3000
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    showToast: (
      state,
      action: PayloadAction<{ message: string; severity?: ToastMessage['severity']; life?: number }>
    ) => {
      state.message = action.payload.message;
      state.severity = action.payload.severity || initialState.severity;
      state.life = action.payload.life || initialState.life;
      state.id = generateSequentialToastId(state);
    },
    clearToast: (state) => {
      state.message = initialState.message;
      state.severity = initialState.severity;
      state.id = initialState.id;
      state.life = initialState.life;
    }
  }
});

export const toastSelector = (state: RootState) => state.toast;
export const { showToast, clearToast } = toastSlice.actions;

const generateSequentialToastId = (state: WritableDraft<ToastState>) =>
  state.id < Number.MAX_SAFE_INTEGER ? state.id + 1 : 0;

export const createToast = (message: string, severity?: ToastMessage['severity']) =>
  showToast({ message: message, severity: severity });

export default toastSlice.reducer;
