import { UserDataReturnModel, UserModel } from 'models/user-model';
import { ProjectMemberModel, ProjectMemberUserModel, ProjectRole } from 'models/project-model';
import { UserGroupModel } from 'models/user-groups-model';

/**
 *
 * @param user First user
 * @param secondUser Second user
 * @returns Boolean value
 */
export const isSameUser = (
  user: UserModel | null,
  secondUser: UserModel | ProjectMemberModel | null
): boolean => {
  // must both exist. user?.email === secondUser?.email when both are undefined would return true
  return !!user && !!secondUser && user.id === secondUser.id;
};

/**
 *
 * @param allUsers List of all available users
 * @param members List of current project members
 * @returns List of project members with new data structure
 */
export const mapUsersToProjectMembers = (allUsers: UserModel[], members: ProjectMemberModel[]) =>
  members.map((mem) => {
    const foundUser = allUsers.find((usr) => usr.id === mem.user);
    return {
      ...mem,
      first_name: foundUser?.first_name || '',
      last_name: foundUser?.last_name || '',
      email: foundUser?.email || '',
      name: (foundUser?.first_name || '') + ' ' + (foundUser?.last_name || ''),
      name_reversed:
        (foundUser?.last_name || '') +
        (foundUser?.last_name && ', ') +
        (foundUser?.first_name || '')
    };
  });

/**
 *
 * @param projectMembers List of current project members
 * @param user Currently logged in user
 * @returns Found project member
 */
export const findProjectMember = (projectMembers: ProjectMemberUserModel[], user: UserModel) =>
  projectMembers.find((pm) => user.id === pm.user);

/**
 *
 * @param admins List of all admin users
 * @param userGroupIds Array of usergroup ids
 * @returns Newly mapped data structure of admins that matches project members.
 */
export const mapSuperUsersToAuthors = (admins: UserModel[], userGroupIds: UserGroupModel['id'][]) =>
  admins.map((admin) => ({
    email: admin.email,
    first_name: admin.first_name,
    last_name: admin.last_name,
    name: (admin?.first_name || '') + ' ' + (admin?.last_name || ''),
    name_reversed:
      (admin?.last_name || '') + (admin?.last_name && ', ') + (admin?.first_name || ''),
    id: admin.id,
    role: ProjectRole.NEUROSOPH,
    user: admin.id,
    user_groups: userGroupIds
  }));

export const filterAuthors = (
  isSuperUser: boolean,
  authors: ProjectMemberUserModel[],
  id: number | undefined | UserDataReturnModel
) => {
  if (isSuperUser) return authors;
  else return authors.filter((a) => a.role !== ProjectRole.NEUROSOPH || a.user === id);
};

/**
 *
 * @param author Author ID
 * @param projectUser Currently logged in project user
 * @returns Boolean value
 */
export const isNotAuthorOrAdmin = (projectUser: ProjectMemberUserModel, author?: number) =>
  author !== projectUser.user && projectUser.role !== ProjectRole.NEUROSOPH;
