import clip from 'text-clipper';

/***
 * Truncate loosely based on string length but also line number
 * also strips all <br> to the effect of making multi-line breaks just 1 break
 * @param content HTML string content
 * @param maxChars max characters for truncation
 * @param maxLines max lines
 */
export const truncateFuzzy = (content: string, maxChars: number, maxLines = 4) => {
  if (!content) {
    return '';
  }
  // strip break tags leaving just the separating <p>..</p><p>...</p>, which has a linebreak
  content = content.replace(/<p><br><\/p>/g, '');

  // if content (including html tags) are less than 100 chars, simply return
  if (content.length < maxChars) {
    return content;
  }

  // truncate after 100 chars, breaking html tags
  content = content.substring(0, maxChars);

  // clean remove last tag if unclosed HTML
  if (content.lastIndexOf('<') > content.lastIndexOf('>')) {
    content = content.substring(0, content.lastIndexOf('<'));
  }

  // add ellipses after first 100 characters
  content += '...';

  // clip content on 4 lines which may remove ellipses
  content = clip(content, maxChars, { html: true, maxLines });

  // re-add ellipses not present
  // for html that ends in a list (non <p> elements), indent the ellipses
  if (content.slice(-3) !== '...') {
    content += content.slice(-4) == '</p>' ? '...' : '<span class="pl-5">...</span>';
  }

  return content;
};

export function toSentenceCase(inputString: string): string {
  const sentences = inputString.split(/\. |\? |! /);
  const sentenceCaseSentences = [];

  for (const sentence of sentences) {
    const trimmedSentence = sentence.trim();

    if (trimmedSentence.length > 0) {
      const sentenceCaseSentence =
        trimmedSentence.charAt(0).toUpperCase() + trimmedSentence.slice(1).toLowerCase();
      sentenceCaseSentences.push(sentenceCaseSentence);
    }
  }

  return sentenceCaseSentences.join('. ');
}
