import React, { useState } from 'react';
import { IconButtonCell } from 'components/Table';
import classnames from 'classnames';
import { uniqueId } from 'util/uniqueIdGenerator';
import parse from 'html-react-parser';
import { Tooltip } from 'primereact/tooltip';

type RoundNotesCellOptionsModel = {
  action(e: React.MouseEvent<HTMLButtonElement>): void;
  className?: string;
  notes: string;
  editable?: boolean;
};

const NotesCell = ({ notes, action, className, editable = true }: RoundNotesCellOptionsModel) => {
  const [id] = useState(uniqueId('notes-tooltip'));
  const empty = notes.length === 0;

  // when showing an empty component in related records
  if (empty && !editable) {
    return <></>;
  }

  return (
    <>
      <IconButtonCell
        icon={empty ? 'pi-plus' : 'pi-book'}
        action={action}
        className={classnames(
          id,
          {
            'p-button-text': !editable || empty,
            'invisible-button': empty
          },
          className
        )}
      />
      {!empty && (
        <Tooltip target={`.${id}`} position="bottom">
          {parse(notes)}
        </Tooltip>
      )}
    </>
  );
};

export default NotesCell;
