import React from 'react';
import ModalLarge from 'containers/modals/ModalLarge';
import { Link } from 'react-router-dom';
import { routes } from 'constants/routes';

const EmptyConversation = function () {
  return (
    <ModalLarge className="specto-empty-conversation-detail" justify="center" align="center">
      <h4 className="specto-text-muted">No Conversations to display!</h4>
      <h6 className="specto-text-muted mt-0">
        Conversations appear automatically when your database is synced via the&nbsp;
        <Link to={routes.EVENT_DATABASE_MANAGER}>Database Manager</Link>.
      </h6>
    </ModalLarge>
  );
};

export default EmptyConversation;
