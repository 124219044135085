import React, { CSSProperties, useRef, useState } from 'react';
import { Editor, EditorTextChangeEvent } from 'primereact/editor';
import { Quill } from 'react-quill';
import { Delta } from 'quill';
import { uniqueId } from 'util/uniqueIdGenerator';
import { isStringified } from 'util/record';
import { HyperlinkDialog } from './HyperlinkDialog';
import { CustomLink } from './CustomLink';
import './_custom-quill.scss';

// Register extended formats
Quill.register(CustomLink, true);

type RichTextFormProps = {
  defaultValue?: string;
  readOnly?: boolean;
  minHeight?: string | number;
  style?: CSSProperties;
  ref?: any;
  placeholder?: string;
  onTextChange?(e: EditorTextChangeEvent): void;
  editorMaxLength?: number;
  modalMaxLength?: number;
};

const QuillEditor = function ({
  placeholder,
  readOnly = false,
  editorMaxLength,
  modalMaxLength,
  minHeight,
  onTextChange,
  ...field
}: RichTextFormProps) {
  const [editorId] = useState(uniqueId('editor'));
  const quillRef = useRef<Editor>(null);
  const [displayLinkDialog, setDisplayLinkDialog] = useState(false);
  const [hyperlinkHrefDefault, setHyperlinkHrefDefault] = useState('');
  const [hyperlinkTargetDefault, setHyperlinkTargetDefault] = useState('_self');
  const [insertMode, setInsertMode] = useState(false);
  const [showRemoveHyperlink, setShowRemoveHyperlink] = useState(true);

  const customLink = () => {
    const quill = quillRef?.current?.getQuill();
    let range = quill?.getSelection();
    if (range) {
      const text = quill.getText(range.index, range.length);
      const leadingSpaces = text.search(/\S|$/);
      const trailingSpaces = text.length - text.search(/\S\s*$/) - 1;

      // Adjust the range to exclude leading and trailing white spaces
      range = {
        index: range.index + leadingSpaces,
        length: range.length - leadingSpaces - trailingSpaces
      };
    }
    const content: Delta = quill?.getContents(range.index, range.length);
    quill.setSelection(range.index, range.length);

    let firstHyperlink = content?.ops?.find(
      (operation) => operation.attributes && 'link' in operation.attributes
    )?.attributes?.link;

    // no selection, insert hyperlink text
    setInsertMode(range.length === 0);

    // if hyperlink exists, show option to remove it
    setShowRemoveHyperlink(Boolean(firstHyperlink));

    if (firstHyperlink) {
      if (isStringified(firstHyperlink)) {
        firstHyperlink = JSON.parse(firstHyperlink);
      }

      setHyperlinkHrefDefault(firstHyperlink.href);
      setHyperlinkTargetDefault(firstHyperlink.target);
    } else {
      setHyperlinkHrefDefault('');
      setHyperlinkTargetDefault('_self');
    }

    setDisplayLinkDialog(true);
  };

  const modules = {
    toolbar: {
      container: `#${editorId}`,
      handlers: {
        customLink: customLink
      }
    },
    clipboard: {
      matchVisual: false
    }
  };

  function formatCustomLinkText(href: string, target: string, text: string) {
    const quill = quillRef?.current?.getQuill();

    // dialog takes focus from quill editor, so save what was highlighted to operate on it later
    quillRef?.current?.getQuill()?.focus();
    const range = quill?.getSelection();

    if (insertMode) {
      quill.insertText(range.index, text, 'link', {
        href: href,
        target: target
      });
    }

    quill?.formatText(range.index, range.length, 'link', {
      href: href,
      target: target
    });

    // if href is empty, remove link
    if (href === '') {
      quill.formatText(range.index, range.length, 'link', false);
    }
  }

  function formatRemoveHyperlink() {
    const quill = quillRef?.current?.getQuill();

    // dialog takes focus from quill editor, so save what was highlighted to operate on it later
    quillRef?.current?.getQuill()?.focus();
    const range = quill?.getSelection();
    const content: Delta = quill?.getContents(range.index, range.length);

    // if link exists in selection, remove link
    if (content?.ops?.some((operation) => operation.attributes && 'link' in operation.attributes)) {
      quill.formatText(range.index, range.length, 'link', false);
    }
  }

  return (
    <>
      <HyperlinkDialog
        visible={displayLinkDialog}
        defaultValueHref={hyperlinkHrefDefault}
        defaultValueTarget={hyperlinkTargetDefault}
        accept={formatCustomLinkText}
        maxLength={modalMaxLength}
        reject={showRemoveHyperlink ? formatRemoveHyperlink : undefined}
        onHide={() => setDisplayLinkDialog(false)}
        insertMode={insertMode}
      />
      <Editor
        theme="snow"
        id={String(editorId)}
        className="border-none p-0"
        headerTemplate={EditorHeaderTemplate(editorId)}
        placeholder={placeholder}
        style={{ minHeight }}
        maxLength={editorMaxLength}
        modules={modules}
        readOnly={readOnly}
        {...field}
        ref={quillRef}
        onTextChange={onTextChange}
      />
    </>
  );
};

const EditorHeaderTemplate = (id: string) => (
  <div id={`toolbar-${id}`} className="flex align-items-center h-3rem">
    <span className="ql-formats" aria-label="header size">
      <select className="ql-header" defaultValue="">
        <option value="">Normal</option>
        <option value="1">Heading 1</option>
        <option value="2">Heading 2</option>
        <option value="3">Heading 3</option>
        <option value="4">Heading 4</option>
        <option value="5">Heading 5</option>
        <option value="6">Heading 6</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" aria-label="Bold" />
      <button className="ql-italic" aria-label="Italic" />
      <button className="ql-underline" aria-label="Underline" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" aria-label="Color" />
      <button className="ql-list" value="bullet" aria-label="Color" />
    </span>
    <span className="ql-formats">
      <button className="ql-customLink" title="Link" aria-label="link">
        <i className="pi pi-link" />
      </button>
    </span>
  </div>
);

export default QuillEditor;
