import { DependencyList, useState } from 'react';
import usePaginate from 'hooks/usePaginate';
import { QueryParameters } from 'models/api-model';
import { buildQueryString } from 'util/api-response';
import { filterUndefinedRecordValues } from 'util/record';

type useQueryOptions = {
  deps?: DependencyList;
  query(text: string): void;
  defaultValues?: Record<string, any>;
  initialValues?: Record<string, any>;
};

/**
 * Hook to encapsulate search text, query text and a debounced query function
 * @param options :
 * query: query function
 * deps: query function dependencies (what changed values should cause re-render/update).
 *  This is usually set to a changing value used in the query function
 */
const useQuery = (options?: useQueryOptions) => {
  // this const must be inside the hook or else state will mutate between evocations
  const defaultQueryParameters: QueryParameters = {
    limit: 5,
    offset: 0
  };

  const defaultValues = filterUndefinedRecordValues<QueryParameters>(
    Object.assign(defaultQueryParameters, options?.defaultValues)
  );

  const initialValues = { ...defaultValues, ...options?.initialValues };

  const paginate = usePaginate(defaultValues);
  const [queryParameters, setAllQueryParameters] = useState<QueryParameters>(initialValues);
  const [queryString, setQueryString] = useState(buildQueryString(initialValues));
  const [searchText, setSearchText] = useState(options?.initialValues?.search ?? '');

  function clearSearch() {
    setSearchText('');
  }

  function clearAllQueryParameters(value?: QueryParameters) {
    const resetValues = filterUndefinedRecordValues<QueryParameters>({
      ...defaultQueryParameters,
      ...value
    });
    setAllQueryParameters(resetValues);
    setQueryString(buildQueryString(resetValues));
  }

  function clearQueryParameter(...parameterList: string[]) {
    const parameters = { ...queryParameters };

    parameterList.forEach((parameter) => {
      if (parameter in defaultValues) {
        parameters[parameter] = defaultValues[parameter];
      } else {
        delete parameters[parameter];
      }
    });

    setAllQueryParameters(parameters);
    setQueryString(buildQueryString(parameters));
  }

  function search(text: string) {
    setSearchText(text);
    options?.query(text);
  }

  function simpleSearch(text: string) {
    setSearchText(text);
  }

  /**
   * Set all meta fields. This clears all previous metadata each time
   * @param metaParameters meta fields to query with
   * @param keyword meta prefix keyword
   */
  function setAllMetaFields(metaParameters: QueryParameters, keyword = 'meta__') {
    // get copy of parameters
    const parameters = { ...queryParameters };

    // get meta__ parameters
    const metaFields = Object.keys(queryParameters).filter((parameter) =>
      parameter.startsWith(keyword)
    );

    // delete meta fields from parameters
    metaFields.forEach((metaField) => {
      delete parameters[metaField];
    });

    const combinedParameters = Object.assign(parameters, metaParameters);

    setQueryParameter(combinedParameters, true);
  }

  function setQueryParameter(parameter: QueryParameters, all = false) {
    const newQueryParameters = filterUndefinedRecordValues<QueryParameters>({
      ...(!all && queryParameters),
      ...parameter
    });
    setAllQueryParameters(newQueryParameters);
    setQueryString(buildQueryString(newQueryParameters));
  }

  /**
   * useQuery re-implementation of the setOffset for pagination to allow for triggering query param
   * @param offset input for pagination.setOffset
   * @param triggerQuery whether to trigger a query by setting the query parameter
   */
  function setOffset(offset: number, triggerQuery?: boolean) {
    paginate.setOffset(offset);

    triggerQuery && setQueryParameter({ offset });
  }

  /**
   * useQuery re-implementation of the setLimit for pagination to allow for triggering query param
   * @param limit input for pagination.setLimit
   * @param triggerQuery whether to trigger a query by setting the query parameter
   */
  function setLimit(limit: number, triggerQuery?: boolean) {
    paginate.setLimit(limit);

    triggerQuery && setQueryParameter({ limit });
  }

  return {
    clearSearch,
    search,
    searchText,
    queryString,
    queryParameters,
    simpleSearch,
    clearAllQueryParameters,
    clearQueryParameter,
    setQueryParameter,
    setAllMetaFields,
    paginate: {
      ...paginate,
      setLimit,
      setOffset
    }
  };
};

export default useQuery;
