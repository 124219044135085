import React from 'react';
import {
  FieldArrayWithId,
  FieldValues,
  UseFieldArrayAppend,
  UseFieldArrayMove,
  UseFieldArrayRemove,
  UseFormReturn
} from 'react-hook-form';

export type EntityFieldArrayValues = {
  id?: string;
  entity?: number | null;
  start?: number;
  end?: number;
  role: string | null;
  group: number | null;
};

/**
 * Individually typed the useFieldArray methods because there will be multiple useFieldArray
 * instances and needed to change the names to avoid clashing (i.e. "append" method from buttons and
 * "append" method from dropdown.)
 */
export interface EntityFormContextModel extends UseFormReturn {
  appendEntity: UseFieldArrayAppend<FieldValues, 'entities'>;
  removeEntity: UseFieldArrayRemove;
  entityFields: FieldArrayWithId<FieldValues, string, string>[];
  moveEntity: UseFieldArrayMove;
}

export const EntityFormContext = React.createContext<EntityFormContextModel>(
  {} as EntityFormContextModel
);
