import { CheckboxComponent } from 'components/Forms/CheckboxField';
import { MultiAutocomplete } from 'components/Forms/MultiAutocompleteField';
import _ from 'lodash';
import { ProjectMemberUserModel } from 'models/project-model';
import { UserGroupModel } from 'models/user-groups-model';
import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { uniqueId } from 'util/uniqueIdGenerator';

/**
 * Field for filtering by user groups
 *
 * @param control control
 * @param userGroups user groups
 * @param onChange on change callback
 * @param projectUser The current project user.
 * @param fieldName Name of field used in Control
 * @param addNoneUserGroup whether to add None as a User Group option
 * @param disabled whether the field is disabled or not
 * @param tooltipTitle Option title for tooltip
 * @param onlyShowTooltipOnDisabled Displays the tooltip only when the field is disabled
 * @returns
 */
const UserGroupFilterField = function ({
  control,
  userGroups,
  onChange,
  projectUser,
  fieldName = 'user_groups',
  addNoneUserGroup = true,
  disabled = false,
  tooltipTitle = '',
  onlyShowTooltipOnDisabled = false
}: {
  control: Control;
  userGroups: UserGroupModel[];
  onChange: (value: number[]) => void;
  projectUser: ProjectMemberUserModel;
  fieldName?: string;
  addNoneUserGroup?: boolean;
  disabled?: boolean;
  tooltipTitle?: string;
  onlyShowTooltipOnDisabled?: boolean;
}) {
  const userGroupOptions = addNoneUserGroup
    ? [{ id: 0, name: 'None', num_projects: 0 }, ...userGroups]
    : userGroups;
  const multiSelectInputId = uniqueId('user-group-filter-input');
  const checkboxInputId = uniqueId('user-group-filter-checkbox');

  return (
    <div className="w-12 specto-border border-round border-1 p-3">
      <label className="line-height-3 font-bold">User Groups:</label>
      {((tooltipTitle && !onlyShowTooltipOnDisabled) ||
        (tooltipTitle && onlyShowTooltipOnDisabled && disabled)) && (
        <>
          <Tooltip content={tooltipTitle} target={`#${multiSelectInputId}`} position="top" />
          <Tooltip content={tooltipTitle} target={`#${checkboxInputId}`} position="top" />
        </>
      )}
      <Controller
        name={fieldName}
        control={control}
        render={({ field, fieldState }) => (
          <div>
            {projectUser?.user_groups?.length > 0 ? (
              <span id={checkboxInputId}>
                <CheckboxComponent
                  field={field}
                  label="Show only mine"
                  handleChange={(_e, checked) => {
                    const groups = checked ? projectUser.user_groups : [];
                    onChange(groups);
                    return groups;
                  }}
                  checked={(values) => _.xor(values, projectUser.user_groups).length === 0}
                  disabled={disabled}
                />
              </span>
            ) : undefined}
            <div className="pt-2">
              <label htmlFor={multiSelectInputId}>All User Groups</label>
              <MultiAutocomplete
                field={field}
                fieldState={fieldState}
                inputId={multiSelectInputId}
                options={userGroupOptions}
                optionLabel="name"
                optionValue="id"
                placeholder="Select User Groups or start typing"
                handleValueChanged={(_old, value) => {
                  onChange(value);
                  return value;
                }}
                disabled={disabled}
              />
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default UserGroupFilterField;
