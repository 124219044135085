import React from 'react';
import { Controller } from 'react-hook-form';
import { InputSwitchChangeEvent } from 'primereact/inputswitch';
import classnames from 'classnames';
import Info from './Info';
import ToggleDividedButton from './ToggleDividedButton';

/**
 * Component that displays a basic text field in a form.
 * @component
 * @param param0 Prop object containing the following:
 *    className: class name
 *    control: react-hook-form control
 *    defaultValue: the default value
 *    disabled: if the input is disabled
 *    falseValue: value the toggle returns when not checked
 *    fieldName: the field name in react-hook-form
 *    info: info tooltip
 *    inlineLabel: if the label should be inline
 *    label: label for the input
 *    noPadding: if default padding should be kept
 *    onChange: onChange callback
 *    trueValue: value the toggle returns when checked
 */
const ToggleSwitch = function ({
  ariaLabel,
  ariaDescribedBy,
  className,
  control,
  defaultValue,
  disabled = false,
  falseLabel = 'DISABLE',
  falseValue = false,
  fieldName,
  info,
  inlineLabel = false,
  label,
  noPadding,
  trueLabel = 'ENABLE',
  trueValue = true
}: {
  ariaLabel?: string;
  ariaDescribedBy?: string;
  className?: string;
  control: any;
  defaultValue?: boolean;
  disabled?: boolean;
  falseLabel?: string;
  falseValue?: any;
  fieldName: string;
  info?: string;
  inlineLabel?: boolean;
  label?: string;
  noPadding?: boolean;
  onChange?(e: InputSwitchChangeEvent): void;
  trueLabel?: string;
  trueValue?: any;
}) {
  return (
    <div className={classnames(className, { field: !noPadding })}>
      {!inlineLabel && (
        <div className="flex">
          {label && <label htmlFor={fieldName}>{label}</label>}
          {info && <Info text={info} />}
        </div>
      )}
      <div className={classnames({ 'flex align-content-center align-items-center': inlineLabel })}>
        {label && inlineLabel && (
          <label htmlFor={fieldName} className="pr-2">
            <h6 className="mb-0">{label}</h6>
          </label>
        )}
        {info && <Info text={info} />}
        <Controller
          name={fieldName}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <ToggleDividedButton
              checked={field.value}
              trueLabel={trueLabel}
              falseLabel={falseLabel}
              trueValue={trueValue}
              falseValue={falseValue}
              onChange={(e) => field.onChange(e.value)}
              disabled={disabled}
              ariaLabel={ariaLabel}
              ariaDescribedBy={ariaDescribedBy ?? fieldName}
            />
          )}
        />
      </div>
    </div>
  );
};

export default ToggleSwitch;
