import React, { useCallback } from 'react';
import { Control, FieldValues, UseFormGetValues } from 'react-hook-form';
import { Paper } from '@mui/material';
import LoadingSpinner from 'components/LoadingSpinner';
import classnames from 'classnames';
import { blockKeyboardOnLoad } from 'util/keyboard';
import UserGroupFilterField from 'components/UserGroups/UserGroupsFilterField';
import { UserGroupModel } from 'models/user-groups-model';
import { ProjectMemberUserModel } from 'models/project-model';
import MultiAutocompleteField from 'components/Forms/MultiAutocompleteField';
import { GenericItemTemplate } from 'components/Table';
import { sortbyProperty } from 'util/arrays';
import { ResponseTableDataModel } from 'models/response-table-data-model';
import { debounce } from 'lodash';
import { NumberField } from 'components/Forms';
import { formatDateISOEnd, formatDateISOStart } from 'util/dates';

type ConversationFilterProps = {
  control: Control;
  disableStatus?: boolean;
  loading?: boolean;
  onFormValueChange(data: FieldValues): void;
  projectUser: ProjectMemberUserModel;
  responses: ResponseTableDataModel[];
  userGroups: UserGroupModel[];
  authors: ProjectMemberUserModel[];
  filterQueryString: string;
  getFilterValues: UseFormGetValues<FieldValues>;
  searchText: string;
};

/**
 *
 * @param control Control
 * @param loading whether the form is in the process of submitting
 * @param onFormValueChange onChange callback function
 * @param projectUser Current project user
 * @param userGroups List of User Groups
 * @param responses List of responses
 * @param authors List of current project members
 * @param filterQueryString current query string used for search
 * @param getFilterValues gets the current values for the conversations filter
 * @param searchText the text in the search bar
 *
 * @component
 */
const ConversationsFilterMenu = ({
  control,
  loading,
  onFormValueChange,
  projectUser,
  responses,
  userGroups,
  authors,
  filterQueryString,
  getFilterValues,
  searchText
}: ConversationFilterProps) => {
  const debouncedChangeHandler = useCallback(debounce(onFormValueChange, 500), [filterQueryString]);

  return (
    <Paper className="w-30rem" onKeyDown={(e) => blockKeyboardOnLoad(e, loading)} tabIndex={0}>
      <LoadingSpinner enabled={loading} style={{ top: '38%', left: '42%' }} />
      <form
        className={classnames('flex flex-wrap overflow-y-auto gap-3 p-3', {
          'pointer-events-none': loading
        })}
        onSubmit={(e) => {
          e.preventDefault();
        }}
        style={{ maxHeight: '70vh' }}
      >
        <MultiAutocompleteField
          className="w-12 specto-border border-round border-1 p-3"
          labelClassName="line-height-3 font-bold"
          fieldName="author"
          label="Authors"
          control={control}
          options={authors}
          optionValue="user"
          optionLabel="name_reversed"
          onChange={(e) => onFormValueChange({ author: e })}
          placeholder="Select Authors or start typing"
          required={false}
          noPadding
          disabled={Boolean(getFilterValues('conversation'))}
          tooltipTitle="Remove Conversation ID filter to enable filtering by Authors."
          onlyShowTooltipOnDisabled
        />

        <NumberField
          label="Conversation ID"
          placeholder="Search by Conversation ID"
          fieldName="conversation"
          labelClassName="line-height-3 font-bold"
          className="w-12 specto-border border-round border-1 p-3"
          control={control}
          onChange={(e) => {
            if (e.value)
              debouncedChangeHandler({
                conversation: e.value?.toString(),
                timestamp_lte: '',
                timestamp_gte: '',
                author: [],
                user_group: [],
                response: [],
                search: ''
              });
            else
              debouncedChangeHandler({
                conversation: e.value?.toString(),
                timestamp_lte: formatDateISOEnd(getFilterValues('timestamp_lte')),
                timestamp_gte: formatDateISOStart(getFilterValues('timestamp_gte')),
                author: getFilterValues('author'),
                user_group: getFilterValues('user_group'),
                response: getFilterValues('response'),
                search: searchText
              });
          }}
          required={false}
          showButtons={false}
          noPadding
        />

        <UserGroupFilterField
          addNoneUserGroup={false}
          fieldName="user_group"
          control={control}
          userGroups={userGroups}
          onChange={(values) => onFormValueChange({ user_group: values })}
          projectUser={projectUser}
          disabled={Boolean(getFilterValues('conversation'))}
          tooltipTitle="Remove Conversation ID filter to enable filtering by User Groups."
          onlyShowTooltipOnDisabled
        />

        <MultiAutocompleteField
          className="w-12 specto-border border-round border-1 p-3"
          labelClassName="line-height-3 font-bold"
          itemTemplate={(item) =>
            GenericItemTemplate({
              id: item.id,
              itemName: 'response',
              allOptions: responses,
              showRetiredIcon: true
            })
          }
          fieldName="response"
          label="Responses"
          control={control}
          options={sortbyProperty([...responses], 'name')}
          optionValue="id"
          optionLabel="name"
          onChange={(e) => {
            onFormValueChange({ response: e });
          }}
          placeholder="Select Responses or start typing"
          required={false}
          noPadding
          disabled={Boolean(getFilterValues('conversation'))}
          tooltipTitle="Remove Conversation ID filter to enable filtering by Responses."
          onlyShowTooltipOnDisabled
        />
      </form>
    </Paper>
  );
};

export default ConversationsFilterMenu;
