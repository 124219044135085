import { Checkbox, FormControlLabel } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { Control, Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { getFormErrorMessage } from 'util/form';

/**
 * Field for a single checkbox.
 *
 * @param checked whether checkbox is checked or not
 * @param control react-hook-form control.
 * @param defaultValue Default value for checkbox.
 * @param fieldName The field name in react-hook-form.
 * @param handleChange Callback function to handle checkbox value change
 * @param label Label for the checkbox.
 * @param required If the checkbox is required.
 * @param padding Whether padding styling is added or not to checkbox
 * @param required Whether input on the checkbox is needed or not
 *
 * @component
 */
const CheckboxField = function ({
  checked,
  control,
  defaultValue = false,
  fieldName,
  handleChange,
  label,
  padding = true,
  required = false
}: {
  checked?: (value: any) => boolean;
  control: Control;
  defaultValue?: boolean;
  fieldName: string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => any;
  label: string;
  padding?: boolean;
  required?: boolean;
}) {
  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={defaultValue}
      rules={{
        ...(required && { required: 'Required field' })
      }}
      render={({ field, fieldState }) => (
        <div className={classNames('ml-01rem', { 'mb-2': padding })}>
          <CheckboxComponent
            field={field}
            label={label}
            handleChange={handleChange}
            checked={checked}
          />
          {getFormErrorMessage(fieldState.error, !padding)}
        </div>
      )}
    />
  );
};

/**
 * Mui Checkbox component.
 *
 * @param checked whether checkbox is checked or not
 * @param handleChange Callback function to handle checkbox value change
 * @param label Label for the checkbox.
 * @param field the value of the checkbox (checked or not)
 * @param disabled whether checkbox is disabled or not
 *
 * @component
 */
export const CheckboxComponent = function ({
  field,
  label,
  handleChange,
  checked,
  disabled = false
}: {
  field: ControllerRenderProps<FieldValues, any>;
  label: string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => any;
  checked?: (value: any) => boolean;
  disabled?: boolean;
}) {
  return (
    <FormControlLabel
      className="material-checkbox"
      control={
        <Checkbox
          {...field}
          disableFocusRipple
          disableTouchRipple
          disableRipple
          className="mr-2"
          checked={checked ? checked(field.value) : field.value}
          onChange={(e, checked) => field.onChange(handleChange ? handleChange(e, checked) : e)}
          disabled={disabled}
        />
      }
      label={label}
    />
  );
};

export default CheckboxField;
