import { Button } from 'primereact/button';
import classnames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { PaginatorTemplate } from 'primereact/paginator';
import { rowCountOptions } from 'constants/tableConstants';

const paginatorTemplate: PaginatorTemplate = {
  layout: 'PrevPageLink FirstPageLink PageLinks LastPageLink NextPageLink RowsPerPageDropdown',
  PrevPageLink: (options) => (
    <Button
      type="button"
      label="previous"
      text
      icon="pi pi-chevron-left"
      onClick={(e) => clickWithScroll(e, options.onClick)}
      disabled={options.disabled}
    />
  ),
  NextPageLink: (options) => (
    <Button
      type="button"
      label="next"
      text
      icon="pi pi-chevron-right"
      iconPos="right"
      onClick={(e) => clickWithScroll(e, options.onClick)}
      disabled={options.disabled}
    />
  ),
  PageLinks: (options) => {
    if (
      (options.view.startPage === options.page && options.view.startPage !== 0) ||
      (options.view.endPage === options.page && options.page + 1 !== options.totalPages)
    ) {
      return (
        <span
          className={classnames(options.className, { 'p-disabled': true })}
          style={{ userSelect: 'none' }}
        >
          ...
        </span>
      );
    }

    return (
      <Button
        label={String(options.page + 1)}
        type="button"
        className={options.className}
        onClick={(e) => clickWithScroll(e, options.onClick)}
      />
    );
  },
  FirstPageLink: (options) => (
    <Button
      type="button"
      label="first"
      text
      onClick={(e) => clickWithScroll(e, options.onClick)}
      disabled={options.disabled}
    />
  ),
  LastPageLink: (options) => (
    <Button
      type="button"
      label="last"
      text
      onClick={(e) => clickWithScroll(e, options.onClick)}
      disabled={options.disabled}
    />
  ),
  RowsPerPageDropdown: (options) => (
    <div className="flex align-content-center align-items-baseline">
      <Dropdown
        aria-label="rows per page"
        value={options.value}
        options={rowCountOptions}
        onChange={options.onChange}
      />
    </div>
  )
};

const clickWithScroll = (e: React.MouseEvent, fn: (e: React.MouseEvent) => void) => {
  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 500);
  fn(e);
};

export default paginatorTemplate;
