import React, { useContext } from 'react';
import { Button } from 'primereact/button';
import { ResponseFormContext } from './ResponseFormContext';
import { useFieldArray } from 'react-hook-form';
import { getDefaultResponseContent } from 'constants/responses';
import { TextField } from 'components/Forms';
import ResponseComponentContainer from './ResponseComponentContainer';
import RemovableFormField from './RemovableFormField';
import { DragSort, SortableFormComponent } from 'features/drag-sort';
import { reorderResponseFields } from 'util/response';
import { ResponseTypeOptions } from 'models/response-table-data-model';
import Info from 'components/Forms/Info';

/**
 * Component that displays dynamic fields to define a "multiselect"
 * @component
 */
const ResponseMultiselect = function ({ componentIndex }: { componentIndex: number }) {
  const baseField = `content.${componentIndex}`;
  const contentField = `${baseField}.content`;
  const { control, moveField, getValues } = useContext(ResponseFormContext);
  const { append, remove, fields, replace } = useFieldArray({
    control,
    name: `${contentField}.elements` as const
  });

  const onReorder = (components: SortableFormComponent[]) =>
    replace(
      reorderResponseFields(
        components,
        fields,
        getValues()?.content?.[componentIndex]?.content.elements
      )
    );

  return (
    <ResponseComponentContainer
      className="specto-response-multiselect-dropdown-container"
      componentIndex={componentIndex}
      title="Multiselect"
      key={componentIndex}
      move={moveField}
    >
      <div className="specto-response-multiselect-dropdown w-full">
        <div className="grid">
          <div className="col-12">
            <TextField
              label="Placeholder Text"
              fieldName={`${contentField}.placeholder`}
              required={true}
              control={control}
              info="The text that will appear on the dropdown when no option is selected"
            />
          </div>
          <div className="flex col col-offset-1 pb-0">
            <h6 className="mb-0">Label</h6>
            <Info text="The text that will appear on the option" />
          </div>
        </div>
        <DragSort onReorder={onReorder}>
          {fields.map((item, index) => (
            <RemovableFormField
              disabled={fields.length < 2}
              key={item.id}
              onRemove={() => remove(index)}
              roundDeleteButton
            >
              <TextField
                /**
                 * Name needs to be "[useFormArrayName].[index].[fieldname]" to properly map field
                 * data to the final useFormArray data object
                 */
                className="col flex flex-wrap align-items-center"
                fieldName={`${contentField}.elements.${index}.label`}
                control={control}
                noPadding
                placeholder="Enter text to appear on option"
                ariaLabel={`row ${index} label`}
              />
            </RemovableFormField>
          ))}
        </DragSort>
        <div className="flex justify-content-end ml-5">
          <Button
            className="font-semibold specto-text-medium p-button-text underline text-sm py-2 w-auto"
            label="ADD ITEM"
            type="button"
            onClick={() =>
              append(getDefaultResponseContent(ResponseTypeOptions.MULTISELECT).elements[0])
            }
            data-cy="add-multiselect-option"
          />
          <div className="col-2"></div>
        </div>
      </div>
    </ResponseComponentContainer>
  );
};

export default ResponseMultiselect;
