import ApiService from 'services/apiService';
import {
  CONVERSATION_DELETE_MESSAGE_API_URL,
  CONVERSATION_TAGS_API_URL,
  CONVERSATIONS_API_URL
} from 'constants/api';
import {
  ConversationDetailDeleteMessagePutRequestAPIModel,
  ConversationDetailModel,
  ConversationDetailTagsModel,
  ConversationModel,
  ConversationTagsPutRequestAPIModel,
  DeleteConversationDetailMessageOptionsModel,
  GetConversationsOptionsModel,
  PutConversationTagsOptionsModel
} from 'models/conversation-model';
import { Paginated, SlugIdOptionsModel } from 'models/api-model';
import { ProjectRole } from 'models/project-model';
import { Permissions } from 'models/permission-model';

/**
 * Get a list of conversations
 */
const getConversations = (options: GetConversationsOptionsModel) =>
  ApiService.get<Paginated<ConversationModel>>(
    '/' + options.slug + CONVERSATIONS_API_URL + options.query
  );

/**
 * Get conversation details
 * @param options slug for conversation url
 */
const getConversationDetails = (options: SlugIdOptionsModel) =>
  ApiService.get<ConversationDetailModel>(
    '/' + options.slug + CONVERSATIONS_API_URL + options.id + '/'
  );

const deleteConversation = (options: SlugIdOptionsModel) =>
  ApiService.remove('/' + options.slug + CONVERSATIONS_API_URL + options.id + '/');

const deleteConversationDetailMessage = (options: DeleteConversationDetailMessageOptionsModel) =>
  ApiService.put<never, ConversationDetailDeleteMessagePutRequestAPIModel>(
    '/' + options.slug + CONVERSATIONS_API_URL + options.id + CONVERSATION_DELETE_MESSAGE_API_URL,
    { message: options.message }
  );

const editConversationTags = (options: PutConversationTagsOptionsModel) =>
  ApiService.put<ConversationDetailTagsModel, ConversationTagsPutRequestAPIModel>(
    '/' + options.slug + CONVERSATIONS_API_URL + options.id + CONVERSATION_TAGS_API_URL,
    options.tags
  );

const conversationsServiceRoles: Permissions = {
  list: [ProjectRole.NEUROSOPH],
  retrieve: [ProjectRole.NEUROSOPH],
  update: [ProjectRole.NEUROSOPH],
  delete: [ProjectRole.NEUROSOPH]
};

const ConversationsService = {
  getConversationDetails,
  getConversations,
  deleteConversation,
  deleteConversationDetailMessage,
  editConversationTags,
  roles: conversationsServiceRoles
};

export default ConversationsService;
