import React, { useEffect, useMemo, useState } from 'react';
// prime react
import { Button } from 'primereact/button';
// components
import SearchBar from 'components/SearchBar';
import IntentDataTable from './components/IntentDataTable';
import IntentPopup from './components/IntentPopup';
import IntentFilterMenu from './components/IntentFilterMenu';
// constants
import { FetchStatusOptions } from 'constants/fetchStatus';
import { DefaultIntentCategories } from 'constants/tableConstants';
// models
import { IntentTableAPIModel, IntentTableDataModel } from 'models/intent-table-data-model';
// hooks
import { useAppDispatch, useAppSelector } from 'hooks/store';
import useFetch from 'hooks/useFetch';
// services
import IntentsService from 'services/intentsService';
// selectors
import { setLayout } from 'features/layout/layoutSlice';
import {
  addIntent as addIntentState,
  categoriesSelector,
  deleteIntent as deleteIntentState,
  editIntent as editIntentState,
  intentsSelector,
  projectMembersSelector,
  projectsSelector,
  topicsSelector,
  userGroupsSelector,
  usersSelector,
  contentFiltersSelector,
  updateContentFilters,
  dirtyFilters,
  filtersDirtySelector
} from 'features/projects/projectsSlice';
import { SlugDataIdOptionsModel, SlugDataOptionsModel, SlugIdOptionsModel } from 'models/api-model';
import { versionsSelector } from 'features/versions/versionsSlice';
import { createToast } from 'features/toast/toastSlice';
// utils
import { FilterChips } from 'components/FilterChips/FilterChips';
import { FilterChip } from 'models/filter-chips';
import { copyNameFormatter } from 'util/uniqueIdGenerator';
import { ThreadStateChangeEvent } from 'pages/Comments/CommentPopup';
import { FormMode } from 'models/form-model';
import { FieldValues, useForm } from 'react-hook-form';
import useQuery from 'hooks/useQuery';
import { humanReadableDateTimeFormatted } from 'util/dates';
import { PublishState, Status } from 'models/status-model';
import { filterDefault } from 'util/default';
import { isAdmin, permissionBoolean } from 'util/permissions';
import CommentsService from 'services/commentsService';
import { getMainLayout } from 'util/layout';
import { statusDisplayNames } from 'constants/status';
import { DataTableStateEvent } from 'primereact/datatable';
import { getDefaultTableState, mapTableStateToPagination } from 'util/table';
import { getUserGroupIds } from 'util/userGroups';
import { mapIdToName } from 'util/mapIdAndName';
import { authSelector } from 'features/auth/authSlice';
import { ProjectMemberUserModel } from 'models/project-model';
import {
  filterAuthors,
  findProjectMember,
  mapSuperUsersToAuthors,
  mapUsersToProjectMembers
} from 'util/users';
import { isArrayAndIncludes, sortbyProperty } from 'util/arrays';
import { useSearchParams } from 'react-router-dom';
import { parseJson } from 'util/query';
import IntentHistoryPopup from './components/IntentHistoryPopup';
import {
  getPersistedFilters,
  populateFilterMenu,
  buildMultipleChips,
  getAppliedFilters
} from 'util/filters';

const Intent = () => {
  const dispatch = useAppDispatch();
  const { selectedProject, projects } = useAppSelector(projectsSelector);
  const { isVersionSelected } = useAppSelector(versionsSelector);
  const allIntents = useAppSelector(intentsSelector);
  const allCategories = useAppSelector(categoriesSelector);
  const allUserGroups = useAppSelector(userGroupsSelector);
  const allTopics = useAppSelector(topicsSelector);
  const allProjectMembers = useAppSelector(projectMembersSelector);
  const allUsers = useAppSelector(usersSelector);
  const contentFilters = useAppSelector(contentFiltersSelector);
  const { user } = useAppSelector(authSelector);
  const filtersDirty = useAppSelector(filtersDirtySelector);
  const [displayNewIntentPopup, setDisplayNewIntentPopup] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [contextIntent, setContextIntent] = useState<IntentTableDataModel | null>(null);
  const [popupMode, setPopupMode] = useState<FormMode>(FormMode.CREATE);
  const [tablestate, setTableState] = useState<DataTableStateEvent>(getDefaultTableState());
  const validQueryParams = ['status', 'topics', 'author', 'user_groups', 'active', 'published_lte'];
  const [searchParams] = useSearchParams();

  const filterInitialValues = useMemo(() => {
    const params: { [key: string]: any } = {};
    if (searchParams.size === 0) {
      // When the page is first loaded, User Group filters may already be applied based on the Project Member
      // If the user clicked on clear button on any page, then do not set the user groups
      // filtersDirty checks if the user modified the filters or if the filters were pre applied from the store
      if (!filtersDirty) {
        params.user_groups = isAdmin(selectedProject.project_user.role)
          ? []
          : selectedProject.project_user.user_groups;
      }
      getPersistedFilters(params, contentFilters);
    } else {
      searchParams.forEach((value, key) => {
        if ([...validQueryParams, 'search'].includes(key)) {
          params[key] = parseJson(value);
        }
      });
      // Clear query params after consuming
      window.history.replaceState(null, '', window.location.pathname);
    }
    return params;
  }, [searchParams]);

  // Find admins from users and convert them to the same object as author
  const superUserAuthors: ProjectMemberUserModel[] = mapSuperUsersToAuthors(
    allUsers.filter((u) => u.is_superuser),
    allUserGroups.map((ug) => ug.id)
  );

  // Map users data to fit authors data structure
  const authors: ProjectMemberUserModel[] = mapUsersToProjectMembers(allUsers, allProjectMembers);

  // List of all authors including super uers
  const allAuthors: ProjectMemberUserModel[] = sortbyProperty(
    authors.concat(superUserAuthors),
    'name_reversed'
  );

  // Get currently logged in project user as a project user
  const ownProjectUser = findProjectMember(allAuthors, user) as ProjectMemberUserModel;

  // Exclude Default category so users cannot create intents with this category
  const availableCategories = allCategories.filter(
    (category) => category.name !== DefaultIntentCategories.DEFAULT
  );

  /* - - - - - - - - - - Init - - - - - - - - - - */

  useEffect(() => {
    dispatch(setLayout(getMainLayout(selectedProject.project_user.role, projects.length)));
    if (searchParams.size === 0) {
      if (!filtersDirty) {
        // Initial value of the filter form for user_groups is different than the default value
        setFilterValue(
          'user_groups',
          isAdmin(selectedProject.project_user.role) ? [] : selectedProject.project_user.user_groups
        );
      }
    } else {
      searchParams.forEach((value, key) => {
        if (validQueryParams.includes(key)) {
          setFilterValue(key, parseJson(value));
        }
      });
    }
    populateFilterMenu(validQueryParams, filterInitialValues, setFilterValue, Status, PublishState);
  }, []);

  /* - - - - - - - - - - Get All Intents - - - - - - - - - - */
  const {
    fetch: getIntents,
    data: getIntentsData,
    fetchStatus: getIntentsStatus
  } = useFetch(IntentsService.getPaginatedIntents, IntentsService.roles.retrieve);

  function getFilteredIntents() {
    getIntents({ slug: selectedProject.slug, query: filterQueryString });
  }

  /* - - - - - - - - - - Get Intent - - - - - - - - - - */

  const {
    fetch: getIntent,
    data: getIntentData,
    fetchStatus: getIntentStatus
  } = useFetch(IntentsService.getIntent, IntentsService.roles.retrieve);

  // update state with modified state after onThreadStateChange event
  useEffect(() => {
    if (getIntentStatus === FetchStatusOptions.SUCCESS && getIntentData) {
      dispatch(editIntentState(getIntentData));
      getFilteredIntents();
    }
  }, [getIntentStatus]);

  /* - - - - - - - - - - Create Intent - - - - - - - - - - */
  const {
    fetch: createIntent,
    data: createIntentData,
    fetchStatus: createIntentStatus,
    permission: canCreateIntent
  } = useFetch<SlugDataOptionsModel<IntentTableAPIModel>, IntentTableDataModel>(
    IntentsService.createIntent,
    IntentsService.roles.create
  );

  useEffect(() => {
    if (createIntentStatus === FetchStatusOptions.SUCCESS && createIntentData) {
      dispatch(addIntentState(createIntentData));
      getFilteredIntents();

      dispatch(createToast('intent created'));
      hidePopup();
    }
  }, [createIntentStatus]);

  /* - - - - - - - - - - Edit Intent - - - - - - - - - - */
  const {
    fetch: editIntent,
    data: editIntentData,
    fetchStatus: editIntentStatus,
    permission: canUpdateIntent
  } = useFetch<SlugDataIdOptionsModel<IntentTableAPIModel>, IntentTableDataModel>(
    IntentsService.editIntent,
    IntentsService.roles.update
  );

  useEffect(() => {
    updateIntent(editIntentData, editIntentStatus);
  }, [editIntentStatus]);

  /* - - - - - - - - - - Retire Intent - - - - - - - - - - */

  const {
    fetch: retireIntent,
    data: retiredIntent,
    fetchStatus: retireIntentStatus
  } = useFetch(IntentsService.retireIntent, IntentsService.roles.update);

  const updateIntent = (
    data: IntentTableDataModel | undefined,
    fetchStatus: FetchStatusOptions,
    toastMessage?: string
  ) => {
    if (fetchStatus !== FetchStatusOptions.SUCCESS || !data) {
      return;
    }

    dispatch(editIntentState(data));
    getFilteredIntents();

    dispatch(createToast(toastMessage ?? 'intent edited'));
    hidePopup();
  };

  /**
   * Retires or un-retires the currently selected node
   */
  const onRetireChange = (node: IntentTableDataModel, isRetired: boolean) =>
    retireIntent({
      slug: selectedProject.slug,
      id: node.id,
      retired: {
        retired: isRetired
      }
    });

  useEffect(() => {
    updateIntent(retiredIntent, retireIntentStatus);
  }, [retireIntentStatus]);

  /* - - - - - - - - - - Delete Intent - - - - - - - - - - */
  const {
    fetch: deleteIntent,
    fetchStatus: intentDeleteStatus,
    fetchOptions: deleteIntentOptions,
    permission: canDeleteIntent
  } = useFetch<SlugIdOptionsModel<IntentTableDataModel['id']>, IntentTableDataModel>(
    IntentsService.deleteIntent,
    IntentsService.roles.delete
  );

  useEffect(() => {
    if (intentDeleteStatus === FetchStatusOptions.SUCCESS && deleteIntentOptions) {
      dispatch(deleteIntentState(deleteIntentOptions.id));
      getFilteredIntents();

      dispatch(createToast('intent deleted'));
    }
  }, [intentDeleteStatus]);

  const deleteTableIntent = (node: IntentTableDataModel) =>
    deleteIntent({
      slug: selectedProject.slug,
      id: node.id
    });

  /* - - - - - - - - - - Duplicate Intent - - - - - - - - - - */

  /**
   * Creates a duplicate of a node
   * @param node node to copy
   */
  const duplicateIntent = (node: IntentTableDataModel) => {
    const name = copyNameFormatter({
      name: node.name,
      nameList: allIntents.map((intent) => intent.name)
    });
    const displayName = copyNameFormatter({
      name: node.display_name,
      nameList: allIntents.map((intent) => intent.display_name)
    });
    handlePopupDisplayChange(true, FormMode.DUPLICATE, {
      ...node,
      name,
      display_name: displayName,
      user_groups: [],
      reference: undefined
    });
  };

  /* - - - - - - - - - - Publish Intent - - - - - - - - - - */

  const {
    fetch: publishIntent,
    data: publishIntentData,
    fetchStatus: publishIntentStatus
  } = useFetch(IntentsService.publishIntent, IntentsService.roles.update);

  useEffect(() => {
    updateIntent(publishIntentData, publishIntentStatus, 'intent published');
  }, [publishIntentStatus]);

  const onPublishIntent = (node: IntentTableDataModel) =>
    publishIntent({
      slug: selectedProject.slug,
      id: node.id
    });

  /* - - - - - - - - - - Approve Intent - - - - - - - - - - */

  const {
    fetch: approveIntent,
    data: approveIntentData,
    fetchStatus: approveIntentStatus
  } = useFetch(IntentsService.approveIntent, IntentsService.roles.update);

  useEffect(() => {
    updateIntent(approveIntentData, approveIntentStatus, 'intent approved');
  }, [approveIntentStatus]);

  const onApproveIntent = (node: IntentTableDataModel) =>
    approveIntent({
      slug: selectedProject.slug,
      id: node.id
    });

  /* - - - - - - - - - - Popup - - - - - - - - - - */

  /**
   * Callback to change the new intent pop-up visibility in state.
   * @param visibility boolean representing the visibility of the new intent popup
   * @param context the context intent, used for editing
   */
  const handlePopupDisplayChange = (
    visibility: boolean,
    mode = FormMode.CREATE,
    context: IntentTableDataModel | null = null
  ) => {
    setPopupMode(mode);
    setContextIntent(context);
    setDisplayNewIntentPopup(visibility);
  };

  const onSubmit = (data: FieldValues) => {
    data.user_groups = getUserGroupIds(data.user_groups);
    const tableData = data as IntentTableAPIModel;

    if (contextIntent && popupMode === FormMode.EDIT) {
      editIntent({
        slug: selectedProject.slug,
        id: contextIntent.id,
        data: tableData
      });
    } else {
      createIntent({ slug: selectedProject.slug, data: tableData });
      // set the filter to show proposed after creating a new object
      setFilterValue('state', PublishState.Proposed);
      setFilterQueryParameter({ active: false, status: Status.Not_Retired });
    }
  };

  const hidePopup = () => handlePopupDisplayChange(false);

  /* - - - - - - - - - - Query - - - - - - - - - - */

  const intentsFilterDefaultValues: FieldValues = {
    state: PublishState.Active,
    published_lte: null,
    topics: [],
    user_groups: [],
    author: []
  };

  const {
    control: filterControl,
    reset: resetFilterForm,
    resetField: resetFilterField,
    setValue: setFilterValue,
    getValues: getFilterValues,
    formState: { dirtyFields: dirtyFilterFields, isDirty: isFilterFormDirty }
  } = useForm({ defaultValues: intentsFilterDefaultValues, mode: 'onChange' });

  const {
    search,
    searchText,
    clearSearch,
    simpleSearch,
    setQueryParameter: setFilterQueryParameter,
    queryString: filterQueryString,
    queryParameters: filterQueryParameters,
    clearAllQueryParameters,
    clearQueryParameter
  } = useQuery({
    query: onQuery,
    deps: [dirtyFilterFields],
    defaultValues: {
      ...mapTableStateToPagination(tablestate),
      active: true,
      status: Status.Not_Retired,
      default: false
    },
    initialValues: filterInitialValues
  });

  // dispatch dirtyFilters action when filter form is dirty
  // sets filterDirty to true in redux store
  useEffect(() => {
    if (isFilterFormDirty) {
      dispatch(dirtyFilters());
    }
  }, [isFilterFormDirty]);

  // query on filterQueryString change.
  // *** also fires onInit, so it replaces default getMessages req
  // also updates the content filters in redux store
  useEffect(() => {
    // getIntent is already done when on activateProject. Needs to happen here again to offload filtering (such as retired) to backend
    getFilteredIntents();

    const appliedFilters = getAppliedFilters(validQueryParams, filterQueryParameters);
    dispatch(updateContentFilters(appliedFilters));
  }, [filterQueryString]);

  // perform back end query using the search
  function onQuery(queryText: string) {
    // set query parameters which will build a queryParameter string
    setFilterQueryParameter({ search: queryText });
  }

  const resetFilter = () => {
    clearSearch();
    // User groups should be set to an empty array instead of the default value
    clearAllQueryParameters({ user_groups: [] });
    resetFilterForm();
  };

  useEffect(() => setFilterQueryParameter(mapTableStateToPagination(tablestate)), [tablestate]);

  /* - - - - - - - - - - Filter Chips - - - - - - - - - - */

  const publishedBeforeFilterEnabled = 'published_lte' in filterQueryParameters;
  const activeFilterEnabled =
    filterQueryParameters?.active === true && filterQueryParameters?.status === Status.Not_Retired;
  const proposedFilterEnabled =
    filterQueryParameters?.active === false && filterQueryParameters?.status === Status.Not_Retired;
  const retiredFilterEnabled = filterQueryParameters?.status === Status.Retired;

  const filterChips: FilterChip[] = [
    {
      name: `State: ${statusDisplayNames[Status.Retired]}`,
      removeFn: () => {
        clearQueryParameter('status', 'active');
        resetFilterField('state');
      },
      enabled: retiredFilterEnabled
    },
    {
      name: `State: Proposed`,
      removeFn: () => {
        clearQueryParameter('status', 'active');

        setFilterValue('state', intentsFilterDefaultValues['state'], { shouldDirty: true });
      },
      enabled: proposedFilterEnabled
    },
    {
      name: `State: ${statusDisplayNames[Status.Not_Retired]}`,
      removeFn: () => {
        clearQueryParameter('status', 'active');
        setFilterValue('state', intentsFilterDefaultValues['state'], { shouldDirty: true });
      },
      enabled: activeFilterEnabled,
      removable: false
    },
    {
      name: `Published on or Before: ${humanReadableDateTimeFormatted(
        String(filterQueryParameters.published_lte)
      )}`,
      removeFn: () => {
        clearQueryParameter('published_lte');
        setFilterValue('published_lte', intentsFilterDefaultValues['published_lte'], {
          shouldDirty: true
        });
      },
      enabled: publishedBeforeFilterEnabled
    },
    ...buildMultipleChips(filterQueryParameters.topics, (topicId: number) => ({
      name: `Topic: ${mapIdToName(topicId, allTopics)}`,
      removeFn: () => {
        const topics: number[] = getFilterValues('topics');
        const filteredTopics = topics.filter((id) => id !== topicId);

        if (filteredTopics.length === 0) {
          clearQueryParameter('topics');
          setFilterValue('topics', intentsFilterDefaultValues['topics'], { shouldDirty: true });
        } else {
          setFilterValue('topics', filteredTopics);
          setFilterQueryParameter({ topics: filteredTopics });
        }
      },
      enabled: isArrayAndIncludes(filterQueryParameters.topics, topicId)
    })),
    ...buildMultipleChips(filterQueryParameters.user_groups, (userGroup) => ({
      name: `User Group: ${userGroup === 0 ? 'None' : mapIdToName(userGroup, allUserGroups)}`,
      removeFn: () => {
        const updatedUserGroups = (filterQueryParameters.user_groups as number[]).filter(
          (group) => group !== userGroup
        );
        setFilterQueryParameter({
          user_groups: updatedUserGroups
        });
        setFilterValue('user_groups', updatedUserGroups, {
          shouldDirty: true
        });
      },
      enabled: true,
      removable: true
    })),
    ...buildMultipleChips(filterQueryParameters.author, (userId: number) => ({
      name: `Author: ${mapIdToName(userId, allAuthors, 'user')}`,
      removeFn: () => {
        const authors: number[] = getFilterValues('author');
        const filteredAuthors = authors.filter((id) => id !== userId);

        if (filteredAuthors.length === 0) {
          clearQueryParameter('author');
          setFilterValue('author', intentsFilterDefaultValues['author'], { shouldDirty: true });
        } else {
          setFilterValue('author', filteredAuthors);
          setFilterQueryParameter({ author: filteredAuthors });
        }
      },
      enabled: isArrayAndIncludes(filterQueryParameters.author, userId)
    }))
  ];

  const filterDeps = [filterChips];

  /* - - - - - - - - - - Comments - - - - - - - - - - */

  const onThreadChange = (options: ThreadStateChangeEvent) => getIntent(options);

  /* - - - - - - - - - - History - - - - - - - - - - */

  const onHistoryOpen = (node: IntentTableDataModel) => {
    setContextIntent(node);
    setShowHistory(true);
  };

  const onHistoryClose = () => {
    setShowHistory(false);
    setContextIntent(null);
  };

  return (
    <div className="specto-intent">
      <IntentPopup
        allCategories={availableCategories}
        allUserGroups={allUserGroups}
        contextIntent={contextIntent}
        loading={
          createIntentStatus === FetchStatusOptions.LOADING ||
          editIntentStatus === FetchStatusOptions.LOADING
        }
        displayPopup={displayNewIntentPopup}
        onSubmit={onSubmit}
        mode={popupMode}
        parentElement={document.activeElement as HTMLElement}
        onHide={hidePopup}
        projectUser={ownProjectUser}
        authors={allAuthors}
        isSuperUser={user.is_superuser}
      />

      <div className="grid grid-nogutter">
        <div className="col flex flex-wrap xl:align-items-center align-items-start justify-content-between flex-column-reverse xl:flex-row">
          <SearchBar
            filterMenu={
              <IntentFilterMenu
                userGroups={allUserGroups}
                loading={getIntentsStatus === FetchStatusOptions.LOADING}
                control={filterControl}
                disableStatus={publishedBeforeFilterEnabled}
                projectUser={ownProjectUser}
                topics={allTopics}
                authors={filterAuthors(user.is_superuser, allAuthors, ownProjectUser?.user)}
                onFormValueChange={(fieldValue) => {
                  if ('published_lte' in fieldValue) {
                    resetFilterField('status');
                    fieldValue['status'] = undefined;
                    fieldValue['active'] = true;
                  }
                  setFilterQueryParameter(fieldValue);
                }}
              />
            }
            placeHolder="Search by Name or Display Name..."
            className="specto-search-bar w-12 xl:w-8 mt-3 xl:mt-0"
            text={searchText}
            onChange={(e) => simpleSearch(e.target.value)}
            onSubmitText={search}
            onClearText={() => {
              clearSearch();
              search('');
            }}
            onFilterClearClick={() => resetFilter()}
            hideFilterClear={!isFilterFormDirty}
          />

          {canCreateIntent && !isVersionSelected && (
            <Button
              className="align-self-end xl:align-self-center"
              label="New Intent"
              raised
              icon="pi pi-plus"
              iconPos="right"
              onClick={() => handlePopupDisplayChange(true)}
            />
          )}
        </div>

        <div className="col-12 mb-4 pt-2">
          <FilterChips filterChips={filterChips} deps={filterDeps} className="lg:w-7" />
        </div>

        <div className="col-12">
          <IntentDataTable
            intents={filterDefault(getIntentsData?.results ?? [])}
            allCategories={allCategories}
            allTopics={allTopics}
            allUserGroups={allUserGroups}
            authors={allAuthors}
            onThreadStateChange={onThreadChange}
            onEditInit={(intent) => handlePopupDisplayChange(true, FormMode.EDIT, intent)}
            onDuplicateIntent={duplicateIntent}
            onHistoryOpen={onHistoryOpen}
            onRetireChange={onRetireChange}
            onPublishIntent={onPublishIntent}
            onApproveIntent={onApproveIntent}
            onDeleteIntent={deleteTableIntent}
            readOnly={!canUpdateIntent || publishedBeforeFilterEnabled}
            canDelete={canDeleteIntent}
            showComments={permissionBoolean(
              CommentsService.roles.general.list,
              selectedProject.project_user.role
            )}
            isSuperUser={user.is_superuser}
            isAdmin={isAdmin(selectedProject.project_user.role)}
            tablestate={tablestate}
            onTableStateChange={setTableState}
            totalRecords={getIntentsData?.count ?? 0}
            loading={getIntentsStatus === FetchStatusOptions.LOADING}
          />
        </div>
        {contextIntent ? (
          <IntentHistoryPopup
            allCategories={allCategories}
            allUserGroups={allUserGroups}
            authors={allAuthors}
            node={contextIntent}
            onHide={onHistoryClose}
            visible={showHistory}
            parentElement={document.activeElement as HTMLElement}
          />
        ) : undefined}
      </div>
    </div>
  );
};

export default Intent;
