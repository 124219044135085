import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { getFormErrorMessage } from 'util/form';
import { ReactComponent as StarActive } from 'assets/star/star_active.svg';
import { ReactComponent as StarInactive } from 'assets/star/star_inactive.svg';
import classNames from 'classnames';
import { Radio, RadioGroup } from '@mui/material';

/**
 * Field for a star rating
 *
 * @param control react-hook-form control.
 * @param defaultValue Default value for rating.
 * @param fieldName The field name in react-hook-form.
 * @param label Label for the rating.
 * @param min Minimum value for the rating.
 * @param max Maximum value for the rating.
 * @param required If the rating is required.
 * @component
 */
const RatingField = function ({
  control,
  defaultValue = 3,
  fieldName,
  label,
  min = 1,
  max = 5,
  required = false,
  oneStarText = '',
  fiveStarText = ''
}: {
  control: Control;
  defaultValue?: number;
  fieldName: string;
  label: string;
  min?: number;
  max?: number;
  required?: boolean;
  oneStarText?: string;
  fiveStarText?: string;
}) {
  const id = `rating-${fieldName}`;
  const BoxStar = ({ children }: { children: React.ReactNode }) => (
    <span className={classNames('star-container flex align-items-center justify-content-center')}>
      {children}
    </span>
  );
  const [hoverValue, setHoverValue] = React.useState(-1);

  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={defaultValue}
      rules={{
        ...(required && { required: 'Required field' })
      }}
      render={({ field, fieldState }) => (
        <div className="rating-field mb-1">
          <label htmlFor={id}>
            {label}
            {required && <span className="specto-form-asterisk-color"> *</span>}
          </label>
          <div className="flex w-full justify-content-center">
            <RadioGroup
              onMouseLeave={() => {
                // Reset hover value after leaving the group so that
                // the stars do not flash to the current value when
                // sliding the cursor across the stars
                setHoverValue(-1);
              }}
              row
              className="w-full justify-content-between"
            >
              {Array(max)
                .fill(0)
                .map((_, index) => {
                  return (
                    <Radio
                      className="p-0 border-noround"
                      checked={
                        // Hovered value takes priority over current value
                        hoverValue != -1 ? hoverValue >= index + 1 : field.value >= index + 1
                      }
                      checkedIcon={
                        <BoxStar>
                          <StarActive />
                        </BoxStar>
                      }
                      icon={
                        <BoxStar>
                          <StarInactive />
                        </BoxStar>
                      }
                      disableRipple
                      key={`${index + 1}-stars`}
                      inputProps={{ 'aria-label': `${index + 1} stars` }}
                      value={index + 1}
                      onMouseEnter={() => setHoverValue(index + 1)}
                      onClick={(e: any) => {
                        const value = parseInt(e.target.value, 10);
                        if (value && value >= min) {
                          field.onChange(value);
                        }
                      }}
                    />
                  );
                })}
            </RadioGroup>
          </div>
          <div className="flex flex-row align-items-center justify-content-between">
            <span>{oneStarText}</span>
            <span>{fiveStarText}</span>
          </div>
          {getFormErrorMessage(fieldState.error)}
        </div>
      )}
    />
  );
};
export default RatingField;
