import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

type UseFilterOptions<K> = {
  searchParameters?: K[];
  delay?: number;
};

const useFilter = <T extends { [key: string | number]: any } = any>(
  options?: UseFilterOptions<keyof T>
) => {
  const [data, setData] = useState<T[]>([]);
  const [filteredData, setFilteredData] = useState<T[]>([]);
  const [searchText, setSearchText] = useState('');
  const debounceFn = useCallback(debounce(handleDebounceFn, options?.delay ?? 1000), [data]);
  const [loading, setLoading] = useState(false);

  const clearSearch = () => {
    setSearchText('');
    setFilteredData(data);
  };

  function search(text: string) {
    setLoading(true);
    setSearchText(text);
    debounceFn(text);
  }

  function simpleSearch(text: string) {
    setSearchText(text);
  }

  function handleDebounceFn(text: string) {
    setLoading(false);
    if (text.length > 0) {
      setFilteredData(
        data?.filter?.((item: T) =>
          (options?.searchParameters
            ? objectValuesFromParameters<T>(item, options.searchParameters)
            : Object.values(item)
          )
            // String() because properties of data can be boolean
            .some((itemValue: any) => new RegExp(`.*${text}.*`, 'i').test(String(itemValue)))
        )
      );
    } else {
      // fallback to regular data if search text is empty
      setFilteredData(data);
    }
  }

  // when new data is set, reset filtered data
  useEffect(() => {
    clearSearch();
  }, [data]);

  return {
    search,
    searchText,
    clearSearch,
    simpleSearch,
    setData,
    loading,
    filteredData
  };
};

// get specific values from an object based on array of parameters
const objectValuesFromParameters = <T = any>(object: T, values: (keyof T)[]) => {
  const arr: string[] = [];

  values.forEach((value) => {
    if (object[value]) {
      arr.push(String(object[value]));
    }
  });

  return arr;
};

export default useFilter;
