/**
 * Get name corresponding to id when given an array of objects containing name and id.
 * @param id id from an object in arrayDict
 * @param arrayDict An array of objects containing an id and name.
 * @param customKey a custom key to map to name instead of 'id', defaults to
 * 'id' if no argument is passed.
 * @returns the name string corresponding to the input id
 */
export const mapIdToName = <T = any>(
  id: number | string | null,
  arrayDict: { [key: string]: any; name: T }[],
  customKey = 'id'
): T | undefined => arrayDict.find((dictEntry) => dictEntry[customKey] === id)?.name;

/**
 * Get id corresponding to name when given an array of objects containing name and id.
 * @param name name from an object in arrayDict
 * @param arrayDict An array of objects containing an id and name.
 * @returns the id number corresponding to the input name
 */
export const mapNameToId = (name: string, arrayDict: { id: number; name: string }[]) =>
  arrayDict.find((dictEntry) => dictEntry.name === name)?.id;
