import React, { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
// components
import Popup from 'containers/Popup';
import { DropdownField, TextField } from 'components/Forms';
// utils
// models
import { CategoryModel } from 'models/category-model';
import { IntentTableDataModel } from 'models/intent-table-data-model';
// constants
import TagsField from 'components/Forms/TagsField';
import { FormMode } from 'models/form-model';
import { PublishingNameConstraint } from 'constants/namingConstraint';
import { PublishingNameWarning } from 'constants/warnings';
import { UserGroupModel } from 'models/user-groups-model';
import { createUserGroupOptions } from 'util/userGroups';
import UserGroupsField from 'components/UserGroups/UserGroupsField';
import { ProjectMemberUserModel } from 'models/project-model';
import AutocompleteField from 'components/Forms/AutocompleteField';
import { filterAuthors } from 'util/users';

type NewIntentPopupPropsModel = {
  allCategories: CategoryModel[];
  allUserGroups: UserGroupModel[];
  contextIntent?: IntentTableDataModel | null;
  displayPopup: boolean;
  loading?: boolean;
  mode?: FormMode;
  onHide(): void;
  onSubmit(data: FieldValues): void;
  parentElement?: HTMLElement;
  projectUser: ProjectMemberUserModel;
  authors: ProjectMemberUserModel[];
  isSuperUser: boolean;
};

/**
 * Component that displays a popup to create a new intent.
 *
 * @param allCategories A list of all categories.
 * @param allUserGroups A list of all user groups.
 * @param contextIntent The context intent.
 * @param displayPopup Whether to display the popup or not.
 * @param loading The loading state.
 * @param mode The mode (default is FormMode.CREATE).
 * @param onHide Callback when hiding the popup.
 * @param onSubmit Callback when submitting the form.
 * @param parentElement Element to return focus to after the dialog is hidden.
 * @param projectUser The current project user
 * @param authors Lsit of current project members
 * @param isSuperUser whether the user is a superuser
 * @constructor
 */
const IntentPopup = function ({
  allCategories,
  allUserGroups,
  contextIntent,
  displayPopup,
  loading,
  mode = FormMode.CREATE,
  onHide,
  onSubmit,
  parentElement,
  projectUser,
  authors,
  isSuperUser = false
}: NewIntentPopupPropsModel) {
  /* - - - - - - - - - - Effects - - - - - - - - - - */

  useEffect(() => {
    /**
     * Sets the default form values.
     *
     * Needs to be done in an effect and not on useForm hook call because the statuses are not
     * immediately available on component mount. There is a delay while they are pulled.
     */
    const defaultValues: FieldValues = contextIntent
      ? {
          ...contextIntent,
          user_groups: createUserGroupOptions(
            contextIntent.user_groups,
            allUserGroups,
            contextIntent.reference?.user_groups
          )
        }
      : {
          name: '',
          display_name: '',
          category: null,
          tags: [],
          user_groups: [],
          author: projectUser?.user
        };
    reset(defaultValues);
  }, [contextIntent]);

  const filteredAuthors = filterAuthors(
    isSuperUser,
    authors,
    mode === FormMode.CREATE ? projectUser?.user : contextIntent?.author
  );

  const { control, handleSubmit, reset } = useForm();

  // reset when closing
  useEffect(() => {
    !displayPopup && reset();
  }, [displayPopup]);

  /* - - - - - - - - - - Callbacks - - - - - - - - - - */
  const onPopupSave = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    handleSubmit(onSubmit)();
  };

  const hidePopup = () => {
    reset();
    onHide();
    parentElement?.focus();
  };

  const titles: Partial<
    Record<FormMode, { header: string; saveButtonText: string; subtitle?: string }>
  > = {
    [FormMode.CREATE]: {
      header: 'New Intent',
      subtitle: 'Create a new Intent',
      saveButtonText: 'Create Intent'
    },
    [FormMode.EDIT]: {
      header: 'Edit Intent',
      subtitle: 'Modify an existing Intent',
      saveButtonText: 'Submit Intent'
    },
    [FormMode.DUPLICATE]: {
      header: 'Duplicate Intent',
      saveButtonText: 'Create Intent',
      subtitle: 'Values from the original row have been pre-filled'
    }
  };

  return (
    <Popup
      title={titles[mode]?.header ?? ''}
      subtitle={titles[mode]?.subtitle ?? ''}
      visible={displayPopup}
      loading={loading}
      onHide={hidePopup}
      onSave={onPopupSave}
      saveButtonText={titles[mode]?.saveButtonText}
    >
      <form className="p-fluid" onSubmit={onPopupSave} aria-hidden="true">
        <div className="specto-text-medium -mt-2 mb-5">
          Required fields are marked with <span className="specto-form-asterisk-color">*</span>
        </div>

        <TextField
          fieldName="name"
          label="Name"
          control={control}
          rules={{
            pattern: {
              value: PublishingNameConstraint,
              message: PublishingNameWarning
            }
          }}
        />
        <TextField fieldName="display_name" label="Display Name" control={control} />

        <DropdownField
          fieldName="category"
          label="Category"
          options={allCategories}
          optionLabel="name"
          optionValue="id"
          appendTo={null}
          control={control}
        />
        <UserGroupsField
          control={control}
          userGroups={allUserGroups}
          projectUser={projectUser}
          mode={mode}
        />
        <AutocompleteField
          labelClassName="line-height-3"
          required={true}
          control={control}
          options={filteredAuthors}
          optionLabel="name_reversed"
          optionValue="user"
          fieldName="author"
          label="Author"
          placeholder="Select Author or start typing"
          info="Author of this Intent"
        />
        <TagsField control={control} />
      </form>
    </Popup>
  );
};

export default IntentPopup;
