import React from 'react';
import { Control, Controller } from 'react-hook-form';
import classNames from 'classnames';
import classnames from 'classnames';
import { getFormErrorMessage, oneSpaceFormatter } from 'util/form';
import { KeyFilterType } from 'primereact/keyfilter';
import { Password } from 'primereact/password';
import { basicInputMaxLength } from 'constants/inputs';
import { UseControllerProps } from 'react-hook-form/dist/types/controller';

/**
 * Component that displays a basic text field in a form
 * @component
 * @param param0 Prop object containing the following:
 *
 *    characterLimit: character limit for the input
 *    className: class name
 *    control: react-hook-form control
 *    defaultValue: default value
 *    feedback: whether to show the password feedback modal
 *    fieldName: the field name in react-hook-form
 *    keyFilter: input constraints
 *    label: label for the input
 *    noPadding: whether to remove or keep padding
 *    placeholder: input placeholder
 *    readOnly: whether the input is read only
 *    required: if the input is required
 *    rules: ReactHookForm rules
 *    toggleMask: masking the input
 *    validate: validation rules
 */
const PasswordField = function ({
  ariaLabel,
  characterLimit = basicInputMaxLength,
  className,
  control,
  defaultValue = '',
  disabled,
  feedback = false,
  fieldName,
  keyFilter,
  label,
  noPadding,
  placeholder,
  readOnly,
  required = true,
  rules,
  toggleMask = true,
  validate
}: {
  ariaLabel?: string;
  characterLimit?: number;
  className?: string;
  control: Control;
  defaultValue?: string;
  disabled?: boolean;
  feedback?: boolean;
  fieldName: string;
  keyFilter?: KeyFilterType;
  label?: string;
  noPadding?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  rules?: UseControllerProps['rules'];
  toggleMask?: boolean;
  validate?: (val: string) => string | undefined;
}) {
  return (
    <div className={classnames(className, { field: !noPadding })}>
      {label && (
        <label htmlFor={fieldName + 'input'}>
          {label} {required && <span className="specto-form-asterisk-color">*</span>}
        </label>
      )}
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        rules={{
          ...(required && { required: 'Required field' }),
          ...(validate && { validate: validate }),
          maxLength: {
            value: characterLimit,
            message: `Exceeded max length of ${characterLimit} characters`
          },
          ...rules
        }}
        render={({ field, fieldState }) => (
          <>
            <Password
              id={field.name}
              inputId={field.name + 'input'}
              {...field}
              ref={null}
              placeholder={placeholder}
              onChange={(e) => field.onChange(oneSpaceFormatter(e.target.value))}
              disabled={disabled}
              keyfilter={keyFilter}
              className={classNames('specto-input-password', {
                'p-invalid': fieldState.error
              })}
              readOnly={readOnly}
              autoComplete="off"
              aria-label={ariaLabel}
              aria-invalid={fieldState?.error ? 'true' : 'false'}
              toggleMask={toggleMask}
              feedback={feedback}
              data-cy={field.name}
            />
            {getFormErrorMessage(fieldState.error, noPadding)}
          </>
        )}
      />
    </div>
  );
};

export default PasswordField;
