import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// withRouter utility hook
function withRouter(Component: any) {
  function ComponentWithRouterProp(props: any) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

type AppBreadcrumbProps = {
  routers: any;
};

const AppBreadcrumb = (props: AppBreadcrumbProps) => {
  const location = useLocation();
  const pathname = location.pathname;

  let name = pathname.replace('/', '');
  if (props.routers) {
    const currentRouter = props.routers.find((router: any) =>
      router.regex
        ? router.regex.test(name)
        : router.path === pathname || router.meta.breadcrumb[0].path === pathname
    );
    name = currentRouter ? currentRouter.meta.breadcrumb[0].label : name;
  }

  return <span>{name}</span>;
};

export default withRouter(AppBreadcrumb);
