import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Button } from 'primereact/button';
import { setLayout } from 'features/layout/layoutSlice';
import { SYSTEM_SETTINGS_LAYOUT } from 'constants/layouts';
import ModalLarge from 'containers/modals/ModalLarge';
import { EditUserOptionsModel, UserModel, UserRequestAPIModel } from 'models/user-model';
import UsersService from 'services/usersService';
import Avatar from 'components/Avatar';
import { FieldValues, useForm } from 'react-hook-form';
import { dirtyValues } from 'util/form';
import { authSelector, updateUser } from 'features/auth/authSlice';
import { isSameUser } from 'util/users';
import { confirmDialog } from 'primereact/confirmdialog';
import { createToast } from 'features/toast/toastSlice';
import UserProfilePopup from 'components/User/UserProfilePopup';
import SearchBar from 'components/SearchBar';
import useFilter from 'hooks/useFilter';
import useFetch from 'hooks/useFetch';
import { IdOptionsModel } from 'models/api-model';
import AuthService from 'services/authService';
import { FetchStatusOptions } from 'constants/fetchStatus';
import { NamedDeletionDialog } from 'components/Dialogs';
import classnames from 'classnames';
import { projectsSelector } from 'features/projects/projectsSlice';
import { DataView } from 'primereact/dataview';

const SystemUsers = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(authSelector);
  const { isProjectSelected } = useAppSelector(projectsSelector);
  const [allUsers, setAllUsers] = useState<UserModel[]>([]);
  const [userContext, setUserContext] = useState<UserModel | null>(null);
  const [displayPopup, setDisplayPopup] = useState<boolean>(false);

  const defaultValues: FieldValues = {
    first_name: userContext?.first_name || '',
    last_name: userContext?.last_name || '',
    password: '',
    email: userContext?.email || '',
    is_superuser: !!userContext?.is_superuser
  };
  const {
    control,
    formState: { dirtyFields },
    handleSubmit,
    reset
  } = useForm({ defaultValues });
  const {
    filteredData: filteredUsersData,
    search: searchUsers,
    searchText,
    clearSearch,
    simpleSearch,
    setData: setFilteredUsersData,
    loading: searchUsersLoading
  } = useFilter<UserModel>();

  // need to keep track of all project members as filtered out members are just for the view
  const setUsers = (members: UserModel[]) => {
    setAllUsers(members);
    setFilteredUsersData(members);
  };

  // onInit
  useEffect(() => {
    dispatch(setLayout(SYSTEM_SETTINGS_LAYOUT));
    getUsers({});
  }, []);

  /* - - - - - - - - - - Get Users - - - - - - - - - - */
  const {
    fetch: getUsers,
    data: usersData,
    fetchStatus: getUsersStatus
  } = useFetch<unknown, UserModel[]>(
    UsersService.getUsers,
    (!isProjectSelected && user?.is_superuser) || UsersService.roles.list
  );

  useEffect(() => {
    if (getUsersStatus === FetchStatusOptions.SUCCESS && usersData) {
      setUsers(usersData);
    }
  }, [getUsersStatus]);

  /* - - - - - - - - - - Edit User - - - - - - - - - - */
  const {
    fetch: editUser,
    data: editUserData,
    fetchStatus: editUserStatus
  } = useFetch<EditUserOptionsModel, UserModel>(UsersService.editUser, UsersService.roles.update);

  useEffect(() => {
    if (editUserStatus === FetchStatusOptions.SUCCESS && editUserData) {
      const usersCopy = [...allUsers];

      // from the user obj returned from back end, find the user in users array that matches
      const editedUser: UserModel | undefined = usersCopy.find(
        (user) => user.id === editUserData.id
      );

      if (!editedUser) {
        return;
      }

      // add/overwrite editedUser properties with all userResponse properties
      Object.assign(editedUser, editUserData);

      // Update own user object if you edited your own user object
      if (isSameUser(user, editUserData)) {
        dispatch(updateUser(editedUser));
      }

      setUsers(usersCopy);
      hideProfilePopup();
      dispatch(createToast('user edited'));
    }
  }, [editUserStatus]);

  // set form values dynamically to any new selectedUser
  useEffect(() => {
    reset(defaultValues);
  }, [userContext]);

  // if function called with no selectedUser input, then the form opens in create mode as opposed to edit
  const openPopup = (selectedUserInput?: UserModel) => {
    setUserContext(selectedUserInput || null);
    setDisplayPopup(true);
  };

  const hideProfilePopup = () => {
    resetForm();
    setDisplayPopup(false);
    setUserActivationFlow(false);
  };

  const resetForm = (values?: FieldValues) => {
    reset(values);
  };

  const onPopupSave = () => {
    setUserActivationFlow(false);
    handleSubmit(onSubmit)();
  };

  const onSubmit = (data: FieldValues) => {
    const formValues = dirtyValues(dirtyFields, data);

    if (!userContext || Object.keys(formValues).length === 0) {
      return;
    }

    const userRequest: UserRequestAPIModel = {
      ...formValues
    };

    editUser({ id: userContext.id, user: userRequest });
  };

  /* - - - - - - - - - - Deactivate User - - - - - - - - - - */
  const [userActivationFlow, setUserActivationFlow] = useState(false);

  const toggleUserActivation = () => {
    if (!userContext) {
      return;
    }

    const userRequest: UserRequestAPIModel = {
      ...userContext,
      is_active: !userContext.is_active
    };

    setUserActivationFlow(true);
    editUser({ id: userContext.id, user: userRequest });
  };

  const deactivateConfirmation = (e: React.MouseEvent) => {
    e.preventDefault();

    if (isSameUser(user, userContext)) {
      dispatch(createToast('cannot deactivate yourself', 'warn'));
      return;
    }

    confirmDialog({
      message: 'Users edits and references will be preserved in data tables',
      header: (userContext?.is_active ? 'Deactivate' : 'Reactivate') + ' User?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: toggleUserActivation
    });
  };

  /* - - - - - - - - - - Delete User - - - - - - - - - - */
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const {
    fetch: deleteSystemUser,
    fetchStatus: deleteUserStatus,
    fetchOptions: deleteUserOptions
  } = useFetch<IdOptionsModel>(UsersService.deleteUser, UsersService.roles.delete);

  useEffect(() => {
    if (deleteUserStatus === FetchStatusOptions.SUCCESS && deleteUserOptions) {
      setUsers(allUsers.filter((someUser: UserModel) => someUser.id !== deleteUserOptions.id));
      hideProfilePopup();
      dispatch(createToast('user deleted'));
    }
  }, [deleteUserStatus]);

  const deleteUser = () => {
    if (!userContext) {
      return;
    }

    deleteSystemUser({ id: userContext.id });
  };

  const { fetch: sendRecoveryCodes, fetchStatus: sendRecoveryCodesStatus } = useFetch(
    AuthService.sendRecoveryCodes,
    AuthService.roles.create
  );

  useEffect(() => {
    if (sendRecoveryCodesStatus === FetchStatusOptions.SUCCESS) {
      dispatch(createToast('Recovery codes successfully sent'));
    }
  }, [sendRecoveryCodesStatus]);

  const sendRecoveryCodesDialog = (user: UserModel) =>
    confirmDialog({
      header: `Email new recovery codes to ${user.first_name} ${user.last_name}?`,
      icon: 'pi pi-info-circle',
      draggable: false,
      message: (
        <div>
          Any previously generated recovery codes will no longer be usable.
          <br />
          New recovery codes will be generated and emailed to the user.
        </div>
      ),
      acceptClassName: 'p-button-danger',
      accept: () => sendRecoveryCodes({ user_id: user.id })
    });

  const userRowTemplate = (systemUser: UserModel) => (
    <div
      className="flex flex-wrap justify-content-between w-full md:py-3 py-4"
      key={systemUser.email}
    >
      <div className="flex align-items-center justify-content-start sm:w-7 w-full md:pb-0 pb-3">
        <Avatar
          fullName={{
            first: systemUser.first_name,
            last: systemUser.last_name
          }}
          className="mr-2"
          outlined={!systemUser.is_active}
        />
        <div>
          <div
            className={classnames('font-semibold', {
              'specto-text-muted': !systemUser.is_active
            })}
          >
            {systemUser.first_name} {systemUser.last_name} {!systemUser.is_active && '(inactive)'}
          </div>
          <div className="specto-text-muted">{systemUser.email}</div>
        </div>
      </div>

      <div className="sm:w-auto w-full">
        <Button
          label="Recovery Codes"
          type="button"
          className="p-button-text underline"
          onClick={() => sendRecoveryCodesDialog(systemUser)}
        />
        <Button
          label="Edit"
          type="button"
          className="p-button-text underline"
          onClick={() => openPopup(systemUser)}
        />
      </div>
    </div>
  );

  return (
    <ModalLarge
      className="specto-system-settings"
      loading={getUsersStatus === FetchStatusOptions.LOADING}
    >
      <h1 className="align-self-center mb-7">System Users</h1>

      <h6>System Users</h6>

      <div className="col col-12 lg:col-8 pl-0">
        <SearchBar
          className="pb-4"
          placeHolder="Search all Users"
          text={searchText}
          formInputStyle
          loading={searchUsersLoading}
          onChange={(e) => simpleSearch(e.target.value)}
          onSubmitText={searchUsers}
          onClearText={() => {
            clearSearch();
            searchUsers('');
          }}
        />
      </div>

      <form className="w-full">
        <DataView
          value={filteredUsersData}
          className="mt-5 w-full"
          itemTemplate={userRowTemplate}
          header={<h6>All Users</h6>}
          paginator
          rows={5}
        />
      </form>

      <UserProfilePopup
        selectedUser={userContext}
        visible={displayPopup}
        control={control}
        loading={!userActivationFlow && editUserStatus === FetchStatusOptions.LOADING}
        onSave={onPopupSave}
        onHide={hideProfilePopup}
        onDelete={() => setShowConfirmDelete(true)}
        onDeactivate={deactivateConfirmation}
      />
      <NamedDeletionDialog
        confirmName={`${userContext?.first_name}.${userContext?.last_name}`}
        prompt="Enter users first.last name to confirm deletion"
        visible={showConfirmDelete}
        onHide={() => setShowConfirmDelete(false)}
        accept={deleteUser}
      />
    </ModalLarge>
  );
};

export default SystemUsers;
