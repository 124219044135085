import React from 'react';
import { Control, FieldValues } from 'react-hook-form';
import RadioButtonField from 'components/Forms/RadioButtonField';
import { onDateChange } from 'util/calendar';
import { PublishState } from 'models/status-model';
import { FilterStatusExclusiveWarning } from 'constants/warnings';
import DatePickerField from 'components/Forms/DatePickerField';
import { Paper } from '@mui/material';
import LoadingSpinner from 'components/LoadingSpinner';
import classnames from 'classnames';
import { blockKeyboardOnLoad } from 'util/keyboard';
import { IntentTableDataModel } from 'models/intent-table-data-model';
import { ResponseTableDataModel } from 'models/response-table-data-model';
import { EntityTableDataModel } from 'models/entity-model';
import MultiAutocompleteField from '../../../components/Forms/MultiAutocompleteField';
import { GenericItemTemplate } from '../../../components/Table';
import { filterDefault } from 'util/default';
import UserGroupFilterField from 'components/UserGroups/UserGroupsFilterField';
import { UserGroupModel } from 'models/user-groups-model';
import { ProjectMemberUserModel } from 'models/project-model';
import { sortbyProperty } from 'util/arrays';
import { mapStateToActiveAndStatus } from 'util/filters';

type TopicFilterProps = {
  control: Control;
  disableState?: boolean;
  entities: EntityTableDataModel[];
  intents: IntentTableDataModel[];
  loading?: boolean;
  onFormValueChange(data: FieldValues): void;
  projectUser: ProjectMemberUserModel;
  responses: ResponseTableDataModel[];
  showEntities?: boolean;
  userGroups: UserGroupModel[];
  authors: ProjectMemberUserModel[];
};

/**
 * Topic FilterMenu
 * @param control form Control
 * @param disableState if the state field should be disabled
 * @param entities entity options
 * @param intents intent options
 * @param loading loading state
 * @param onFormValueChange form value change callback
 * @param responses response options
 * @param showEntities boolean to show/hide the entities field
 * @param userGroups List of available user groups.
 * @param authors List of current Project members
 *
 *
 * @component
 */
function TopicFilterMenu({
  control,
  disableState,
  entities,
  intents,
  loading,
  onFormValueChange,
  projectUser,
  responses,
  showEntities,
  userGroups,
  authors
}: TopicFilterProps) {
  return (
    <Paper className="p-3 w-30rem" onKeyDown={(e) => blockKeyboardOnLoad(e, loading)} tabIndex={0}>
      <LoadingSpinner enabled={loading} style={{ top: '38%', left: '42%' }} />
      <form
        className={classnames('flex flex-wrap overflow-y-auto gap-3', {
          'pointer-events-none': loading
        })}
        onSubmit={(e) => {
          e.preventDefault();
        }}
        style={{ maxHeight: '70vh' }}
      >
        <RadioButtonField
          label="State:"
          fieldName="state"
          labelClassName="line-height-3 font-bold"
          disabled={disableState}
          tooltip={FilterStatusExclusiveWarning}
          tooltipOptions={{ showOnDisabled: true, disabled: !disableState }}
          className="w-12 specto-border border-round border-1 p-3"
          control={control}
          options={[
            { id: 1, value: PublishState.Active, label: 'Active' },
            { id: 2, value: PublishState.Proposed, label: 'Proposed' },
            { id: 3, value: PublishState.Retired, label: 'Retired' }
          ]}
          noPadding
          onChange={(e) => onFormValueChange(mapStateToActiveAndStatus(e.value))}
        />
        <DatePickerField
          label="Published on or Before:"
          placeholder="Select a date"
          fieldName="published_lte"
          labelClassName="line-height-3 font-bold"
          className="w-12 specto-border border-round border-1 p-3"
          control={control}
          onChange={(e) => onDateChange(e, onFormValueChange, 'published_', true)}
        />
        <UserGroupFilterField
          control={control}
          userGroups={userGroups}
          onChange={(values) => onFormValueChange({ user_groups: values })}
          projectUser={projectUser}
        />
        <MultiAutocompleteField
          className="w-12 specto-border border-round border-1 p-3"
          labelClassName="line-height-3 font-bold"
          itemTemplate={(item) =>
            GenericItemTemplate({
              id: item.id,
              itemName: 'intent',
              allOptions: intents,
              showRetiredIcon: true
            })
          }
          fieldName="intents"
          label="Intents"
          control={control}
          options={sortbyProperty(filterDefault(intents), 'name')}
          optionValue="id"
          optionLabel="name"
          onChange={(e) => {
            onFormValueChange({ intents: e });
          }}
          placeholder="Select Intents or start typing"
          required={false}
          noPadding
        />
        <MultiAutocompleteField
          className="w-12 specto-border border-round border-1 p-3"
          labelClassName="line-height-3 font-bold"
          itemTemplate={(item) =>
            GenericItemTemplate({
              id: item.id,
              itemName: 'response',
              allOptions: responses,
              showRetiredIcon: true
            })
          }
          fieldName="responses"
          label="Responses"
          control={control}
          options={sortbyProperty([...responses], 'name')}
          optionValue="id"
          optionLabel="name"
          onChange={(e) => {
            onFormValueChange({ responses: e });
          }}
          placeholder="Select Responses or start typing"
          required={false}
          noPadding
        />
        {showEntities && (
          <MultiAutocompleteField
            className="w-12 specto-border border-round border-1 p-3"
            labelClassName="line-height-3 font-bold"
            itemTemplate={(item) =>
              GenericItemTemplate({
                id: item.id,
                itemName: 'entity',
                allOptions: entities,
                showRetiredIcon: true
              })
            }
            fieldName="entities"
            label="Entities"
            control={control}
            options={filterDefault(entities)}
            optionValue="id"
            optionLabel="name"
            onChange={(e) => {
              onFormValueChange({ entities: e });
            }}
            placeholder="Select Entities or start typing"
            required={false}
            noPadding
          />
        )}
        <MultiAutocompleteField
          className="w-12 specto-border border-round border-1 p-3"
          labelClassName="line-height-3 font-bold"
          fieldName="author"
          label="Authors"
          control={control}
          options={authors}
          optionValue="user"
          optionLabel="name_reversed"
          onChange={(e) => onFormValueChange({ author: e })}
          placeholder="Select Authors or start typing"
          required={false}
          noPadding
        />
      </form>
    </Paper>
  );
}

export default TopicFilterMenu;
