import { CheckBoxCell } from 'components/Table';
import MultiAutocompleteCell from 'components/Table/MultiAutocompleteCell';
import {
  NotificationModel,
  NotificationSetting,
  NotificationTypeDetails
} from 'models/notification-model';
import { ProjectUserModel } from 'models/project-model';
import { UserGroupModel } from 'models/user-groups-model';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useMemo } from 'react';

/**
 * Table view for the notifications settings
 *
 * @param loading if the table is loading
 * @param notifications the notifications
 * @param onEdit the edit handler
 * @param projectUser the project user
 * @param userGroups the user groups
 * @param projectUserGroupSelected the project that has their user group dropdown expanded
 * @param setProjectUserGroupSelected the set project user group selected handler
 * @component
 */
const NotificationView = function ({
  loading = false,
  notifications,
  onEdit,
  projectUser,
  userGroups,
  projectUserGroupSelected,
  setProjectUserGroupSelected
}: {
  loading: boolean;
  notifications: NotificationModel[];
  onEdit: (notification: NotificationModel) => void;
  projectUser?: ProjectUserModel;
  userGroups: UserGroupModel[];
  projectUserGroupSelected: string;
  setProjectUserGroupSelected: (val: string) => void;
}) {
  const userGroupOptions = useMemo(() => {
    // Seperate the user groups into groups that the user is a part of
    // and groups that the user is not a part of
    const userUserGroups: UserGroupModel[] = [];
    const otherUserGrups: UserGroupModel[] = [];
    const noUserGroupsMessage = `<div class="no-usergroups-message"><i className="pi pi-ban mr-2 text-sm"></i>You don't have user groups</div>`;
    userGroups.forEach((userGroup) => {
      if (projectUser?.user_groups.includes(userGroup.id)) {
        userUserGroups.push(userGroup);
      } else {
        otherUserGrups.push(userGroup);
      }
    });
    return [
      'My user groups',
      ...(userUserGroups.length > 0 ? userUserGroups : [noUserGroupsMessage]),
      'Other user groups',
      ...otherUserGrups
    ];
  }, [userGroups]);

  return (
    <DataTable
      className="w-full bg-cyan-500"
      value={notifications}
      loading={loading}
      pt={{ thead: { className: 'bg-blue-500' } }}
    >
      <Column
        className="specto-table__column--xxl bg-white"
        header="Type of the Notification"
        headerClassName="text-black-alpha-90"
        field="type"
        body={(node: NotificationModel) => (
          <div>
            <h6>{NotificationTypeDetails[node.type].title}</h6>
            <div>{NotificationTypeDetails[node.type].description}</div>
          </div>
        )}
      />
      <Column
        className="specto-table__column--sm bg-white"
        header="My Content"
        headerClassName="text-black-alpha-90"
        field="my_content_only"
        alignHeader="center"
        body={(node: NotificationModel) => (
          <div className="flex w-full justify-content-center">
            <CheckBoxCell
              checked={node.setting === NotificationSetting.MY_CONTENT_ONLY}
              onChange={(e) =>
                onEdit({
                  ...node,
                  setting: e.checked
                    ? NotificationSetting.MY_CONTENT_ONLY
                    : NotificationSetting.DISABLED
                })
              }
            />
          </div>
        )}
      />
      <Column
        className="specto-table__column--sm bg-white"
        header="All Content"
        field="all_content"
        alignHeader="center"
        headerClassName="text-black-alpha-90"
        body={(node: NotificationModel) => (
          <div className="flex w-full justify-content-center">
            <CheckBoxCell
              checked={node.setting === NotificationSetting.ALL_CONTENT}
              onChange={(e) =>
                onEdit({
                  ...node,
                  setting: e.checked
                    ? NotificationSetting.ALL_CONTENT
                    : NotificationSetting.DISABLED
                })
              }
            />
          </div>
        )}
      />
      <Column
        className="specto-table__column--lg bg-white"
        header="User Groups"
        field="user_groups"
        headerClassName="text-black-alpha-90"
        body={(node: NotificationModel) => (
          <MultiAutocompleteCell
            options={userGroupOptions}
            value={node.user_groups}
            onChange={(val) => onEdit({ ...node, user_groups: val })}
            optionLabel="name"
            optionValue="id"
            disabled={node.setting !== NotificationSetting.ALL_CONTENT}
            tooltip={
              node.setting !== NotificationSetting.ALL_CONTENT
                ? 'Select "All Content" to filter by User Groups'
                : undefined
            }
            placeholder="Filter by User Groups"
            onOpen={() => setProjectUserGroupSelected(`${node.project}-${node.type}`)}
            onClose={() => setProjectUserGroupSelected('')}
            onClickAway={() => setProjectUserGroupSelected('')}
            open={projectUserGroupSelected === `${node.project}-${node.type}`}
          />
        )}
      />
    </DataTable>
  );
};

export default NotificationView;
