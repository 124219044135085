import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SynonymTableDataModel } from 'models/synonym-model';
import { useAppSelector } from 'hooks/store';
import { CommentPopup, ThreadStateChangeEvent } from 'pages/Comments/CommentPopup';
import { CommentType } from 'models/comment-model';
import { CommentThreadButton } from 'pages/Comments/CommentThreadButton';
import { ChipsCell, LastModifiedCell, TableHeader } from 'components/Table';
import { versionsSelector } from 'features/versions/versionsSlice';
import { confirmDialog } from 'primereact/confirmdialog';
import { MenuButtonCell } from 'components/Table/MenuButtonCell';
import paginatorTemplate from 'components/Table/PaginatorTemplate';
import { customSortIconFn } from 'util/table';

type SynonymDataTablePropsModel = {
  synonyms: SynonymTableDataModel[];
  canDelete: boolean;
  onDeleteSynonym?(e: SynonymTableDataModel): void;
  onThreadStateChange?(options: ThreadStateChangeEvent): void;
  readOnly?: boolean;
  onEditInit?(e: SynonymTableDataModel): void;
  showComments?: boolean;
};

/**
 * Component for displaying the Synonym Data Table.
 *
 * @param synonyms List of synonyms
 * @param canDelete Flag indicating if the user can delete
 * @param onRowEditComplete Callback function for when a row is edited
 * @param onDeleteSynonym Callback function for deleting a synonym
 * @param onThreadStateChange Callback function for the thread state change action
 * @param readOnly Flag indicating if the table is read-only
 * @param showComments if the comments should be shown
 *
 * @constructor
 * @component
 */
const SynonymDataTable = function ({
  synonyms,
  canDelete,
  onDeleteSynonym,
  onThreadStateChange,
  readOnly = false,
  onEditInit,
  showComments
}: SynonymDataTablePropsModel) {
  const version = useAppSelector(versionsSelector);
  // Store the synonym that will be deleted
  const [selectedNode, setSelectedNode] = useState<SynonymTableDataModel>(
    {} as SynonymTableDataModel
  );
  const [showCommentPopup, setShowCommentPopup] = useState(false);

  /* - - - - - - - - - - Dialogs - - - - - - - - - - */

  /**
   * Opens the comments dialog
   * @param node object to open the comments dialog for
   */
  const openComments = (node: any) => {
    setSelectedNode(node);
    setShowCommentPopup(true);
  };

  /**
   * Delete confirmation popup
   * @param node node to delete
   */
  const confirmDeleteRow = (node: SynonymTableDataModel) => {
    confirmDialog({
      message: (
        <div>
          <h6>This action cannot be undone.</h6>
          <ul className="list-disc">
            <li>This Synonym cannot be recovered.</li>
          </ul>
        </div>
      ),
      header: 'Delete Row?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => onDeleteSynonym?.(node)
    });
  };

  return (
    <div>
      <h6>{TableHeader({ tableType: 'Synonym', itemCount: synonyms.length })}</h6>
      <DataTable
        className="specto-synonym__table"
        dataKey="id"
        sortIcon={customSortIconFn}
        value={synonyms}
        paginator
        paginatorTemplate={paginatorTemplate}
        selectionAutoFocus={false}
        rows={5}
        editMode="row"
        onRowDoubleClick={(e) =>
          !version.isVersionSelected && !readOnly && onEditInit?.(e.data as SynonymTableDataModel)
        }
        metaKeySelection={false}
      >
        <Column field="text" className="specto-table__column--md" header="Synonym" sortable />
        <Column
          field="phrases"
          className="specto-table__column--xl"
          header="Words/Phrases"
          body={(node) => ChipsCell({ nodeId: node.id, chips: node.phrases })}
        />
        <Column
          field="last_modified"
          className="specto-table__column--md"
          header="Last Modified"
          alignHeader="left"
          align="left"
          body={(node) =>
            LastModifiedCell({
              nodeId: node.id,
              user: node.user,
              lastModified: node.last_modified
            })
          }
          sortable
        />
        <Column
          field="util-comments"
          hidden={!showComments || readOnly || version.isVersionSelected}
          header="Comments"
          className="specto-table-icon"
          body={(node: SynonymTableDataModel) => (
            <CommentThreadButton
              key={`${node.id}-comments`}
              threadResolved={node.comment_thread_resolved}
              action={() => openComments(node)}
            />
          )}
        />
        <Column
          columnKey="util-action"
          className="specto-table__column--sm-2 pl-0"
          header="Actions"
          alignHeader="center"
          align="center"
          rowEditor
          hidden={readOnly || version.isVersionSelected}
          body={(node: SynonymTableDataModel) => (
            <MenuButtonCell
              data-cy={`${node.id}-actions`}
              menu={[
                {
                  label: 'Edit',
                  command: () => onEditInit?.(node)
                },
                {
                  label: 'Delete',
                  visible: canDelete,
                  command: () => confirmDeleteRow(node)
                }
              ]}
            />
          )}
        />
      </DataTable>

      <CommentPopup
        displayPopup={showCommentPopup}
        onPopupDisplayChange={setShowCommentPopup}
        objectType={CommentType.SYNONYM}
        objectId={selectedNode.id}
        objectName={selectedNode.text}
        parentElement={document.activeElement as HTMLElement}
        onThreadStateChange={onThreadStateChange}
      />
    </div>
  );
};

export default SynonymDataTable;
