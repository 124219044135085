import React, { useContext, useEffect, useState } from 'react';
import { ResponseFormContext } from './ResponseFormContext';
import { DropdownField, TextField } from 'components/Forms';
import ResponseComponentContainer from './ResponseComponentContainer';
import { ResponseLiveChatService } from 'models/response-table-data-model';
import { projectsSelector } from 'features/projects/projectsSlice';
import { useAppSelector } from 'hooks/store';
import { isAdmin } from 'util/permissions';

enum LiveChatServiceFields {
  HostUrl,
  TenantName
}

const liveChatServicesRecord: Record<string, LiveChatServiceFields[]> = {
  [ResponseLiveChatService.Five9]: [
    LiveChatServiceFields.HostUrl,
    LiveChatServiceFields.TenantName
  ],
  [ResponseLiveChatService.ServiceNow]: [LiveChatServiceFields.HostUrl]
};

/**
 * Component that displays the response form for the Live Chat
 * @param componentIndex Index of the component
 * @component
 */
const ResponseLiveChat = function ({ componentIndex }: { componentIndex: number }) {
  const { control, getValues, watch, setValue } = useContext(ResponseFormContext);
  const { selectedProject } = useAppSelector(projectsSelector);
  const baseField = `content.${componentIndex}`;
  const contentField = `${baseField}.content`;
  const contentValueField = `${baseField}.contentValue`;
  const liveChatServiceWatch: ResponseLiveChatService = watch(contentValueField + '.service');
  const [liveChatServiceValue, setLiveChatServiceValue] = useState('');

  useEffect(() => {
    setLiveChatServiceValue(getValues(contentField + '.service'));
  }, []);

  useEffect(() => {
    const contentValues = getValues(contentValueField);
    setLiveChatServiceValue(contentValues?.service);
    if (contentValues?.service === ResponseLiveChatService.ServiceNow) {
      delete contentValues.tenant_name;
      setValue(contentValueField, contentValues);
    }
  }, [liveChatServiceWatch]);

  return (
    <ResponseComponentContainer
      componentIndex={componentIndex}
      title="Response Live Chat"
      key={componentIndex}
    >
      <>
        <DropdownField
          className="w-full mb-0 pt-4"
          label="Live Chat Service"
          defaultValue={getValues(contentField + '.service')}
          fieldName={contentValueField + '.service'}
          control={control}
          options={[ResponseLiveChatService.Five9, ResponseLiveChatService.ServiceNow]}
          disabled={!isAdmin(selectedProject.project_user.role)}
          info="The service that will be used to host the live chat"
        />
        {liveChatServicesRecord[liveChatServiceValue]?.includes(LiveChatServiceFields.HostUrl) && (
          <TextField
            className="w-full"
            label="Live Chat Host Url"
            defaultValue={getValues(contentField + '.host')}
            fieldName={contentValueField + '.host'}
            control={control}
            disabled={!isAdmin(selectedProject.project_user.role)}
            info="The host url of the live chat"
          />
        )}
        {liveChatServicesRecord[liveChatServiceValue]?.includes(
          LiveChatServiceFields.TenantName
        ) && (
          <TextField
            className="w-full"
            label="Live Chat Tenant Name"
            defaultValue={getValues(contentField + '.tenant_name')}
            fieldName={contentValueField + '.tenant_name'}
            control={control}
            disabled={!isAdmin(selectedProject.project_user.role)}
            info="The tenant name of the live chat"
          />
        )}
      </>
    </ResponseComponentContainer>
  );
};

export default ResponseLiveChat;
