import React from 'react';
import { TagModel } from 'models/tag-model';
import { Chip } from 'primereact/chip';
import TagTemplate from './TagTemplate';

/**
 * PrimeReact ItemTemplate sometimes returns undefined.
 * Undefined must be returned so the component knows to render the placeholder text
 * @param tag optional tag parameter
 */
const ChipTagTemplate = (tag?: TagModel) =>
  tag ? <Chip className="specto-tag-chip" template={TagTemplate(tag)} /> : undefined;

export default ChipTagTemplate;
