import classNames from 'classnames';
import { Button } from 'primereact/button';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { isString } from 'lodash';

/**
 * Component accepts file inputs.
 * @component
 * @param param0 Prop object containing the following:

 *    buttonLabel: label that displays on the button
 *    className: Additional CSS to be added
 *    control: react-hook-form control
 *    defaultValue: the default value
 *    disabled: interact-able or not
 *    fieldName: the field name in react-hook-form
 *    infoText: extra text that displays below
 *    label: label for the input
 */
const noFileText = 'No File Selected';
const FileInputField = function ({
  buttonLabel,
  className,
  control,
  defaultValue,
  disabled = false,
  fieldName,
  infoText,
  label
}: {
  buttonLabel: string;
  className?: string;
  control: Control;
  defaultValue?: boolean;
  disabled?: boolean;
  fieldName: string;
  infoText?: string;
  label?: string;
}) {
  const hiddenFileInput: React.RefObject<HTMLInputElement> = React.createRef();
  return (
    <div className={classNames('field', className)}>
      <label htmlFor={fieldName}>{label}</label>
      <div>
        <Controller
          name={fieldName}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <div className="flex flex-wrap">
              <Button
                type="button"
                label={buttonLabel}
                className="w-auto"
                onClick={() => hiddenFileInput.current?.click() ?? undefined}
                disabled={disabled}
                data-cy={`${fieldName}-button`}
              />
              <div className="flex align-items-center p-2">
                {field.value
                  ? isString(field.value)
                    ? field.value
                    : field.value.name
                  : noFileText}
              </div>
              <input
                name={field.name}
                ref={hiddenFileInput}
                type="file"
                className="hidden"
                onChange={(e) => field.onChange(e.target.files?.[0])}
                data-cy={`${fieldName}-file`}
              />
            </div>
          )}
        />
      </div>
      <div className="pt-2">{infoText}</div>
    </div>
  );
};

export default FileInputField;
