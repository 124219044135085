import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import ModalLarge from 'containers/modals/ModalLarge';
import { FieldValues, useForm } from 'react-hook-form';
import { authSelector, updateUser } from 'features/auth/authSlice';
import { createToast } from 'features/toast/toastSlice';
import useFetch from 'hooks/useFetch';
import AuthService from 'services/authService';
import { FetchStatusOptions } from 'constants/fetchStatus';
import { USER_SETTINGS_LAYOUT } from 'constants/layouts';
import { setLayout } from 'features/layout/layoutSlice';
import UserProfileForm from 'components/User/UserProfileForm';
import { EditUserOptionsModel, UserModel, UserRequestAPIModel } from 'models/user-model';
import UsersService from 'services/usersService';
import { dirtyValues } from 'util/form';
import { Button } from 'primereact/button';

/**
 * Page for generic user settings
 *
 * @component
 */
const UserSettings = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(authSelector);

  // Use user service for admins and auth service for all other users
  const {
    data: editUserData,
    fetch: editUser,
    fetchStatus: editUserStatus
  } = useFetch<EditUserOptionsModel, UserModel>(
    user.is_superuser ? UsersService.editUser : AuthService.updateUser,
    user.is_superuser ? UsersService.roles.update : AuthService.roles.update
  );

  const defaultValues: FieldValues = {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    is_superuser: user.is_superuser
  };
  const {
    control,
    formState: { dirtyFields },
    handleSubmit,
    reset
  } = useForm();

  useEffect(() => {
    dispatch(setLayout(USER_SETTINGS_LAYOUT));
    reset(defaultValues);
  }, []);

  const onUserProfileSubmit = (data: FieldValues) => {
    const formValues = dirtyValues(dirtyFields, data);

    if (Object.keys(formValues).length === 0) {
      return;
    }

    const userData: UserRequestAPIModel = {
      ...formValues
    };

    editUser({ id: user.id, user: userData });
  };

  // handle edit user effect
  useEffect(() => {
    if (editUserStatus === FetchStatusOptions.SUCCESS && editUserData) {
      dispatch(updateUser(editUserData));
      dispatch(createToast('user edited'));
      reset({
        first_name: editUserData.first_name,
        last_name: editUserData.last_name,
        email: editUserData.email,
        is_superuser: editUserData.is_superuser
      });
    }
  }, [editUserStatus]);

  const onSave = () => {
    handleSubmit(onUserProfileSubmit)();
  };

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSave();
  };

  return (
    <ModalLarge className="specto-system-settings">
      <h1 className="align-self-center mb-7">User Settings</h1>

      <div className="w-8 mb-5">
        <UserProfileForm
          control={control}
          user={user}
          selectedUser={user}
          onFormSubmit={onFormSubmit}
        />
      </div>

      <Button raised label="Save Changes" onClick={onSave} data-cy="save-changes" />
    </ModalLarge>
  );
};

export default UserSettings;
