import React from 'react';
import { Button } from 'primereact/button';
import { ConversationDetailMessageModel } from 'models/conversation-model';
import classnames from 'classnames';
import parse from 'html-react-parser';
import { intentsSelector } from 'features/projects/projectsSlice';
import { useAppSelector } from 'hooks/store';
import { mapIdToName } from 'util/mapIdAndName';
import { roundFloor } from 'util/round';
import MetadataIcon from 'components/Table/MetadataIcon';

type ConversationBubbleProps = {
  message: ConversationDetailMessageModel;
  id: number;
  canDelete?: boolean;
  onDelete?(): void;
  messageRef?: React.RefObject<HTMLDivElement>;
};

const ConversationBubble = function ({
  message,
  id,
  canDelete = true,
  onDelete,
  messageRef
}: ConversationBubbleProps) {
  const isUserResponse = message.type_name === 'user';
  const allIntents = useAppSelector(intentsSelector);
  canDelete = canDelete && isUserResponse && message.text !== null;
  const intent = mapIdToName(message.intent, allIntents);

  return (
    <div className="specto-conversation-bubble mb-6 text-center invisible-button-toggle">
      {message.type_name === 'action' && (
        <div className="specto-text-muted">
          {message.action_name} {message.confidence && <span>({message.confidence})</span>}
        </div>
      )}

      {message.type_name !== 'action' && (
        <div
          className={classnames('flex flex-nowrap justify-content-end', {
            'specto-conversation-bubble__user-response': isUserResponse,
            'specto-conversation-bubble__bot-message flex-row-reverse': !isUserResponse
          })}
        >
          {isUserResponse && (
            <MetadataIcon id={id} metadata={message.metadata} className="invisible-button" />
          )}
          {canDelete && (
            <div className="flex align-content-center">
              <Button
                icon="pi pi-trash"
                className="p-button-rounded specto-r-button-lg invisible-button"
                onClick={onDelete}
              />
            </div>
          )}
          <div className="specto-conversation-bubble__message flex align-items-center justify-content-center mx-1">
            <div className="p-3" ref={messageRef}>
              {parse(message.text ?? '*Message Deleted*')}
            </div>
          </div>
          <div>
            <Button
              icon={
                isUserResponse ? (
                  'pi pi-user'
                ) : (
                  <img
                    className="specto-conversation-bubble__bot-icon"
                    src="/assets/conversation-data/bot-icon.svg"
                    alt="bot-icon"
                  />
                )
              }
              className="p-button-rounded specto-r-button-lg pointer-events-none specto-text-muted specto-bg-muted border-none"
            />
          </div>
        </div>
      )}

      <div>
        {isUserResponse && intent && (
          <div className="mt-2 px-6 text-right">
            {intent}
            {message.confidence && <span>({roundFloor(message.confidence, 2)})</span>}
          </div>
        )}
      </div>
    </div>
  );
};

export default ConversationBubble;
