import classnames from 'classnames';
import React from 'react';

enum CustomImageSize {
  small = 'small',
  default = 'default'
}

const customImageSizeRecord: Record<string, string> = {
  [CustomImageSize.small]: '1.5rem'
};

type CustomIconProps = {
  src?: string;
  icon?: string;
  alt: string;
  className?: string;
  imageSize?: CustomImageSize | string;
};

export const CustomIcon = ({
  src,
  icon,
  alt,
  className,
  imageSize = CustomImageSize.default
}: CustomIconProps) =>
  src ? (
    <img
      src={src}
      alt={alt}
      className={classnames(className, imageSize === CustomImageSize.default && 'specto-svg-icon')}
      style={
        imageSize !== CustomImageSize.default
          ? { width: customImageSizeRecord[imageSize] ?? imageSize }
          : undefined
      }
    />
  ) : (
    <i className={classnames(className, icon, 'specto-r-button-lg')}></i>
  );
