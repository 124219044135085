import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { FieldValues, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import { useNavigate } from 'react-router-dom';

import { setLayout } from 'features/layout/layoutSlice';
import ModalLarge from 'containers/modals/ModalLarge';
import { spectoModules } from 'constants/modules';
import { ModuleModel } from 'models/module-model';
import { dirtyValues } from 'util/form';
import {
  addProject,
  exitProject,
  projectsSelector,
  removeProject as removeStateProject,
  updateProject
} from 'features/projects/projectsSlice';
import ProjectsService from 'services/projectsService';
import { createToast } from 'features/toast/toastSlice';
import { routes } from 'constants/routes';
import {
  EditProjectOptionsModel,
  ProjectsPatchRequestAPIModel,
  ProjectSummaryModel
} from 'models/project-model';
import { ResponseLanguageTranslation } from 'models/language-model';
import useFetch from 'hooks/useFetch';
import { FetchStatusOptions } from 'constants/fetchStatus';
import { SlugOptionsModel } from 'models/api-model';
import { DropdownField, TextField, ToggleSwitch } from 'components/Forms';
import { NamedDeletionDialog } from 'components/Dialogs';
import MultiSelectField from 'components/Forms/MultiSelectField';
import { PERMISSIONS_WARNING } from 'constants/warnings';
import { authSelector } from 'features/auth/authSlice';
import { getProjectSettingsLayout } from 'util/layout';

const responseLanguageOptions: { value: ResponseLanguageTranslation; label: string }[] = [
  { value: ResponseLanguageTranslation.English, label: 'None (English Only)' },
  { value: ResponseLanguageTranslation.Automated, label: 'Specto Backend' },
  { value: ResponseLanguageTranslation.Browser, label: 'Browser' }
];

const ProjectSettings = () => {
  const dispatch = useAppDispatch();
  const { selectedProject, supportedLanguages } = useAppSelector(projectsSelector);
  const { user } = useAppSelector(authSelector);
  const { control: moduleControl } = useForm();
  const { control: responseLanguageControl } = useForm();
  const navigate = useNavigate();
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const {
    data: editProjectResponseData,
    fetch: editProject,
    fetchStatus: editProjectStatus,
    fetchOptions: editProjectOptions
  } = useFetch<EditProjectOptionsModel, ProjectSummaryModel>(
    ProjectsService.editProject,
    ProjectsService.roles.project.update
  );
  const {
    fetch: removeProject,
    fetchStatus: removeProjectStatus,
    fetchOptions: removeProjectOptions,
    permission: canDeleteProject
  } = useFetch<SlugOptionsModel>(
    ProjectsService.deleteProject,
    ProjectsService.roles.project.delete
  );
  const {
    fetch: duplicateProject,
    fetchStatus: duplicateProjectStatus,
    data: duplicateProjectData,
    permission: canCreateProject
  } = useFetch(ProjectsService.duplicateProject, ProjectsService.roles.project.create);

  const defaultValues: FieldValues = {
    company: selectedProject.company,
    manager: selectedProject.manager,
    name: selectedProject.name,
    sentiment: selectedProject.sentiment
  };
  const {
    control: projectControl,
    reset,
    formState: { dirtyFields },
    handleSubmit
  } = useForm({ defaultValues });

  // onInit
  useEffect(() => {
    dispatch(setLayout(getProjectSettingsLayout(user.is_superuser)));
  }, []);

  const onEditProject = (key: string, value: boolean) => {
    const projectRequest: ProjectsPatchRequestAPIModel = {
      [key]: value
    };

    editProject({ project: projectRequest, slug: selectedProject.slug });
  };

  const onEditProjectFormSubmit = (data: FieldValues) => {
    const formValues = dirtyValues(dirtyFields, data);

    if (Object.keys(formValues).length === 0) {
      return;
    }

    const projectRequest: ProjectsPatchRequestAPIModel = {
      ...formValues
    };

    editProject({ project: projectRequest, slug: selectedProject.slug });
  };

  // edit project Effect
  useEffect(() => {
    if (editProjectStatus === FetchStatusOptions.SUCCESS && editProjectResponseData) {
      dispatch(updateProject(editProjectResponseData));
      reset(editProjectResponseData);
      if (editProjectOptions && 'sentiment' in editProjectOptions.project) {
        // Show a different toast message when the sentiment module is enabled/disabled
        dispatch(
          createToast(
            `Sentiment Module ${editProjectOptions.project.sentiment ? 'enabled' : 'disabled'}`
          )
        );
      } else {
        dispatch(createToast('Project details edited'));
      }
    }
  }, [editProjectStatus]);

  const deleteProject = () => {
    setShowConfirmDelete(false);
    setTimeout(() => removeProject({ slug: selectedProject.slug }), 500);
  };

  useEffect(() => {
    if (removeProjectStatus === FetchStatusOptions.SUCCESS && removeProjectOptions) {
      navigate(routes.HOME); // navigate first before exiting project
      dispatch(removeStateProject(removeProjectOptions.slug));

      setTimeout(() => {
        dispatch(exitProject());
        dispatch(createToast('Project deleted'));
      }, 750);
    }
  }, [removeProjectStatus]);

  // Duplicate project effect
  useEffect(() => {
    if (duplicateProjectStatus === FetchStatusOptions.SUCCESS && duplicateProjectData) {
      dispatch(addProject(duplicateProjectData));
      dispatch(createToast('Project successfully duplicated'));
    }
  }, [duplicateProjectStatus]);

  /**
   * Dialog for confirming project duplication
   */
  const duplicateDialog = () =>
    confirmDialog({
      message: 'This will create a copy of this project and any data associated with it',
      header: 'Duplicate Project?',
      icon: 'pi pi-info-circle',
      accept: () => duplicateProject({ slug: selectedProject.slug })
    });

  const editProjectFormValidate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit(onEditProjectFormSubmit)();
  };

  return (
    <ModalLarge
      className="specto-project-settings"
      loading={removeProjectStatus === FetchStatusOptions.LOADING}
    >
      <h1 className="align-self-center mb-7">General</h1>

      <form
        className="p-fluid mt-3 flex flex-wrap justify-content-between"
        onSubmit={editProjectFormValidate}
      >
        <h5>Project Details</h5>

        <div className="specto-text-medium w-full mb-6">
          Required fields are marked with <span className="specto-form-asterisk-color">*</span>
        </div>

        <TextField
          className="lg:w-5 w-full specto-nav-skip"
          fieldName="company"
          label="Company"
          control={projectControl}
        />
        <TextField
          className="lg:w-5 w-full"
          fieldName="manager"
          label="Manager"
          control={projectControl}
        />

        <div className="field mb-5 lg:w-5 w-full flex align-content-center flex-order-4 lg:flex-order-3">
          <Button
            type="submit"
            loading={editProjectStatus === FetchStatusOptions.LOADING}
            label="Update Details"
            className="m-auto py-2 px-3 font-bold"
          />
        </div>

        <TextField
          className="mb-5 lg:w-5 w-full flex-order-3 lg:flex-order-4"
          fieldName="name"
          label="Name"
          control={projectControl}
        />
      </form>

      <div className="mt-6">
        <h5>Project Actions</h5>
        <Button
          disabled={!canCreateProject}
          type="button"
          label="Duplicate Project"
          tooltip={PERMISSIONS_WARNING}
          className="w-auto font-semibold p-button-text underline pt-2"
          onClick={duplicateDialog}
          tooltipOptions={{
            disabled: canDeleteProject,
            showOnDisabled: true
          }}
        />

        <h6 className="p-error">Danger Zone</h6>
        <Button
          disabled={!canDeleteProject}
          type="button"
          label="Delete Project"
          className="w-auto font-semibold p-button-text underline pt-2"
          tooltip={PERMISSIONS_WARNING}
          tooltipOptions={{
            disabled: canDeleteProject,
            showOnDisabled: true
          }}
          onClick={() => setShowConfirmDelete(true)}
        />
      </div>

      <form className="w-full mt-3">
        <h5 className="mt-8">Modules</h5>
        {spectoModules.map((module: ModuleModel) => (
          <div className="w-full mb-4" key={module.id}>
            <hr className="w-8" />
            <div className="flex justify-content-between align-items-center w-full">
              <div>
                <span className="font-semibold">{module.title}</span>
                <br />
                <span>{module.description}</span>
              </div>

              <ToggleSwitch
                className="mt-2"
                fieldName={module.id}
                control={moduleControl}
                defaultValue={defaultValues[module.id]}
                onChange={(e) => onEditProject(module.id, Boolean(e.value))}
              />
            </div>
          </div>
        ))}
      </form>

      {/* <form className="w-full mt-8">
        <h5>Multi-Language Support</h5>
        <hr className="w-8" />

        <div className="grid flex-wrap justify-content-between mt-6">
          <div className="w-12 md:w-6">Translation Mode</div>

          <DropdownField
            className="w-12 md:w-6 mb-4"
            fieldName="inputChanel"
            control={responseLanguageControl}
            defaultValue={selectedProject.translation}
            options={responseLanguageOptions}
            optionLabel="label"
            optionValue="value"
            onChange={(e) => onEditProject('translation', e.value)}
            noPadding
          />

          <div className="w-12 md:w-6">Languages</div>

          <MultiSelectField
            className="w-12 md:w-6"
            fieldName="supportedLanguage"
            control={responseLanguageControl}
            options={supportedLanguages}
            onChange={(e) => onEditProject('languages', e.value)}
            defaultValue={selectedProject.languages}
            optionValue="id"
            optionLabel="name"
            maxSelectedLabels={4}
            showSelectAll={true}
            noPadding
          />
        </div>
      </form> */}

      <NamedDeletionDialog
        confirmName={selectedProject.name}
        prompt="Enter the project name to confirm deletion:"
        visible={showConfirmDelete}
        onHide={() => setShowConfirmDelete(false)}
        accept={() => deleteProject()}
      />
    </ModalLarge>
  );
};

export default ProjectSettings;
