import { NOTIFICATIONS_API_URL } from 'constants/api';
import { OPEN_PERMISSIONS } from 'constants/roles';
import { NotificationModel } from 'models/notification-model';
import ApiService from 'services/apiService';

const getNotifications = () => ApiService.get<NotificationModel[]>(NOTIFICATIONS_API_URL);

const postNotification = (options: NotificationModel) =>
  ApiService.post<NotificationModel, NotificationModel>(NOTIFICATIONS_API_URL, options);

const NotificationsService = {
  getNotifications,
  postNotification,
  roles: OPEN_PERMISSIONS
};

export default NotificationsService;
