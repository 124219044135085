// convert complex permission array to boolean
import { ProjectRole } from 'models/project-model';

/**
 * Check if you have a certain permission
 * @param permissions list of permissions
 * @param role current user role
 */
export const permissionBoolean = (
  permissions: undefined | boolean | ProjectRole[],
  role: ProjectRole
) => (Array.isArray(permissions) ? permissions.includes(role) : Boolean(permissions));

/**
 * Returns whether the user is an admin or not
 * The user is considered an admin if their role is either ADMIN or NEUROSOPH
 * @param role role of the user in the project
 * @returns boolean
 */
export const isAdmin = (role: ProjectRole) =>
  [ProjectRole.ADMIN, ProjectRole.NEUROSOPH].includes(role);

/**
 * Returns whether the user is a superuser or not
 * @param role
 * @returns
 */
export const isSuperUser = (role: ProjectRole) => ProjectRole.NEUROSOPH === role;
