import { useState } from 'react';

type UsePaginateOptions = {
  limit?: number;
  offset?: number;
  total?: number;
};

const usePaginate = (defaultValues?: UsePaginateOptions) => {
  // this const must be inside the hook or else state will mutate between evocations
  const defaultPaginateOptions: Record<string, number> = {
    limit: 5,
    offset: 0
  };

  const [limit, setLimit] = useState(defaultValues?.limit ?? defaultPaginateOptions.limit);
  const [offset, setOffset] = useState(defaultValues?.offset ?? defaultPaginateOptions.offset);
  const [total, setTotal] = useState(defaultValues?.total);

  return {
    limit,
    setLimit,
    offset,
    setOffset,
    total,
    setTotal
  };
};

export default usePaginate;
