import { IconButtonCell } from 'components/Table';
import React from 'react';
import classnames from 'classnames';

/**
 * Button customized to open the comment thread.
 *
 * @param action action to perform
 * @param className custom class name
 * @param 'data-cy' cypress tag
 * @param hidden whether to show or hide the comment
 * @param threadResolved whether the thread is resolved or not, affects the icon
 */
export const CommentThreadButton = function ({
  action,
  className,
  'data-cy': cypressTag,
  hidden,
  threadResolved
}: {
  'data-cy'?: string;
  action: () => void;
  className?: string;
  hidden?: boolean;
  threadResolved: boolean | null;
}) {
  return (
    <IconButtonCell
      ariaLabel="comment"
      className={classnames(className, 'mx-auto')}
      hidden={hidden}
      icon={threadResolved === null ? 'pi-comment' : 'pi-comments'}
      badgeSeverity={threadResolved === false ? 'danger' : undefined}
      tooltip={
        threadResolved === null
          ? 'Start a comment thread'
          : threadResolved
            ? 'See comments'
            : 'See or add comments'
      }
      action={action}
      data-cy={cypressTag}
    />
  );
};
