import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import ToggleSwitch from 'components/Forms/ToggleSwitch';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { TextField } from 'components/Forms';
import validator from 'validator';

/**
 * Dialog used for accepting a hyperlink and hyperlinks target
 *
 * @param accept fn to run on accept
 * @param defaultValueHref default href value
 * @param defaultValueTarget default target value
 * @param insertMode toggle mode where modal will insert hyperlink text
 * @param maxLength max length of input fields
 * @param onHide fn to run on hide
 * @param reject fn to run on reject
 * @param visible whether the dialog is visible
 * @constructor
 */
export const HyperlinkDialog = ({
  accept,
  defaultValueHref,
  defaultValueTarget,
  insertMode,
  maxLength,
  onHide,
  reject,
  visible
}: {
  accept(href: string, target: string, text?: string): void;
  defaultValueHref?: string;
  defaultValueTarget?: string;
  insertMode?: boolean;
  maxLength?: number;
  onHide(): void;
  reject?(): void;
  visible: boolean;
}) => {
  const { reset, control, handleSubmit } = useForm();

  //update default values on init
  // component is mounted when visible is true
  useEffect(() => {
    reset({
      href: defaultValueHref || '',
      target: defaultValueTarget || '_self'
    });
  }, [visible]);

  // handle save button click
  const onDialogSave = () => {
    handleSubmit((data) => {
      accept(data.href.trim(), data.target.trim(), data.text?.trim());
      onHide();
    })();
  };

  // handle remove hyperlink button click
  const onRemoveHyperlink = () => {
    reject && reject();
    onHide();
  };

  return (
    <Dialog visible={visible} onHide={onHide} draggable={false} header="Insert Hyperlink">
      <form
        className="flex flex-wrap"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {insertMode && (
          <div className="w-full">
            <TextField
              className="line-height-4"
              fieldName="text"
              label="Hyperlink Text"
              control={control}
              required={true}
              info="The text of the link that will appear to the user"
              rules={{ validate: (value) => (value?.trim() || '').length > 0 }}
            />
          </div>
        )}

        <div className="w-full">
          <TextField
            className="line-height-4"
            fieldName="href"
            label="Hyperlink URL"
            control={control}
            required={true}
            characterLimit={maxLength}
            rules={{
              required: 'Required field',
              validate: (value) => value === '' || validator.isURL(value) || 'Invalid URL'
            }}
            info="The URL the user will be brought to when the link is triggered"
          />
        </div>

        <div className="flex align-content-center align-items-center w-full">
          <ToggleSwitch
            className="mr-5 h-max"
            label="Open link in"
            control={control}
            fieldName="target"
            falseLabel="Same Tab"
            falseValue="_self"
            trueLabel="New Tab"
            trueValue="_blank"
            defaultValue={defaultValueTarget === '_blank'}
            aria-label="hyperlink target toggle"
            ariaLabel="open links"
            inlineLabel={true}
            info="For internal links, set to open in the same tab. For external links, set to open in a new tab."
          />
        </div>
      </form>
      <div className="flex justify-content-end mt-3 gap-3 w-full">
        <Button
          className={classNames('border-2 p-button-outlined ', {
            hidden: insertMode
          })}
          onClick={() => onRemoveHyperlink()}
        >
          Remove Hyperlink
        </Button>
        <Button onClick={() => onDialogSave()} className="border-2 p-button border-2">
          Save
        </Button>
      </div>
    </Dialog>
  );
};

export default HyperlinkDialog;
