import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Button } from 'primereact/button';
import { setLayout } from 'features/layout/layoutSlice';
import ModalLarge from 'containers/modals/ModalLarge';
import { FieldValues, useForm } from 'react-hook-form';
import { dirtyValues } from 'util/form';
import LoadingSpinner from 'components/LoadingSpinner';
import SearchBar from 'components/SearchBar';
import { FetchStatusOptions } from 'constants/fetchStatus';
import useFilter from 'hooks/useFilter';
import {
  CategoryModel,
  CategoryPatchRequestAPIModel,
  CategoryPostRequestAPIModel,
  PatchCategoryOptionsModel,
  PostCategoryOptionsModel
} from 'models/category-model';
import useToggleKeys from 'hooks/useToggleKeys';
import useFetch from 'hooks/useFetch';
import CategoriesService from 'services/categoriesService';
import {
  addCategory,
  categoriesSelector,
  deleteCategory as deleteCategoryState,
  editCategory as editCategoryState,
  projectsSelector
} from 'features/projects/projectsSlice';
import { confirmDialog } from 'primereact/confirmdialog';
import { createToast } from 'features/toast/toastSlice';
import { SlugIdOptionsModel, SlugOptionsModel } from 'models/api-model';
import { TextField } from 'components/Forms';
import { DataView } from 'primereact/dataview';
import { authSelector } from 'features/auth/authSlice';
import { getProjectSettingsLayout } from 'util/layout';

const CategoryManager = () => {
  const dispatch = useAppDispatch();
  const { selectedProject } = useAppSelector(projectsSelector);
  const categories = useAppSelector(categoriesSelector);
  const { user } = useAppSelector(authSelector);
  const {
    toggleKeySingle: setCategoryEditMode,
    isKeyExpanded: isCategoryEditMode,
    clearKeys: clearAllCategoryEditMode,
    hasExpandedKeys: someCategoryEditMode,
    keys: editModeCategories
  } = useToggleKeys();
  const {
    toggleKeySingle: setCategoryRowLoading,
    isKeyExpanded: isCategoryRowLoading,
    clearKeys: clearAllCategoriesRowLoading
  } = useToggleKeys();
  const {
    filteredData: filteredCategoriesData,
    search: searchCategories,
    searchText,
    clearSearch,
    simpleSearch,
    setData: setFilteredCategoriesData,
    loading: searchCategoriesLoading
  } = useFilter({ searchParameters: ['name'] });
  const { fetchStatus: getCategoriesStatus } = useFetch<SlugOptionsModel, CategoryModel[]>(
    CategoriesService.getCategories,
    CategoriesService.roles.list
  );
  const {
    data: editCategoryData,
    fetchStatus: editCategoriesStatus,
    fetch: editCategory
  } = useFetch<PatchCategoryOptionsModel, CategoryModel>(
    CategoriesService.editCategory,
    CategoriesService.roles.update
  );
  const {
    fetchStatus: deleteCategoriesStatus,
    fetch: deleteCategory,
    fetchOptions: deleteCategoryOptions
  } = useFetch<SlugIdOptionsModel<CategoryModel['id']>, CategoryModel>(
    CategoriesService.deleteCategory,
    CategoriesService.roles.delete
  );
  const {
    data: createCategoryData,
    fetchStatus: createCategoriesStatus,
    fetch: createCategory
  } = useFetch<PostCategoryOptionsModel, CategoryModel>(
    CategoriesService.createCategory,
    CategoriesService.roles.create
  );

  const defaultValues: FieldValues = {
    newCategoryName: ''
  };
  const {
    control: newCategoryControl,
    handleSubmit: newCategoryHandleSubmit,
    reset: newCategoryFormReset
  } = useForm({
    defaultValues,
    mode: 'onSubmit'
  });

  const {
    control: editCategoryControl,
    formState: { dirtyFields: editCategoryDirtyFields },
    handleSubmit: editCategoryHandleSubmit,
    reset: editCategoryFormReset
  } = useForm();

  // onInit
  useEffect(() => {
    dispatch(setLayout(getProjectSettingsLayout(user.is_superuser)));
  }, []);

  // getCategories response effect
  useEffect(() => setFilteredCategoriesData(categories), [categories]);

  // editCategory response effect
  useEffect(() => {
    if (editCategoriesStatus === FetchStatusOptions.SUCCESS && editCategoryData) {
      editCategoryFormReset();
      clearAllCategoryEditMode();
      dispatch(editCategoryState(editCategoryData));
      dispatch(createToast('category edited'));
    }
  }, [editCategoriesStatus]);

  // createCategory response effect
  useEffect(() => {
    if (createCategoriesStatus === FetchStatusOptions.SUCCESS && createCategoryData) {
      dispatch(addCategory(createCategoryData));
      dispatch(createToast('category created'));
      newCategoryFormReset();
    }
  }, [createCategoriesStatus]);

  // deleteCategory response effect
  useEffect(() => {
    if (deleteCategoriesStatus === FetchStatusOptions.SUCCESS && deleteCategoryOptions) {
      editCategoryFormReset();
      clearAllCategoriesRowLoading();
      dispatch(deleteCategoryState(deleteCategoryOptions.id));
      dispatch(createToast('category deleted'));
    }
  }, [deleteCategoriesStatus]);

  const onAddCategoryFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    newCategoryHandleSubmit(onCreateCategory)();
  };

  const onEditCategoryFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    editCategoryHandleSubmit(onEditCategory)();
  };

  const onEditCategoryCancel = () => {
    clearAllCategoryEditMode();
    editCategoryFormReset();
  };

  const onCreateCategory = (data: FieldValues) => {
    const createCategoryRequest: CategoryPostRequestAPIModel = {
      name: data.newCategoryName
    };

    createCategory({ slug: selectedProject.slug, category: createCategoryRequest });
  };

  const onEditCategory = (data: FieldValues) => {
    const formValues = dirtyValues(editCategoryDirtyFields, data);
    if (Object.keys(formValues).length === 0 || !selectedProject || !someCategoryEditMode) {
      return;
    }

    // get the edit mode category id, it will be the only key expanded
    // should get this from the active edit mode useKey rather than the form.
    // useKeys guarantees only 1 edit mode active at a time, the form record formValues does not.
    const editModeCategoryId = Object.keys(editModeCategories)?.[0];

    // pipe local forms newCategoryName property to name, if it exists
    const editCategoryRequest: CategoryPatchRequestAPIModel = {
      id: editModeCategoryId,
      ...(formValues[editModeCategoryId]?.name && { name: formValues[editModeCategoryId].name })
    };
    editCategory({ slug: selectedProject.slug, category: editCategoryRequest });
  };

  const onCategoryDelete = (category: CategoryModel) => {
    // manually set the row loading for the specific category
    setCategoryRowLoading(category.id);
    deleteCategory({ slug: selectedProject.slug, id: category.id });
  };

  const confirmDeleteCategory = (category: CategoryModel) => {
    confirmDialog({
      message: 'This action cannot be undone.',
      header: 'Delete Category?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => onCategoryDelete(category)
    });
  };

  if (getCategoriesStatus === FetchStatusOptions.LOADING) {
    return <LoadingSpinner />;
  }

  const categoryRow = (category: CategoryModel) => (
    <div className="pl-2 w-full specto-system-settings__styled-list" key={category.id}>
      {isCategoryEditMode(category.id) ? (
        <div className="h-5rem flex justify-content-between align-items-center w-full">
          <TextField
            placeholder="Category Name"
            defaultValue={category.name}
            fieldName={category.id + '.name'}
            className="col md:mr-5 pl-0"
            control={editCategoryControl}
            noPadding
          />

          <div className="flex">
            <Button
              icon="pi pi-check"
              aria-label="Confirm Category Edit"
              className="p-button-rounded specto-r-button-lg"
              type="submit"
              loading={editCategoriesStatus === FetchStatusOptions.LOADING}
            />
            <Button
              icon="pi pi-times"
              aria-label="Cancel Category Edit"
              className="p-button-rounded specto-r-button-lg ml-1"
              onClick={onEditCategoryCancel}
              disabled={editCategoriesStatus === FetchStatusOptions.LOADING}
              type="button"
            />
          </div>
        </div>
      ) : (
        <div className="h-5rem flex justify-content-between align-items-center w-full">
          <div className="flex align-items-center justify-content-start">
            <div className="font-semibold mx-2">{category.name}</div>
          </div>

          <div className="flex">
            <Button
              icon="pi pi-pencil"
              aria-label="Edit Category"
              className="p-button-rounded specto-r-button-lg"
              onClick={() => setCategoryEditMode(category.id)}
              disabled={category.default || editCategoriesStatus === FetchStatusOptions.LOADING}
              tooltip="Cannot edit default categories"
              tooltipOptions={{
                showOnDisabled: true,
                disabled: !category.default,
                position: 'left'
              }}
              type="button"
            />
            <Button
              icon="pi pi-trash"
              aria-label="Delete Category"
              className="p-button-rounded specto-r-button-lg ml-1"
              onClick={() => confirmDeleteCategory(category)}
              loading={isCategoryRowLoading(category.id)}
              disabled={category.default || deleteCategoriesStatus === FetchStatusOptions.LOADING}
              tooltip="Cannot delete default categories"
              tooltipOptions={{ showOnDisabled: true, disabled: !category.default }}
              type="button"
            />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <ModalLarge className="specto-system-settings">
      <h1 className="align-self-center mb-7">Category Manager</h1>

      <h6>Intent Category Settings</h6>
      <span>Categories modified here effect the data model</span>
      <div className="col flex flex-wrap xl:align-items-center align-items-start justify-content-between flex-column-reverse xl:flex-row">
        <SearchBar
          className="specto-search-bar w-12 xl:w-8 mt-3 xl:mt-0"
          placeHolder="Search all Categories"
          text={searchText}
          formInputStyle
          loading={searchCategoriesLoading}
          onChange={(e) => simpleSearch(e.target.value)}
          onSubmitText={searchCategories}
          onClearText={() => {
            clearSearch();
            searchCategories('');
          }}
        />
      </div>

      <h6>Add Category</h6>
      <form className="w-full" onSubmit={onAddCategoryFormSubmit}>
        <div className="flex flex-column md:flex-row w-full align-content-start md:align-content-center justify-content-between">
          <TextField
            className="col md:mr-5 pl-0"
            fieldName="newCategoryName"
            control={newCategoryControl}
            placeholder="Category Name"
            noPadding
          />

          <div className="md:m-auto mt-3 flex justify-content-start">
            <Button
              label="Add Category"
              type="submit"
              loading={createCategoriesStatus === FetchStatusOptions.LOADING}
            />
          </div>
        </div>
      </form>

      <form className="w-full" onSubmit={onEditCategoryFormSubmit}>
        <DataView
          value={filteredCategoriesData}
          className="mt-5 w-full"
          itemTemplate={categoryRow}
          header={<h6>All Categories</h6>}
          paginator
          rows={5}
        />
      </form>
    </ModalLarge>
  );
};

export default CategoryManager;
