import React, { useState } from 'react';
import classnames from 'classnames';
import { Label } from 'models/message-inbox-table-data-model';
import { DuplicateNLUModel, NLUConflictType } from 'models/nlu-table-data-model';
import { Status } from 'models/status-model';
import { roundFloor } from './round';
import { uniqueId } from './uniqueIdGenerator';
import { Tooltip, TooltipProps } from 'primereact/tooltip';

export const getLabelStatus = <T extends { name: string; status: Status }>(
  allValues: T[],
  label?: { name: string }
) => {
  if (!label) {
    return null;
  }
  const mappedValue = allValues.find((value) => label?.name === value.name);
  return mappedValue?.status;
};

/**
 * Filter NLU conflicts to just a given conflict type
 * @param duplicates NLU duplicates
 * @param conflictType conflict type
 */
export const filterConflicts = (
  duplicates: DuplicateNLUModel,
  conflictType: NLUConflictType
): DuplicateNLUModel => duplicates.filter((conflict) => conflict[0] === conflictType);

/**
 * Reusable intent label item template
 * @param label Message Inbox label
 * @param dropdown if the template is a dropdown
 * @param icon custom icon
 * @param iconTooltip custom icon tooltip
 * @param truncatedNameTooltip if there should be truncation with a tooltip
 */
export const IntentSentimentLabelTemplate = ({
  label,
  dropdown = true,
  icon,
  iconTooltip,
  truncatedNameTooltip = false
}: {
  label?: Label;
  dropdown?: boolean;
  iconTooltip?: TooltipProps['children'];
  icon?: string;
  truncatedNameTooltip?: boolean;
}) => {
  const [tooltipKey] = useState(uniqueId());

  return label ? (
    <div
      className={classnames('flex justify-content-between align-content-center', {
        'px-3': dropdown
      })}
    >
      <div
        id={'specto-intent-label-template-name-' + tooltipKey}
        className="white-space-nowrap overflow-hidden text-overflow-ellipsis w-full"
      >
        {label?.name}
      </div>
      {truncatedNameTooltip && label?.name.length > 11 && (
        <Tooltip target={'#specto-intent-label-template-name-' + tooltipKey} position="bottom">
          {label?.name}
        </Tooltip>
      )}

      <div className="flex w-9rem">
        <div className="w-3 mr-1 flex flex-wrap justify-content-center align-content-center">
          {icon && <i id={'specto-intent-label-template-icon-' + tooltipKey} className={icon} />}
          {iconTooltip && (
            <Tooltip target={'#specto-intent-label-template-icon-' + tooltipKey} position="bottom">
              {iconTooltip}
            </Tooltip>
          )}
        </div>
        <div className="w-3 flex flex-wrap justify-content-center align-content-center">
          {label.is_predicted && <i className="pi pi-android" />}
        </div>
        <div className="w-6">{label.confidence && `(${roundFloor(label.confidence, 2)})`}</div>
      </div>
    </div>
  ) : (
    <></>
  );
};
