import React, { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
// components
import Popup from 'containers/Popup';
import { TagsField, TextArea, TextField } from 'components/Forms';
// utils
// models
import { EntityTableDataModel } from 'models/entity-model';
import { IntentTableDataModel } from 'models/intent-table-data-model';
import { ResponseTableDataModel } from 'models/response-table-data-model';
import { FormMode } from 'models/form-model';
import { TopicTableDataModel } from 'models/topic-model';
import { GenericItemTemplate } from 'components/Table';
import { filterRetired } from 'util/status';
import MultiAutocompleteField from 'components/Forms/MultiAutocompleteField';
import { filterDefault } from 'util/default';
import { UserGroupModel } from 'models/user-groups-model';
import { createUserGroupOptions } from 'util/userGroups';
import UserGroupsField from 'components/UserGroups/UserGroupsField';
import { ProjectMemberUserModel } from 'models/project-model';
import AutocompleteField from 'components/Forms/AutocompleteField';
import { filterAuthors } from 'util/users';
import { sortbyProperty } from 'util/arrays';

type TopicPopupPropsModel = {
  contextTopic?: TopicTableDataModel | null;
  displayPopup: boolean;
  entities: EntityTableDataModel[];
  intents: IntentTableDataModel[];
  isSuperUser?: boolean;
  loading: boolean;
  mode?: FormMode;
  onHide(): void;
  onSubmit(data: FieldValues): void;
  parentElement?: HTMLElement;
  projectUser: ProjectMemberUserModel;
  responses: ResponseTableDataModel[];
  userGroups: UserGroupModel[];
  projectMemberOptions: ProjectMemberUserModel[];
};

/**
 * Component that displays a popup to create a new topic.
 * @param contextTopic A list of all statuses.
 * @param displayPopup Boolean representing whether to display the popup or not.
 * @param entities A list of available entities.
 * @param intents A list of available intents.
 * @param isSuperUser whether the user is a superuser
 * @param loading Whether the form is loading.
 * @param mode The mode of the component.
 * @param onHide Callback function to hide the popup.
 * @param onSubmit Callback function on form submit.
 * @param parentElement Element to return focus to after the dialog is hidden.
 * @param responses A list of available responses.
 * @param projectUser The current project member logged in.
 * @param projectMemberOptions list of current project members.
 * @constructor
 * @component
 */
const TopicPopup = function ({
  contextTopic,
  displayPopup,
  entities,
  intents,
  isSuperUser = false,
  loading,
  mode = FormMode.CREATE,
  onHide,
  onSubmit,
  parentElement,
  projectUser,
  responses,
  userGroups,
  projectMemberOptions
}: TopicPopupPropsModel) {
  const { control, handleSubmit, reset, formState } = useForm();
  const titles: Partial<
    Record<FormMode, { header: string; saveButtonText: string; subtitle?: string }>
  > = {
    [FormMode.CREATE]: {
      header: 'New Topic',
      subtitle: 'Create a new Topic',
      saveButtonText: 'Create Topic'
    },
    [FormMode.EDIT]: {
      header: 'Edit Topic',
      subtitle: 'Modify an existing Topic',
      saveButtonText: 'Submit Topic'
    },
    [FormMode.DUPLICATE]: {
      header: 'Duplicate Topic',
      saveButtonText: 'Create Topic',
      subtitle: 'Values from the original row have been pre-filled'
    }
  };

  const filteredAuthors = filterAuthors(
    isSuperUser,
    projectMemberOptions,
    mode === FormMode.CREATE ? projectUser.user : contextTopic?.author
  );

  useEffect(() => {
    /* - - - - - - - - - - Effects - - - - - - - - - - */

    /**
     * Sets the default form values.
     *
     * Needs to be done in an effect and not on useForm hook call because the statuses are not
     * immediately available on component mount. There is a delay while they are pulled.
     */
    const defaultValues: FieldValues = contextTopic
      ? {
          ...contextTopic,
          user_groups: createUserGroupOptions(
            contextTopic.user_groups,
            userGroups,
            contextTopic.reference?.user_groups
          )
        }
      : {
          name: '',
          description: '',
          intents: [],
          responses: [],
          entities: [],
          user_groups: [],
          author: projectUser?.user
        };
    reset(defaultValues);
  }, [contextTopic]);

  // reset when closing
  useEffect(() => {
    !displayPopup && reset();
  }, [displayPopup]);

  /* - - - - - - - - - - Callbacks - - - - - - - - - - */
  const onPopupSave = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    handleSubmit(onSubmit)();
  };

  const hidePopup = () => {
    reset();
    onHide();
    parentElement?.focus();
  };

  return (
    <Popup
      title={titles[mode]?.header ?? ''}
      subtitle={titles[mode]?.subtitle ?? ''}
      visible={displayPopup}
      loading={loading}
      onHide={hidePopup}
      onSave={onPopupSave}
      saveButtonText={titles[mode]?.saveButtonText}
    >
      <form className="p-fluid" onSubmit={onPopupSave} aria-hidden="true">
        <div className="specto-text-medium -mt-2 mb-5">
          Required fields are marked with <span className="specto-form-asterisk-color">*</span>
        </div>

        <TextField
          fieldName="name"
          label="Name"
          control={control}
          info="Unique name that will be used to identify the topic"
        />
        <TextArea
          fieldName="description"
          label="Description"
          control={control}
          info="Provides a summary of the information covered by the topic"
          rows={3}
        />
        <UserGroupsField
          control={control}
          userGroups={userGroups}
          projectUser={projectUser}
          mode={mode}
        />

        <AutocompleteField
          labelClassName="line-height-3"
          required={true}
          control={control}
          options={filteredAuthors}
          optionLabel="name_reversed"
          optionValue="user"
          fieldName="author"
          label="Author"
          placeholder="Select Author or start typing"
          info="Author of this Topic"
        />
        <MultiAutocompleteField
          fieldName="responses"
          label="Responses"
          required={false}
          control={control}
          options={sortbyProperty(
            filterRetired(responses, formState.defaultValues?.responses),
            'name'
          )}
          itemTemplate={(item) =>
            GenericItemTemplate({
              id: item.id,
              itemName: 'response',
              allOptions: responses,
              showRetiredIcon: true
            })
          }
          optionLabel="name"
          optionValue="id"
          placeholder="Select Responses or start typing"
          info="Responses that are associated with this topic"
        />

        <MultiAutocompleteField
          fieldName="intents"
          label="Intents"
          options={sortbyProperty(
            filterRetired(filterDefault(intents), formState.defaultValues?.intents),
            'name'
          )}
          itemTemplate={(item) =>
            GenericItemTemplate({
              id: item.id,
              itemName: 'intent',
              allOptions: intents,
              showRetiredIcon: true
            })
          }
          required={false}
          control={control}
          optionLabel="name"
          optionValue="id"
          placeholder="Select Intents or start typing"
          info="Intents that are associated with this topic"
        />

        {isSuperUser && (
          <MultiAutocompleteField
            fieldName="entities"
            label="Entities"
            required={false}
            control={control}
            options={filterRetired(filterDefault(entities), formState.defaultValues?.entities)}
            itemTemplate={(item) =>
              GenericItemTemplate({
                id: item.id,
                itemName: 'entity',
                allOptions: entities,
                showRetiredIcon: true
              })
            }
            optionLabel="name"
            optionValue="id"
            placeholder="Select Entities or start typing"
            info="Entities that are associated with this topic"
          />
        )}
        {isSuperUser && <TagsField control={control} />}
      </form>
    </Popup>
  );
};

export default TopicPopup;
