import React from 'react';

import { SpectoRouter } from 'models/routers-model';
import Dashboard from 'pages/Dashboard/Dashboard';
import Intent from 'pages/Intent/Intent';
import Conversations from 'pages/Conversations/Conversations';
import Response from 'pages/Responses/Response';
import NLUData from 'pages/NLUData/NLUData';
import MessageInbox from 'pages/MessageInbox/MessageInbox';
import SystemUsers from 'pages/SystemSettings/SystemUsers';
import ProjectSettings from 'pages/ProjectSettings/ProjectSettings';
import MembersSettings from 'pages/MembersSettings/MembersSettings';
import TagManager from 'pages/TagManager/TagManager';
import CategoryManager from 'pages/CategoryManager/CategoryManager';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import { routes } from 'constants/routes';
import { VersionGuardedRoute } from 'containers/guards';
import Entity from 'pages/Entities/Entity';
import Synonym from 'pages/Synonyms/Synonym';
import Regex from 'pages/Regex/Regex';
import EntityGroup from 'pages/EntityGroup/EntityGroup';
import LookupTable from 'pages/Lookup/LookupTable';
import Topic from 'pages/Topics/Topic';
import { PermissionGuardedRoute } from 'containers/guards/PermissionGuardedRoute';
import NLUService from 'services/nluService';
import MessageInboxService from 'services/messageInboxService';
import ConversationsService from 'services/conversationsService';
import SecuritySettings from 'pages/UserSettings/SecuritySettings';
import UserSettings from 'pages/UserSettings/UserSettings';
import ResponseDatabaseManager from './pages/DatabaseManagers/ResponseDatabaseManager';
import EntitiesService from 'services/entitiesService';
import SynonymsService from 'services/synonymsService';
import RegexService from 'services/regexService';
import EntityGroupsService from 'services/entityGroupsService';
import LookupTableService from 'services/lookupTableService';
import { LookupTableProvider } from 'context/LookupTableContext';
import UserGroups from 'pages/UserGroups/UserGroups';
import NotificationSettings from 'pages/UserSettings/Notification/NotificationSettings';
import ProjectsService from 'services/projectsService';
import Analytics from 'pages/Analytics/Analytics';
import ConversationViewer from 'pages/ConversationViewer/ConversationViewer';
import EventDatabaseManager from 'pages/DatabaseManagers/EventDatabaseManager';
import EventsService from 'services/eventsService';

/**
 * For general routes
 * Guarded only by Auth
 */
export const mainRouter: SpectoRouter = [
  {
    path: routes.HOME,
    component: <Dashboard />,
    exact: true,
    meta: { breadcrumb: [{ label: 'Dashboard', parent: 'Dashboard' }] }
  },
  {
    path: '*',
    component: <ErrorPage title="404" message="Page Not Found!" />,
    meta: { breadcrumb: [{ parent: 'Error', label: 'Error' }] }
  }
];

/**
 * For Page routes
 * Guarded by activeProject & Auth
 */
export const pageRouter: SpectoRouter = [
  {
    path: '/analytics',
    component: <Analytics />,
    exact: true,
    meta: {
      breadcrumb: [{ label: 'Analytics', parent: 'Bot Content' }]
    }
  },
  {
    path: '/intents',
    component: <Intent />,
    exact: true,
    meta: {
      breadcrumb: [{ label: 'Intents', parent: 'Bot Content' }]
    }
  },
  {
    path: '/conversation-viewer',
    component: <ConversationViewer />,
    exact: true,
    meta: {
      breadcrumb: [{ label: 'Conversation Viewer', parent: 'Bot Content' }]
    }
  },
  {
    path: routes.CONVERSATIONS,
    component: (
      <VersionGuardedRoute>
        <PermissionGuardedRoute roles={ConversationsService.roles.list}>
          <Conversations />
        </PermissionGuardedRoute>
      </VersionGuardedRoute>
    ),
    exact: true,
    meta: {
      breadcrumb: [{ label: 'Conversation Data', parent: 'Conversations' }]
    }
  },
  {
    path: '/responses',
    component: <Response />,
    exact: true,
    meta: {
      breadcrumb: [{ label: 'Responses', parent: 'Bot Content' }]
    }
  },
  {
    path: '/nlu-data',
    component: (
      <PermissionGuardedRoute roles={NLUService.roles.list}>
        <NLUData />
      </PermissionGuardedRoute>
    ),
    exact: true,
    meta: { breadcrumb: [{ label: 'NLU Data', parent: 'Training' }] }
  },
  {
    path: '/message-inbox',
    component: (
      <VersionGuardedRoute>
        <PermissionGuardedRoute roles={MessageInboxService.roles.list}>
          <MessageInbox />
        </PermissionGuardedRoute>
      </VersionGuardedRoute>
    ),
    exact: true,
    meta: {
      breadcrumb: [{ label: 'Message Inbox', parent: 'NLU Annotation' }]
    }
  },
  {
    path: '/entities',
    component: (
      <PermissionGuardedRoute roles={EntitiesService.roles.list}>
        <Entity />
      </PermissionGuardedRoute>
    ),
    exact: true,
    meta: {
      breadcrumb: [{ label: 'Entity Labelling', parent: 'Bot Content' }]
    }
  },
  {
    path: '/synonyms',
    component: (
      <PermissionGuardedRoute roles={SynonymsService.roles.list}>
        <Synonym />
      </PermissionGuardedRoute>
    ),
    exact: true,
    meta: {
      breadcrumb: [{ label: 'Synonyms', parent: 'Bot Content' }]
    }
  },
  {
    path: '/regex',
    component: (
      <PermissionGuardedRoute roles={RegexService.roles.list}>
        <Regex />
      </PermissionGuardedRoute>
    ),
    exact: true,
    meta: {
      breadcrumb: [{ label: 'Regular Expressions', parent: 'Bot Content' }]
    }
  },
  {
    path: '/entity-groups',
    component: (
      <PermissionGuardedRoute roles={EntityGroupsService.roles.list}>
        <EntityGroup />
      </PermissionGuardedRoute>
    ),
    exact: true,
    meta: {
      breadcrumb: [{ label: 'Entity Groups', parent: 'Bot Content' }]
    }
  },
  {
    path: '/lookuptables',
    component: (
      <PermissionGuardedRoute roles={LookupTableService.roles.list}>
        <LookupTableProvider>
          <LookupTable />
        </LookupTableProvider>
      </PermissionGuardedRoute>
    ),
    exact: true,
    meta: {
      breadcrumb: [{ label: 'Lookup Tables', parent: 'Bot Content' }]
    }
  },
  {
    path: '/topics',
    component: <Topic />,
    exact: true,
    meta: {
      breadcrumb: [{ label: 'Topics', parent: 'Bot Content' }]
    }
  }
];

/**
 * For Project Settings routes
 * Guarded by activeProject & Auth
 */
export const projectSettingsRouter: SpectoRouter = [
  {
    path: 'general',
    component: <ProjectSettings />,
    exact: true,
    meta: {
      breadcrumb: [
        {
          label: 'Project Settings',
          parent: 'Project Settings',
          path: routes.PROJECT_GENERAL_SETTINGS
        }
      ]
    }
  },
  {
    path: 'members',
    component: <MembersSettings />,
    exact: true,
    meta: {
      breadcrumb: [
        {
          label: 'Project Settings',
          parent: 'Project Settings',
          path: routes.PROJECT_SETTINGS + '/members'
        }
      ]
    }
  },
  {
    path: 'event-database-manager',
    component: (
      <PermissionGuardedRoute
        roles={ProjectsService.roles.database.create}
        redirectPath="/project-settings/general"
      >
        <EventDatabaseManager />
      </PermissionGuardedRoute>
    ),
    exact: true,
    meta: {
      breadcrumb: [
        {
          label: 'Project Settings',
          parent: 'Project Settings',
          path: routes.EVENT_DATABASE_MANAGER
        }
      ]
    }
  },
  {
    path: 'response-database-manager',
    component: (
      <PermissionGuardedRoute
        roles={ProjectsService.roles.database.create}
        redirectPath="/project-settings/general"
      >
        <ResponseDatabaseManager />
      </PermissionGuardedRoute>
    ),
    exact: true,
    meta: {
      breadcrumb: [
        {
          label: 'Project Settings',
          parent: 'Project Settings',
          path: routes.RESPONSE_DATABASE_MANAGER
        }
      ]
    }
  },
  {
    path: 'tag-manager',
    component: (
      <PermissionGuardedRoute
        roles={ProjectsService.roles.database.create}
        redirectPath="/project-settings/general"
      >
        <TagManager />
      </PermissionGuardedRoute>
    ),
    exact: true,
    meta: {
      breadcrumb: [
        {
          label: 'Project Settings',
          parent: 'Project Settings',
          path: routes.PROJECT_SETTINGS + '/tag-manager'
        }
      ]
    }
  },
  {
    path: 'user-groups',
    component: <UserGroups />,
    exact: true,
    meta: {
      breadcrumb: [
        {
          label: 'Project Settings',
          parent: 'Project Settings',
          path: routes.USER_GROUPS
        }
      ]
    }
  },
  {
    path: 'intent-category-manager',
    component: <CategoryManager />,
    exact: true,
    meta: {
      breadcrumb: [
        {
          label: 'Project Settings',
          parent: 'Project Settings',
          path: routes.PROJECT_SETTINGS + '/intent-category-manager'
        }
      ]
    }
  }
];

/**
 * For System Settings routes
 * Guarded by activeProject & Auth
 */
export const systemSettingsRouter: SpectoRouter = [
  {
    path: 'system-users',
    component: <SystemUsers />,
    exact: true,
    meta: {
      breadcrumb: [
        {
          label: 'System Users',
          parent: 'Settings',
          path: routes.SYSTEM_USERS
        }
      ]
    }
  }
];

export const userSettingsRouter: SpectoRouter = [
  {
    path: '',
    component: <UserSettings />,
    exact: true,
    meta: {
      breadcrumb: [
        {
          label: 'User Settings',
          parent: 'Settings',
          path: routes.USER_SETTINGS
        }
      ]
    }
  },
  ...(process.env.REACT_APP_USE_SSO !== 'true'
    ? [
        {
          path: 'security',
          component: <SecuritySettings />,
          exact: true,
          meta: {
            breadcrumb: [
              {
                label: 'Security Settings',
                parent: 'Settings',
                path: routes.USER_SETTINGS + '/security'
              }
            ]
          }
        }
      ]
    : []),
  {
    path: 'notifications',
    component: <NotificationSettings />,
    exact: true,
    meta: {
      breadcrumb: [
        {
          label: 'Notification Settings',
          parent: 'Settings',
          path: routes.USER_SETTINGS + '/notifications'
        }
      ]
    }
  }
];

export const allRouters: SpectoRouter = [
  ...pageRouter,
  ...projectSettingsRouter,
  ...systemSettingsRouter,
  ...userSettingsRouter,
  ...mainRouter
];
