import React, { useEffect, useState } from 'react';
import {
  ConversationDetailMessageModel,
  ConversationDetailModel,
  ConversationDetailTagsModel,
  ConversationModel,
  DeleteConversationDetailMessageOptionsModel,
  PutConversationTagsOptionsModel
} from 'models/conversation-model';
import { TagModel } from 'models/tag-model';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { projectsSelector } from 'features/projects/projectsSlice';
import ConversationBubble from './ConversationBubble';
import { ScrollTop } from 'primereact/scrolltop';
import useFetch from 'hooks/useFetch';
import ConversationsService from 'services/conversationsService';
import { FetchStatusOptions } from 'constants/fetchStatus';
import { createToast } from 'features/toast/toastSlice';
import LoadingSpinner from 'components/LoadingSpinner';
import { TagTemplate } from 'components/Forms';
import { mapTags } from 'util/tags';
import { selectConversations } from 'features/conversations/conversationsSlice';
import { confirmDialog } from 'primereact/confirmdialog';
import { MultiSelect } from 'primereact/multiselect';
import ChipTagTemplate from 'components/Forms/ChipTagTemplate';

type ConversationDetailProps = {
  conversation: ConversationDetailModel;
  allTags: TagModel[];
  onTagsChange(tags: ConversationModel['tags']): void;
  onDeleteConversationDetailMessage(message: ConversationDetailMessageModel['text']): void;
  onScrollComplete?(): void;
};

const BUBBLE_HIGHLIGHTED_CLASSNAME = 'text-l font-semibold p-3';

const ConversationDetail = function ({
  conversation,
  allTags,
  onTagsChange,
  onDeleteConversationDetailMessage,
  onScrollComplete
}: ConversationDetailProps) {
  const dispatch = useAppDispatch();
  const { selectedProject } = useAppSelector(projectsSelector);

  /* - - - - - - - - - - Delete - - - - - - - - - - */
  const {
    fetchStatus: deleteConversationDetailMessagesStatus,
    fetch: deleteConversationDetailMessage,
    fetchOptions: deleteConversationDetailMessageOptions,
    permission: canDeleteMessage
  } = useFetch<DeleteConversationDetailMessageOptionsModel>(
    ConversationsService.deleteConversationDetailMessage,
    ConversationsService.roles.delete
  );
  const confirmDeleteDialog = (message: ConversationDetailMessageModel) => {
    confirmDialog({
      message: 'This action cannot be undone.',
      header: 'Delete Conversation Message?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () =>
        deleteConversationDetailMessage({
          id: conversation.id,
          message: message.text,
          slug: selectedProject.slug
        })
    });
  };

  // handle delete detail message effect
  useEffect(() => {
    if (
      deleteConversationDetailMessagesStatus === FetchStatusOptions.SUCCESS &&
      deleteConversationDetailMessageOptions
    ) {
      onDeleteConversationDetailMessage(deleteConversationDetailMessageOptions.message);
      dispatch(createToast('message deleted'));
    }
  }, [deleteConversationDetailMessagesStatus]);

  /* - - - - - - - - - - Tags - - - - - - - - - - */
  const [tags, setTags] = useState<TagModel[]>([]);
  const {
    data: conversationDetailTagsData,
    fetchStatus: patchConversationDetailTagsStatus,
    fetch: patchConversationDetailTags
  } = useFetch<PutConversationTagsOptionsModel, ConversationDetailTagsModel>(
    ConversationsService.editConversationTags,
    ConversationsService.roles.update
  );

  // handle edit tag effect
  useEffect(() => {
    if (
      patchConversationDetailTagsStatus === FetchStatusOptions.SUCCESS &&
      conversationDetailTagsData
    ) {
      // set the tags in conversation detail
      setTags(mapTags(allTags, conversationDetailTagsData));
      // set the tags in conversation list
      onTagsChange(conversationDetailTagsData);
      dispatch(createToast('Conversation Tags updated'));
    }
  }, [patchConversationDetailTagsStatus]);

  const editTags = (newTags: TagModel[]) => {
    patchConversationDetailTags({
      slug: selectedProject.slug,
      id: conversation.id,
      tags: newTags.map((tag) => tag.id)
    });
  };

  // set local tags on conversation detail change
  useEffect(() => {
    setTags(mapTags(allTags, conversation.tags));
  }, [conversation.sender]);

  /* - - - - - - - - - - Scrolling - - - - - - - - - - */
  const { message: conversationSearchMessage } = useAppSelector(selectConversations);

  // messages ref record
  const messageRefs: Record<string, React.RefObject<HTMLDivElement>> = Object.fromEntries(
    conversation.messages.map((conversation) => [
      conversation.text ?? '',
      React.createRef<HTMLDivElement>()
    ])
  );

  // scroll into view effect
  useEffect(() => {
    const message = conversationSearchMessage;
    const bubbleRef = messageRefs[message]?.current;

    if (
      !bubbleRef ||
      message.length === 0 ||
      bubbleRef.className === BUBBLE_HIGHLIGHTED_CLASSNAME
    ) {
      return;
    }

    bubbleRef.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });

    bubbleRef.className = BUBBLE_HIGHLIGHTED_CLASSNAME;

    onScrollComplete?.();
  }, [conversationSearchMessage]);

  return (
    <div className="specto-conversation-detail flex flex-column align-items-center h-full">
      <h6 className="specto-text-muted mt-3">{conversation.sender}</h6>

      <hr className="w-8" />

      <div className="specto-conversation-detail__conversation-bubble-container w-full overflow-y-scroll overflow-x-hidden flex flex-column align-items-center">
        <h6 className="mt-3">
          Tags
          {patchConversationDetailTagsStatus === FetchStatusOptions.LOADING && (
            <LoadingSpinner small position="inline" />
          )}
        </h6>
        <MultiSelect
          filter
          multiple
          value={tags}
          options={allTags}
          optionLabel="name"
          className="specto-input-select"
          style={{ width: '98%' }}
          placeholder="Select Tags"
          filterPlaceholder="Start typing to search..."
          filterBy="name"
          disabled={patchConversationDetailTagsStatus === FetchStatusOptions.LOADING}
          itemTemplate={TagTemplate}
          selectedItemTemplate={ChipTagTemplate}
          onChange={(e) => e.value && editTags(e.value)}
          aria-label="Tags"
          showSelectAll={false}
          appendTo="self"
          autoFocus
          tabIndex={0}
        />

        <hr className="w-8" />

        <div className="w-full">
          {conversation.messages.map((message: ConversationDetailMessageModel, index) => (
            <ConversationBubble
              id={index}
              messageRef={message.text ? messageRefs[message.text] : undefined}
              message={message}
              canDelete={canDeleteMessage}
              onDelete={() => confirmDeleteDialog(message)}
              key={index}
            />
          ))}
        </div>

        <ScrollTop
          threshold={100}
          target="parent"
          className="w-2rem h-2rem text-lg"
          icon="pi pi-chevron-up "
          style={{ padding: 20 }}
        />
      </div>
    </div>
  );
};

export default ConversationDetail;
