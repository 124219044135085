import React from 'react';
import {
  FieldArrayWithId,
  FieldValues,
  UseFieldArrayAppend,
  UseFieldArrayMove,
  UseFieldArrayRemove,
  UseFormReturn
} from 'react-hook-form';

/**
 * Individually typed the useFieldArray methods because there will be multiple useFieldArray
 * instances and needed to change the names to avoid clashing (i.e. "append" method from buttons and
 * "append" method from dropdown.)
 */
export interface ResponseFormContextModel extends UseFormReturn {
  appendContent: UseFieldArrayAppend<FieldValues, 'content'>;
  removeContent: UseFieldArrayRemove;
  contentFields: FieldArrayWithId<FieldValues, string, string>[];
  moveField: UseFieldArrayMove;
}

export const ResponseFormContext = React.createContext<ResponseFormContextModel>(
  {} as ResponseFormContextModel
);
