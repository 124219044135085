import React from 'react';
import {
  addBotMessage,
  addUserMessage,
  contextSelector,
  fontSizeSelector,
  languageSelector,
  messageSelector,
  reset,
  resetMessages,
  setContext,
  setFontSize,
  setLanguage,
  setMessages,
  setUser,
  setWidgetState,
  userSelector,
  widgetStateSelector,
  widgetVersionSelector
} from 'features/widget/widgetSlice';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { SpectoWidget, BotMessage, UserMessage, WidgetState } from '@developers/specto-widget';
import '@developers/specto-widget/dist/spectoWidget.css';

/**
 * Creates the root element for the widget
 * and loads the widget's script and stylesheet
 *
 * @component
 */
const Widget = function () {
  const dispatch = useAppDispatch();

  const user = useAppSelector(userSelector);
  const messages = useAppSelector(messageSelector);
  const fontSize = useAppSelector(fontSizeSelector);
  const widgetState = useAppSelector(widgetStateSelector);
  const language = useAppSelector(languageSelector);
  const widgetVersion = useAppSelector(widgetVersionSelector);
  const context = useAppSelector(contextSelector);

  const widgetStore = {
    user,
    messages,
    fontSize,
    widgetState,
    language,
    widgetVersion,
    context,
    reset: (payload: string) => dispatch(reset(payload)),
    setMessages: (payload: (BotMessage | UserMessage)[]) => dispatch(setMessages(payload)),
    addUserMessage: (payload: UserMessage) => dispatch(addUserMessage(payload)),
    addBotMessage: (payload: BotMessage[]) => dispatch(addBotMessage(payload)),
    resetMessages: (payload: boolean) => dispatch(resetMessages(payload)),
    setFontSize: (payload: number) => dispatch(setFontSize(payload)),
    setUser: () => dispatch(setUser()),
    setWidgetState: (payload: WidgetState) => dispatch(setWidgetState(payload)),
    setLanguage: (payload: string) => dispatch(setLanguage(payload)),
    setContext: (payload: string) => dispatch(setContext(payload))
  };

  return <SpectoWidget {...widgetStore} />;
};

export default Widget;
