import React, { useContext } from 'react';
// components
import { RichTextForm } from 'components/Table';
// context
import { ResponseFormContext } from './ResponseFormContext';
// utils
import { getFormErrorMessage } from 'util/form';
import ResponseComponentContainer from './ResponseComponentContainer';

/**
 * Component that formats and displays the response rich text field in a form
 * @component
 * @param componentIndex index of this component in the component list
 */
const ResponseRichText = function ({ componentIndex }: { componentIndex: number }) {
  const {
    formState: { errors },
    control,
    setValue,
    getValues
  } = useContext(ResponseFormContext);

  const baseField = `content.${componentIndex}`;
  const contentField = `${baseField}.content.text`;
  const contentValueField = `${baseField}.contentValue.text`;

  return (
    <ResponseComponentContainer componentIndex={componentIndex} title="Text" key={componentIndex}>
      <div className="w-full">
        <RichTextForm
          control={control}
          setValue={setValue}
          defaultValue={getValues(contentValueField) || getValues(contentField)}
          controlRules={{ required: 'required' }}
          controlName={contentValueField}
          minHeight="20vh"
        />
        {getFormErrorMessage(errors.richTextResponse)}
      </div>
    </ResponseComponentContainer>
  );
};

export default ResponseRichText;
