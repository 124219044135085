import React, { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
// components
import Popup from 'containers/Popup';
import { TextField } from 'components/Forms';
// models
import { SynonymTableDataModel } from 'models/synonym-model';
// constants
import ChipsField from 'components/Forms/ChipsField';
import { FormMode } from 'models/form-model';

type SynonymPopupPropsModel = {
  contextSynonym?: SynonymTableDataModel | null;
  displayPopup: boolean;
  loading?: boolean;
  mode?: FormMode;
  onHide(): void;
  onSubmit(data: FieldValues): void;
  parentElement?: HTMLElement;
};

/**
 * Component that displays a popup to create a new synonym.
 * @component
 * @param param0 Prop object containing the following:
 *
 *    contextSynonym: the context Synonym
 *    displayPopup: Boolean representing whether to display the popup or not.
 *    loading: loading state
 *    mode: form mode
 *    onHide: callback for hiding the form
 *    onSubmit:  callback for when the form is submitted
 *    parentElement: Element to return focus to after the dialog is hidden
 *
 * @returns
 */
const SynonymPopup = function ({
  contextSynonym,
  displayPopup,
  loading,
  mode = FormMode.CREATE,
  onHide,
  onSubmit,
  parentElement
}: SynonymPopupPropsModel) {
  const { control, handleSubmit, reset } = useForm();

  /* - - - - - - - - - - Effects - - - - - - - - - - */

  useEffect(() => {
    const defaultValues: FieldValues = contextSynonym ?? {
      text: '',
      phrases: []
    };
    reset(defaultValues);
  }, [contextSynonym]);

  // reset when closing
  useEffect(() => {
    !displayPopup && reset();
  }, [displayPopup]);

  /* - - - - - - - - - - Callbacks - - - - - - - - - - */
  const onPopupSave = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    handleSubmit(onSubmit)();
  };

  const hidePopup = () => {
    reset();
    onHide();
    parentElement?.focus();
  };

  return (
    <Popup
      title={mode === FormMode.CREATE ? 'New Synonym' : 'Edit Synonym'}
      subtitle={mode === FormMode.CREATE ? 'Create a new Synonym' : 'Modify an existing Synonym'}
      visible={displayPopup}
      loading={loading}
      onHide={hidePopup}
      onSave={onPopupSave}
    >
      <form className="p-fluid" onSubmit={onPopupSave}>
        <div className="specto-text-medium -mt-2 mb-5">
          Required fields are marked with <span className="specto-form-asterisk-color">*</span>
        </div>

        <TextField fieldName="text" label="Synonym" control={control} />
        <ChipsField
          fieldName="phrases"
          label="Words/Phrases"
          control={control}
          placeholder="Press Enter or Comma to complete a Word/Phrase"
        />
      </form>
    </Popup>
  );
};

export default SynonymPopup;
