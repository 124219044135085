import React from 'react';
import classNames from 'classnames';
import { NLUCollisionModel, NLUConflictType, NLUModel } from 'models/nlu-table-data-model';
import { MessageInboxTableDataModel } from 'models/message-inbox-table-data-model';
import { Control, Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import { RadioButton } from 'primereact/radiobutton';
import { mapIdToName } from 'util/mapIdAndName';
import { useAppSelector } from 'hooks/store';
import { intentsSelector, sentimentsSelector } from 'features/projects/projectsSlice';
import { getFormErrorMessage } from 'util/form';
import { getStatusWarnings } from 'util/status';
import { filterConflicts } from 'util/messsage-inbox';
import { IntentTableDataModel } from 'models/intent-table-data-model';
import { SentimentModel } from 'models/sentiment-model';

type ConflictPopupProps = {
  control: Control;
  nluCollisions: NLUCollisionModel;
  conflictingMessagesRecord: Record<MessageInboxTableDataModel['id'], MessageInboxTableDataModel>;
  conflictingNLURecord: Record<NLUModel['id'], NLUModel>;
  setValue: UseFormSetValue<FieldValues>;
  sentimentModule?: boolean;
};

const NLUConflictForm = ({
  control,
  nluCollisions,
  sentimentModule,
  conflictingNLURecord,
  conflictingMessagesRecord,
  setValue
}: ConflictPopupProps) => {
  const allIntents = useAppSelector(intentsSelector);
  const allSentiments = useAppSelector(sentimentsSelector);

  return (
    <div className="specto-nlu-conflict-form">
      {nluCollisions.duplicates.map((conflicts, i) => {
        const nluId: NLUModel['id'] = filterConflicts(conflicts, NLUConflictType.nlu)?.[0][1];
        const conflictId = nluId;
        const messageIds: MessageInboxTableDataModel['id'][] = filterConflicts(
          conflicts,
          NLUConflictType.message
        ).map((conflict) => conflict[1]);

        const intentIds: IntentTableDataModel['id'][] = messageIds.map(
          (messageId) => conflictingMessagesRecord[messageId].intent
        );

        const sentimentIds: SentimentModel['id'][] = messageIds.map(
          (messageId) => conflictingMessagesRecord[messageId].sentiment
        );

        if (!nluId || !(nluId in conflictingNLURecord)) {
          return;
        }

        const nluData = conflictingNLURecord[nluId];

        // set the values of the original nlu data to be in the form value
        setValue(`${conflictId}.id`, nluData.id);

        return (
          <div key={i} className="mt-1 grid grid-nogutter">
            <div className="col-12 mb-6">
              <h6>Message</h6>
              <div>{nluData.message}</div>
            </div>

            <div className="col-6">
              <h6>Intent</h6>
              <Controller
                defaultValue={nluData?.intent}
                rules={{ required: 'required' }}
                name={`${conflictId}.intent`}
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    {conflicts.map((conflict, i) => {
                      const conflictType = conflict[0];
                      const conflictId: NLUModel['id'] | MessageInboxTableDataModel['id'] =
                        conflict[1];

                      const intentId =
                        conflictType === NLUConflictType.message
                          ? conflictingMessagesRecord[conflictId].intent
                          : conflictingNLURecord[conflictId].intent;

                      const intentName = mapIdToName(intentId, allIntents);

                      // duplicate if it's a message type AND
                      // 1. clashes with the nlu intent, OR
                      // 2. clashes with a previous intent in the list
                      const duplicate =
                        conflictType === NLUConflictType.message &&
                        (intentId === nluData.intent ||
                          intentIds.some((id, j) => id == intentId && i !== j && i > j));

                      return (
                        <div className="field-radiobutton" key={conflictId + conflictType}>
                          <RadioButton
                            tooltip="Duplicate item"
                            disabled={duplicate}
                            tooltipOptions={{
                              showOnDisabled: true,
                              disabled: !duplicate,
                              position: 'bottom'
                            }}
                            className={classNames({
                              'p-invalid': fieldState.error
                            })}
                            value={intentId}
                            id={String(conflictId)}
                            onChange={(e) => field.onChange(e.value)}
                            checked={!duplicate && field.value === intentId}
                          />
                          <div>
                            <label
                              htmlFor={String(conflictId)}
                              className={classNames({ 'specto-text-muted': duplicate })}
                            >
                              &nbsp;{intentName}
                              {conflictType === NLUConflictType.nlu && !duplicate && (
                                <b> (original)</b>
                              )}
                            </label>
                            <div className="text-sm text-orange-500 ml-1">
                              {getStatusWarnings(intentId, allIntents)}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {getFormErrorMessage(fieldState.error)}
                  </>
                )}
              />
            </div>

            {sentimentModule && (
              <div className="col-6">
                <h6>Sentiment</h6>
                <Controller
                  defaultValue={nluData?.sentiment}
                  rules={{ required: 'cannot pick null' }}
                  name={`${conflictId}.sentiment`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      {conflicts.map((conflict, i) => {
                        const conflictType = conflict[0];
                        const conflictId: NLUModel['id'] | MessageInboxTableDataModel['id'] =
                          conflict[1];

                        const sentimentId =
                          conflictType === NLUConflictType.message
                            ? conflictingMessagesRecord[conflictId].sentiment
                            : conflictingNLURecord[conflictId].sentiment;

                        const sentimentName = mapIdToName(sentimentId, allSentiments) ?? '(null)';

                        // duplicate if it's a message type AND
                        // 1. clashes with the nlu sentiment, OR
                        // 2. clashes with a previous sentiment in the list
                        const duplicate =
                          conflictType === NLUConflictType.message &&
                          (sentimentId === nluData.sentiment ||
                            sentimentIds.some((id, j) => id == sentimentId && i !== j && i > j));

                        return (
                          <div className="field-radiobutton" key={conflictId + conflictType}>
                            <RadioButton
                              tooltip="Duplicate item"
                              disabled={duplicate}
                              tooltipOptions={{
                                showOnDisabled: true,
                                disabled: !duplicate,
                                position: 'bottom'
                              }}
                              className={classNames({
                                'p-invalid': fieldState.error
                              })}
                              value={sentimentId}
                              id={String(conflictId)}
                              onChange={(e) => field.onChange(e.value)}
                              checked={!duplicate && field.value === sentimentId}
                            />
                            <div>
                              <label
                                htmlFor={String(conflictId)}
                                className={classNames({ 'specto-text-muted': duplicate })}
                              >
                                &nbsp;{sentimentName}
                                {conflictType === NLUConflictType.nlu && !duplicate && (
                                  <b> (original)</b>
                                )}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                      {getFormErrorMessage(fieldState.error)}
                    </>
                  )}
                />
              </div>
            )}

            <div className="col-12">
              {nluCollisions.duplicates.length - 1 !== i && <hr className="w-8 mb-5" />}
            </div>
          </div>
        );
      })}
      {nluCollisions.no_accepted_annotation.length > 0 && (
        <div>
          <h6>
            The following messages do not have their annotation accepted and will not be added:
          </h6>
          <ul>
            {nluCollisions.no_accepted_annotation.map((msgId) => (
              <li key={`invalid-entity-label-${msgId}`}>{conflictingMessagesRecord[msgId].text}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default NLUConflictForm;
