import {
  CommentApiRequestModel,
  CommentModel,
  CommentOptionsModel,
  EditCommentOptionsModel,
  PostCommentOptionsModel,
  ResolveCommentApiModel
} from 'models/comment-model';
import ApiService from './apiService';
import { COMMENTS_API_URL } from 'constants/api';
import { ProjectRole } from 'models/project-model';
import { Permissions } from 'models/permission-model';
import { NON_GUEST_ROLES } from 'constants/roles';

const getComments = (options: CommentOptionsModel) =>
  ApiService.get<CommentModel[]>(
    '/' + options.slug + COMMENTS_API_URL + options.object_type + '-' + options.object_id
  );

const createComment = (options: PostCommentOptionsModel) =>
  ApiService.post<CommentModel, CommentApiRequestModel>(
    '/' + options.slug + COMMENTS_API_URL + options.object_type + '-' + options.object_id + '/',
    { text: options.text, mentions: options.mentions }
  );

const editComment = (options: EditCommentOptionsModel) =>
  ApiService.patch<CommentModel, CommentApiRequestModel>(
    '/' +
      options.slug +
      COMMENTS_API_URL +
      options.object_type +
      '-' +
      options.object_id +
      '/' +
      options.id +
      '/',
    { text: options.text, mentions: options.mentions }
  );

const resolveComment = (options: CommentOptionsModel) =>
  ApiService.put<ResolveCommentApiModel>(
    '/' +
      options.slug +
      COMMENTS_API_URL +
      options.object_type +
      '-' +
      options.object_id +
      '/toggle_resolved/'
  );

const deleteThread = (options: CommentOptionsModel) =>
  ApiService.remove(
    '/' +
      options.slug +
      COMMENTS_API_URL +
      options.object_type +
      '-' +
      options.object_id +
      '/delete_thread/'
  );

const commentsServiceRoles: Record<'general' | 'own', Permissions> = {
  general: {
    list: [ProjectRole.NEUROSOPH],
    retrieve: true,
    update: NON_GUEST_ROLES,
    delete: [ProjectRole.NEUROSOPH]
  },
  own: {
    create: NON_GUEST_ROLES,
    update: NON_GUEST_ROLES,
    delete: NON_GUEST_ROLES
  }
};

const CommentsService = {
  getComments,
  createComment,
  editComment,
  resolveComment,
  deleteThread,
  roles: commentsServiceRoles
};

export default CommentsService;
