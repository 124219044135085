/**
 * Filters out all items that are default
 * @param items list of items to filter
 * @param currentId id of items to skip (i.e. item is currently in use)
 * @returns list of filtered items
 */
export const filterDefault = <T extends { id: number; default: boolean }>(
  items: T[],
  currentId?: number
) => {
  return items.filter((item) => item.id === currentId || !item.default);
};
