import React from 'react';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { Label } from 'models/message-inbox-table-data-model';

type DropDownCellProps = {
  filter?: boolean;
  options: any[];
  optionLabel?: string;
  optionValue?: string;
  value?: Label | number;
  viewOnly?: boolean;
  icon?: string;
  onChange?(e: DropdownChangeEvent): void;
  itemTemplate?(item?: Label): JSX.Element;
  valueTemplate(item?: Label): JSX.Element;
};

const DropDownCell = function ({
  filter,
  options,
  value,
  onChange,
  viewOnly = false,
  optionLabel,
  optionValue,
  itemTemplate,
  valueTemplate
}: DropDownCellProps) {
  return viewOnly ? (
    <div>
      {valueTemplate(
        typeof value === 'number' ? options.find((option) => option.id === value) : value
      )}
    </div>
  ) : (
    <Dropdown
      value={value}
      options={options}
      filter={filter}
      itemTemplate={itemTemplate}
      valueTemplate={valueTemplate}
      className="specto-input-select"
      style={{ minHeight: 46.5 }}
      optionLabel={optionLabel}
      optionValue={optionValue}
      onChange={onChange}
    />
  );
};

export default DropDownCell;
