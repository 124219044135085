import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import ApiService from 'services/apiService';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { authState } from 'features/auth/authSlice';
import { ConversationsState } from 'features/conversations/conversationsSlice';
import { LayoutState } from 'features/layout/layoutSlice';
import { WidgetStoreState } from 'features/widget/widgetSlice';
import { VersionsState } from 'features/versions/versionsSlice';
import { ProjectsState } from 'features/projects/projectsSlice';
import { ToastState } from 'features/toast/toastSlice';

type RootStoreState = {
  auth: authState;
  conversations: ConversationsState;
  layout: LayoutState;
  projects: ProjectsState;
  toast: ToastState;
  versions: VersionsState;
  widget: WidgetStoreState;
  widgetHistory: WidgetStoreState;
};

const persistConfig: PersistConfig<RootStoreState> = {
  key: 'root',
  storage,
  blacklist: ['login', 'toast'],
  stateReconciler: autoMergeLevel2
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux-logger middleware
const logger = createLogger({
  predicate: () => process.env.NODE_ENV !== 'production',
  collapsed: () => true
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat(logger)
});

export const persistor = persistStore(store, null, () => {
  // Rehydration also runs on first startup, when store is empty

  // set token from rehydrated store if exists
  const token = store.getState().auth?.access;
  token && ApiService.setHeaderTokenBearer(token);
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type RootStateType = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
