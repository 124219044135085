import React from 'react';
import { Control } from 'react-hook-form';
import Popup from 'containers/Popup';
import { TextField } from 'components/Forms';
import TextArea from 'components/Forms/TextArea';
import { FormMode } from 'models/form-model';

type NewVersionPopupPropsModel = {
  displayPopup: boolean;
  onPopupDisplayChange: (visible: boolean) => void;
  loading?: boolean;
  control: Control;
  mode?: FormMode;
  onSubmit(): void;
};

const VersionFormPopup = function ({
  displayPopup,
  onPopupDisplayChange,
  onSubmit,
  control,
  mode = FormMode.READ_ONLY,
  loading = false
}: NewVersionPopupPropsModel) {
  const readOnly = mode === FormMode.READ_ONLY;
  const editMode = mode === FormMode.EDIT;

  /* - - - - - - - - - - Callbacks - - - - - - - - - - */
  const onFormSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    onSubmit();
  };

  const hidePopup = () => {
    onPopupDisplayChange(false);
  };

  return (
    <Popup
      title={readOnly ? 'Version Details' : editMode ? 'Edit Version Details' : 'Create Version'}
      subtitle={!readOnly ? 'Specify the named version and description' : undefined}
      visible={displayPopup}
      loading={loading}
      saveButtonText={readOnly ? null : editMode ? 'Save Edits' : 'Create Snapshot'}
      onHide={hidePopup}
      onSave={onFormSubmit}
    >
      <form className="p-fluid" onSubmit={onFormSubmit}>
        {!readOnly && (
          <div className="specto-text-medium -mt-2 mb-5">
            Required fields are marked with <span className="specto-form-asterisk-color">*</span>
          </div>
        )}

        <TextField
          fieldName="version_name"
          label="Name"
          control={control}
          readOnly={readOnly}
          required={!readOnly}
        />

        <TextArea
          fieldName="version_description"
          control={control}
          label="Description"
          required={!readOnly}
          readOnly={readOnly}
        />
      </form>
    </Popup>
  );
};

export default VersionFormPopup;
