import { QueryParameters } from 'models/api-model';
import { contentFiltersModel } from 'models/content-filters-model';
import { FilterChip } from 'models/filter-chips';
import { PublishState, Status } from 'models/status-model';

/**
 * Return the corresponding active and status params for a state
 *
 * @param state
 */
export const mapStateToActiveAndStatus = (state: PublishState) => {
  let value;

  if (state === PublishState.Active) {
    value = { active: true, status: Status.Not_Retired };
  } else if (state === PublishState.Proposed) {
    value = { active: false, status: Status.Not_Retired };
  } else {
    value = { active: false, status: Status.Retired };
  }

  return value;
};

/**
 *
 * @param params : valid query params
 * @param contentFilters : state of the content filters
 *
 * This function gets the persisted the filters across the pages
 *
 */
const getPersistedFilters = (params: any, contentFilters?: contentFiltersModel) => {
  const userGroupFilter = contentFilters?.user_groups || [];
  const statusFilter = contentFilters?.status;
  const activeFilter = contentFilters?.active;
  const intentsFilter = contentFilters?.intents || [];
  const responsesFilter = contentFilters?.responses || [];
  const entitiesFilter = contentFilters?.entities || [];
  const lastModifiedFilter = contentFilters?.published_lte;
  const authorsFilter = contentFilters?.author || [];
  const topicsFilter = contentFilters?.topics || [];
  const contentFilter = contentFilters?.content || '';

  if (authorsFilter?.length > 0) {
    params.author = authorsFilter;
  }

  if (topicsFilter.length > 0) {
    params.topics = topicsFilter;
  }

  if (lastModifiedFilter) {
    params.published_lte = lastModifiedFilter;
  }

  if (entitiesFilter?.length > 0) {
    params.entities = entitiesFilter;
  }

  if (responsesFilter?.length > 0) {
    params.responses = responsesFilter;
  }

  if (intentsFilter?.length > 0) {
    params.intents = intentsFilter;
  }

  if (contentFilter?.length > 0) {
    params.content = contentFilter;
  }

  // if user groups were already filtered, set the filter to the filtered user groups
  if (userGroupFilter?.length > 0) {
    params.user_groups = userGroupFilter;
  }

  if (activeFilter) {
    params.active = true;
    params.status = Status.Not_Retired;
  } else {
    params.active = false;
    params.status = statusFilter;
  }
};

/**
 *
 * @param validQueryParams : valid query params
 * @param filterInitialValues : state of the content filters
 * @param setFilterField : function to set the filter field
 * @param Status : Status (active == true or active == false)
 * @param PublishState : PublishState (Retired or Not_Retired)
 *
 * This function populates the filter menu with the values from the query params
 * use this function in the useEffect of the component where the filter menu is used to persist filters across the pages
 *
 */
const populateFilterMenu = (
  validQueryParams: string[],
  filterInitialValues: any,
  setFilterField: any,
  Status: any,
  PublishState: any
) => {
  for (const key of validQueryParams) {
    if (key in filterInitialValues) {
      if (key === 'active' && filterInitialValues[key] === true) {
        setFilterField('state', PublishState.Active);
      } else if (key === 'published_lte') {
        setFilterField(key, new Date(filterInitialValues[key]));
      } else if (key === 'status') {
        const status = filterInitialValues[key];
        if (status === Status.Retired) {
          setFilterField('state', PublishState.Retired);
        } else if (status === Status.Not_Retired) {
          setFilterField('state', PublishState.Proposed);
        }
      } else {
        setFilterField(key, filterInitialValues[key]);
      }
    }
  }
};

/**
 * Build multiple chips. Is a lot cleaner than trying to
 * @param idArray idArray set to unknown to prevent having to cast it every time on input
 * @param buildFn function that returns a filterChip given an individual ID
 */
function buildMultipleChips<T = number>(
  idArray: unknown,
  buildFn: (id: T) => FilterChip
): FilterChip[] {
  return (Array.isArray(idArray) && idArray?.map(buildFn)) || [];
}

/**
 * This function returns the applied filters based on the valid query params and the filter query parameters
 * @param validQueryParams
 * @param filterQueryParameters
 * @returns applied filters
 */
const getAppliedFilters = (validQueryParams: string[], filterQueryParameters: QueryParameters) => {
  let appliedFilters = {};
  for (const key of validQueryParams) {
    if (key in filterQueryParameters) {
      appliedFilters = { ...appliedFilters, [key]: filterQueryParameters[key] };
    } else {
      appliedFilters = { ...appliedFilters, [key]: null };
    }
  }
  return appliedFilters;
};

export { getPersistedFilters, populateFilterMenu, buildMultipleChips, getAppliedFilters };
