import React, { useContext } from 'react';
// prime react
import { Button } from 'primereact/button';
// components
import SearchBar from 'components/SearchBar';
import LookupTableDataTable from './components/LookupTableDataTable';
import NewLookupTablePopup from './components/NewLookupTablePopup';
import { permissionBoolean } from 'util/permissions';
import CommentsService from 'services/commentsService';
import LookupTableContext from '../../context/LookupTableContext';

const LookupTable = () => {
  const {
    loading,
    searchText,
    search,
    simpleSearch,
    clearSearch,
    handlePopupDisplayChange,
    isVersionSelected,
    canCreateLookupTable,
    selectedProject
  } = useContext(LookupTableContext);

  return (
    <div className="specto-lookup-table grid grid-nogutter">
      <div className="col flex flex-wrap xl:align-items-center align-items-start justify-content-between flex-column-reverse xl:flex-row">
        <SearchBar
          placeHolder="Search by Phrase"
          text={searchText}
          loading={loading}
          className="specto-search-bar w-12 xl:w-8 mt-3 xl:mt-0"
          onChange={(e) => simpleSearch(e.target.value)}
          onSubmitText={search}
          onClearText={() => {
            clearSearch();
            search('');
          }}
        />
        {!isVersionSelected && canCreateLookupTable && (
          <Button
            label="New Lookup Table"
            icon="pi pi-plus"
            iconPos="right"
            onClick={() => handlePopupDisplayChange(true)}
          />
        )}
      </div>
      <div className="col-12 mt-4">
        <div className="card">
          <LookupTableDataTable
            onEditInit={(data) => handlePopupDisplayChange(true, data)}
            showComments={permissionBoolean(
              CommentsService.roles.general.list,
              selectedProject.project_user.role
            )}
          />
        </div>
      </div>
      <NewLookupTablePopup
        onHide={() => handlePopupDisplayChange(false)}
        parentElement={document.activeElement as HTMLElement}
      />
    </div>
  );
};

export default LookupTable;
