import React from 'react';
// prime react
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// components
import { ChipsCell, LastModifiedCell, TagsCell, TextCell } from 'components/Table';
// models
import { EntityTableDataModel, EntityTableHistoryModel } from 'models/entity-model';
import { TagModel } from 'models/tag-model';
// util
import { mapTags } from 'util/tags';
import { statusDisplayNames } from 'constants/status';
import { ProjectMemberUserModel } from 'models/project-model';
import AvatarCell from 'components/Avatar/AvatarCell';

type EntityRowExpansionPropsModel = {
  allTags: TagModel[];
  isVersion: boolean;
  node: EntityTableDataModel;
  readOnly: boolean;
  showComments?: boolean;
  authors: ProjectMemberUserModel[];
};

/**
 * Component for showing the history objects of a table row in a dropdown.
 * @component
 * @param allTags all tags
 * @param isVersion adjust column sizes if a version is selected
 * @param node The row data
 * @param readOnly Flag indicating if the table is read-only
 * @param showComments Flag indicating if comments should be shown
 * @param authors List of current project Members
 */
const EntityRowExpansionTemplate = function ({
  allTags,
  isVersion,
  node,
  readOnly,
  showComments,
  authors
}: EntityRowExpansionPropsModel) {
  return (
    <DataTable value={node?.history || []} className="specto-data-table__tr-child">
      <Column columnKey="util-history" className="specto-table-icon" />
      <Column
        field="name"
        className="specto-table__column--md"
        body={(node: EntityTableHistoryModel, options) =>
          TextCell({ id: 'name-' + options.rowIndex, text: node.name })
        }
      />
      <Column field="description" className="specto-table__column--xl" />
      <Column columnKey="util-topics" className="specto-table__column--md" />
      <Column
        field="authors"
        header="Author"
        className="specto-table__column--md"
        align="center"
        body={(node: EntityTableDataModel) => {
          const fullName = authors.find((pm) => pm.user === node?.author)?.name || '';
          return fullName ? <AvatarCell fullName={fullName} id={node.id} /> : null;
        }}
      />
      <Column
        field="roles"
        className="specto-table__column--md"
        body={(node) => ChipsCell({ nodeId: node.id, chips: node.roles })}
      />
      <Column
        field="last_modified"
        align="center"
        className="specto-table__column--md"
        body={(node) =>
          LastModifiedCell({
            nodeId: node.last_modified.replaceAll(/[:.-]/g, ''),
            user: node.user,
            lastModified: node.last_modified
          })
        }
      />
      <Column
        field="status"
        className="specto-table__column--sm"
        body={(node: EntityTableHistoryModel, options) =>
          TextCell({ id: 'status-' + options.rowIndex, text: statusDisplayNames[node.status] })
        }
      />
      <Column
        columnKey="util-tag"
        field="tags"
        body={(node: EntityTableHistoryModel) => (
          <TagsCell tags={mapTags(allTags, node.tags)} editable={false} />
        )}
        className="specto-table-icon specto-table__column--sm"
      />
      <Column
        hidden={!showComments || readOnly || isVersion}
        columnKey="util-comments"
        className="specto-table-icon specto-table__column--sm"
      />
      <Column
        hidden={readOnly || isVersion}
        columnKey="util-action"
        className="specto-table__column--sm-2 pl-0"
      />
    </DataTable>
  );
};

export default EntityRowExpansionTemplate;
