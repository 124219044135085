import React, { useState } from 'react';
import classnames from 'classnames';
import Avatar from 'components/Avatar';
import { CommentModel } from 'models/comment-model';
import { Button } from 'primereact/button';
import { humanReadableSimpleDateTimeFormatted } from 'util/dates';
import { UserMention } from './UserMention';
import parse from 'html-react-parser';
import { UserModel } from 'models/user-model';

type CommentProps = {
  user?: UserModel;
  users?: UserModel[];
  comment: CommentModel;
  updateComment: (text: string, id: number) => void;
  disableEditing: boolean | null;
};
/**
 * Component that displays a comment
 *
 * @param user user the comment was made by
 * @param users users that can be mentioned
 * @param comment comment to display
 * @param updateComment function to update the comment
 * @param disableEditing whether the comment should be editable
 * @component
 */
export const CommentBox = function ({
  user,
  users,
  comment,
  updateComment,
  disableEditing
}: CommentProps) {
  const [newText, setNewText] = useState(comment.text);
  const [editing, setEditingState] = useState(false);

  const setEditing = (flag: boolean) => {
    !disableEditing && setEditingState(flag);
  };

  const firstName = user ? user.first_name : 'Removed';
  const lastName = user ? user.last_name : 'User';

  // Display the mentioned people in bold
  const text = comment.text.replaceAll(/@\[(.*?)]\(\d+\)/g, '<strong>@$1</strong>');

  /**
   * Function that edits a comment
   */
  const confirmEdit = () => {
    if (newText !== comment.text) {
      // only send request if the text changed
      updateComment(newText, comment.id);
    }
    setEditing(false);
  };

  /**
   * Function that cancels an edit
   */
  const cancelEdit = () => {
    // Reset text to initial state
    setNewText(comment.text);
    setEditing(false);
  };

  return (
    <div className="flex w-full mb-4">
      <Avatar
        fullName={{
          first: firstName,
          last: lastName
        }}
        className={classnames('mr-2', editing ? 'mt-5' : 'my-auto')}
      />
      <div className="w-full">
        <div className="flex pt-1 mb-1 align-content-center">
          <div className="font-bold text-sm specto-text-medium align-self-center">
            {firstName}&nbsp;{lastName}
          </div>
          <div className="ml-2 text-sm">
            {humanReadableSimpleDateTimeFormatted(comment.created)}
          </div>
          <div className="ml-2 text-sm align-self-center">{comment.edited && '(edited)'}</div>
          {disableEditing === true ? (
            <></>
          ) : editing ? (
            <div className="flex">
              <Button
                icon="pi pi-check"
                className="ml-1 p-button-rounded p-button-text p-button-sm h-2rem w-2rem -my-2"
                type="button"
                onClick={confirmEdit}
              />
              <Button
                icon="pi pi-times"
                className="ml-1 p-button-rounded p-button-text p-button-sm h-2rem w-2rem -my-2"
                type="button"
                onClick={cancelEdit}
              />
            </div>
          ) : (
            <Button
              icon="pi pi-pencil"
              className="ml-1 p-button-rounded p-button-text p-button-sm h-2rem w-2rem -my-2"
              type="button"
              onClick={() => setEditing(true)}
            />
          )}
        </div>
        {editing ? (
          <UserMention
            className="mt-2"
            users={users ?? []}
            comment={newText}
            setComment={setNewText}
          />
        ) : (
          <div>{parse(text)}</div>
        )}
      </div>
    </div>
  );
};
