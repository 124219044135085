import React from 'react';
import { FieldValues } from 'react-hook-form';
import { isEmpty, isNull, isString } from 'lodash';

/**
 * Get form Error Message
 * It will return a 20px div regardless, so that the form size doesn't change when errors are present
 */
export const getFormErrorMessage = (error?: { message?: string }, noPadding = false) => (
  <div role="alert" style={{ minHeight: 20, marginBottom: noPadding ? -20 : 0 }}>
    <small className="p-error">{error?.message || ''}</small>
  </div>
);

type UnknownArrayOrObject = unknown[] | Record<string, unknown> | any;

/**
 * React hook form addon to get dirtyValues from dirtyFields formState
 * @param dirtyFields
 * @param allValues
 */
export const dirtyValues = (
  dirtyFields: UnknownArrayOrObject | boolean,
  allValues: UnknownArrayOrObject
): UnknownArrayOrObject => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])])
  );
};

export const isBlankInput = (val: any): boolean => {
  if (isNull(val)) return true;
  if (isString(val)) return isEmpty(val.trim());

  return false;
};

type RelatedKeysMap = Record<string | number, string | number>;

/**
 * Reverse keys of a keymap dictionary. keys and values should be either string or numbers.
 * @param map relational Map
 */
const reverseKeys = (map: RelatedKeysMap) => {
  const ret: RelatedKeysMap = {};
  Object.keys(map).forEach((key) => {
    ret[map[key]] = key;
  });
  return ret;
};

/**
 * Map keys in an object to another object. all other keys will be unchanged
 * E.g. Map isFlow key in formValues to key is_flow
 * mapKeys({ isFlow: 'is_flow' }, formValues);
 *
 * @param map
 * @param form
 * @param options
 */
export const mapKeys = (
  map: RelatedKeysMap,
  form: FieldValues,
  options?: { reverseRelation?: boolean }
) => {
  const keyMap = options?.reverseRelation ? reverseKeys(map) : map;
  const pipedKeyObj: FieldValues = {};

  Object.entries(form).forEach(([key, value]) => (pipedKeyObj[keyMap[key] ?? key] = value));

  return pipedKeyObj;
};

/**
 * Take a string input and:
 * - do not allow double spaces
 * - remove leading and trailing spaces
 * - no empty strings
 * - numbers and boolean values pass through without mutation
 * @param value input to format
 */
export const oneSpaceFormatter = (value: string | number | boolean) => {
  // some inputs such as dropdowns use number values
  if (typeof value !== 'string') {
    return value;
  }
  if (value === ' ') {
    return '';
  }

  return value.split('').filter(oneSpaceFilterFn).join('');
};

/**
 * Filter to filter out double spaces, blank spaces
 * @param val1 value
 * @param ind index
 * @param arr array
 */
const oneSpaceFilterFn = (val1: string, ind: number, arr: string[]) => {
  return ind == 0 || val1 !== ' ' || arr[ind - 1] !== ' ';
};

/**
 * Helper function that takes a JSON object and flattens it to a single level
 *
 * @param obj object to flatten
 * @param parentKey key to prepend to the flattened keys
 * @returns flattened object
 */
export const flattenJSON = (obj: any, parentKey = '') => {
  let result: { [key: string]: any } = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'object') {
      result = { ...result, ...flattenJSON(value, parentKey ? `${parentKey}.${key}` : key) };
    } else {
      result = { [`${parentKey}`]: value };
    }
  });

  return result;
};
