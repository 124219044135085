import { Button } from 'primereact/button';
import React from 'react';
import { UseFieldArrayMove } from 'react-hook-form';

/**
 * Component that contains buttons which move other components' indices
 * @Component
 *
 * @param param0 Prop object containing the following:
 *
 *      componentIndex: index of the component this is attached to
 *
 *      length: number of components in the array, determines if buttons are disabled or not
 *
 *      move: method that swaps or moves the elements
 *
 */
const MoveComponent = function ({
  componentIndex,
  length,
  move
}: {
  componentIndex: number;
  length: number;
  move: UseFieldArrayMove;
}) {
  return (
    <div className="flex">
      <Button
        icon="pi pi-chevron-down"
        aria-label="Move component down"
        type="button"
        className="p-button-rounded p-button-text p-button-sm"
        onClick={() => move(componentIndex, componentIndex + 1)}
        disabled={componentIndex === length - 1}
        data-cy={`move-component-${componentIndex}-down`}
      />
      <Button
        icon="pi pi-chevron-up"
        aria-label="Move component up"
        type="button"
        className="p-button-rounded p-button-text p-button-sm"
        onClick={() => move(componentIndex, componentIndex - 1)}
        disabled={componentIndex === 0}
        data-cy={`move-component-${componentIndex}-up`}
      />
    </div>
  );
};

export default MoveComponent;
