import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export type ConversationsState = {
  searchText: string;
  id: number;
  message: string;
  loading: boolean;
  active: boolean;
};

const initialState: ConversationsState = {
  searchText: '',
  id: -1,
  message: '',
  loading: false,
  active: false
};

export const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    searchConversations: (
      state,
      action: PayloadAction<{
        id?: number;
        searchText?: string | null;
        message?: string;
      }>
    ) => {
      state.searchText = action.payload.searchText || initialState.searchText;
      state.id = action.payload.id || initialState.id;
      state.message = action.payload.message ?? initialState.searchText;
      state.loading = true;
      state.active = true;
    },
    clearConversationsSearch: (state) => {
      state.searchText = initialState.searchText;
      state.id = initialState.id;
      state.message = initialState.message;
      state.loading = false;
      state.active = false;
    },
    setConversationsLoading: (
      state,
      action: PayloadAction<{
        loading: boolean;
      }>
    ) => {
      state.loading = action.payload.loading;
    }
  }
});

export const selectConversations = (state: RootState) => state.conversations;
export const { searchConversations, clearConversationsSearch, setConversationsLoading } =
  conversationsSlice.actions;

export default conversationsSlice.reducer;
