import ApiService from 'services/apiService';
import { FEEDBACK_API_URL } from 'constants/api';
import { FeedbackModel } from 'models/feedback-model';
import { OPEN_PERMISSIONS } from 'constants/roles';

const sendFeedback = (data: FeedbackModel) =>
  ApiService.post<FeedbackModel>(FEEDBACK_API_URL, data);

const feedbackService = {
  sendFeedback,
  roles: OPEN_PERMISSIONS
};

export default feedbackService;
