import { ConversationDetailTagsModel } from 'models/conversation-model';
import { TagModel } from 'models/tag-model';

/**
 * The backend returns tags as an array of integer ids. This Fn maps those back to TagModels.
 * @param allTags all tags of TagModel (that is saved in state)
 * @param tagIds array of tag ids to map to an array of TagModels.
 * tagIds May be null if fetchAPI fails on a get request for some table data
 */
export const mapTags = (allTags: TagModel[], tagIds?: ConversationDetailTagsModel): TagModel[] =>
  tagIds
    ? tagIds
        .map(
          (tagId) =>
            allTags?.find((tag) => tag.id === tagId) ?? {
              id: Number.MIN_SAFE_INTEGER,
              name: '',
              color: ''
            }
        )
        .filter((tag) => tag.id !== Number.MIN_SAFE_INTEGER)
    : [];
