import React, { useState } from 'react';
import { TagModel } from 'models/tag-model';
import { Tag } from 'components/Tags';
import { Tooltip } from 'primereact/tooltip';
import classnames from 'classnames';
import { uniqueId } from 'util/uniqueIdGenerator';

type TagsProps = {
  tags: TagModel[];
  limit?: number;
  className?: string;
  onClick?(e: React.MouseEvent<HTMLDivElement>): void;
};

/**
 * Component for displaying the tag name with a circle to the left that is the tag color.
 * @component
 * @param tags tag
 * @param className extra classes
 * @param onClick optional onClick function
 */
const Tags = function ({ tags, className, onClick }: TagsProps) {
  const [id] = useState(uniqueId('tag'));

  return (
    <div className={classnames(className, 'specto-tag-group w-max', id)} onClick={onClick}>
      <div className={classnames('specto-tags flex align-items-center')}>
        {tags.map((tag: TagModel) => (
          <Tag key={tag.id} tag={tag} size="small" />
        ))}
      </div>

      <Tooltip target={`.${id}`} position="bottom">
        <div className="p-2">
          {tags.map((tag: TagModel) => (
            <Tag key={tag.id} tag={tag} />
          ))}
        </div>
      </Tooltip>
    </div>
  );
};

export default Tags;
