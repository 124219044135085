import { Button } from 'primereact/button';
import React, { CSSProperties } from 'react';
import classNames from 'classnames';

type RemovableFormFieldProps = {
  onRemove(): void;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  deleteLabel?: string;
  roundDeleteButton?: boolean;
  style?: CSSProperties;
};

/**
 * Removable form field. Container containing a delete option to the right
 * @param onRemove
 * @param children
 * @param disabled
 * @param className
 * @param deleteLabel
 * @param roundDeleteButton
 * @param style custom style
 * @constructor
 */
const RemovableFormField = function ({
  onRemove,
  children,
  disabled,
  className,
  deleteLabel = 'REMOVE',
  roundDeleteButton,
  style
}: RemovableFormFieldProps) {
  return (
    <div className={classNames('specto-removable-form-field grid', className)} style={style}>
      {children}
      <div className="col-1 sm:col-2 flex align-items-center justify-content-end">
        <Button
          className={
            roundDeleteButton
              ? 'p-button-rounded specto-r-button-lg'
              : 'font-semibold p-button-text underline text-sm py-2'
          }
          label={roundDeleteButton ? undefined : deleteLabel}
          aria-label={roundDeleteButton ? 'Delete element' : 'Delete component'}
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}
          type="button"
          icon={roundDeleteButton ? 'pi pi-trash' : undefined}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default RemovableFormField;
