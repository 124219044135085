import React, { CSSProperties } from 'react';
import classnames from 'classnames';
import { Badge, BadgeProps } from 'primereact/badge';
import { Button } from 'primereact/button';
import { TooltipOptions } from 'primereact/tooltip/tooltipoptions';

export const iconButtonCellIcon = {
  DELETE: 'pi-trash',
  EDIT: 'pi-pencil'
};

type RoundIconButtonCellOptionsModel = {
  action(e: React.MouseEvent<HTMLButtonElement>): void;
  ariaLabel?: string;
  badgeSeverity?: BadgeProps['severity'];
  className?: string;
  'data-cy'?: string;
  disabled?: boolean;
  hidden?: boolean;
  icon?: string | JSX.Element;
  label?: string;
  loading?: boolean;
  style?: CSSProperties;
  tooltip?: string;
  tooltipOptions?: TooltipOptions;
};

/**
 * Component to display an icon in a Cell.
 * @component
 * @param param0 Prop object containing the following:
 *
 *    action: callback to perform an action on the row
 *    ariaLabel: aria label
 *    badgeSeverity: the color of the badge to display
 *    className: addition className to apply
 *    disabled: whether the button is disabled
 *    'data-cy': cypress tag
 *    hidden: whether the button is hidden
 *    icon: string or jsx representing the icon symbol
 *    label: label for the button
 *    loading: whether the button is loading
 *    style: CSS property style
 *    tooltip: tooltip to display when hovering
 *    tooltipOptions:  the options for the tooltip
 */
export const IconButtonCell = ({
  action,
  ariaLabel,
  badgeSeverity,
  className,
  'data-cy': cypressTag,
  disabled = false,
  hidden = false,
  icon,
  label,
  loading,
  style,
  tooltip,
  tooltipOptions = { position: 'bottom' }
}: RoundIconButtonCellOptionsModel) =>
  hidden ? (
    <></>
  ) : (
    <Button
      label={label}
      text={!!icon}
      rounded={!!icon}
      className={classnames(className, { 'specto-r-button-lg': !!icon })}
      onClick={(e) => {
        e.stopPropagation();
        action(e);
      }}
      type="button"
      tooltip={tooltip}
      disabled={disabled}
      style={style}
      loading={loading}
      tooltipOptions={tooltipOptions}
      data-cy={cypressTag}
      aria-label={ariaLabel}
    >
      {typeof icon === 'string' ? (
        <i className={`pi ${icon} p-overlay-badge text-xl`}>
          {badgeSeverity && (
            <Badge
              // the "p-badge-dot" className that should be used has lower
              // priority than the base badge className, so it does not apply.
              // Using style instead.
              style={{ minWidth: '0.5rem', height: '0.5rem' }}
              severity={badgeSeverity}
            />
          )}
        </i>
      ) : (
        icon
      )}
    </Button>
  );
