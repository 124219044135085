import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import classNames from 'classnames';
import classnames from 'classnames';
import { getFormErrorMessage } from 'util/form';
import { KeyFilterType } from 'primereact/keyfilter';
import { SelectItemOptionsType } from 'primereact/selectitem';
import {
  ControllerFieldState,
  ControllerRenderProps,
  UseControllerProps
} from 'react-hook-form/dist/types/controller';
import Info from './Info';
import { uniqueId } from 'util/uniqueIdGenerator';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderGetTagProps,
  Checkbox,
  Chip,
  TextField
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Tooltip } from 'primereact/tooltip';

/**
 * Component that displays a basic text field in a form.
 *
 * @param characterLimit Character limit for the input.
 * @param className Custom class name.
 * @param control react-hook-form control.
 * @param defaultValue Default value for dropdown.
 * @param disabled Whether the input is disabled or not.
 * @param fieldName The field name in react-hook-form.
 * @param formState react-hook-form form state.
 * @param itemTemplate dropdown item template.
 * @param label Label for the input.
 * @param labelClassName Class name for custom label styling.
 * @param noPadding errorBoundary adds 20px margin when no error occurs so that when an error happens the UI doesn't move.
 * @param onChange Optional onChange function.
 * @param optionLabel What property of the options contains the label.
 * @param optionValue What property of the options contains the value.
 * @param options Dropdown options.
 * @param placeholder Dropdown placeholder.
 * @param readOnly For readonly input.
 * @param required If the dropdown is required.
 * @param tooltipTitle Optional tooltip for the input
 * @param handleValueChanged Callback function that handles changes to dropdown.
 * @param isHidden whether an option in the dropdown is hidden or not
 * @param isOptionEqualToValue Determines if the option represents the given value.
 * @param onlyShowTooltipOnDisabled Displays the tooltip only when the field is disabled.
 */
const MultiAutocompleteField = function ({
  fieldName,
  label,
  characterLimit = 200,
  control,
  defaultValue = '',
  className,
  labelClassName,
  disabled,
  handleValueChanged,
  isHidden,
  isOptionEqualToValue,
  placeholder,
  required = true,
  readOnly,
  renderTags,
  noPadding,
  optionLabel,
  optionValue,
  options,
  itemTemplate,
  onChange,
  info,
  rules,
  tooltipTitle,
  onlyShowTooltipOnDisabled = false
}: {
  fieldName: string;
  disabled?: boolean;
  label?: string;
  characterLimit?: number;
  defaultValue?: any;
  className?: string;
  labelClassName?: string;
  control: Control;
  required?: boolean;
  keyFilter?: KeyFilterType;
  placeholder?: string;
  readOnly?: boolean;
  optionLabel?: string;
  optionValue?: string;
  noPadding?: boolean;
  options: SelectItemOptionsType;
  itemTemplate?: (item: any) => JSX.Element;
  onChange?(e: any): void;
  info?: string;
  rules?: UseControllerProps['rules'];
  tooltipTitle?: string;
  renderTags?: (value: any, getTagProps: AutocompleteRenderGetTagProps) => React.ReactNode;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  handleValueChanged?(
    originalValue: any[],
    newValue: any[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<any> | undefined
  ): any[];
  isHidden?: (value: any) => boolean;
  onlyShowTooltipOnDisabled?: boolean;
}) {
  const selectId = uniqueId('select');
  const inputId = uniqueId('select');
  return (
    <div className={classnames(className, { field: !noPadding })}>
      <div className="flex">
        {label && (
          <label htmlFor={selectId} className={classnames(labelClassName)}>
            {label} {required && <span className="p-error">*</span>}
          </label>
        )}
        {info && <Info text={info} />}
      </div>
      {((tooltipTitle && !onlyShowTooltipOnDisabled) ||
        (tooltipTitle && onlyShowTooltipOnDisabled && disabled)) && (
        <Tooltip content={tooltipTitle} target={`#${inputId}`} position="top" />
      )}
      <Controller
        name={fieldName}
        control={control}
        defaultValue={defaultValue}
        rules={{
          ...(required && { required: 'Required field' }),
          maxLength: {
            value: characterLimit,
            message: `Exceeded max length of ${characterLimit} characters`
          },
          ...rules
        }}
        render={({ field, fieldState }) => (
          <>
            <MultiAutocomplete
              field={field}
              fieldState={fieldState}
              disabled={disabled}
              handleValueChanged={handleValueChanged}
              isHidden={isHidden}
              inputId={inputId}
              isOptionEqualToValue={isOptionEqualToValue}
              placeholder={placeholder}
              readOnly={readOnly}
              renderTags={renderTags}
              optionLabel={optionLabel}
              optionValue={optionValue}
              options={options}
              itemTemplate={itemTemplate}
              onChange={onChange}
            />
            {getFormErrorMessage(fieldState.error, noPadding)}
          </>
        )}
      />
    </div>
  );
};

/**
 * Component that displays a basic text field in a form.
 *
 * @param field Current value of Autocompelte.
 * @param fieldset Current state of Autocomplete.
 * @param inputId The id of the Autocomplete TextField.
 * @param renderTags How the selected values are rendered.
 * @param optionValue Which value to take from Autocomplete option.
 * @param disabled Whether the input is disabled or not.
 * @param itemTemplate dropdown item template.
 * @param onChange Optional onChange function.
 * @param optionLabel What property of the options contains the label.
 * @param handleValueChanged Callback function that handles changes to dropdown.
 * @param options Dropdown options.
 * @param placeholder Dropdown placeholder.
 * @param readOnly For readonly input.
 * @param isHidden whether an option in the dropdown is hidden or not
 * @param isOptionEqualToValue Determines if the option represents the given value.
 */
export const MultiAutocomplete = function ({
  field,
  fieldState,
  disabled,
  handleValueChanged,
  isHidden,
  inputId,
  isOptionEqualToValue,
  placeholder,
  readOnly,
  renderTags,
  optionLabel,
  optionValue,
  options,
  itemTemplate,
  onChange
}: {
  disabled?: boolean;
  field: ControllerRenderProps<FieldValues, any>;
  fieldState: ControllerFieldState;
  inputId: string;
  keyFilter?: KeyFilterType;
  placeholder?: string;
  readOnly?: boolean;
  optionLabel?: string;
  optionValue?: string;
  options: SelectItemOptionsType;
  itemTemplate?: (item: any) => JSX.Element;
  onChange?(e: any): void;
  renderTags?: (value: any, getTagProps: AutocompleteRenderGetTagProps) => React.ReactNode;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  handleValueChanged?(
    originalValue: any[],
    newValue: any[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<any> | undefined
  ): any[];
  isHidden?: (value: any) => boolean;
}) {
  return (
    <Autocomplete
      {...field}
      className="w-full material-field"
      // The autocomplete wants the full option object but the controller only stores the value
      // so we need to find the option object that matches the value
      value={
        optionValue
          ? field?.value?.map(
              (val: any) => options?.find((item) => item[optionValue] === val) ?? val
            )
          : field?.value
      }
      options={options}
      onChange={(_e, value, reason, details) => {
        let val = optionValue ? value.map((item) => item[optionValue]) : value;
        val = handleValueChanged ? handleValueChanged(field.value, val, reason, details) : val;
        field.onChange(val);
        onChange?.(val);
      }}
      renderInput={(params) => (
        <span id={inputId}>
          <TextField
            {...params}
            className={classNames(`specto-input-select autocomplete-input`, {
              'p-invalid': fieldState.error
            })}
            placeholder={field.value ? placeholder : ''}
            aria-label={`Currently selected: ${field.value}`}
          />
        </span>
      )}
      renderOption={(props, option, { selected }) => {
        // Need to create list items with checkboxes
        if (isHidden && isHidden(optionValue ? option[optionValue] : option)) {
          return undefined;
        } else {
          const label = optionLabel ? option[optionLabel] ?? option : option;
          return (
            <li {...props} className={classNames(props.className, 'material-option')}>
              <Checkbox checked={selected} />
              {itemTemplate ? itemTemplate(option) : label}
            </li>
          );
        }
      }}
      renderTags={
        renderTags
          ? renderTags
          : (value, getTagProps) =>
              value.map((val, index) => {
                return (
                  <Chip
                    {...getTagProps({ index })}
                    className="autocomplete-field-chips"
                    label={optionLabel ? val[optionLabel] : val}
                    deleteIcon={<CancelOutlinedIcon />}
                  />
                );
              })
      }
      getOptionLabel={(option) => (optionLabel ? option[optionLabel] ?? option : option)}
      isOptionEqualToValue={
        isOptionEqualToValue
          ? isOptionEqualToValue
          : (option, value) =>
              optionValue ? option[optionValue] === value[optionValue] : option === value
      }
      disableClearable
      readOnly={readOnly}
      disabled={disabled}
      multiple
      disableCloseOnSelect
      popupIcon={<ExpandMoreIcon />}
    />
  );
};

export default MultiAutocompleteField;
