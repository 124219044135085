import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Button } from 'primereact/button';
import { setLayout } from 'features/layout/layoutSlice';
import ModalLarge from 'containers/modals/ModalLarge';
import { UserModel } from 'models/user-model';
import UsersService from 'services/usersService';
import { FieldValues } from 'react-hook-form';
import { authSelector } from 'features/auth/authSlice';
import { mapUsersToProjectMembers, isSameUser } from 'util/users';
import { confirmDialog } from 'primereact/confirmdialog';
import { createToast } from 'features/toast/toastSlice';
import { useNavigate } from 'react-router-dom';
import { routes } from 'constants/routes';
import useToggleKeys from 'hooks/useToggleKeys';
import ProjectsService from 'services/projectsService';
import { projectsSelector, userGroupsSelector } from 'features/projects/projectsSlice';
import {
  EditProjectMemberOptionsModel,
  ProjectMemberOptionsModel,
  ProjectMemberModel,
  ProjectRole,
  ProjectMemberUserModel
} from 'models/project-model';
import useFetch from 'hooks/useFetch';
import { FetchStatusOptions } from 'constants/fetchStatus';
import useFilter from 'hooks/useFilter';
import { updateObjectInArray } from 'util/record';
import { SlugIdOptionsModel, SlugOptionsModel } from 'models/api-model';
import SearchBar from 'components/SearchBar';
import MembersDataTable from './MembersDataTable';
import MemberPopup from './MemberPopup';
import { getProjectSettingsLayout } from 'util/layout';
import { FormMode } from 'models/form-model';

const MembersSettings = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user: ownUser } = useAppSelector(authSelector);
  const { selectedProject } = useAppSelector(projectsSelector);
  const [popupMode, setPopupMode] = useState<FormMode>(FormMode.CREATE);
  const [allProjectMembers, setAllProjectMembers] = useState<ProjectMemberModel[]>([]);
  const [systemUsersRecord, setSystemUsersRecord] = useState<Record<string, UserModel>>({});
  const [memberToDelete, setMemberToDelete] = useState<ProjectMemberModel | null>(null);
  const [userDeleteConfirm, setUserDeleteConfirm] = useState(false);
  const [allUsersSuggestions, setAllUsersSuggestions] = useState<UserModel[]>([]);
  const [displayEditMemberPopup, setDisplayEditMemberPopup] = useState(false);
  const [contextMember, setContextMember] = useState<ProjectMemberUserModel | null>(null);
  const { filteredData: suggestedUsers, setData: setUserSuggestions } = useFilter<UserModel>();
  const allUserGroups = useAppSelector(userGroupsSelector);

  const {
    toggleKeySingle: setMemberRowLoading,
    isKeyExpanded: isMemberRowLoading,
    clearKeys: clearMemberRowsLoading
  } = useToggleKeys();
  const {
    data: editProjectMemberResponseData,
    fetchStatus: editProjectMemberStatus,
    fetch: editProjectMember
  } = useFetch<EditProjectMemberOptionsModel, ProjectMemberModel>(
    ProjectsService.editProjectMember,
    ProjectsService.roles.members.update
  );
  const {
    filteredData: filteredMembersData,
    search: searchMembers,
    searchText,
    simpleSearch,
    clearSearch,
    setData: setFilteredMembersData,
    loading: searchMembersLoading
  } = useFilter<ProjectMemberModel>();

  /**
   * Created a list of available role options
   * Hides the admin role if the user is not currently an admin
   *
   * @param isAdmin whether the added user is an admin
   * @returns list of relevant roles
   */
  const memberRolesOptions = (isAdmin?: boolean) =>
    Object.entries(ProjectRole)
      .filter((role) => isAdmin || role[1] !== ProjectRole.NEUROSOPH)
      .map((role, idx) => ({
        id: idx,
        label: role[0]
          .split('_')
          .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
          .join(' '),
        value: role[1],
        hidden: role[1] === ProjectRole.NEUROSOPH && !isAdmin
      }));

  // onInit
  useEffect(() => {
    dispatch(setLayout(getProjectSettingsLayout(ownUser.is_superuser)));
    getUsers({});
  }, []);

  /* - - - - - - - - - - Get System Users - - - - - - - - - - */
  const {
    fetch: getUsers,
    data: users,
    fetchStatus: getUsersStatus
  } = useFetch<any, UserModel[]>(UsersService.getUsers, UsersService.roles.list);

  // handle fetched users
  useEffect(() => {
    if (getUsersStatus === FetchStatusOptions.SUCCESS && users) {
      // set all users as the complete suggestion list
      setAllUsersSuggestions(users);

      // set project member permissions in a Record of { email: systemUser }
      setSystemUsersRecord(
        Object.fromEntries(users.map((systemUser) => [systemUser.id, systemUser]))
      );

      getProjectMembers({ slug: selectedProject.slug });
    }
  }, [getUsersStatus]);

  /* - - - - - - - - - - Get System Members - - - - - - - - - - */
  const {
    fetch: getProjectMembers,
    data: getProjectMembersData,
    fetchStatus: getProjectMembersStatus
  } = useFetch<SlugOptionsModel, ProjectMemberModel[]>(
    ProjectsService.getProjectMembers,
    ProjectsService.roles.members.list
  );

  // handle fetched members
  useEffect(() => {
    if (getProjectMembersStatus === FetchStatusOptions.SUCCESS && getProjectMembersData) {
      // set project members
      setProjectMembers(getProjectMembersData);
    }
  }, [getProjectMembersStatus]);

  // when project members change, update user suggestions to be a list of users that are not in selected already
  useEffect(() => {
    setUserSuggestions(
      allUsersSuggestions.filter((systemUser) =>
        allProjectMembers.every(
          (projectMember) => projectMember.user !== systemUser.id && systemUser.is_active
        )
      )
    );
  }, [allProjectMembers]);

  // edit member role effect handler
  useEffect(() => {
    if (editProjectMemberStatus === FetchStatusOptions.SUCCESS && editProjectMemberResponseData) {
      const updatedMembers = updateObjectInArray<ProjectMemberModel>(
        allProjectMembers,
        editProjectMemberResponseData,
        'id'
      );

      if (updatedMembers) {
        setProjectMembers(updatedMembers);
        hidePopup();
        dispatch(createToast('project member role edited'));
      }

      clearMemberRowsLoading();
    } else if (editProjectMemberStatus === FetchStatusOptions.ERROR) {
      clearMemberRowsLoading();
    }
  }, [editProjectMemberStatus]);

  // When form has submitted, then check the autocompleted email.
  // This is the most efficient way to combine pressing enter on non-autocomplete results && autocompleted selected results
  const addUserToProject = (formData: FieldValues) => {
    if (allProjectMembers.find((member) => member.id === formData.id)) {
      dispatch(createToast('user already exists'));
      return;
    }

    addProjectMember({
      slug: selectedProject.slug,
      role: formData.role,
      user: formData.user,
      user_groups:
        formData.role === ProjectRole.ADMIN
          ? allUserGroups.map((ug) => ug.id)
          : formData.user_groups
    });
  };

  /* - - - - - - - - - - Add System Member - - - - - - - - - - */
  const {
    fetch: addProjectMember,
    data: addProjectMemberData,
    fetchStatus: addProjectMemberStatus
  } = useFetch<ProjectMemberOptionsModel, ProjectMemberModel>(
    ProjectsService.addProjectMember,
    ProjectsService.roles.members.create
  );

  // handle fetched Member
  useEffect(() => {
    if (addProjectMemberStatus === FetchStatusOptions.SUCCESS && addProjectMemberData) {
      setProjectMembers([addProjectMemberData, ...allProjectMembers]);
      hidePopup();
      dispatch(createToast('user added to project'));
    }
  }, [addProjectMemberStatus]);

  /* - - - - - - - - - - Remove Project Member - - - - - - - - - - */

  const {
    error: removeProjectMemberError,
    fetchStatus: removeProjectMemberStatus,
    fetch: removeProjectMember
  } = useFetch<SlugIdOptionsModel<ProjectMemberModel['id']>>(
    ProjectsService.removeProjectMember,
    ProjectsService.roles.members.delete
  );

  const deleteConfirmation = () => {
    confirmDialog({
      message: 'They will no longer have access to this project',
      header: `Remove ${systemUsersRecord[memberToDelete?.user ?? '']
        ?.first_name} ${systemUsersRecord[memberToDelete?.user ?? '']?.last_name} ?`,
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      draggable: false,
      accept: () => setUserDeleteConfirm(true),
      reject: clearRemoveMemberConfirmation
    });
  };

  // remove member Effect
  useEffect(() => {
    if (!memberToDelete || !selectedProject) {
      return;
    }

    // must have member delete confirmation
    if (!userDeleteConfirm) {
      deleteConfirmation();
      return;
    }

    setMemberRowLoading(memberToDelete.id); // activates spinner for row of this ID
    removeProjectMember({ slug: selectedProject.slug, id: memberToDelete.id });
  }, [memberToDelete, userDeleteConfirm]);

  // handle remove member response
  useEffect(() => {
    if (removeProjectMemberStatus === FetchStatusOptions.SUCCESS) {
      if (!memberToDelete) {
        console.error('user to delete is null');
        return;
      }

      setProjectMembers(
        allProjectMembers.filter((user: ProjectMemberModel) => user.id !== memberToDelete?.id)
      );
      clearMemberRowsLoading(); // set loading spinner of row false first, so no state updates happen after possible navigate()

      // if removing yourself, and you're not superuser then route to dashboard
      if (isSameUser(ownUser, memberToDelete) && !ownUser?.is_superuser) {
        navigate(routes.HOME); // navigate first
      }
      clearRemoveMemberConfirmation();
      dispatch(createToast('user deleted'));
    } else if (removeProjectMemberStatus === FetchStatusOptions.ERROR && removeProjectMemberError) {
      clearMemberRowsLoading();
    }
  }, [removeProjectMemberStatus]);

  // need to keep track of all project members as filtered out members are just for the view
  const setProjectMembers = (members: ProjectMemberModel[]) => {
    const modifiedMembers = mapUsersToProjectMembers(allUsersSuggestions, members);
    setAllProjectMembers(modifiedMembers);
    setFilteredMembersData(modifiedMembers);
  };

  const clearRemoveMemberConfirmation = () => {
    setMemberToDelete(null);
    setUserDeleteConfirm(false);
  };

  const onClickDeleteUser = (projectUser: ProjectMemberModel) => {
    setMemberToDelete(projectUser);
  };

  const onChangeUserPermission = (role: ProjectRole, projectUser: ProjectMemberModel) => {
    setMemberRowLoading(projectUser.id); // set loading state for specific member row
    editProjectMember({
      slug: selectedProject.slug,
      id: projectUser.id,
      role: role,
      user: projectUser.user,
      user_groups: projectUser.user_groups
    });
  };

  /**
   * Callback to change the new intent pop-up visibility in state.
   * @param visibility boolean representing the visibility of the new intent popup
   * @param context the context intent, used for editing
   */
  const handlePopupDisplayChange = (
    visibility: boolean,
    popupMode: FormMode = FormMode.CREATE,
    context: ProjectMemberUserModel | null = null
  ) => {
    setContextMember(context);
    setPopupMode(popupMode);
    setDisplayEditMemberPopup(visibility);
  };

  // Handle hiding member popup
  const hidePopup = () => handlePopupDisplayChange(false);

  // Handle submit from member popup
  const onSubmit = (data: FieldValues) => {
    if (popupMode === FormMode.CREATE) {
      addUserToProject(data);
    } else {
      editProjectMember({
        slug: selectedProject.slug,
        id: data.id,
        role: data.role,
        user: data.user,
        user_groups:
          data.role === ProjectRole.ADMIN ? allUserGroups.map(({ id }) => id) : data.user_groups
      });
    }
  };

  return (
    <ModalLarge
      className="specto-members-settings"
      loading={getUsersStatus === FetchStatusOptions.LOADING}
    >
      <h1 className="align-self-center mb-7">Members & Roles</h1>

      <h6>Project Members</h6>
      <div className="flex sm:flex-row flex-column-reverse w-full align-items-center gap-5 lg:gap-3 xl:gap-5 justify-content-between">
        <SearchBar
          placeHolder="Search all Members"
          text={searchText}
          formInputStyle
          loading={searchMembersLoading}
          onChange={(e) => simpleSearch(e.target.value)}
          onSubmitText={searchMembers}
          onClearText={() => {
            clearSearch();
            searchMembers('');
          }}
        />
        <Button
          label="Add Member"
          className="add-member-btn py-2 px-3 mr-3 sm:mr-0 white-space-nowrap min-w-min"
          icon="pi pi-plus"
          onClick={() => handlePopupDisplayChange(true)}
        />
      </div>
      <div className="w-full">
        <MembersDataTable
          filteredMembersData={filteredMembersData}
          systemUsersRecord={systemUsersRecord}
          ownUser={ownUser}
          memberRolesOptions={memberRolesOptions}
          onClickDeleteUser={onClickDeleteUser}
          handlePopupDisplayChange={handlePopupDisplayChange}
          isMemberRowLoading={isMemberRowLoading}
          userGroups={allUserGroups}
        />
        {displayEditMemberPopup && (
          <MemberPopup
            displayPopup={displayEditMemberPopup}
            parentElement={document.activeElement as HTMLElement}
            onHide={hidePopup}
            context={contextMember}
            memberRolesOptions={memberRolesOptions}
            userGroups={allUserGroups}
            onSubmit={onSubmit}
            mode={popupMode}
            suggestedUsers={suggestedUsers}
          />
        )}
      </div>
    </ModalLarge>
  );
};

export default MembersSettings;
