import { FilterChips } from 'components/FilterChips/FilterChips';
import {
  projectMembersSelector,
  projectsSelector,
  responsesSelector,
  userGroupsSelector,
  usersSelector
} from 'features/projects/projectsSlice';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { ProjectMemberUserModel } from 'models/project-model';
import React, { useEffect, useRef, useState } from 'react';
import { isArrayAndIncludes, sortbyProperty } from 'util/arrays';
import {
  filterAuthors,
  findProjectMember,
  mapSuperUsersToAuthors,
  mapUsersToProjectMembers
} from 'util/users';
import ConversationsFilterMenu from './Components/ConversationsFilterMenu';
import { authSelector } from 'features/auth/authSlice';
import useQuery from 'hooks/useQuery';
import { FieldValues, useForm } from 'react-hook-form';
import useFetch from 'hooks/useFetch';
import EventsService from 'services/eventsService';
import { FetchStatusOptions } from 'constants/fetchStatus';
import ConversationViewerContent from './Components/ConversationViewerContent';
import { ConversationDataModel, ConversationsDataModel } from 'models/events-model';
import { setMessages } from 'features/widget/widgetSlice';
import { mapIdToName } from 'util/mapIdAndName';
import { FilterChip } from 'models/filter-chips';
import { buildMultipleChips } from 'util/filters';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { formatDateISOEnd, formatDateISOStart } from 'util/dates';
import SearchBar from 'components/SearchBar';
import ConversationDatePicker from './Components/ConversationDatePicker';

const maxSearchDays = 30;
const defaultDateDiffNum = 7;

const initConversationsData: ConversationsDataModel = {
  count: 0,
  next: null,
  previous: null,
  results: []
};

const conversationsFilterDefaultValues: FieldValues = {
  author: [],
  conversation: null,
  user_group: [],
  response: [],
  timestamp_gte: new Date(new Date().setDate(new Date().getDate() - defaultDateDiffNum)),
  timestamp_lte: new Date()
};

const ConversationViewer = () => {
  const dispatch = useAppDispatch();
  const { selectedProject } = useAppSelector(projectsSelector);
  const allUserGroups = useAppSelector(userGroupsSelector);
  const allProjectMembers = useAppSelector(projectMembersSelector);
  const allUsers = useAppSelector(usersSelector);
  const allResponses = useAppSelector(responsesSelector);
  const { user } = useAppSelector(authSelector);
  const filterChipsref = useRef<HTMLDivElement>(null);

  const [conversationsData, setConversationsData] = useState(initConversationsData);
  const [selectedConversation, setSelectedConversation] = useState<ConversationDataModel | null>(
    null
  );
  const [copiedConversationId, setCopiedConversationId] = useState<number | null>(null);
  const [paginationState, setPaginationState] = useState({
    rows: 12,
    totalRecords: 0,
    first: 0,
    filters: {}
  });

  // Find admins from users and convert them to the same object as author
  const superUserAuthors: ProjectMemberUserModel[] = mapSuperUsersToAuthors(
    allUsers.filter((u) => u.is_superuser),
    allUserGroups.map((ug) => ug.id)
  );

  // Map users data to fit authors data structure
  const authors: ProjectMemberUserModel[] = mapUsersToProjectMembers(allUsers, allProjectMembers);

  // List of all authors including super uers
  const allAuthors: ProjectMemberUserModel[] = sortbyProperty(
    authors.concat(superUserAuthors),
    'name_reversed'
  );

  // Get currently logged in project user as a project user
  const ownProjectUser = findProjectMember(allAuthors, user) as ProjectMemberUserModel;

  // perform back end query using the search
  const onQuery = (queryText: string) => {
    // set query parameters which will build a queryParameter string
    setFilterQueryParameter({ search: queryText });
  };

  // useForm
  const {
    control: filterControl,
    reset: resetFilterForm,
    formState: { dirtyFields: dirtyFilterFields, isDirty: isFilterFormDirty },
    getValues: getFilterValues,
    setValue: setFilterValue
  } = useForm({ defaultValues: conversationsFilterDefaultValues, mode: 'onChange' });

  // useQuery
  const {
    search,
    searchText,
    clearSearch,
    simpleSearch,
    setQueryParameter: setFilterQueryParameter,
    queryString: filterQueryString,
    queryParameters: filterQueryParameters,
    clearAllQueryParameters,
    clearQueryParameter
  } = useQuery({
    query: onQuery,
    deps: [dirtyFilterFields],
    defaultValues: {
      limit: paginationState.rows,
      offset: paginationState.first,
      timestamp_gte: formatDateISOStart(
        DateTime.now().minus({ days: defaultDateDiffNum }).toJSDate()
      ),
      timestamp_lte: formatDateISOEnd(DateTime.now().toJSDate())
    }
  });

  const resetFilter = () => {
    clearSearch();
    // User groups should be set to an empty array instead of the default value
    clearAllQueryParameters({ user_group: [] });
    resetFilterForm();
  };

  // Copies the conversation ID to the clipboard
  const handleCopyConversationId = (id: number) => setCopiedConversationId(id);

  // Selects a conversation when it's clicked on
  const handleSelectConversation = (conversation: ConversationDataModel) =>
    setSelectedConversation(conversation);

  // Handles changing the pagination by clicking PREV/NEXT/page number
  const handlePaginationChange = (first: number, rows: number) =>
    setPaginationState({ ...paginationState, first, rows });

  useEffect(
    () => setFilterQueryParameter({ limit: paginationState.rows, offset: paginationState.first }),
    [paginationState]
  );

  /* - - - - - - - - - - Get Conversations - - - - - - - - - - */
  const {
    fetch: getConversations,
    data: getConversationsData,
    fetchStatus: getConversationsStatus
  } = useFetch(EventsService.getPaginatedConversations, EventsService.roles.retrieve);

  useEffect(() => {
    getConversations({ slug: selectedProject.slug, query: filterQueryString });
    setSelectedConversation(null);
  }, [filterQueryString]);

  useEffect(() => {
    if (getConversationsStatus === FetchStatusOptions.SUCCESS && getConversationsData) {
      setConversationsData(getConversationsData);
      if (getConversationsData.results[0]) setSelectedConversation(getConversationsData.results[0]);
      else setSelectedConversation(null);
      setPaginationState({ ...paginationState, totalRecords: getConversationsData.count });
    }
  }, [getConversationsStatus]);

  useEffect(() => {
    if (selectedConversation) dispatch(setMessages(selectedConversation.messages));
  }, [selectedConversation]);

  /* - - - - - - - - - - Filter Chips - - - - - - - - - - */
  const conversationIdString = filterQueryParameters?.conversation
    ? (filterQueryParameters.conversation as string)
    : '';

  const filterChips: FilterChip[] = [
    ...buildMultipleChips(filterQueryParameters.author, (userId: number) => ({
      name: `Author: ${mapIdToName(userId, allAuthors, 'user')}`,
      removeFn: () => {
        const author: number[] = getFilterValues('author');
        const filteredAuthors = author.filter((id) => id !== userId);

        if (filteredAuthors.length === 0) {
          clearQueryParameter('author');
          setFilterValue('author', conversationsFilterDefaultValues['author'], {
            shouldDirty: true
          });
        } else {
          setFilterValue('author', filteredAuthors);
          setFilterQueryParameter({ author: filteredAuthors });
        }
      },
      enabled: isArrayAndIncludes(filterQueryParameters.author, userId)
    })),
    {
      name: `Conversation ID: ${
        conversationIdString.length > 15
          ? `${conversationIdString.slice(0, 15)}...`
          : conversationIdString
      }`,
      removeFn: () => {
        clearQueryParameter('conversation');
        setFilterValue('conversation', conversationsFilterDefaultValues['conversation'], {
          shouldDirty: true
        });
      },
      enabled: (filterQueryParameters.conversation as string)?.length > 0
    },
    ...buildMultipleChips(filterQueryParameters.user_group, (userGroup) => ({
      name: `User Group: ${userGroup === 0 ? 'None' : mapIdToName(userGroup, allUserGroups)}`,
      removeFn: () => {
        const updatedUserGroups = (filterQueryParameters.user_group as number[]).filter(
          (group) => group !== userGroup
        );
        setFilterQueryParameter({
          user_group: updatedUserGroups
        });
        setFilterValue('user_group', updatedUserGroups, {
          shouldDirty: true
        });
      },
      enabled: true,
      removable: true
    })),
    ...buildMultipleChips(filterQueryParameters.response, (responseId: number) => ({
      name: `Response: ${mapIdToName(responseId, allResponses)}`,
      removeFn: () => {
        const response: number[] = getFilterValues('response');
        const filteredResponses = response.filter((id) => id !== responseId);

        if (filteredResponses.length === 0) {
          clearQueryParameter('response');
          setFilterValue('response', conversationsFilterDefaultValues['response'], {
            shouldDirty: true
          });
        } else {
          setFilterValue('response', filteredResponses);
          setFilterQueryParameter({ response: filteredResponses });
        }
      },
      enabled: isArrayAndIncludes(filterQueryParameters.response, responseId)
    }))
  ];

  const filterDeps = [filterChips];

  const getDateDiff = () => {
    const date_lte = DateTime.fromJSDate(getFilterValues('timestamp_lte'));
    const date_gte = DateTime.fromJSDate(getFilterValues('timestamp_gte'));

    return date_lte.diff(date_gte, 'days').days;
  };

  // Removes checked icon after a conversation ID is copied
  useEffect(() => {
    if (!copiedConversationId) return;
    const timer = setTimeout(() => setCopiedConversationId(null), 3000);
    return () => clearTimeout(timer);
  }, [copiedConversationId]);

  return (
    <div
      className="conversation-viewer-page flex flex-column pb-2"
      style={{ height: `calc(100vh - 200px - ${filterChipsref.current?.clientHeight}px)` }}
    >
      <div className="grid grid-nogutter">
        <div className="col flex flex-wrap xl:align-items-center align-items-start justify-content-between flex-column-reverse xl:flex-row mb-2">
          <SearchBar
            filterMenu={
              <ConversationsFilterMenu
                loading={getConversationsStatus === FetchStatusOptions.LOADING}
                filterQueryString={filterQueryString}
                control={filterControl}
                userGroups={allUserGroups}
                projectUser={ownProjectUser}
                responses={allResponses}
                authors={filterAuthors(user.is_superuser, allAuthors, ownProjectUser?.user)}
                onFormValueChange={(fieldValue) => setFilterQueryParameter(fieldValue)}
                getFilterValues={getFilterValues}
                searchText={searchText}
              />
            }
            datePicker={
              <ConversationDatePicker
                control={filterControl}
                onFormValueChange={(fieldValue) => {
                  if (getDateDiff() > maxSearchDays) {
                    setFilterQueryParameter({ ...fieldValue, search: '' });
                  } else {
                    setFilterQueryParameter({ ...fieldValue, search: searchText });
                  }
                }}
                getFilterValues={getFilterValues}
              />
            }
            placeHolder="Search by user message..."
            tooltipText={
              getFilterValues('conversation')
                ? 'Remove Conversation ID to enable search.'
                : 'Please select a date range that is 30 days or less to enable search.'
            }
            className="specto-search-bar mt-3 xl:mt-0"
            text={searchText}
            onChange={(e) => simpleSearch(e.target.value)}
            onSubmitText={search}
            onClearText={() => {
              clearSearch();
              search('');
            }}
            onFilterClearClick={() => resetFilter()}
            hideFilterClear={!isFilterFormDirty}
            searchMaxWidth={75}
            inputDisabled={getDateDiff() > maxSearchDays || getFilterValues('conversation')}
          />
        </div>

        {filterChips.length > 0 && (
          <div className="col-12 mb-2" ref={filterChipsref}>
            <FilterChips
              filterChips={filterChips}
              deps={filterDeps}
              className={classNames('lg:w-9', {})}
            />
          </div>
        )}

        <div className="col-12"></div>
      </div>
      <ConversationViewerContent
        conversationsData={conversationsData}
        copiedConversationId={copiedConversationId}
        selectedConversation={selectedConversation}
        handleCopyConversationId={handleCopyConversationId}
        handleSelectConversation={handleSelectConversation}
        paginationState={paginationState}
        handlePaginationChange={handlePaginationChange}
        getConversationsStatus={getConversationsStatus}
      />
    </div>
  );
};

export default ConversationViewer;
