import React, { useEffect } from 'react';
import { Control, Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import { QuillEditor } from 'features/quill-editor';
import { getFormErrorMessage } from 'util/form';
import { textAreaInputMaxLength, urlInputMaxLength } from 'constants/inputs';

type RichTextFormProps = {
  control: Control;
  defaultValue?: string;
  setValue: UseFormSetValue<FieldValues>;
  readOnly?: boolean;
  controlRules?: any;
  controlName?: string;
  minHeight?: string | number;
};

const RichTextForm = function ({
  control,
  defaultValue,
  setValue,
  readOnly = false,
  controlName = 'richTextForm',
  minHeight = '60vh',
  controlRules
}: RichTextFormProps) {
  // defaultValue workaround as the quill editor DefaultValue is not working
  useEffect(() => {
    if (defaultValue) setValue(controlName, defaultValue);
  }, []);

  return (
    <Controller
      control={control}
      name={controlName}
      defaultValue={defaultValue}
      rules={controlRules}
      render={({ field, fieldState }) => (
        <>
          <QuillEditor
            placeholder="Click here to start typing..."
            style={{ minHeight }}
            readOnly={readOnly}
            {...field}
            ref={undefined}
            /**
             * Need to use set value since 'field' only has onChange, not onTextChange. As
             * well, we want to set the value to the e.html value.
             *
             * Need to manually set the field as dirty to show it is updated. setValue does not do
             * this by default.
             *
             * ref must be undefined as it was causing issues rendering with Response table control property
             */
            onTextChange={(e) => setValue(field.name, e.htmlValue, { shouldDirty: true })}
            modalMaxLength={urlInputMaxLength}
            editorMaxLength={textAreaInputMaxLength}
          />
          {getFormErrorMessage(fieldState.error, true)}
        </>
      )}
    />
  );
};

export default RichTextForm;
