import React from 'react';
import { Control, Controller } from 'react-hook-form';
import classnames from 'classnames';
import { Slider, SliderChangeEvent } from 'primereact/slider';

/**
 * Component that displays the tag's field for the create response form.
 *
 * @param className class name
 * @param control  form Control
 * @param fieldName field name
 * @param label field label
 * @param labelClassName label class name
 * @param labelFn label function
 * @param max max value
 * @param onChange onChange callback
 * @param placeholder input placeholder
 * @param range input range
 * @constructor
 */
const SliderField = function ({
  className,
  control,
  fieldName = 'slider',
  label,
  labelClassName,
  labelFn,
  max = 100,
  onChange,
  placeholder,
  range = true
}: {
  className?: string;
  control: Control;
  fieldName?: string;
  label?: string;
  labelClassName?: string;
  labelFn?(value: any): string;
  max?: number;
  onChange?(e: SliderChangeEvent): void;
  placeholder?: string;
  range?: boolean;
}) {
  return (
    <div className={className}>
      {label && (
        <label htmlFor={fieldName} className={classnames(labelClassName)}>
          {label}
        </label>
      )}
      <Controller
        name={fieldName}
        control={control}
        render={({ field }) => (
          <>
            {labelFn && (
              <label htmlFor={fieldName} className={classnames(labelClassName)}>
                {labelFn(field.value)}
              </label>
            )}
            <Slider
              className="mt-3"
              range={range}
              max={max}
              placeholder={placeholder}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
                onChange?.(e);
              }}
              data-cy={field.name}
            />
          </>
        )}
      />
    </div>
  );
};

export default SliderField;
