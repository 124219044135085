import React, { useState } from 'react';
import { DataTable, DataTablePageEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useAppSelector } from 'hooks/store';
import { intentsSelector, sentimentsSelector } from 'features/projects/projectsSlice';
import { NLUModel } from 'models/nlu-table-data-model';
import { CommentPopup, ThreadStateChangeEvent } from 'pages/Comments/CommentPopup';
import { CommentType } from 'models/comment-model';
import { CommentThreadButton } from 'pages/Comments/CommentThreadButton';
import { versionsSelector } from 'features/versions/versionsSlice';
import { confirmDialog } from 'primereact/confirmdialog';
import { GenericItemTemplate, IdToNameCell, TableHeader } from 'components/Table';
import { MenuButtonCell } from 'components/Table/MenuButtonCell';
import paginatorTemplate from 'components/Table/PaginatorTemplate';
import { customSortIconFn } from 'util/table';

type NLUTablePropsModel = {
  allNLUData: NLUModel[];
  onDeleteNLUData?(e: NLUModel): void;
  onThreadStateChange?(options: ThreadStateChangeEvent): void;
  loading?: boolean;
  sentiment?: boolean;
  onPage?(e: DataTablePageEvent): void;
  rows: number;
  first: number;
  totalRecords?: number;
  canDelete?: boolean;
  onEditInit?(e: NLUModel): void;
  showComments?: boolean;
};

/**
 * Component for displaying the NLU Data Table.
 *
 * @param allNLUData List of all NLU data
 * @param onThreadStateChange Callback function for the thread state change action
 * @param onDeleteNLUData Callback function for deleting NLU data
 * @param loading Flag indicating if the table is loading
 * @param onPage Current page number
 * @param rows Number of rows per page
 * @param first Index of the first row being displayed
 * @param totalRecords Total number of records
 * @param sentiment Sentiment data
 * @param onEditInit Callback function for when a row is edited
 * @param canDelete Flag indicating if the user can delete
 * @param showComments if the comments should be shown
 *
 * @constructor
 * @component
 */
const NLUDataTable = function ({
  allNLUData,
  onThreadStateChange,
  onDeleteNLUData,
  loading,
  onPage,
  rows,
  first,
  totalRecords,
  sentiment,
  onEditInit,
  canDelete = true,
  showComments
}: NLUTablePropsModel) {
  const version = useAppSelector(versionsSelector);
  const allIntents = useAppSelector(intentsSelector);
  const allSentiments = useAppSelector(sentimentsSelector);
  const [selectedNode, setSelectedNode] = useState<NLUModel>({} as NLUModel);
  const [showCommentPopup, setShowCommentPopup] = useState(false);

  /* - - - - - - - - - - Dialogs - - - - - - - - - - */

  /**
   * Opens the comments dialog
   * @param node object to open the comments dialog for
   */
  const openComments = (node: any) => {
    setSelectedNode(node);
    setShowCommentPopup(true);
  };

  const confirmDeleteRow = (node: NLUModel) => {
    confirmDialog({
      message: 'This action cannot be undone.',
      header: 'Delete Row?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => onDeleteNLUData?.(node)
    });
  };

  return (
    <div>
      <h6>{TableHeader({ tableType: 'NLU', itemCount: totalRecords })}</h6>
      <DataTable
        className="specto-data-table"
        dataKey="id"
        sortIcon={customSortIconFn}
        value={allNLUData}
        loading={loading}
        paginator
        paginatorTemplate={paginatorTemplate}
        onPage={onPage}
        rows={rows}
        first={first}
        totalRecords={totalRecords}
        lazy
        editMode="row"
        onRowDoubleClick={(e) => onEditInit?.(e.data as NLUModel)}
        metaKeySelection={false}
      >
        <Column field="message" className="specto-table__column--xl" header="Message" />
        <Column
          field="intent"
          className="specto-table__column--md"
          header="Intent"
          body={(node: NLUModel) => (
            <GenericItemTemplate
              id={node.intent}
              itemName="intent"
              allOptions={allIntents}
              showNewIcon
              showRetiredIcon
            />
          )}
        />
        {sentiment && (
          <Column
            field="sentiment"
            header="Sentiment"
            className="specto-table__column--md"
            body={(node: NLUModel) =>
              node.sentiment && IdToNameCell({ id: node.sentiment, allOptions: allSentiments })
            }
          />
        )}
        <Column
          field="util-comments"
          hidden={!showComments || version.isVersionSelected}
          header="Comments"
          className="specto-table-icon"
          body={(node: NLUModel) => (
            <CommentThreadButton
              key={`${node.id}-comments`}
              threadResolved={node.comment_thread_resolved}
              action={() => openComments(node)}
            />
          )}
        />
        <Column
          columnKey="util-action"
          className="specto-table__column--sm-2 pl-0"
          header="Actions"
          alignHeader="center"
          align="center"
          rowEditor
          hidden={version.isVersionSelected}
          body={(node: NLUModel) => (
            <MenuButtonCell
              data-cy={`${node.id}-actions`}
              menu={[
                {
                  label: 'Edit',
                  command: () => onEditInit?.(node)
                },
                {
                  label: 'Delete',
                  visible: canDelete,
                  command: () => confirmDeleteRow(node)
                }
              ]}
            />
          )}
        />
      </DataTable>
      <CommentPopup
        displayPopup={showCommentPopup}
        onPopupDisplayChange={setShowCommentPopup}
        objectType={CommentType.NLU}
        objectId={selectedNode.id}
        objectName={selectedNode.message}
        parentElement={document.activeElement as HTMLElement}
        onThreadStateChange={onThreadStateChange}
      />
    </div>
  );
};

export default NLUDataTable;
