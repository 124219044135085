import classNames from 'classnames';
import classnames from 'classnames';
import { InputNumber, InputNumberChangeEvent } from 'primereact/inputnumber';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import Info from './Info';

/**
 * React hook form field for numbers.
 *
 * @param className classNames that affect the input
 * @param control form control
 * @param fieldName name of the field
 * @param info info text
 * @param label the title of the field
 * @param labelClassName classnames that affect the label
 * @param maxValue maximum value of the field
 * @param minValue minimum value of the field
 * @param noPadding whether the padding should be removed
 * @param required whether the field is required
 * @param onChange onChange function to handle changes for parent component
 * @param showButtons whether to show increment/decrement buttons
 * @param placeholder custom placeholder for input
 * @component
 */
const NumberField = function ({
  className,
  control,
  fieldName,
  info,
  label,
  labelClassName,
  maxValue,
  minValue = 0,
  noPadding,
  required = true,
  onChange,
  showButtons = true,
  placeholder = ''
}: {
  className?: string;
  control: Control;
  fieldName: string;
  info?: string;
  label?: string;
  labelClassName?: string;
  maxValue?: number;
  minValue?: number;
  noPadding?: boolean;
  required?: boolean;
  onChange?(e: InputNumberChangeEvent): void;
  showButtons?: boolean;
  placeholder?: string;
}) {
  return (
    <div className={classnames(className, { field: !noPadding })}>
      <div className="flex">
        {label && (
          <label htmlFor={fieldName + 'input'} className={classnames(labelClassName)}>
            {label} {required && <span className="specto-form-asterisk-color">*</span>}
          </label>
        )}
        {info && <Info text={info} />}
      </div>
      <Controller
        name={fieldName}
        control={control}
        rules={{
          ...(required && { required: 'Required field' })
        }}
        render={({ field, fieldState }) => (
          <div>
            <InputNumber
              placeholder={placeholder}
              id={field.name}
              inputId={field.name + 'input'}
              className={classNames('w-full specto-input-number border-none', {
                'p-invalid': fieldState.error
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
                onChange?.(e);
              }}
              useGrouping={false}
              showButtons={showButtons}
              min={minValue}
              max={maxValue}
              ref={null}
              data-cy={field.name}
            />
          </div>
        )}
      />
    </div>
  );
};

export default NumberField;
