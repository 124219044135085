import React, { useEffect, useMemo, useState } from 'react';
// prime react
import { Button } from 'primereact/button';
// components
import SearchBar from 'components/SearchBar';
import ResponseDataTable from './components/ResponseDataTable';
import ResponsePopup from './components/ResponsePopup';
// constants
import { FetchStatusOptions } from 'constants/fetchStatus';
// models
import {
  ResponseTableAPIModel,
  ResponseTableDataModel,
  ResponseTypeOptions
} from 'models/response-table-data-model';
// hooks
import { useAppDispatch, useAppSelector } from 'hooks/store';
import useFetch from 'hooks/useFetch';
// services
import ResponsesService from 'services/responsesService';
// selectors
import { setLayout } from 'features/layout/layoutSlice';
import {
  addResponse as addResponseState,
  deleteResponse as deleteResponseState,
  editResponse as editResponseState,
  projectMembersSelector,
  projectsSelector,
  responsesSelector,
  topicsSelector,
  userGroupsSelector,
  usersSelector,
  dirtyFilters,
  filtersDirtySelector,
  contentFiltersSelector,
  updateContentFilters
} from 'features/projects/projectsSlice';
import { SlugDataIdOptionsModel, SlugDataOptionsModel, SlugIdOptionsModel } from 'models/api-model';
import { versionsSelector } from 'features/versions/versionsSlice';
import { createToast } from 'features/toast/toastSlice';
import { ThreadStateChangeEvent } from 'pages/Comments/CommentPopup';
import ResponseFilterMenu from './components/ResponseFilterMenu';
import { FilterChip } from 'models/filter-chips';
import { FilterChips } from 'components/FilterChips/FilterChips';
import { FormMode } from 'models/form-model';
import { FieldValues, useForm } from 'react-hook-form';
import { copyNameFormatter } from 'util/uniqueIdGenerator';
import { setMessages } from 'features/widget/widgetSlice';
import useQuery from 'hooks/useQuery';
import { humanReadableDateTimeFormatted } from 'util/dates';
import { PublishState, Status } from 'models/status-model';
import { statusDisplayNames } from 'constants/status';
import { isAdmin, permissionBoolean } from 'util/permissions';
import CommentsService from 'services/commentsService';
import { getMainLayout } from 'util/layout';
import { ProjectMemberUserModel, ProjectRole } from 'models/project-model';
import ExportResponses from './components/ExportResponses/ExportResponses';
import { DataTableStateEvent } from 'primereact/datatable';
import { getDefaultTableState, mapTableStateToPagination } from 'util/table';
import { mapIdToName } from 'util/mapIdAndName';
import {
  filterAuthors,
  findProjectMember,
  mapSuperUsersToAuthors,
  mapUsersToProjectMembers
} from 'util/users';
import { authSelector } from 'features/auth/authSlice';
import { isArrayAndIncludes, sortbyProperty } from 'util/arrays';
import { parseJson } from 'util/query';
import { useSearchParams } from 'react-router-dom';
import ResponseHistoryPopup from './components/ResponseHistoryPopup';
import { widgetHistorySetMessages } from 'features/widget/widgetHistorySlice';
import {
  getPersistedFilters,
  populateFilterMenu,
  buildMultipleChips,
  getAppliedFilters
} from 'util/filters';

const Response = () => {
  const dispatch = useAppDispatch();
  const { isVersionSelected } = useAppSelector(versionsSelector);
  const { selectedProject, projects } = useAppSelector(projectsSelector);
  const contentFilters = useAppSelector(contentFiltersSelector);
  const allResponses = useAppSelector(responsesSelector);
  const allTopics = useAppSelector(topicsSelector);
  const allUserGroups = useAppSelector(userGroupsSelector);
  const allProjectMembers = useAppSelector(projectMembersSelector);
  const allUsers = useAppSelector(usersSelector);
  const { user } = useAppSelector(authSelector);
  const filtersDirty = useAppSelector(filtersDirtySelector);
  const [displayResponsePopup, setDisplayResponsePopup] = useState(false);
  const [responseCustomActionMode, setResponseCustomActionMode] = useState(false);
  const [contextResponse, setContextResponse] = useState<ResponseTableDataModel | null>(null);
  const [popupMode, setPopupMode] = useState<FormMode>(FormMode.CREATE);
  const [showHistory, setShowHistory] = useState(false);
  const [tablestate, setTableState] = useState<DataTableStateEvent>(getDefaultTableState());
  const validQueryParams = [
    'status',
    'topics',
    'author',
    'user_groups',
    'active',
    'published_lte',
    'content'
  ];
  const [searchParams] = useSearchParams();

  const filterInitialValues = useMemo(() => {
    const params: { [key: string]: any } = {};
    if (searchParams.size === 0) {
      // When the page is first loaded, User Group filters may already be applied based on the Project Member
      // If the user clicked on clear button on any page, then do not set the user groups
      // filtersDirty checks if the user modified the filters or if the filters were pre applied from the store
      if (!filtersDirty) {
        params.user_groups = isAdmin(selectedProject.project_user.role)
          ? []
          : selectedProject.project_user.user_groups;
      }
      getPersistedFilters(params, contentFilters);
    } else {
      searchParams.forEach((value, key) => {
        if ([...validQueryParams, 'search'].includes(key)) {
          params[key] = parseJson(value);
        }
      });
      // Clear query params after consuming
      window.history.replaceState(null, '', window.location.pathname);
    }
    return params;
  }, [searchParams]);

  // Find admins from users and convert them to the same object as author
  const superUserAuthors: ProjectMemberUserModel[] = mapSuperUsersToAuthors(
    allUsers.filter((u) => u.is_superuser),
    allUserGroups.map((ug) => ug.id)
  );

  // Map users data to fit authors data structure
  const authors: ProjectMemberUserModel[] = mapUsersToProjectMembers(allUsers, allProjectMembers);

  // List of all authors including super uers
  const allAuthors: ProjectMemberUserModel[] = sortbyProperty(
    authors.concat(superUserAuthors),
    'name_reversed'
  );

  // Get currently logged in project user as a project user
  const ownProjectUser = findProjectMember(
    authors.concat(superUserAuthors),
    user
  ) as ProjectMemberUserModel;

  /* - - - - - - - - - - Init - - - - - - - - - - */

  // set layout and fetch responses on init
  useEffect(() => {
    dispatch(setLayout(getMainLayout(selectedProject.project_user.role, projects.length)));
    if (searchParams.size === 0) {
      if (!filtersDirty) {
        // Initial value of the filter form for user_groups is different than the default value
        setFilterValue(
          'user_groups',
          isAdmin(selectedProject.project_user.role) ? [] : selectedProject.project_user.user_groups
        );
      }
    } else {
      searchParams.forEach((value, key) => {
        if (validQueryParams.includes(key)) {
          setFilterValue(key, parseJson(value));
        }
      });
    }

    populateFilterMenu(validQueryParams, filterInitialValues, setFilterValue, Status, PublishState);
  }, []);

  /* - - - - - - - - - - Get All Responses - - - - - - - - - - */
  const {
    fetch: getResponses,
    data: getResponsesData,
    fetchStatus: getResponsesStatus
  } = useFetch(ResponsesService.getPaginatedResponses, ResponsesService.roles.retrieve);

  function getFilteredResponses() {
    getResponses({ slug: selectedProject.slug, query: filterQueryString });
  }

  /* - - - - - - - - - - Get Response - - - - - - - - - - */

  const {
    fetch: getResponse,
    data: getResponseData,
    fetchStatus: getResponseStatus
  } = useFetch(ResponsesService.getResponse, ResponsesService.roles.list);

  useEffect(() => {
    if (getResponseStatus === FetchStatusOptions.SUCCESS && getResponseData) {
      dispatch(editResponseState(getResponseData));
    }
  }, [getResponseStatus]);

  /* - - - - - - - - - - Create Response - - - - - - - - - - */
  const {
    fetch: createResponse,
    data: createResponseData,
    fetchStatus: createResponseStatus,
    permission: canCreateResponse,
    error: createResponseError
  } = useFetch<SlugDataOptionsModel<ResponseTableAPIModel>, ResponseTableDataModel>(
    ResponsesService.createResponse,
    ResponsesService.roles.create
  );

  useEffect(() => {
    if (createResponseStatus === FetchStatusOptions.SUCCESS && createResponseData) {
      dispatch(addResponseState(createResponseData));
      getFilteredResponses();

      dispatch(createToast('response created'));
      hidePopup();
    }
  }, [createResponseStatus]);

  /* - - - - - - - - - - Edit Response - - - - - - - - - - */
  const {
    fetch: editResponse,
    data: editResponseData,
    fetchStatus: editResponseStatus,
    permission: canUpdateResponse
  } = useFetch<SlugDataIdOptionsModel<ResponseTableAPIModel>, ResponseTableDataModel>(
    ResponsesService.editResponse,
    ResponsesService.roles.update
  );

  useEffect(() => {
    updateResponse(editResponseData, editResponseStatus);
  }, [editResponseStatus]);

  /* - - - - - - - - - - Retire Response - - - - - - - - - - */

  const {
    fetch: retireResponse,
    data: retiredResponse,
    fetchStatus: retireResponseStatus
  } = useFetch(ResponsesService.retireResponse, ResponsesService.roles.update);

  const updateResponse = (
    data: ResponseTableDataModel | undefined,
    status: FetchStatusOptions,
    toastMessage?: string
  ) => {
    if (status === FetchStatusOptions.SUCCESS && data) {
      dispatch(editResponseState(data));
      getFilteredResponses();

      hidePopup();
      dispatch(createToast(toastMessage ?? 'response edited'));
    }
  };

  useEffect(() => {
    updateResponse(retiredResponse, retireResponseStatus);
  }, [retireResponseStatus]);

  const onRetireChange = (node: ResponseTableDataModel, isRetired: boolean) =>
    retireResponse({
      slug: selectedProject.slug,
      id: node.id,
      retired: {
        retired: isRetired
      }
    });

  /* - - - - - - - - - - Delete Response - - - - - - - - - - */

  const {
    fetch: deleteResponse,
    fetchOptions: deleteResponseOptions,
    fetchStatus: responseDeleteStatus,
    permission: canDeleteResponse
  } = useFetch<SlugIdOptionsModel<ResponseTableDataModel['id']>, ResponseTableDataModel>(
    ResponsesService.deleteResponse,
    ResponsesService.roles.delete
  );

  useEffect(() => {
    if (responseDeleteStatus === FetchStatusOptions.SUCCESS && deleteResponseOptions) {
      dispatch(deleteResponseState(deleteResponseOptions.id));
      getFilteredResponses();

      dispatch(createToast('response deleted'));
    }
  }, [responseDeleteStatus]);

  const deleteTableResponse = (node: ResponseTableDataModel) =>
    deleteResponse({
      slug: selectedProject.slug,
      id: node.id
    });

  /* - - - - - - - - - - Publish Response - - - - - - - - - - */
  const isActionRow = (type: ResponseTypeOptions) => type === ResponseTypeOptions.CUSTOM_ACTION;

  const {
    fetch: publishResponse,
    data: publishResponseData,
    fetchStatus: publishResponseStatus
  } = useFetch(ResponsesService.publishResponse, ResponsesService.roles.update);

  useEffect(() => {
    updateResponse(publishResponseData, publishResponseStatus, 'response published');
  }, [publishResponseStatus]);

  const onPublishResponse = (node: ResponseTableDataModel) => {
    publishResponse({
      slug: selectedProject.slug,
      id: node.id
    });
  };

  const canPublish = (node: ResponseTableDataModel) => {
    const role = selectedProject.project_user.role;
    const isStatusApproved = node.status === Status.Approved;
    const isRetired = node.status === Status.Retired;
    const isCustomAction = isActionRow(node?.content?.[0]?.type);
    const isAdmin = permissionBoolean([ProjectRole.ADMIN, ProjectRole.NEUROSOPH], role);
    const activeResponseFilter = filterQueryParameters?.active === false;

    // cannot publish retired or unapproved Responses
    if (isRetired || !isStatusApproved) return false;

    // hide permissions when in "Proposed" view for non admins
    if (!isAdmin && activeResponseFilter) return false;

    // all can publish Responses with approved status
    if (isStatusApproved && !isCustomAction) return true;

    // only Admins+ can publish custom actions with an approved status
    if (isStatusApproved && isCustomAction) return isAdmin;

    return false;
  };

  /* - - - - - - - - - - Approve Response - - - - - - - - - - */

  const {
    fetch: approveResponse,
    data: approveResponseData,
    fetchStatus: approveResponseStatus
  } = useFetch(ResponsesService.approveResponse, ResponsesService.roles.update);

  useEffect(() => {
    updateResponse(approveResponseData, approveResponseStatus, 'response approved');
  }, [approveResponseStatus]);

  const onApproveResponse = (node: ResponseTableDataModel) =>
    approveResponse({
      slug: selectedProject.slug,
      id: node.id
    });

  /* - - - - - - - - - - Popup - - - - - - - - - - */

  const handlePopupDisplayChange = (
    visibility: boolean,
    popupMode: FormMode = FormMode.CREATE,
    context: ResponseTableDataModel | null = null,
    customActionMode = false
  ) => {
    setContextResponse(context);
    setPopupMode(popupMode);
    setResponseCustomActionMode(customActionMode);
    setDisplayResponsePopup(visibility);
  };

  const onSubmit = (data: FieldValues) => {
    const tableData = data as ResponseTableAPIModel;

    if (contextResponse && popupMode === FormMode.EDIT) {
      editResponse({
        slug: selectedProject.slug,
        id: contextResponse.id,
        data: tableData
      });
    } else {
      createResponse({ slug: selectedProject.slug, data: tableData });
      // set the filter to show proposed after creating a new object
      setFilterValue('state', PublishState.Proposed);
      setFilterQueryParameter({ active: false, status: Status.Not_Retired });
    }
  };

  const hidePopup = () => handlePopupDisplayChange(false);

  /* - - - - - - - - - - Query - - - - - - - - - - */

  const responsesFilterDefaultValues: FieldValues = {
    content: '',
    state: PublishState.Active,
    published_lte: null,
    topics: [],
    user_groups: [],
    author: []
  };

  const {
    control: filterControl,
    reset: resetFilterForm,
    resetField: resetFilterField,
    formState: { dirtyFields: dirtyFilterFields, isDirty: isFilterFormDirty },
    getValues: getFilterValues,
    setValue: setFilterValue
  } = useForm({ defaultValues: responsesFilterDefaultValues, mode: 'onChange' });

  const {
    search,
    searchText,
    clearSearch,
    simpleSearch,
    setQueryParameter: setFilterQueryParameter,
    queryString: filterQueryString,
    queryParameters: filterQueryParameters,
    clearAllQueryParameters,
    clearQueryParameter
  } = useQuery({
    query: onQuery,
    deps: [dirtyFilterFields],
    defaultValues: {
      ...mapTableStateToPagination(tablestate),
      active: true,
      status: Status.Not_Retired
    },
    initialValues: filterInitialValues
  });

  // dispatch dirtyFilters action when filter form is dirty
  // sets filterDirty to true in redux store
  useEffect(() => {
    if (isFilterFormDirty) {
      dispatch(dirtyFilters());
    }
  }, [isFilterFormDirty]);

  // query on filterQueryString change.
  // *** also fires onInit, so it replaces default getMessages req
  // this also updates filters' state in redux store
  useEffect(() => {
    // getResponses is already done when on activateProject. Needs to happen here again to offload filtering (such as retired) to backend
    getFilteredResponses();
    const appliedFilters = getAppliedFilters(validQueryParams, filterQueryParameters);
    dispatch(updateContentFilters(appliedFilters));
  }, [filterQueryString]);

  // perform back end query using the search
  function onQuery(queryText: string) {
    // set query parameters which will build a queryParameter string
    setFilterQueryParameter({ search: queryText });
  }

  const resetFilter = () => {
    clearSearch();
    // User groups should be set to an empty array instead of the default value
    clearAllQueryParameters({ user_groups: [] });
    resetFilterForm();
  };

  useEffect(() => setFilterQueryParameter(mapTableStateToPagination(tablestate)), [tablestate]);

  /* - - - - - - - - - - Filter Chips - - - - - - - - - - */

  const publishedBeforeFilterEnabled = 'published_lte' in filterQueryParameters;
  const activeFilterEnabled =
    filterQueryParameters?.active === true && filterQueryParameters?.status === Status.Not_Retired;
  const proposedFilterEnabled =
    filterQueryParameters?.active === false && filterQueryParameters?.status === Status.Not_Retired;
  const retiredFilterEnabled = filterQueryParameters?.status === Status.Retired;
  const contentString = filterQueryParameters?.content
    ? (filterQueryParameters.content as string)
    : '';

  const filterChips: FilterChip[] = [
    {
      name: `State: ${statusDisplayNames[Status.Retired]}`,
      removeFn: () => {
        clearQueryParameter('status', 'active');
        resetFilterField('state');
      },
      enabled: retiredFilterEnabled
    },
    {
      name: `State: Proposed`,
      removeFn: () => {
        clearQueryParameter('status', 'active');
        setFilterValue('state', responsesFilterDefaultValues['state'], { shouldDirty: true });
      },
      enabled: proposedFilterEnabled
    },
    {
      name: `State: ${statusDisplayNames[Status.Not_Retired]}`,
      removeFn: () => {
        clearQueryParameter('status', 'active');
        setFilterValue('state', responsesFilterDefaultValues['state'], { shouldDirty: true });
      },
      enabled: activeFilterEnabled,
      removable: false
    },
    {
      name: `Content: ${
        contentString.length > 15 ? `${contentString.slice(0, 15)}...` : contentString
      }`,
      removeFn: () => {
        clearQueryParameter('content');
        setFilterValue('content', responsesFilterDefaultValues['content'], {
          shouldDirty: true
        });
      },
      enabled: (filterQueryParameters.content as string)?.length > 0
    },
    {
      name: `Published on or Before: ${humanReadableDateTimeFormatted(
        String(filterQueryParameters.published_lte)
      )}`,
      removeFn: () => {
        clearQueryParameter('published_lte');
        setFilterValue('published_lte', responsesFilterDefaultValues['published_lte'], {
          shouldDirty: true
        });
      },
      enabled: publishedBeforeFilterEnabled
    },
    ...buildMultipleChips(filterQueryParameters.topics, (topicId: number) => ({
      name: `Topic: ${mapIdToName(topicId, allTopics)}`,
      removeFn: () => {
        const topics: number[] = getFilterValues('topics');
        const filteredTopics = topics.filter((id) => id !== topicId);

        if (filteredTopics.length === 0) {
          clearQueryParameter('topics');
          setFilterValue('topics', responsesFilterDefaultValues['topics'], { shouldDirty: true });
        } else {
          setFilterValue('topics', filteredTopics);
          setFilterQueryParameter({ topics: filteredTopics });
        }
      },
      enabled: isArrayAndIncludes(filterQueryParameters.topics, topicId)
    })),
    ...buildMultipleChips(filterQueryParameters.user_groups, (userGroup) => ({
      name: `User Group: ${userGroup === 0 ? 'None' : mapIdToName(userGroup, allUserGroups)}`,
      removeFn: () => {
        const updatedUserGroups = (filterQueryParameters.user_groups as number[]).filter(
          (group) => group !== userGroup
        );
        setFilterQueryParameter({
          user_groups: updatedUserGroups
        });
        setFilterValue('user_groups', updatedUserGroups, {
          shouldDirty: true
        });
      },
      enabled: true,
      removable: true
    })),
    ...buildMultipleChips(filterQueryParameters.author, (userId: number) => ({
      name: `Author: ${mapIdToName(userId, allAuthors, 'user')}`,
      removeFn: () => {
        const authors: number[] = getFilterValues('author');
        const filteredAuthors = authors.filter((id) => id !== userId);

        if (filteredAuthors.length === 0) {
          clearQueryParameter('author');
          setFilterValue('author', responsesFilterDefaultValues['author'], { shouldDirty: true });
        } else {
          setFilterValue('author', filteredAuthors);
          setFilterQueryParameter({ author: filteredAuthors });
        }
      },
      enabled: isArrayAndIncludes(filterQueryParameters.author, userId)
    }))
  ];

  const filterDeps = [filterChips];

  /* - - - - - - - - - - Comments - - - - - - - - - - */

  const onThreadChange = (options: ThreadStateChangeEvent) => getResponse(options);

  /* - - - - - - - - - - Widget - - - - - - - - - - */
  const {
    fetch: previewWidgetResponse,
    data: previewResponseData,
    fetchStatus: previewResponseStatus
  } = useFetch(ResponsesService.previewResponse, ResponsesService.roles.list);

  useEffect(() => {
    if (previewResponseStatus === FetchStatusOptions.SUCCESS && previewResponseData) {
      dispatch(setMessages(previewResponseData));
    }
  }, [previewResponseStatus]);

  const {
    fetch: previewWidgetResponseHistory,
    data: previewResponseHistoryData,
    fetchStatus: previewResponseHistoryStatus
  } = useFetch(ResponsesService.previewResponse, ResponsesService.roles.list);

  useEffect(() => {
    if (previewResponseHistoryStatus === FetchStatusOptions.SUCCESS && previewResponseHistoryData) {
      dispatch(widgetHistorySetMessages(previewResponseHistoryData));
    }
  }, [previewResponseHistoryStatus]);

  /* - - - - - - - - - - History - - - - - - - - - - */
  const onHistoryOpen = (node: ResponseTableDataModel) => {
    setContextResponse(node);
    setShowHistory(true);
    const fullHistory = [node, ...node.history];
    const liveVersion = fullHistory.find((item) => item.status === Status.Published) ?? undefined;
    previewWidgetResponse({
      slug: selectedProject.slug,
      id: node.id
    });
    if (liveVersion) {
      previewWidgetResponseHistory({
        slug: selectedProject.slug,
        id: liveVersion.id
      });
    }
  };

  const onHistoryClose = () => {
    setShowHistory(false);
    setContextResponse(null);
    dispatch(setMessages([]));
    dispatch(widgetHistorySetMessages([]));
  };

  const onHistorySelect = (id: number) => {
    previewWidgetResponse({
      slug: selectedProject.slug,
      id
    });
  };

  /* - - - - - - - - - - Render Response Table - - - - - - - - - - */
  return (
    <div className="specto-response">
      <div className="grid grid-nogutter">
        <div className="col gap-3 flex flex-wrap align-items-start justify-content-between specto-response-top-container">
          <SearchBar
            filterMenu={
              <ResponseFilterMenu
                userGroups={allUserGroups}
                control={filterControl}
                disableStatus={publishedBeforeFilterEnabled}
                filterQueryString={filterQueryString}
                projectUser={ownProjectUser}
                topics={allTopics}
                authors={filterAuthors(user.is_superuser, allAuthors, ownProjectUser?.user)}
                loading={getResponsesStatus === FetchStatusOptions.LOADING}
                onFormValueChange={(fieldValue) => {
                  if ('published_lte' in fieldValue) {
                    resetFilterField('status');
                    fieldValue['status'] = undefined;
                    fieldValue['active'] = true;
                  }
                  setFilterQueryParameter(fieldValue);
                }}
              />
            }
            placeHolder="Search by Name..."
            className="specto-search-bar w-12 xl:w-8 mt-3 xl:mt-0 column"
            text={searchText}
            onFilterClearClick={() => resetFilter()}
            hideFilterClear={!isFilterFormDirty}
            onChange={(e) => simpleSearch(e.target.value)}
            onSubmitText={search}
            onClearText={() => {
              clearSearch();
              search('');
            }}
          />
          {canCreateResponse && !isVersionSelected && (
            <div className="col align-items-center flex justify-content-end p-0 align-self-end">
              {user.is_superuser && <ExportResponses />}
              {user.is_superuser && (
                <Button
                  label="Custom Action"
                  raised
                  icon="pi pi-plus"
                  iconPos="right"
                  className="mr-3 md:flex-none"
                  onClick={() => handlePopupDisplayChange(true, FormMode.CREATE, null, true)}
                />
              )}
              <Button
                label="New Response"
                raised
                icon="pi pi-plus"
                iconPos="right"
                className="md:flex-none"
                onClick={() => handlePopupDisplayChange(true)}
              />
            </div>
          )}
        </div>

        <div className="col-12 mb-4 pt-2">
          <FilterChips filterChips={filterChips} deps={filterDeps} className="lg:w-7" />
        </div>

        <div className="col-12">
          <ResponseDataTable
            responses={getResponsesData?.results ?? []}
            onEditInit={(response, isActionRow) =>
              handlePopupDisplayChange(true, FormMode.EDIT, response, isActionRow)
            }
            onThreadStateChange={onThreadChange}
            onDuplicateResponse={(response, isActionRow) => {
              const copiedName = copyNameFormatter({
                name: response.name,
                nameList: allResponses.map((response) => response.name)
              });

              handlePopupDisplayChange(
                true,
                FormMode.DUPLICATE,
                { ...response, name: copiedName, user_groups: [], reference: undefined },
                isActionRow
              );
            }}
            onHistoryOpen={onHistoryOpen}
            onRetireChange={onRetireChange}
            onDeleteResponse={deleteTableResponse}
            onPublishResponse={onPublishResponse}
            canPublish={canPublish}
            onApproveResponse={onApproveResponse}
            readOnly={!canUpdateResponse || publishedBeforeFilterEnabled}
            canDelete={canDeleteResponse}
            showComments={permissionBoolean(
              CommentsService.roles.general.list,
              selectedProject.project_user.role
            )}
            isAdmin={isAdmin(selectedProject.project_user.role)}
            isSuperUser={user.is_superuser}
            tablestate={tablestate}
            onTableStateChange={setTableState}
            totalRecords={getResponsesData?.count ?? 0}
            loading={getResponsesStatus === FetchStatusOptions.LOADING}
            allTopics={allTopics}
            allUserGroups={allUserGroups}
            authors={allAuthors}
            projectUser={ownProjectUser}
          />
        </div>
      </div>
      {displayResponsePopup && (
        <ResponsePopup
          displayPopup={displayResponsePopup}
          contextResponse={contextResponse}
          loading={
            createResponseStatus === FetchStatusOptions.LOADING ||
            editResponseStatus === FetchStatusOptions.LOADING
          }
          formMode={popupMode}
          customActionMode={responseCustomActionMode}
          onSubmit={onSubmit}
          onHide={hidePopup}
          parentElement={document.activeElement as HTMLElement}
          isSuperUser={user.is_superuser}
          isAdmin={isAdmin(selectedProject.project_user.role)}
          errors={createResponseError as any}
          allUserGroups={allUserGroups}
          projectUser={ownProjectUser}
          authors={allAuthors}
        />
      )}
      {contextResponse ? (
        <ResponseHistoryPopup
          allUserGroups={allUserGroups}
          authors={allAuthors}
          node={contextResponse}
          onHide={onHistoryClose}
          onHistorySelect={onHistorySelect}
          visible={showHistory}
          parentElement={document.activeElement as HTMLElement}
        />
      ) : undefined}
    </div>
  );
};

export default Response;
