import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { ResponseFormContext } from './ResponseFormContext';
import { useFieldArray } from 'react-hook-form';
import { getDefaultResponseContent } from 'constants/responses';
import { TextField, ToggleDividedButton } from 'components/Forms';
import ResponseComponentContainer from './ResponseComponentContainer';
import { ResponseTypeOptions } from 'models/response-table-data-model';
import Info from 'components/Forms/Info';

/**
 * Component that displays dynamic fields to define a "List Group"
 * @component
 */
const ResponseListGroup = function ({ componentIndex }: { componentIndex: number }) {
  const baseField = `content.${componentIndex}`;
  const contentField = `${baseField}.content`;
  const { control, getValues, setValue } = useContext(ResponseFormContext);
  const { append, remove, fields, move } = useFieldArray({
    control,
    name: `${contentField}.elements` as const
  });

  // All component contents should have the same target,
  // use first elements target as component's target
  const [openInNewTab, setOpenInNewTab] = useState(
    getValues(`${contentField}.elements.0.target`) === '_blank'
  );

  // Update Target for all elements
  useEffect(() => {
    fields.forEach((_value, index) => {
      setValue(`${contentField}.elements.${index}.target`, openInNewTab ? '_blank' : '_self');
    });
  }, [openInNewTab]);

  return (
    <ResponseComponentContainer
      componentIndex={componentIndex}
      className="specto-response-list-group-container"
      title="List Group"
      key={componentIndex}
    >
      <div className="specto-response-list-group w-full">
        <div className="grid mb-2">
          <div className="col-12 flex align-items-center">
            <h6 id="open-link-toggle" className="mr-2">
              Open link in
            </h6>
            {/* Not using a ToggleSwitch because it needs to correspond to multiple fields */}
            <ToggleDividedButton
              checked={openInNewTab}
              trueLabel="New Tab"
              falseLabel="Same Tab"
              onChange={(e: any) => setOpenInNewTab(e.value)}
              ariaLabel="open links"
            />
            <Info text="If this option is enabled, links will open in a new tab. Otherwise, links will open in the same tab." />
          </div>
          <div className="col-12">
            <TextField
              label="Title"
              fieldName={`${contentField}.title`}
              control={control}
              noPadding
              info="The title of the list group"
            />
          </div>
          <div className="col-1 sm:col-2" />
        </div>
        {fields.map((item, index) => (
          <ResponseComponentContainer
            componentIndex={index}
            title={'Item ' + (index + 1)}
            key={item.id}
            move={move}
            remove={remove}
            deleteLabel="REMOVE ITEM"
            noDivider
            fieldLength={fields.length}
          >
            <div className="w-full">
              <TextField
                label="Primary Text"
                className="pb-3"
                noPadding
                fieldName={`${contentField}.elements.${index}.primary_text`}
                control={control}
                info="The primary text of the item"
              />
              <TextField
                label="Secondary Text"
                className="mb-3"
                noPadding
                fieldName={`${contentField}.elements.${index}.secondary_text`}
                control={control}
                info="The secondary text of the item"
              />
              <TextField
                label="Image URL"
                className="mb-3"
                noPadding
                fieldName={`${contentField}.elements.${index}.image`}
                control={control}
                info="The URL of the image displayed on the item"
              />
              <TextField
                label="Image Alt Text"
                className="mb-3"
                noPadding
                fieldName={`${contentField}.elements.${index}.alt_text`}
                control={control}
                info="This is descriptive text that conveys the meaning and context of an image. It's used for screen readers or if the image cannot be rendered."
              />
              <TextField
                label="Link URL"
                className="mb-3"
                noPadding
                fieldName={`${contentField}.elements.${index}.payload`}
                control={control}
                info="The URL that will be opened if the item is clicked"
              />
            </div>
          </ResponseComponentContainer>
        ))}
        <div className="flex justify-content-end">
          <Button
            className="font-semibold specto-text-medium p-button-text underline text-sm py-2 w-auto"
            label="ADD ITEM"
            type="button"
            onClick={() =>
              append({
                ...getDefaultResponseContent(ResponseTypeOptions.LIST_GROUP).elements[0],
                target: openInNewTab ? '_blank' : '_self'
              })
            }
            data-cy="add-list-item"
          />
        </div>
      </div>
    </ResponseComponentContainer>
  );
};

export default ResponseListGroup;
