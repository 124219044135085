import React, { CSSProperties } from 'react';
import classnames from 'classnames';
import { OverlayPanel } from 'primereact/overlaypanel';
import LoadingSpinner from 'components/LoadingSpinner';

type props = {
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
  popupRef: React.RefObject<OverlayPanel>;
  loading?: boolean;
};

const PopupSmall = ({ className, children, popupRef, style, loading, ...custom }: props) => {
  return (
    <OverlayPanel
      {...custom}
      ref={popupRef}
      id="overlay_panel"
      style={{ ...style, width: '450px', maxWidth: '100vw' }}
      className={classnames(className, 'specto-popup-small')}
    >
      {loading && (
        <div
          className="p-component-overlay w-full h-full absolute top-0 left-0 flex align-content-center"
          style={{ zIndex: 2000 }}
          onClick={(e) => e.stopPropagation()}
        >
          <LoadingSpinner position="inline" />
        </div>
      )}
      {children}
    </OverlayPanel>
  );
};

export default PopupSmall;
