import React, { useCallback } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import RadioButtonField from 'components/Forms/RadioButtonField';
import { onDateChange } from 'util/calendar';
import { PublishState } from 'models/status-model';
import { FilterStatusExclusiveWarning } from 'constants/warnings';
import DatePickerField from 'components/Forms/DatePickerField';
import { Paper } from '@mui/material';
import LoadingSpinner from 'components/LoadingSpinner';
import classnames from 'classnames';
import { blockKeyboardOnLoad } from 'util/keyboard';
import UserGroupFilterField from 'components/UserGroups/UserGroupsFilterField';
import { UserGroupModel } from 'models/user-groups-model';
import { ProjectMemberUserModel } from 'models/project-model';
import MultiAutocompleteField from 'components/Forms/MultiAutocompleteField';
import { TopicTableDataModel } from 'models/topic-model';
import { GenericItemTemplate } from 'components/Table';
import { sortbyProperty } from 'util/arrays';
import { TextField } from 'components/Forms';
import { debounce } from 'lodash';
import { mapStateToActiveAndStatus } from 'util/filters';

type ResponseFilterProps = {
  control: Control;
  disableStatus?: boolean;
  filterQueryString: string;
  loading?: boolean;
  onFormValueChange(data: FieldValues): void;
  topics: TopicTableDataModel[];
  userGroups: UserGroupModel[];
  projectUser: ProjectMemberUserModel;
  authors: ProjectMemberUserModel[];
};

/**
 * Response FilterMenu
 *
 * @param control form Control
 * @param disableStatus if the state field should be disabled
 * @param filterQueryString filter query string
 * @param loading loading state
 * @param onFormValueChange form value change callback
 * @param projectUser the current project user
 * @param projectUser user group options
 * @param authors List of current Project members
 *
 * @component
 */
function ResponseFilterMenu({
  control,
  disableStatus,
  filterQueryString,
  loading,
  onFormValueChange,
  projectUser,
  topics,
  userGroups,
  authors
}: ResponseFilterProps) {
  // debounce the form value change so that the filter is not called on every key press
  // need to re-create the function whenever the filterQueryString changes to update the debounce
  const debouncedChangeHandler = useCallback(debounce(onFormValueChange, 500), [filterQueryString]);

  return (
    <Paper className="p-3 w-30rem" onKeyDown={(e) => blockKeyboardOnLoad(e, loading)} tabIndex={0}>
      <LoadingSpinner enabled={loading} style={{ top: '38%', left: '42%' }} />
      <form
        className={classnames('flex flex-wrap overflow-y-auto gap-3', {
          'pointer-events-none': loading
        })}
        onSubmit={(e) => {
          e.preventDefault();
        }}
        style={{ maxHeight: '70vh' }}
      >
        <TextField
          label="Content:"
          placeholder="Search by content"
          fieldName="content"
          labelClassName="line-height-3 font-bold"
          className="w-12 specto-border border-round border-1 p-3"
          control={control}
          onChange={(e) => debouncedChangeHandler({ content: e.target.value })}
          required={false}
          noPadding
        />
        <RadioButtonField
          label="State:"
          fieldName="state"
          labelClassName="line-height-3 font-bold"
          disabled={disableStatus}
          tooltip={FilterStatusExclusiveWarning}
          tooltipOptions={{ showOnDisabled: true, disabled: !disableStatus }}
          className="col specto-border border-round border-1 p-3"
          control={control}
          options={[
            { id: 1, value: PublishState.Active, label: 'Active' },
            { id: 2, value: PublishState.Proposed, label: 'Proposed' },
            { id: 3, value: PublishState.Retired, label: 'Retired' }
          ]}
          noPadding
          onChange={(e) => onFormValueChange(mapStateToActiveAndStatus(e.value))}
        />
        <DatePickerField
          label="Published on or Before:"
          placeholder="Select a date"
          fieldName="published_lte"
          labelClassName="line-height-3 font-bold"
          className="w-12 specto-border border-round border-1 p-3"
          control={control}
          onChange={(e) => onDateChange(e, onFormValueChange, 'published_', true)}
        />
        <UserGroupFilterField
          control={control}
          userGroups={userGroups}
          projectUser={projectUser}
          onChange={(values) => onFormValueChange({ user_groups: values })}
        />
        <MultiAutocompleteField
          className="w-12 specto-border border-round border-1 p-3"
          labelClassName="line-height-3 font-bold"
          itemTemplate={(item) =>
            GenericItemTemplate({
              id: item.id,
              itemName: 'topic',
              allOptions: topics,
              showRetiredIcon: true
            })
          }
          fieldName="topics"
          label="Topics"
          control={control}
          options={sortbyProperty([...topics], 'name')}
          optionValue="id"
          optionLabel="name"
          onChange={(e) => onFormValueChange({ topics: e })}
          placeholder="Select Topics or start typing"
          required={false}
          noPadding
        />
        <MultiAutocompleteField
          className="w-12 specto-border border-round border-1 p-3"
          labelClassName="line-height-3 font-bold"
          fieldName="author"
          label="Authors"
          control={control}
          options={authors}
          optionValue="user"
          optionLabel="name_reversed"
          onChange={(e) => onFormValueChange({ author: e })}
          placeholder="Select Authors or start typing"
          required={false}
          noPadding
        />
      </form>
    </Paper>
  );
}

export default ResponseFilterMenu;
