import { useEffect, useState } from 'react';
import { permissionBoolean } from 'util/permissions';
import { projectsSelector } from 'features/projects/projectsSlice';
import { useAppSelector } from './store';
import { PermissionRoles } from 'models/permission-model';
import { authSelector } from 'features/auth/authSlice';

/**
 * simple hook to check function role against user role
 * @param roles function permissions
 * @returns boolean to indicate if you can use the passed in permission
 */
export const usePermission = (roles: PermissionRoles = false) => {
  const { selectedProject } = useAppSelector(projectsSelector);
  const { user } = useAppSelector(authSelector);
  const [permission, setPermission] = useState(
    user.is_superuser || permissionBoolean(roles, selectedProject?.project_user?.role)
  );

  useEffect(() => {
    setPermission(
      user.is_superuser || permissionBoolean(roles, selectedProject?.project_user?.role)
    );
  }, [selectedProject.project_user]);

  return permission;
};
