import React, { useEffect } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { authSelector } from 'features/auth/authSlice';
import { Button } from 'primereact/button';
import ModalFormFullScreen from 'containers/modals/ModalFormFullScreen';
import { FieldValues, useForm } from 'react-hook-form';
import { TextField } from 'components/Forms';
import useFetch from 'hooks/useFetch';
import AuthService from 'services/authService';
import { ForgotPasswordOptionsModel } from 'models/auth-model';
import { FetchStatusOptions } from 'constants/fetchStatus';
import { createToast } from 'features/toast/toastSlice';

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoggedIn } = useAppSelector(authSelector);
  const { control, handleSubmit, reset } = useForm();

  const defaultValues: FieldValues = {
    email: ''
  };

  const {
    data: resetPasswordData,
    fetch: resetPassword,
    fetchStatus: resetPasswordStatus
  } = useFetch(AuthService.forgotPassword, AuthService.roles.update);

  useEffect(() => {
    reset(defaultValues);
  }, []);

  useEffect(() => {
    if (resetPasswordStatus === FetchStatusOptions.SUCCESS && resetPasswordData) {
      dispatch(createToast(resetPasswordData.detail));
      navigate('/login');
    }
  }, [resetPasswordStatus]);

  const onSubmit = (data: FieldValues) => {
    const postData = data as ForgotPasswordOptionsModel;
    resetPassword(postData);
  };

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit(onSubmit)();
  };

  return isLoggedIn ? (
    <Navigate to="/" replace />
  ) : (
    <ModalFormFullScreen onSubmit={onFormSubmit} className="specto-reset-password">
      <h2 className="pt-4">Password Reset</h2>
      <span key="password-span" className="specto-reset-password__subtitle pb-7">
        <div>An email to reset your password will be sent to your email.</div>
      </span>

      <TextField
        key="email-field"
        control={control}
        className="w-full"
        fieldName="email"
        label="Email"
        placeholder="email@company.com"
      />

      <Button
        key="submit-button"
        label="Send Reset Email"
        raised
        className="w-full p-3 mt-6 mb-5 justify-content-center text-xl font-bold"
        type="submit"
        iconPos="left"
        loading={resetPasswordStatus === FetchStatusOptions.LOADING}
      />
      <div key="title-text" className="flex justify-content-end align-items-start mb-2 w-full">
        <Link to="/login" className="specto-link">
          Return to Login
        </Link>
      </div>
    </ModalFormFullScreen>
  );
};

export default ResetPassword;
