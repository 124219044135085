import { ProjectDetailModel } from './project-model';

export enum CommentType {
  INTENT = 'intent',
  RESPONSE = 'response',
  NLU = 'nlu',
  ENTITY = 'entity',
  SYNONYM = 'synonym',
  REGEX = 'regex',
  ENTITY_GROUP = 'entitygroup',
  LOOKUP_TABLE = 'lookuptable',
  TOPIC = 'topic',
  MESSAGE = 'message'
}

export type CommentModel = {
  id: number;
  text: string;
  edited: boolean;
  user: number;
  created: string;
  mentions: number[];
  thread_resolved: boolean;
};

export interface CommentOptionsModel {
  slug: ProjectDetailModel['slug'];
  object_type: CommentType;
  object_id: number;
}

export type CommentApiRequestModel = {
  text: CommentModel['text'];
  mentions: CommentModel['mentions'];
};

export interface PostCommentOptionsModel extends CommentOptionsModel {
  text: string;
  mentions: number[];
}

export interface EditCommentOptionsModel extends PostCommentOptionsModel {
  id: number;
}

export type ResolveCommentApiModel = {
  comment_thread_resolved: CommentModel['thread_resolved'];
};
