import ApiService from 'services/apiService';
import {
  PROJECT_MEMBERS_API_URL,
  PROJECT_RESPONSE_DATABASE_URL,
  PROJECT_EVENTS_DATABASE_API_URL,
  PROJECT_EVENTS_DATABASE_SYNC_API_URL,
  PROJECTS_API_URL
} from 'constants/api';
import {
  EditEventsDatabaseSyncOptionsModel,
  EditEventsDatabaseOptionsModel,
  EditProjectMemberOptionsModel,
  EditProjectOptionsModel,
  EventsDatabaseSyncModel,
  EventsDatabaseSyncRequestAPIModel,
  EventsDatabaseModel,
  EventsDatabaseRequestAPIModel,
  ProjectMemberOptionsModel,
  ProjectDetailModel,
  ProjectMemberModel,
  ProjectMemberPatchAPIModel,
  ProjectMemberPostAPIModel,
  ProjectRole,
  ProjectsPatchRequestAPIModel,
  ProjectsPostRequestAPIModel,
  ProjectSummaryModel,
  ResponseDatabaseModel
} from 'models/project-model';
import { SlugDataOptionsModel, SlugIdOptionsModel, SlugOptionsModel } from 'models/api-model';
import {
  getCategories,
  getEntities,
  getEntityGroups,
  getIntents,
  getLookupTables,
  getProjectMembersList,
  getRegex,
  getResponses,
  getSentiments,
  getSupportedLanguages,
  getSynonyms,
  getTags,
  getTopics,
  getUserGroups,
  getUsers,
  selectProject
} from 'features/projects/projectsSlice';
import { AppDispatch } from 'store';
import { Permissions } from 'models/permission-model';
import { isSuperUser, permissionBoolean } from 'util/permissions';
import MetadataService from 'services/metadataService';

/**
 * Get a list of projects
 */
const getProjects = () => ApiService.get<ProjectSummaryModel[]>(PROJECTS_API_URL);

/**
 * Get project details
 * @param options slug for project url
 */
const getProjectDetails = (options: SlugOptionsModel) =>
  ApiService.get<ProjectDetailModel>(PROJECTS_API_URL + options.slug + '/');

/**
 Create a new project
 */
const createProject = (options: ProjectsPostRequestAPIModel) =>
  ApiService.post<ProjectSummaryModel, ProjectSummaryModel>(PROJECTS_API_URL, options.project);

const editProject = (options: EditProjectOptionsModel) =>
  ApiService.patch<ProjectSummaryModel, ProjectsPatchRequestAPIModel>(
    PROJECTS_API_URL + options.slug + '/',
    options.project
  );

const deleteProject = (options: SlugOptionsModel) =>
  ApiService.remove(PROJECTS_API_URL + options.slug + '/');

const duplicateProject = (options: SlugOptionsModel) =>
  ApiService.post<ProjectSummaryModel, ProjectSummaryModel>(
    PROJECTS_API_URL + options.slug + '/duplicate/'
  );

const getProjectMembers = (options: SlugOptionsModel) =>
  ApiService.get<ProjectMemberModel[]>('/' + options.slug + PROJECT_MEMBERS_API_URL);

const addProjectMember = ({ slug, user, user_groups, role }: ProjectMemberOptionsModel) =>
  ApiService.post<ProjectMemberModel, ProjectMemberPostAPIModel>(
    '/' + slug + PROJECT_MEMBERS_API_URL,
    { user, user_groups, role }
  );

const removeProjectMember = (options: SlugIdOptionsModel) =>
  ApiService.remove<ProjectMemberModel>(
    '/' + options.slug + PROJECT_MEMBERS_API_URL + options.id + '/'
  );

const editProjectMember = (options: EditProjectMemberOptionsModel) =>
  ApiService.patch<ProjectMemberModel, ProjectMemberPatchAPIModel>(
    '/' + options.slug + PROJECT_MEMBERS_API_URL + options.id + '/',
    { role: options.role, user: options.user, user_groups: options.user_groups }
  );

const getEventsDatabaseData = (options: SlugOptionsModel) =>
  ApiService.get<EventsDatabaseModel>(
    PROJECTS_API_URL + PROJECT_EVENTS_DATABASE_API_URL + options.slug + '/'
  );

const getEventsDatabaseSyncData = (options: SlugOptionsModel) =>
  ApiService.get<EventsDatabaseSyncModel>(
    PROJECTS_API_URL + PROJECT_EVENTS_DATABASE_SYNC_API_URL + options.slug + '/'
  );

const editEventsDatabaseData = (options: EditEventsDatabaseOptionsModel) =>
  ApiService.patch<EventsDatabaseModel, EventsDatabaseRequestAPIModel>(
    PROJECTS_API_URL + PROJECT_EVENTS_DATABASE_API_URL + options.slug + '/',
    options.eventsDatabase,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );

const editEventsDatabaseSyncData = (options: EditEventsDatabaseSyncOptionsModel) =>
  ApiService.patch<EventsDatabaseSyncModel, EventsDatabaseSyncRequestAPIModel>(
    PROJECTS_API_URL + PROJECT_EVENTS_DATABASE_SYNC_API_URL + options.slug + '/',
    { sync_events: options.sync_events }
  );

const getResponseDatabase = (options: SlugOptionsModel) =>
  ApiService.get<ResponseDatabaseModel>(
    PROJECTS_API_URL + options.slug + PROJECT_RESPONSE_DATABASE_URL
  );

const editResponseDatabase = (options: SlugDataOptionsModel<Partial<ResponseDatabaseModel>>) =>
  ApiService.post<ResponseDatabaseModel, Partial<ResponseDatabaseModel>>(
    PROJECTS_API_URL + options.slug + PROJECT_RESPONSE_DATABASE_URL,
    options.data,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );

/**
 * Activates a project.
 * dispatches get actions to update intents, responses etc.
 * @param project new project (or version) to select.
 * @param dispatch dispatch fn since pure functions don't have access to hooks
 */
const activateProject = ({
  project,
  dispatch
}: {
  project: ProjectDetailModel;
  dispatch: AppDispatch;
}) => {
  dispatch(selectProject(project));

  // get information related to the selected project and save to state
  const slug = project.slug;
  dispatch(getUserGroups());
  dispatch(getUsers());
  dispatch(getProjectMembersList({ slug }));
  dispatch(getTopics({ slug, query: '?fields=id,name,status' }));
  dispatch(getIntents({ slug, query: '?fields=id,name,status,default' }));
  dispatch(getSentiments({ slug }));
  dispatch(getCategories({ slug }));
  dispatch(getTags({ slug }));
  dispatch(getResponses({ slug, query: '?fields=id,name,status' }));
  dispatch(getEntities({ slug, query: '?fields=id,name,status,default' }));
  isSuperUser(project.project_user.role) && dispatch(getSynonyms({ slug }));
  isSuperUser(project.project_user.role) && dispatch(getRegex({ slug }));
  isSuperUser(project.project_user.role) && dispatch(getEntityGroups({ slug }));
  isSuperUser(project.project_user.role) && dispatch(getLookupTables({ slug }));
  permissionBoolean(MetadataService.roles.list, project.project_user.role) &&
    // dispatch(getMetadataFilters({ slug }));
    dispatch(getSupportedLanguages());
};

const projectServiceRoles: Record<'project' | 'members' | 'database' | 'versions', Permissions> = {
  project: {
    list: true,
    retrieve: true,
    create: [ProjectRole.NEUROSOPH],
    update: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN],
    delete: [ProjectRole.NEUROSOPH]
  },
  members: {
    list: true,
    retrieve: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN],
    create: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN],
    update: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN],
    delete: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN]
  },
  database: {
    retrieve: true,
    create: [ProjectRole.NEUROSOPH],
    update: [ProjectRole.NEUROSOPH]
  },
  versions: {
    list: true,
    create: [ProjectRole.NEUROSOPH],
    update: [ProjectRole.NEUROSOPH],
    delete: [ProjectRole.NEUROSOPH]
  }
};

const ProjectsService = {
  activateProject,
  addProjectMember,
  createProject,
  deleteProject,
  duplicateProject,
  editEventsDatabaseData,
  editEventsDatabaseSyncData,
  editProject,
  editProjectMember,
  editResponseDatabase,
  getEventsDatabaseData,
  getEventsDatabaseSyncData,
  getProjectDetails,
  getProjectMembers,
  getProjects,
  getResponseDatabase,
  removeProjectMember,
  roles: projectServiceRoles
};

export default ProjectsService;
