import React, { useEffect, useState } from 'react';
// prime react
import { Button } from 'primereact/button';
// components
import SearchBar from 'components/SearchBar';
import RegexDataTable from './components/RegexDataTable';
import RegexPopup from './components/RegexPopup';
// constants
import { FetchStatusOptions } from 'constants/fetchStatus';
// models
import {
  EditRegexOptionsModel,
  PostRegexOptionsModel,
  RegexTableAPIModel,
  RegexTableDataModel
} from 'models/regex-model';
// hooks
import { useAppDispatch, useAppSelector } from 'hooks/store';
import useFetch from 'hooks/useFetch';
// services
import RegexService from 'services/regexService';
// selectors
import { setLayout } from 'features/layout/layoutSlice';
import {
  addRegex as addRegexState,
  deleteRegex as deleteRegexState,
  editRegex as editRegexState,
  entitiesSelector,
  getRegex as getRegexState,
  projectsSelector,
  regexSelector
} from 'features/projects/projectsSlice';
import { SlugIdOptionsModel } from 'models/api-model';
import { versionsSelector } from 'features/versions/versionsSlice';
import { createToast } from 'features/toast/toastSlice';
import { filterDefault } from 'util/default';
import { ThreadStateChangeEvent } from 'pages/Comments/CommentPopup';
import useDataFilter from 'hooks/useDataFilter';
import { FormMode } from 'models/form-model';
import { FieldValues } from 'react-hook-form';
import { filterRetired } from 'util/status';
import { permissionBoolean } from 'util/permissions';
import CommentsService from 'services/commentsService';
import { getMainLayout } from 'util/layout';

const Regex = () => {
  const { selectedProject, projects } = useAppSelector(projectsSelector);
  const { isVersionSelected } = useAppSelector(versionsSelector);
  const allRegex = useAppSelector(regexSelector);
  const allEntities = useAppSelector(entitiesSelector);
  const entities = filterDefault(allEntities);
  const filteredEntities = filterRetired(entities);
  const [contextRegex, setContextRegex] = useState<RegexTableDataModel | null>(null);
  const [popupMode, setPopupMode] = useState<FormMode>(FormMode.CREATE);
  const [displayRegexPopup, setDisplayRegexPopup] = useState(false);
  const dispatch = useAppDispatch();
  const {
    searchText,
    search,
    clearSearch,
    simpleSearch,
    results: filteredRegex,
    loading
  } = useDataFilter({
    searchTextFields: 'pattern',
    items: allRegex
  });

  /* - - - - - - - - - - Init - - - - - - - - - - */

  useEffect(() => {
    dispatch(setLayout(getMainLayout(selectedProject.project_user.role, projects.length)));
    dispatch(getRegexState({ slug: selectedProject.slug }));
  }, []);

  /* - - - - - - - - - - Get Regex - - - - - - - - - - */

  const {
    fetch: getRegex,
    data: getRegexData,
    fetchStatus: getRegexStatus
  } = useFetch(RegexService.getRegex, RegexService.roles.list);

  useEffect(() => {
    if (getRegexStatus === FetchStatusOptions.SUCCESS && getRegexData) {
      dispatch(editRegexState(getRegexData));
    }
  }, [getRegexStatus]);

  /* - - - - - - - - - - Create Regex - - - - - - - - - - */

  const {
    fetch: postRegex,
    data: newRegex,
    fetchStatus: regexPostStatus,
    permission: canCreateRegex
  } = useFetch<PostRegexOptionsModel, RegexTableDataModel>(
    RegexService.createRegex,
    RegexService.roles.create
  );

  useEffect(() => {
    if (regexPostStatus === FetchStatusOptions.SUCCESS && newRegex) {
      dispatch(addRegexState(newRegex));
      dispatch(createToast('regex created'));
      hidePopup();
    }
  }, [regexPostStatus]);

  /* - - - - - - - - - - Edit Regex - - - - - - - - - - */
  const {
    fetch: editRegex,
    data: updatedRegex,
    fetchStatus: regexEditStatus,
    permission: canEditRegex
  } = useFetch<EditRegexOptionsModel, RegexTableDataModel>(
    RegexService.editRegex,
    RegexService.roles.update
  );

  const updateRegex = (data: RegexTableDataModel | undefined, fetchStatus: FetchStatusOptions) => {
    if (fetchStatus === FetchStatusOptions.SUCCESS && data) {
      dispatch(editRegexState(data));
      dispatch(createToast('regex edited'));
      hidePopup();
    }
  };

  useEffect(() => {
    updateRegex(updatedRegex, regexEditStatus);
  }, [regexEditStatus]);

  /* - - - - - - - - - - Delete Regex - - - - - - - - - - */

  const {
    fetch: deleteRegex,
    fetchStatus: regexDeleteStatus,
    fetchOptions: deleteRegexOptions,
    permission: canDelete
  } = useFetch<SlugIdOptionsModel<RegexTableDataModel['id']>, RegexTableDataModel>(
    RegexService.deleteRegex,
    RegexService.roles.delete
  );

  useEffect(() => {
    if (regexDeleteStatus === FetchStatusOptions.SUCCESS && deleteRegexOptions) {
      dispatch(deleteRegexState(deleteRegexOptions.id));
      dispatch(createToast('regex deleted'));
    }
  }, [regexDeleteStatus]);

  /* - - - - - - - - - - Popup - - - - - - - - - - */

  /**
   * Callback to change the new Regex pop-up visibility in state.
   * @param visibility boolean representing the visibility of the new Regex popup
   * @param context the context Regex, used for editing
   */
  const handlePopupDisplayChange = (visibility: boolean, context?: RegexTableDataModel) => {
    if (context) {
      setContextRegex(context);
      setPopupMode(FormMode.EDIT);
    } else {
      setPopupMode(FormMode.CREATE);
      setContextRegex(null);
    }
    setDisplayRegexPopup(visibility);
  };

  const onSubmit = (data: FieldValues) => {
    const tableData = data as RegexTableAPIModel;

    if (contextRegex && popupMode === FormMode.EDIT) {
      editRegex({
        slug: selectedProject.slug,
        regexId: contextRegex.id,
        updatedRegexData: tableData
      });
    } else {
      postRegex({ slug: selectedProject.slug, newRegexData: tableData });
    }
  };

  const hidePopup = () => handlePopupDisplayChange(false);

  /* - - - - - - - - - - Table Actions - - - - - - - - - - */

  const deleteTableRegex = (node: RegexTableDataModel) =>
    deleteRegex({
      slug: selectedProject.slug,
      id: node.id
    });

  const onThreadChange = (options: ThreadStateChangeEvent) => getRegex(options);

  return (
    <div className="specto-regex">
      <RegexPopup
        contextRegex={contextRegex}
        displayPopup={displayRegexPopup}
        allEntities={filteredEntities}
        onHide={hidePopup}
        onSubmit={onSubmit}
        mode={popupMode}
        loading={
          regexPostStatus === FetchStatusOptions.LOADING ||
          regexEditStatus === FetchStatusOptions.LOADING
        }
        parentElement={document.activeElement as HTMLElement}
      />
      <div className="grid grid-nogutter">
        <div className="col flex flex-wrap xl:align-items-center align-items-start justify-content-between flex-column-reverse xl:flex-row">
          <SearchBar
            placeHolder="Search by Pattern"
            text={searchText}
            loading={loading}
            className="specto-search-bar w-12 lg:w-7 xl:w-5 mt-3 xl:mt-0"
            onChange={(e) => simpleSearch(e.target.value)}
            onSubmitText={search}
            onClearText={() => {
              clearSearch();
              search('');
            }}
          />
          {!isVersionSelected && canCreateRegex && (
            <Button
              label="New Regex"
              icon="pi pi-plus"
              iconPos="right"
              onClick={() => handlePopupDisplayChange(true)}
            />
          )}
        </div>
        <div className="col-12 mt-4">
          <div className="card">
            <RegexDataTable
              regex={filteredRegex}
              allEntities={entities}
              canDelete={canDelete}
              onThreadStateChange={onThreadChange}
              onEditInit={(regex) => handlePopupDisplayChange(true, regex)}
              onDeleteRegex={deleteTableRegex}
              readOnly={!canEditRegex}
              showComments={permissionBoolean(
                CommentsService.roles.general.list,
                selectedProject.project_user.role
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Regex;
