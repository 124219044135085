import { MentionsInput, Mention } from 'react-mentions';
import React from 'react';
import classnames from 'classnames';
import { UserModel } from 'models/user-model';

/**
 * Custom textarea that allows @ mentioning
 *
 * @param users users that can be @ mentioned
 * @param className class name
 * @param disabled whether the component can be interacted with
 * @param comment comment value
 * @param setComment function to update the comment
 * @component
 */
export const UserMention = function ({
  users,
  className,
  disabled = false,
  comment,
  setComment
}: {
  users: UserModel[];
  disabled?: boolean;
  comment: string;
  className?: string;
  setComment(comment: string): void;
}) {
  // Map the users to a specialized format for mentions
  const userSuggestions = users.map((user) => {
    return { id: user.id, display: `${user.first_name} ${user.last_name}` };
  });

  return (
    <MentionsInput
      className={classnames('user-mention specto-input-text', className)}
      value={comment}
      onChange={(event) => setComment(event.target.value)}
      disabled={disabled}
      singleLine
      allowSpaceInQuery
      placeholder="Share your thoughts..."
    >
      <Mention trigger="@" data={userSuggestions} className="mention" />
    </MentionsInput>
  );
};
