import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import Avatar from 'components/Avatar';

type RoundAvatarCellOptionsModel = {
  fullName?: string | null;
  id: string | number;
};

const AvatarCell = ({ fullName, id }: RoundAvatarCellOptionsModel) => (
  <div className={'select-none specto-avatar-cell-' + id}>
    <Avatar
      fullName={{
        first: fullName ? fullName.split(' ')[0] : 'U',
        last: fullName ? fullName.split(' ')[1] : ''
      }}
    />
    <Tooltip target={'.specto-avatar-cell-' + id} position="bottom">
      {fullName}
    </Tooltip>
  </div>
);

export default AvatarCell;
