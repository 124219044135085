import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { Calendar } from 'primereact/calendar';
import { onDateChange } from 'util/calendar';
import { Paper } from '@mui/material';

type ConversationFilterProps = {
  control: Control;
  onFormValueChange(data: FieldValues): void;
};

/**
 * Conversation Filter menu
 * @param control form control
 * @param onFormValueChange onChange callback
 * @constructor
 */
function ConversationFilterMenu({ control, onFormValueChange }: ConversationFilterProps) {
  return (
    <Paper className="p-3 w-30rem">
      <form className="flex flex-wrap gap-3" onSubmit={(e) => e.preventDefault()}>
        <div className="w-12 specto-border border-round border-1 p-3">
          <h6>Date</h6>
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <Calendar
                id={field.name}
                value={field.value}
                className="w-full specto-input-number"
                placeholder="Select a date range"
                onChange={(e) => {
                  field.onChange(e.value);
                  onDateChange(e.value, onFormValueChange, 'start__');
                }}
                selectionMode="range"
                maxDate={new Date()}
                showIcon
                readOnlyInput
              />
            )}
          />
        </div>
      </form>
    </Paper>
  );
}

export default ConversationFilterMenu;
