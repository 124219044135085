import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'store';

import { AuthModel, PostAuthOptionsModel } from 'models/auth-model';
import { UserModel } from 'models/user-model';
import AuthService from 'services/authService';
import ApiService from 'services/apiService';

export type authState = {
  user: UserModel;
  access: string;
  refresh: string;
  isLoggedIn: boolean;
  isFetching: boolean;
  isError: boolean;
};

const initialState: authState = {
  user: {} as UserModel,
  access: '',
  refresh: '',
  isLoggedIn: false,
  isFetching: false,
  isError: false
};

export const authSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    loginUser: (state, action: PayloadAction<UserModel>) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    logoutUser: (state) => {
      state.isLoggedIn = false;
      state.isFetching = false;
      state.user = initialState.user;
    },
    updateUser: (state, action: PayloadAction<UserModel>) => {
      if (state.user) {
        Object.assign(state.user, action.payload);
      }
    },
    updateAuth: (state, action: PayloadAction<Partial<AuthModel>>) => {
      state.user = action.payload.user ?? state.user;
      state.access = action.payload.access ?? state.access;
      state.refresh = action.payload.refresh ?? state.refresh;
    },
    clearAuthLoadingState: (state) => {
      state.isFetching = false;
    },
    clearAuth: (state) => {
      state.access = '';
      state.refresh = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(loginThunk.fulfilled, (state, action: PayloadAction<AuthModel>) => {
        state.isLoggedIn = true;
        state.isFetching = false;
        state.user = action.payload.user;
        state.access = action.payload.access;
        state.refresh = action.payload.refresh;
      })
      .addCase(loginThunk.rejected, (state) => {
        Object.assign(state, initialState);
        state.isError = true;
      });
  }
});

export const authSelector = (state: RootState) => state.auth;
export const { loginUser, logoutUser, updateUser, clearAuthLoadingState, updateAuth, clearAuth } =
  authSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loginThunk = createAsyncThunk<AuthModel, PostAuthOptionsModel>(
  'auth/login',
  async (data, thunkAPI) => {
    try {
      return await AuthService.login({ email: data.email, password: data.password });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// custom thunk
export const logout = (): AppThunk => (dispatch) => {
  ApiService.removeHeaderTokenBearer();
  AuthService.logout();
  dispatch(logoutUser());
};

export default authSlice.reducer;
