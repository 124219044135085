import React, { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
// components
import Popup from 'containers/Popup';
import { TextField } from 'components/Forms';
import ChipsField from 'components/Forms/ChipsField';
// utils
// models
import { EntityTableDataModel } from 'models/entity-model';
// constants
import TagsField from 'components/Forms/TagsField';
import { FormMode } from 'models/form-model';
import { ProjectMemberUserModel } from 'models/project-model';
import AutocompleteField from 'components/Forms/AutocompleteField';

type EntityPopupPropsModel = {
  contextEntity?: EntityTableDataModel | null;
  displayPopup: boolean;
  loading?: boolean;
  mode?: FormMode;
  onHide(): void;
  onSubmit(data: FieldValues): void;
  parentElement?: HTMLElement;
  projectUser: ProjectMemberUserModel;
  authors: ProjectMemberUserModel[];
};

/**
 *
 * @param contextEntity context entity
 * @param displayPopup Whether to display the popup or not.
 * @param loading The loading state.
 * @param mode The mode (default is FormMode.CREATE).
 * @param onHide Callback when hiding the popup.
 * @param onSubmit Callback when submitting the form.
 * @param parentElement Element to return focus to after the dialog is hidden.
 * @param projectUser The current project user.
 * @param authors Lsit of current project members.
 * @constructor
 */
const EntityPopup = function ({
  contextEntity,
  displayPopup,
  loading,
  mode = FormMode.CREATE,
  onHide,
  onSubmit,
  parentElement,
  projectUser,
  authors
}: EntityPopupPropsModel) {
  const { control, handleSubmit, reset } = useForm();

  /* - - - - - - - - - - Effects - - - - - - - - - - */

  useEffect(() => {
    /**
     * Sets the default form values.
     *
     * Needs to be done in an effect and not on useForm hook call because the statuses are not
     * immediately available on component mount. There is a delay while they are pulled.
     */
    const defaultValues: FieldValues = contextEntity ?? {
      name: '',
      description: '',
      roles: [],
      tags: [],
      author: projectUser?.user
    };
    reset(defaultValues);
  }, [contextEntity]);

  // reset when closing
  useEffect(() => {
    !displayPopup && reset();
  }, [displayPopup]);

  /* - - - - - - - - - - Callbacks - - - - - - - - - - */
  const onPopupSave = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    handleSubmit(onSubmit)();
  };

  const hidePopup = () => {
    reset();
    onHide();
    parentElement?.focus();
  };

  return (
    <Popup
      title={mode === FormMode.CREATE ? 'New Entity' : 'Edit Entity'}
      subtitle={mode === FormMode.CREATE ? 'Create a new Entity' : 'Modify an existing Entity'}
      visible={displayPopup}
      loading={loading}
      onHide={hidePopup}
      onSave={onPopupSave}
    >
      <form className="p-fluid" onSubmit={onPopupSave}>
        <div className="specto-text-medium -mt-2 mb-5">
          Required fields are marked with <span className="specto-form-asterisk-color">*</span>
        </div>

        <TextField fieldName="name" label="Name" control={control} />

        <TextField fieldName="description" label="Description" control={control} />

        <ChipsField
          fieldName="roles"
          label="Roles"
          control={control}
          placeholder="Press Enter or Comma to complete a Role"
          required={false}
          info="Press Enter or Comma to complete a Role"
        />

        <AutocompleteField
          labelClassName="line-height-3"
          required={true}
          control={control}
          options={authors}
          optionLabel="name_reversed"
          optionValue="user"
          fieldName="author"
          label="Author"
          placeholder="Select Author or start typing"
          info="Author of this Entity"
        />

        <TagsField control={control} />
      </form>
    </Popup>
  );
};

export default EntityPopup;
