import ModalLarge from 'containers/modals/ModalLarge';
import React, { useEffect, useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import useFetch from 'hooks/useFetch';
import NotificationsService from 'services/notificationsService';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { projectsSelector } from 'features/projects/projectsSlice';
import {
  NotificationModel,
  NotificationSetting,
  NotificationType,
  NotificationViewModel,
  NotificationViewModelDetails
} from 'models/notification-model';
import { FetchStatusOptions } from 'constants/fetchStatus';
import NotificationView from './NotificationView';
import UserGroupsService from 'services/userGroupsService';
import { UserGroupModel } from 'models/user-groups-model';
import _ from 'lodash';
import { uniqueId } from 'util/uniqueIdGenerator';
import { ReactComponent as Mail } from 'assets/notifications/mail.svg';
import { USER_SETTINGS_LAYOUT } from 'constants/layouts';
import { setLayout } from 'features/layout/layoutSlice';

export const NotificationSettings = function () {
  const [activeIndex, setActiveIndex] = useState(0);
  const { projects } = useAppSelector(projectsSelector);
  const [userGroups, setUserGroups] = useState<UserGroupModel[]>([]);
  // Need to track which project has their user group dropdown expanded here
  // in order to prevent the dropdown from closing when the dropdown is re-renders
  // due to receiving new data from the back-end when user groups are changed
  const [nodeUserGroupExpanded, setNodeUserGroupExpanded] = React.useState('');
  const dispatch = useAppDispatch();

  /**
   * Constructs the default notifications for all
   * the user's projects and notification types
   *
   * @returns NotificationViewModel
   */
  const getDefaultNotifications = () => {
    const defaultNotifications = projects.reduce((accumulator, project) => {
      return {
        ...accumulator,
        [project.slug]: Object.values(NotificationType).reduce(
          (acc, notif) => ({
            ...acc,
            [notif]: {
              setting: NotificationSetting.DISABLED,
              user_groups: []
            }
          }),
          {} as NotificationViewModelDetails
        )
      };
    }, {} as NotificationViewModel);
    return defaultNotifications;
  };

  const [notifications, setNotifications] =
    useState<NotificationViewModel>(getDefaultNotifications());

  /* --------------------------- Initialization --------------------------- */

  useEffect(() => {
    getUserGroups({});
    getNotifications({});
    dispatch(setLayout(USER_SETTINGS_LAYOUT));
  }, []);

  /* ----------------------------- Fetch Notifications ------------------------------ */

  const {
    fetch: getNotifications,
    data: getNotificationsData,
    fetchStatus: getNotificationsStatus
  } = useFetch(NotificationsService.getNotifications, NotificationsService.roles.list);

  useEffect(() => {
    if (getNotificationsStatus === FetchStatusOptions.SUCCESS && getNotificationsData) {
      const notifs = { ...notifications };

      getNotificationsData.forEach((notif) => {
        const { project, type, ...details } = notif;
        notifs[project][type] = details;
      }, notifications);
      setNotifications(notifs);
    }
  }, [getNotificationsStatus]);

  /* ----------------------------- Fetch User Groups ------------------------------ */

  // User groups need to be fetched as they are only fetched upon loading a project
  // They are needed in order to populate the user groups dropdown
  const {
    fetch: getUserGroups,
    data: getUserGroupsData,
    fetchStatus: getUserGroupsStatus
  } = useFetch(UserGroupsService.getUserGroups, UserGroupsService.roles.list);

  useEffect(() => {
    if (getUserGroupsStatus === FetchStatusOptions.SUCCESS && getUserGroupsData) {
      setUserGroups(getUserGroupsData);
    }
  }, [getUserGroupsStatus]);

  /* ----------------------------- Edit Notification ------------------------------ */

  const {
    fetch: postNotification,
    data: postNotificationData,
    fetchStatus: postNotificationStatus
  } = useFetch(NotificationsService.postNotification, NotificationsService.roles.create);

  const onEdit = (notification: NotificationModel) => {
    postNotification(notification);
  };

  useEffect(() => {
    if (postNotificationStatus === FetchStatusOptions.SUCCESS && postNotificationData) {
      const { project, type, ...notif } = postNotificationData;
      const notifs = { ...notifications };
      notifs[project][type] = notif;
      setNotifications(notifs);
    }
  }, [postNotificationStatus]);

  return (
    <ModalLarge className="specto-system-settings">
      {_.isEmpty(notifications) ? (
        <div className="w-full flex flex-column align-items-center">
          <h1>You don't have any projects yet</h1>
          <div className="mb-7 capitalize">
            You can set up your notification once you have a project
          </div>

          <Mail />
        </div>
      ) : (
        <div className="w-full">
          <h1 className="align-self-center">Email Notifications</h1>
          <div className="align-self-center mb-7">
            Customize Your Notifications For Each Project
          </div>
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            scrollable
          >
            {Object.entries(notifications).map(([project, notifications]) => (
              <TabPanel
                key={uniqueId(project)}
                header={projects.find((proj) => proj.slug === project)?.name ?? project}
              >
                <NotificationView
                  notifications={
                    Object.entries(notifications).map(([type, details]) => ({
                      project,
                      type,
                      ...details
                    })) as NotificationModel[]
                  }
                  userGroups={userGroups}
                  onEdit={onEdit}
                  loading={
                    getUserGroupsStatus === FetchStatusOptions.LOADING ||
                    getNotificationsStatus === FetchStatusOptions.LOADING
                  }
                  projectUser={projects.find((proj) => proj.slug === project)?.project_user}
                  projectUserGroupSelected={nodeUserGroupExpanded}
                  setProjectUserGroupSelected={setNodeUserGroupExpanded}
                />
              </TabPanel>
            ))}
          </TabView>
        </div>
      )}
    </ModalLarge>
  );
};

export default NotificationSettings;
