import React, { useState } from 'react';
import { Chip } from 'primereact/chip';
import { Tooltip } from 'primereact/tooltip';
import { useNavigate } from 'react-router';

/**
 *
 * @param chipId Id of the chip
 * @param label Text to display on the chip
 * @param nav_url Url to navigate to when the chip is clicked
 * @component
 */
const TruncatedLinkChip = function ({
  chipId,
  label,
  nav_url
}: {
  chipId: string;
  label: string;
  nav_url: string;
}) {
  const navigate = useNavigate();
  const [truncated, setTruncated] = useState(false);
  const nav = () => navigate(nav_url);

  return (
    <span key={chipId}>
      <Chip
        className="specto-chip mb-1 cursor-pointer truncated-chip"
        id={chipId}
        ref={(el) => {
          const span = el?.getElement().querySelector('.p-chip-text') as HTMLSpanElement;
          if (span && span.offsetWidth < span.scrollWidth) {
            setTruncated(true);
          }
        }}
        label={label}
        onClick={nav}
        tabIndex={0}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            nav();
          }
        }}
      />
      {truncated && (
        <Tooltip event="both" target={`#${chipId}`}>
          {label}
        </Tooltip>
      )}
    </span>
  );
};

export default TruncatedLinkChip;
