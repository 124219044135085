import React from 'react';
import {
  widgetHistoryUserSelector,
  widgetHistoryMessageSelector,
  widgetHistoryFontSizeSelector,
  widgetHistoryStateSelector,
  widgetHistoryLanguageSelector,
  widgetHistoryVersionSelector,
  widgetHistoryContextSelector,
  widgetHistoryReset,
  widgetHistorySetMessages,
  widgetHistoryAddUserMessage,
  widgetHistoryAddBotMessage,
  widgetHistoryResetMessages,
  widgetHistorySetFontSize,
  widgetHistorySetUser,
  widgetHistorySetState,
  widgetHistorySetLanguage,
  widgetHistorySetContext
} from 'features/widget/widgetHistorySlice';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { SpectoWidget, BotMessage, UserMessage, WidgetState } from '@developers/specto-widget';
import '@developers/specto-widget/dist/spectoWidget.css';

/**
 * The second instance of the widget component.
 * @component
 */
const HistoryWidget = function () {
  const dispatch = useAppDispatch();

  const widgetHistoryUser = useAppSelector(widgetHistoryUserSelector);
  const widgetHistoryMessages = useAppSelector(widgetHistoryMessageSelector);
  const widgetHistoryFontSize = useAppSelector(widgetHistoryFontSizeSelector);
  const widgetHistoryState = useAppSelector(widgetHistoryStateSelector);
  const widgetHistoryLanguage = useAppSelector(widgetHistoryLanguageSelector);
  const widgetHistoryVersion = useAppSelector(widgetHistoryVersionSelector);
  const widgetHistoryContext = useAppSelector(widgetHistoryContextSelector);

  const widgetHistoryStore = {
    user: widgetHistoryUser,
    messages: widgetHistoryMessages,
    fontSize: widgetHistoryFontSize,
    widgetState: widgetHistoryState,
    language: widgetHistoryLanguage,
    widgetVersion: widgetHistoryVersion,
    context: widgetHistoryContext,
    reset: (payload: string) => dispatch(widgetHistoryReset(payload)),
    setMessages: (payload: (BotMessage | UserMessage)[]) =>
      dispatch(widgetHistorySetMessages(payload)),
    addUserMessage: (payload: UserMessage) => dispatch(widgetHistoryAddUserMessage(payload)),
    addBotMessage: (payload: BotMessage[]) => dispatch(widgetHistoryAddBotMessage(payload)),
    resetMessages: (payload: boolean) => dispatch(widgetHistoryResetMessages(payload)),
    setFontSize: (payload: number) => dispatch(widgetHistorySetFontSize(payload)),
    setUser: () => dispatch(widgetHistorySetUser()),
    setWidgetState: (payload: WidgetState) => dispatch(widgetHistorySetState(payload)),
    setLanguage: (payload: string) => dispatch(widgetHistorySetLanguage(payload)),
    setContext: (payload: string) => dispatch(widgetHistorySetContext(payload))
  };

  return <SpectoWidget {...widgetHistoryStore} />;
};

export default HistoryWidget;
