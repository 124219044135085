import { Configuration, PublicClientApplication } from '@azure/msal-browser';

// Create a configuration object
const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || '',
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    authority: process.env.REACT_APP_TENANT_ID_URL
  }
};

// Initialize MSAL PublicClientApplication instance
const pca = new PublicClientApplication(msalConfig);

export default pca;
