import ApiService from 'services/apiService';
import { USERS_API_URL } from 'constants/api';
import {
  CreateUserOptionsModel,
  EditUserOptionsModel,
  PutUserOptionsModel,
  UserModel,
  UserRequestAPIModel
} from 'models/user-model';
import { IdOptionsModel } from 'models/api-model';
import { Permissions } from 'models/permission-model';
import { ProjectRole } from 'models/project-model';

/**
 * Get a list of users
 */
const getUsers = () => ApiService.get<UserModel[]>(USERS_API_URL);

const getUser = (options: IdOptionsModel) =>
  ApiService.get<UserModel>(USERS_API_URL + options.id + '/');

const editUser = (options: EditUserOptionsModel) =>
  ApiService.patch<UserModel, UserRequestAPIModel>(USERS_API_URL + options.id + '/', options.user);

const createUser = (options: CreateUserOptionsModel) =>
  ApiService.post<UserModel, UserRequestAPIModel>(USERS_API_URL, options.user);

const putUser = (options: PutUserOptionsModel) =>
  ApiService.put<UserModel, UserRequestAPIModel>(USERS_API_URL + options.id + '/', options.user);

const deleteUser = (options: IdOptionsModel) => ApiService.remove(USERS_API_URL + options.id + '/');

const userServiceRoles: Permissions = {
  list: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN],
  retrieve: [ProjectRole.NEUROSOPH, ProjectRole.ADMIN],
  create: [ProjectRole.NEUROSOPH],
  update: [ProjectRole.NEUROSOPH],
  delete: [ProjectRole.NEUROSOPH]
};

const UsersService = {
  getUsers,
  getUser,
  editUser,
  createUser,
  putUser,
  deleteUser,
  roles: userServiceRoles
};

export default UsersService;
