import React from 'react';
import { Controller } from 'react-hook-form';
import { getFormErrorMessage } from 'util/form';
import classnames from 'classnames';
import { RadioButton, RadioButtonChangeEvent } from 'primereact/radiobutton';
import { TooltipOptions } from 'primereact/tooltip/tooltipoptions';

type RadioButtonFieldOption = { id: number; value: string | boolean; label: string };

/**
 * Radio button field.
 * @param className Classnames that affect the input
 * @param control Form control
 * @param defaultValue Default value for the field
 * @param disabled Whether the field is disabled
 * @param fieldName Name of the field
 * @param inlineLabel Whether the label should be displayed inline
 * @param label The title of the field
 * @param labelClassName Classnames that affect the label
 * @param noPadding Whether the padding should be removed
 * @param onChange Function to handle onChange event
 * @param options Array of options for radio buttons
 * @param required Whether the field is required
 */
const RadioButtonField = function ({
  className,
  control,
  defaultValue,
  disabled = false,
  fieldName,
  inlineLabel = false,
  label,
  labelClassName,
  noPadding,
  onChange,
  options,
  tooltip,
  tooltipOptions
}: {
  className?: string;
  control: any;
  defaultValue?: boolean;
  disabled?: boolean;
  falseValue?: any;
  fieldName: string;
  inlineLabel?: boolean;
  label: string;
  labelClassName?: string;
  noPadding?: boolean;
  onChange?(e: RadioButtonChangeEvent): void;
  options: RadioButtonFieldOption[];
  tooltip?: string;
  tooltipOptions?: TooltipOptions;
  trueValue?: any;
}) {
  return (
    <div aria-disabled={disabled} className={classnames(className, { field: !noPadding })}>
      {disabled && (
        <div
          tabIndex={0}
          aria-label={`${label} disabled. ` + (tooltipOptions?.showOnDisabled ? tooltip : '')}
        ></div>
      )}
      <legend className={classnames(labelClassName)}>{label}</legend>
      <div className={classnames({ 'flex align-content-center align-items-center': inlineLabel })}>
        <Controller
          name={fieldName}
          control={control}
          defaultValue={defaultValue}
          render={({ field, fieldState }) => (
            <>
              {options.map((option) => (
                <div
                  key={option.id}
                  aria-disabled={disabled}
                  className="mb-2 justify-content-center"
                >
                  <RadioButton
                    inputId={String(option.id)}
                    aria-label={`option ${option.label ?? option.id}`}
                    {...field}
                    inputRef={field.ref}
                    value={option.value}
                    disabled={disabled}
                    tooltip={tooltip}
                    tooltipOptions={tooltipOptions}
                    checked={field.value === option.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                      onChange?.(e);
                    }}
                  />
                  <label htmlFor={String(option.id)} className="ml-2 mr-3">
                    {option.label}
                  </label>
                </div>
              ))}
              {getFormErrorMessage(fieldState.error, noPadding)}
            </>
          )}
        />
        {label && inlineLabel && (
          <label htmlFor={fieldName + 'input'} className="pl-2">
            <h6 className="mb-0">{label}</h6>
          </label>
        )}
      </div>
    </div>
  );
};

export default RadioButtonField;
