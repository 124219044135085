import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Navigate, Outlet } from 'react-router-dom';
import { createToast } from 'features/toast/toastSlice';
import { versionsSelector } from 'features/versions/versionsSlice';
import { guardWarning } from 'constants/warnings';
import { routes } from 'constants/routes';

type GuardedRouteProps = {
  redirectPath?: string;
  children?: React.ReactNode;
};

/**
 * Can be guard multiple routes as an outlet
 * <Route element={<AuthGuardedRoute/>}> {... your routes } </Route>
 *
 * Can be used for single routes
 * <Route element={<AuthGuardedRoute><YourComponent></AuthGuardedRoute>}/>
 *
 * @param guards array of guards that are conditions to access this route
 * @param redirectPath path to redirect to
 * @param children child component when guarding a single route
 */
export const VersionGuardedRoute = ({
  redirectPath = routes.PROJECT_HOME,
  children
}: GuardedRouteProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isVersionSelected } = useAppSelector(versionsSelector);

  // create toast message on failed guard
  useEffect(() => {
    if (isVersionSelected) {
      dispatch(createToast(guardWarning.version, 'warn'));
    }
  }, [isVersionSelected]);

  return isVersionSelected ? <Navigate to={redirectPath} replace /> : <>{children || <Outlet />}</>;
};
