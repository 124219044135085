import ApiService from 'services/apiService';
import {
  BatchMessagesModel,
  EditMessageInboxEntitiesOptionsModel,
  EditMessageInboxOptionsModel,
  EditMessageInboxTagsOptionsModel,
  GetMessageInboxOptionsModel,
  GetMessageOptionsModel,
  MessageInboxEntityLabelPutRequestAPIModel,
  MessageInboxTableDataModel,
  MessageInboxTagsPutRequestAPIModel,
  ModifyMessageInboxOptionsModel,
  PostBatchMessagesOptionsModel
} from 'models/message-inbox-table-data-model';
import { ENTITIES_API_URL, MESSAGE_INBOX_API_URL, TAGS_API_URL } from 'constants/api';
import { Paginated, SlugIdOptionsModel } from 'models/api-model';
import { ProjectRole } from 'models/project-model';
import { Permissions } from 'models/permission-model';

const getInboxMessages = (options: GetMessageInboxOptionsModel) =>
  ApiService.get<Paginated<MessageInboxTableDataModel>>(
    '/' + options.slug + MESSAGE_INBOX_API_URL + options.query
  );

const getInboxMessage = (options: GetMessageOptionsModel) =>
  ApiService.get<MessageInboxTableDataModel>(
    '/' + options.slug + MESSAGE_INBOX_API_URL + options.id
  );

const editMessageInbox = (options: EditMessageInboxOptionsModel) =>
  ApiService.patch<MessageInboxTableDataModel>(
    '/' + options.slug + MESSAGE_INBOX_API_URL + options.id + '/',
    options.updatedMessageInboxData
  );

const modifyMessageInbox = (options: ModifyMessageInboxOptionsModel) =>
  ApiService.put<MessageInboxTableDataModel>(
    '/' + options.slug + MESSAGE_INBOX_API_URL + options.id + '/',
    options.updatedMessageInboxData
  );

const deleteMessageInboxMessage = (options: SlugIdOptionsModel) =>
  ApiService.remove('/' + options.slug + MESSAGE_INBOX_API_URL + options.id + '/');

const getMessageInboxTags = (options: SlugIdOptionsModel) =>
  ApiService.get<MessageInboxTableDataModel>(
    '/' + options.slug + MESSAGE_INBOX_API_URL + options.id + TAGS_API_URL
  );

const editMessageInboxTags = (options: EditMessageInboxTagsOptionsModel) =>
  ApiService.put<MessageInboxTableDataModel['tags'], MessageInboxTagsPutRequestAPIModel>(
    '/' + options.slug + MESSAGE_INBOX_API_URL + options.id + TAGS_API_URL,
    options.tags
  );

const editMessageInboxEntities = (options: EditMessageInboxEntitiesOptionsModel) =>
  ApiService.put<
    MessageInboxTableDataModel['entity_labels'],
    MessageInboxEntityLabelPutRequestAPIModel
  >(
    '/' + options.slug + MESSAGE_INBOX_API_URL + options.id + ENTITIES_API_URL,
    options.updatedEntities
  );

const batchMessages = (options: PostBatchMessagesOptionsModel) =>
  ApiService.post<null, BatchMessagesModel>(
    '/' + options.slug + MESSAGE_INBOX_API_URL + 'batch/',
    options.params
  );

const messageInboxServiceRoles: Permissions = {
  list: [ProjectRole.NEUROSOPH],
  create: [ProjectRole.NEUROSOPH],
  update: [ProjectRole.NEUROSOPH],
  delete: [ProjectRole.NEUROSOPH]
};

const MessageInboxService = {
  getInboxMessages,
  getInboxMessage,
  deleteMessageInboxMessage,
  editMessageInbox,
  modifyMessageInbox,
  getMessageInboxTags,
  editMessageInboxTags,
  editMessageInboxEntities,
  batchMessages,
  roles: messageInboxServiceRoles
};

export default MessageInboxService;
