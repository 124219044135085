import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { Status } from 'models/status-model';
import { Paper } from '@mui/material';

type NLUFilterProps = {
  control: Control;
  onFormValueChange(data: FieldValues): void;
};

function NLUFilterMenu({ control, onFormValueChange }: NLUFilterProps) {
  const handleStatusFilter = (e: DropdownChangeEvent) => {
    if (e.target.value === 'retired') {
      onFormValueChange({ intent__status: true });
    } else {
      onFormValueChange({ intent__status: e.target.value });
    }
  };

  return (
    <Paper className="p-3 w-30rem">
      <form
        className="flex flex-wrap gap-3"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="w-12 specto-border border-round border-1 p-3">
          <h6>Show Only Intents with Status:</h6>
          <Controller
            name="intent__status"
            control={control}
            render={({ field }) => (
              <Dropdown
                value={field.value}
                options={[
                  { id: 1, value: Status.New, label: 'New' },
                  { id: 2, value: Status.Retired, label: 'Retired' }
                ]}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  handleStatusFilter(e);
                }}
                placeholder="Select a Status"
                className="specto-input-select"
                autoFocus
              />
            )}
          />
        </div>
      </form>
    </Paper>
  );
}

export default NLUFilterMenu;
