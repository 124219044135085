import React from 'react';
import {
  EntityDisplayLabel,
  EntityDisplayOption,
  EntityDisplayOptionColor,
  EntityText,
  EntityTokenLabel
} from './entity-labelling-model';
import { sortEntities } from 'util/entity';

/**
 *
 * @param text string, or array of tokens/strings
 * @param spans Entity Labels
 * @param ents All Entities (Options)
 * @param entityTemplate custom entity template
 * @param onClick on click fn
 * @param onHighlight on highlight fn
 * @constructor
 */
const SpectoTaggy = ({
  text = '',
  spans = [],
  ents = [],
  entityTemplate,
  onClick,
  onHighlight
}: {
  text: EntityText;
  spans: EntityDisplayLabel[];
  ents: EntityDisplayOption[];
  entityTemplate?(element: JSX.Element, token: EntityDisplayLabel, index: number): JSX.Element;
  onClick?(e: React.MouseEvent<HTMLElement>, token: EntityDisplayLabel, index: number): any;
  onHighlight?(
    e: React.MouseEvent<HTMLSpanElement>,
    text: string,
    index: number,
    start: number,
    end?: number
  ): any;
}) => {
  // Find the correct color of the given entity type. If the given entity is not found, set the color to grey.
  const findColor = (type: EntityDisplayOption['type'], color: keyof EntityDisplayOptionColor) => {
    for (let e = 0; e < ents.length; e++) {
      if (ents[e].type === type) {
        return ents[e].color[color];
      }
    }
    // grey
    return 220;
  };

  // Initialize an empty array that will hold the text and entities
  const jsx: any[] = [];

  // Make sure spans are ordered by the start index
  spans = sortEntities(spans);

  // METHOD 1 - STRING
  if (typeof text === 'string') {
    // Initialize an empty array. The contents of 'elements' will eventually get pushed to the 'jsx' array, and will be converted to jsx markup in the process.
    let elements: any[] = [];
    // Keep track of location in the string of text
    let offset = 0;
    // Loop through the spans, using the span data to construct the 'elements' array
    spans.forEach((span) => {
      // Create a string of text that does not contain any entities
      const fragment = text.slice(offset, span.start);
      // Create an entity
      const entity = text.slice(span.start, span.end);
      // Push the both of them to the elements array
      elements.push(fragment);
      span.token = entity;
      elements.push(span);
      // Update our position within the string of text. DNE when using tokens
      offset = span.end ?? 0;
    });
    // After pushing all the entities to the 'elements' array, push the remaining text to the 'elements' array. Elements should now consist of strings and objects/entities.
    elements.push(text.slice(offset, text.length));
    // Filter out unnecessary spaces
    elements = elements.filter((val) => val !== ' ');
    // Loop through elements array looking for multi-word entities.
    for (let e = 0; e < elements.length; e++) {
      // Check if we've stopped at an entity
      if (elements[e].token) {
        // Examine the consecutive entities, if any.
        for (let i = e + 1; i < elements.length; i++) {
          // Combine consecutive entities of the same type into one entity. Then, mark the duplicates as 'false'.
          if (typeof elements[i] !== 'string' && elements[i].type === elements[e].type) {
            elements[e].token += ' ' + elements[i].token;
            elements[i] = false;
          }
          // Stop the loop when we've run out of consecutive entities
          if (typeof elements[i] === 'string') {
            break;
          }
        }
      }
    }
    // Filter out the consecutive entities that were marked as duplicates
    elements = elements.filter((val) => !!val);
    // Loop through our 'elements' array. Push strings directly to the 'jsx' array. Convert entity objects to jsx markup, then push to the 'jsx' array.
    populateEntities(elements, jsx);
  }

  // METHOD 2 - TOKENS
  if (Array.isArray(text)) {
    // Rename 'text' to 'tokens' for clarity
    const tokens = text as any[];
    const entityLabels = spans as EntityTokenLabel[];

    // Loop through the 'spans' array. Use the span data to update our 'tokens' array with entities
    for (let s = 0; s < entityLabels.length; s++) {
      tokens[entityLabels[s].index] = entityLabels[s];
      tokens[entityLabels[s].index].token = tokens[entityLabels[s].index];
      tokens[entityLabels[s].index].type = entityLabels[s].type;
    }

    // Loop through the tokens array, looking for multi-word entities
    for (let t = 0; t < tokens.length; t++) {
      // Check if we've stopped at an entity
      if (tokens[t].token) {
        // Examine the consecutive entities, if any.
        for (let i = t + 1; i < tokens.length; i++) {
          // Combine consecutive entities of the same type into one entity. Then, mark the duplicates as 'false'.
          if (typeof tokens[i] !== 'string' && tokens[i].type === tokens[t].type) {
            tokens[t].token += ' ' + tokens[i].token;
            tokens[i] = false;
          }
          // Stop the loop when we've run out of consecutive entities
          if (typeof tokens[i] === 'string') {
            break;
          }
        }
      }
    }
    // Filter out the consecutive entities that were marked as duplicates
    const tokenLabels: (string | EntityTokenLabel)[] = tokens.filter((val) => !!val);

    // Add a space to the end of each string/non-entity
    const tokensWithSpaces = tokenLabels.map((t) => {
      if (typeof t === 'string') {
        return `${t} `;
      }
      return t;
    });

    // Loop through our 'tokens' array. Push strings directly to the 'jsx' array. Convert entity objects to jsx markup, then push to the 'jsx' array.
    populateEntities(tokensWithSpaces, jsx);
  }

  const highlightCallback = (
    e: React.MouseEvent<HTMLSpanElement>,
    spanText: string,
    index: number
  ) => {
    // Start and end are relative to the current element, not the whole text
    let start = window.getSelection()?.anchorOffset;
    const end = window.getSelection()?.focusOffset;
    let text = '';

    if (start !== undefined) {
      text = spanText.substring(start, end);
    } else {
      start = -1;
    }

    onHighlight?.(e, text, index, start, end);
  };

  function populateEntities(elements: (string | EntityDisplayLabel)[], entities: JSX.Element[]) {
    let tokenIndexCount = 0;

    elements.forEach((mixedToken, index) => {
      if (typeof mixedToken === 'string') {
        entities.push(
          <span
            onMouseUp={(e) => {
              highlightCallback(e, mixedToken, index);
            }}
            onDoubleClick={(e) => {
              highlightCallback(e, mixedToken, index);
            }}
          >
            {mixedToken}
          </span>
        );
      } else {
        const entityElement = styledEntity({ onClick, token: mixedToken, index: tokenIndexCount });

        entities.push(
          entityTemplate?.(entityElement, mixedToken, tokenIndexCount) ?? entityElement
        );
        tokenIndexCount++;
      }
    });
  }

  function styledEntity({
    onClick,
    token,
    index
  }: {
    token: EntityDisplayLabel;
    index: number;
    onClick?(e: React.MouseEvent<HTMLElement>, token: EntityDisplayLabel, index: number): void;
  }) {
    return (
      <mark
        onClick={(e) => onClick?.(e, token, index)}
        style={{
          padding: '0.25em 0.35em',
          margin: '0px 0.25em',
          lineHeight: '1',
          display: 'inline-block',
          borderRadius: '0.25em',
          border: '1px solid',
          background: `rgba(
                                ${findColor(token.type, 'r')},
                                ${findColor(token.type, 'g')},
                                ${findColor(token.type, 'b')},
                                0.2
                            )`,
          borderColor: `rgb(
                                ${findColor(token.type, 'r')},
                                ${findColor(token.type, 'g')},
                                ${findColor(token.type, 'b')}
                            )`
        }}
      >
        {token.token}
        <span
          style={{
            boxSizing: 'border-box',
            fontSize: '0.6em',
            lineHeight: '1',
            padding: '0.35em',
            borderRadius: '0.35em',
            display: 'inline-block',
            verticalAlign: 'middle',
            margin: '0px 0px 0.1rem 0.5rem',
            background: `rgb(
                                ${findColor(token.type, 'r')},
                                ${findColor(token.type, 'g')},
                                ${findColor(token.type, 'b')}
                            )`
          }}
        >
          {token.type}
        </span>
      </mark>
    );
  }

  // Return the markup
  return (
    <div style={{ display: 'inline-block' }}>
      {jsx.map((j, i) => (
        <span key={i}>{j}</span>
      ))}
    </div>
  );
};

export default SpectoTaggy;
