import LoadingSpinner from 'components/LoadingSpinner';
import { embedDashboard } from 'components/Superset';
import { FetchStatusOptions } from 'constants/fetchStatus';
import { projectsSelector } from 'features/projects/projectsSlice';
import { useAppSelector } from 'hooks/store';
import useFetch from 'hooks/useFetch';
import React, { useEffect } from 'react';
import AnalyticsService from 'services/analyticsService';

const Analytics = function () {
  const { selectedProject } = useAppSelector(projectsSelector);
  const {
    fetch: fetchDashboard,
    data: dashboard,
    fetchStatus: dashboardStatus
  } = useFetch(AnalyticsService.getDashboards, AnalyticsService.roles.list);

  useEffect(() => {
    if (selectedProject?.slug) {
      fetchDashboard({ slug: selectedProject.slug });
    }
  }, [selectedProject]);

  useEffect(() => {
    if (dashboardStatus === FetchStatusOptions.SUCCESS) {
      // Embed the dashboard
      const mountPoint = document.getElementById('superset-app');
      if (mountPoint && dashboard) {
        embedDashboard({
          id: dashboard[0].id,
          supersetDomain: dashboard[0].domain,
          mountPoint: mountPoint, // any html element that can contain an iframe
          fetchGuestToken: () =>
            AnalyticsService.getToken({ slug: selectedProject.slug }).then((token) => token.token),
          dashboardUiConfig: {
            // dashboard UI config: hideTitle, hideTab, hideChartControls, filters.visible, filters.expanded (optional)
            hideTitle: true
          }
        });
      }
    }
  }, [dashboardStatus]);

  return (
    <div className="analytics" id="superset-app">
      {dashboardStatus === FetchStatusOptions.LOADING && <LoadingSpinner />}
      {dashboardStatus === FetchStatusOptions.ERROR && (
        <div>There was an error loading the dashboard</div>
      )}
    </div>
  );
};

export default Analytics;
