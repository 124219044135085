import { CalendarChangeEvent } from 'primereact/calendar';
import { isDate } from 'lodash';
import { formatDateISOEnd, formatDateISOStart } from './dates';

/**
 * onDateChange function which triggers query parameters
 * @param calendarDateValue
 * @param fn function to run
 * @param rangeKeyBase key base like "start__" for "start__gte"
 * @param singleDate if the function is a single date
 * @param extension "lte" or "gte"
 */
export const onDateChange = (
  calendarDateValue: CalendarChangeEvent['value'],
  fn: (i: any) => void,
  rangeKeyBase: string,
  singleDate = false,
  extension = 'lte'
): void => {
  const params: any = {};

  if (Array.isArray(calendarDateValue)) {
    const [start, end] = calendarDateValue;
    if (start) params[rangeKeyBase + 'gte'] = formatDateISOStart(start);
    if (end) params[rangeKeyBase + 'lte'] = formatDateISOEnd(end);
  } else if (singleDate && isDate(calendarDateValue)) {
    if (extension === 'lte') params[rangeKeyBase + extension] = formatDateISOEnd(calendarDateValue);
    else params[rangeKeyBase + extension] = formatDateISOStart(calendarDateValue);
  }

  fn(params);
};
