import React, { useContext } from 'react';
import { TextField } from 'components/Forms';
import { ResponseFormContext } from './ResponseFormContext';
import ResponseComponentContainer from './ResponseComponentContainer';
import TextArea from 'components/Forms/TextArea';

/**
 * Component that displays dynamic fields to define a "table"
 * @component
 */
const ResponseTableCSV = function ({ componentIndex }: { componentIndex: number }) {
  const baseField = `content.${componentIndex}`;
  const contentField = `${baseField}.content`;
  const { control, moveField } = useContext(ResponseFormContext);

  return (
    <ResponseComponentContainer
      componentIndex={componentIndex}
      title="Table"
      key={componentIndex}
      move={moveField}
    >
      <div className="w-full grid pt-3">
        <div className="col-6 pr-3">
          <TextField
            label="Table Name"
            fieldName={`${contentField}.name`}
            control={control}
            info="Title of the table"
          />
        </div>
        <div className="col-6 pl-3">
          <TextField
            label="Table Description"
            fieldName={`${contentField}.description`}
            control={control}
            info="Description of the table"
          />
        </div>

        <div className="col-12">
          <TextArea
            label="Table Data"
            placeholder="CSV data..."
            fieldName={`${contentField}.value`}
            control={control}
            info="This field is a table formatted as a CSV (comma-separated values). The first row will be used as the header row."
          />
        </div>
      </div>
    </ResponseComponentContainer>
  );
};

export default ResponseTableCSV;
