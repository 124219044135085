import { WidgetState, WidgetSize, BotMessage, UserMessage } from '@developers/specto-widget';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

export type WidgetStoreState = {
  widgetVersion: string;
  user: string;
  messages: any[];
  fontSize: number;
  widgetState: WidgetState;
  language: string;
  context: string;
};

export const initialState: WidgetStoreState = {
  widgetVersion: '1.2.1',
  user: 'chatbot_studio_preview',
  messages: [],
  fontSize: 1,
  widgetState: { size: WidgetSize.MAXIMIZED, isClosed: false },
  language: 'en',
  context: ''
};

export const widgetSlice = createSlice({
  name: 'widget_widget',
  initialState,
  reducers: {
    reset: (state, action: PayloadAction<string>) => {
      state = {
        ...initialState,
        widgetVersion: action.payload
      };
    },
    setMessages: (state, action: PayloadAction<(BotMessage | UserMessage)[]>) => {
      state.messages = action.payload;
    },
    addUserMessage: (state, action: PayloadAction<UserMessage>) => {
      state.messages = [...state.messages, action.payload];
    },
    addBotMessage: (state, action: PayloadAction<BotMessage[]>) => {
      state.messages = [...state.messages, ...action.payload];
    },
    resetMessages: (state, action: PayloadAction<boolean>) => {
      state.messages = [];
      !action.payload && (state.user = 'chatbot_studio_preview');
    },
    setFontSize: (state, action: PayloadAction<number>) => {
      state.fontSize = action.payload;
    },
    setUser: (state) => {
      state.user = 'chatbot_studio_preview';
    },
    setWidgetState: (state, action: PayloadAction<WidgetState>) => {
      state.widgetState = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setContext: (state, action: PayloadAction<string>) => {
      state.context = action.payload;
    }
  }
});

/* - - - - - - - - - - Selectors - - - - - - - - - - */

export const userSelector = (state: RootState) => state.widget.user;
export const messageSelector = (state: RootState) => state.widget.messages;
export const fontSizeSelector = (state: RootState) => state.widget.fontSize;
export const widgetStateSelector = (state: RootState) => state.widget.widgetState;
export const languageSelector = (state: RootState) => state.widget.language;
export const widgetVersionSelector = (state: RootState) => state.widget.widgetVersion;
export const contextSelector = (state: RootState) => state.widget.context;

export const {
  reset,
  setMessages,
  addUserMessage,
  addBotMessage,
  resetMessages,
  setFontSize,
  setUser,
  setWidgetState,
  setLanguage,
  setContext
} = widgetSlice.actions;

export default widgetSlice.reducer;
