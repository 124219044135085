import ApiService from 'services/apiService';
import { NLU_API_URL } from 'constants/api';
import { Paginated, SlugIdOptionsModel } from 'models/api-model';
import {
  AddNLUMessagesOptionsModel,
  CreateNLUOptionsModel,
  EditNLUOptionsModel,
  GetNLUOptionsModel,
  GetSingleNLUOptionsModel,
  NLUAPIModel,
  NLUCollisionModel,
  NLUModel,
  NLUPostAPIModel
} from 'models/nlu-table-data-model';
import { IntentTableDataModel } from 'models/intent-table-data-model';
import { ProjectRole } from 'models/project-model';
import { Permissions } from 'models/permission-model';

const getNLUData = (options: GetNLUOptionsModel) =>
  ApiService.get<Paginated<NLUModel>>('/' + options.slug + NLU_API_URL + options.query);

const getSingleNLUData = (options: GetSingleNLUOptionsModel) =>
  ApiService.get<NLUModel>('/' + options.slug + NLU_API_URL + options.id);

const deleteNLUData = (options: SlugIdOptionsModel) =>
  ApiService.remove('/' + options.slug + NLU_API_URL + options.id + '/');

const editNLUData = (options: EditNLUOptionsModel) =>
  ApiService.patch<NLUModel, NLUPostAPIModel>(
    '/' + options.slug + NLU_API_URL + options.updatedNLUData.id + '/',
    options.updatedNLUData
  );

const createNLUData = (options: CreateNLUOptionsModel) =>
  ApiService.post<NLUModel, NLUAPIModel>('/' + options.slug + NLU_API_URL, options.updatedNLUData);

const addNLUMessages = (options: AddNLUMessagesOptionsModel) =>
  ApiService.post<NLUCollisionModel, IntentTableDataModel['id'][]>(
    '/' + options.slug + NLU_API_URL + 'add/',
    options.updatedNLUData
  );

const nluServiceRoles: Permissions = {
  list: [ProjectRole.NEUROSOPH],
  retrieve: [ProjectRole.NEUROSOPH],
  create: [ProjectRole.NEUROSOPH],
  update: [ProjectRole.NEUROSOPH],
  delete: [ProjectRole.NEUROSOPH]
};

const NLUService = {
  addNLUMessages,
  getNLUData,
  getSingleNLUData,
  deleteNLUData,
  editNLUData,
  createNLUData,
  roles: nluServiceRoles
};

export default NLUService;
