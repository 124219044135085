import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { EntityGroupTableDataModel } from 'models/entity-group-model';
import { useAppSelector } from 'hooks/store';
import { CommentPopup, ThreadStateChangeEvent } from 'pages/Comments/CommentPopup';
import { CommentType } from 'models/comment-model';
import { CommentThreadButton } from 'pages/Comments/CommentThreadButton';
import { versionsSelector } from 'features/versions/versionsSlice';
import { EntityTableDataModel } from 'models/entity-model';
import { mapIdToName } from 'util/mapIdAndName';
import { confirmDialog } from 'primereact/confirmdialog';
import { ChipsCell, LastModifiedCell, TableHeader } from 'components/Table';
import { MenuButtonCell } from 'components/Table/MenuButtonCell';
import paginatorTemplate from 'components/Table/PaginatorTemplate';
import { customSortIconFn } from 'util/table';

type EntityGroupDataTablePropsModel = {
  entityGroups: EntityGroupTableDataModel[];
  allEntities: EntityTableDataModel[];
  onDeleteEntityGroup?(e: EntityGroupTableDataModel): void;
  onThreadStateChange?(options: ThreadStateChangeEvent): void;
  onEditInit?(e: EntityGroupTableDataModel): void;
  readOnly?: boolean;
  canDelete?: boolean;
  showComments?: boolean;
};

/**
 * Component for displaying the Entity Group Table.
 *
 * @param entityGroups List of entity groups
 * @param allEntities List of all entities
 * @param onDeleteEntityGroup Callback function for deleting an entity group
 * @param onThreadStateChange Callback function for the thread state change action
 * @param readOnly Flag indicating if the table is read-only
 * @param canDelete Flag indicating if the user can delete
 * @param onEditInit when the edit action is performed
 * @param showComments if the comments should be shown
 * @constructor
 * @component
 */
const EntityGroupDataTable = function ({
  entityGroups,
  allEntities,
  onDeleteEntityGroup,
  onThreadStateChange,
  readOnly = false,
  canDelete = true,
  onEditInit,
  showComments
}: EntityGroupDataTablePropsModel) {
  const version = useAppSelector(versionsSelector);
  // Store the entity group that will be retired or deleted
  const [selectedNode, setSelectedNode] = useState<EntityGroupTableDataModel>(
    {} as EntityGroupTableDataModel
  );
  const [showCommentPopup, setShowCommentPopup] = useState(false);

  /* - - - - - - - - - - Dialogs - - - - - - - - - - */

  /**
   * Opens the comments dialog
   * @param node object to open the comments dialog for
   */
  const openComments = (node: any) => {
    setSelectedNode(node);
    setShowCommentPopup(true);
  };

  /**
   * Delete confirmation popup
   * @param options delete options
   */
  const confirmDeleteRow = (options: EntityGroupTableDataModel) => {
    confirmDialog({
      message: (
        <div>
          <h6>This action cannot be undone.</h6>
          <ul className="list-disc">
            <li>This Entity Group cannot be recovered.</li>
          </ul>
        </div>
      ),
      header: 'Delete Row?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => onDeleteEntityGroup?.(options)
    });
  };

  return (
    <div>
      <h6>{TableHeader({ tableType: 'Entity Group', itemCount: entityGroups.length })}</h6>
      <DataTable
        className="specto-entity-group__table"
        dataKey="id"
        value={entityGroups}
        sortIcon={customSortIconFn}
        paginator
        paginatorTemplate={paginatorTemplate}
        selectionAutoFocus={false}
        rows={5}
        editMode="row"
        onRowDoubleClick={(e) =>
          !version.isVersionSelected &&
          !readOnly &&
          onEditInit?.(e.data as EntityGroupTableDataModel)
        }
        metaKeySelection={false}
      >
        <Column field="name" className="specto-table__column--md" header="Name" sortable />
        <Column
          field="entities"
          className="specto-table__column--xl"
          header="Entities"
          body={(node) =>
            ChipsCell({
              nodeId: node.id,
              chips: node.entities.map((entity: number) => mapIdToName(entity, allEntities))
            })
          }
        />
        <Column
          field="last_modified"
          className="specto-table__column--md"
          header="Last Modified"
          alignHeader="left"
          align="left"
          body={(node) =>
            LastModifiedCell({
              nodeId: node.id,
              user: node.user,
              lastModified: node.last_modified
            })
          }
          sortable
        />
        <Column
          field="util-comments"
          hidden={!showComments || readOnly || version.isVersionSelected}
          header="Comments"
          className="specto-table-icon"
          body={(node: EntityGroupTableDataModel) => (
            <CommentThreadButton
              key={`${node.id}-comments`}
              threadResolved={node.comment_thread_resolved}
              action={() => openComments(node)}
            />
          )}
        />

        <Column
          columnKey="util-action"
          className="specto-table__column--sm-2 pl-0"
          header="Actions"
          alignHeader="center"
          align="center"
          rowEditor
          hidden={readOnly || version.isVersionSelected}
          body={(node: EntityGroupTableDataModel) => (
            <MenuButtonCell
              data-cy={`${node.id}-actions`}
              menu={[
                {
                  label: 'Edit',
                  command: () => onEditInit?.(node)
                },
                {
                  label: 'Delete',
                  visible: canDelete,
                  command: () => confirmDeleteRow(node)
                }
              ]}
            />
          )}
        />
      </DataTable>

      <CommentPopup
        displayPopup={showCommentPopup}
        onPopupDisplayChange={setShowCommentPopup}
        objectType={CommentType.ENTITY_GROUP}
        objectId={selectedNode.id}
        objectName={selectedNode.name}
        parentElement={document.activeElement as HTMLElement}
        onThreadStateChange={onThreadStateChange}
      />
    </div>
  );
};

export default EntityGroupDataTable;
