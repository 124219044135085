import React, { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import HistoryStepper from './HistoryStepper';
import { ProjectMemberUserModel } from 'models/project-model';
import { Status } from 'models/status-model';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { uniqueId } from 'lodash';
import { Tooltip } from 'primereact/tooltip';

type HistoryPopupPropsModel = {
  ContentTemplate: ({
    node,
    current,
    mode,
    retired
  }: {
    node?: any;
    current?: boolean;
    mode?: string;
    retired?: boolean;
  }) => React.JSX.Element;
  findChanges(item1: any, item2: any): any[];
  modes?: string[];
  defaultMode?: string;
  node: any;
  objectType: string;
  onHide(): void;
  onHistorySelect?(id: number, index: number): void;
  parentElement?: HTMLElement;
  users: ProjectMemberUserModel[];
  visible: boolean;
};

/**
 * Component for showing the history objects of a table row in a dropdown.
 * @component
 * @param ContentTemplate The template for the content
 * @param findChanges Function to find changes between two items
 * @param modes The modes to display
 * @param defaultMode The default mode to display
 * @param node The row data
 * @param objectType The type of object
 * @param onHide Callback when hiding the popup
 * @param onHistorySelect Callback when selecting a history item
 * @param parentElement The element to return focus to after the dialog is hidden
 * @param users List of current project members
 * @param visible Whether the popup is visible
 */
const HistoryPopup = function ({
  ContentTemplate,
  findChanges,
  modes,
  defaultMode,
  node,
  objectType,
  onHide,
  onHistorySelect,
  parentElement,
  users,
  visible
}: HistoryPopupPropsModel) {
  const [historyIndex, setHistoryIndex] = useState(0);
  const [mode, setMode] = useState(defaultMode ?? modes?.[0] ?? '');
  const history = [node, ...node.history];
  const liveVersion = history.find((item) => item.status === Status.Published) ?? undefined;
  const titleId = uniqueId('history-popup-title-');
  const titleRef = useRef<HTMLSpanElement>(null);
  // Used to indicate if the initial item has been focused
  // so that an item is not focused every time a re-render occurs
  const [initialItemFocused, setInitialItemFocused] = useState(false);

  const onSelect = (id: number, index: number) => {
    setHistoryIndex(index);
    if (onHistorySelect) onHistorySelect(id, index);
  };

  const hidePopup = () => {
    onHide();
    parentElement?.focus();
  };

  /**
   * Modals should focus on the first interactive element when opened
   * In this case it will be on the select button or close button
   *
   * @param element header element to search
   */
  const onHeaderLoad = (element: HTMLDivElement | null) => {
    if (!initialItemFocused && element) {
      if (modes) {
        const selectButton = element.querySelector('.p-selectbutton')?.children[0] as
          | HTMLDivElement
          | undefined;
        selectButton?.focus();
      } else {
        const closeButton = element.querySelector('.p-dialog-header-close') as
          | HTMLButtonElement
          | undefined;
        closeButton?.focus();
      }
      setInitialItemFocused(true);
    }
  };

  return (
    <Dialog
      draggable={false}
      blockScroll={true}
      visible={visible}
      onHide={hidePopup}
      className="history-popup"
      contentClassName="p-0"
      pt={{ closeButton: { className: 'hidden' } }}
      headerClassName="p-0"
      header={
        <div ref={onHeaderLoad} className="history-popup__header">
          <div className="header-left">
            <div className="content title">{`${objectType} History Log`}</div>
          </div>
          <div className="header-right">
            <div className="content flex align-content-center">
              <div aria-details={node.name} className="flex flex-1 title overflow-hidden">
                <span id={titleId} ref={titleRef} className="title-content">
                  {node.name}
                </span>
              </div>
              {titleRef.current && titleRef.current?.offsetWidth < titleRef.current?.scrollWidth ? (
                <Tooltip target={`#${titleId}`} position="bottom" content={node.name} />
              ) : undefined}
              {modes ? (
                <div className="flex flex-1 justify-content-center">
                  <SelectButton
                    className="flex w-full select-button"
                    pt={{ button: { className: 'flex-1' } }}
                    options={modes}
                    value={mode}
                    onChange={(e) => {
                      if (e.value) setMode(e.value);
                    }}
                  />
                </div>
              ) : undefined}
              <div className="flex flex-1 justify-content-end">
                <Button
                  onClick={onHide}
                  className="p-dialog-header-icon p-dialog-header-close p-link"
                  type="button"
                  icon="pi pi-times"
                  aria-label="Close"
                  rounded
                  text
                />
              </div>
            </div>
          </div>
        </div>
      }
    >
      <div className="flex justify-space-between h-full">
        <div className="history-popup__content--log overflow-y-auto">
          <HistoryStepper
            activeIndex={historyIndex}
            findChanges={findChanges}
            objectType={objectType}
            onSelect={onSelect}
            items={history}
            users={users}
          />
        </div>
        <div className="history-popup__content--center overflow-y-auto">
          <ContentTemplate current={false} mode={mode} node={history[historyIndex]} />
        </div>
        <div className="history-popup__content h-full overflow-y-auto">
          <ContentTemplate
            current={true}
            mode={mode}
            node={liveVersion}
            retired={node.status === Status.Retired}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default HistoryPopup;
