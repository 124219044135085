import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import Popup from 'containers/Popup';
import { accessibilityConfig } from './accessibility-config';

type AccessibilitySettings = {
  fontSize?: number;
};

type AccessibilityPanelProps = {
  visible: boolean;
  onHide(): void;
};

const AccessibilityPanel = function ({ visible, onHide }: AccessibilityPanelProps) {
  const [accessibilityState, setAccessibilityState] = useState<AccessibilitySettings>(
    {} as AccessibilitySettings
  );

  //on init, load changes from local storage
  useEffect(() => {
    const accessibilitySettings = loadAccessibility();

    // set default fontSize if none exist
    if (!accessibilitySettings.fontSize) {
      accessibilitySettings.fontSize = parseFloat(
        window.getComputedStyle(document.documentElement).getPropertyValue('font-size')
      );
    } else {
      setFontSize(accessibilitySettings.fontSize);
    }

    setAccessibilityState(accessibilitySettings);
  }, []);

  const setAccessibility = (accessibilitySettings: Partial<AccessibilitySettings>) => {
    const settings = { ...accessibilityState, ...accessibilitySettings };

    setAccessibilityState(settings);
    localStorage.setItem(accessibilityConfig.localStorageKey, JSON.stringify(settings));
  };

  function loadAccessibility() {
    return JSON.parse(localStorage.getItem(accessibilityConfig.localStorageKey) || '{}');
  }

  /**
   * Set the font size for the <html> element. All font sizes are specified by rem, so all text scales accordingly
   * @param fontSize the new fontsize
   */
  const setFontSize = (fontSize: number) => {
    document.documentElement.style.fontSize = fontSize + 'px';
    setAccessibility({ fontSize });
  };

  return (
    <Popup
      className="specto-accessibility-panel"
      title="Adjust Font Size"
      onHide={onHide}
      visible={visible}
      footerVisible={false}
    >
      <div role="scale font size">
        <h6>Text Size: {accessibilityState?.fontSize && `${accessibilityState.fontSize}px`}</h6>
        <div className="pb-3">Change how large the font appears on your screen</div>
        <Button
          icon="pi pi-chevron-down"
          className="p-button-rounded p-button-text p-button-sm"
          aria-label="decrease font size"
          type="button"
          onClick={() =>
            accessibilityState.fontSize && setFontSize(accessibilityState.fontSize - 1)
          }
          disabled={
            !!accessibilityState.fontSize &&
            accessibilityState.fontSize <= accessibilityConfig.fontSize.min
          }
        />
        <Button
          icon="pi pi-chevron-up"
          className="p-button-rounded p-button-text p-button-sm"
          aria-label="increase font size"
          type="button"
          onClick={() =>
            accessibilityState.fontSize && setFontSize(accessibilityState.fontSize + 1)
          }
          disabled={
            !!accessibilityState.fontSize &&
            accessibilityState.fontSize >= accessibilityConfig.fontSize.max
          }
        />
      </div>
    </Popup>
  );
};

export default AccessibilityPanel;
