// intent categories
export enum DefaultIntentCategories {
  DEFAULT = 'Default',
  BOT_ADDED = 'Bot-Added',
  GREETING = 'Greeting',
  FAQ = 'FAQ'
}

export const rowCountOptions = [5, 20, 100];

export const chipCharLimit = 15;
