import React, { useContext, useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import Popup from 'containers/Popup';
import { DropdownField } from 'components/Forms';
import { ListBox } from 'primereact/listbox';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import FileInputField from 'components/Forms/FileInputField';
import { LookupTableEditData } from 'models/lookup-table-model';
import { useAppSelector } from 'hooks/store';
import { filterRetired } from 'util/status';
import { Checkbox } from 'primereact/checkbox';
import { entitiesSelector } from 'features/projects/projectsSlice';
import { filterDefault } from 'util/default';
import ToggleDividedButton from 'components/Forms/ToggleDividedButton';
import LookupTableContext from '../../../context/LookupTableContext';

type NewLookupTablePopupPropsModel = {
  parentElement?: HTMLElement;
  onHide(): void;
};

/**
 * Component that displays a popup to create a new lookup table.
 * @component
 * @param param0 Prop object containing the following:
 *
 *    onHide:  callback when the popup is hidden
 *    onSubmit: callback for when the popup is submitted
 *    parentElement: Element to return focus to after the dialog is hidden
 *    rowData: data for each row
 *    visible: Boolean representing whether to display the popup or not.
 * @returns
 */
const NewLookupTablePopup = function ({ onHide, parentElement }: NewLookupTablePopupPropsModel) {
  const [isSubmitLoading, setSubmitLoading] = useState(false); // is the popup submit loading
  const [useFile, setUseFile] = useState(false);
  const [newPhrase, setNewPhrase] = useState('');
  const allEntities = useAppSelector(entitiesSelector);
  const filteredEntities = filterRetired(filterDefault(allEntities));
  const { control, handleSubmit, reset } = useForm();

  const {
    displayNewLookupTablePopup: visible,
    rowEditContextNode: rowData,
    editTableLookup: onSubmit
  } = useContext(LookupTableContext);

  /* - - - - - - - - - - Effects - - - - - - - - - - */

  useEffect(() => {
    const defaultValues: FieldValues = {
      phrases: rowData ? rowData.phrases : [],
      entity: rowData ? rowData.entity : null,
      file: null,
      field_name: false
    };
    reset(defaultValues);
    setUseFile(false);
  }, [rowData]);

  /* - - - - - - - - - - Callbacks - - - - - - - - - - */

  const submitLookupTable = (data: FieldValues) => {
    const values: LookupTableEditData = { ...data } as LookupTableEditData;
    onSubmit?.(values);
  };

  const onPopupSave = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    handleSubmit(submitLookupTable)();
  };

  const hidePopup = () => {
    reset();
    setNewPhrase('');
    setSubmitLoading(false);
    parentElement?.focus();
    onHide();
  };

  return (
    <Popup
      title={`${rowData ? 'Edit' : 'New'} Lookup Table`}
      subtitle={`${rowData ? 'Update an existing' : 'Create a new'} Lookup Table`}
      visible={visible}
      loading={isSubmitLoading}
      saveButtonText={`${rowData ? 'Save' : 'Create '} Lookup Table`}
      onHide={hidePopup}
      onSave={onPopupSave}
    >
      <form className="p-fluid" onSubmit={onPopupSave}>
        <div className="specto-text-medium -mt-2 mb-5">
          Required fields are marked with <span className="specto-form-asterisk-color">*</span>
        </div>

        <DropdownField
          label="Entity"
          required
          fieldName="entity"
          placeholder="Select an Entity"
          filter
          control={control}
          options={filteredEntities}
          optionLabel="name"
          optionValue="id"
          appendTo={null}
        />

        <div className="mb-5 align-items-center">
          <label id="lookup-table-input-mode">Input Mode</label>
          <ToggleDividedButton
            ariaDescribedBy="lookup-table-input-mode"
            className="mt-1"
            trueLabel="Upload a File"
            falseLabel="Manual Inpput"
            checked={useFile}
            onChange={(e) => setUseFile(Boolean(e.value))}
          />
        </div>

        <div className={useFile ? '' : 'hidden'}>
          <FileInputField control={control} fieldName="file" buttonLabel="File" />
          <Controller
            control={control}
            name="field_name"
            render={({ field }) => (
              <div className="flex align-items-center">
                <Checkbox
                  inputId="has_header"
                  checked={field.value}
                  onChange={(e) => field.onChange(e.checked)}
                />
                <label htmlFor="has_header" className="ml-2">
                  File has column header?
                </label>
              </div>
            )}
          />
        </div>
        <div className={useFile ? 'hidden' : ''}>
          <label className="specto-text-medium">
            {'Phrases'} <span className="specto-form-asterisk-color">*</span>
          </label>
          <Controller
            control={control}
            name="phrases"
            render={({ field }) => (
              <div>
                <ListBox
                  className="mb-2"
                  listClassName="max-h-20rem"
                  filter
                  filterPlaceholder="Search Phrases"
                  options={field.value}
                  itemTemplate={(item) => (
                    <div className="flex justify-content-between align-items-center">
                      {item}
                      <Button
                        className="p-button-text"
                        icon="pi pi-times"
                        onClick={() =>
                          field.onChange(field.value.filter((phrase: string) => phrase !== item))
                        }
                      />
                    </div>
                  )}
                />
                <div className="flex">
                  <InputText
                    className="specto-input-text"
                    value={newPhrase}
                    onChange={(e) => setNewPhrase(e.target.value)}
                  />
                  <Button
                    className="w-3 ml-2"
                    label="Add Phrase"
                    onClick={() => {
                      const phrase = newPhrase.trim();
                      if (phrase) {
                        if (!field.value.includes(phrase)) {
                          field.onChange([...field.value, phrase]);
                        }
                        setNewPhrase('');
                      }
                    }}
                    type="button"
                  />
                </div>
              </div>
            )}
          />
        </div>
      </form>
    </Popup>
  );
};

export default NewLookupTablePopup;
