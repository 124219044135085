import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import classnames from 'classnames';

type LoadingSpinnerProps = {
  className?: string;
  enabled?: boolean;
  style?: React.CSSProperties;
  position?: 'center' | 'inline';
  small?: boolean;
};

const LoadingSpinner = ({
  className,
  style,
  enabled = true,
  position = 'center',
  small
}: LoadingSpinnerProps) =>
  enabled ? (
    <div
      style={{
        ...style,
        ...(small ? { width: '30px', height: '30px' } : { width: '60px', height: '60px' })
      }}
      className={classnames(className, {
        'specto-loading-spinner': true,
        'specto-loading-spinner__center': position === 'center'
      })}
    >
      <ProgressSpinner
        style={small ? { width: '30px', height: '30px' } : { width: '60px', height: '60px' }}
      />
    </div>
  ) : (
    <></>
  );

export default LoadingSpinner;
