import React from 'react';
import { Chip } from 'primereact/chip';
import { Tooltip } from 'primereact/tooltip';
import { UserGroupModel, UserGroupState } from 'models/user-groups-model';
import { createUserGroupOptions } from 'util/userGroups';
import classNames from 'classnames';

/**
 * Component for displaying user groups in a cell
 *
 * @param nodeId id of the node
 * @param userGroups user groups associated with the node
 * @param referenceUserGroups user groups associated with the node's reference
 * @param allUserGroups all user groups
 * @component
 */
const UserGroupsCell = function ({
  nodeId,
  userGroups,
  referenceUserGroups,
  allUserGroups
}: {
  nodeId: number | string;
  userGroups: number[];
  referenceUserGroups?: number[];
  allUserGroups: UserGroupModel[];
}) {
  const items = createUserGroupOptions(userGroups, allUserGroups, referenceUserGroups);

  return items.length > 0 ? (
    <div id={'specto-chips-cell-' + nodeId} className="select-none">
      {items.map((chip) => {
        const chipId = `chip-${nodeId}-${chip.id}`;
        const details =
          chip.state === UserGroupState.UNCHANGED
            ? undefined
            : chip.state === UserGroupState.ADDED
              ? 'This user group will be added after this row has been published'
              : 'This user group will be removed after this row has been published';

        return (
          <span key={chipId}>
            <Chip
              className={classNames('mb-1', {
                'user-group-chips': chip.state === UserGroupState.UNCHANGED,
                'user-group-chips--added': chip.state === UserGroupState.ADDED,
                'user-group-chips--removed': chip.state === UserGroupState.REMOVED
              })}
              id={chipId}
              label={chip.name}
              aria-description={details}
              tabIndex={0}
              role="presentation"
            />
            {chip.state !== UserGroupState.UNCHANGED && (
              <Tooltip event="both" position="bottom" target={`#${chipId}`} content={details} />
            )}
          </span>
        );
      })}
    </div>
  ) : (
    <div id={'specto-chips-cell-' + nodeId} className="specto-chips-none">
      No User Groups
    </div>
  );
};

export default UserGroupsCell;
