import { DateTime } from 'luxon';

/**
 * Convert UTC ISO 8601 date to YYY-MM-DD format in local timezone
 * @param utcDate Date in UTC timezone and ISO 8601 format
 * @returns A string in YYYY-MM-DD format (local timezone)
 */
export const utcToLocalDateFormatted = (utcDate: string) => {
  const localDate = new Date(utcDate);
  return `${localDate.getFullYear()}-${(localDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${localDate.getDate().toString().padStart(2, '0')}`;
};

/**
 * Convert YYY-MM-DD format in local timezone to a human-readable date and time
 * @param date Date YYY-MM-DD format
 * @returns human readable date and time
 */
export const humanReadableDateTimeFormatted = (date: string) =>
  new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

/**
 * Convert YYY-MM-DD format to a human-readable date
 * @param date Date YYY-MM-DD format
 * @returns human readable date
 */
export const humanReadableSimpleDateFormatted = (date: string) =>
  new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });

/**
 * Convert YYY-MM-DD format to a human-readable date
 * @param date Date YYY-MM-DD format
 * @returns human readable date
 */
export const humanReadableSimpleDateTimeFormatted = (date: string) =>
  new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'America/Edmonton',
    hour12: true
  });

/**
 * Convert ISO 8601 date string to local timezone to a human-readable date and time
 * @param date Date ISO 8601 date string
 * @returns human readable date and time
 */
export const humanReadableDateTimeFormatted24 = (dateString: string) => {
  const date = new Date(dateString);
  const pad = (number: number) => (number < 10 ? '0' + number : number);
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

/**
 * Convert ISO 8601 date string to local timezone to a human-readable date and time
 * @param date Date ISO 8601 date string
 * @returns human readable date and time
 */
export const humanReadableDateFormatted24 = (dateString: string) => {
  const date = new Date(dateString);
  const pad = (number: number) => (number < 10 ? '0' + number : number);
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());

  return `${year}-${month}-${day}`;
};

/**
 * Date String formatter with ISOString fallback. Formatted like 2023-07-11T12:14:32.006-06:00
 * @param dateString the date string
 */
export const formatDateISOStart = (dateString: Date): string => {
  const dateTime = DateTime.fromJSDate(dateString).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0
  });
  return dateTime.toISO({ includeOffset: true }) ?? dateString.toISOString();
};

/**
 * Date String formatter with ISOString fallback. Formatted like 2023-07-11T12:14:32.006-06:00
 * @param dateString the date string
 */
export const formatDateISOEnd = (dateString: Date): string => {
  const dateTime = DateTime.fromJSDate(dateString).set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 999
  });
  return dateTime.toISO({ includeOffset: true }) ?? dateString.toISOString();
};
