import { WidgetState, BotMessage, UserMessage } from '@developers/specto-widget';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { WidgetStoreState, initialState } from './widgetSlice';

export const widgetHistoryInitialState: WidgetStoreState = initialState;

export const widgetHistorySlice = createSlice({
  name: 'widget_widget_history',
  initialState: widgetHistoryInitialState,
  reducers: {
    widgetHistoryReset: (state, action: PayloadAction<string>) => {
      state = {
        ...widgetHistoryInitialState,
        widgetVersion: action.payload
      };
    },
    widgetHistorySetMessages: (state, action: PayloadAction<(BotMessage | UserMessage)[]>) => {
      state.messages = action.payload;
    },
    widgetHistoryAddUserMessage: (state, action: PayloadAction<UserMessage>) => {
      state.messages = [...state.messages, action.payload];
    },
    widgetHistoryAddBotMessage: (state, action: PayloadAction<BotMessage[]>) => {
      state.messages = [...state.messages, ...action.payload];
    },
    widgetHistoryResetMessages: (state, action: PayloadAction<boolean>) => {
      state.messages = [];
      !action.payload && (state.user = 'chatbot_studio_preview');
    },
    widgetHistorySetFontSize: (state, action: PayloadAction<number>) => {
      state.fontSize = action.payload;
    },
    widgetHistorySetUser: (state) => {
      state.user = 'chatbot_studio_preview';
    },
    widgetHistorySetState: (state, action: PayloadAction<WidgetState>) => {
      state.widgetState = action.payload;
    },
    widgetHistorySetLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    widgetHistorySetContext: (state, action: PayloadAction<string>) => {
      state.context = action.payload;
    }
  }
});

/* - - - - - - - - - - Selectors - - - - - - - - - - */

export const widgetHistoryUserSelector = (state: RootState) => state.widgetHistory.user;
export const widgetHistoryMessageSelector = (state: RootState) => state.widgetHistory.messages;
export const widgetHistoryFontSizeSelector = (state: RootState) => state.widgetHistory.fontSize;
export const widgetHistoryStateSelector = (state: RootState) => state.widgetHistory.widgetState;
export const widgetHistoryLanguageSelector = (state: RootState) => state.widgetHistory.language;
export const widgetHistoryVersionSelector = (state: RootState) => state.widgetHistory.widgetVersion;
export const widgetHistoryContextSelector = (state: RootState) => state.widgetHistory.context;

export const {
  widgetHistoryReset,
  widgetHistorySetMessages,
  widgetHistoryAddUserMessage,
  widgetHistoryAddBotMessage,
  widgetHistoryResetMessages,
  widgetHistorySetFontSize,
  widgetHistorySetUser,
  widgetHistorySetState,
  widgetHistorySetLanguage,
  widgetHistorySetContext
} = widgetHistorySlice.actions;

export default widgetHistorySlice.reducer;
