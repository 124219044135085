import React from 'react';
import { ResponseTableDataModel } from 'models/response-table-data-model';
import { ProjectMemberUserModel } from 'models/project-model';
import { UserGroupModel } from 'models/user-groups-model';
import HistoryPopup from 'components/History/HistoryPopup';
import { Chip } from 'primereact/chip';
import _, { uniqueId } from 'lodash';
import { utcToLocalDateFormatted } from 'util/dates';
import { statusDisplayNames } from 'constants/status';
import { mapIdToName } from 'util/mapIdAndName';
import { TopicTableDataModel } from 'models/topic-model';
import { IntentTableDataModel } from 'models/intent-table-data-model';
import { EntityTableDataModel } from 'models/entity-model';
import classNames from 'classnames';
import { HistoryStepRightArrow } from 'components/History/HistoryStepper';

type TopicHistoryPopupPropsModel = {
  allEntities: EntityTableDataModel[];
  allIntents: IntentTableDataModel[];
  allResponses: ResponseTableDataModel[];
  allUserGroups: UserGroupModel[];
  authors: ProjectMemberUserModel[];
  isSuperUser: boolean;
  node: TopicTableDataModel;
  onHide(): void;
  parentElement?: HTMLElement;
  visible: boolean;
};

/**
 * Component for showing the history objects of a table row in a dropdown.
 * @component
 * @param allUserGroups all user groups
 * @param authors List of current project members
 * @param node The row data
 * @param onHide Callback when hiding the popup
 * @param parentElement The element to return focus to after the dialog is hidden
 */
const TopicHistoryPopup = function ({
  allEntities,
  allIntents,
  allResponses,
  allUserGroups,
  authors,
  isSuperUser,
  node,
  onHide,
  parentElement,
  visible
}: TopicHistoryPopupPropsModel) {
  const ContentTemplate = ({
    current = true,
    node,
    retired
  }: {
    current?: boolean;
    node?: TopicTableDataModel;
    retired?: boolean;
  }) => (
    <div className="specto-topic-history-popup-content h-full">
      <div className="flex">
        <strong>{current ? 'Active version' : 'Selected version'}</strong>

        {node ? (
          <>
            <div className="px-2">{'•'}</div>
            <div>{`Modified on ${utcToLocalDateFormatted(node.last_modified)}`}</div>
          </>
        ) : undefined}
      </div>
      {!node || retired ? (
        <div className="flex h-full align-items-center">
          <span className="text-center w-full">
            {!node ? "Topic hasn't been published yet" : 'Topic is retired'}
          </span>
        </div>
      ) : (
        <>
          <table className="info">
            <tbody>
              <tr>
                <td>Name</td>
                <td>{node.name}</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>{node.description}</td>
              </tr>
              <tr>
                <td>Author</td>
                <td className={classNames({ 'empty-cell': !node.author })}>
                  {node.author
                    ? authors.find((author) => author.user === node.author)?.name
                    : 'None'}
                </td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{statusDisplayNames[node.status]}</td>
              </tr>
              <tr>
                <td className="chips-label-cell">User Groups</td>
                <td
                  className={classNames({
                    'empty-cell--chips': node.user_groups.length === 0
                  })}
                >
                  {node.user_groups.length > 0
                    ? node.user_groups.map((userGroup) => {
                        const label = allUserGroups.find((group) => group.id === userGroup)?.name;
                        return (
                          <Chip
                            className="user-group-chips mb-1"
                            label={label ? label : userGroup.toString()}
                            key={uniqueId(`user-group-chip-${userGroup}`)}
                          />
                        );
                      })
                    : 'None'}
                </td>
              </tr>
              <tr>
                <td className={'chips-label-cell'}>Intents</td>
                <td className={classNames({ 'empty-cell--chips': node.intents.length === 0 })}>
                  {node.intents.length > 0
                    ? node.intents.map((intent) => {
                        const label = allIntents.find((i) => i.id === intent)?.name;
                        return (
                          <Chip
                            className="user-group-chips mb-1"
                            label={label ? label : intent.toString()}
                            key={uniqueId(`intent-chip-${intent}`)}
                          />
                        );
                      })
                    : 'None'}
                </td>
              </tr>
              <tr>
                <td className={'chips-label-cell'}>Responses</td>
                <td
                  className={classNames({
                    'empty-cell--chips': node.responses.length === 0
                  })}
                >
                  {node.responses.length > 0
                    ? node.responses.map((response) => {
                        const label = allResponses.find((r) => r.id === response)?.name;
                        return (
                          <Chip
                            className="user-group-chips mb-1"
                            label={label ? label : response.toString()}
                            key={uniqueId(`response-chip-${response}`)}
                          />
                        );
                      })
                    : 'None'}
                </td>
              </tr>
              {isSuperUser ? (
                <tr>
                  <td className="chips-label-cell">Entities</td>
                  <td
                    className={classNames({
                      'empty-cell--chips': node.entities.length === 0
                    })}
                  >
                    {node.entities.length > 0
                      ? node.entities.map((entity) => {
                          const label = allEntities.find((e) => e.id === entity)?.name;
                          return (
                            <Chip
                              className="user-group-chips mb-1"
                              label={label ? label : entity.toString()}
                              key={uniqueId(`entity-chip-${entity}`)}
                            />
                          );
                        })
                      : 'None'}
                  </td>
                </tr>
              ) : undefined}
            </tbody>
          </table>
        </>
      )}
    </div>
  );

  const findChanges = (currentItem: TopicTableDataModel, oldItem: TopicTableDataModel) => {
    let changes = [];

    if (oldItem.status !== currentItem.status) {
      changes.push(`Status changed to ${statusDisplayNames[currentItem.status]}`);
    }

    if (oldItem.name !== currentItem.name) {
      changes.push([
        `Name of the topic was changed.`,
        <div className="align-items-center" key={uniqueId()}>
          {oldItem.name}
          <HistoryStepRightArrow />
          {currentItem.name}
        </div>
      ]);
    }
    if (oldItem.description !== currentItem.description) {
      changes.push(`Description changed`);
    }
    if (oldItem.author !== currentItem.author) {
      if (!oldItem.author) {
        changes.push(`Author changed to ${mapIdToName(currentItem.author, authors, 'user')}`);
      } else if (!currentItem.author) {
        changes.push(`Author was removed`);
      } else {
        changes.push([
          `Author of the topic was changed.`,
          <div className="align-items-center" key={uniqueId()}>
            {`${mapIdToName(oldItem.author, authors, 'user')}`}
            <HistoryStepRightArrow />
            {`${mapIdToName(currentItem.author, authors, 'user')}`}
          </div>
        ]);
      }
    }
    const userGroupsAdded = _.difference(currentItem.user_groups, oldItem.user_groups);
    changes = changes.concat(
      userGroupsAdded.map((group) => `User group "${mapIdToName(group, allUserGroups)}" added`)
    );
    const removedUserGroups = _.difference(oldItem.user_groups, currentItem.user_groups);
    changes = changes.concat(
      removedUserGroups.map((group) => `User group "${mapIdToName(group, allUserGroups)}" removed`)
    );

    const intentsAdded = _.difference(currentItem.intents, oldItem.intents);
    changes = changes.concat(
      intentsAdded.map((intent) => `Intent "${mapIdToName(intent, allIntents)}" added`)
    );
    const removedIntents = _.difference(oldItem.intents, currentItem.intents);
    changes = changes.concat(
      removedIntents.map((intent) => `Intent "${mapIdToName(intent, allIntents)}" removed`)
    );

    const responsesAdded = _.difference(currentItem.responses, oldItem.responses);
    changes = changes.concat(
      responsesAdded.map((response) => `Response "${mapIdToName(response, allResponses)}" added`)
    );
    const removedResponses = _.difference(oldItem.responses, currentItem.responses);
    changes = changes.concat(
      removedResponses.map(
        (response) => `Response "${mapIdToName(response, allResponses)}" removed`
      )
    );

    if (isSuperUser) {
      const entitiesAdded = _.difference(currentItem.entities, oldItem.entities);
      changes = changes.concat(
        entitiesAdded.map((entity) => `Entity "${mapIdToName(entity, allEntities)}" added`)
      );
      const removedEntities = _.difference(oldItem.entities, currentItem.entities);
      changes = changes.concat(
        removedEntities.map((entity) => `Entity "${mapIdToName(entity, allEntities)}" removed`)
      );
    }

    return changes;
  };

  return (
    <HistoryPopup
      ContentTemplate={ContentTemplate}
      findChanges={findChanges}
      node={node}
      objectType="Topic"
      onHide={onHide}
      parentElement={parentElement}
      users={authors}
      visible={visible}
    />
  );
};

export default TopicHistoryPopup;
