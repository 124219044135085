import React from 'react';
import { Link } from 'react-router-dom';
import AppSubmenu from './AppSubmenu';
import { useAppSelector } from 'hooks/store';
import { projectsSelector } from 'features/projects/projectsSlice';
import { routes } from 'constants/routes';
import NavSkip from 'components/NavSkip/NavSkip';

type AppMenuProps = {
  onRootMenuitemClick: any;
  onMenuitemClick: any;
  mobileMenuActive: any;
  active: any;
  menuMode: any;
  model: any;
  onMenuClick: any;
  bannerHeight: number;
};

const AppMenu = (props: AppMenuProps) => {
  const { selectedProject } = useAppSelector(projectsSelector);
  return (
    <div
      style={{
        top: `${(props.bannerHeight || 0) - 1}px`,
        maxHeight: `calc(100vh - ${props.bannerHeight}px)`
      }}
      className="layout-sidebar"
      onClick={props.onMenuClick}
    >
      <NavSkip />
      <Link to="/" className="logo flex align-content-center align-items-center">
        <div>
          <img
            id="app-logo"
            className="logo-image"
            src="/assets/layout/images/askma-logo-dark.svg"
            alt="specto logo"
          />
        </div>
      </Link>
      {selectedProject.name && (
        <Link
          to={routes.PROJECT_HOME}
          className="logo specto-app-menu-project-route-link px-3 mx-3 mt-3 flex align-content-center align-items-center"
        >
          <div
            className="app-name uppercase w-full text-left w-max text-xl overflow-hidden white-space-nowrap"
            style={{
              height: '1.5rem',
              wordBreak: 'break-all'
            }}
          >
            {selectedProject.name}
          </div>
        </Link>
      )}

      <div className="layout-menu-container">
        <AppSubmenu
          items={props.model}
          menuMode={props.menuMode}
          parentMenuItemActive
          menuActive={props.active}
          mobileMenuActive={props.mobileMenuActive}
          root
          onMenuitemClick={props.onMenuitemClick}
          onRootMenuitemClick={props.onRootMenuitemClick}
        />
      </div>
    </div>
  );
};

export default AppMenu;
