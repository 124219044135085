import ModalFormFullScreen from 'containers/modals/ModalFormFullScreen';
import { Button } from 'primereact/button';
import { ReactComponent as MicrosoftLogo } from 'assets/microsoft/microsoft.svg';
import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import useFetch from 'hooks/useFetch';
import AuthService from 'services/authService';
import { SSOAuthRequestAPIModel } from 'models/auth-model';
import { FetchStatusOptions } from 'constants/fetchStatus';
import TokenService from 'services/tokenService';
import ApiService from 'services/apiService';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import {
  authSelector,
  clearAuthLoadingState,
  loginUser,
  logout,
  updateAuth
} from 'features/auth/authSlice';
import { persistor } from 'store';
import LoadingSpinner from 'components/LoadingSpinner';

const SSOLogin = () => {
  const { instance, inProgress } = useMsal();
  const dispatch = useAppDispatch();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const { isFetching, user } = useAppSelector(authSelector);

  useEffect(() => {
    isFetching && dispatch(clearAuthLoadingState());
    persistor.purge().catch(console.error); // in case user persisted in redux-persist local storage
    user.email && dispatch(logout()); // in case user persisted in state
  }, []);

  const {
    fetch: login,
    data: loginData,
    fetchStatus: loginStatus
  } = useFetch(AuthService.loginMicrosoft, AuthService.roles.create);

  useEffect(() => {
    if (loginStatus === FetchStatusOptions.SUCCESS && loginData) {
      const authenticate = async () => {
        // Set User in local session
        TokenService.setAuth(loginData);

        // Set Token Bearer in Axios instance
        ApiService.setHeaderTokenBearer(loginData.access);
        dispatch(updateAuth(loginData));
        dispatch(loginUser(loginData.user));
        setIsRedirecting(false);
      };
      authenticate();
    }
  }, [loginStatus]);

  useEffect(() => {
    const handleRedirect = async () => {
      try {
        setIsRedirecting(true);
        await instance.initialize().then(async () => {
          const response = await instance.handleRedirectPromise();
          if (response && response?.accessToken) {
            const accessToken = response.accessToken;
            const data: SSOAuthRequestAPIModel = {
              access_token: accessToken
            };

            login(data);
          }
        });
      } catch (error) {
        console.log('Error handling redirect:', error);
      }
    };

    inProgress === 'handleRedirect' && handleRedirect();
  }, [instance, inProgress]);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      instance.loginRedirect();
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return isRedirecting || inProgress !== 'none' ? (
    <div className="font-bold flex flex-row justify-content-center align-items-center w-screen h-screen gap-3">
      Redirecting...
      <LoadingSpinner small style={{ margin: 0, position: 'inherit' }} />
    </div>
  ) : (
    <ModalFormFullScreen
      onSubmit={handleLogin}
      className="specto-login text-align-center justify-content-center"
    >
      <h2 className="pt-4 m-auto mb-2">Sign in to your Account</h2>
      <span className="specto-login__subtitle m-auto">Chatbot training and Management</span>

      <Button
        className="flex flex-row w-full py-3 px-2 mt-6 mb-5 justify-content-center align-items-center text-xl font-bold gap-2 border-3"
        iconPos="left"
        data-cy="login-button"
        type="submit"
        outlined
        raised
      >
        <MicrosoftLogo />
        <span className="text-primary">Continue with Microsoft</span>
      </Button>
    </ModalFormFullScreen>
  );
};

export default SSOLogin;
