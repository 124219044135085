import { LanguageModel, ResponseLanguageTranslation } from 'models/language-model';
import { UserGroupModel } from './user-groups-model';
import { UserModel } from './user-model';

export enum ProjectRole {
  NEUROSOPH = 'SU',
  ADMIN = 'AD',
  CONTENT_STRATEGIST = 'CS',
  CONTENT_AUTHOR = 'CA',
  GUEST = 'GU'
}

export type ProjectSummaryModel = {
  slug: string;
  manager: string;
  company: string;
  name: string;
  project_user: ProjectUserModel;
};

export interface ProjectDetailModel extends ProjectSummaryModel {
  sentiment: boolean;
  languages: LanguageModel['id'][];
  translation: ResponseLanguageTranslation;
}

export interface ProjectMemberModel {
  id: number;
  user: number;
  role: ProjectRole;
  user_groups: UserGroupModel['id'][];
}

export interface ProjectMemberUserModel extends ProjectMemberModel {
  first_name: string;
  last_name: string;
  email: string;
  name: string;
  name_reversed: string;
}

export type EventsDatabaseModel = {
  db_host: string;
  db_name: string;
  db_port: number;
  db_user: string;
  sync_events: boolean;
  valid_connection: boolean;
};

export type EventsDatabaseSyncModel = {
  sync_events: boolean;
  valid_connection: boolean;
};

export type EventsDatabaseRequestAPIModel = Partial<EventsDatabaseModel>;

export type EventsDatabaseSyncRequestAPIModel = {
  sync_events: boolean;
};

export type ResponseDatabaseModel = {
  name: string;
  host: string;
  port: number | null;
  user: string;
  password: string;
  root_cert: File | string | null;
  valid_connection: boolean;
};

export type ProjectMemberPostAPIModel = {
  role: ProjectRole;
  user: UserModel['id'];
  user_groups: UserGroupModel['id'][];
};

export type ProjectMemberPatchAPIModel = {
  role: ProjectRole;
  user_groups: UserGroupModel['id'][];
  user: UserModel['id'];
};

export type ProjectsPostRequestAPIModel = {
  project: ProjectSummaryModel;
};

export type ProjectsPatchRequestAPIModel = {
  manager?: string;
  company?: string;
  name?: string;
};

export type EditProjectOptionsModel = {
  slug: ProjectSummaryModel['slug'];
  project: ProjectsPatchRequestAPIModel;
};

export type EditEventsDatabaseOptionsModel = {
  eventsDatabase: EventsDatabaseRequestAPIModel;
  slug: ProjectSummaryModel['slug'];
};

export type EditEventsDatabaseSyncOptionsModel = {
  sync_events: boolean;
  slug: ProjectSummaryModel['slug'];
};

export interface ProjectMemberOptionsModel {
  slug: ProjectSummaryModel['slug'];
  user: UserModel['id'];
  user_groups: UserGroupModel['id'][];
  role: ProjectRole;
}

export type PostProjectMemberRoleOptionsModel = {
  slug: ProjectSummaryModel['slug'];
  user: UserModel['id'];
  user_groups: UserGroupModel['id'][];
  role: ProjectRole;
};

export type ProjectUserModel = {
  role: ProjectRole;
  user_groups: UserGroupModel['id'][];
};

export interface EditProjectMemberOptionsModel extends ProjectMemberOptionsModel {
  id: ProjectMemberModel['id'];
}
