import React from 'react';
import classNames from 'classnames';
import { Control, FieldValues, useFieldArray } from 'react-hook-form';
import { useAppSelector } from 'hooks/store';
import {
  intentsSelector,
  projectsSelector,
  sentimentsSelector
} from 'features/projects/projectsSlice';
import { DropdownField, TextField } from 'components/Forms';
import { MessageInboxLabelledByModel } from 'models/message-inbox-table-data-model';
import { onDateChange } from 'util/calendar';
import TagsField from 'components/Forms/TagsField';
import MultiSelectField from 'components/Forms/MultiSelectField';
import CalendarField from 'components/Forms/CalendarField';
import SliderField from 'components/Forms/SliderField';
import { Button } from 'primereact/button';
import { MetadataFilterModel } from 'models/metadata-filter-model';
import { Paper } from '@mui/material';
import LoadingSpinner from 'components/LoadingSpinner';

type MessageInboxFilterProps = {
  control: Control;
  onFormValueChange(data: FieldValues): void;
  onChangeMetadataField(index: number): void;
  superUserView?: boolean;
  loading?: boolean;
};

type AnnotationOptionType = {
  name: string;
  queryValue?: string | MessageInboxLabelledByModel;
  queryParameter?: string;
};

const reviewedOptions: AnnotationOptionType[] = [
  { name: 'Reviewed', queryValue: 'True', queryParameter: 'reviewed' },
  { name: 'Not Reviewed', queryValue: 'False', queryParameter: 'reviewed' },
  { name: 'Accepted', queryValue: MessageInboxLabelledByModel.ACCEPTED }
];

const annotationOptions: AnnotationOptionType[] = [
  { name: 'Single', queryValue: MessageInboxLabelledByModel.SINGLE },
  { name: 'Multiple', queryValue: MessageInboxLabelledByModel.MULTIPLE },
  { name: 'Conflict', queryValue: MessageInboxLabelledByModel.CONFLICT },
  { name: 'Accepted', queryValue: MessageInboxLabelledByModel.ACCEPTED },
  { name: 'Not Annotated', queryValue: MessageInboxLabelledByModel.NONE }
];

const MessageInboxFilterMenu = function ({
  control,
  onFormValueChange,
  onChangeMetadataField,
  superUserView,
  loading
}: MessageInboxFilterProps) {
  const intents = useAppSelector(intentsSelector);
  const sentiments = useAppSelector(sentimentsSelector);
  const { selectedProject, metadataFilters } = useAppSelector(projectsSelector);
  const contentField = 'metadata';
  const { append, remove, fields } = useFieldArray({
    control,
    name: `${contentField}` as const
  });

  return (
    <Paper className="p-3 w-30rem">
      {loading ? (
        <LoadingSpinner position="inline" />
      ) : (
        <form className="flex flex-wrap gap-3 overflow-y-auto" style={{ maxHeight: '70vh' }}>
          <MultiSelectField
            className="col specto-border border-round border-1 p-3"
            labelClassName="line-height-3 font-bold"
            fieldName="intent"
            label="Intent"
            control={control}
            options={intents}
            optionValue="id"
            optionLabel="name"
            onChange={(e) => onFormValueChange({ intent: e.value })}
            filter
            filterBy="name"
            placeholder="Select an Intent"
            required={false}
            noPadding
          />

          {selectedProject.sentiment && (
            <MultiSelectField
              className="col specto-border border-round border-1 p-3"
              labelClassName="line-height-3 font-bold"
              fieldName="sentiment"
              label="Sentiment"
              control={control}
              options={sentiments}
              optionValue="id"
              optionLabel="name"
              onChange={(e) => onFormValueChange({ sentiment: e.value })}
              filter
              filterBy="name"
              placeholder="Select an sentiment"
              required={false}
              noPadding
            />
          )}

          <CalendarField
            label="Date"
            labelClassName="line-height-3 font-bold"
            className="w-12 specto-border border-round border-1 p-3"
            control={control}
            onChange={(e) => onDateChange(e.value, onFormValueChange, 'date_')}
          />

          <TagsField
            className="w-12 specto-border border-round border-1 p-3"
            labelClassName="line-height-3 font-bold"
            control={control}
            onChange={(e) => onFormValueChange({ tags: e.value })}
            noPadding
          />

          <DropdownField
            className="w-12 specto-border border-round border-1 p-3"
            fieldName={superUserView ? 'annotation' : 'review'}
            labelClassName="line-height-3 font-bold"
            label="Labelled By"
            placeholder="Select an option"
            required={false}
            control={control}
            options={superUserView ? annotationOptions : reviewedOptions}
            optionLabel="name"
            appendTo={null}
            onChange={(e) => {
              e?.value &&
                onFormValueChange({
                  labelled_by: '',
                  reviewed: '',
                  ...{
                    [e.value.queryParameter ?? 'labelled_by']: e.value.queryValue
                  }
                });
            }}
            noPadding
          />

          <SliderField
            labelFn={(value) => `Bot Intent Confidence: ${value[0]}% - ${value[1]}%`}
            control={control}
            labelClassName="line-height-3 font-bold"
            className="w-12 specto-border border-round border-1 p-3"
            fieldName="intent_confidence"
            onChange={(e) =>
              Array.isArray(e.value) &&
              onFormValueChange({
                bot_intent_confidence_gte: e.value[0] / 100 || undefined,
                bot_intent_confidence_lte: e.value[1] === 100 ? undefined : e.value[1] / 100
              })
            }
          />

          {selectedProject.sentiment && (
            <SliderField
              labelFn={(value) => `Bot Sentiment Confidence: ${value[0]}% - ${value[1]}%`}
              control={control}
              labelClassName="line-height-3 font-bold"
              className="w-12 specto-border border-round border-1 p-3"
              fieldName="sentiment_confidence"
              onChange={(e) =>
                Array.isArray(e.value) &&
                onFormValueChange({
                  bot_sentiment_confidence_gte: e.value[0] / 100 || undefined,
                  bot_sentiment_confidence_lte: e.value[1] === 100 ? undefined : e.value[1] / 100
                })
              }
            />
          )}

          <SliderField
            labelFn={(value) => `Message Length: ${value[0]} - ${value[1]} characters`}
            control={control}
            labelClassName="line-height-3 font-bold"
            className="w-12 specto-border border-round border-1 p-3"
            fieldName="message_length"
            max={200}
            onChange={(e) =>
              Array.isArray(e.value) &&
              onFormValueChange({
                length_gte: e.value[0] || undefined,
                length_lte: e.value[1] === 200 ? undefined : e.value[1]
              })
            }
          />

          <div className="w-12 specto-border border-round border-1 p-3">
            <h6>Metadata Field</h6>
            {fields.map((item, index) => (
              <div key={item.id} className="flex py-2 align-items-center">
                <DropdownField
                  filter
                  editable
                  appendTo={null}
                  fieldName={`${contentField}.${index}.field`}
                  control={control}
                  className="w-6 pr-1"
                  optionLabel="alias"
                  optionValue="filter"
                  onChange={() => onChangeMetadataField(index)}
                  itemTemplate={(item: MetadataFilterModel) =>
                    item.alias ? `${item.alias} (${item.filter})` : item.filter
                  }
                  placeholder="Field"
                  options={metadataFilters}
                  noPadding
                />
                <TextField
                  fieldName={`${contentField}.${index}.value`}
                  control={control}
                  onChange={() => onChangeMetadataField(index)}
                  className="w-6 pl-1"
                  placeholder="Value"
                  noPadding
                />
                <Button
                  className={classNames('p-button-rounded specto-r-button-lg ml-2', {
                    hidden: index === 0 && fields.length === 1
                  })}
                  icon="pi pi-trash"
                  type="button"
                  onClick={() => {
                    remove(index);
                    onChangeMetadataField(index);
                  }}
                />
              </div>
            ))}
            <div className="flex justify-content-end ml-5 mt-3">
              <Button
                className="font-semibold specto-text-medium p-button-text underline text-sm py-2 w-auto"
                label="ADD FIELD"
                type="button"
                onClick={() => append({})}
              />
            </div>
          </div>
        </form>
      )}
    </Paper>
  );
};

export default MessageInboxFilterMenu;
